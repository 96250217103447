import { Button, Grid, Paper, TextField, Typography } from "@material-ui/core";
import React from "react";
import PlanService from "./../../../services/plans";
import _ from "lodash";
import Snackbar from "../../common/Snackbar";

class AffordableHealthPlan extends React.Component {

    state = {
        premium: 0,
        old_premium: 0,
        isValidPersonalInfo: {
            premium: undefined
        },
        loading: false,
        showSnackbar: false,
        snackBarMessage: "",
        messageType: "",
    }

    handleCloseSnackbar = () => {
        this.setState({ showSnackbar: false })
    }

    componentDidMount() {
        this.loadDetails()
    }

    onBlur = (name, value) => {
        let isValidPersonalInfo = { ...this.state.isValidPersonalInfo };
        if (value === "") {
            isValidPersonalInfo[name] = false;
        }
        else {
            isValidPersonalInfo[name] = true;
        }
        this.setState({ isValidPersonalInfo })
    }

    loadDetails = async () => {

        let affordable_health_plan_price = await PlanService.getPlanPrices("health");
        let premium = _.get(affordable_health_plan_price, "data.data.health_affordable_health_plan.premium", 0)
        let old_premium = _.get(affordable_health_plan_price, "data.data.health_affordable_health_plan.premium", 0)

        this.onBlur("premium", premium);
        this.setState({ premium, old_premium })
    }

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    saveAffordableHealthPlan = async () => {
        this.setState({ loading: true })
        let status = false;
        try {
            let response = await PlanService.updatePlanPremium("health", "Affordable Health Plan", { premium: this.state.premium })
            if (response.data.status) {
                status = true
            }

        } catch (error) { }

        this.setState({
            loading: false,
            showSnackbar: true,
            snackBarMessage: status ? "Affordable Health premium updated." : "Something went wrong.",
            messageType: status ? "success" : "danger"
        }, this.loadDetails);

    }

    render() {
        return (
            <React.Fragment>
                <Grid item xs={12} md={4} sm={6}>
                    <Snackbar
                        showSnackbar={this.state.showSnackbar}
                        messageType={this.state.messageType}
                        snackBarMessage={this.state.snackBarMessage}
                        onCloseSnackbar={this.handleCloseSnackbar}
                    />
                    <Paper className="sq-shadow p-2">
                        <Typography><strong>Affordable Health Plan</strong></Typography>
                        <br />
                        <TextField
                            type="number"
                            fullWidth
                            value={this.state.premium}
                            label="Premium" variant="outlined"
                            onBlur={e => { this.onBlur("premium", e.target.value) }}
                            name="premium"
                            onChange={this.handleChange}
                        />
                        <br />
                        <br />
                        <Button
                            className="mr-2"
                            onClick={this.saveAffordableHealthPlan}
                            disabled={!this.state.isValidPersonalInfo.premium || this.state.loading || this.state.old_premium === this.state.premium}
                            variant="contained" color="primary">
                            {this.state.loading ? "Processing..." : "Save"}
                        </Button>
                        <Button onClick={this.loadDetails}
                            disabled={this.state.loading || this.state.old_premium === this.state.premium}
                            variant="contained" >
                            Reset
                        </Button>

                    </Paper>
                </Grid>
            </React.Fragment>
        )
    }
}
export default AffordableHealthPlan;