import React from "react";
import _ from "lodash";
import Navigation from "../common/Navigation";
import { Grid, TextField, MenuItem, Button, Table, TableHead, TableBody, TableRow, TableCell, Paper, Typography, TablePagination } from "@material-ui/core";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import PolicyService from "../../services/policy";
import Eye from "mdi-react/EyeIcon";
import { Link, withRouter } from "react-router-dom";
import { saveAs } from "file-saver";
import AffiliateSelect from "../common/AffiliateSelect";
import helpers from "../../helpers";
import { REPORT_SOB_OPTIONS, REPORT_STATUS_OPTIONS } from "../../constants";
import moment from "moment";
import BranchList from "../../services/branches.json";
import RegionList from "../../services/region.json";
import MotorService from "../../services/motor-mis";
import AffiliateService from "../../services/affiliates";

const months = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"]

class ConsolidateReport extends React.Component {
  state = {
    application: "self",
    startDate: null,
    endDate: null,
    showTable: false,
    data: [],
    page: 0,
    rowsPerPage: 10,
    totalRecords: 0,
    headings: [],
    isLoading: false,
    isDownloading: false,
    status: "All",
    loadedPercentage: 0,
    sob : "All",
    region : "All",
    branch : "All",
    affiliates : [],
    selected_affiliate : undefined,
    motor_data : []
  }
  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    });
  }

  handleStartDateChange = (date) => {
    const startDate = new Date(date.setHours(0, 0, 0, 0)).getTime();
    this.setState({ startDate });
  }

  handleEndDateChange = (date) => {
    const endDate = new Date(date.setHours(23, 59, 59, 999)).getTime();
    this.setState({ endDate });
  }

  componentDidMount() {
    let date = new Date().setHours(23, 59, 59, 999);
    let endDate = new Date(date).getTime();
    
    this.setState({
      startDate: new Date(new Date().setHours(0, 0, 0, 0)).getTime(),
      endDate
    });
    this.getAffiliates()
  }

  getAffiliates = async () => {
    const affiliates = await AffiliateService.getAllAffiliates();
    this.setState({ affiliates : affiliates.data }, () => {
      const center_id = sessionStorage.getItem("center_id");

      if(center_id){
        const selected_affiliate = this.state.affiliates.find((affiliate) => affiliate.affiliateId === center_id);
        this.setState({ selected_affiliate })
      }
    })
  }

  handleChangePage = (e, page) => {
    this.setState({ page }, this.handleSearch);
  }

  handleChangeRowsPerPage = (e) => {
    this.setState({ rowsPerPage: e.target.value }, this.handleSearch);
  }

  getCompanyAffiliate = (affiliate) => {
    let parent_affiliates = _.get(affiliate, "parents", []) ? affiliate.parents : [];
    let company_affiliate;
    if (parent_affiliates && Array.isArray(parent_affiliates)) {
      parent_affiliates.reverse();
      for (const parent_affiliate_id of parent_affiliates) {
        company_affiliate = this.state.affiliates.find((user) => user.affiliateId === parent_affiliate_id);
        if (_.get(company_affiliate, "affiliateSubType", false) == "Company") {
          return company_affiliate;
        }
      }
    }
    return;
  }

  handleSearch = async () => {
    this.setState({ isLoading: true });
    const status = this.state.status === "All" ? undefined : this.state.status;
    const sob = this.state.sob === "All" ? undefined : this.state.sob;
    const region = this.state.region === "All" ? undefined : this.state.region;
    const branch = this.state.branch === "All" ? undefined : this.state.branch;
    const selected_affiliate = this.state.selected_affiliate;

    const data = await PolicyService.getConsolidateDashPolicies({
      startDate : this.state.startDate,
      endDate : this.state.endDate,
      status,
      page : this.state.page,
      limit : this.state.rowsPerPage,
      sob : sob,
      region : region,
      branch : branch
    });

    let company_id, employee_id, employee_ids = [];

    if(selected_affiliate){
      let company_affiliate;

      if(selected_affiliate.affiliateSubType === "Company"){
        company_affiliate = selected_affiliate;
      }else{
        company_affiliate = this.getCompanyAffiliate(selected_affiliate);
      }

      const child_affiliates_res = await AffiliateService.getChildAffiliates(selected_affiliate.affiliateId);
      const child_affiliates = child_affiliates_res.data.data;

      if(company_affiliate){
        company_id = company_affiliate.accountCode;
        
        if(selected_affiliate.affiliateSubType !== "Company"){
          employee_ids.push(selected_affiliate.employeeID)
          if(child_affiliates.length){
            child_affiliates.forEach((affiliate) => {
              employee_ids.push(affiliate.employeeID);
            })
          }
        }
        employee_id = employee_ids.length ? JSON.stringify(employee_ids) : null
      }
      
    }

    const motorRes = await MotorService.getPolicies({
      start_date : moment(this.state.startDate).format("DD-MMMM-YYYY"),
      end_date : moment(this.state.endDate).format("DD-MMMM-YYYY"),
      company_code : company_id,
      employee_id : employee_id,
      send_data : true
    });
    
    this.setState({
      motor_data : motorRes.data.data,
      motor_total_records : motorRes.data.totalRecords
    })
    
    if (data.status === 200) {
      this.setState({ showTable: true, data: data.data.data, totalRecords: data.data.count })
    }

    if(motorRes.data.totalRecords > 0 || data.data.count > 0){
      this.setState({ show_download_button : true })
    }

    let headings = [
        "Sr No", 
        "Insurance Type",
        "SOB",
        "Affiliate name",
        "Approval Date",
        "Coverage From",
        "Coverage To",
        "Policy Number", 
        "Card Number", 
        "Civil ID", 
        "Name Of policy holder",
        "DOB",
        "Gender",
        "Marital Status",
        "Nationality",
        "Email ID",
        "Contact No",
        "Application Type",
        "Net Premium",
        "Discount Code",
        "Discount",
        "Policy Fee",
        "Emergency Fund Fee",
        "Goverment Fee",
        "Gross Premium",
        "VAT",
        "Loading",
        "Plan Name",
        "Status",
        "Payment Status",
        "Payment Date",
        "Policy Status"
    ];
    headings.push( "Company", "Partner Branch Name", "Channel Name", "Sub Channel Name", "Employee ID", "Employee Name", "Employee EmailID", "Employee Designation", "Location/Branch-Id", "Location/Branch-Name" ,"Zone/Region" ,"Reporting Manager" ,"Reporting Mgr email id" ,"Employee Contact No." ,"Comment", "View");
    this.setState({ isLoading: false, headings });
  }

  getFormattedDate = (date) => {
    return moment(date).format("DD-MM-YYYY");
  }

  getStartDate = (data) => {

    const {
      startDate,
      insurance_type,
      travel_start_date,
      plan,
      inbound_travel_start_date 
    } = data;

    if(insurance_type === "travel"){
      if(plan === "inbound_travel"){
        return this.getFormattedDate(inbound_travel_start_date)
      }else{
        return travel_start_date;
      }
    }else{
      return this.getFormattedDate(startDate);
    }
  }

  getExpiryDate = (data) => {

    const {
      startDate,
      cover_type,
      insurance_type,
      travel_end_date,
      plan,
      inbound_travel_end_date 
    } = data;

    if(insurance_type === "travel"){
      if(plan === "inbound_travel"){
        return this.getFormattedDate(inbound_travel_end_date)
      }else{
        return travel_end_date;
      }
    }else{
      let numYears = 1;
      if (["Unified Domestic Worker", "Personal Accident"].includes(cover_type)) {
        numYears = 2
      }
  
      return moment(startDate).subtract(1, "day").add(numYears, "year").format("DD-MM-YYYY");
    }
  }

  getDOB = (data) => {

    const {
      personalInformation,
      insurance_type,
      plan,
      travellers
    } = data;
    if(insurance_type === "travel"){
      if(travellers && travellers[0]){
        return moment(travellers[0].dob, "DD-MM-YYYY").format("DD-MMM-YYYY")
      }
      else{
        return moment(personalInformation.dob, "DD-MM-YYYY").format("DD-MMM-YYYY")
      }
    }
    else{
      return moment(personalInformation.dob).isValid() ? personalInformation.dob : "-"
    }

  }

  downloadData = async () => {
    this.setState({ isDownloading: true, showProcessPopup: true });
    
    const status = this.state.status === "All" ? undefined : this.state.status;
    const sob = this.state.sob === "All" ? undefined : this.state.sob;
    const region = this.state.region === "All" ? undefined : this.state.region;
    const branch = this.state.branch === "All" ? undefined : this.state.branch;

    const res = await PolicyService.getConsolidateDashPolicies({
      startDate : this.state.startDate,
      endDate : this.state.endDate,
      status,
      sob : sob,
      region : region,
      branch : branch
    });
    
    const data = res.data.data;
    let csvArr = [];
    let newHeadings = [...this.state.headings];
    newHeadings.splice(-1, 1);
    csvArr.push(newHeadings.join(","));
    data.forEach((policy, ind) => {
      const maritalStatus = policy.personalInformation.isMarried === "yes" ? "Married" : "Single";
      const startDate = this.getStartDate(policy);
      const endDate = this.getExpiryDate(policy);
      const base_price = policy.amount ? helpers.getBasePrice(policy.amount) : 0;
      const discount_code = policy.amount ? helpers.getDiscountCode(policy.amount) : 0;
      const discount = policy.amount ? helpers.getDiscount(policy.amount) : "-";
      const policy_fee = policy.amount ? policy.amount.policy_fee : 0;
      const emergency_fund_fee = policy.amount ? policy.amount.emergency_fund_fee : 0;
      const government_fee = policy.amount ? policy.amount.government_fee : 0;
      const amount = policy.amount ? policy.amount.total : 0;
      const paymentStatus = policy.payments && policy.payments.isPaid ? "Paid" : "Unpaid";
      const paymentDate = policy.payments && policy.payments.isPaid ? this.getFormattedDate(policy.payments.payment_done_at) : "-";
      const bmiValue = _.get(policy.amount, "bmi_value", 0)
      const vatValue = _.get(policy.amount, "vat", 0)
      const approveDate = _.get(policy, "payments.payment_done_at", false) ? 
      this.getFormattedDate(policy.payments.payment_done_at) : 
      (_.get(policy, "active_at", false) ? this.getFormattedDate(policy.active_at) : "-");

      let arr = [
        ind + 1,
        _.get(policy, "insurance_type", "-"),
        _.get(policy, "sob", "-"),
        _.get(policy, "affiliate_name", "-"),
        approveDate,
        startDate,
        endDate,
        policy._id,
        policy.policy_num,
        policy.civil_id,
        policy.personalInformation.fullName,
        this.getDOB(policy),
        policy.personalInformation.gender,
        maritalStatus,
        policy.nationality,
        policy.personalInformation.email,
        policy.personalInformation.mobileNumber,
        policy.applicationType,
        base_price,
        discount_code,
        discount,
        policy_fee,
        emergency_fund_fee,
        government_fee,
        amount,
        vatValue,
        bmiValue,
        policy.plan,
        _.get(policy, "status", "-"),
        paymentStatus,
        paymentDate,
        policy.status,
        policy.company_affiliate_name ? policy.company_affiliate_name : "-",
        policy.affiliate_partner_name ? policy.affiliate_partner_name : "-",
        policy.channel_name ? policy.channel_name : "-",
        policy.sub_channel_name ? policy.sub_channel_name : "-",
        policy.affiliate_employee_id ? policy.affiliate_employee_id : "-",
        policy.affiliate_name ? policy.affiliate_name : "AXSS",
        policy.affiliate_email ? policy.affiliate_email : "-",
        policy.affiliate_employee_designation ? policy.affiliate_employee_designation : "-",
        policy.branch_id ? policy.branch_id : "-",
        policy.branch_name ? policy.branch_name : "-",
        policy.region_name ? policy.region_name : "-",
        policy.branch_manager_name ? policy.branch_manager_name : "-",
        policy.branch_manager_email ? policy.branch_manager_email : "-",
        policy.affiliate_phone ? policy.affiliate_phone : "-",
        policy.comment ? `"${policy.comment}"` : "-"
      ];
      csvArr.push(arr.join(","));
    });

    const motorPolicies = this.state.motor_data;

    motorPolicies.forEach((policy, ind) => {

      const company_id = policy.ULM_AGENT_ID;
      const employee_id = policy.ULM_APU;

      const affiliates = [...this.state.affiliates];
      let company_affiliate,
      selected_affiliate,
      branch_id = "-",
      branch_name = "-",
      region_name = "-",
      manager_affiliate;

      if(company_id){
        company_affiliate = affiliates.find((affiliate) => affiliate.accountCode === company_id);
        if(company_affiliate && employee_id){

          selected_affiliate = affiliates.find((affiliate) => affiliate.parents.includes(company_affiliate.affiliateId) && affiliate.employeeID === employee_id)
        } 
      }else{
        if(employee_id){
          selected_affiliate = affiliates.find((affiliate) => affiliate.employeeID === employee_id)
        }
      }

      if(selected_affiliate){

        manager_affiliate = affiliates.find((affiliate) => affiliate.affiliateId === selected_affiliate.parent_id);

        branch_id = selected_affiliate.employeeBranchID;

        const selected_branch = BranchList.find((branchObj) => branchObj.id === selected_affiliate.employeeBranchID);
        if(selected_branch){
          branch_name = selected_branch.name;
        }

        const selected_region = RegionList.find((regionObj) => regionObj.id === selected_affiliate.region_id);
        if(selected_region){
          region_name = selected_region.name;
        }
      }

      let arr = [
        data.length + ind + 1,
        "Motor",
        "-",
        selected_affiliate ? selected_affiliate.name : "-",
        moment(policy.APPRV_DATE).format("DD-MM-YYYY"),
        moment(policy.COVERAGE_FROM_DATE).format("DD-MM-YYYY"),
        moment(policy.COVERAGE_TO_DATE).format("DD-MM-YYYY"),
        policy.POLICY_NUMER,
        policy.POLICY_NUMER,
        policy.CIVIL_ID,
        policy.INSURED_NAME,
        moment(policy.ULMI_DOB).format("DD-MMM-YYYY"),
        policy.ULMI_GENDER === "F" ? "Female" : "Male",
        "-",
        policy.ULMI_NATIONALITY,
        policy.ULMI_EMAIL_ID,
        policy.ULMI_MOBILE_NO,
        "-",
        policy.NET_PREMIUM,
        "-",
        "-",
        "-",
        "-",
        "-",
        policy.GROSS_PREMIUM,
        policy.CHARGES,
        "-",
        policy.PRODUCT,
        "-",
        "Paid",
        "-",
        "-",
        company_affiliate ? company_affiliate.name : "-",
        "-",
        "-",
        "-",
        policy.ULM_APU || "-",
        selected_affiliate ? selected_affiliate.name : "-",
        selected_affiliate ? selected_affiliate.email : "-",
        selected_affiliate ? selected_affiliate.designation : "-",
        branch_id,
        branch_name,
        region_name,
        manager_affiliate ? manager_affiliate.name : "-",
        manager_affiliate ? manager_affiliate.email : "-",
        selected_affiliate ? selected_affiliate.phone : "-",
        "-"
      ];
      csvArr.push(arr.join(","));
    });

    const csvFile = new Blob([csvArr.join("\n")], { type: "text/csv" });
    saveAs(csvFile, "consolidate-dash.csv");
    this.setState({ isDownloading: false, showProcessPopup: false });
  }

  handleAffiliateSelectChange = (value) => {
    const affiliates = [...this.state.affiliates];
    const affiliate = affiliates.find((affiliate) => affiliate.affiliateId === value);

    this.setState({ selected_affiliate : affiliate })
  }

  downloadDataBankFormat = async () => {
    this.setState({ isDownloadingBankFormat: true });
    
    const status = this.state.status === "All" ? undefined : this.state.status;
    const sob = this.state.sob === "All" ? undefined : this.state.sob;
    const region = this.state.region === "All" ? undefined : this.state.region;
    const branch = this.state.branch === "All" ? undefined : this.state.branch;

    const res = await PolicyService.getConsolidateDashPolicies({
      startDate : this.state.startDate,
      endDate : this.state.endDate,
      status,
      sob : sob,
      region : region,
      branch : branch
    });
    
    const data = res.data.data;
    let csvArr = [];
    let bank_format_headings = [
      "APPROVAL_DATE",
      "LOB",
      "PRODUCT",
      "POLICY_NUMBER",
      "QUOTE_NUMBER",
      "ENDORSEMENT_ID",
      "ENDORSEMENT_NUMBER",
      "PREMIUM_TRANSACTION_TYPE",
      "CIVIL_ID_PASSPORT",
      "INSURED_NAME",
      "INSURED_DOB",
      "MOBILE_NO",
      "BANK_BRANCH",
      "BANK_EMP ID",
      "BANK_EMP",
      "COVERAGE_PERIOD",
      "PREMIUM",
      "CHARGES",
      "COMMISSION",
      "APPROVER_USER_ID",
      "APPROVER_NAME",
      "PARTNER_NAME"
    ];
    
    csvArr.push(bank_format_headings.join(","));
    data.forEach((policy, ind) => {
      const base_price = policy.amount ? helpers.getBasePrice(policy.amount) : 0;
      const policy_fee = policy.amount ? policy.amount.policy_fee : 0;
      const emergency_fund_fee = policy.amount ? policy.amount.emergency_fund_fee : 0;
      const government_fee = policy.amount ? policy.amount.government_fee : 0;
      const paymentStatus = policy.payments && policy.payments.isPaid ? "Paid" : "Unpaid";
      const bmiValue = _.get(policy.amount, "bmi_value", 0)
      const vatValue = _.get(policy.amount, "vat", 0)
      const approveDate = _.get(policy, "payments.payment_done_at", false) ? 
      this.getFormattedDate(policy.payments.payment_done_at) : 
      (_.get(policy, "active_at", false) ? this.getFormattedDate(policy.active_at) : "-");

      const commissionPercentage = _.get(policy, "affiliate_id", false) && policy.commission ? parseInt(policy.commission) : 0;
      let commissionAmount = "0.000";
      if (!isNaN(commissionPercentage) && commissionPercentage > 0 && policy.payments && policy.payments.isPaid) {
        commissionAmount = Number((base_price * commissionPercentage) / 100).toFixed(2)
      }

      let coverage_days = 365;

      if(["Unified Domestic Worker", "Personal Accident"].includes(policy.cover_type)){
        coverage_days = 365 * 2
      }

      if(policy.insurance_type === "travel"){
        if(policy.plan === "inbound_travel"){
          coverage_days = policy.intbound_generate_quotation_request ? policy.intbound_generate_quotation_request.CumulativeDays : 365;
        }else{
          coverage_days = policy.outbound_generate_quotation_request ? policy.outbound_generate_quotation_request.CumulativeDays : 365;
        }
      }

      let arr = [
        approveDate,
        _.get(policy, "sob", "-"),
        _.get(policy, "insurance_type", "-"),
        policy._id,
        "-",
        "-",
        "-",
        paymentStatus === "Paid" ? policy.payments.mode : "-",
        policy.civil_id || policy.personalInformation.passport,
        policy.personalInformation.fullName,
        this.getDOB(policy),
        policy.personalInformation.mobileNumber,
        policy.branch_name ? policy.branch_name : "-",
        policy.affiliate_employee_id ? policy.affiliate_employee_id : "-",
        policy.affiliate_name ? policy.affiliate_name : "-",
        `${coverage_days} Days`,
        base_price,
        (policy_fee + emergency_fund_fee + government_fee + vatValue + bmiValue),
        commissionAmount,
        policy.affiliate_employee_id ? policy.affiliate_employee_id : "-",
        policy.affiliate_name ? policy.affiliate_name : "-",
        "-"
      ];
      csvArr.push(arr.join(","));
    });

    const motorPolicies = this.state.motor_data;

    motorPolicies.forEach((policy, ind) => {

      const company_id = policy.ULM_AGENT_ID;
      const employee_id = policy.ULM_APU;

      const affiliates = [...this.state.affiliates];
      let company_affiliate,
      selected_affiliate,
      branch_id = "-",
      branch_name = "-",
      region_name = "-",
      manager_affiliate;

      if(company_id){
        company_affiliate = affiliates.find((affiliate) => affiliate.accountCode === company_id);
        if(company_affiliate && employee_id){

          selected_affiliate = affiliates.find((affiliate) => affiliate.parents.includes(company_affiliate.affiliateId) && affiliate.employeeID === employee_id)
        } 
      }else{
        if(employee_id){
          selected_affiliate = affiliates.find((affiliate) => affiliate.employeeID === employee_id)
        }
      }

      if(selected_affiliate){

        manager_affiliate = affiliates.find((affiliate) => affiliate.affiliateId === selected_affiliate.parent_id);

        branch_id = selected_affiliate.employeeBranchID;

        const selected_branch = BranchList.find((branchObj) => branchObj.id === selected_affiliate.employeeBranchID);
        if(selected_branch){
          branch_name = selected_branch.name;
        }

        const selected_region = RegionList.find((regionObj) => regionObj.id === selected_affiliate.region_id);
        if(selected_region){
          region_name = selected_region.name;
        }
      }

      const coverage_preiod = moment(policy.COVERAGE_TO_DATE).diff(moment(policy.COVERAGE_FROM_DATE), "days") + " Days"

      let arr = [
        moment(policy.APPRV_DATE).format("DD-MM-YYYY"),
        "-",
        policy.PRODUCT,
        policy.POLICY_NUMER,
        policy.QUOTE_NUMER,
        policy.ENDORSEMENT_ID,
        policy.ENDORSEMENT_NUMBER,
        policy.COLLECTION_METHOD || "-",
        policy.CIVIL_ID,
        policy.INSURED_NAME,
        moment(policy.ULMI_DOB).format("DD-MM-YYYY"),
        policy.ULMI_MOBILE_NO,
        branch_name,
        selected_affiliate ? selected_affiliate.employeeID : "-",
        selected_affiliate ? selected_affiliate.name : "-",
        coverage_preiod,
        policy.GROSS_PREMIUM,
        policy.CHARGES,
        "-",
        selected_affiliate ? selected_affiliate.employeeID : "-",
        selected_affiliate ? selected_affiliate.name : "-",
        "-"
      ];
      csvArr.push(arr.join(","));
    });

    const csvFile = new Blob([csvArr.join("\n")], { type: "text/csv" });
    saveAs(csvFile, "consolidate-dash-bank-format.csv");
    this.setState({ isDownloadingBankFormat: false, showProcessPopup: false });
  }

  render() {
    return (
      <div className="d-flex">
        <Navigation />
        <div className="container-fluid">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Paper className="p-2 sq-shadow d-flex">
                <Typography><Link to={`/home`}>Home</Link></Typography>
                <Typography className="mr-2 ml-2">/</Typography>
                <Typography>Consolidate Report</Typography>
              </Paper>
            </Grid>

            <Grid item md={3} xs={12}>
              <AffiliateSelect onChange={this.handleAffiliateSelectChange} />
            </Grid>
            <Grid item md={3} xs={12}>
              <TextField
                variant="outlined"
                select
                label="Source of Business"
                fullWidth
                name="sob"
                onChange={this.handleChange}
                value={this.state.sob}
                SelectProps={{
                  MenuProps: {
                    anchorOrigin: {
                      vertical: "top",
                      horizontal: "left"
                    },
                    getContentAnchorEl: null
                  },
                }}
              >
                {
                  Object.values(REPORT_SOB_OPTIONS).map((option, index) => <MenuItem key={index} value={option}>{option}</MenuItem>)
                }
              </TextField>
            </Grid>
            <Grid item md={3} xs={12}>
              <TextField
                variant="outlined"
                select
                label="Region"
                fullWidth
                name="region"
                onChange={this.handleChange}
                value={this.state.region}
                SelectProps={{
                  MenuProps: {
                    anchorOrigin: {
                      vertical: "top",
                      horizontal: "left"
                    },
                    getContentAnchorEl: null
                  },
                }}
              >
                <MenuItem value="All">All</MenuItem>
                {
                  RegionList.map((region) => 
                    <MenuItem key={region.id} value={region.id}>{region.name}</MenuItem>
                  )
                }
              </TextField>
            </Grid>
            <Grid item md={3} xs={12}>
              <TextField
                variant="outlined"
                select
                label="Branch"
                fullWidth
                name="branch"
                onChange={this.handleChange}
                value={this.state.branch}
                SelectProps={{
                  MenuProps: {
                    anchorOrigin: {
                      vertical: "top",
                      horizontal: "left"
                    },
                    getContentAnchorEl: null
                  },
                }}
              >
                <MenuItem value="All">All</MenuItem>
                {
                  BranchList.map((branch) => 
                    <MenuItem key={branch.id} value={branch.id}>{branch.name}</MenuItem>
                  )
                }
              </TextField>
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={3}>
              <TextField
                variant="outlined"
                select
                label="Status"
                fullWidth
                name="status"
                onChange={this.handleChange}
                value={this.state.status}
                SelectProps={{
                  MenuProps: {
                    anchorOrigin: {
                      vertical: "top",
                      horizontal: "left"
                    },
                    getContentAnchorEl: null
                  },
                }}
              >
                {
                  Object.values(REPORT_STATUS_OPTIONS).map((option, index) => <MenuItem key={index} value={option}>{option}</MenuItem>)
                }

              </TextField>
            </Grid>

            <Grid item xs={3}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                  label="Start Date"
                  inputVariant="outlined"
                  animateYearScrolling
                  name="startDate"
                  clearable
                  fullWidth
                  value={this.state.startDate}
                  onChange={this.handleStartDateChange}
                  format="dd-MM-yyyy"
                />
              </MuiPickersUtilsProvider>
            </Grid>

            <Grid item xs={3}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                  label="End Date"
                  inputVariant="outlined"
                  animateYearScrolling
                  name="endDate"
                  clearable
                  fullWidth
                  value={this.state.endDate}
                  onChange={this.handleEndDateChange}
                  format="dd-MM-yyyy"
                />
              </MuiPickersUtilsProvider>
            </Grid>

            <Grid item xs={3} className="d-flex align-items-center">
              <Button variant="contained" color="primary" disabled={this.state.isLoading} onClick={this.handleSearch}>{this.state.isLoading ? "Searching . . ." : "Search"}</Button>
            </Grid>
          </Grid>

          {
            (this.state.showTable && this.state.data.length > 0) &&
            <Grid container spacing={2} className="d-flex">
              <Grid item xs={12}>
                <Paper className="sq-shadow overflow-auto">
                  <div className="dash-table-container">
                    <Table>
                      <TableHead>
                        <TableRow>
                          {
                            this.state.headings.map((heading, ind) =>
                              <TableCell key={ind}>{heading}</TableCell>
                            )
                          }
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {
                          this.state.data.map((data, ind) => {
                            return (
                              <TableRow key={ind}>
                                <TableCell>{ind + 1}</TableCell>
                                <TableCell className="text-capitalize">{_.get(data, "insurance_type", "-")}</TableCell>
                                <TableCell className="white-space-pre">{_.get(data, "sob", "-")}</TableCell>
                                <TableCell >{_.get(data, "affiliate_name", "-")}</TableCell>
                                <TableCell className="white-space-pre">
                                    { 
                                        _.get(data, "payments.payment_done_at", false) ? 
                                        this.getFormattedDate(data.payments.payment_done_at) : 
                                        (_.get(data, "active_at", false) ? this.getFormattedDate(data.active_at) : "-")
                                    }
                                </TableCell>
                                <TableCell className="white-space-pre" padding="checkbox">{this.getStartDate(data)}</TableCell>
                                <TableCell className="white-space-pre" padding="checkbox">{this.getExpiryDate(data)}</TableCell>
                                <TableCell className="white-space-pre">{data._id}</TableCell>
                                <TableCell className="white-space-pre">{data.policy_num}</TableCell>
                                <TableCell>{data.civil_id}</TableCell>
                                <TableCell>{data.personalInformation.fullName}</TableCell>
                                <TableCell>{this.getDOB(data)}</TableCell>
                                <TableCell className="text-capitalize"> {data.personalInformation.gender} </TableCell>
                                <TableCell>{(data.personalInformation.isMarried === "yes" ? "Married" : "Single")}</TableCell>
                                <TableCell>{data.nationality}</TableCell>
                                <TableCell>{data.personalInformation.email}</TableCell>
                                <TableCell>{data.personalInformation.mobileNumber}</TableCell>
                                <TableCell className="text-capitalize"> {data.applicationType} </TableCell>
                                <TableCell>{data.amount ? helpers.getBasePrice(data.amount) : 0} OMR</TableCell>
                                <TableCell>{data.amount ? helpers.getDiscountCode(data.amount) : "-"}</TableCell>
                                <TableCell>{data.amount ? helpers.getDiscount(data.amount) : 0} OMR</TableCell>
                                <TableCell>{data.amount ? data.amount.policy_fee : 0} OMR</TableCell>
                                <TableCell>{data.amount ? data.amount.emergency_fund_fee : 0} OMR</TableCell>
                                <TableCell>{data.amount ? data.amount.government_fee : 0} OMR</TableCell>
                                <TableCell>{data.amount ? data.amount.total : 0} OMR</TableCell>
                                <TableCell>{data.amount ? data.amount.vat : 0} OMR</TableCell>
                                <TableCell>{data.amount ? data.amount.bmi_value : 0} OMR</TableCell>
                                <TableCell> {data.plan} </TableCell>
                                <TableCell> {_.get(data, "status", "-")} </TableCell>
                                <TableCell> {data.payments && data.payments.isPaid ? "Paid" : "Unpaid"} </TableCell>
                                <TableCell> {data.payments && data.payments.isPaid ? this.getFormattedDate(data.payments.payment_done_at) : "-"} </TableCell>
                                <TableCell> {data.status} </TableCell>
                                {/* {
                                  ["Active"].includes(this.state.status) &&
                                  <>
                                    <TableCell>
                                      {
                                        data.payments.mode === "Link" ? "Card" : data.payments.mode
                                      }
                                    </TableCell>
                                    <TableCell>
                                      {
                                        data.payments.mode !== "Cash" ? data.payments.payment_id : "-"
                                      }
                                    </TableCell>
                                  </>
                                } */}
                                <TableCell>{data.company_affiliate_name ? data.company_affiliate_name : "-"}</TableCell>
                                <TableCell>{data.affiliate_partner_name ? data.affiliate_partner_name : "-"}</TableCell>
                                <TableCell>{data.channel_name ? data.channel_name : "-"}</TableCell>
                                <TableCell>{data.sub_channel_name ? data.sub_channel_name : "-"}</TableCell>
                                <TableCell>{data.affiliate_employee_id ? data.affiliate_employee_id : "-"}</TableCell>
                                <TableCell>{data.affiliate_name ? data.affiliate_name : "AXSS"}</TableCell>
                                <TableCell>{data.affiliate_email ? data.affiliate_email : "-"}</TableCell>
                                <TableCell>{data.affiliate_employee_designation ? data.affiliate_employee_designation : "-"}</TableCell>
                                <TableCell>{data.branch_id ? data.branch_id : "-"}</TableCell>
                                <TableCell>{data.branch_name ? data.branch_name : "-"}</TableCell>
                                <TableCell>{data.region_name ? data.region_name : "-"}</TableCell>
                                <TableCell>{data.branch_manager_name ? data.branch_manager_name : "-"}</TableCell>
                                <TableCell>{data.branch_manager_email ? data.branch_manager_email : "-"}</TableCell>
                                <TableCell>{data.affiliate_phone ? data.affiliate_phone : "-"}</TableCell>
                                <TableCell>{data.comment ? data.comment : "-"}</TableCell>
                                <TableCell>
                                  <Link to={`/policies/${data._id}`} className="table-eye-link">
                                    <Eye />
                                  </Link>
                                </TableCell>
                              </TableRow>
                            )
                          })
                        }
                        <TableRow>
                          <TablePagination
                            rowsPerPage={this.state.rowsPerPage}
                            rowsPerPageOptions={[10, 25, 50]}
                            page={this.state.page}
                            count={this.state.totalRecords}
                            colSpan={0}
                            onChangePage={this.handleChangePage}
                            onChangeRowsPerPage={this.handleChangeRowsPerPage}
                          />
                        </TableRow>
                      </TableBody>
                    </Table>
                  </div>
                </Paper>
              </Grid>


            </Grid>
          }
          {
            this.state.show_download_button &&
            <div className="mt-3">
              <Button
                onClick={this.downloadData}
                variant="contained"
                color="primary"
                disabled={this.state.isDownloading}
              >
                {this.state.isDownloading ? "Downloading . . ." : "Download csv"}
              </Button>

              <Button
                onClick={this.downloadDataBankFormat}
                variant="contained"
                color="primary"
                disabled={this.state.isDownloadingBankFormat}
                className="ml-3"
              >
                {this.state.isDownloadingBankFormat ? "Downloading . . ." : "Download in Bank Format"}
              </Button>
            </div>
          }
          {
            (this.state.showTable && this.state.data.length === 0 && this.state.motor_total_records === 0) &&
            <Paper className="q-shadow mt-3 pt-5 pb-5 d-flex align-items-center justify-content-center">
              <Typography variant="h5"> No Data Found </Typography>
            </Paper>
          }
        </div>
      </div>
    )
  }
}
export default withRouter(ConsolidateReport);