import React from "react";
import {Card, TextField, Grid, Button, InputAdornment, IconButton, Typography} from "@material-ui/core";
import {withRouter, Link} from "react-router-dom";
import AuthService from "../../services/auth";
import { Email } from "@material-ui/icons";
import Snackbar from "../common/Snackbar";

class ForgotPassword extends React.Component{

  state = {
    email : "",
    showMailError : false,
    isLoading : false,
    showSnackbar : false
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({ isLoading : true });
    AuthService.sendResetMail(this.state.email).then((res) => {
      if(res.mailSent){
        this.setState({
          isLoading : false,
          showSnackbar : true,
          snackBarMessage : "Password reset mail sent to " + this.state.email,
          messageType : "success"
        })
      }
    }).catch((e) => {
      this.setState({
        isLoading : false,
        showSnackbar : true,
        snackBarMessage : e.message,
        messageType : "danger"
      })
    })
  }

  isValidMail = () => {
    const value = this.state.email;
    const atpos = value.indexOf("@");
    const dotpos = value.lastIndexOf(".");
    if(atpos < 1 || dotpos < atpos + 2 || dotpos + 2 >= value.length || value === "")
      return false;
    else
      return true;
  }
  
  onBlurMail = () => {
    this.setState({showMailError : !this.isValidMail() });
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name] : e.target.value,
      showMailError : false,
      showPasswordError : false,
      showError : false
    })
  }

  handleCloseSnackbar = () => {
    this.setState({ showSnackbar : false });
  }

  render(){
    return(
      <Grid container className="d-flex align-items-center justify-content-center h-100">
        <Snackbar
          showSnackbar = { this.state.showSnackbar }
          messageType = {this.state.messageType}
          snackBarMessage = {this.state.snackBarMessage}
          onCloseSnackbar = {this.handleCloseSnackbar}
        />
        <Grid item md={3} sm={6} xs={11}>
          <form onSubmit={this.handleSubmit}>
            <Card className="p-4">
              <div>
                <TextField
                  variant = "outlined"
                  margin = "normal"
                  label = "Email"
                  fullWidth
                  name = "email"
                  onChange = {this.handleChange}
                  onBlur = {this.onBlurMail}
                  error = {this.state.showMailError}
                  helperText = {this.state.showMailError ? "Please enter valid email" : ""}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton edge="end" tabIndex="-1">
                          <Email />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
              <Button
                variant = "contained"
                color="primary"
                fullWidth
                type="submit"
                size="large"
                className="submit-button mt-3"
                disabled = {!this.isValidMail() || this.state.isLoading}
              >
                Send reset password mail
              </Button>
              <Typography variant="body2" className="text-center mt-3">
                <Link to="/login">Back to login</Link>
              </Typography>
            </Card>
          </form>
        </Grid>
      </Grid>
    )
  }
}

export default withRouter(ForgotPassword);