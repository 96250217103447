import { Dialog, DialogTitle, DialogActions, DialogContent, Button, Accordion, AccordionDetails, AccordionSummary, MenuItem, Grid, Paper, TextField, Typography, IconButton } from "@material-ui/core";
import React from "react";
import { Delete, Edit, ExpandMore } from "@material-ui/icons";
import PlanService from "./../../../services/plans";
import PropTypes from "prop-types";
import _ from "lodash";
import Snackbar from "../../common/Snackbar";
import DeleteConfirmDialog from "../../common/DeleteConfirmDialog";

class TravelPricePlan extends React.Component {

    state = {
        premium: [{
            from: 0,
            to: 18,
            premium: []
        }],
        ages: [],
        worldwide: ["including", "excluding"],
        selected: {
            age: "",
            worldwide: ""
        },
        loading: false,
        showSnackbar: false,
        snackBarMessage: "",
        messageType: "",
        open: false,
        days: undefined,
        selectedPremium: {},
        savedPremium: undefined,
        deleteDialog: false
    }

    handleCloseSnackbar = () => {
        this.setState({ showSnackbar: false })
    }

    componentDidMount() {
        this.loadDetails()
    }

    constructor(props) {
	    super(props);
	    this.plan_type = props.plan_type;
    }

    loadDetails = async () => {
        this.setState({ loading: true });
        let travel_plan_price = await PlanService.getPlanPrices("travel");
        let plans = Object.values(_.get(travel_plan_price, "data.data", {}));
        let premium = plans.find(plan => plan.insurance_type === "travel" && plan.plan_type === this.plan_type)
        premium = _.get(premium, "premium", this.state.premium)
        let ages = [];
        for (const key in premium) {
            ages.push(`${premium[key].from}-${premium[key].to}`)
        }
        let savedPremium = JSON.stringify(premium);
        this.setState({ savedPremium, premium, ages, loading: false })
    }

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    handleChangeSelected = (e) => {
        let selected = { ...this.state.selected };
        selected[e.target.name] = e.target.value;
        this.setState({ selected })
    }

    openPriceDialog = (days) => {
        let premium = [..._.get(this.state, "premium", [])];
        premium = premium.find(details => {
            return parseInt(this.state.selected.age.split("-")[0]) === parseInt(details.from)
                && parseInt(this.state.selected.age.split("-")[1]) === parseInt(details.to)
        })
        this.setState({
            days: days,
            open: true,
            selectedPremium: _.get(premium, "premium", {})
        })
    }

    onClose = (save, response) => {
        if (save) {
            let premium = [..._.get(this.state, "premium", [])];
            premium = premium.map(details => {
                if (parseInt(this.state.selected.age.split("-")[0]) === parseInt(details.from)
                    && parseInt(this.state.selected.age.split("-")[1]) === parseInt(details.to)) {
                    details.premium = response
                }
                return details;
            })

            this.setState({ premium })
        
        }

        this.setState({
            days: false,
            open: false,
            selectedPremium: {}
        })
    }

    confirmDelete = async (days) => {
        this.setState({ deleteDialog: true, days: days });
    };

    onCloseDeleteDialog = async (confirm) => {
        if (confirm === true) {
            let premium = [..._.get(this.state, "premium", [])];
            premium = premium.map(details => {
                if (parseInt(this.state.selected.age.split("-")[0]) === parseInt(details.from)
                    && parseInt(this.state.selected.age.split("-")[1]) === parseInt(details.to)) {
                    delete details.premium[this.state.days];
                }
                return details;
            })

            this.setState({ premium, deleteDialog: false, days: false })
        } else {
            this.setState({ deleteDialog: false, days: false });
        }
    };

    savePremium = async () => {
        this.setState({ loading: true })
        let status = false;
        let message = false;

        try {
            let response = await PlanService.updatePlanPremium("travel", this.plan_type, { premium: this.state.premium })
            if (response.data.status) {
                status = true
                message = `${this.plan_type} plan updated successfully.`;
            }else{
                message = _.get(response.data, "message", "Something went wrong.");
            }

        } catch (error) { }

        this.setState({
            loading: false,
            showSnackbar: true,
            snackBarMessage: message ? message : "Something went wrong.",
            messageType: status ? "success" : "danger"
        }, this.loadDetails);
    }

    checkIsChanged = () => {
        try {
            return this.state.savedPremium !== JSON.stringify(this.state.premium);
        } catch (error) { }
        return false;
    }

    render() {
        return (
            <React.Fragment>
                <Grid item xs={12}>
                    <Snackbar
                        showSnackbar={this.state.showSnackbar}
                        messageType={this.state.messageType}
                        snackBarMessage={this.state.snackBarMessage}
                        onCloseSnackbar={this.handleCloseSnackbar}
                    />
                    <DeleteConfirmDialog
                        onClose={this.onCloseDeleteDialog}
                        open={this.state.deleteDialog}
                        processing={false}
                    />
                    <Accordion expanded={true}>
                        <AccordionSummary expandIcon={<ExpandMore />} >
                            <Typography>{this.plan_type} Plan</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={4}>
                                    <TextField
                                        label="Age Limit"
                                        variant="outlined"
                                        margin="normal"
                                        select
                                        fullWidth
                                        className="mt-1"
                                        name="age"
                                        onChange={this.handleChangeSelected}
                                        value={this.state.selected.age}
                                    >
                                        <MenuItem value="">Select Age Limit</MenuItem>
                                        {
                                            Array.isArray(this.state.ages) &&
                                            this.state.ages.map((value, ind) => (
                                                <MenuItem key={ind} value={value}>{value}</MenuItem>
                                            ))
                                        }
                                    </TextField>
                                    <TextField
                                        label="Worldwide"
                                        variant="outlined"
                                        margin="normal"
                                        select
                                        fullWidth
                                        className="mt-1"
                                        name="worldwide"
                                        onChange={this.handleChangeSelected}
                                        value={this.state.selected.worldwide}
                                    >
                                        <MenuItem value="">Select Worldwide</MenuItem>
                                        {
                                            Array.isArray(this.state.worldwide) &&
                                            this.state.worldwide.map((value, ind) => (
                                                <MenuItem key={ind} value={value} className="text-capitalize">{value}</MenuItem>
                                            ))
                                        }
                                    </TextField>
                                    {
                                        (Array.isArray(this.state.premium)
                                            && this.state.premium.length
                                            && this.state.selected.age !== ""
                                            && this.state.selected.worldwide !== "") &&
                                        <Button onClick={e => { this.openPriceDialog(false) }} color="primary" variant="contained" className="float-right">Add</Button>
                                    }
                                </Grid>
                                <Grid item xs={12} sm={8}>
                                    <DayPremiumDialog
                                        open={this.state.open}
                                        onClose={this.onClose}
                                        age={this.state.selected.age}
                                        worldwide={this.state.selected.worldwide}
                                        days={this.state.days}
                                        premium={this.state.selectedPremium}
                                    />
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th>Days</th>
                                                <th>Travel Code</th>
                                                <th>Price</th>
                                                <th>RI Code</th>
                                                <th>RI Premium</th>
                                                <th>Additional Premium</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                (Array.isArray(this.state.premium)
                                                    && this.state.premium.length
                                                    && this.state.selected.age !== ""
                                                    && this.state.selected.worldwide !== "")
                                                    ? this.state.premium.map((premium, premiumIndex) => {
                                                        return (
                                                            parseInt(this.state.selected.age.split("-")[0]) === parseInt(premium.from)
                                                            && parseInt(this.state.selected.age.split("-")[1]) === parseInt(premium.to)
                                                            && premium.premium && Array.isArray(Object.keys(premium.premium)) && Object.keys(premium.premium).length
                                                        ) ?
                                                            <React.Fragment key={premiumIndex}>
                                                                {
                                                                    Object.keys(premium.premium).map((dayPremium, ind) => {
                                                                        return <tr key={ind}>
                                                                            <td>{dayPremium}</td>
                                                                            <td>{_.get(premium, `premium.${dayPremium}.${this.state.selected.worldwide}.travel_code`, "-")}</td>
                                                                            <td>{_.get(premium, `premium.${dayPremium}.${this.state.selected.worldwide}.price`, "-")}</td>
                                                                            <td>{_.get(premium, `premium.${dayPremium}.${this.state.selected.worldwide}.ri_code`, "-")}</td>
                                                                            <td>{_.get(premium, `premium.${dayPremium}.${this.state.selected.worldwide}.ri_premium`, "-")}</td>
                                                                            <td>{_.get(premium, `premium.${dayPremium}.${this.state.selected.worldwide}.additional_premium`, "-")}</td>
                                                                            <td>
                                                                                <IconButton size="small" onClick={e => { this.openPriceDialog(dayPremium) }}> <Edit /> </IconButton>
                                                                                <IconButton size="small" onClick={() => this.confirmDelete(dayPremium)}> <Delete /> </IconButton>
                                                                            </td>
                                                                        </tr>
                                                                    })
                                                                }
                                                            </React.Fragment>
                                                            : <React.Fragment key={premiumIndex}></React.Fragment>
                                                    }) : <tr>
                                                        <td colSpan={4} className="text-sm-secondary text-center">No data found.</td>
                                                    </tr>
                                            }
                                        </tbody>
                                    </table>

                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>

                </Grid>
                <Grid item xs={12}>
                    <Paper className="sq-shadow p-2 text-right">
                        <Button onClick={this.loadDetails} variant="outlined" className="mr-2">
                            {this.state.loading ? "Processing..." : "Reset"}
                        </Button>
                        <Button onClick={this.savePremium} disabled={this.state.loading || !this.checkIsChanged()} variant="contained" color="primary">
                            {this.state.loading ? "Processing..." : "Save"}
                        </Button>
                    </Paper>
                </Grid>
            </React.Fragment>
        )
    }
}

class DayPremiumDialog extends React.Component {

    state = {
        oldDays: false,
        age: "",
        worldwide: "",
        days: 0,
        excluding_travel_code: 0,
        excluding_price: 0,
        excluding_ri_code: 0,
        excluding_ri_premium: 0,
        excluding_additional_premium: 0,
        including_travel_code: 0,
        including_price: 0,
        including_ri_code: 0,
        including_ri_premium: 0,
        including_additional_premium: 0,
        premium: {},
        otherPremiums: {},
        isInValid: {
            days: undefined,
            excluding_travel_code: undefined,
            excluding_price: undefined,
            excluding_ri_code: undefined,
            excluding_ri_premium: undefined,
            excluding_additional_premium: undefined,
            including_travel_code: undefined,
            including_price: undefined,
            including_ri_code: undefined,
            including_ri_premium: undefined,
            including_additional_premium: undefined
        },
        open: false
    }

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    constructor(props) {
        super(props);
    }

    componentWillReceiveProps(props) {
        this.onClose = props.onClose;
        let open = props.open;

        let age = props.age;
        let worldwide = props.worldwide;
        let premium = props.premium;

        let oldDays = props.days;
        let values = { ...premium[props.days] };
        let days = oldDays === false ? 0 : oldDays;
        let excluding_travel_code = _.get(values, `excluding.travel_code`, 0);
        let excluding_price = _.get(values, `excluding.price`, 0);
        let excluding_ri_code = _.get(values, `excluding.ri_code`, 0);
        let excluding_ri_premium = _.get(values, `excluding.ri_premium`, 0);
        let excluding_additional_premium = _.get(values, `excluding.additional_premium`, 0);
        let including_travel_code = _.get(values, `including.travel_code`, 0);
        let including_price = _.get(values, `including.price`, 0);
        let including_ri_code = _.get(values, `including.ri_code`, 0);
        let including_ri_premium = _.get(values, `including.ri_premium`, 0);
        let including_additional_premium = _.get(values, `including.additional_premium`, 0);

        let otherPremiums = { ...premium };
        if (otherPremiums[props.days]) delete otherPremiums[props.days]

        this.setState({
            open, age, worldwide,
            otherPremiums, oldDays, days,
            excluding_travel_code,
            excluding_price,
            excluding_ri_code,
            excluding_ri_premium,
            excluding_additional_premium,
            including_travel_code,
            including_price,
            including_ri_code,
            including_ri_premium,
            including_additional_premium,
            premium
        }, () => {
            let isInValid = { ...this.state.isInValid };
            if (oldDays !== false) {
                isInValid.days = this.onBlurDays(this.state.days);
                isInValid.excluding_travel_code = this.onBlur("excluding_travel_code", this.state.excluding_travel_code);
                isInValid.excluding_price = this.onBlur("excluding_price", this.state.excluding_price);
                isInValid.excluding_ri_code = this.onBlur("excluding_ri_code", this.state.excluding_ri_code);
                isInValid.excluding_ri_premium = this.onBlur("excluding_ri_premium", this.state.excluding_ri_premium);
                isInValid.excluding_additional_premium = this.onBlur("excluding_additional_premium", this.state.excluding_additional_premium);
                isInValid.including_travel_code = this.onBlur("including_travel_code", this.state.including_travel_code);
                isInValid.including_price = this.onBlur("including_price", this.state.including_price);
                isInValid.including_ri_code = this.onBlur("including_ri_code", this.state.including_ri_code);
                isInValid.including_ri_premium = this.onBlur("including_ri_premium", this.state.including_ri_premium);
                isInValid.including_additional_premium = this.onBlur("including_additional_premium", this.state.including_additional_premium);
            } else {
                isInValid = {
                    days: undefined,
                    excluding_travel_code: undefined,
                    excluding_price: undefined,
                    excluding_ri_code: undefined,
                    excluding_ri_premium: undefined,
                    excluding_additional_premium: undefined,
                    including_travel_code: undefined,
                    including_price: undefined,
                    including_ri_code: undefined,
                    including_ri_premium: undefined,
                    including_additional_premium: undefined
                };
            }
            this.setState({ isInValid });
        })
    }

    isValid = () => {
        return (
            this.state.isInValid.days === false
            && this.state.isInValid.excluding_travel_code === false
            && this.state.isInValid.excluding_price === false
            && this.state.isInValid.excluding_ri_code === false
            && this.state.isInValid.excluding_ri_premium === false
            && this.state.isInValid.excluding_additional_premium === false
            && this.state.isInValid.including_travel_code === false
            && this.state.isInValid.including_price === false
            && this.state.isInValid.including_ri_code === false
            && this.state.isInValid.including_ri_premium === false
            && this.state.isInValid.including_additional_premium === false
        )
    }

    handleClose = () => {
        this.onClose(false)
    }

    saveDetails = () => {
        if (this.isValid()) {
            let premium = { ...this.state.premium };

            if (this.state.oldDays !== false) {
                delete premium[this.state.days];
            }
            premium[this.state.days] = {
                excluding: { price: this.state.excluding_price, travel_code: this.state.excluding_travel_code, ri_code: this.state.excluding_ri_code, ri_premium: this.state.excluding_ri_premium, additional_premium: this.state.excluding_additional_premium },
                including: { price: this.state.including_price, travel_code: this.state.including_travel_code, ri_code: this.state.including_ri_code, ri_premium: this.state.including_ri_premium, additional_premium: this.state.including_additional_premium }
            }

            let days = Object.keys(premium);
            days = days.sort((a, b) => a - b);

            let response = {};
            for (const key in days) {
                response[days[key]] = premium[days[key]];
            }

            this.onClose(true, response)
        }
    }

    onBlurDays = (value) => {
        let isInValid = { ...this.state.isInValid };
        if (value <= 0) {
            isInValid.days = "Enter valid days.";
        } else if (value > 365) {
            isInValid.days = "Days must not be more than 365.";
        } else if (this.state.otherPremiums[value]) {
            isInValid.days = "Days already exist.";
        } else {
            isInValid.days = false;
        }
        this.setState({ isInValid })
        return isInValid.days;
    }

    onBlur = (key, value) => {
        let isInValid = { ...this.state.isInValid };
        if (value > 0) {
            isInValid[key] = false;
        } else {
            isInValid[key] = "Enter valid value.";
        }
        this.setState({ isInValid })
        return isInValid[key];
    }

    getField = (field, label, type = "text") => {
        return <TextField
            type={type}
            className="mt-2"
            fullWidth
            label={label}
            value={this.state[field]}
            variant="outlined"
            name={field}
            onBlur={(e) => { this.onBlur(e.target.name, e.target.value) }}
            onChange={this.handleChange}
            error={typeof this.state.isInValid[field] === "string"}
            helperText={typeof this.state.isInValid[field] === "string" ? this.state.isInValid[field] : ""}
        />
    }

    render() {
        return (<Dialog fullWidth={true} onClose={this.handleClose} aria-labelledby="simple-dialog-title" open={this.state.open}>
            <DialogTitle id="simple-dialog-title">
                {this.state.oldDays === false ? "Add Price" : "Update Price"}
            </DialogTitle>
            <DialogContent>
                <TextField
                    type="number"
                    fullWidth
                    label="Days"
                    value={this.state.days}
                    variant="outlined"
                    name="days"
                    onChange={this.handleChange}
                    onBlur={(e) => { this.onBlurDays(e.target.value) }}
                    error={typeof this.state.isInValid.days === "string"}
                    helperText={typeof this.state.isInValid.days === "string" ? this.state.isInValid.days : ""}
                />
                <Grid container spacing="2" className="mt-2">
                    <Grid item sm={12} md={6}>
                        <Typography>Worldwide including</Typography>
                        {this.getField("including_price", "Price", "number")}
                        {this.getField("including_travel_code", "Travel Code", "number")}
                        {this.getField("including_ri_code", "RI Code", "number")}
                        {this.getField("including_ri_premium", "RI Premium", "number")}
                        {this.getField("including_additional_premium", "Additional Premium", "number")}
                    </Grid>
                    <Grid item sm={12} md={6}>
                        <Typography>Worldwide excluding</Typography>
                        {this.getField("excluding_price", "Price", "number")}
                        {this.getField("excluding_travel_code", "Travel Code", "number")}
                        {this.getField("excluding_ri_code", "RI Code", "number")}
                        {this.getField("excluding_ri_premium", "RI Premium", "number")}
                        {this.getField("excluding_additional_premium", "Additional Premium", "number")}
                    </Grid>
                </Grid>

            </DialogContent>
            <DialogActions>
                <Button variant="contained" size="small" onClick={this.handleClose}>
                    Cancel
                </Button>
                <Button variant="contained" disabled={!this.isValid()} color="primary" size="small"
                    onClick={this.saveDetails.bind(this, true)}>
                    Save
                </Button>
            </DialogActions>
        </Dialog>)
    }
}

DayPremiumDialog.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    age: PropTypes.any,
    worldwide: PropTypes.any,
    day: PropTypes.any,
    premium: PropTypes.any
}


export default TravelPricePlan;