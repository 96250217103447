import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import Login from "./components/Login";
import Home from "./components/Home";
import Policies from "./components/Policies";
import Policy from "./components/Policy";
import DomesticWorkersReport from "./components/DomesticWorkersReport";
import IndividualMedicalReport from "./components/IndividualMedicalReport";
import Analysis from "./components/Analysis";
import LifeInsuranceReport from "./components/LifeInsuranceReport";
import RiyatiReport from "./components/RiyatiReport";
import Settings from "./components/Settings";
import ForgotPassword from "./components/ForgotPassword";
import HomeSa from "./components/HomeSa";
import ShowAffiliates from "./components/Affiliates/ShowAffiliates";
import TransferPolicy from "./components/TransferPolicy";
import EditPolicy from "./components/EditPolicy";
import CreateAffiliator from "./components/CreateAffiliator";
import HomeInsuranceReport from "./components/HomeInsuranceReport";
import OutboundTravelReport from "./components/OutboundTravelReport";
import Targets from "./components/Targets";
import Channel from "./components/Channel";
import CreateChannel from "./components/CreateChannel";
import ModifyPremiums from "./components/ModifyPremiums";
import ReferPolicies from "./components/ReferPolicies";
import ReferPolicyView from "./components/ReferPolicyView";
import ConsolidateReport from "./components/ConsolidateReport";
import CreditLimits from "./components/CreditLimits";
import DiscountList from "./components/Discounts/DiscountList";
import DiscountCreate from "./components/Discounts/DiscountCreate";
import DiscountView from "./components/Discounts/DiscountView";
import InboundTravelReport from "./components/InboundTravelReport";
import AffiliateApp from "./components/AffiliateApp";
import CreateAffiliateApp from "./components/CreateAffiliateApp";
import AffiliateAppShow from "./components/AffiliateAppShow";
import MotorReport from "./components/MotorReport";
import ManageAccess from "./components/ManageAccess";
import AccessForm from "./components/ManageAccess/AccessForm";

class Routes extends React.Component {
  render() {
    return (
      <Router>
        <Route exact path="/" component={Home} />
        <Route exact path="/home" component={Home} />
        <Route exact path="/affiliator" component={HomeSa} />
        <Route path="/forgot-password" component={ForgotPassword} />
        <Route path="/login" component={Login} />
        <Route path="/settings" component={Settings} />

        <Route exact path="/policies" component={Policies} />
        <Route exact path="/refer-policies" component={ReferPolicies} />
        <Route exact path="/refer-policies/:id" component={ReferPolicyView} />
        <Route exact path="/affiliator/targets" component={Targets} />
        <Route exact path="/discounts" component={DiscountList} />
        <Route exact path="/discounts/create" component={DiscountCreate} />
        <Route exact path="/discounts/edit/:id" component={DiscountCreate} />
        <Route exact path="/discount/:id" component={DiscountView} />
        <Route exact path="/affiliator/channel" component={Channel} />
        <Route exact path="/affiliator/channel/create" component={CreateChannel} />
        <Route exact path="/affiliator/channel/edit/:id" component={CreateChannel} />
        <Route exact path="/affiliator/create" component={CreateAffiliator} />
        <Route exact path="/affiliator/edit/:id" component={CreateAffiliator} />
        <Route exact path="/modify-premiums/:insurance_type?/:plan_type?" component={ModifyPremiums} />
        <Route exact path="/credit-limits" component={CreditLimits} />
        <Route exact path="/policies/:id" component={Policy} />
        <Route path="/domestic-workers-report" component={DomesticWorkersReport} />
        <Route path="/individual-medical-report" component={IndividualMedicalReport} />
        <Route path="/life-insurance-report" component={LifeInsuranceReport} />
        <Route path="/home-insurance-report" component={HomeInsuranceReport} />
        <Route path="/outbound-travel-report" component={OutboundTravelReport} />
        <Route path="/inbound-travel-report" component={InboundTravelReport} />
        <Route path="/riyati-report" component={RiyatiReport} />
        <Route path="/consolidate-report" component={ConsolidateReport} />
        <Route path="/motor-report" component={MotorReport} />
        <Route path="/analysis" component={Analysis} />
        <Route path="/affiliates/:id" component={ShowAffiliates} />
        <Route exact path="/policies/transfer/:id" component={TransferPolicy} />
        <Route exact path="/policies/edit/:id" component={EditPolicy} />
        <Route exact path="/affiliator/dhofar-api-kit" component={AffiliateApp} />
        <Route exact path="/affiliator/dhofar-api-kit/create" component={CreateAffiliateApp}/>
        <Route exact path="/affiliator/dhofar-api-kit/show/:affiliate_id/:app_id" component={AffiliateAppShow}/>
        <Route exact path="/manage-access" component={ManageAccess}/>
        <Route exact path="/manage-access/create" component={AccessForm}/>
        <Route exact path="/manage-access/edit/:id" component={AccessForm}/>
      </Router>
    )
  }
}

export default Routes;