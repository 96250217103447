import React from "react";
import { Button } from "@material-ui/core"
import { useEffect, useState } from "react";
import AffiliateService from "../../services/affiliates";
import _ from "lodash";
import { saveAs } from "file-saver";
import branch_list from "../../services/branches.json";
import regions_list from "../../services/region.json";

const DOWNLOADING_STEPS = {
  DOWNLOADING : "DOWNLOADING",
  PROCESSING : "PROCESSING",
}

const INSURANCE_TYPES = {
  health: "Health",
  life: "Life",
  travel: "Travel",
  home: "Home",
  riyati: "Riyati",
  motor : "Motor"
}

const PLAN_TYPES = {
  individual_medical: "Individual Medical",
  affordable_health_plan: "Affordable Health Plan",
  unified_domestic_worker: "Unified Domestic Worker",
  unified_health_insurance_plan: "Unified Health Insurance Plan",
  term_plan: "Term Plan",
  personal_accident: "Personal Accident",
  standard_travel: "Standard",
  lite_travel: "Lite",
  senior_travel: "Senior",
  covid_shield_travel: "Covid Shield",
  family_travel: "Family",
  inbound: "Inbound"
}

const DownloadAffiliates = () => {

  const [isDownloading, setDownloading] = useState(false);
  const [downloadingStep, setDownloadingStep] = useState("");
  const [stepText, setStepText] = useState("");

  const getCompanyAffiliate = (users, affiliate) => {
    let parent_affiliates = _.get(affiliate, "parents", []) ? affiliate.parents : [];
    let company_affiliate;
    if (parent_affiliates && Array.isArray(parent_affiliates)) {
      parent_affiliates.reverse();
      for (const parent_affiliate_id of parent_affiliates) {
        company_affiliate = users.find((user) => user.affiliateId === parent_affiliate_id);
        if (_.get(company_affiliate, "affiliateSubType", false) == "Company") {
          return company_affiliate;
        }
      }
    }
    return;
  }

  const downloadData = () => {

    setDownloading(true);
    setDownloadingStep(DOWNLOADING_STEPS.DOWNLOADING);

    const titles = [
      "SR No.",
      "Name",
      "Email",
      "Mobile Number",
      "Type",
      "Is Active",
      "INS Type - Health",
      "INS Type - Life",
      "INS Type - Travel",
      "INS Type - Home",
      "INS Type - Riyati",
      "INS Type - Motor",
      "Plan - Individual Medical",
      "Plan - Affordable Health Plan",
      "Plan - Unified Domestic Worker",
      "Plan - Unified Health Insurance Plan",
      "Plan - Term Plan",
      "Plan - Personal Accident",
      "Plan - Standard Travel",
      "Plan - Lite Travel",
      "Plan - Senior Travel",
      "Plan - Covid Shield Travel",
      "Plan - Family Travel",
      "Plan - Inbound Travel",
      "Role",
      "Under",
      "Affiliate ID",
      "Employee ID",
      "Source Of Business",
      "Company Name",
      "Company Account Code",
      "Region",
      "Branch",
      "Individual Medical Commission",
      "Affordable Health Plan Commission",
      "Unified Domesctic Worker Commission",
      "Unified Health Insurance Plan Commission",
      "Term Plan Commission",
      "Personal Accident Commission",
      "Standard Travel Commission",
      "Lite Travel Commission",
      "Covid Shield Travel Commission",
      "Senior Travel Commission",
      "Family Travel Commission",
      "Inbound Travel Commission",
      "Home Insurance Commission"
    ]

    let csvArr = [];
    csvArr.push(titles.join(","));

    AffiliateService.getAffiliates().then((res) => {
      const affiliatesData = res.data;
      setDownloadingStep(DOWNLOADING_STEPS.PROCESSING)

      affiliatesData.forEach((affiliate, ind) => {
        let company_parent = getCompanyAffiliate(affiliatesData, affiliate);
        
        if(company_parent){
          affiliate.company_name = _.get(company_parent, "name", null);
          affiliate.company_account_code = _.get(company_parent, "accountCode", null);
        }
  
        let under = "None";
        if(affiliate.parent_id){
          const parent_affiliate = affiliatesData.find((user) => user.affiliateId === affiliate.parent_id);
          if(parent_affiliate){
            under = parent_affiliate.name
          }
        }

        const company_name = affiliate.affiliateSubType === "Company" ? affiliate.name : affiliate.company_name;
        const company_account_code = affiliate.affiliateSubType === "Company" ? affiliate.accountCode : affiliate.company_account_code;

        const employeeRegionID = affiliate.employeeRegionID;
        const employeeBranchID = affiliate.employeeBranchID;
        
        const region = employeeRegionID ? regions_list.find((region) => region.id === employeeRegionID)?.name : "-";
        const branch = employeeBranchID ? branch_list.find((branch) => branch.id === employeeBranchID)?.name : "-";

        const getCommission = (commission_key) => {
          return affiliate[commission_key] || 0
        }

        const availableInsurance = affiliate.availableInsurance || []
        const availablePlans = affiliate.availablePlans || []

        const getInsuranceEnabled = (insurance_type) => {
          return availableInsurance.includes(insurance_type) ? "Enabled" : "Disabled";
        }

        const getPlanEnabled = (plan) => {
          return availablePlans.includes(plan) ? "Enabled" : "Disabled";
        }

        const affiliateType = affiliate.affiliateType
        let SOB = "";

        if (affiliateType == "Employee") {
          SOB = "Direct";
        } else if (affiliateType == "Bank") {
          SOB = "Banca";
        } else {
          SOB = "B2B";
        }

        const arr = [
          `${ind + 1}`,
          affiliate.name,
          affiliate.email,
          affiliate.phone,
          affiliateType,
          affiliate.isActive ? "YES" : "NO",
          getInsuranceEnabled(INSURANCE_TYPES.health),
          getInsuranceEnabled(INSURANCE_TYPES.life),
          getInsuranceEnabled(INSURANCE_TYPES.travel),
          getInsuranceEnabled(INSURANCE_TYPES.home),
          getInsuranceEnabled(INSURANCE_TYPES.riyati),
          getInsuranceEnabled(INSURANCE_TYPES.motor),
          getPlanEnabled(PLAN_TYPES.individual_medical),
          getPlanEnabled(PLAN_TYPES.affordable_health_plan),
          getPlanEnabled(PLAN_TYPES.unified_domestic_worker),
          getPlanEnabled(PLAN_TYPES.unified_health_insurance_plan),
          getPlanEnabled(PLAN_TYPES.term_plan),
          getPlanEnabled(PLAN_TYPES.personal_accident),
          getPlanEnabled(PLAN_TYPES.standard_travel),
          getPlanEnabled(PLAN_TYPES.lite_travel),
          getPlanEnabled(PLAN_TYPES.senior_travel),
          getPlanEnabled(PLAN_TYPES.covid_shield_travel),
          getPlanEnabled(PLAN_TYPES.family_travel),
          getPlanEnabled(PLAN_TYPES.inbound),
          affiliate.affiliateSubType,
          under,
          affiliate.affiliateId,
          affiliate.employeeID || "-",
          SOB,
          company_name,
          company_account_code,
          region,
          branch,
          getCommission("IMCommision"),
          getCommission("AHPCommision"),
          getCommission("UDWCommision"),
          getCommission("UHIPCommision"),
          getCommission("term_plan_commission"),
          getCommission("pa_commission"),
          getCommission("standard_travel_commission"),
          getCommission("lite_travel_commission"),
          getCommission("covid_shield_travel_commission"),
          getCommission("senior_travel_commission"),
          getCommission("family_travel_commission"),
          getCommission("inbound_travel_commission"),
          getCommission("home_commission"),
        ]
        csvArr.push(arr.join(","))
      })

      const csvFile = new Blob([csvArr.join("\n")], { type: "text/csv" });
      saveAs(csvFile, "affiliates-data.csv");
      setDownloading(false);
      setDownloadingStep("");

    })
  }

  useEffect(() => {
    let step_text = "Download Affiliates Data"
    if(downloadingStep === DOWNLOADING_STEPS.DOWNLOADING){
      step_text = "Downloading . . .";
    }
    if(downloadingStep === DOWNLOADING_STEPS.PROCESSING){
      step_text = "Processing . . .";
    }
    setStepText(step_text)
  }, [downloadingStep])
  return(
    <Button
      variant="contained"
      color="primary"
      onClick={downloadData}
      disabled = {isDownloading}
    >
      {stepText}
    </Button>
  )
}

export default DownloadAffiliates;