import React, { useState, useEffect } from 'react';
import { Document, Page, StyleSheet, View, Text, Image, Font } from "@react-pdf/renderer";
import Arabic from "../../static/fonts/Arabic/A_Nefel_Adeti.ttf";
import Header from "../../static/images/pa-schedule-header.jpg";
import Footer from "../../static/images/pa-schedule-footer.jpg";
import Stamp from "../../static/images/pa-schedule-signature.jpg";
import AffiliateService from "../../services/affiliates";
import branchList from "../../services/branches.json";

Font.register({ family: "Arabic", src: Arabic });

const styles = StyleSheet.create({
  page: {
    fontSize: 10,
    fontFamily: "Arabic",
    marginTop: 10
  },
  fontSize9: {
    fontSize: 9
  },
  container: {
    paddingHorizontal: 20,
    paddingVertical: 20
  },
  paddingLeft1: {
    paddingLeft: 2
  },
  paddingRight1: {
    paddingRight: 2
  },
  marginTop: {
    marginTop: 10
  },
  marginTop2: {
    marginTop: 20
  },
  marginBottom1: {
    marginBottom: 5
  },
  footerMarginBottom: {
    marginBottom: 50
  },
  headerImage: {
    width: "100%",
    paddingVertical: 5,
    paddingHorizontal: 20
  },
  border_right: {
    borderRight: "1pt solid black"
  },
  border_left: {
    borderLeft: "1pt solid black"
  },
  border_top: {
    borderTop: "1pt solid black"
  },
  border_bottom: {
    borderBottom: "1pt solid black"
  },
  borderAll: {
    border: "1pt solid black"
  },
  center: {
    textAlign: "center"
  },
  topTitle: {
    marginTop: 2,
    fontSize: 12
  },
  row: {
    display: "flex",
    flexDirection: "row"
  },
  detail_column_1: {
    width: "25%",
    paddingLeft: 5
  },
  detail_column_2: {
    justifyContent: "center",
    alignItems: "center",
    width: "3%"
  },
  detail_column_3: {
    width: "47%",
    paddingLeft: 5
  },
  detail_column_4: {
    width: "25%",
    paddingLeft: 5
  },
  description_head_column_1: {
    width: "37%",
    paddingLeft: 5
  },
  description_column_1: {
    width: "9%",
    paddingLeft: 5
  },
  description_column_2: {
    width: "28%",
    paddingLeft: 5
  },
  description_column_3: {
    width: "34%",
    paddingLeft: 5
  },
  description_column_4: {
    width: "9%",
    paddingLeft: 5
  },
  description_column_5: {
    width: "20%",
    paddingLeft: 5
  },
  top_column_1: {
    width: "27%",
    paddingLeft: 5
  },
  top_column_2: {
    justifyContent: "center",
    alignItems: "center",
    width: "3%"
  },
  top_column_3: {
    width: "70%",
    paddingLeft: 5
  },
  toMarLeft: {
    paddingLeft: 25
  },
  note_column_1: {
    width: "60%"
  },
  note_column_2: {
    width: "40%"
  },
  table_half_column: {
    width: "50%"
  },
  table_25_column: {
    width: "25%"
  },
  text_right: {
    textAlign: "right"
  },
  capitalize: {
    textTransform: "capitalize"
  },
  beneficiary_column_1: {
    width: "6%"
  },
  beneficiary_column_2: {
    width: "38%",
    paddingHorizontal: 5
  },
  beneficiary_column_3: {
    width: "12%"
  },
  beneficiary_column_4: {
    width: "8%"
  },
  beneficiary_column_5: {
    width: "12%"
  },
  beneficiary_column_6: {
    width: "12%"
  },
  beneficiary_column_7: {
    width: "12%"
  },
  cause_column_1: {
    width: "20%"
  },
  cause_column_2: {
    width: "3%"
  },
  cause_column_3: {
    width: "57%"
  },
  cause_column_4: {
    width: "20%"
  },
  footer: {
    width: "100%",
    paddingHorizontal: 20,
    position: "absolute",
    bottom: 10
  },
  stamp_row: {
    justifyContent: "flex-end"
  },
  stamp_container: {
    display: "flex",
    alignItems: "center",
  },
  stamp: {
    height: 100,
    width: 100
  },
});

const getCurrentDate = (dateMS) => {
  const newDate = new Date(dateMS);
  const date = newDate.getDate();
  const month = newDate.getMonth() + 1;
  const year = newDate.getFullYear();

  return `${date > 9 ? date : "0" + date} / ${month > 9 ? month : "0" + month} / ${year}`
}
const getExpiryDate = (dateMS) => {
  let numYears = 1;
  let newDate = new Date(dateMS);
  newDate.setDate(newDate.getDate() - 1);
  const date = newDate.getDate();
  const month = newDate.getMonth() + 1;
  const year = newDate.getFullYear() + numYears;

  return `${date > 9 ? date : "0" + date}/${month > 9 ? month : "0" + month}/${year}`
}

const HomeSchedulePDF = ({ data }) => {

  const {
    _id,
    startDate,
    personalInformation,
    buildingInformation,
    homeCoverageInformation,
    homeOwnerType,
    amount,
    affiliate_details
  } = data;

  const [branchName, setBranchName] = useState("");

  const {
    fullName
  } = personalInformation;

  const {
    address,
    type_of_building
  } = buildingInformation

  const {
    total
  } = amount

  const {
    section_1,
    section_2,
    section_3,
    section_4,
    section_5,
    section_6,
    section_7,
    section_8
  } = homeCoverageInformation;

  useEffect(() => {
    let branchName = "AXSS Bank";
    if(affiliate_details && affiliate_details.employeeBranchID && Array.isArray(branchList)){
      let branch = branchList.find(value => value.id == affiliate_details.employeeBranchID);
      if(branch){
        branchName = `${branch.id} - ${branch.name}`;
      }  
    }
    setBranchName(branchName);
  }, []);

  return (
    <Document creator="AXSS" producer="AXSS" title="HOME INSURANCE POLICY SCHEDULE">
      <Page style={styles.page}>
        <Image source={Header} style={styles.headerImage} fixed />
        <View style={styles.container}>
          <View style={[styles.topTitle, styles.center]}>
            <Text> HOME INSURANCE POLICY SCHEDULE </Text>
            <Text>جدول وثيقة تأمين المنزل</Text>
          </View>

          <View style={[styles.row, styles.border_top, styles.border_bottom, styles.border_left, styles.border_right]}>
            <View style={[styles.table_half_column, styles.row, styles.border_right]}>
              <View style={[styles.paddingLeft1, styles.table_25_column, styles.border_right]}>
                <Text> Branch &nbsp;:&nbsp; </Text>
              </View>
              <View style={[styles.paddingLeft1, styles.table_half_column, styles.border_right]}>
                <Text> {branchName} </Text>
              </View>
              <View style={[styles.paddingLeft1, styles.table_25_column, styles.text_right, styles.paddingRight1]}>
                <Text> {"الفرع"} </Text>
              </View>
            </View>
            <View style={[styles.table_half_column, styles.row, styles.border_right]}>
              <View style={[styles.paddingLeft1, styles.table_25_column, styles.border_right]}>
                <Text> Policy No &nbsp;:&nbsp; </Text>
              </View>
              <View style={[styles.paddingLeft1, styles.table_half_column, styles.border_right]}>
                <Text> {_id} </Text>
              </View>
              <View style={[styles.paddingLeft1, styles.table_25_column, styles.text_right, styles.paddingRight1]}>
                <Text> {"رقم الوثيقة"} </Text>
              </View>
            </View>
          </View>

          {[
            {
              key: "Policy Issued On",
              ar_key: "تاريخ إصدار الوثيقة",
              value: getCurrentDate(startDate)
            },
            {
              key: "Insured Name",
              ar_key: "المؤمن له",
              value: fullName
            },
            {
              key: "Postal Address",
              ar_key: "عنوان البريد",
              value: address
            }
          ].map((e, index) => {
            return (<View key={index} style={[styles.row, styles.border_bottom, styles.border_left, styles.border_right]}>
              <View style={[styles.detail_column_1, styles.border_right, styles.paddingLeft1]}>
                <Text> {e.key}</Text>
              </View>
              <View style={[styles.detail_column_2, styles.border_right, styles.paddingLeft1]}>
                <Text>:</Text>
              </View>
              <View style={[styles.detail_column_3, styles.border_right, styles.paddingLeft1]}>
                <Text> {e.value} </Text>
              </View>
              <View style={[styles.detail_column_4, styles.text_right, styles.paddingRight1]}>
                <Text> {e.ar_key} </Text>
              </View>
            </View>)
          })}

          <View style={[styles.row, styles.border_bottom, styles.border_left, styles.border_right]}>
            <View style={[styles.detail_column_1, styles.border_right, styles.paddingLeft1]}>
              <Text> Period Of Insurance   </Text>
            </View>
            <View style={[styles.detail_column_2, styles.border_right, styles.paddingLeft1]}>
              <Text>:</Text>
            </View>
            <View style={[styles.row, styles.detail_column_3, styles.border_right, styles.paddingLeft1]}>
              <View style={[styles.border_right]}>
                <Text> {"From  من"} &nbsp; &#58; &nbsp; {getCurrentDate(startDate)} </Text>
              </View>
              <View style={[styles.paddingLeft1]}>
                <Text> {"To إلى"} &nbsp; &#58; &nbsp; {getExpiryDate(startDate)} </Text>
              </View>
            </View>
            <View style={[styles.detail_column_4, styles.text_right, styles.paddingRight1]}>
              <Text> {"مدة التأمين"} </Text>
            </View>
          </View>

          {[
            {
              key: "Details Of Property Insured",
              ar_key: "تفاصيل الممتلكات المؤمن لها",
              value: type_of_building
            },
            {
              key: "Address Of The Property Insurance /Risk Location Address",
              ar_key: "عنوان الممتلكات المؤمن لها / عنوان الموقع",
              value: address
            },
            {
              key: "Premium(RO)",
              ar_key: "القسط",
              value: `OMR ${Number(total).toFixed(3)} (Inclusive of Taxes)`
            },
            {
              key: "Territorial Limits",
              ar_key: "حدود التغطية",
              value: "Sultanate of Oman"
            },
            {
              key: "Applicable Law & Jurisdiction",
              ar_key: "نطاق القضاء للقانون المطبق",
              value: "Sultanate of Oman"
            },
          ].map((e, ind) => {
            return (<View key={ind} style={[styles.row, styles.border_bottom, styles.border_left, styles.border_right]}>
              <View style={[styles.detail_column_1, styles.border_right, styles.paddingLeft1]}>
                <Text> {e.key}</Text>
              </View>
              <View style={[styles.detail_column_2, styles.border_right, styles.paddingLeft1]}>
                <Text>:</Text>
              </View>
              <View style={[styles.detail_column_3, styles.border_right, styles.paddingLeft1]}>
                <Text> {e.value} </Text>
              </View>
              <View style={[styles.detail_column_4, styles.text_right, styles.paddingRight1]}>
                <Text> {e.ar_key} </Text>
              </View>
            </View>)
          })}


          <View style={[styles.marginTop]}>
            <Text> ( Subject to all specified terms, conditions here under and in the policy form attached to this schedule)</Text>
            <Text> ({"مع مراعاة جميع الشروط المحددة ، الشروط هنا بموجب نموذج الوثيقة المرفقة بهذا الجدول"})</Text>
          </View>

          <View style={[styles.center, styles.borderAll, styles.marginTop]}>
            <Text>Description of Cover</Text>
          </View>

          <View style={[styles.row, styles.border_left, styles.border_right, styles.border_bottom]}>
            <View style={[styles.description_head_column_1, styles.center]}>
              <Text> Interest Covered</Text>
              <Text>{"فوائد التغطية"}</Text>
            </View>
            <View style={[styles.description_column_3, styles.border_left]}>
              <Text> Sum Insured /Policy Limits/Sub-Limits Currency : RO </Text>
              <Text> {" مبلغ التأمين /حدود الوثيقة/الحدود الجزئية"} </Text>
              <Text>{"العملة : الريال العماني "}</Text>
            </View>
            <View style={[styles.description_column_4, styles.border_left]}>
              <Text style={[styles.fontSize9]}> Required  </Text>
              <Text> {"إلزامي"} </Text>
            </View>
            <View style={[styles.description_column_5, styles.border_left]}>
              <Text> Deductible </Text>
              <Text> {"قابلة للخصم"} </Text>
            </View>
          </View>

          {[
            {
              section: "Section 1",
              interest_covered: ["1.1: Home Contents Cover", "تغطية محتويات المنزل"],
              sum_assured: [
                ` RO ${Number(section_1.sum_assured).toFixed(3)}`, "But", "RO 2,500/- Single Article Limit",
                "In respect of Personal Belongings & Valuables within the Home, the sub - limit is 20 % of Contents Sum Insured subject to RO 400 / - Single Article Limit"
              ],
              required: section_1.selected ? "Yes" : "No",
              deductible: section_1.selected ? ["Fire and AOG perils:", "5.000% of the claim subject to min of RO 50 For all other perils: RO 25"] : [""]
            },
            {
              section: "",
              interest_covered: ["1.2: Contents Temporarily Removed", "المحتويات المزالة مؤقتًا"],
              sum_assured: ["20% of Sum Insured"],
              required: section_1.selected ? "Yes" : "No",
              deductible: section_1.selected ? ["RO 50.000"]: [""]
            },
            {
              section: "",
              interest_covered: ["1.3: Replacement of Locks and Keys", "استبدال الأقفال والمفاتيح"],
              sum_assured: ["RO:200.000", "in the annual aggregate"],
              required: section_1.selected ? "Yes" : "No",
              deductible: section_1.selected ? [" RO 25.000"] : [""]
            },
            {
              section: "",
              interest_covered: ["1.4: Contents in Open", "المحتويات المكشوفة"],
              sum_assured: ["RO:250.000", "in the annual aggregate"],
              required: section_1.selected ? "Yes" : "No",
              deductible: section_1.selected ? [" RO 25.000"] : [""]
            },
            {
              section: "",
              interest_covered: ["1.5: Stock in Deep Freezer / Refrigerator", "المخزون المجمد \ الثلاجة"],
              sum_assured: ["RO:100.000", "in the annual aggregate"],
              required: section_1.selected ? "Yes" : "No",
              deductible: section_1.selected ? [" RO 10.000"] : [""]
            },
            {
              section: "Section 2",
              interest_covered: ["Accidental Damage to Contents", "تلف غير مقصود للمحتويات"],
              sum_assured: [` RO ${Number(0).toFixed(3)}`],
              required: section_2.selected ? "Yes" : "No",
              deductible: section_2.selected ? ["RO 50.000"] : [``]
            },
            {
              section: "Section 3",
              interest_covered: ["Personal Belongings, Valuables", "المتعلقات الشخصية والأشياء الثمينة"],
              sum_assured: [` RO ${Number(section_3.sum_assured).toFixed(3)}`, "RO 400/- Single Article Limit unless a higher amount with details of the item(s) is stated in the Schedule"],
              required: section_3.selected ? "Yes" : "No",
              deductible: section_3.selected ? ["RO 50.000"] : [""]
            },
          ].map((e, index) => {
            return (<View key={index} style={[styles.row, styles.border_left, styles.border_right, styles.border_bottom]}>
              <View style={[styles.description_column_1, styles.border_left]}>
                <Text>{e.section}</Text>
              </View>
              <View style={[styles.description_column_2, styles.border_left]}>
                {e.interest_covered.map((val, ind) => (<Text key={ind}>{val}</Text>))}
              </View>
              <View style={[styles.description_column_3, styles.border_left]}>
                {e.sum_assured.map((val, ind) => (<Text key={ind}>{val}</Text>))}
              </View>
              <View style={[styles.description_column_4, styles.border_left]}>
              <Text>{e.required}</Text>
              </View>
              <View style={[styles.description_column_5, styles.border_left]}>
                {e.deductible.map((val, ind) => (<Text key={ind}>{val}</Text>))}
              </View>
            </View>)
          })}

          <View break>
            {[
              {
                section: "Section 4",
                interest_covered: ["Personal Documents, Credit Card, Money", "المستندات الشخصية ، بطاقة الائتمان ، النقود"],
                sum_assured: ["RO:", "But",
                  "Money: RO 400/- in the aggregate", "Credit Card: RO 1,000/- in the aggregate", "Documents: RO 100/- per document, RO 250/- in the aggregate"
                ],
                required: section_4.selected ? "Yes" : "No",
                deductible: section_4.selected ? ["RO 25.000"] : [""]
              },
            ].map((e, index) => {
              return (<View key={index} style={[styles.row, styles.borderAll]}>
                <View style={[styles.description_column_1, styles.border_left]}>
                  <Text>{e.section}</Text>
                </View>
                <View style={[styles.description_column_2, styles.border_left]}>
                  {e.interest_covered.map((val, ind) => (<Text key={ind}>{val}</Text>))}
                </View>
                <View style={[styles.description_column_3, styles.border_left]}>
                  {e.sum_assured.map((val, ind) => (<Text key={ind}>{val}</Text>))}
                </View>
                <View style={[styles.description_column_4, styles.border_left]}>
                <Text>{e.required}</Text>
                </View>
                <View style={[styles.description_column_5, styles.border_left]}>
                  {e.deductible.map((val, ind) => (<Text key={ind}>{val}</Text>))}
                </View>
              </View>)
            })}
            {[
              {
                section: "Section 5",
                interest_covered: ["Building(s) Cover", "تغطية المباني"],
                sum_assured: [` RO ${Number(section_5.sum_assured).toFixed(3)}`],
                required: section_5.selected ? "Yes" : "No",
                deductible: section_5.selected ? ["Fire and AOG perils: 5% of the claim amount subject to min of RO 500", "All other perils: RO 250"] : [""]
              },
              {
                section: "Section 6",
                interest_covered: ["Liabilities Cover", "تغطية المتطلبات"],
                sum_assured: [""],
                required: "",
                deductible: [""]
              },
              {
                section: "",
                interest_covered: ["6.1: Personal Liability", "المسؤولية الشخصية"],
                sum_assured: [` RO ${Number(100000).toFixed(3)}`, "in the annual aggregate"],
                required: section_6.selected ? "Yes" : "No",
                deductible: [""]
              },
              {
                section: "",
                interest_covered: ["6.2.1: Liability as Tenant of the insured Home", "مسؤولية مستأجر المبنى المؤمن له "],
                sum_assured: [` RO ${Number(25000).toFixed(3)}`, "in the annual aggregate"],
                required: section_6.selected && homeOwnerType === "tenant" ? "Yes" : "No",
                deductible: section_6.selected && homeOwnerType === "tenant" ? ["RO 100 for third party property damage and NIL for bodily Injury"] : [""]
              },
              {
                section: "",
                interest_covered: ["6.2.2: Liability as Owner of the insured Building", "المسؤولية كمالك للمبنى المؤمن له"],
                sum_assured: [` RO ${Number(100000).toFixed(3)}`, "in the annual aggregate"],
                required: section_6.selected && homeOwnerType === "owner" ? "Yes" : "No",
                deductible: section_6.selected && homeOwnerType === "owner" ? ["RO 100 for third party property damage and NIL for bodily Injury"] : [""]
              },
              {
                section: "Section 7",
                interest_covered: ["Rent and Alternative Accommodation", "الايجار و محلات الإقامة البديلة"],
                sum_assured: ["12 months Indemnity, limited to 20% of Contents Sum Insured (as Tenant) or 10% of Building Sum Insured (as Owner)"],
                required: section_7.selected ? "Yes" : "No",
                deductible: section_7.selected ? ["First 3 days"] : [""]
              },
              {
                section: "Section 8",
                interest_covered: ["Death Cover", "تغطية الوفاة"],
                sum_assured: [` RO ${Number(5000).toFixed(3)}`, "But in the annual aggregate"],
                required: section_8.selected ? "Yes" : "No",
                deductible: section_8.selected ? ["NIL"] : [""]
              },
            ].map((e, index) => {
              return (<View key={index} style={[styles.row, styles.border_left, styles.border_right, styles.border_bottom]}>
                <View style={[styles.description_column_1, styles.border_left]}>
                  <Text>{e.section}</Text>
                </View>
                <View style={[styles.description_column_2, styles.border_left]}>
                  {e.interest_covered.map((val, ind) => (<Text key={ind}>{val}</Text>))}
                </View>
                <View style={[styles.description_column_3, styles.border_left]}>
                  {e.sum_assured.map((val, ind) => (<Text key={ind}>{val}</Text>))}
                </View>
                <View style={[styles.description_column_4, styles.border_left]}>
                <Text>{e.required}</Text>
                </View>
                <View style={[styles.description_column_5, styles.border_left]}>
                  {e.deductible.map((val, ind) => (<Text key={ind}>{val}</Text>))}
                </View>
              </View>)
            })}
          </View>


          <View style={[styles.row, styles.borderAll, styles.marginTop]}>
            <View style={[styles.cause_column_1, styles.border_left, styles.paddingLeft1]}>
              <Text> Clauses</Text>
            </View>
            <View style={[styles.cause_column_2, styles.border_left, styles.center]}>
              <Text>:</Text>
            </View>
            <View style={[styles.cause_column_3, styles.border_left, styles.paddingLeft1]}>
              <Text></Text>
            </View>
            <View style={[styles.cause_column_4, styles.border_left, styles.paddingLeft1, styles.text_right]}>
              <Text>{"البنود"}</Text>
            </View>
          </View>
          <View style={[styles.row, styles.border_left, styles.border_right, styles.border_bottom]}>
            <View style={[styles.cause_column_1, styles.border_left, styles.paddingLeft1]}>
              <Text> Dated</Text>
            </View>
            <View style={[styles.cause_column_2, styles.border_left, styles.center]}>
              <Text>:</Text>
            </View>
            <View style={[styles.cause_column_3, styles.border_left, styles.paddingLeft1]}>
              <Text>{getCurrentDate(startDate)}</Text>
            </View>
            <View style={[styles.cause_column_4, styles.border_left, styles.paddingLeft1, styles.text_right]}>
              <Text>{"التاريخ"}</Text>
            </View>
          </View>

          <View style={[styles.row]}>
            <View style={[styles.table_half_column]}></View>
            <View style={[styles.table_half_column, styles.center]}>
              <Text>Signed For and on behalf of</Text>
              <Text>{"التوقيع بالنيابة عن"}</Text>
              <Text>INSURANCE COMPANY(S.A.O.G)</Text>
              <Text>{"شركة ظفار للتأمين ش.م.ع.ع"}</Text>
              <View style={[styles.stamp_container]}>
                <Image source={Stamp} style={styles.stamp} />
              </View>
            </View>
          </View>

          <View style={[styles.row, styles.marginTop]}>
            <View style={[styles.table_half_column]}>
              <Text>Date : {getCurrentDate(startDate)}</Text>
            </View>
            <View style={[styles.table_half_column, styles.text_right]}>
              <Text>{"التاريخ"}</Text>
            </View>
          </View>
          {/* <View style={[styles.row, styles.marginTop]}>
            <View style={[styles.table_half_column]}>
              <Text>Prepared By : Wadha.A</Text>
            </View>
            <View style={[styles.table_half_column, styles.text_right]}>
              <Text>{"أعدت من قبل"}</Text>
            </View>
          </View> */}
          {/* <View style={[styles.row, styles.marginTop]}>
            <View style={[styles.table_half_column]}>
              <Text>Approved By : Wadha.A</Text>
            </View>
            <View style={[styles.table_half_column, styles.text_right]}>
              <Text>{"تم الإعتماد من قبل"}</Text>
            </View>
          </View> */}

          <View style={[styles.row, styles.marginTop, styles.border_bottom]}>
          </View>

          <View style={[styles.footerMarginBottom]}></View>

        </View>
        <View fixed>
          <Image source={Footer} style={styles.footer} />
        </View>
      </Page>
    </Document>
  )
}

export default HomeSchedulePDF;