import React from "react";
import Navigation from "../common/Navigation";
import {Grid, Paper, Typography, Divider, Button } from "@material-ui/core";
import {Link, withRouter} from "react-router-dom";
import { GetApp, Delete } from "@material-ui/icons";
import PolicyService from "../../services/policy";
import { saveAs } from "file-saver";
import DownloadService from "../../services/download";
import JSZip from "jszip";
import Snackbar from "../common/Snackbar";
import $ from "jquery";
import HtmlToCanvas from "html2canvas";
import Download from "../../static/images/vc-download.png";
import DhofarLogo from "../../static/images/dhofar-logo.jpg";
import NextCare from "../../static/images/nextcare-logo.png";

class ReferPolicyView extends React.Component{

  state = {
    policy : {},
    items : [],
    showSnackbar : false,
    beneficiaries : [],
    showDomPdf : false,
    showDeleteDocumentDialog : false,
    selectedDocument : "",
    isDeletingDocument : false,
    showPaymentReceipt : false
  }

  getData = async () => {
    const id = this.props.match.params.id;
    const data = await PolicyService.getReferalPolicyById(id);
    const policy = data.data;
    const beneficiaries = policy.beneficiaries || [];
    this.setState({
      policy_id : id,
      data : policy,
      beneficiaries,
      insurance_type : policy.insurance_type
    }, () => {
      this.setState({
        showPdf : this.state.beneficiaries.length > 0,
        showDomPdf : this.state.data && (this.state.data.applicationType === "domestic" ),
        showPaymentReceipt : true,
        showTravelPdf : true,
        showHomePdf : true
      })
    });

  }

  componentDidMount(){
    const center = this.props.match.params.center;
    this.setState({ center })
    this.getData();
  }

  downloadFile = (image) => {
    const title = image.title.split(" ").join("-");
    const url = image.url;
    const fileExt = url.substr(url.lastIndexOf("."));
    const _id = this.state.data._id;
    const fileName = `${(_id === "" || _id === undefined) ? "" : _id + "-" }${title}${fileExt}`;

    saveAs(url, fileName);
  }

  handleCloseSnackbar = () => {
    this.setState({ showSnackbar : false, snackBarDuration : 5000 });
  }


  downloadFiles = async () => {
    this.setState({
      showSnackbar : true,
      snackBarMessage : "Downloading . . .",
      messageType : "primary"
    })
    const images = [...this.state.data.images];
    const items = this.state.items;
    let arr = [];
    items.forEach((item) => {
      const selectedItem = images.find((image) => { return image.title === item });
      arr.push(selectedItem);
    })
    const policyId = this.state.data.policy_id;
    var zip = new JSZip();

    for(let docObj of arr){
      const title = docObj.title.split(" ").join("-");
      const res = await DownloadService.getFile(docObj.url); 
      if(res.status === 200){
        const path = `${title}.pdf`;
        zip.file(path, res.data);
      }
    }
    zip.generateAsync({ type : "blob" }).then((blob) => {
      this.setState({ showSnackbar : false });
      saveAs(blob, `${policyId}.zip`);
    });
  }

  handleDialogClose = () => {
    this.setState({
      showMemberDialog : false,
      showPolicyDialog : false,
      policyId : "",
      memberId : ""
    })
  }

  saveImage = () => {
    const top = $("html").scrollTop();
    if(top !== 0){
      $("html").animate({
        scrollTop : 0
      },500, "linear", this.downloadImage);
    }else{
      this.downloadImage();
    }
  }

  downloadImage = () => {
    const el = $(".vc-container");
    HtmlToCanvas(el[0], {useCORS : true}).then((canvas) => {
      let image = canvas.toDataURL("image/jpeg", 1.0);
      let link = document.createElement("a");
      link.download = "dhofar-virtual-card.jpeg";
      link.href = image;
      link.click();
    });
  }

  getDob = () => {
    const dob = this.state.data.personalInformation.dob;
    const splitDob = dob.split("-");
    const month = splitDob[1];
    const months = ["JAN","FEB","MAR","APR","MAY","JUN","JUL","AUG","SEP","OCT","NOV","DEC"];
    let monthInd = months.indexOf(month);
    const dobMonth = monthInd + 1;
    return `${splitDob[0]}-${dobMonth < 10 ? "0" + dobMonth : dobMonth}-${splitDob[2]}`;
  }

  getStartDate = (startDate) => {
    let newDate = new Date(startDate);
    const date = newDate.getDate();
    const month = newDate.getMonth() + 1;
    const year = newDate.getFullYear();

    return `${date > 9 ? date : "0" + date}/${month > 9 ? month : "0" + month}/${year}`
  }

  getEndDate = (startDate) => {
    let numYears = 1;
    if(["Unified Domestic Worker", "Personal Accident"].includes(this.state.data.cover_type)){
      numYears = 2
    }
    let newDate = new Date(startDate);
    newDate.setDate(newDate.getDate() - 1);
    const date = newDate.getDate();
    const month = newDate.getMonth() + 1;
    const year = newDate.getFullYear() + numYears;

    return `${date > 9 ? date : "0" + date}/${month > 9 ? month : "0" + month}/${year}`
  }

  getYob = () => {
    if(this.state.data){
      const dob = this.state.data.personalInformation.dob.split("-");
      return dob[2];
    }
  }

  getCoverType = () => {
    if(this.state.data.cover_type === "Individual Medical"){
      return "Individual Policy"
    }
    else if(this.state.data.cover_type === "Affordable Health Cover Plan"){
      return "Affordable Health Policy"
    }
    else{
      return "Domestic Worker Policy"
    }
  }

  getFormattedUploadDate = (dateMS) => {
    let newDate = new Date(dateMS);
    const date = newDate.getDate();
    const month = newDate.getMonth() + 1;
    const year = newDate.getFullYear();
    const hours = newDate.getHours();
    const minutes = newDate.getMinutes();
    const seconds = newDate.getSeconds();

    return `${date > 9 ? date : "0" + date}-${month > 9 ? month : "0" + month}-${year} ${hours}:${minutes}:${seconds}`;
  }

  render(){
    return(
      <div className="d-flex">
        <Navigation />
        <Snackbar
          showSnackbar = { this.state.showSnackbar }
          messageType = {this.state.messageType}
          snackBarMessage = {this.state.snackBarMessage}
          onCloseSnackbar = {this.handleCloseSnackbar}
        />
        <div className="container-fluid">
          <Paper className="p-2 sq-shadow mb-3 d-flex">
            <Typography><Link to={`/home`}>Home</Link></Typography>
            <Typography className="mr-2 ml-2">/</Typography>
            <Typography> <Link to={`/refer-policies`}>Referal Policies</Link> </Typography>
            <Typography className="mr-2 ml-2">/</Typography>
            <Typography>{this.props.match.params.id}</Typography>
          </Paper>
          <Grid container spacing={2}>
            
            {
              (this.state.data && this.state.data.applicationType === "domestic") &&
              <>
                <Grid item xs = {12}>
                  <div className="vc-container domestic-vc-container position-relative sq-shadow">
                    <div className="d-flex align-items-center justify-content-between domestic-vc-header">
                      <img src={DhofarLogo} alt="Dhofar" className="domestic-dhofar-logo"/>
                      <div className="ml-2">
                        <div className="d-flex justify-content-end text-right w-100">
                          <img src={NextCare} alt="Next-care" className="nextcare-logo text-right" /> 
                        </div>
                        <div className="vc-description mr-4 mt-1">
                        {
                          this.state.data.cover_type === "Unified Domestic Worker" ?
                          <>
                            <div>UNIFIED MEDICAL INSURANCE </div>
                            <div>FOR HOUSEHOLD DOMESTIC WORKERS</div>
                          </>
                          : 
                          <div className="mr-md-5"> AFFORDABLE HEALTH COVER  </div>
                        }
                        </div>
                      </div>
                    </div>
                    <div className="d-flex align-items-center vcd-detail-container mt-1 ">
                      <span className="vcd-title ">Name: </span> &nbsp; <span className="vc-description"> {this.state.data && this.state.data.personalInformation.fullName} </span>
                    </div>
                    <div className="d-flex align-items-center vcd-detail-container">
                      <div className="col-8 p-0">
                        <div className="d-flex align-items-center ">
                          <span className="vcd-title">Card No: </span> &nbsp; <span className="vc-description"> {this.state.data && this.state.data.policy_num} </span>
                        </div>
                      </div>
                      <div className="col-4 p-0">
                        <div className="d-flex align-items-center ">
                          <span className="vcd-title">Id No: </span> &nbsp; <span className="vc-description"> {this.state.data && this.state.data.civil_id} </span>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex align-items-center vcd-detail-container">
                      <div className="col-8 p-0">
                        <div className="d-flex align-items-center ">
                          <span className="vcd-title">Network: </span> &nbsp; <span className="vc-description"> RN2 (Access in Oman) </span>
                        </div>
                      </div>
                      <div className="col-4 p-0">
                        <div className="d-flex align-items-center ">
                          <span className="vcd-title">D.O.B: </span> &nbsp; <span className="vc-description"> {this.getDob()} </span>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex align-items-center vcd-detail-container">
                      <div className="col-4 p-0">
                        <div className="d-flex align-items-center">
                          <span className="vcd-title">Ded: </span> &nbsp; <span className="vc-description"> Nil </span>
                        </div>
                        <div className="d-flex align-items-center">
                          <span className="vcd-title">Co.Ins: </span> &nbsp; <span className="vc-description"> Nil </span>
                        </div>
                        <div className="d-flex align-items-center">
                          <span className="vcd-title">Cons: </span> &nbsp; <span className="vc-description"> Yes </span>
                        </div>
                      </div>
                      <div className="col-4 p-0">
                        <div className="d-flex align-items-center">
                          <span className="vcd-title">Optical: </span> &nbsp; <span className="vc-description"> No </span>
                        </div>
                        <div className="d-flex align-items-center">
                          <span className="vcd-title">Dental: </span> &nbsp; <span className="vc-description"> No </span>
                        </div>
                        <div className="d-flex align-items-center">
                          <span className="vcd-title">Metarnity: </span> &nbsp; <span className="vc-description"> No </span>
                        </div>
                      </div>
                      <div className="col-4 p-0">
                        <div className="d-flex align-items-center">
                          <span className="vcd-title">Class: </span> &nbsp; <span className="vc-description"> Semi-Private </span>
                        </div>
                        <div className="d-flex align-items-center">
                          <span className="vcd-title">Effective:</span> &nbsp; <span className="vc-description">{this.getStartDate(this.state.data && this.state.data.startDate)}</span>
                        </div>
                        <div className="d-flex align-items-center">
                          <span className="vcd-title">Expiry:</span> &nbsp; <span className="vc-description">{this.getEndDate(this.state.data && this.state.data.startDate)}</span>
                        </div>
                      </div>
                    </div>
                    <div className="vc-bottom-descreption vcd-detail-container"> Pre approval required for all Out-Patient services <br/> Out-Patient Pre-Existing & Chronic Conditions:Not Covered <br/> Please collect resident card copy & submit along with claim </div>
                    <div className="vcd-bottom-text vcd-detail-container">This card is for personal use and must be presented at every doctor's visit</div>
                    <div className="vcd-title text-center mt-1 w-100 vcd-detail-container">Nextcare Hotline Number: 80077757</div> 
                    <div data-html2canvas-ignore className="vc-download-btn vcd-download-btn d-flex align-items-center justify-content-center" onClick={this.saveImage}>
                      <img src={Download} alt="Download" />
                    </div>
                  </div>
                </Grid>
              </>
            }


            <Grid item xs={12} md={4}>
              <Paper className="pt-3 pb-3 pr-2 pl-2 sq-shadow">
                <Typography variant="h5" className="pb-2">
                  Policy Details
                </Typography>
                <Divider />
                <table className="mt-4 mb-3">
                  <tbody>
                    <tr>
                      <td>
                        <Typography variant="body1"> Member ID </Typography>
                      </td>
                      <td> &nbsp; - &nbsp; </td>
                      <td>
                        <Typography variant="body1" className="text-capitalize"> {this.state.data && this.state.data._id} </Typography>
                      </td>
                    </tr>
                    {
                      ["health", "life"].includes(this.state.insurance_type) &&
                      <tr>
                        <td>
                          <Typography variant="body1"> Relation </Typography>
                        </td>
                        <td> &nbsp; - &nbsp; </td>
                        <td>
                          <Typography variant="body1" className="text-capitalize"> {this.state.data && this.state.data.applicationType} </Typography>
                        </td>
                      </tr>
                    }
                    {/* <tr>
                      <td>
                        <Typography variant="body1"> Status </Typography>
                      </td>
                      <td> &nbsp; - &nbsp; </td>
                      <td>
                        <Typography variant="body1" className="text-capitalize"> {this.state.data && this.state.data.status} </Typography>
                      </td>
                    </tr> */}
                    <tr>
                      <td>
                        <Typography variant="body1"> Nationality </Typography>
                      </td>
                      <td> &nbsp; - &nbsp; </td>
                      <td>
                        <Typography variant="body1" className="text-capitalize"> {this.state.data && this.state.data.nationality} </Typography>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <Typography variant="body1"> Amount </Typography>
                      </td>
                      <td> &nbsp; - &nbsp; </td>
                      <td>
                        <Typography variant="body1" className="text-capitalize"> {this.state.data && (this.state.data.amount ? Number(this.state.data.amount.total).toFixed(2) : 0)} OMR </Typography>
                      </td>
                    </tr>
                    {/* <tr>
                      <td>
                        <Typography variant="body1"> Payment Mode </Typography>
                      </td>
                      <td> &nbsp; - &nbsp; </td>
                      <td>
                        <Typography variant="body1"> {this.state.data && this.state.data.payments && (this.state.data.payments.mode === "Link" ? "Card" : this.state.data.payments.mode)} </Typography>
                      </td>
                    </tr> */}
                  </tbody>
                </table>
              </Paper>

              <Paper className="pt-3 pb-3 pr-2 pl-2 mt-3 sq-shadow">
                <Typography variant="h5" className="pb-2">
                  Personal Information
                </Typography>
                <Divider />
                <table className="mt-4">
                  <tbody>
                    <tr>
                      <td>
                        <Typography variant="body1"> Name </Typography>
                      </td>
                      <td> &nbsp; - &nbsp; </td>
                      <td>
                        <Typography variant="body1" className="text-capitalize"> {this.state.data && this.state.data.personalInformation.fullName} </Typography>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <Typography variant="body1"> Email </Typography>
                      </td>
                      <td> &nbsp; - &nbsp; </td>
                      <td>
                        <Typography variant="body1"> {this.state.data && this.state.data.personalInformation.email} </Typography>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <Typography variant="body1"> Phone </Typography>
                      </td>
                      <td> &nbsp; - &nbsp; </td>
                      <td>
                        <Typography variant="body1"> {this.state.data && this.state.data.personalInformation.mobileNumber} </Typography>
                      </td>
                    </tr>
                    {
                      this.state.insurance_type !== "home" &&
                      <tr>
                        <td>
                          <Typography variant="body1"> DOB </Typography>
                        </td>
                        <td> &nbsp; - &nbsp; </td>
                        <td>
                          <Typography variant="body1"> {this.state.data && this.state.data.personalInformation.dob} </Typography>
                        </td>
                      </tr>
                    }
                    {
                      ["health", "life"].includes(this.state.insurance_type) &&
                      <tr>
                        <td>
                          <Typography variant="body1"> Married </Typography>
                        </td>
                        <td> &nbsp; - &nbsp; </td>
                        <td>
                          <Typography variant="body1" className="text-capitalize"> {this.state.data && this.state.data.personalInformation.isMarried} </Typography>
                        </td>
                      </tr>
                    }
                    <tr>
                      <td>
                        <Typography variant="body1"> Civil Id </Typography>
                      </td>
                      <td> &nbsp; - &nbsp; </td>
                      <td>
                        <Typography variant="body1"> {this.state.data && this.state.data.civil_id } </Typography>
                      </td>
                    </tr>

                    {
                      (["health", "life"].includes(this.state.insurance_type) && this.state.data && this.state.data.personalInformation.height) &&
                      <tr>
                        <td>
                          <Typography variant="body1"> Height </Typography>
                        </td>
                        <td> &nbsp; - &nbsp; </td>
                        <td>
                          <Typography variant="body1" className="text-capitalize"> {this.state.data.personalInformation.height} </Typography>
                        </td>
                      </tr>
                    }
                    {
                      (["health", "life"].includes(this.state.insurance_type) && this.state.data && this.state.data.personalInformation.weight) &&
                      <tr>
                        <td>
                          <Typography variant="body1"> Weight </Typography>
                        </td>
                        <td> &nbsp; - &nbsp; </td>
                        <td>
                          <Typography variant="body1" className="text-capitalize"> {this.state.data.personalInformation.weight} </Typography>
                        </td>
                      </tr>
                    }
                  </tbody>
                </table>
              </Paper>
              
              {
                (this.state.data && this.state.data.cover_type === "Individual Medical") &&
                <Paper className="pt-3 pb-3 pr-2 pl-2 mt-3 sq-shadow">
                  <Typography variant="h5" className="pb-2">
                    Plan Details
                  </Typography>
                  <Divider />
                  <table className="mt-4 mb-3">
                    <tbody>
                      <tr>
                        <td>
                          <Typography variant="body1"> Plan Name </Typography>
                        </td>
                        <td> &nbsp; - &nbsp; </td>
                        <td>
                          <Typography variant="body1" className="text-capitalize"> {this.state.data && this.state.data.plan} </Typography>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <Typography variant="body1"> Network </Typography>
                        </td>
                        <td> &nbsp; - &nbsp; </td>
                        <td>
                          <Typography variant="body1" className="text-capitalize"> {this.state.data && (this.state.data.network === "Silver" ? "Silver Premium" : this.state.data.network)} </Typography>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <Typography variant="body1"> Annual Limit </Typography>
                        </td>
                        <td> &nbsp; - &nbsp; </td>
                        <td>
                          <Typography variant="body1" className="text-capitalize"> {this.state.data && this.state.data.annual_limit} OMR </Typography>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <Typography variant="body1"> OP Deductible </Typography>
                        </td>
                        <td> &nbsp; - &nbsp; </td>
                        <td>
                          <Typography variant="body1" className="text-capitalize"> {this.state.data && this.state.data.op} OMR </Typography>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <Typography variant="body1"> Pharmacy Co Pay </Typography>
                        </td>
                        <td> &nbsp; - &nbsp; </td>
                        <td>
                          <Typography variant="body1"> {this.state.data && this.state.data.co_pay} </Typography>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </Paper>
              }

              <Paper className="pt-3 pb-3 pr-2 pl-2 mt-3 sq-shadow">
                <Typography variant="h5" className="pb-2">
                  Question Information
                </Typography>
                <Divider />
                {
                  (this.state.data && this.state.data.has_li_addon) &&
                  <Typography variant="h6" className = "mt-4 border-top border-bottom">Health</Typography>
                }
                {
                  (this.state.data && this.state.data.insurance_type === "health") &&
                  <div className = "mt-4">
                    <Typography variant="body1"> Are you under any medical observation/undergoing any medical/ surgical/ treatment or have been advised for the same? </Typography>
                    <Typography variant="body1" className="text-capitalize"> <b>{this.state.data && this.state.data.underlyingMedicalObservation}</b> </Typography>

                    <Typography variant="body1" className = "mt-3"> Do you have any chronic illness? </Typography>
                    <Typography variant="body1" className="text-capitalize"> <b>{this.state.data && this.state.data.haveChronicIllness}</b> </Typography>

                    <Typography variant="body1" className = "mt-3"> Are you taking any medication (pharmaceutical/alternative medicine) or have been advised? </Typography>
                    <Typography variant="body1" className="text-capitalize"> <b>{this.state.data && this.state.data.isTakingMedication}</b> </Typography>

                    <Typography variant="body1" className = "mt-3"> Do you have any physical problems/ disability for which you are undergoing physiotherapy or have been advised for? </Typography>
                    <Typography variant="body1" className="text-capitalize"> <b>{this.state.data && this.state.data.havePhysicalProblem}</b> </Typography>

                    <Typography variant="body1" className = "mt-3"> Have you been admitted in the hospital in the last 10 years? </Typography>
                    <Typography variant="body1" className="text-capitalize"> <b>{this.state.data && this.state.data.haveAdmitted}</b> </Typography>

                    <Typography variant="body1" className = "mt-3"> Do you have any previous surgical history or are you advised to undergo any kind of surgeries in the near future? </Typography>
                    <Typography variant="body1" className="text-capitalize"> <b>{this.state.data && this.state.data.havePreviousSurgicalHistory}</b> </Typography>

                    <Typography variant="body1" className = "mt-3"> Have you been ever diagnosed/treated and cured or undergoing treatments for cancer? </Typography>
                    <Typography variant="body1" className="text-capitalize"> <b>{this.state.data && this.state.data.haveCuredCancer}</b> </Typography>

                    <Typography variant="body1" className = "mt-3"> Is there any other medical condition or disorder or any symptoms that you should be declared, and you are unable to relate to the above-mentioned Questions? </Typography>
                    <Typography variant="body1" className="text-capitalize"> <b>{this.state.data && this.state.data.haveOtherConditions}</b> </Typography>
                    {
                      (this.state.data && this.state.data.personalInformation.gender === "female" && this.state.data.personalInformation.isMarried === "yes") &&
                      <>
                        <Typography variant="body1" className = "mt-3"> Are you currently pregnant or show signs and symptoms of pregnancy ?  </Typography>
                        <Typography variant="body1" className="text-capitalize"> <b>{this.state.data && this.state.data.isPregnant}</b> </Typography>
                      </>
                    }
                  </div>
                }
                {
                  (this.state.data && this.state.data.has_li_addon) &&
                  <Typography variant="h6" className = "mt-4 border-top border-bottom">Life</Typography>
                }
                {
                  (this.state.data && (this.state.data.insurance_type === "life" || this.state.data.has_li_addon)) &&
                  <div className = "mt-4">
                    <Typography variant="body1"> I have not been on any medication/ suffered from any ailment/disease/deformity which required continuous follow up treatment /medication beyond 15 days/advised for any surgery/hospitalization in the past 5 years and none of my previous applications for insurance has ever been declined/ deferred/ accepted with extra premium for any reasons. </Typography>
                    <Typography variant="body1" className="text-capitalize"> <b>{this.state.data && this.state.data.on_medication}</b> </Typography>

                    <Typography variant="body1" className = "mt-3"> I have not travelled abroad since 01/01/2020, do not intend to travel abroad within the next 3 months, am not currently not receiving/ have not received any medical care/ referred to a hospital/clinic for pneumonia, flu like symptoms, fever, sore throat, runny nose, cough, shortness of breath and breathing difficulties in the past 30 days. I have not undergone any surgical procedure during my travel abroad or in my country of residence in the past 30 days. I have not been issued any notice or directive to self-quarantine or stay home (excluding as part of government mandate or altered employment arrangement), nor have I within the past 15 days come in contact with someone confirmed as infected with the COVID- 19 virus </Typography>
                    <Typography variant="body1" className="text-capitalize"> <b>{this.state.data && this.state.data.have_travelled}</b> </Typography>

                    <Typography variant="body1" className = "mt-3"> I am currently in good health, free from any defect, disease, disability or medication </Typography>
                    <Typography variant="body1" className="text-capitalize"> <b>{this.state.data && this.state.data.in_good_health}</b> </Typography>

                    <Typography variant="body1" className = "mt-3"> Are you a member of any armed forces ? </Typography>
                    <Typography variant="body1" className="text-capitalize"> <b>{this.state.data && this.state.data.is_armed_member}</b> </Typography>
                  </div>
                }
                {
                  this.state.insurance_type === "travel" &&
                  <div className = "mt-4">
                    <Typography variant="body1"> There are no actual circumstances with the travel that render it abnormal </Typography>
                    <Typography variant="body1" className="text-capitalize"> <b>{this.state.data && this.state.data.no_abnormal_travel_circumstances}</b> </Typography>

                    <Typography variant="body1" className = "mt-3"> All persons to be insured are in good health and free from any physical defect at the time of effecting this insurance </Typography>
                    <Typography variant="body1" className="text-capitalize"> <b>{this.state.data && this.state.data.in_good_health}</b> </Typography>
                  </div>
                }

                {
                  this.state.insurance_type === "home" &&
                  <div className = "mt-4">
                    <Typography variant="body1"> Has this insurance been declined before ? </Typography>
                    <Typography variant="body1" className="text-capitalize"> <b> No </b> </Typography>

                    <Typography variant="body1" className = "mt-3"> Has this insurance been cancelled by other insurance company ? </Typography>
                    <Typography variant="body1" className="text-capitalize"> <b> No </b> </Typography>

                    <Typography variant="body1" className = "mt-3"> Does this insurance have any special Conditions imposed ? </Typography>
                    <Typography variant="body1" className="text-capitalize"> <b> No </b> </Typography>
                  </div>
                }
              </Paper>
              
              {
                (this.state.data && (this.state.data.insurance_type === "life" || this.state.data.has_li_addon)) &&
                <Paper className="pt-3 pb-3 pr-2 pl-2 mt-3 sq-shadow">
                  <Typography variant="h5" className="pb-2">
                    Life Insurance Information
                  </Typography>
                  <Divider />
                  <div className = "mt-4">
                    <Typography variant="body1"> Sum Assured </Typography>
                    <Typography variant="body1" className="text-capitalize"> <b>{this.state.data && this.state.data.sum_assured} OMR</b> </Typography>
                    <Typography variant="body1" className = "mt-3"> Beneficiaries </Typography>
                    <table className = "table table-striped table-hover">
                      <thead>
                        <tr>
                          <th>
                            <Typography variant="body1"> # </Typography>  
                          </th>
                          <th>
                            <Typography variant="body1"> Name </Typography>  
                          </th>
                          <th>
                            <Typography variant="body1"> Relation </Typography>  
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          this.state.data && this.state.data.beneficiaries.map((beneficiary, ind) =>
                            <tr key = {ind}>
                              <td>
                                <Typography variant="body1"> {ind + 1} </Typography>  
                              </td>
                              <td>
                                <Typography variant="body1"> {beneficiary.name} </Typography>  
                              </td>
                              <td>
                                <Typography variant="body1"> {beneficiary.relation} </Typography>  
                              </td>
                            </tr>
                          )
                        }
                      </tbody>
                    </table>
                  </div>
                </Paper>
              }

              {
                (this.state.insurance_type === "travel" && this.state.data && this.state.data.travel_countries.length > 1) &&
                <Paper className="px-2 py-3 mt-3 sq-shadow">
                  <Typography variant="h5" className="pb-2">
                    Travel Countries
                  </Typography>
                  <Divider />
                  <div className = "mt-3">
                    {
                      this.state.data.travel_countries.map((country, ind) => 
                        <Typography variant = "body1" className = "travel-country d-flex align-items-center" key = {ind}>
                          {country}
                        </Typography>
                      )
                    }
                  </div>
                </Paper>
              }

              {
                this.state.insurance_type === "home" &&
                <Paper className="px-2 py-3 mt-3 sq-shadow">
                  <Typography variant="h5" className="pb-2">
                    Cover Details
                  </Typography>
                  <Divider />
                  
                  <Typography variant="body1" className = "mt-3"> Ownership Type </Typography>
                  <Typography variant="body1" className="text-capitalize"> <b> {this.state.data.homeOwnerType} </b> </Typography>

                  <table className = "table table-bordered mt-3">
                    <thead>
                      <tr>
                        <th> <Typography variant="body1"> Section Name </Typography> </th>
                        <th> <Typography variant="body1"> Required </Typography> </th>
                        <th> <Typography variant="body1"> Sum Assured </Typography> </th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr>
                        <td> <Typography variant="body1"> Section 1 </Typography> </td>
                        <td> <Typography variant="body1"> {this.state.data.homeCoverageInformation.section_1.selected ? "Yes" : "No"} </Typography> </td>
                        <td> <Typography variant="body1"> {this.state.data.homeCoverageInformation.section_1.sum_assured || "- -"} </Typography> </td>
                      </tr>
                      <tr>
                        <td> <Typography variant="body1"> Section 2 </Typography> </td>
                        <td> <Typography variant="body1"> {this.state.data.homeCoverageInformation.section_2.selected ? "Yes" : "No"} </Typography> </td>
                        <td> <Typography variant="body1"> {this.state.data.homeCoverageInformation.section_2.sum_assured || "- -"} </Typography> </td>
                      </tr>
                      <tr>
                        <td> <Typography variant="body1"> Section 3 </Typography> </td>
                        <td> <Typography variant="body1"> {this.state.data.homeCoverageInformation.section_3.selected ? "Yes" : "No"} </Typography> </td>
                        <td> <Typography variant="body1"> {this.state.data.homeCoverageInformation.section_3.sum_assured} </Typography> </td>
                      </tr>
                      <tr>
                        <td> <Typography variant="body1"> Section 4 </Typography> </td>
                        <td> <Typography variant="body1"> {this.state.data.homeCoverageInformation.section_4.selected ? "Yes" : "No"} </Typography> </td>
                        <td> <Typography variant="body1"> {this.state.data.homeCoverageInformation.section_4.sum_assured || "- -"} </Typography> </td>
                      </tr>
                      <tr>
                        <td> <Typography variant="body1"> Section 5 </Typography> </td>
                        <td> <Typography variant="body1"> {this.state.data.homeCoverageInformation.section_5.selected ? "Yes" : "No"} </Typography> </td>
                        <td> <Typography variant="body1"> {this.state.data.homeCoverageInformation.section_5.sum_assured || "- -"} </Typography> </td>
                      </tr>
                      <tr>
                        <td> <Typography variant="body1"> Section 6 </Typography> </td>
                        <td> <Typography variant="body1"> {this.state.data.homeCoverageInformation.section_6.selected ? "Yes" : "No"} </Typography> </td>
                        <td> <Typography variant="body1"> {this.state.data.homeCoverageInformation.section_6.sum_assured || "- -"} </Typography> </td>
                      </tr>
                      <tr>
                        <td> <Typography variant="body1"> Section 7 </Typography> </td>
                        <td> <Typography variant="body1"> {this.state.data.homeCoverageInformation.section_7.selected ? "Yes" : "No"} </Typography> </td>
                        <td> <Typography variant="body1"> {this.state.data.homeCoverageInformation.section_7.sum_assured || "- -"} </Typography> </td>
                      </tr>
                      <tr>
                        <td> <Typography variant="body1"> Section 8 </Typography> </td>
                        <td> <Typography variant="body1"> {this.state.data.homeCoverageInformation.section_8.selected ? "Yes" : "No"} </Typography> </td>
                        <td> <Typography variant="body1"> {this.state.data.homeCoverageInformation.section_8.sum_assured || "- -"} </Typography> </td>
                      </tr>
                    </tbody>
                  </table>

                </Paper>
              }

              {
                (this.state.data && this.state.data.affiliate_name) &&
                <Paper className="pt-3 pb-3 pr-2 pl-2 mt-3 sq-shadow">
                  <Typography variant="h5" className="pb-2">
                    Center Details
                  </Typography>
                  <Divider />
                  <table className="mt-4">
                    <tbody>
                      <tr>
                        <td>
                          <Typography variant="body1"> Center Name </Typography>
                        </td>
                        <td> &nbsp; - &nbsp; </td>
                        <td>
                          <Typography variant="body1" className="text-capitalize"> {this.state.data.affiliate_name} </Typography>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </Paper>
              }

            </Grid>
            <Grid item xs={12} md={4}>
              {
                this.state.insurance_type === "home" &&
                <Paper className="py-3 px-2 mt-3 sq-shadow">
                  <Typography variant="h5" className="pb-2">
                    Building Details
                  </Typography>
                  <Divider />

                  <Typography variant="body1" className = "mt-3"> Business or Occupation of the Insured </Typography>
                  <Typography variant="body1" className="text-capitalize"> <b> {this.state.data.buildingInformation.business_of_insured} </b> </Typography>

                  <Typography variant="body1" className = "mt-3"> Age of the Building </Typography>
                  <Typography variant="body1" className="text-capitalize"> <b> {this.state.data.buildingInformation.age_of_building} </b> </Typography>

                  <Typography variant="body1" className = "mt-3"> Type of Building </Typography>
                  <Typography variant="body1" className="text-capitalize"> <b> {this.state.data.buildingInformation.type_of_building} </b> </Typography>

                  <Typography variant="body1" className = "mt-3"> On which floor/ story of the building is the premises located </Typography>
                  <Typography variant="body1" className="text-capitalize"> <b> {this.state.data.buildingInformation.floor_no} </b> </Typography>

                  <Typography variant="body1" className = "mt-3"> Is your property in the basement </Typography>
                  <Typography variant="body1" className="text-capitalize"> <b> {this.state.data.buildingInformation.is_property_in_basement} </b> </Typography>

                  <Typography variant="body1" className = "mt-3"> Is the whole building used for residential purposes only? </Typography>
                  <Typography variant="body1" className="text-capitalize"> <b> {this.state.data.buildingInformation.is_whole_building_residential} </b> </Typography>
                  {
                    this.state.data.buildingInformation.is_whole_building_residential === "No" &&
                    <Typography variant="body1" className="text-capitalize"> <b> {this.state.data.buildingInformation.not_residential_reason} </b> </Typography>
                  }

                  <Typography variant="body1" className = "mt-3"> Address of Property to be Insured </Typography>
                  <Typography variant="body1" className="text-capitalize"> <b> {this.state.data.buildingInformation.address} </b> </Typography>

                  <Typography variant="body1" className = "mt-3"> Type of Fire protection devices installed in premises </Typography>

                  <Typography variant="body1" className = "mt-3 border-top border-bottom"> Fire Alarm Systems </Typography>
                  <Typography variant="body1" className="text-capitalize"> <b> {this.state.data.buildingInformation.have_fire_alarm_system} </b> </Typography>

                  <Typography variant="body1" className = "mt-3"> Portable Extinguishers </Typography>
                  <Typography variant="body1" className="text-capitalize"> <b> {this.state.data.buildingInformation.have_portable_extinguishers} </b> </Typography>

                  <Typography variant="body1" className = "mt-3"> Hydrant System </Typography>
                  <Typography variant="body1" className="text-capitalize"> <b> {this.state.data.buildingInformation.have_hydrant_system} </b> </Typography>

                  <Typography variant="body1" className = "mt-3"> Other </Typography>
                  <Typography variant="body1" className="text-capitalize"> <b> {this.state.data.buildingInformation.other_fire_protection} </b> </Typography>

                  <Typography variant="body1" className = "mt-3 border-top border-bottom"> Security systems provided in the premises </Typography>

                  <Typography variant="body1" className = "mt-3"> Surveillance Camera </Typography>
                  <Typography variant="body1" className="text-capitalize"> <b> {this.state.data.buildingInformation.have_surveillance_camera} </b> </Typography>

                  <Typography variant="body1" className = "mt-3"> Burglar Alarm System </Typography>
                  <Typography variant="body1" className="text-capitalize"> <b> {this.state.data.buildingInformation.have_burglar_alarm_system} </b> </Typography>
                  
                  <Typography variant="body1" className = "mt-3"> Grilled Doors </Typography>
                  <Typography variant="body1" className="text-capitalize"> <b> {this.state.data.buildingInformation.have_grilled_doors} </b> </Typography>

                  <Typography variant="body1" className = "mt-3"> 24 Hours watchman services </Typography>
                  <Typography variant="body1" className="text-capitalize"> <b> {this.state.data.buildingInformation.have_watchman_service} </b> </Typography>

                  <Typography variant="body1" className = "mt-3"> Security Check point </Typography>
                  <Typography variant="body1" className="text-capitalize"> <b> {this.state.data.buildingInformation.have_security_checkpoint} </b> </Typography>

                  <Typography variant="body1" className = "mt-3 border-bottom"> Other </Typography>
                  <Typography variant="body1" className="text-capitalize"> <b> {this.state.data.buildingInformation.other_security_system} </b> </Typography>

                  <Typography variant="body1" className = "mt-3"> Previous Insurance Details </Typography>
                  <Typography variant="body1" className="text-capitalize"> <b> {this.state.data.buildingInformation.previous_insurance_details} </b> </Typography>

                  <Typography variant="body1" className = "mt-3"> Photographs of the property attached </Typography>
                  <Typography variant="body1" className="text-capitalize"> <b> {this.state.data.buildingInformation.have_photographs_of_property_attached} </b> </Typography>

                </Paper>
              }
            </Grid>
            <div className="w-100"></div>
            {
              this.state.data && this.state.data.images.map((image, ind) => 
                <Grid item xs={12} md={4} key={ind}>
                  <Paper className="p-2 sq-shadow">
                    <Typography variant = "body2" className="pb-2 border-bottom d-flex align-items-center justify-content-between">
                      <span>{image.title}</span>
                      {
                        image.uploaded_at &&
                        <span>{this.getFormattedUploadDate(image.uploaded_at)}</span>
                      }
                    </Typography>
                    <div className="d-flex justify-content-between mt-2">
                      <Button variant="contained" color="primary" className="text-white" onClick={() => this.downloadFile(image)}>
                        <GetApp/>
                      </Button>
                    </div>
                  </Paper>
                </Grid>
              )
            }
          </Grid>
        </div>
      </div>
    )
  }
}
export default withRouter(ReferPolicyView);