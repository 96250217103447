import React from "react";
import { Table, TableHead, TableBody, TableRow, TableCell, TablePagination, TextField, Paper, InputAdornment, Dialog, DialogTitle, DialogActions, Button } from "@material-ui/core";
import { Link, withRouter } from "react-router-dom";
import Eye from "mdi-react/EyeIcon";
import { Search } from "@material-ui/icons";
import PolicyService from "../../services/policy";
import Snackbar from "../common/Snackbar";
import utils from "../../services/utils";
import AffiliateSelect from "../common/AffiliateSelect";

class AllTable extends React.Component {
  state = {
    data: [],
    page: 0,
    rowsPerPage: 10,
    count: 0,
    searchValue: "",
    center: "",
    status: undefined,
    showDeleteDialog: false,
    selectedId: "",
    showSnackbar: false,
    showLoader: false
  }

  getData = async (page, rowsPerPage) => {
    const value = this.state.searchValue === "" ? undefined : this.state.searchValue;
    const data = await PolicyService.getReferalPoliciesAll(page, rowsPerPage, this.props.status, value);
    this.setState({
      count: data.data.count,
      data: data.data.data,
      status: this.props.status
    })
  }
  handleChangePage = (e, page) => {
    this.setState({ page });
    this.getData(page, this.state.rowsPerPage);
  }
  handleChangeRowsPerPage = (e) => {
    this.setState({ rowsPerPage: e.target.value });
    this.getData(this.state.page, e.target.value);
  }
  componentDidMount() {
    const sa_level = localStorage.getItem("sa_level");
    const center = this.props.match.params.center;
    this.setState({ center, sa_level });
    this.getData(this.state.page, this.state.rowsPerPage);
  }

  handleSearchChange = (e) => {
    this.setState({
      searchValue: e.target.value
    }, () => {
      this.setState({ page: 0 });
      this.getData(0, this.state.rowsPerPage)
    })
  }

  handleClose = () => {
    this.setState({
      showDeleteDialog: false,
      selectedId: ""
    })
  }

  handleDelete = async () => {
    const data = await PolicyService.deletePolicy(this.state.selectedId);
    if (data.data.deleted) {
      this.setState({
        showSnackbar: true,
        messageType: "success",
        snackBarMessage: "Policy deleted successfully"
      })
      this.handleClose();
      this.getData(this.state.page, this.state.rowsPerPage);
    }
  }

  handleCloseSnackbar = () => {
    this.setState({ showSnackbar: false })
  }

  getFormattedDate = (startDate) => {
    let newDate = new Date(startDate);
    const date = newDate.getDate();
    const month = newDate.getMonth() + 1;
    const year = newDate.getFullYear();
    return `${date > 9 ? date : "0" + date}/${month > 9 ? month : "0" + month}/${year}`;
  }

  addToPremia = (id) => {
    this.setState({ showLoader: true })
    PolicyService.addToPremia(id).then((res) => {
      this.setState({
        showLoader: false,
        showSnackbar: true,
        messageType: "success",
        snackBarMessage: "Data pushed successfully"
      });
      this.getData(this.state.page, this.state.rowsPerPage);
    }).catch((e) => {
      this.setState({
        showLoader: false,
        showSnackbar: true,
        messageType: "danger",
        snackBarMessage: "Cannot push data"
      })
    })
  }

  handleAffiliateChange = () => {
    this.getData(this.state.page, this.state.rowsPerPage);
  }

  render() {
    return (
      <div>
        <Snackbar
          showSnackbar={this.state.showSnackbar}
          messageType={this.state.messageType}
          snackBarMessage={this.state.snackBarMessage}
          onCloseSnackbar={this.handleCloseSnackbar}
        />
        <div className={`table-loader-container ${this.state.showLoader ? "table-loader-container-active" : ""} d-flex align-items-center justify-content-center`}>
          <div className="table-loader"></div>
        </div>
        <div className="row justify-content-between">
          <div className="col-md-5 col-12 mb-3">
            <AffiliateSelect fresh={false} onChange={this.handleAffiliateChange} />
          </div>

          <div className="col-md-5 col-12 mb-3">
            <TextField
              variant="outlined"
              label="Search"
              fullWidth
              placeholder="Search by quote no / name / email"
              onChange={this.handleSearchChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Search />
                  </InputAdornment>
                ),
              }}
            />
          </div>
        </div>
        <Paper className="sq-shadow policies-table-container mb-3">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  Quote ID
                </TableCell>
                <TableCell>
                  Quote Generate Date
                </TableCell>
                <TableCell>
                  Plan Name
                </TableCell>
                <TableCell>
                  Name
                </TableCell>
                <TableCell>
                  Email
                </TableCell>
                <TableCell>
                  Mobile No
                </TableCell>
                <TableCell>
                  View
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                this.state.data.map((data) =>
                  <TableRow key={data.id}>
                    <TableCell>
                      {data.id}
                    </TableCell>
                    <TableCell>
                      {utils.getFormatedDate(data.quote_generated_date)}
                    </TableCell>
                    <TableCell>
                      {data.policy_type !== "undefined" ? data.policy_type : "Domestic Worker"}
                    </TableCell>
                    <TableCell>
                      {data.name}
                    </TableCell>
                    <TableCell>
                      {data.email}
                    </TableCell>
                    <TableCell>
                      {data.mobileNumber}
                    </TableCell>
                    <TableCell>
                      <Link to={`/refer-policies/${data.id}`} className="table-eye-link">
                        <Eye />
                      </Link>
                    </TableCell>
                  </TableRow>
                )
              }
              <TableRow>
                <TablePagination
                  count={this.state.count}
                  page={this.state.page}
                  rowsPerPage={this.state.rowsPerPage}
                  onChangePage={this.handleChangePage}
                  onChangeRowsPerPage={this.handleChangeRowsPerPage}
                  rowsPerPageOptions={[10, 25, 50]}
                />
              </TableRow>
            </TableBody>
          </Table>
        </Paper>

        <Dialog
          open={this.state.showDeleteDialog}
          onClose={this.handleClose}
        >
          <DialogTitle>Are you sure you want to delete this policy ?</DialogTitle>
          <DialogActions>
            <Button variant="outlined" color="primary" onClick={this.handleClose}>
              Cancel
            </Button>
            <Button variant="contained" color="primary" onClick={this.handleDelete}>
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}

export default withRouter(AllTable);