import React from "react";
import Navigation from "../common/Navigation";
import {Grid, Tabs, Tab, Paper, Typography, Button, Dialog, DialogContent, DialogTitle, TextField, DialogActions} from "@material-ui/core";
import AffiliateSelect from "../common/AffiliateSelect";
import PolicyService from "../../services/policy";
import {Link, withRouter} from "react-router-dom";
import CreditLimit from "../../services/credit-limit";

function getRandomColor(){
  var letters = '0123456789ABCDEF';
  var color = '#';
  for (var i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}

class CreditLimits extends React.Component{
  state = {
    paymentIndex : 0,
    data : [],
    paymentTypeData : [],
    agentsData : [],
    paymentTypeIndex : 0,
    agentsIndex : 0,
    center : "",
    creditLimit : "",
    availableLimit : "",
    showCreditLimitDialog : false,
    creditLimitValue : "",
    availableLimitValue : "",
    showUpdateLimitDialog : false,
    isCenterSelected : false,
    childrenIndex : 0,
    childrenData : [],
    brokerIndex : 0,
    brokerData : []
  }

  getPaymentTypeData = async () => {
    let startDate = undefined;
    let endDate = undefined;
    const date = new Date();
    const paymentIndex = this.state.paymentTypeIndex;
    if(paymentIndex === 0){
      startDate = new Date(date.setHours(0,0,0,0));
      endDate = new Date(date.setHours(23,59,59,999));
    }
    if(paymentIndex === 1){
      startDate = new Date();
      startDate.setDate(date.getDate() - 7);
      startDate.setHours(0,0,0,0);
      endDate = new Date(date.setHours(23,59,59,999));
    }
    if(paymentIndex === 2){
      startDate = new Date();
      startDate.setDate(date.getDate() - 30);
      startDate.setHours(0,0,0,0);
      endDate = new Date(date.setHours(23,59,59,999));
    }

    const startDateIso = startDate ? startDate.getTime() : startDate;
    const endDateIso = endDate ? endDate.getTime() : endDate;

    const data = await PolicyService.getPaymentAnalysisData(startDateIso, endDateIso);
    let paymentTypeData = [];
    data.data.forEach((paymentObj) => {
      let obj = {};

      obj.title = paymentObj.type;
      obj.value = paymentObj.count;
      obj.color = getRandomColor();

      paymentTypeData.push(obj);
    })
    this.setState({ paymentTypeData });
  }

  handlePaymentTypeIndexChange = (e, ind) => {
    if(this.state.paymentTypeIndex !== ind){
      this.setState({ paymentTypeIndex : ind }, this.getPaymentTypeData);
    }
  }

  handleAgentsIndexChange = (e, ind) => {
    if(this.state.agentsIndex !== ind){
      this.setState({ agentsIndex : ind }, this.getAgentsData);
    }
  }

  handleBrokerIndexChange = (e, ind) => {
    if(this.state.brokerIndex !== ind){
      this.setState({ brokerIndex : ind }, this.getBrokersData);
    }
  }

  componentDidMount(){
    this.getCreditLimit();
  }

  getCreditLimit = async () => {

    const center = this.props.match.params.center;
    const isCenterSelected = Boolean(sessionStorage.getItem("center_id"));

    if(isCenterSelected){
      const res = await CreditLimit.getCreditLimit();
      const data = res.data;
      if(Boolean(data)){
        this.setState({
          creditLimit : data.total_limit,
          availableLimit : data.available_limit,
          availableLimitValue : data.available_limit
        });
      }else{
          this.setState({
              creditLimit : "",
              availableLimit : "",
              availableLimitValue : ""
          });
      }
    }
    this.setState({ center, isCenterSelected });

  }

  handleCloseDialog = () => {
    this.setState({
      showCreditLimitDialog : false,
      showUpdateLimitDialog : false
    })
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name] : e.target.value
    })
  }

  handleCreditLimitSave = async () => {
    let obj = {
      total_limit : parseFloat(Number(this.state.creditLimitValue).toFixed(2))
    }
    
    if(this.state.availableLimit === ""){
      obj.available_limit = parseFloat(Number(this.state.creditLimitValue).toFixed(2));
    }

    const res = await CreditLimit.updateLimit(obj);
    if(res.status === 200){
      this.handleCloseDialog();
      this.getCreditLimit();
    }
  }

  handleAvalilableLimitSave = async () => {
    let obj = {
      available_limit : parseFloat(Number(this.state.availableLimitValue).toFixed(2))
    }
    const res = await CreditLimit.updateLimit(obj);
    if(res.status === 200){
      this.handleCloseDialog();
      this.getCreditLimit();
    }
  }

  render(){
    return(
      <div className="d-flex">
        <Navigation />
        <div className="container-fluid">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Paper className="p-2 sq-shadow d-flex">
                <Typography> <Link to={`/home`}>Home</Link></Typography>
                <Typography className="mr-2 ml-2">/</Typography>
                <Typography>Credit Limit</Typography>
              </Paper>
            </Grid>

            <Grid item md={3} xs={12}>
              <AffiliateSelect onlyParents={true} onChange={this.getCreditLimit} />
            </Grid>

            <Grid item xs={12}>
              {
                this.state.isCenterSelected ?
                <div className="col-8">
                  <div className="row mt-4">
                    <div className={`col-6`}>
                      <div className="p-2 home-card sq-shadow text-center">
                        <Typography variant="subtitle1"> Credit Limit </Typography>
                        <Typography variant="subtitle2"> {this.state.creditLimit === "" ? "Not set yet" : `${Number(this.state.creditLimit).toFixed(2)} OMR`} </Typography>
                        <Button variant="contained" color="primary" size="small" className="mt-2" onClick={() => this.setState({ showCreditLimitDialog : true })}>
                          {this.state.creditLimit === "" ? "Set" : "Update"} Limit
                        </Button>

                        <Dialog
                          open={this.state.showCreditLimitDialog}
                          maxWidth = "xs"
                          fullWidth
                          onClose= {this.handleCloseDialog}
                        >
                          <DialogTitle>
                            {this.state.creditLimit === "" ? "Set" : "Update"} Credit Limit
                          </DialogTitle>
                          <DialogContent>
                            <TextField
                              variant="outlined"
                              fullWidth
                              label = "Credit Limit"
                              name = "creditLimitValue"
                              onChange = {this.handleChange}
                              value = {this.state.creditLimitValue}
                            />
                          </DialogContent>
                          <DialogActions>
                            <Button variant="outlined" color="primary" onClick={this.handleCloseDialog}>Cancel</Button>
                            <Button variant="contained" color="primary" onClick={this.handleCreditLimitSave} disabled={this.state.creditLimitValue === ""}>Save</Button>
                          </DialogActions>
                        </Dialog>

                      </div>
                    </div>
                    <div className={`col-6`}>
                      <div className="p-2 home-card sq-shadow text-center">
                        <Typography variant="subtitle1"> Remaining Usability </Typography>
                        <Typography variant="subtitle2"> {this.state.availableLimit === "" ? "Not set yet" : `${Number(this.state.availableLimit).toFixed(2)} OMR`} </Typography>
                        <Button variant="contained" color="primary" size="small" className="mt-2" onClick={() => this.setState({ showUpdateLimitDialog : true })}>
                          Update Usability
                        </Button>

                        <Dialog
                          open={this.state.showUpdateLimitDialog}
                          maxWidth = "xs"
                          fullWidth
                          onClose= {this.handleCloseDialog}
                        >
                          <DialogTitle>
                            Update Credit Limit
                          </DialogTitle>
                          <DialogContent>
                            <TextField
                              variant="outlined"
                              fullWidth
                              label = "Credit Limit"
                              name = "availableLimitValue"
                              onChange = {this.handleChange}
                              error = {parseFloat(this.state.creditLimit) < parseFloat(this.state.availableLimitValue)}
                              helperText = {parseFloat(this.state.creditLimit) < parseFloat(this.state.availableLimitValue) ? "Cannot be more than credit limit" : ""}
                              value = {this.state.availableLimitValue}
                            />
                          </DialogContent>
                          <DialogActions>
                            <Button variant="outlined" color="primary" onClick={this.handleCloseDialog}>Cancel</Button>
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={this.handleAvalilableLimitSave}
                              disabled = {
                                parseFloat(this.state.creditLimit) < parseFloat(this.state.availableLimitValue) ||
                                this.state.availableLimitValue === ""
                              }
                            >
                              Save
                            </Button>
                          </DialogActions>
                        </Dialog>
                      </div>
                    </div>
                  </div>

                </div>
                : <Typography className="text-center">Please select affiliate.</Typography>
              }
            </Grid>
          </Grid>
        </div>
      </div>
    )
  }
}
export default withRouter(CreditLimits);