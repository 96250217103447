import config from "./config";
import axios from "axios";
import { BehaviorSubject, Observable } from "rxjs";
import _, { method } from "lodash";

let affiliateApp = {}
const baseUrl = config.baseUrl;

affiliateApp.getAllAffiliateApp = async () => {
  const url = `${baseUrl}/affiliate-app`;
  const res = await axios(url);
  return res;
}

affiliateApp.updateStatus = async (affiliateId, appId, data) => {
  const url = `${baseUrl}/affiliate/app/${affiliateId}/${appId}/change-status`;
  const res = await axios({ url, method: "PUT", data });
  return res;
}

affiliateApp.addAffiliateApp = async (affiliateId, data) => {
  console.log("id", affiliateId)
  const url = `${baseUrl}/affiliate/app/${affiliateId}`
  const res = await axios({ url, method: "POST", data });
  return res
};

affiliateApp.showAllAffiliateApp = async (affiliateId, appId) => {
  const url = `${baseUrl}/affiliate/app/${affiliateId}/${appId}`;
  const res = await axios(url);
  return res;
}

affiliateApp.changeAppSecret = async (affiliateId, appId) => {
  const url = `${baseUrl}/affiliate/app/${affiliateId}/${appId}/change-secret`;
  const res = await axios({url, method:"PUT"});
  return res;
}

affiliateApp.getAffiliateAppSecret = async (affiliateId, appId) => {
  const url = `${baseUrl}/affiliate/app/${affiliateId}/${appId}/show-secret`;
  const res = await axios(url);
  return res;
}


export default affiliateApp;
