import React from 'react';
import { Document, Page, StyleSheet, View, Text, Image, Font } from "@react-pdf/renderer";
import Arabic from "../../static/fonts/Arabic/A_Nefel_Adeti.ttf";
import Header from "../../static/images/pa-schedule-header.jpg";
import Footer from "../../static/images/pa-schedule-footer.jpg";
import Stamp from "../../static/images/pa-schedule-signature.jpg";
import { PLAN_TYPES } from '../../constants';

Font.register({ family: "Arabic", src: Arabic });

const styles = StyleSheet.create({
  page: {
    fontSize: 10,
    fontFamily: "Arabic",
    marginTop: 10
  },
  container: {
    paddingHorizontal: 20,
    paddingVertical: 20
  },
  paddingLeft1: {
    paddingLeft: 2
  },
  marginTop: {
    marginTop: 10
  },
  marginTop2: {
    marginTop: 20
  },
  marginBottom1: {
    marginBottom: 5
  },
  footerMarginBottom: {
    marginBottom: 30
  },
  headerImage: {
    width: "100%",
    paddingVertical: 5,
    paddingHorizontal: 20
  },
  border_right: {
    borderRight: "1pt solid black"
  },
  border_left: {
    borderLeft: "1pt solid black"
  },
  border_top: {
    borderTop: "1pt solid black"
  },
  border_bottom: {
    borderBottom: "1pt solid black"
  },
  borderAll: {
    border: "1pt solid black"
  },
  center: {
    textAlign: "center"
  },
  topTitle: {
    marginTop: 10
  },
  row: {
    display: "flex",
    flexDirection: "row"
  },
  top_column_1: {
    width: "27%",
    paddingLeft: 5
  },
  top_column_2: {
    justifyContent: "center",
    alignItems: "center",
    width: "3%"
  },
  top_column_3: {
    width: "70%",
    paddingLeft: 5
  },
  toMarLeft: {
    paddingLeft: 25
  },
  note_column_1: {
    width: "60%"
  },
  note_column_2: {
    width: "40%"
  },
  table_full_column: {
    width: "100%"
  },
  table_cell_padding: {
    paddingLeft: 5
  },
  table_half_column: {
    width: "50%"
  },
  text_right: {
    textAlign: "right"
  },
  capitalize: {
    textTransform: "capitalize"
  },
  beneficiary_column_1: {
    width: "6%"
  },
  beneficiary_column_2: {
    width: "38%",
    paddingHorizontal: 5
  },
  beneficiary_column_3: {
    width: "12%"
  },
  beneficiary_column_4: {
    width: "8%"
  },
  beneficiary_column_5: {
    width: "12%"
  },
  beneficiary_column_6: {
    width: "12%"
  },
  beneficiary_column_7: {
    width: "12%"
  },
  cause_column_1: {
    width: "40%"
  },
  cause_column_2: {
    width: "20%"
  },
  cause_column_3: {
    width: "40%"
  },
  footer: {
    width: "100%",
    paddingHorizontal: 20,
    position: "absolute",
    bottom: 10
  },
  stamp_row: {
    justifyContent: "flex-end",
    marginVertical: 10
  },
  stamp_container: {
    width: 180,
    display: "flex",
    alignItems: "center"
  },
  stamp: {
    height: 124,
    width: 129
  }
});

const TravelSchedule = ({ data }) => {

  const coveragesList = {
    "Emergency medical expenses hospitalization abroad": {
      [PLAN_TYPES.standard_travel]: "50,000 *",
      [PLAN_TYPES.lite_travel]: "50,000 *",
      [PLAN_TYPES.family_travel]: "50,000 * #",
      [PLAN_TYPES.senior_travel]: "50,000 *",
      [PLAN_TYPES.covid_shield_travel]: "150,000 *",
    },
    "COVID- 19 cover abroad": {
      [PLAN_TYPES.standard_travel]: "NA",
      [PLAN_TYPES.lite_travel]: "NA",
      [PLAN_TYPES.family_travel]: "NA",
      [PLAN_TYPES.senior_travel]: "NA",
      [PLAN_TYPES.covid_shield_travel]: "35,000 * (sub limit of Emergency medical expenses hospitalization abroad)",
    },
    "Emergency dental treatment": {
      [PLAN_TYPES.standard_travel]: "1000/Annum",
      [PLAN_TYPES.lite_travel]: "500/Annum",
      [PLAN_TYPES.family_travel]: "1000/Annum",
      [PLAN_TYPES.senior_travel]: "500/Annum",
      [PLAN_TYPES.covid_shield_travel]: "1000/Annum",
    },
    "Transport & Repatriation after illness or accident": {
      [PLAN_TYPES.standard_travel]: "150,000",
      [PLAN_TYPES.lite_travel]: "75,000",
      [PLAN_TYPES.family_travel]: "150,000",
      [PLAN_TYPES.senior_travel]: "100,000",
      [PLAN_TYPES.covid_shield_travel]: "100,000 (sub limit of Emergency medical expenses hospitalization abroad)",
    },
    "Sea and mountain rescue": {
      [PLAN_TYPES.standard_travel]: "20,000",
      [PLAN_TYPES.lite_travel]: "15,000",
      [PLAN_TYPES.family_travel]: "20,000",
      [PLAN_TYPES.senior_travel]: "15,000",
      [PLAN_TYPES.covid_shield_travel]: "20,000",
    },
    "Round trip ticket for visiting relative in case of hospitalization exceeding 7 days": {
      [PLAN_TYPES.standard_travel]: "1 Air Ticket Economy Class or Rail Ticket 1st Class",
      [PLAN_TYPES.lite_travel]: "NA",
      [PLAN_TYPES.family_travel]: "1 Air Ticket Economy Class or Rail Ticket 1st Class",
      [PLAN_TYPES.senior_travel]: "1 Air Ticket Economy Class or Rail Ticket 1st Class",
      [PLAN_TYPES.covid_shield_travel]: "1 Air Ticket Economy Class or Rail Ticket 1st Class",
    },
    "Accomodation expenses following illness or accident for visiting relative in case of hospitalization exceeding 7 days": {
      [PLAN_TYPES.standard_travel]: "100 per day, maximum upto 15 days",
      [PLAN_TYPES.lite_travel]: "NA",
      [PLAN_TYPES.family_travel]: "100 per day, maximum upto 15 days",
      [PLAN_TYPES.senior_travel]: "100 per day, maximum upto 15 days",
      [PLAN_TYPES.covid_shield_travel]: "100 per day, maximum upto 15 days",
    },
    "Repatriation of mortal remains in case of death of the Insured": {
      [PLAN_TYPES.standard_travel]: "35,000",
      [PLAN_TYPES.lite_travel]: "25,000",
      [PLAN_TYPES.family_travel]: "35,000",
      [PLAN_TYPES.senior_travel]: "25,000",
      [PLAN_TYPES.covid_shield_travel]: "35,000",
    },
    "Return and assistance to declared minor children of the Insured less than 18 years old": {
      [PLAN_TYPES.standard_travel]: "Actual Cost",
      [PLAN_TYPES.lite_travel]: "NA",
      [PLAN_TYPES.family_travel]: "Actual Cost",
      [PLAN_TYPES.senior_travel]: "NA",
      [PLAN_TYPES.covid_shield_travel]: "Actual Cost",
    },
    "Repatriation of immediate family member or any declared person travelling with the Insured in case of decease of the Insured or hostitalization exceeeding 7 days": {
      [PLAN_TYPES.standard_travel]: "1 Air Ticket Economy Class or Rail Ticket 1st Class",
      [PLAN_TYPES.lite_travel]: "NA",
      [PLAN_TYPES.family_travel]: "1 Air Ticket Economy Class or Rail Ticket 1st Class",
      [PLAN_TYPES.senior_travel]: "NA",
      [PLAN_TYPES.covid_shield_travel]: "1 Air Ticket Economy Class or Rail Ticket 1st Class",
    },
    "Emergency return home following death of family member": {
      [PLAN_TYPES.standard_travel]: "1 Air Ticket Economy Class or Rail Ticket 1st Class",
      [PLAN_TYPES.lite_travel]: "NA",
      [PLAN_TYPES.family_travel]: "1 Air Ticket Economy Class or Rail Ticket 1st Class",
      [PLAN_TYPES.senior_travel]: "NA",
      [PLAN_TYPES.covid_shield_travel]: "1 Air Ticket Economy Class or Rail Ticket 1st Class",
    },
    "Urgent dispatch of medicine": {
      [PLAN_TYPES.standard_travel]: "Actual Expenses",
      [PLAN_TYPES.lite_travel]: "NA",
      [PLAN_TYPES.family_travel]: "Actual Expenses",
      [PLAN_TYPES.senior_travel]: "Actual Expenses",
      [PLAN_TYPES.covid_shield_travel]: "Actual Expenses",
    },
    "Dispatch of urgent messages": {
      [PLAN_TYPES.standard_travel]: "Actual Expenses",
      [PLAN_TYPES.lite_travel]: "NA",
      [PLAN_TYPES.family_travel]: "Actual Expenses",
      [PLAN_TYPES.senior_travel]: "Actual Expenses",
      [PLAN_TYPES.covid_shield_travel]: "Actual Expenses",
    },
    "Appointment of local medical specialist": {
      [PLAN_TYPES.standard_travel]: "Actual Expenses",
      [PLAN_TYPES.lite_travel]: "NA",
      [PLAN_TYPES.family_travel]: "Actual Expenses",
      [PLAN_TYPES.senior_travel]: "Actual Expenses",
      [PLAN_TYPES.covid_shield_travel]: "Actual Expenses",
    },
    "General Information Service (Connection Services)": {
      [PLAN_TYPES.standard_travel]: "Actual Expenses",
      [PLAN_TYPES.lite_travel]: "NA",
      [PLAN_TYPES.family_travel]: "Actual Expenses",
      [PLAN_TYPES.senior_travel]: "Actual Expenses",
      [PLAN_TYPES.covid_shield_travel]: "Actual Expenses",
    },
    "Local Treatment Of Patient under Assistance Co's Supervision": {
      [PLAN_TYPES.standard_travel]: "Actual Expenses",
      [PLAN_TYPES.lite_travel]: "NA",
      [PLAN_TYPES.family_travel]: "Actual Expenses",
      [PLAN_TYPES.senior_travel]: "Actual Expenses",
      [PLAN_TYPES.covid_shield_travel]: "Actual Expenses",
    },
    "Cancellation and curtailment": {
      [PLAN_TYPES.standard_travel]: "1,000 (50 deductible **)",
      [PLAN_TYPES.lite_travel]: "750 (50 deductible **)",
      [PLAN_TYPES.family_travel]: "1,000 (50 deductible**)",
      [PLAN_TYPES.senior_travel]: "750 (50 deductible **)",
      [PLAN_TYPES.covid_shield_travel]: "1,000 (50 deductible **)",
    },
    "Delayed Depurture (Cancellation Option)": {
      [PLAN_TYPES.standard_travel]: "2,500",
      [PLAN_TYPES.lite_travel]: "NA",
      [PLAN_TYPES.family_travel]: "2,500",
      [PLAN_TYPES.senior_travel]: "2,500",
      [PLAN_TYPES.covid_shield_travel]: "2,500",
    },
    "Delayed Depurture ": {
      [PLAN_TYPES.standard_travel]: "30 for each 5 hours delay upto a maximum of 180",
      [PLAN_TYPES.lite_travel]: "NA",
      [PLAN_TYPES.family_travel]: "30 for each 5 hours delay upto a maximum of 180",
      [PLAN_TYPES.senior_travel]: "30 for each 5 hours delay upto a maximum of 180",
      [PLAN_TYPES.covid_shield_travel]: "30 for each 5 hours delay upto a maximum of 180",
    },
    "Loss of registered luggage": {
      [PLAN_TYPES.standard_travel]: "1,000",
      [PLAN_TYPES.lite_travel]: "NA",
      [PLAN_TYPES.family_travel]: "1,000",
      [PLAN_TYPES.senior_travel]: "1,000",
      [PLAN_TYPES.covid_shield_travel]: "1,000",
    },
    "Delayed luggage": {
      [PLAN_TYPES.standard_travel]: "300 after 12 hours",
      [PLAN_TYPES.lite_travel]: "NA",
      [PLAN_TYPES.family_travel]: "300 after 12 hours",
      [PLAN_TYPES.senior_travel]: "300 after 12 hours",
      [PLAN_TYPES.covid_shield_travel]: "300 after 12 hours",
    },
    "Passport loss: expenses related to formalities and issuing of a new passport": {
      [PLAN_TYPES.standard_travel]: "1,000",
      [PLAN_TYPES.lite_travel]: "500",
      [PLAN_TYPES.family_travel]: "1,000",
      [PLAN_TYPES.senior_travel]: "1,000",
      [PLAN_TYPES.covid_shield_travel]: "1,000",
    },
    "Death or PTD due to accident": {
      [PLAN_TYPES.standard_travel]: "25,000 ***",
      [PLAN_TYPES.lite_travel]: "NA",
      [PLAN_TYPES.family_travel]: "25,000 ***",
      [PLAN_TYPES.senior_travel]: "NA",
      [PLAN_TYPES.covid_shield_travel]: "25,000 ***",
    },
  };

  const {
    _id,
    travel_start_date,
    travel_end_date,
    personalInformation,
    nationality,
    amount,
    travel_duration_days,
    travellers_type,
    travellers,
    age,
    travel_coverage_type,
    plan
  } = data;

  const {
    fullName,
    resident_location,
    mobileNumber,
    passport,
    dob
  } = personalInformation;

  const {
    total
  } = amount;

  return (
    <Document creator="Dhofar" producer="Dhofar" title="Travel Insurance Policy Schedule">
      <Page style={styles.page}>
        <Image source={Header} style={styles.headerImage} fixed />
        <View style={styles.container}>
          <View style={[styles.topTitle, styles.center]}>
            <Text> Travel Insurance Policy Schedule</Text>
            <Text>جدول وثيقة التأمين على السفر</Text>
          </View>

          <View style={[styles.row, styles.border_top, styles.border_bottom, styles.border_left, styles.border_right]}>
            <View style={[styles.top_column_1]}>
              <Text> Policy No {"رقم الوثيقة"} </Text>
            </View>
            <View style={[styles.row, styles.top_column_2, styles.border_left, styles.border_right]}>
              <Text>:</Text>
            </View>
            <View style={[styles.top_column_3]}>
              <Text> {_id} </Text>
            </View>
          </View>

          <View style={[styles.row, styles.border_bottom, styles.border_left, styles.border_right]}>
            <View style={[styles.top_column_1]}>
              <Text> Period of Insurance {"مدة التأمين"} </Text>
            </View>
            <View style={[styles.row, styles.top_column_2, styles.border_left, styles.border_right]}>
              <Text>:</Text>
            </View>
            <View style={[styles.top_column_3, styles.row]}>
              <View>
                <Text> {"From  من"} &nbsp; &#58; &nbsp; {travel_start_date} </Text>
              </View>
              <View style={styles.toMarLeft}>
                <Text> {"To إلى"} &nbsp; &#58; &nbsp; {travel_end_date} </Text>
              </View>
            </View>
          </View>

          <View style={[styles.row, styles.border_bottom, styles.border_left, styles.border_right]}>
            <View style={[styles.top_column_1]}>
              <Text> Customer Name {"اسم الزبون"}</Text>
            </View>
            <View style={[styles.row, styles.top_column_2, styles.border_left, styles.border_right]}>
              <Text>:</Text>
            </View>
            <View style={[styles.top_column_3]}>
              <Text> {fullName} </Text>
            </View>
          </View>

          <View style={[styles.row, styles.border_bottom, styles.border_left, styles.border_right]}>
            <View style={[styles.top_column_1]}>
              <Text> Address {" العنوان"} </Text>
            </View>
            <View style={[styles.row, styles.top_column_2, styles.border_left, styles.border_right]}>
              <Text>:</Text>
            </View>
            <View style={[styles.top_column_3]}>
              <Text> {resident_location} </Text>
            </View>
          </View>

          <View style={[styles.row, styles.border_bottom, styles.border_left, styles.border_right]}>
            <View style={[styles.top_column_1]}>
              <Text> Phone Number {"رقم الهاتف"} </Text>
            </View>
            <View style={[styles.row, styles.top_column_2, styles.border_left, styles.border_right]}>
              <Text>:</Text>
            </View>
            <View style={[styles.top_column_3]}>
              <Text> {mobileNumber} </Text>
            </View>
          </View>

          <View style={[styles.row, styles.border_bottom, styles.border_left, styles.border_right]}>
            <View style={[styles.top_column_1]}>
              <Text> Premium (OMR) {"القسط ريال عماني"} </Text>
            </View>
            <View style={[styles.row, styles.top_column_2, styles.border_left, styles.border_right]}>
              <Text>:</Text>
            </View>
            <View style={[styles.top_column_3]}>
              <Text> {Number(total).toFixed(3)} </Text>
            </View>
          </View>

          <View style={[styles.row, styles.border_bottom, styles.border_left, styles.border_right]}>
            <View style={[styles.top_column_1]}>
              <Text> Policy Period (Days) {"مدة الوثيقة يوم"} </Text>
            </View>
            <View style={[styles.row, styles.top_column_2, styles.border_left, styles.border_right]}>
              <Text>:</Text>
            </View>
            <View style={[styles.top_column_3]}>
              <Text> {travel_duration_days} Days </Text>
            </View>
          </View>

          <View style={[styles.row, styles.marginTop]}>
            <View style={[styles.note_column_1]}>
              <Text> Policy Wording: As per DIC Policy Wordings as attached. </Text>
              {/* <Text> Claims Procedure: Please contact: MAPFRE ASSISTENCE/GULF ASSIST On: </Text> */}
            </View>
            <View style={[styles.note_column_2, styles.text_right]}>
              <Text> تنص الوثيقة: على حسب نص وثيقة التأمين الصادرة من شركة ظفار </Text>
              {/*<Text> للتأمين والمرفقة . </Text>*/}
            </View>

          </View>

          <View style={[styles.center, styles.borderAll, styles.marginTop]}>
            <Text>Traveller Details</Text>
          </View>

          <View style={[styles.row, styles.border_left, styles.border_right, styles.border_bottom]}>
            <View style={[styles.beneficiary_column_1, styles.center]}>
              <Text> SR. No </Text>
              <Text>{" م"}</Text>
            </View>
            <View style={[styles.center, styles.beneficiary_column_2, styles.border_left]}>
              <Text> Insured Name </Text>
              <Text> {"المؤمن له"} </Text>
            </View>
            <View style={[styles.center, styles.beneficiary_column_3, styles.border_left]}>
              <Text> DOB </Text>
              <Text> {"تاريخ الميلاد"} </Text>
            </View>
            <View style={[styles.center, styles.beneficiary_column_4, styles.border_left]}>
              <Text> Age  </Text>
              <Text> {"العمر"} </Text>
            </View>
            <View style={[styles.center, styles.beneficiary_column_5, styles.border_left]}>
              <Text> Passport No </Text>
              <Text> {"رقم جواز السفر"} </Text>
            </View>
            <View style={[styles.center, styles.beneficiary_column_6, styles.border_left]}>
              <Text> Nationality </Text>
              <Text> {"الجنسية"} </Text>
            </View>
            <View style={[styles.center, styles.beneficiary_column_7, styles.border_left]}>
              <Text> Relationship </Text>
              <Text> {"صلة القرابة"} </Text>
            </View>
          </View>

          {
            travellers_type === "family" ?
              travellers.map((traveller, ind) =>
                <View style={[styles.row, styles.border_left, styles.border_right, styles.border_bottom]}>
                  <View style={[styles.beneficiary_column_1, styles.center]}>
                    <Text> {ind + 1} </Text>
                  </View>
                  <View style={[styles.center, styles.beneficiary_column_2, styles.border_left]}>
                    <Text> {traveller.name}  </Text>
                  </View>
                  <View style={[styles.center, styles.beneficiary_column_3, styles.border_left]}>
                    <Text> {traveller.dob} </Text>
                  </View>
                  <View style={[styles.center, styles.beneficiary_column_4, styles.border_left]}>
                    <Text> {traveller.age}  </Text>
                  </View>
                  <View style={[styles.center, styles.beneficiary_column_5, styles.border_left]}>
                    <Text> {traveller.passport_no} </Text>
                  </View>
                  <View style={[styles.center, styles.beneficiary_column_6, styles.border_left]}>
                    <Text> {nationality} </Text>
                  </View>
                  <View style={[styles.center, styles.beneficiary_column_7, styles.border_left]}>
                    <Text> {traveller.relation} </Text>
                  </View>
                </View>
              )
              :
              <View style={[styles.row, styles.border_left, styles.border_right, styles.border_bottom]}>
                <View style={[styles.beneficiary_column_1, styles.center]}>
                  <Text> 1 </Text>
                </View>
                <View style={[styles.center, styles.beneficiary_column_2, styles.border_left]}>
                  <Text> {fullName}  </Text>
                </View>
                <View style={[styles.center, styles.beneficiary_column_3, styles.border_left]}>
                  <Text> {dob} </Text>
                </View>
                <View style={[styles.center, styles.beneficiary_column_4, styles.border_left]}>
                  <Text> {age}  </Text>
                </View>
                <View style={[styles.center, styles.beneficiary_column_5, styles.border_left]}>
                  <Text> {passport} </Text>
                </View>
                <View style={[styles.center, styles.beneficiary_column_6, styles.border_left]}>
                  <Text> {nationality} </Text>
                </View>
                <View style={[styles.center, styles.beneficiary_column_7, styles.border_left]}>
                  <Text> Self </Text>
                </View>
              </View>
          }

          <View style={[styles.row, styles.marginTop]}>
            <View style={[styles.note_column_1]}>
              <Text>Claims Procedure: Please contact: MAPFRE ASSISTENCE/GULF ASSIST</Text>
              <Text>On:</Text>
            </View>
            <View style={[styles.note_column_2, styles.text_right]}>
              <Text>ﺇﺟﺮﺍﺀﺍﺕ ﺍﻟﻤﻄﺎﻟﺒﺔ: ﻧﺄﻣﻞ ﺍﻟﺘﻮﺍﺻﻞ ﻣﻊ ﻣﺎﺏ ﻓﺮﻱ/ ﺟﻠﻒ ﺃﺳﺴﺖ</Text>
              <Text>ﻋﻠﻰ:</Text>
            </View>
          </View>

          <View style={[styles.row, styles.marginTop]}>
            <View style={[styles.note_column_1]}>
              <Text>Call within Oman: 24799420, International: +962 6500 8182 or email:</Text>
              <Text>travela@mapfre.com</Text>
            </View>
            <View style={[styles.note_column_2, styles.text_right]}>
              <Text> ﻣﻦ ﺳﻠﻄﻨﺔ ﻋﻤﺎﻥ ﻭﺩﻭﻟﻴﺎ ﻋﻠﻰ ﺭﻗﻢ 28180056 269+ ﺃﻭ ﺍﻟﺒﺮﻳﺪ <Text>24799420</Text> </Text>
              <Text>travela@mapfre.com ﺍﻻﻟﻜﺘﺮﻭﻧﻲ</Text>
            </View>
          </View>

          <View style={[styles.row]}>
            <View style={[styles.note_column_1]}>
              <Text>MEDICAL & EMERGENCY ASSISTANCE: Emergency telephone number:</Text>
              <Text>+962 6500 8182</Text>
            </View>
            <View style={[styles.note_column_2, styles.text_right]}>
              <Text>ﻟﻠﻤﺴﺎﻋﺪﺓ ﺍﻟﻄﺒﻴﺔ ﻭﻓﻲ ﺣﺎﻻﺕ ﺍﻟﻄﻮﺍﺭﺉ: ﺭﻗﻢ ﻫﺎﺗﻒ ﺍﻟﻄﻮﺍﺭﺉ <Text> +962 6500 8182 </Text></Text>
            </View>
          </View>

          <View style={[styles.row]}>
            <View style={[styles.note_column_1]}>
              <Text>Since the appearance of an event that could be included in any of the</Text>
              <Text>guarantees described previously, the beneficiary or any person acting in</Text>
              <Text>his place will necessarily contact, in the shortest possible _me, in every</Text>
              <Text>case, the Alarm Centre mentioned above, which will be available to help</Text>
              <Text>any person 24/7 365 days. Note: By dialling MAPFRE Assistance</Text>
              <Text>Emergency number, the insured will be prompted to provide:</Text>
              <Text>&#8226; Passport or Identity card number .</Text>
              <Text>&#8226; Policy number.</Text>
              <Text>&#8226; Full name of insured.</Text>
              <Text>&#8226; The cause of the call.</Text>
              <Text>&#8226; The place he/she located (Hotel/City/Address/Phone number).</Text>
              <Text>&#8226; A full medical certificate from the attending doctors must be</Text>
              <Text>submitted along with all original reports /bills/receipts</Text>
            </View>
            <View style={[styles.note_column_2, styles.text_right]}>
              <Text>نظرًا لظهور حدث يمكن تضمينه في أي من الضمانات الموصوفة سابقًا ، فإن</Text>
              <Text>المستفيد أو أي شخص يعمل مكانه سيتواصل بالضرورة ، في أقرب وقت ممكن ،</Text>
              <Text>في كل حالة ، بمركز الطوارئ المذكور أعلاه ، والذي سيكون متاح لمساعدة أي</Text>
              <Text>شخص 563 7/42 يومًا. ملاحظة: ﻋﻨﺪ ﺍﻻﺗﺼﺎﻝ ﻋﻠﻰ ﺭﻗﻢ ﺍﻟﻤﺴﺎﻋﺪﺓ ﻓﻲ ﺣﺎﻻﺕ</Text>
              <Text>ﺍﻟﻄﻮﺍﺭﺉ ﻣﻦ ﻣﺎﺏ ﻓﺮ، ﺳﻴُﻄﻠﺐ ﻣﻦ ﺍﻟﻤﺆﻣﻦ ﻟﻪ ﺃﻥ ﻳﻘﺪﻡ ﻣﺎ ﻳﻠﻲ:</Text>
              <Text>من المؤمن له أن يقدم ما يلي:</Text>
              <Text>ﺭﻗﻢ ﺟﻮﺍﺯ ﺍﻟﺴﻔﺮ ﺃﻭ ﺑﻄﺎﻗﺔ ﺍﻟﻬﻮﻳﺔ <Text> &#8226; </Text> </Text>
              <Text>رقم وثيقة التأمين <Text> &#8226; </Text> </Text>
              <Text>اسم المؤمن بالكامل <Text> &#8226; </Text> </Text>
              <Text>سبب الاتصال <Text> &#8226; </Text> </Text>
              <Text>المكان الذي يوجد فيه )فندق / مدينة / عنوان / هاتف( <Text> &#8226; </Text> </Text>
              <Text>جب تقديم شهادة طبية كاملة من الأطباء المعالجين مع جميع التقارير / الفواتير / <Text> &#8226; </Text> </Text>
              <Text> الإيصالات الأصلية.</Text>
            </View>
          </View>

          <View style={[styles.row, styles.marginTop]}>
            <View style={[styles.note_column_1]}>
              <Text>THIS POLICY IS SUJECT TO THE ATTACHED POLICY</Text>
              <Text>WORDING, ITS GENERAL AND SPECIAL CONDITIONS,</Text>
              <Text>CLAUSES, ENDORSEMENT, EXCLUSION etc. WHICH FORM</Text>
              <Text>PART OF THIS POLICY SCHEDULE</Text>
            </View>
            <View style={[styles.note_column_2, styles.text_right]}>
              <Text>تخضع هذه الوثيقة إلى صيغة السياسة المرفقة ، وشروطها العامة</Text>
              <Text>والخاصة ، والبنود ، والمصادقة ، والاستثناء وما إلى ذلك، والتي</Text>
              <Text>تشكل جزءًا من جدول هذه الوثيقة</Text>
            </View>
          </View>

          <View break>
            <View style={[styles.row, styles.marginTop, styles.border_top, styles.border_left, styles.border_right]}>
              <View style={[styles.cause_column_1]}>
                <Text> &nbsp; SPECIAL CLAUSES </Text>
              </View>
              <View style={[styles.row, styles.center, styles.cause_column_2]}>
                <Text>NA</Text>
              </View>
              <View style={[styles.cause_column_3, styles.text_right]}>
                <Text> شروط خاصه </Text>
              </View>
            </View>

            <View style={[styles.row, styles.border_top, styles.border_left, styles.border_right]}>
              <View style={[styles.table_half_column, styles.border_right, styles.paddingLeft1]}>
                <Text>Summary of Benefits</Text>
                <Text>
                  ملخص المزايا
                </Text>
              </View>
              <View style={[styles.table_half_column, styles.paddingLeft1]}>
                <View>
                  <View>
                    <Text>{travel_coverage_type}</Text>
                  </View>
                  <View>
                    <Text> {plan} Travel </Text>
                  </View>
                </View>
                <View style={[styles.border_top]}>
                  <Text> Limit (up to) in USD {"الحد حتى دولار امريكي"}</Text>
                </View>
              </View>
            </View>

            {
              [
                {
                  key: "Emergency medical expenses hospitalization abroad",
                  value: coveragesList["Emergency medical expenses hospitalization abroad"][plan]
                },
                { key: "COVID-19 cover abroad", value: coveragesList["COVID- 19 cover abroad"][plan] },
                { key: "Emergency dental treatment", value: coveragesList["Emergency dental treatment"][plan] },
                {
                  key: "Transport & Repatriation after illness or accident",
                  value: coveragesList["Transport & Repatriation after illness or accident"][plan]
                },
                {
                  key: "Round trip ticket for visiting relative in case of hospitalization exceeding 7 days",
                  value: coveragesList["Round trip ticket for visiting relative in case of hospitalization exceeding 7 days"][plan]
                },
                {
                  key: "Accomodation expenses following illness or accident for visiting relative in case of hospitalization",
                  value: coveragesList["Accomodation expenses following illness or accident for visiting relative in case of hospitalization exceeding 7 days"][plan]
                },
                {
                  key: "Repatriation of mortal remains in case of death of the Insured",
                  value: coveragesList["Repatriation of mortal remains in case of death of the Insured"][plan]
                },
                {
                  key: "Return and assistance to declared minor children of the Insured less than 18 years old",
                  value: coveragesList["Return and assistance to declared minor children of the Insured less than 18 years old"][plan]
                },
                {
                  key: "Repatriation of immediate family member or any declared person travelling with the Insured in case of decease of the Insured or hostitalization exceeeding 7 days",
                  value: coveragesList["Repatriation of immediate family member or any declared person travelling with the Insured in case of decease of the Insured or hostitalization exceeeding 7 days"][plan]
                },
                {
                  key: "Emergency return home following death of family member",
                  value: coveragesList["Emergency return home following death of family member"][plan]
                },
                {
                  key: "Urgent dispatch of medicine ",
                  value: coveragesList["Urgent dispatch of medicine"][plan]
                },
                {
                  key: "Dispatch of urgent messages",
                  value: coveragesList["Dispatch of urgent messages"][plan]
                },
                {
                  key: "Appointment of local medical specialist ",
                  value: coveragesList["Appointment of local medical specialist"][plan]
                },
                {
                  key: "General Information Service (Connection Services)",
                  value: coveragesList["General Information Service (Connection Services)"][plan]
                },
                {
                  key: "Local Treatment Of Patient under Assistance Co's Supervision",
                  value: coveragesList["Local Treatment Of Patient under Assistance Co's Supervision"][plan]
                },
                {
                  key: "Cancellation and curtailment",
                  value: coveragesList["Cancellation and curtailment"][plan]
                },
                {
                  key: "Delayed Depurture (Cancellation Option)",
                  value: coveragesList["Delayed Depurture (Cancellation Option)"][plan]
                },
                {
                  key: "Delayed Depurture",
                  value: coveragesList["Delayed Depurture "][plan]
                },
                {
                  key: "Loss of registered luggage ",
                  value: coveragesList["Loss of registered luggage"][plan]
                },
                {
                  key: "Delayed luggage",
                  value: coveragesList["Delayed luggage"][plan]
                },
                {
                  key: "Passport loss: expenses related to formalities and issuing of a new passport",
                  value: coveragesList["Passport loss: expenses related to formalities and issuing of a new passport"][plan]
                },
                {
                  key: "Death or PTD due to accident",
                  value: coveragesList["Death or PTD due to accident"][plan]
                },
                {
                  key: "Death or PTD due to accident",
                  value: coveragesList["Death or PTD due to accident"][plan]
                },
              ].map((e, index) => {
                return (<View key={index} style={[styles.row, styles.border_top, styles.border_left, styles.border_right]}>
                  <View style={[styles.table_half_column, styles.border_right, styles.paddingLeft1]}>
                    <Text>{e.key}</Text>
                  </View>
                  <View style={[styles.table_half_column, styles.paddingLeft1]}>
                    <Text>{e.value}</Text>
                  </View>
                </View>)
              })
            }

            <View style={[styles.row, styles.marginBottom1, styles.border_top, styles.border_bottom, styles.border_left, styles.border_right]}>
              <View style={[styles.table_half_column, styles.border_right, styles.paddingLeft1]}>
                <Text> Sea and mountain rescue </Text>
              </View>
              <View style={[styles.table_half_column, styles.paddingLeft1]}>
                <Text> {coveragesList["Sea and mountain rescue"][plan]}</Text>
              </View>
            </View>
          </View>

          <View break>
            <View style={[styles.row]}>
              <View style={[styles.note_column_1]}>
                <Text> Law & Jurisdiction: Sultanate Of Oman </Text>
              </View>
              <View style={[styles.note_column_2, styles.center]}>
                <Text> القانون والاختصاص القضائي: سلطنة عمان </Text>
              </View>
            </View>

            <View style={[styles.row]}>
              <View style={[styles.note_column_1]}>
                <Text> In witness whereof this policy is signed for and on behalf of the Company DHOFAR INSURANCE COMPANY (S.A.O.G) </Text>
              </View>
              <View style={[styles.note_column_2, styles.center]}>
                <Text> التوقيع نيابة عن شركة ظفار للتأمين ش.م.ع.ع </Text>
              </View>
            </View>

            <View style={[styles.row, styles.stamp_row]}>
              <View style={[styles.stamp_container]}>
                <Text> Signature of the Issuing Authority </Text>
                <Text> التوقيع والجهة المصدرة </Text>
                <Image source={Stamp} style={styles.stamp} />
                <Text> Stamp / Seal {"الختم"} </Text>
              </View>
            </View>

            <View style={[styles.row, styles.marginTop, styles.footerMarginBottom]}>
              <View style={[styles.note_column_1]}>
                <Text>N.B. Please examine this document carefully and advise us immediately if any of the terms & conditions does not accurately meet your requirements or is incorrect.</Text>
              </View>
              <View style={[styles.note_column_2, styles.center]}>
                <Text> {"تنبيه: نأمل قراءة الوثيقة بعناية وإبلاغنا فوراً بأي شروط أو أحكام لا تلبي احتياجاتك أو غير صحيحة"} </Text>
              </View>
            </View>

          </View>

          <View break>    
            <View style={[styles.row, styles.marginTop, styles.border_top, styles.border_left, styles.border_right]}>
              <View style={[styles.table_full_column, styles.table_cell_padding]}>
                <Text> VAT CLAUSE: </Text>
              </View>
            </View>
            <View style={[styles.row, styles.border_top, styles.border_left, styles.border_right, styles.border_bottom, styles.footerMarginBottom]}>
              <View style={[styles.table_full_column, styles.table_cell_padding]}>
                <Text> All premiums, fees, charges [under the policy/in this quotation] are shown exclusive of any value added tax, or any other similar taxes, charges or duties applicable at the time of the proposal or which may become applicable as a result of provision of insurance services (“Applicable Tax”) at any point in time. The Applicable Tax shall be added to the Premiums/fees/charges in accordance with the applicable laws and regulations of the Sultanate of Oman and shall be solely payable by the Policyholder/Insured. </Text>
                <Text style={[styles.marginTop]}> Article 1: The Premiums, Fees and/or Charges stated in this [Insurance] Policy are shown exclusive of any value added tax, goods and service tax or sales tax, or any other similar taxes, charges or duties to the extent any such tax is applicable or will become applicable as a result of provision of the services under this Policy (hereinafter – “Applicable Tax”). </Text>
              </View>
            </View>
          </View>
            
        </View>
        <View fixed>
          <Image source={Footer} style={styles.footer} />
        </View>
      </Page>
    </Document>
  )
}

export default TravelSchedule;