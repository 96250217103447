import React from "react";
import Navigation from "../common/Navigation";
import {
    Paper,
    Typography,
    Grid,
    Button,
    Drawer,
    TextField,
    InputAdornment,
    IconButton,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Switch,
    TablePagination,
    MenuItem,
    List,
    ListItem,
    Checkbox,
    ListItemText,
    ListItemIcon,
    FormControlLabel,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from "@material-ui/core";
import { Link, withRouter } from "react-router-dom";
import Snackbar from "../common/Snackbar";
import { Edit, Delete, Visibility } from "@material-ui/icons";
import AffiliateAppService from "../../services/affiliateApp";
import _ from "lodash";
import AffiliateAppChangeStatus from "../AffiliateAppUpdateStatus";
import utils from "../../services/utils";

class index extends React.Component {
    state = {
        affiliateApp: [],
        dialogActive: false,
        deleteTrue: false,
        processing: false
    };

    componentDidMount() {
        this.getAffiliateApp();
    }

    getAffiliateApp = async () => {
        const data = await AffiliateAppService.getAllAffiliateApp();
        console.log("DATA", data)
        this.setState({ affiliateApp: data.data.data });
    };
    render() {
        return (
            <div className="d-flex">
                <Navigation />
                {/* <Snackbar
          showSnackbar={this.state.showSnackbar}
          messageType={this.state.messageType}
          snackBarMessage={this.state.snackBarMessage}
          onCloseSnackbar={this.handleCloseSnackbar}
        /> */}
                <div className="container-fluid">
                    <Paper className="p-2 sq-shadow mb-3 d-flex">
                        <Typography> <Link to={`${process.env.PUBLIC_URL}/`}>Home</Link> </Typography>
                        <Typography className="mr-2 ml-2">/</Typography>
                        <Typography><Link to={`${process.env.PUBLIC_URL}/affiliator`}>Affiliators</Link></Typography>
                        <Typography className="mr-2 ml-2">/</Typography>
                        <Typography> Api Kit </Typography>
                    </Paper>
                    <Grid container spacing={2}>
                        <Grid item xs={6} className="d-flex align-items-center">
                            <Link
                                to={`/affiliator/dhofar-api-kit/create`}
                                className="mr-3"
                            >
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => this.setState({ drawerOpen: true })}
                                >
                                    Add New Api Kit
                                </Button>
                            </Link>
                        </Grid>

                        <Grid item xs={12}>
                            <Paper className="sq-shadow policies-table-container">
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>App Name </TableCell>
                                            <TableCell>Affiliate Name</TableCell>
                                            <TableCell>Status</TableCell>
                                            <TableCell>Show</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    {this.state.affiliateApp.length ? (
                                        <TableBody>
                                            {this.state.affiliateApp.map((app) => (
                                                <TableRow key={app._id}>
                                                    <TableCell>{app.app_name}</TableCell>
                                                    <TableCell>{app.affiliate_name}</TableCell>
                                                    <TableCell>
                                                        <AffiliateAppChangeStatus affiliateAppId={app.affiliate_id} status={app.status} appId={app._id} loadCallback={this.getAffiliateApp} />
                                                        {utils.getStatusText(app.status)}
                                                    </TableCell>
                                                    <TableCell><Link
                                                        to={`${process.env.PUBLIC_URL}/affiliator/dhofar-api-kit/show/${app.affiliate_id}/${app._id}`}
                                                        className="table-eye-link"
                                                    >
                                                        <Visibility />
                                                    </Link></TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    ) : (
                                        <TableBody>
                                            <TableRow>
                                                <TableCell colSpan={3} align="center" width="100%">
                                                    No data found
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    )}

                                </Table>
                            </Paper>
                        </Grid>
                    </Grid>
                </div>
            </div>
        );
    }
}
export default withRouter(index);
