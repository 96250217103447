import React from "react";
import Navigation from "../common/Navigation";
import {
  Paper,
  Typography,
  Grid,
  Button,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TablePagination
} from "@material-ui/core";
import { Link, withRouter } from "react-router-dom";
import Snackbar from "../common/Snackbar";
import { Edit, Delete } from "@material-ui/icons";
import utils from "../../services/utils";
import Eye from "mdi-react/EyeIcon";
import DiscountService from "../../services/discount";
import _ from "lodash";
import DeleteConfirmDialog from "../common/DeleteConfirmDialog";
import { PERMISSIONS } from "../../constants";

class DiscountList extends React.Component {

  state = {
    discounts: [],
    page: 0,
    rowsPerPage: 10,
    count: 1,
    dialogActive: false,
    deleteTrue: false,
    delete_id: "",
    processing: false,
    permissions : []
  };

  componentDidMount() {
    this.getDiscount();
    window.addEventListener("storage", () => {
      const permissions = localStorage.getItem("permissions") ? JSON.parse(localStorage.getItem("permissions")) : []
      this.setState({ permissions })
    })
  }

  getDiscount = async () => {
    const data = await DiscountService.getDiscountList();
    this.setState({ discounts: data.data, count: 1 });
  };

  handleChangePage = (e, page) => {
    this.setState({ page }, this.getDiscount);
  };

  handleChangeRowsPerPage = (e) => {
    this.setState({ rowsPerPage: e.target.value }, this.getDiscount);
  };

  confirmDelete = async (id) => {
    this.setState({ dialogActive: true, delete_id: id });
  };

  onCloseDialog = async (confirm) => {
    if (confirm === true) {
      this.deleteDiscount(this.state.delete_id);
    }
    else {
      this.setState({ dialogActive: false, delete_id: undefined });
    }
  };

  deleteDiscount = async (id) => {
    this.setState({ deleteTrue: true, processing: true });
    let deleted = false;
    try {
      const response = await DiscountService.deleteDiscount(id);
      deleted = _.get(response, "data.status", false) ? true : false;
    } catch (error) { }

    if (deleted) {
      this.setState({
        showSnackbar: true,
        messageType: "danger",
        snackBarMessage: "Discount deleted successfully",
      });
    } else {
      this.setState({
        showSnackbar: true,
        messageType: "danger",
        snackBarMessage: "Something went wrong.",
      });
    }
    this.setState({ dialogActive: false, delete_id: undefined, processing: false });
    this.getDiscount();
  };

  render() {
    const permissions = this.state.permissions;

    return (
      <div className="d-flex">
        <Navigation />
        <Snackbar
          showSnackbar={this.state.showSnackbar}
          messageType={this.state.messageType}
          snackBarMessage={this.state.snackBarMessage}
          onCloseSnackbar={this.handleCloseSnackbar}
        />
        <div className="container-fluid">
          <Paper className="p-2 sq-shadow mb-3 d-flex">
            <Typography> <Link to={`${process.env.PUBLIC_URL}/`}>Home</Link> </Typography>
            <Typography className="mr-2 ml-2">/</Typography>
            <Typography> Discounts </Typography>
          </Paper>
          <Grid container spacing={2}>
            {
              permissions.includes(PERMISSIONS.CREATE_DISCOUNT_CODES) &&
              <Grid item xs={6} className="d-flex align-items-center">
                <Link
                  to={`${process.env.PUBLIC_URL}/discounts/create`}
                  className="mr-3"
                >
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => this.setState({ drawerOpen: true })}
                  >
                    Add New Discount
                  </Button>
                </Link>
              </Grid>
            }

            <Grid item xs={12}>
              <Paper className="sq-shadow policies-table-container">
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell> Code </TableCell>
                      <TableCell> Validity </TableCell>
                      <TableCell> Type </TableCell>
                      <TableCell> Discount </TableCell>
                      <TableCell> Status </TableCell>
                      <TableCell> Max Discount Amount </TableCell>
                      <TableCell> Min Amount </TableCell>
                      <TableCell> Created At </TableCell>
                      {
                        permissions.includes(PERMISSIONS.DELETE_DISCOUNT_CODES) &&
                        <TableCell width="8%"> Delete </TableCell>
                      }
                      {
                        permissions.includes(PERMISSIONS.EDIT_DISCOUNT_CODES) &&
                        <TableCell width="8%"> Edit </TableCell>
                      }
                      <TableCell width="8%"> View </TableCell>
                    </TableRow>
                  </TableHead>

                  {this.state.count != 0 ? (
                    <TableBody>
                      {this.state.discounts.map((discount) => (
                        <TableRow key={discount._id}>
                          <TableCell>{discount.code}</TableCell>
                          <TableCell>{utils.getFormatedDate(discount.validity, "DD-MM-YYYY")}</TableCell>
                          <TableCell className="text-capitalize">{discount.type}</TableCell>
                          <TableCell>
                            {discount.type === "flat" && (utils.getFormattedAmount(discount.discount))}
                            {discount.type === "percentage" && (`${discount.discount}%`)}
                          </TableCell>
                          <TableCell >{(discount.status == 1)?"Active":"Deactive"}</TableCell>
                          <TableCell>{utils.getFormattedAmount(discount.max_discount)}</TableCell>
                          <TableCell>{utils.getFormattedAmount(discount.min_amount)}</TableCell>
                          <TableCell>{utils.getFormatedDate(discount.created_at)}</TableCell>
                          {
                            permissions.includes(PERMISSIONS.DELETE_DISCOUNT_CODES) &&
                            <TableCell width="8%">
                              <Link
                                to="#"
                                onClick={() => this.confirmDelete(discount._id)}
                                className="table-eye-link"
                              >
                                <Delete />
                              </Link>
                            </TableCell>
                          }
                          {
                            permissions.includes(PERMISSIONS.EDIT_DISCOUNT_CODES) &&
                            <TableCell width="8%">
                              <Link
                                to={`/discounts/edit/${discount._id}`}
                                className="table-eye-link"
                              >
                                <Edit />
                              </Link>
                            </TableCell>
                          }
                          <TableCell>
                            <Link 
                              to={`/discount/${discount._id}`} 
                              className="table-eye-link">
                              <Eye />
                            </Link>
                          </TableCell>
                        </TableRow>
                      ))}

                      <TableRow>
                        <TablePagination
                          count={this.state.count}
                          page={this.state.page}
                          rowsPerPage={this.state.rowsPerPage}
                          onChangePage={this.handleChangePage}
                          onChangeRowsPerPage={this.handleChangeRowsPerPage}
                          rowsPerPageOptions={[10, 25, 50]}
                        />
                      </TableRow>
                    </TableBody>
                  ) : (
                    <TableCell colSpan={3} align="center" width="100%">
                      No data found
                    </TableCell>
                  )}
                </Table>
              </Paper>
            </Grid>

            <DeleteConfirmDialog
              onClose={this.onCloseDialog}
              open={this.state.dialogActive}
              content="are you sure want to delete"
              processing={this.state.processing}
            />
          </Grid>
        </div>
      </div>
    );
  }
}
export default withRouter(DiscountList);
