import React from "react";
import { Document, Page, View, Text, Image, StyleSheet, Font } from "@react-pdf/renderer"
import DhofarLogo from "../../../static/images/dhofar-logo.jpg";
import MedNet from "../../../static/images/mednet.jpg";
import Lato from "../../../static/fonts/Lato/Lato-Black.ttf";
import moment from "moment";

Font.register({ family: "Lato", src: Lato });

const styles = StyleSheet.create({
  page : {
    paddingTop : 30,
    fontSize : 9,
    fontFamily : "Lato",
    fontWeight : "black"
  },
  card_container : {
    width : 330,
    marginLeft : "auto",
    marginRight : "auto",
    paddingVertical : 10,
    paddingLeft : 15,
    paddingRight : 25,
    border : "1pt solid #ccc",
    borderRadius : 4,
    backgroundColor : "#fff",
    lineHeight : 1.4,
    position : "relative"
  },
  row : {
    display : "flex",
    flexDirection : "row"
  },
  company_logo : {
    height : 30
  },
  label : {
    width : 60
  },
  separator : {
    backgroundColor : "#000",
    height : "1pt",
    width : "100%",
    marginVertical : 2
  },
  call_center_text : {
    position : "absolute",
    right : -63,
    bottom : 85,
    transform : "rotate(90deg)"
  }
});

const IndividualPDF = ({ data }) => {

  const startDate = data.startDate;
  const network = data.network;

  const getStartDate = () => {
    return moment(startDate).format("DD/MM/YYYY")
  }
  
  const getEndDate = () => {
    let numYears = 1;
    return moment(startDate).subtract(1, "day").add(numYears, "year").format("DD/MM/YYYY");
  }
  
  const getYob = () => {
    const dob = data.personalInformation.dob;

    let date = moment(dob, 'DD-MM-YYYY');
    if(!date.isValid()){
      date = moment(dob, 'DD-MMM-YYYY');
    }
  
    return date.get("year");
  }
  
  const getNetworkText = () => {
    let text = "";
    if(network === "Silver"){
      text = "Premium"
    }
    else if(network === "Gold"){
      text = "(30% Copay @ MPH on all services)"
    }
  
    return text;
  }

  return(
    <Document>
      <Page style={styles.page}>
        <View style={styles.card_container}>
          <View style={[styles.row, {justifyContent : "space-between", marginBottom : 2}]}>
            <Image source={DhofarLogo} style={styles.company_logo} />
            <Image source={MedNet} style={styles.company_logo} />
          </View>
          <View style={styles.row}>
            <View style={styles.label}>
              <Text> Card No : </Text>
            </View>
            <Text> {data.policy_num} </Text>
          </View>

          <View style={styles.row}>
            <View style={styles.label}>
              <Text> Validity : </Text>
            </View>
            <View style={styles.row}>
              <Text> {getStartDate()} </Text>
              <Text> To </Text>
              <Text> {getEndDate()} </Text>
            </View>
          </View>

          <View style={styles.row}>
            <View style={styles.label}>
              <Text> Civil No : </Text>
            </View>
            <Text> {data.civil_id} </Text>
          </View>

          <View style={styles.separator}></View>

          <View style={styles.row}>
            <View style={styles.label}>
              <Text> Name : </Text>
            </View>
            <Text> {data.personalInformation.fullName} </Text>
          </View>

          <View style={styles.row}>
            <View style={styles.label}>
              <Text> Holder : </Text>
            </View>
            <Text> {data.personalInformation.fullName} </Text>
          </View>

          <View style={styles.row}>
            <View style={styles.label}>
              <Text> Policy Type : </Text>
            </View>
            <View style={styles.row}>
              <Text style={{ color : "red" }}> Individual Policy </Text>
              <Text style={{ marginLeft : 20 }}> Y.O.B. </Text>
              <Text> {getYob()} </Text>
            </View>
          </View>

          <View style={styles.separator}></View>

          <View style={styles.row}>
            <View style={styles.label}>
              <Text> Network : </Text>
            </View>
            <Text> {network} {getNetworkText()} </Text>
          </View>

          <View style={styles.row}>
            <Text> Ded : {data.op} RO, Co-pay : {data.op} RO on Lab and Rad, PH : {data.co_pay === "Nil" ? "0%" : data.co_pay} </Text>
          </View>

          {
            (data && data.plan === "Gold") &&
            <View style={styles.row}>
              <Text> Dental & Optical: Y-20%, ALT : Y-10%</Text>
            </View>
          }
          
          <View style={styles.row}>
            <Text> Vitamins and Minerals Covered </Text>
          </View>

          <View style={styles.separator}></View>

          <View style={{ color : "red" }}>
            <Text>
              Pre-existing and Chronic Condition listed in MEMBER BENEFIT
            </Text>
            <Text>
              DOCUMENT (available online) are not covered
            </Text>
            <Text>
              Network Access in OMAN Only
            </Text>
          </View>

          <View style={styles.call_center_text}>
            <Text>
              MedNet Call Centre 24/7 : 8008 5005
            </Text>
          </View>

        </View>
      </Page>
    </Document>
  )
}

export default IndividualPDF;