import React from "react";
import Navigation from "../common/Navigation";
import { Grid, TextField, MenuItem, Button, Table, TableHead, TableBody, TableRow, TableCell, Paper, Typography, TablePagination } from "@material-ui/core";
import "./style.css";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import PolicyService from "../../services/policy";
import Eye from "mdi-react/EyeIcon";
import { Link, withRouter } from "react-router-dom";
import { saveAs } from "file-saver";
import AffiliateSelect from "../common/AffiliateSelect";
import _ from "lodash";
import helpers from "../../helpers";
import { REPORT_STATUS_OPTIONS, REPORT_SOB_OPTIONS } from "../../constants";
import * as XLSX from "xlsx-js-style";
import moment from "moment";
import BranchList from "../../services/branches.json";
import RegionList from "../../services/region.json";

const months = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"]

class DomesticWorkersReport extends React.Component {
  state = {
    application: "domestic",
    startDate: null,
    endDate: null,
    showTable: false,
    data: [],
    page: 0,
    rowsPerPage: 10,
    totalRecords: 0,
    headings: [],
    isLoading: false,
    isDownloading: false,
    status: "All",
    loadedPercentage: 0,
    sob : "All",
    region : "All",
    branch : "All"
  }
  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    });
  }

  handleStartDateChange = (date) => {
    const startDate = new Date(date.setHours(0, 0, 0, 0)).getTime();
    this.setState({ startDate });
  }

  handleEndDateChange = (date) => {
    const endDate = new Date(date.setHours(23, 59, 59, 999)).getTime();
    this.setState({ endDate });
  }

  componentDidMount() {
    let date = new Date().setHours(23, 59, 59, 999);
    let endDate = new Date(date).getTime();
    this.setState({
      startDate: new Date(new Date().setHours(0, 0, 0, 0)).getTime(),
      endDate
    });

  }

  handleChangePage = (e, page) => {
    this.setState({ page }, this.handleSearch);
  }

  handleChangeRowsPerPage = (e) => {
    this.setState({ rowsPerPage: e.target.value }, this.handleSearch);
  }

  handleSearch = async () => {
    this.setState({ isLoading: true });
    const status = this.state.status === "All" ? undefined : this.state.status;
    const sob = this.state.sob === "All" ? undefined : this.state.sob;
    const region = this.state.region === "All" ? undefined : this.state.region;
    const branch = this.state.branch === "All" ? undefined : this.state.branch;

    const data = await PolicyService.getHealthReport({
      startDate : this.state.startDate,
      endDate : this.state.endDate, 
      type : "domestic",
      status,
      page : this.state.page,
      limit : this.state.rowsPerPage,
      sob : sob,
      region : region,
      branch : branch
    });

    if (data.status === 200) {
      this.setState({ showTable: true, data: data.data.data, totalRecords: data.data.count })
    }

    let headings = [
      "Sr No",
      "Policy Number",
      "Card Number",
      "Sponsor Name",
      "Sponsor Civil ID",
      "Sponsor Type",
      "Civil ID",
      "Name Of policy holder",
      "DOB",
      "Gender",
      "Occupation",
      "Occupation Code",
      "Passport / Civil ID Expiry date",
      "Marital Status",
      "Nationality",
      "Policy Start Date",
      "Policy End Date",
      "Email ID",
      "Contact No",
      "Application Type",
      "Net Premium",
      "Discount Code",
      "Discount",
      "Policy Fee",
      "Emergency Fund Fee",
      "Goverment Fee",
      "Gross Premium",
      "VAT",
      "Loading",
      "Plan Name",
      "Payment Status",
      "Policy Status"
    ];
    if (["Active"].includes(this.state.status)) {
      headings.push("Payment Mode", "Payment Reference Number");
    }
    headings.push( "Company", "Partner Branch Name", "Channel Name", "Sub Channel Name", "Employee ID", "Employee Name", "Employee EmailID", "Employee Designation", "Location/Branch-Id", "Location/Branch-Name" ,"Zone/Region" ,"Reporting Manager" ,"Reporting Mgr email id" ,"Employee Contact No." ,"Comment",  "View");
    this.setState({ isLoading: false, headings });
  }

  getFormattedDate = (date) => {
    return moment(date).format("DD-MM-YYYY");
  }

  getExpiryDate = (startDate, cover_type) => {
    const numYears = cover_type === "Unified Domestic Worker" ? 2 : 1;
    return moment(startDate).subtract(1, "day").add(numYears, "year").format("DD-MM-YYYY");
  }

  downloadData = async () => {
    this.setState({ isDownloading: true, showProcessPopup: true });
    const status = this.state.status === "All" ? undefined : this.state.status;
    const sob = this.state.sob === "All" ? undefined : this.state.sob;
    const region = this.state.region === "All" ? undefined : this.state.region;
    const branch = this.state.branch === "All" ? undefined : this.state.branch;

    const res = await PolicyService.getHealthReport({
      startDate : this.state.startDate,
      endDate : this.state.endDate,
      type : "domestic",
      status,
      sob : sob,
      region : region,
      branch : branch
    });
    const data = res.data.data;
    let csvArr = [];
    let newHeadings = [...this.state.headings];
    newHeadings.splice(-1, 1);
    csvArr.push(newHeadings.join(","));
    data.forEach((policy, ind) => {
      const maritalStatus = policy.personalInformation.isMarried === "yes" ? "Married" : "Single";
      const startDate = this.getFormattedDate(policy.startDate);
      const endDate = this.getExpiryDate(policy.startDate, policy.cover_type);
      const base_price = policy.amount ? helpers.getBasePrice(policy.amount) : 0;
      const discount_code = policy.amount ? helpers.getDiscountCode(policy.amount) : 0;
      const discount = policy.amount ? helpers.getDiscount(policy.amount) : "-";
      const policy_fee = policy.amount ? policy.amount.policy_fee : 0;
      const emergency_fund_fee = policy.amount ? policy.amount.emergency_fund_fee : 0;
      const government_fee = policy.amount ? policy.amount.government_fee : 0;
      const amount = policy.amount ? policy.amount.total : 0;
      const paymentStatus = policy.payments && policy.payments.isPaid ? "Paid" : "Unpaid";
      const bmiValue = _.get(policy.amount, "bmi_value", 0)
      const vatValue = _.get(policy.amount, "vat", 0)


      let arr = [
        ind + 1,
        policy._id,
        policy.policy_num,
        policy.personalInformation.sponsorName,
        policy.personalInformation.sponsor_id,
        policy.personalInformation.sponsor_type,
        policy.civil_id,
        policy.personalInformation.fullName,
        policy.personalInformation.dob,
        policy.personalInformation.gender,
        `"${policy.personalInformation.occupation}"` || "-",
        policy.personalInformation.occupation_code || "-",
        policy.personalInformation.id_expiry_date || "-",
        maritalStatus,
        policy.nationality,
        startDate,
        endDate,
        policy.personalInformation.email,
        policy.personalInformation.mobileNumber,
        policy.applicationType,
        base_price,
        discount_code,
        discount,
        policy_fee,
        emergency_fund_fee,
        government_fee,
        amount,
        vatValue,
        bmiValue,
        "Domestic Worker",
        paymentStatus,
        policy.status
      ];
      if (["Active"].includes(this.state.status)) {
        const paymentMode = policy.payments.mode === "Link" ? "Card" : policy.payments.mode;
        arr.push(
          paymentMode,
          policy.payments.mode !== "Cash" ? policy.payments.payment_id : "-"
        )
      }
     
      arr.push(policy.company_affiliate_name ? policy.company_affiliate_name : "-")
      arr.push(policy.affiliate_partner_name ? policy.affiliate_partner_name : "-")
      arr.push(policy.channel_name ? policy.channel_name : "-")
      arr.push(policy.sub_channel_name ? policy.sub_channel_name : "-")
      arr.push(policy.affiliate_employee_id ? policy.affiliate_employee_id : "-")
      arr.push(policy.affiliate_name ? policy.affiliate_name : "AXSS")
      arr.push(policy.affiliate_email ? policy.affiliate_email : "-")
      arr.push(policy.affiliate_employee_designation ? policy.affiliate_employee_designation : "-")
      arr.push(policy.branch_id ? policy.branch_id : "-")
      arr.push(policy.branch_name ? policy.branch_name : "-")
      arr.push(policy.region_name ? policy.region_name : "-")
      arr.push(policy.branch_manager_name ? policy.branch_manager_name : "-")
      arr.push(policy.branch_manager_email ? policy.branch_manager_email : "-")
      arr.push(policy.affiliate_phone ? policy.affiliate_phone : "-")
      arr.push(policy.comment ? `"${policy.comment}"` : "-");
      csvArr.push(arr.join(","));
    });

    const csvFile = new Blob([csvArr.join("\n")], { type: "text/csv" });
    saveAs(csvFile, "domestic-dash.csv");
    this.setState({ isDownloading: false, showProcessPopup: false });
  }

  downloadRiData = async () => {
    this.setState({ isDownloadingRI: true });
    const status = this.state.status === "All" ? undefined : this.state.status;
    const res = await PolicyService.getHealthReport({
      startDate : this.state.startDate,
      endDate : this.state.endDate,
      type : "domestic",
      status
    });
    const data = res.data.data;
    
    const wb = XLSX.utils.book_new();

    let rows = [];

    const header_row_1 = [
      { v : "Imported" , s : { font : { bold : true } } },
      { v : "ExternalRef" , s : { font : { bold : true }, fill: { fgColor: { rgb: "92D050" } } } },
      { v : "Name" , s : { font : { bold : true }, fill: { fgColor: { rgb: "92D050" } } } },
      { v : "EffDate" , s : { font : { bold : true }, fill: { fgColor: { rgb: "92D050" } } } },
      { v : "ExpDate" , s : { font : { bold : true }, fill: { fgColor: { rgb: "92D050" } } } },
      { v : "Currency" , s : { font : { bold : true } } },
      { v : "Distributor" , s : { font : { bold : true } } },
      { v : "NbrRenewals" , s : { font : { bold : true } } },
      { v : "ImportedFromPayer" , s : { font : { bold : true } } },
      { v : "StartDate" , s : { font : { bold : true }, fill: { fgColor: { rgb: "92D050" } } } },
      { v : "ContNote" , s : { font : { bold : true } } },
      { v : "ContractHolderPin" , s : { font : { bold : true } } },
      { v : "PayMode" , s : { font : { bold : true } } },
      { v : "PayWay" , s : { font : { bold : true } } },
      { v : "Bank" , s : { font : { bold : true } } },
      { v : "BankAccountNbr" , s : { font : { bold : true } } },
      { v : "Policy Nbr" , s : { font : { bold : true } } },
      { v : "PrevPolicyNbr" , s : { font : { bold : true } } },
      { v : "CCHIPolicyPin" , s : { font : { bold : true } } },
      { v : "LicensingAuthority" , s : { font : { bold : true } } },
      { v : "SponsorType" , s : { font : { bold : true } } },
      { v : "SponsorNumber" , s : { font : { bold : true } } },
      { v : "SkipCategory" , s : { font : { bold : true } } },
      { v : "TaxesCovered" , s : { font : { bold : true } } },
      { v : "PolicyHolderTaxRegistrationNbr" , s : { font : { bold : true } } },
      { v : "IBANNbr" , s : { font : { bold : true } } },
      { v : "NoPremiumRefund" , s : { font : { bold : true } } },
      { v : "PayerBranch" , s : { font : { bold : true } } },
      { v : "IssuanceSource" , s : { font : { bold : true } } },
    ];
    const header_row_2 = [
      { v: "Gender", s : {font : {bold : true}, fill: { fgColor: { rgb: "92D050" } }} },
      { v: "Dependency", s : {font : {bold : true}} },
      { v: "FirstName", s : {font : {bold : true}, fill: { fgColor: { rgb: "92D050" } }} },
      { v: "MiddleName", s : {font : {bold : true}} },
      { v: "FamilyName", s : {font : {bold : true}} },
      { v: "MaidenName", s : {font : {bold : true}} },
      { v: "DOB", s : {font : {bold : true}, fill: { fgColor: { rgb: "92D050" } }} },
      { v: "MaritalStatus", s : {font : {bold : true}, fill: { fgColor: { rgb: "92D050" } }} },
      { v: "Height(cm)", s : {font : {bold : true}, fill: { fgColor: { rgb: "92D050" } }} },
      { v: "Weight(Kg)", s : {font : {bold : true}, fill: { fgColor: { rgb: "92D050" } }} },
      { v: "Product", s : {font : {bold : true}} },
      { v: "ProductClass", s : {font : {bold : true}} },
      { v: "ImportOP", s : {font : {bold : true}} },
      { v: "ImportIsAmount", s : {font : {bold : true}} },
      { v: "Remarks", s : {font : {bold : true}} },
      { v: "StartDate", s : {font : {bold : true}, fill: { fgColor: { rgb: "92D050" } }} },
      { v: "AmountOnGrossPremium", s : {font : {bold : true}} },
      { v: "Beneficiaryid", s : {font : {bold : true}} },
      { v: "Cardnumber", s : {font : {bold : true}, fill: { fgColor: { rgb: "92D050" } }} },
      { v: "BeneficiaryPIN", s : {font : {bold : true}} },
      { v: "ImageName", s : {font : {bold : true}} },
      { v: "NotCovered", s : {font : {bold : true}} },
      { v: "HasLimit", s : {font : {bold : true}} },
      { v: "HasExclusion", s : {font : {bold : true}} },
      { v: "certificatenbr", s : {font : {bold : true}} },
      { v: "ProductCode", s : {font : {bold : true}} },
      { v: "SponsorID", s : {font : {bold : true}, fill: { fgColor: { rgb: "92D050" } }} },
      { v: "LG", s : {font : {bold : true}} },
      { v: "ExtEndoNbr", s : {font : {bold : true}} },
      { v: "IDNumber", s : {font : {bold : true}, fill: { fgColor: { rgb: "92D050" } }} },
      { v: "IDType", s : {font : {bold : true}, fill: { fgColor: { rgb: "92D050" } }} },
      { v: "CountryOfOrigin", s : {font : {bold : true}, fill: { fgColor: { rgb: "92D050" } }} },
      { v: "CountryOfResidence", s : {font : {bold : true}} },
      { v: "grlgstartdate", s : {font : {bold : true}} },
      { v: "grlglegacy", s : {font : {bold : true}} },
      { v: "IDExpiryDate", s : {font : {bold : true}, fill: { fgColor: { rgb: "92D050" } }} },
      { v: "BorderEntryVisa", s : {font : {bold : true}} },
      { v: "Salary", s : {font : {bold : true}} },
      { v: "Profession", s : {font : {bold : true}, fill: { fgColor: { rgb: "92D050" } }} },
      { v: "Country", s : {font : {bold : true}} },
      { v: "Region", s : {font : {bold : true}} },
      { v: "SubRegion", s : {font : {bold : true}} },
      { v: "City", s : {font : {bold : true}} },
      { v: "Nationalidnumber", s : {font : {bold : true}, fill: { fgColor: { rgb: "92D050" } }} },
      { v: "Phone", s : {font : {bold : true}} },
      { v: "Mobile", s : {font : {bold : true}, fill: { fgColor: { rgb: "92D050" } }} },
      { v: "Email", s : {font : {bold : true}} },
      { v: "ExternalID", s : {font : {bold : true}} },
      { v: "ALTBENEFCODE", s : {font : {bold : true}} },
      { v: "Commission", s : {font : {bold : true}} },
      { v: "PassportNumber", s : {font : {bold : true}, fill: { fgColor: { rgb: "92D050" } }} },
      { v: "UIDNumber", s : {font : {bold : true}} },
      { v: "sponsorType", s : {font : {bold : true}} },
      { v: "ResidenceVisaPlace", s : {font : {bold : true}} },
      { v: "IsDMP", s : {font : {bold : true}} },
      { v: "DMPProgram", s : {font : {bold : true}} },
      { v: "BeneficiariesInCaseOfDeath", s : {font : {bold : true}} },
      { v: "OtherInsuranceCompanies", s : {font : {bold : true}} },
      { v: "ResidencyFileNumber", s : {font : {bold : true}} },
      { v: "BirthCertificateID", s : {font : {bold : true}} },
      { v: "RegulatorMemberID", s : {font : {bold : true}} },
      { v: "MemberType", s : {font : {bold : true}} },
      { v: "CostCentre", s : {font : {bold : true}} },
      { v: "Section", s : {font : {bold : true}} },
      { v: "CoverageConstraint", s : {font : {bold : true}} },
    ];

    const blank_row = [
      { v: "" }
    ]

    data.forEach((item) => {
      const {
        _id,
        personalInformation,
        startDate,
        policy_num,
        civil_id,
        nationality
      } = item;

      const {
        sponsorName,
        gender,
        fullName,
        dob,
        is_married,
        height,
        weight,
        sponsor_id,
        id_expiry_date,
        occupation,
        mobileNumber,
        passport
      } = personalInformation

      let dob_date = moment(dob, 'DD-MM-YYYY');
      if(!dob_date.isValid()){
        dob_date = moment(dob, 'DD-MMM-YYYY');
      }

      const policy_period = item.cover_type === "Unified Domestic Worker" ? 2 : 1;
      const start_date = moment(startDate).format("DD-MMM-YYYY")
      const end_date = moment(startDate).add(policy_period, "years").subtract(1, "day").format("DD-MMM-YYYY");

      const data_row_1 = [
        { v : "No" },
        { v : _id },
        { v : sponsorName },
        { v : start_date },
        { v : end_date },
        { v : "OMR" },
        { v : "Direct" },
        { v : "0" },
        { v : "" },
        { v : start_date },
        { v : "" },
        { v : "" },
        { v : "Annually" },
        { v : "Direct Payment" },
        { v : "" },
        { v : "" },
        { v : "" },
        { v : "" },
        { v : "" },
        { v : "MOHOmanCMADHAMANI" },
        { v : "Citizen" },
        { v : "" },
        { v : "" },
        { v : "" },
        { v : "" },
        { v : "" },
        { v : "" },
        { v : "" },
        { v : "TPA" },
      ];

      const data_row_2 = [
        { v: gender.toUpperCase() },
        { v: "Principal" },
        { v: fullName },
        { v: "-" },
        { v: "," },
        { v: "-" },
        { v: dob_date.format("DD-MMM-YYYY") },
        { v: is_married === "yes" ? "Married" : "Single" },
        { v: height },
        { v: weight },
        { v: "271055" },
        { v: "B" },
        { v: "" },
        { v: "" },
        { v: "" },
        { v: start_date },
        { v: "" },
        { v: "" },
        { v: policy_num },
        { v: "" },
        { v: "" },
        { v: "" },
        { v: "" },
        { v: "" },
        { v: "" },
        { v: "271055" },
        { v: sponsor_id },
        { v: "" },
        { v: "" },
        { v: civil_id },
        { v: "ID CARD" },
        { v: nationality },
        { v: "Oman" },
        { v: "" },
        { v: "" },
        { v: id_expiry_date },
        { v: "" },
        { v: "1" },
        { v: occupation },
        { v: "Oman" },
        { v: "" },
        { v: "" },
        { v: "" },
        { v: civil_id },
        { v: "" },
        { v: `968-${mobileNumber}` },
        { v: "" },
        { v: "" },
        { v: "" },
        { v: "" },
        { v: passport },
        { v: "" },
        { v: "Citizen" },
        { v: "" },
        { v: "" },
        { v: "" },
        { v: "" },
        { v: "" },
        { v: "" },
        { v: "" },
        { v: "" },
        { v: "" },
        { v: "" },
        { v: "" },
        { v: "" },
      ]
      rows.push(header_row_1);
      rows.push(data_row_1);
      rows.push(header_row_2);
      rows.push(data_row_2);
      rows.push(blank_row);
    })

    const ws = XLSX.utils.aoa_to_sheet(rows);
    XLSX.utils.book_append_sheet(wb, ws, "Data");
    XLSX.writeFile(wb, "Domestic-Netcare-Report.xlsx");

    this.setState({ isDownloadingRI: false });
  }

  render() {
    return (
      <div className="d-flex">
        <Navigation />
        <div className="container-fluid">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Paper className="p-2 sq-shadow d-flex">
                <Typography><Link to={`/home`}>Home</Link></Typography>
                <Typography className="mr-2 ml-2">/</Typography>
                <Typography>Domestic Workers Report</Typography>
              </Paper>
            </Grid>

            <Grid item md={3} xs={12}>
              <AffiliateSelect onChange={() => { }} />
            </Grid>
            <Grid item md={3} xs={12}>
              <TextField
                variant="outlined"
                select
                label="Source of Business"
                fullWidth
                name="sob"
                onChange={this.handleChange}
                value={this.state.sob}
                SelectProps={{
                  MenuProps: {
                    anchorOrigin: {
                      vertical: "top",
                      horizontal: "left"
                    },
                    getContentAnchorEl: null
                  },
                }}
              >
                {
                  Object.values(REPORT_SOB_OPTIONS).map((option, index) => <MenuItem key={index} value={option}>{option}</MenuItem>)
                }
              </TextField>
            </Grid>
            <Grid item md={3} xs={12}>
              <TextField
                variant="outlined"
                select
                label="Region"
                fullWidth
                name="region"
                onChange={this.handleChange}
                value={this.state.region}
                SelectProps={{
                  MenuProps: {
                    anchorOrigin: {
                      vertical: "top",
                      horizontal: "left"
                    },
                    getContentAnchorEl: null
                  },
                }}
              >
                <MenuItem value="All">All</MenuItem>
                {
                  RegionList.map((region) => 
                    <MenuItem key={region.id} value={region.id}>{region.name}</MenuItem>
                  )
                }
              </TextField>
            </Grid>
            <Grid item md={3} xs={12}>
              <TextField
                variant="outlined"
                select
                label="Branch"
                fullWidth
                name="branch"
                onChange={this.handleChange}
                value={this.state.branch}
                SelectProps={{
                  MenuProps: {
                    anchorOrigin: {
                      vertical: "top",
                      horizontal: "left"
                    },
                    getContentAnchorEl: null
                  },
                }}
              >
                <MenuItem value="All">All</MenuItem>
                {
                  BranchList.map((branch) => 
                    <MenuItem key={branch.id} value={branch.id}>{branch.name}</MenuItem>
                  )
                }
              </TextField>
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={3}>
              <TextField
                variant="outlined"
                select
                label="Status"
                fullWidth
                name="status"
                onChange={this.handleChange}
                value={this.state.status}
                SelectProps={{
                  MenuProps: {
                    anchorOrigin: {
                      vertical: "top",
                      horizontal: "left"
                    },
                    getContentAnchorEl: null
                  },
                }}
              >
                {
                  Object.values(REPORT_STATUS_OPTIONS).map((option, index) => <MenuItem key={index} value={option}>{option}</MenuItem>)
                }
                
              </TextField>
            </Grid>

            <Grid item xs={3}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                  label="Start Date"
                  inputVariant="outlined"
                  animateYearScrolling
                  name="startDate"
                  clearable
                  fullWidth
                  value={this.state.startDate}
                  onChange={this.handleStartDateChange}
                  format="dd-MM-yyyy"
                />
              </MuiPickersUtilsProvider>
            </Grid>

            <Grid item xs={3}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                  label="End Date"
                  inputVariant="outlined"
                  animateYearScrolling
                  name="endDate"
                  clearable
                  fullWidth
                  value={this.state.endDate}
                  onChange={this.handleEndDateChange}
                  format="dd-MM-yyyy"
                />
              </MuiPickersUtilsProvider>
            </Grid>

            <Grid item xs={3} className="d-flex align-items-center">
              <Button variant="contained" color="primary" disabled={this.state.isLoading} onClick={this.handleSearch}>{this.state.isLoading ? "Searching . . ." : "Search"}</Button>
            </Grid>
          </Grid>

          {
            (this.state.showTable && this.state.data.length > 0) &&
            <Grid container spacing={2} className="d-flex">
              <Grid item xs={12}>
                <Paper className="sq-shadow overflow-auto">
                  <div className="dash-table-container">
                    <Table>
                      <TableHead>
                        <TableRow>
                          {
                            this.state.headings.map((heading, ind) =>
                              <TableCell key={ind}>{heading}</TableCell>
                            )
                          }
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {
                          this.state.data.map((data, ind) => {
                            return (
                              <TableRow key={ind}>
                                <TableCell>{ind + 1}</TableCell>
                                <TableCell>{data._id}</TableCell>
                                <TableCell>{data.policy_num}</TableCell>
                                <TableCell>{data.personalInformation.sponsorName}</TableCell>
                                <TableCell>{data.personalInformation.sponsor_id}</TableCell>
                                <TableCell>{data.personalInformation.sponsor_type}</TableCell>
                                <TableCell>{data.civil_id}</TableCell>
                                <TableCell>{data.personalInformation.fullName}</TableCell>
                                <TableCell>{data.personalInformation.dob}</TableCell>
                                <TableCell className="text-capitalize"> {data.personalInformation.gender} </TableCell>
                                <TableCell>{data.personalInformation.occupation || "-"}</TableCell>
                                <TableCell>{data.personalInformation.occupation_code || "-"}</TableCell>
                                <TableCell>{data.personalInformation.id_expiry_date || "-"}</TableCell>
                                <TableCell>{(data.personalInformation.isMarried === "yes" ? "Married" : "Single")}</TableCell>
                                <TableCell>{data.nationality}</TableCell>
                                <TableCell padding="checkbox">{this.getFormattedDate(data.startDate)}</TableCell>
                                <TableCell padding="checkbox">{this.getExpiryDate(data.startDate, data.cover_type)}</TableCell>
                                <TableCell>{data.personalInformation.email}</TableCell>
                                <TableCell>{data.personalInformation.mobileNumber}</TableCell>
                                <TableCell className="text-capitalize"> {data.applicationType} </TableCell>
                                <TableCell>{data.amount ? helpers.getBasePrice(data.amount) : 0} OMR</TableCell>
                                <TableCell>{data.amount ? helpers.getDiscountCode(data.amount) : "-"}</TableCell>
                                <TableCell>{data.amount ? helpers.getDiscount(data.amount) : 0} OMR</TableCell>
                                <TableCell>{data.amount ? data.amount.policy_fee : 0} OMR</TableCell>
                                <TableCell>{data.amount ? data.amount.emergency_fund_fee : 0} OMR</TableCell>
                                <TableCell>{data.amount ? data.amount.government_fee : 0} OMR</TableCell>
                                <TableCell>{data.amount ? data.amount.total : 0} OMR</TableCell>
                                <TableCell>{data.amount ? data.amount.vat : 0} OMR</TableCell>
                                <TableCell>{data.amount ? data.amount.bmi_value : 0} OMR</TableCell>
                                <TableCell> Domestic Worker </TableCell>
                                <TableCell> {data.payments && data.payments.isPaid ? "Paid" : "Unpaid"} </TableCell>
                                <TableCell> {data.status} </TableCell>
                                {
                                  ["Active"].includes(this.state.status) &&
                                  <>
                                    <TableCell>
                                      {
                                        data.payments.mode === "Link" ? "Card" : data.payments.mode
                                      }
                                    </TableCell>
                                    <TableCell>
                                      {
                                        data.payments.mode !== "Cash" ? data.payments.payment_id : "-"
                                      }
                                    </TableCell>
                                  </>
                                }
                                <TableCell>{data.company_affiliate_name ? data.company_affiliate_name : "-"}</TableCell>
                                <TableCell>{data.affiliate_partner_name ? data.affiliate_partner_name : "-"}</TableCell>
                                <TableCell>{data.channel_name ? data.channel_name : "-"}</TableCell>
                                <TableCell>{data.sub_channel_name ? data.sub_channel_name : "-"}</TableCell>
                                <TableCell>{data.affiliate_employee_id ? data.affiliate_employee_id : "-"}</TableCell>
                                <TableCell>{data.affiliate_name ? data.affiliate_name : "AXSS"}</TableCell>
                                <TableCell>{data.affiliate_email ? data.affiliate_email : "-"}</TableCell>
                                <TableCell>{data.affiliate_employee_designation ? data.affiliate_employee_designation : "-"}</TableCell>
                                <TableCell>{data.branch_id ? data.branch_id : "-"}</TableCell>
                                <TableCell>{data.branch_name ? data.branch_name : "-"}</TableCell>
                                <TableCell>{data.region_name ? data.region_name : "-"}</TableCell>
                                <TableCell>{data.branch_manager_name ? data.branch_manager_name : "-"}</TableCell>
                                <TableCell>{data.branch_manager_email ? data.branch_manager_email : "-"}</TableCell>
                                <TableCell>{data.affiliate_phone ? data.affiliate_phone : "-"}</TableCell>
                                <TableCell>{data.comment ? data.comment : "-"}</TableCell>
                                <TableCell>
                                  <Link to={`/policies/${data._id}`} className="table-eye-link">
                                    <Eye />
                                  </Link>
                                </TableCell>
                              </TableRow>
                            )
                          })
                        }
                        <TableRow>
                          <TablePagination
                            rowsPerPage={this.state.rowsPerPage}
                            rowsPerPageOptions={[10, 25, 50]}
                            page={this.state.page}
                            count={this.state.totalRecords}
                            colSpan={0}
                            onChangePage={this.handleChangePage}
                            onChangeRowsPerPage={this.handleChangeRowsPerPage}
                          />
                        </TableRow>
                      </TableBody>
                    </Table>
                  </div>
                </Paper>
              </Grid>

              <Grid item xs={12}>
                <Button onClick={this.downloadData} variant="contained" color="primary" disabled={this.state.isDownloading}>{this.state.isDownloading ? "Downloading . . ." : "Download csv"}</Button>
                <Button onClick={this.downloadRiData} variant="contained" color="primary" className="ml-3" disabled={this.state.isDownloadingRI}> {this.state.isDownloadingRI ? "Downloading . . ." : "Download Ri Data"} </Button>
              </Grid>

            </Grid>
          }
          {
            (this.state.showTable && this.state.data.length === 0) &&
            <Paper className="q-shadow mt-3 pt-5 pb-5 d-flex align-items-center justify-content-center">
              <Typography variant="h5"> No Data Found </Typography>
            </Paper>
          }
        </div>
      </div>
    )
  }
}
export default withRouter(DomesticWorkersReport);