import axios from "axios";
import config from "./config";

let creditLimit = {};
const {baseUrl} = config;

creditLimit.getCreditLimit = async () => {
  const centerId = sessionStorage.getItem("center_id");
  const url = `${baseUrl}/credit-limit?center=${centerId}`;
  const res = await axios({ url });
  return res;
}

creditLimit.updateLimit = async (data) => {
  const centerId = sessionStorage.getItem("center_id");
  const url = `${baseUrl}/credit-limit/?center=${centerId}`;
  const res = await axios({ url, data, method : "PUT" });
  return res;
}
export default creditLimit;