import React from "react";
import Navigation from "../common/Navigation";
import { Paper, Typography, Grid, Button, Drawer, TextField, InputAdornment, IconButton, Table, TableHead, TableBody, TableRow, TableCell, Switch, TablePagination, MenuItem, List, ListItem, Checkbox, ListItemText, ListItemIcon, FormControlLabel  } from "@material-ui/core";
import { Link, withRouter } from "react-router-dom";
import "./Affiliate.css";
import { VisibilityOff, Visibility, Search, InvertColors, Image, Edit } from "@material-ui/icons";
import Auth from "../../services/auth";
import Snackbar from "../common/Snackbar";
import AffiliateService from "../../services/affiliates";
import config from "../../services/config";
import Tree from "mdi-react/FileTreeIcon";
import DownloadAffiliates from "./DownloadAffiliates";
import { PERMISSIONS } from "../../constants";

const base_url = "https://porta.insurance.com";

class Affiliates extends React.Component{
  state = {
    drawerOpen : false,
    fname : "",
    email : "",
    password : "",
    mobileNumber : "",
    showPassword : false,
    isLoading : false,
    affiliates : [],
    page : 0,
    rowsPerPage : 10,
    count : 0,
    imageDrawerOpen : false,
    imageFile : "",
    isImageChanged : false,
    isSavingImage : false,
    selectedAffiliate : {},
    imageUrl : "",
    searchText : "",
    themeDrawerOpen : false,
    isThemeChanged : false,
    affiliateType : "",
    availableHealthInsuranceType : [],
    IMComission : "",
    AHPCommision : "",
    UDWCommision : "",
    UHIPCommision : "",
    permissions : []
  }

  componentDidMount(){
    this.getAffiliates();

    window.addEventListener("storage", () => {
      const permissions = localStorage.getItem("permissions") ? JSON.parse(localStorage.getItem("permissions")) : []
      this.setState({ permissions })
    })
  }

  handleCloseDrawer = () => {
    this.setState({
      drawerOpen : false,
      fname : "",
      email : "",
      password : "",
      mobileNumber : "",
      showEmailError : false,
      affiliateType : "",
      availableHealthInsuranceType : [],
      IMComission : "",
      AHPCommision : "",
      UDWCommision : "",
      UHIPCommision : "",
      showPasswordError : false,
      isLoading : false
    });
  }

  handleChange = (e) => {
    this.setState({ [e.target.name] : e.target.value });
  }

  isValidMail = () => {
    const value = this.state.email;
    const atpos = value.indexOf("@");
    const dotpos = value.lastIndexOf(".");
    if(atpos < 1 || dotpos < atpos + 2 || dotpos + 2 >= value.length || value === "")
      return false;
    else
      return true;
  }

  onBlurEmail = () => {
    this.setState({ showEmailError : !this.isValidMail() })
  }

  onBlurPassword = () => {
    this.setState({ showPasswordError : this.state.password.length < 6 });
  }

  handleClickShowPassword = () => {
    this.setState({ showPassword : !this.state.showPassword });
  }

  saveAffiliate = async () => {
    this.setState({ isLoading : true })
    try{
      const registerData = await Auth.register(this.state.email, this.state.password);
      const uid = registerData.user.uid;
      const data = await AffiliateService.addAffiliate(this.state.fname, this.state.email, this.state.mobileNumber,this.state.availableHealthInsuranceType, this.state.affiliateType, uid, this.state.IMComission, this.state.AHPCommision, this.state.UDWCommision, this.state.UHIPCommision);
      if(data.data.inserted){
        this.setState({ isLoading : true })
        Auth.logout2();
        this.handleCloseDrawer();
        this.getAffiliates();
        this.setState({
          showSnackbar : true,
          snackBarMessage : "Affiliator Added",
          messageType : "success"
        })
      }
    }catch(e){
      this.setState({ isLoading : false });
      if(e.code === "auth/email-already-in-use"){
        this.setState({
          showSnackbar : true,
          snackBarMessage : "Email already in use",
          messageType : "danger"
        })
      }
    }
  }

  handleCloseSnackbar = () => {
    this.setState({ showSnackbar : false });
  }

  getAffiliates = async () => {
    const page = this.state.page;
    const rowsPerPage = this.state.rowsPerPage;
    const search = this.state.searchText === "" ? undefined : this.state.searchText;
    const data = await AffiliateService.getAffiliates(page, rowsPerPage, search);
    this.setState({ affiliates : data.data.data, count : data.data.count });
  }

  handleChangePage = (e, page) => {
    this.setState({ page }, this.getAffiliates);
  }

  handleChangeRowsPerPage = (e) => {
    this.setState({ rowsPerPage : e.target.value }, this.getAffiliates);
  }

  handleActiveChange = (checked, uid) => {
    const obj = { isActive : checked };
    AffiliateService.updateAffiliate(uid, obj).then((res) => {
      if(res.data.updated){
        this.getAffiliates();
        this.setState({
          showSnackbar : true,
          snackBarMessage : "Status Changed Successfully",
          messageType : "success"
        })
      }
    })
  }

  handleViewImageClick = async (uid) => {
    const data = await AffiliateService.getAffiliate(uid);
    const imageUrl = `${config.baseUrl}/uploads/affiliate-images/${data.data.data.image}`;
    this.setState({
      selectedAffiliate : data.data.data,
      imageDrawerOpen : true,
      imageUrl : data.data.data.image === "" ? "" : imageUrl
    })
  }

  handleViewThemeClick = async (uid) => {
    const data = await AffiliateService.getAffiliate(uid);
    const selectedAffiliate = data.data.data;
    this.setState({
      themeDrawerOpen : true,
      selectedAffiliate,
      selectedTheme : selectedAffiliate.theme
    });
  }

  saveAffiliateImage = async () => {
    this.setState({ isSavingImage : true });
    let formData = new FormData();
    formData.append("file", this.state.imageFile);

    const filename = await AffiliateService.uploadImage(formData);
    this.updateFileName(filename.data.filename)
  }

  updateFileName = async (filename) => {
    const res = await AffiliateService.updateAffiliate(this.state.selectedAffiliate.affiliateId, {image : filename});
    if(res.data.updated){
      this.setState({
        isSavingImage : false,
        showSnackbar : true,
        snackBarMessage : "Image changed successfully",
        messageType : "success",
        isImageChanged : false
      });
      this.handleCloseImageDrawer();
      this.getAffiliates();
    }
  }

  handleCloseImageDrawer = () => {
    this.setState({
      imageUrl : "",
      selectedAffiliate : {},
      imageDrawerOpen : false,
      imageFile : ""
    })
  }

  handleFileChange = (e) => {
    const file = e.target.files[0];
    let fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = () => {
      this.setState({ imageUrl : fileReader.result, isImageChanged : true, imageFile : file });
    }
  }

  handleSearchChange = (e) => {
    this.setState({
      searchText : e.target.value,
      page : 0
    }, this.getAffiliates)
  }

  setData = (affiliate_id) => {
    sessionStorage.setItem("center_id", affiliate_id);
    sessionStorage.setItem("center_selected", "true");
  }

  handleCloseThemeDrawer = () => {
    this.setState({ themeDrawerOpen : false, isSavingTheme : false, isThemeChanged : false });
  }

  handleClickTheme = (theme) => {
    if(this.state.selectedTheme !== theme){
      this.setState({
        selectedTheme : theme,
        isThemeChanged : true
      });
    }
  }

  saveAffiliateTheme = () => {
    this.setState({ isSavingTheme : true });
    AffiliateService.updateAffiliate(this.state.selectedAffiliate.affiliateId, {theme : this.state.selectedTheme}).then(() => {
      this.handleCloseThemeDrawer();
    });
  }

  handleHealthTypeInsuranceChange = (healthInsuranceType) => {
    let availableHealthInsuranceType = [...this.state.availableHealthInsuranceType];
    const ind = availableHealthInsuranceType.indexOf(healthInsuranceType);
    if(ind < 0){
      availableHealthInsuranceType.push(healthInsuranceType);
    }else{
      availableHealthInsuranceType.splice(ind,1);
    }
    this.setState({ availableHealthInsuranceType })
  }

  render(){
    return(
      <div>
        <Snackbar
          showSnackbar = { this.state.showSnackbar }
          messageType = {this.state.messageType}
          snackBarMessage = {this.state.snackBarMessage}
          onCloseSnackbar = {this.handleCloseSnackbar}
        />
        <Grid container spacing = {2}>

          <Grid item xs={6} className="d-flex align-items-center">
            {/* <Link to="/home" className="view-sehteq-link mr-3">
              <Button className="bg-dark text-white" variant="contained" onClick = {() => sessionStorage.setItem("center_selected", "true")}>View All</Button>
            </Link> */}
            {/* <Button variant="contained" color="primary" onClick= {() => this.setState({ drawerOpen : true })}> Add New Affiliator </Button> */}
            {
              this.state.permissions.includes(PERMISSIONS.CREATE_AFFILIATES) &&
              <Link to={`${process.env.PUBLIC_URL}/affiliator/create`} className="mr-3">
                <Button variant="contained" color="primary" onClick= {() => this.setState({ drawerOpen : true })}> Add New Affiliator </Button>
              </Link>
            }
            <Link to={`${process.env.PUBLIC_URL}/affiliator/targets`} className="mr-3">
              <Button variant="contained" color="primary"> Targets </Button>
            </Link>
            <Link to={`${process.env.PUBLIC_URL}/affiliator/channel`} className="mr-3">
              <Button variant="contained" color="primary"> Channel </Button>
            </Link>
            <Link to={`${process.env.PUBLIC_URL}/affiliator/dhofar-api-kit`} className="mr-3">
              <Button variant="contained" color="primary"> Api Kit </Button>
            </Link>
            <DownloadAffiliates />
          </Grid>

          <Grid item xs={6} className="text-right">
            <TextField
              variant = "outlined"
              label = "Search"
              placeholder = "Search by name or email"
              onChange = {this.handleSearchChange}
              value = {this.state.searchText}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Search />
                  </InputAdornment>
                ),
              }}
              style={{
                width : "400px"
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <Paper className="sq-shadow policies-table-container">
              <Table>

                <TableHead>
                  <TableRow>
                    <TableCell> Name </TableCell>
                    <TableCell> Email </TableCell>
                    <TableCell> Affiliate Type </TableCell>
                    {
                      this.state.permissions.includes(PERMISSIONS.EDIT_AFFILIATES) &&
                      <TableCell> Is Active </TableCell>
                    }
                    <TableCell> Link </TableCell>
                    <TableCell> Channel </TableCell>
                    <TableCell> Sub Channel </TableCell>
                    <TableCell> Sub Type </TableCell>
                    <TableCell> Image </TableCell>
                    <TableCell> Theme </TableCell>
                    <TableCell> View As Tree </TableCell>
                    {/* <TableCell> View </TableCell> */}
                    {
                      this.state.permissions.includes(PERMISSIONS.EDIT_AFFILIATES) &&
                      <TableCell> Edit </TableCell>
                    }
                  </TableRow>
                </TableHead>

                <TableBody>
                  {
                    this.state.affiliates.map((affiliate) => 
                    <TableRow key={affiliate._id}>
                      <TableCell> {affiliate.name} </TableCell>
                      <TableCell> {affiliate.email} </TableCell>
                      <TableCell> {affiliate.affiliateType}</TableCell>
                      {
                        this.state.permissions.includes(PERMISSIONS.EDIT_AFFILIATES) &&
                        <TableCell>
                          <Switch
                            checked = {affiliate.isActive}
                            onChange = {(e) => this.handleActiveChange(e.target.checked, affiliate.affiliateId)}
                          />
                        </TableCell>
                      }
                      <TableCell>
                        <a href={`${base_url}/affiliate/${affiliate.affiliateId}`} target="_blank" rel="noopener noreferrer">
                          {`${base_url}/affiliate/${affiliate.affiliateId}`}
                        </a>
                      </TableCell>
                      <TableCell> {affiliate.channel_name} </TableCell>
                      <TableCell> {affiliate.sub_channel_name}</TableCell>
                      <TableCell> {affiliate.affiliateSubType}</TableCell>
                      <TableCell className="text-center">
                        <span style={{ cursor : "pointer" }} className="table-eye-link" onClick={() => this.handleViewImageClick(affiliate.affiliateId)}>
                          <Image />
                        </span>
                      </TableCell>

                      <TableCell className="text-center">
                        <span style={{ cursor : "pointer" }} className="table-eye-link" onClick={() => this.handleViewThemeClick(affiliate.affiliateId)}>
                          <InvertColors />
                        </span>
                      </TableCell>
                      <TableCell className="text-center">
                        <Link to={`/affiliates/${affiliate.affiliateId}`} className="table-eye-link">
                          <Tree/>
                        </Link>
                      </TableCell>
                      {/* <TableCell>
                        <Link to="/home" onClick={() => this.setData(affiliate.affiliateId)} className="table-eye-link">
                          <Visibility />
                        </Link>
                      </TableCell> */}
                      {
                        this.state.permissions.includes(PERMISSIONS.EDIT_AFFILIATES) &&
                        <TableCell>
                          <Link to={`${process.env.PUBLIC_URL}/affiliator/edit/${affiliate.affiliateId}`} className="table-eye-link">
                            <Edit />
                          </Link>
                        </TableCell>
                      }
                    </TableRow>
                    )
                  }
                  <TableRow>
                    <TablePagination
                      count = {this.state.count}
                      page = {this.state.page}
                      rowsPerPage = {this.state.rowsPerPage}
                      onChangePage = {this.handleChangePage}
                      onChangeRowsPerPage = {this.handleChangeRowsPerPage}
                      rowsPerPageOptions = {[ 10,25,50 ]}
                    />
                  </TableRow>
                </TableBody>
              </Table>
            </Paper>
          </Grid>

        </Grid>

        {/* Image Drawer */}
        <Drawer
          className = "aff-drawer"
          open={this.state.imageDrawerOpen}
          classes={{
            paper: "aff-drawer",
          }}
          anchor = "right"
        >
          <Typography variant = "h6" className="d-flex align-items-center bg-theme text-white drawer-form-title sq-shadow pl-3">
            Edit Affiliator Image
          </Typography>

          <div className = "p-3">
            <div className="aff-image-container d-flex align-items-center justify-content-center">
              {
                this.state.imageUrl === "" ?
                <Typography variant="body1"> No image selected </Typography> : 
                <img src={this.state.imageUrl} alt = "" />
              }
            </div>

            <div className="mt-3">
              <label>
                <input type="file" className="d-none" onChange={this.handleFileChange}/>
                <Button variant="contained" color="primary" component="span">Choose File</Button>
              </label>
            </div>

            <div className="mt-3 text-right">
              <Button
                variant="contained"
                color="primary"
                className="mr-3"
                disabled = {
                  !this.state.isImageChanged ||
                  this.state.isSavingImage
                }
                onClick = {this.saveAffiliateImage}
              >
                {this.state.isSavingImage ? "Saving . . ." : "Save"}
              </Button>
              <Button variant="outlined" color="primary" onClick={this.handleCloseImageDrawer}>
                Cancel
              </Button>
            </div>
          </div>


        </Drawer>

        {/* Theme Drawer */}
        <Drawer
          className = "aff-drawer"
          open={this.state.themeDrawerOpen}
          classes={{
            paper: "aff-drawer",
          }}
          anchor = "right"
        >
          <Typography variant = "h6" className="d-flex align-items-center bg-theme text-white drawer-form-title sq-shadow pl-3">
            Edit Affiliator Theme
          </Typography>

          <div className = "p-3">
            <div className="theme-container">

              <div className={`aff-theme-container d-flex align-items-center sq-shadow ${this.state.selectedTheme === "default" ? "aff-theme-container-active" : ""}`} id="default-theme" onClick={() => this.handleClickTheme("default")}>
                <div className="text-center w-100">
                  <span className="d-inline-block"></span>
                  <Typography variant = "body2">
                    Default
                  </Typography>
                </div>
              </div>

              <div className={`aff-theme-container d-flex align-items-center sq-shadow ${this.state.selectedTheme === "red" ? "aff-theme-container-active" : ""}`} id="red-theme" onClick={() => this.handleClickTheme("red")}>
                <div className="text-center w-100">
                  <span className="d-inline-block"></span>
                  <Typography variant = "body2">
                    Red
                  </Typography>
                </div>
              </div>
             
              <div className={`aff-theme-container d-flex align-items-center sq-shadow ${this.state.selectedTheme === "purple" ? "aff-theme-container-active" : ""}`} id="purple-theme" onClick={() => this.handleClickTheme("purple")}>
                <div className="text-center w-100">
                  <span className="d-inline-block"></span>
                  <Typography variant = "body2">
                    Purple
                  </Typography>
                </div>
              </div>
              
              <div className={`aff-theme-container d-flex align-items-center sq-shadow ${this.state.selectedTheme === "green" ? "aff-theme-container-active" : ""}`} id="green-theme" onClick={() => this.handleClickTheme("green")}>
                <div className="text-center w-100">
                  <span className="d-inline-block"></span>
                  <Typography variant = "body2">
                    Green
                  </Typography>
                </div>
              </div>
              
              <div className={`aff-theme-container d-flex align-items-center sq-shadow ${this.state.selectedTheme === "blue" ? "aff-theme-container-active" : ""}`} id="blue-theme" onClick={() => this.handleClickTheme("blue")}>
                <div className="text-center w-100">
                  <span className="d-inline-block"></span>
                  <Typography variant = "body2">
                    Sky Blue
                  </Typography>
                </div>
              </div>

            </div>

            <div className="mt-3 text-right">
              <Button
                variant="contained"
                color="primary"
                className="mr-3"
                disabled = {
                  !this.state.isThemeChanged ||
                  this.state.isSavingTheme
                }
                onClick = {this.saveAffiliateTheme}
              >
                {this.state.isSavingTheme ? "Saving . . ." : "Save"}
              </Button>
              <Button variant="outlined" color="primary" onClick={this.handleCloseThemeDrawer}>
                Cancel
              </Button>
            </div>
          </div>


        </Drawer>
      </div>
    );
  }
}

export default withRouter(Affiliates);