import axios from "axios";
import AuthService from "./auth";

export default (history, showSnackbar) => {
  axios.interceptors.request.use((request) => {
    const url = request.url;
    if(!url.includes("/login") && !url.includes("checkout.com")){
      const token = localStorage.getItem("token");
      request.headers["auth-token"] = token;
    }
    return request;
  });
  
  axios.interceptors.response.use((response) => {
    return response;
  }, (error) => {
    const status = error.response.status;
    if(status === 401){
      showSnackbar();
      setTimeout(() => {
        AuthService.logout().then(() => {
          const {host, protocol} = window.location;
          const path = `${protocol}//${host}/login`;
          window.location.href = path;
        })
      }, 3000)
    }
    return Promise.reject(error);
  });
}
