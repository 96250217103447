import config from "./config";
import axios from "axios";
import { BehaviorSubject, Observable } from "rxjs";
import _ from "lodash";

let affiliate = {};
const baseUrl = config.baseUrl;

affiliate.addAffiliate = async (dataObj) => {
  const data = {
    ...dataObj,
    children: [],
    role: "Affiliate",
    isActive: true,
    created_date: new Date().toISOString(),
    image: "",
    theme: "default"
  }
  const url = `${baseUrl}/add-affiliate`;

  const res = await axios({
    url,
    method: "POST",
    data
  });
  affiliate.affiliateList$.next(undefined)
  return res;
}

affiliate.affiliateList$ = new BehaviorSubject(undefined);
affiliate.channelList$ = new BehaviorSubject(undefined);

affiliate.getAffiliates = async (page, limit, search) => {
  const url = `${baseUrl}/get-affiliates`;
	const params = {}

	if(page !== undefined){
		params.page = page;
	}
	if(limit !== undefined){
		params.limit = limit;
	}
	if(search !== undefined){
		params.search = search;
	}
  const res = await axios({
		url,
		params
	});
  return res;
}

affiliate.getChannels = async () => {
  const url = `${baseUrl}/channels`;
  const res = await axios(url);
  return res;
}

affiliate.getListAllChannels = async (hard = false) => {
  if(affiliate.channelList$.getValue() === undefined || hard === true){
    let channels = [];
    try {
      const url = `${baseUrl}/channels`;
      const res = await axios(url);
      channels = _.get(res, "data", []);
      affiliate.channelList$.next(channels);
    } catch (error) { }
    return channels;
  }else{
    return affiliate.channelList$.getValue();
  }
}

affiliate.getAllAffiliates = async () => {
  const url = `${baseUrl}/get-affiliates`;
  const res = await axios(url);
  return res;
}

affiliate.listAllAffiliates = async (hard = false) => {
    const url = `${baseUrl}/list-affiliates`;
    const res = await axios(url);
    return res;
}

affiliate.getListAllAffiliates = async (hard = false) => {
  if(affiliate.affiliateList$.getValue() === undefined || hard === true){
    let affiliates = [];
    try {
      const url = `${baseUrl}/list-affiliates`;
      const res = await axios(url);
      affiliates = _.get(res, "data", []);
      affiliate.affiliateList$.next(affiliates)
    } catch (error) {
    }
    return affiliates;
  }else{
    return affiliate.affiliateList$.getValue();
  }
}

affiliate.updateAffiliate = async (uid, data) => {
  const url = `${baseUrl}/affiliate/${uid}`;
  const res = axios({
    url,
    method: "PUT",
    data
  });
  affiliate.affiliateList$.next(undefined)

  return res;
}

affiliate.addTarget = async (uid, data) => {
  const url = `${baseUrl}/affiliate/${uid}/add-target`;
  const res = axios({
    url,
    method: "POST",
    data
  });

  return res;
}

affiliate.getTargets = async (uid) => {
  const url = `${baseUrl}/affiliate/${uid}/get-target`;
  const res = axios({ url });

  return res;
}

affiliate.getAffiliate = async (uid) => {
  const url = `${baseUrl}/affiliate/${uid}`;
  const res = await axios(url);
  return res;
}

affiliate.uploadImage = async (data) => {
  const url = `${baseUrl}/upload-affiliate-image`;
  const res = await axios({
    url,
    method: "POST",
    data
  });
  return res;
}

affiliate.getChildAffiliates = async (uid) => {
  const url = `${baseUrl}/affiliates-broker?uid=${uid}`
  const res = await axios(url);
  return res;
}

export default affiliate;