import React from "react";
import Navigation from "../common/Navigation";
import {
  Paper,
  Typography,
  Grid,
  Button,
  Drawer,
  TextField,
  InputAdornment,
  IconButton,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Switch,
  TablePagination,
  MenuItem,
  List,
  ListItem,
  Checkbox,
  ListItemText,
  ListItemIcon,
  FormControlLabel,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import { Link, withRouter } from "react-router-dom";
import Snackbar from "../common/Snackbar";
import { Edit, Delete } from "@material-ui/icons";
import ChannelService from "../../services/channel";
import _ from "lodash";
import DeleteConfirmDialog from "../common/DeleteConfirmDialog";

class index extends React.Component {
  state = {
    channels: [],
    page: 0,
    rowsPerPage: 10,
    count: 0,
    dialogActive: false,
    deleteTrue: false,
    delete_id: "",
    processing: false
  };

  componentDidMount() {
    this.getChannels();
  }

  getChannels = async () => {
    const page = this.state.page;
    const rowsPerPage = this.state.rowsPerPage;
    const data = await ChannelService.getChannels(rowsPerPage, page);
    this.setState({ channels: data.data.data, count: data.data.count });
  };

  handleChangePage = (e, page) => {
    this.setState({ page }, this.getChannels);
  };

  handleChangeRowsPerPage = (e) => {
    this.setState({ rowsPerPage: e.target.value }, this.getChannels);
  };

  confirmDelete = async (id) => {
    this.setState({ dialogActive: true, delete_id: id });
  };

  onCloseDialog = async (confirm) => {
    if (confirm === true) {
      this.deleteChannel(this.state.delete_id);
    }
    else {
      this.setState({ dialogActive: false, delete_id: undefined });
    }
  };

  deleteChannel = async (id) => {
    this.setState({ deleteTrue: true, processing: true });
    let deleted = false;
    try {
      const response = await ChannelService.deleteChannel(id);
      deleted = _.get(response, "data.status", false) ? true : false;
    } catch (error) { }

    if (deleted) {
      this.setState({
        showSnackbar: true,
        messageType: "danger",
        snackBarMessage: "Policy deleted successfully",
      });
    } else {
      this.setState({
        showSnackbar: true,
        messageType: "danger",
        snackBarMessage: "Something went wrong.",
      });
    }
    this.setState({ dialogActive: false, delete_id: undefined, processing: false });
    this.getChannels();
  };

  render() {
    return (
      <div className="d-flex">
        <Navigation />
        <Snackbar
          showSnackbar={this.state.showSnackbar}
          messageType={this.state.messageType}
          snackBarMessage={this.state.snackBarMessage}
          onCloseSnackbar={this.handleCloseSnackbar}
        />
        <div className="container-fluid">
          <Paper className="p-2 sq-shadow mb-3 d-flex">
            <Typography> <Link to={`${process.env.PUBLIC_URL}/`}>Home</Link> </Typography>
            <Typography className="mr-2 ml-2">/</Typography>
            <Typography><Link to={`${process.env.PUBLIC_URL}/affiliator`}>Affiliators</Link></Typography>
            <Typography className="mr-2 ml-2">/</Typography>
            <Typography> Channels </Typography>
          </Paper>
          <Grid container spacing={2}>
            <Grid item xs={6} className="d-flex align-items-center">
              <Link
                to={`${process.env.PUBLIC_URL}/affiliator/channel/create`}
                className="mr-3"
              >
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => this.setState({ drawerOpen: true })}
                >
                  Add New Channel
                </Button>
              </Link>
            </Grid>

            <Grid item xs={12}>
              <Paper className="sq-shadow policies-table-container">
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell> Name </TableCell>
                      <TableCell width="10%"> Delete </TableCell>
                      <TableCell width="10%"> Edit </TableCell>
                      
                      
                    </TableRow>
                  </TableHead>

                  {this.state.count != 0 ? (
                    <TableBody>
                      {this.state.channels.map((channel) => (
                        <TableRow key={channel._id}>
                          <TableCell>{channel.name}</TableCell>
                          <TableCell width="10%">
                            <Link
                              to="#"
                              onClick={() => this.confirmDelete(channel._id)}
                              className="table-eye-link"
                            >
                              <Delete />
                            </Link>
                          </TableCell>
                          <TableCell width="10%">
                            <Link
                              to={`${process.env.PUBLIC_URL}/affiliator/channel/edit/${channel._id}`}
                              className="table-eye-link"
                            >
                              <Edit />
                            </Link>
                          </TableCell>
                          
                        </TableRow>
                      ))}

                      <TableRow>
                        <TablePagination
                          count={this.state.count}
                          page={this.state.page}
                          rowsPerPage={this.state.rowsPerPage}
                          onChangePage={this.handleChangePage}
                          onChangeRowsPerPage={this.handleChangeRowsPerPage}
                          rowsPerPageOptions={[10, 25, 50]}
                        />
                      </TableRow>
                    </TableBody>
                  ) : (
                    <TableCell colSpan={3} align="center" width="100%">
                      No data found
                    </TableCell>
                  )}
                </Table>
              </Paper>
            </Grid>

            <DeleteConfirmDialog
              onClose={this.onCloseDialog}
              open={this.state.dialogActive}
              content="This channel may link with one or more Affiliates, after delete you cannot retrive."
              processing={this.state.processing}
            />
          </Grid>
        </div>
      </div>
    );
  }
}
export default withRouter(index);
