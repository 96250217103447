import axios from "axios";
import config from "./config";

let premium = {};
const {baseUrl} = config;

premium.update = async (data) => {
  const url = `${baseUrl}/premium`;
  const res = await axios({
    url,
    method : "PUT",
    data
  })
  return res;
}

premium.getPremium = async (id) => {
  const url = `${baseUrl}/premium/${id}`;
  const res = await axios({ url });
  return res;
}

export default premium;