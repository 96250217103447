import axios from "axios";
import config from "./config";

let policy = {};
const {baseUrl} = config;

policy.getAll = async (page, rowsPerPage, status, search) => {
  const centerId = sessionStorage.getItem("center_id");
  const url = `${baseUrl}/policy?page=${page}&limit=${rowsPerPage}${centerId ? `&center=${centerId}` : ""}${status ? `&status=${status}` : ""}${search ? `&search=${search}`: ""}`
  const res = await axios({ url });
  return res;
}

policy.getReferalPoliciesAll = async (page, rowsPerPage, status, search) => {
  const centerId = sessionStorage.getItem("center_id");
  const url = `${baseUrl}/policy-referal?page=${page}&limit=${rowsPerPage}${centerId ? `&center=${centerId}` : ""}${status ? `&status=${status}` : ""}${search ? `&search=${search}`: ""}`
  const res = await axios({ url });
  return res;
}

policy.getAllUnpaid = async (page, rowsPerPage, search) => {
  const centerId = sessionStorage.getItem("center_id");
  const url = `${baseUrl}/policies-unpaid?page=${page}&limit=${rowsPerPage}${centerId ? `&center=${centerId}` : ""}${search ? `&search=${search}`: ""}`
  const res = await axios({ url });
  return res;
}

policy.getPolicyById = async (id) => {
  const url = `${baseUrl}/policy/${id}`;
  const res = await axios({ url });
  return res;
}

policy.getReferalPolicyById = async (id) => {
  const url = `${baseUrl}/referal-policy/${id}`;
  const res = await axios({ url });
  return res;
}

policy.getAllPolicies = async (value) => {
  const centerId = sessionStorage.getItem("center_id");
  const url = `${baseUrl}/policy-all${centerId ? `?center=${centerId}` : ""}${value ? `${centerId ? "&" : "?"}search=${value}` : ""}`;
  const res = await axios({ url });
  return res;
}

policy.updateStatus = async (status, id) => {
      
  const url = `${baseUrl}/policy/${id}`;
  const res = await axios({
    url,
    method : "PUT",
    data : {
      status
    }
  })
  return res;
}

policy.updateComment = async (comment, id) => {
      
  const url = `${baseUrl}/policy/${id}`;
  const res = await axios({
    url,
    method : "PUT",
    data : {
      comment
    }
  })
  return res;
}

policy.update = async (obj, id) => {
  const url = `${baseUrl}/policy/${id}`;
  const res = await axios({
    url,
    method : "PUT",
    data : obj
  });

  return res;
}

policy.getHealthReport = async ({
  startDate,
  endDate,
  type,
  status,
  page,
  limit,
  sob,
  region,
  branch
}) => {
  const centerId = sessionStorage.getItem("center_id");
  const url = `${baseUrl}/policy-dash`;
  let params = {
    startDate : startDate,
    endDate : endDate
  }
  if(type){
    params.type = type;
  }
  if(status){
    params.status = status;
  }
  if(centerId){
    params.center = centerId;
  }
  if(page !== undefined){
    params.page = page;
    params.limit = limit;
  }
  if(sob){
    params.sob = sob;
  }
  if(region){
    params.region = region;
  }
  if(branch){
    params.branch = branch;
  }
  const res = await axios({
    url,
    params
  });
  return res;
}

policy.getConsolidateDashPolicies = async ({
  startDate,
  endDate,
  status,
  page,
  limit,
  sob,
  region,
  branch
}) => {
  const centerId = sessionStorage.getItem("center_id");
  const url = `${baseUrl}/consolidate-report-dash`;

  let params = {
    startDate : startDate,
    endDate : endDate
  }
  if(page !== undefined){
    params.page = page;
    params.limit = limit;
  }
  if(status){
    params.status = status;
  }
  if(centerId){
    params.center = centerId;
  }
  if(sob){
    params.sob = sob;
  }
  if(region){
    params.region = region;
  }
  if(branch){
    params.branch = branch;
  }
  
  const res = await axios({
    url,
    params
  });

  return res;
}

policy.getAnalysisData = async (startDate, endDate) => {
  const centerId = sessionStorage.getItem("center_id");
  const url = `${baseUrl}/policy-analysis${centerId ? `?center=${centerId}` : ""}${startDate ? `${centerId ? "&" : "?"}startDate=${startDate}&endDate=${endDate}` : ""}`;
  const res = await axios({ url });
  return res;
}

policy.getPaymentAnalysisData = async (startDate, endDate) => {
  const centerId = sessionStorage.getItem("center_id");
  const url = `${baseUrl}/policy-analysis-payment${centerId ? `?center=${centerId}` : ""}${startDate ? `${centerId ? "&" : "?"}startDate=${startDate}&endDate=${endDate}` : ""}`;
  const res = await axios({ url });
  return res;
}

policy.getChildrenAnalysis = async (startDate, endDate) => {
  const centerId = sessionStorage.getItem("center_id");
  const url = `${baseUrl}/affiliate-analysis?center=${centerId}${startDate ? `&startDate=${startDate}&endDate=${endDate}` : ""}`;
  const res = await axios({ url });
  return res;
}

policy.deletePolicy = async (id) => {
  const url = `${baseUrl}/policy/${id}`;
  const res = await axios({ url, method : "DELETE" });
  return res;
}

policy.addToPremia = async (id) => {
  const url = `${baseUrl}/add-to-premia/${id}`;
  const res = await axios({ url, method : "POST" });
  return res;
}

policy.getUHIPDocument = async(id) => {
  const url = `${baseUrl}/download-uhipdocument/${id}`
  const res = axios({url, responseType: "blob"});
  return res;
}

policy.updateToISA = async(id) => {
  const url = `${baseUrl}/update-isa-travel/${id}`;
  const res = await axios({ url, method : "POST" });
  return res;
}

policy.updatePaymentDetails = async (id, data) => {
  const url = `${baseUrl}/policy-payment/${id}`;
  const res = await axios({
    url,
    method : "PUT",
    data
  });
  return res;
}

policy.personal_accident_questions = {
  questions : [
    {
      key: "sufferFromImpairmentOfSight",
      validAnswer: "Yes",
      session_storage_key: "suffer_from_impairment_of_sight",
      question: ["HAVE_YOU_SUFFERED_OR_DO_YOU_SUFFER_FROM_IMPAIRMENT_OF_SIGHT_OR_HEARING,_VARICOSE_VEINS,_RUPTURE,_ANY_AILMENT_AFFECTING_THE_HEART_OR_ANY_OTHER_SERIOUS_INJURY_OR_ILLNESS?"]
    },
    {
      key: "isHealthy",
      validAnswer: "No",
      session_storage_key: "is_healthy",
      question: ["ARE_YOU_OF_HEALTHY_AND_UNIMPAIRED_CONSTITUTION_AND_AT_PRESENT_IN_SOUND_HEALTH?"]
    },
    {
      key: "practiseAcitivty",
      validAnswer: "Yes",
      session_storage_key: "practise_acitivty",
      question: [
        "DO_YOU_PRACTISE_ANY_OF_THE_FOLLOWING_ACTIVITIES:",
        "(A)_HUNTING",
        "(B)_STEEPLE_CHASING",
        "(C)_RACING_OF_ANY_KIND",
        "(D)_RUGBY_FOOTBALL",
        "(E)_POLO",
        "(F)_MOTOR_CYCLING",
        "(G)_MOUNTAINEERING_",
        "(H)_BIG_GAME_SHOOTING",
        "(I)_WINTER_SPORTS",
        "(J)_AIR_TRAVEL_"
      ]
    },
    {
      key: "haveIntentionLeavingCountry",
      validAnswer: "Yes",
      session_storage_key: "have_intention_leaving_country",
      question: ["HAVE_YOU_ANY_INTENTION_OF_LEAVING_THE_COUNTRY_IN_WHICH_YOU_ARE_AT_PRESENT_RESIDING?_IF_SO,_WHAT_PART_OF_THE_WORLD_DO_YOU_INTEND_TO_VISIT,_FOR_WHAT_PERIOD_AND_FOR_WHAT_PURPOSE?"]
    },
    {
      key: "haveHazardousCricumstances",
      validAnswer: "Yes",
      session_storage_key: "have_hazardous_cricumstances",
      question: ["ARE_THERE_ANY_CIRCUMSTANCES_CONNECTED_WITH_YOUR_OCCUPATION,_HEALTH,_HABITS_OR_PURSUITS_WHICH_WOULD_RENDER_THE_INSURANCE_SUCH_AS_IS_PROPOSED_MORE_THAN_USUALLY_HAZARDOUS?"]
    },
    {
      key: "haveAnyProposalDeclined",
      validAnswer: "Yes",
      session_storage_key: "have_any_proposal_declined",
      question: ["HAS_ANY_PROPOSAL_BEEN_MADE_TO_INSURE_YOUR_LIFE?_IF_SO,_ANY_SUCH_PROPOSAL_BEEN_DECLINED,_DEFERRED,_WITHDRAWN_OR_ACCEPTED_AT_AN_INCREASED_PREMIUM?"]
    },
    {
      key: "havePADeclined",
      validAnswer: "Yes",
      session_storage_key: "have_pa_declined",
      question: ["HAVE_YOU_EVER_PROPOSED_FOR_INSURANCE_IN_RESPECT_OF_PERSONAL_ACCIDENT_OR_SICKNESS?_IF_SO,_HAS_ANY_SUCH_PROPOSAL_OR_RENEWAL_OF_SUCH_INSURANCE_EVER_BEEN,_DECLINED,_WITHDRAWN_OR_MADE_SUBJECT_TO_A_RESTRICTIVE_ENDORSEMENT?"]
    },
    {
      key: "havePaPolicy",
      validAnswer: "Yes",
      session_storage_key: "have_pa_policy",
      question: ["HAVE_YOU_A_PERSONAL_ACCIDENT_AND_/_OR_SICKNESS_POLICY_IN_FORCE?_IF_SO,_STATE_WITH_WHOM_AND_THE_TOTAL_AMOUNT_OF_INSURANCE"]
    },
    {
      key: "haveClaimedCompensation",
      validAnswer: "Yes",
      session_storage_key: "have_claimed_compensation",
      question: ["HAVE_YOU_EVER_CLAIMED_OR_RECEIVED_COMPENSATION_UNDER_ANY_ACCIDENT_OR_SICKNESS_POLICY?_IF_SO,_GIVE_FULL_PARTICULARS,_NAME_OF_INSURER,_AMOUNTS_AND_DATES"]
    },
  ],
  permissions : []
}

export default policy;