import config from "./config";
import axios from "axios";
import AffiliateService from "./affiliates";
let channel = {};
const baseUrl = config.baseUrl;


channel.getChannels = async (limit ,page) => {
    const url = `${baseUrl}/channels?limit=${limit}&page=${page}`;
    const res = await axios(url);
    return res;
  }

channel.channelAdd = async(data) => {
  
  const url = `${baseUrl}/channels`;
  const res = await axios({
    url,
    method: "POST",
    data
  });
  AffiliateService.channelList$.next(undefined);
  return res;
}

channel.updateChannel = async (uid, data) => {
  const url = `${baseUrl}/channels/${uid}`;
  const res = axios({
    url,
    method: "PUT",
    data
  });
  AffiliateService.channelList$.next(undefined);
  return res;
}

channel.deleteChannel = async (id) => {
  const url = `${baseUrl}/channels/${id}`;
  const res = await axios({
    url,
    method: "DELETE",
    id
  });
  return res;
}

channel.getChannel = async (uid) => {
  const url = `${baseUrl}/channels/${uid}`;
  const res = await axios(url);
  return res;
}



export default channel;