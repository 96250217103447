import React, { useEffect, useRef, useState } from "react";
import { Switch } from "@material-ui/core";
import AffiliateAppService from "../../services/affiliateApp";
import Snackbar from "../common/Snackbar";


function AffiliateAppChangeStatus({ affiliateAppId, status, appId, loadCallback }) {

    const [snackbar, setShowSnackbar] = useState({
        showSnackbar: false,
        messageType: "",
        snackBarMessage: ""
    })
    const handleCloseSnackbar = () => {
        let _snackbar = { ...snackbar }
        _snackbar.showSnackbar = false;
        setShowSnackbar(_snackbar)
    }
    useEffect(() => { }, [])
    const switchChange = (e) => {
        AffiliateAppService.updateStatus(affiliateAppId, appId, { status: e.target.checked })
            .then((response) => {
                if (response.data && response.data.success) {
                    setShowSnackbar({
                        showSnackbar: true,
                        messageType: "success",
                        snackBarMessage: response.data.message
                    })
                    loadCallback();
                } else {
                    setShowSnackbar({
                        showSnackbar: true,
                        messageType: "danger",
                        snackBarMessage: "Something went wrong."
                    })
                }
            }).catch(err => {
                setShowSnackbar({
                    showSnackbar: true,
                    messageType: "danger",
                    snackBarMessage: "Something went wrong."
                })
            })
    }

    return (
        <React.Fragment>
            <Snackbar
                showSnackbar={snackbar.showSnackbar}
                messageType={snackbar.messageType}
                snackBarMessage={snackbar.snackBarMessage}
                onCloseSnackbar={handleCloseSnackbar}
            />
            <Switch color="primary" checked={status} onChange={switchChange} name="status" />
        </React.Fragment>
    );
}


export default AffiliateAppChangeStatus;