import React from 'react';
import { Document, Font, Image, Page, StyleSheet, Text, View } from "@react-pdf/renderer"
import Header from '../../static/images/riyati-policy-schedule-header.png';
import LatoRegular from "../../static/fonts/Lato/Lato-Regular.ttf";
import LatoBold from "../../static/fonts/Lato/Lato-Bold.ttf";
import Stamp from "../../static/images/pa-schedule-signature.jpg";
import moment from 'moment';
import _ from "lodash";
import utils from '../../services/utils';

Font.register({
    family: 'Lato', fonts: [
        { src: LatoRegular }, // font-style: normal, font-weight: normal
        { src: LatoBold, fontWeight: 700 }
    ]
});

const styles = StyleSheet.create({
    page: {
        fontSize: 12,
        marginTop: 20,
        paddingHorizontal: 20,
    },
    fontSize9: {
        fontSize: 9
    },
    container: {
        fontFamily: 'Lato',
        paddingVertical: 20,
        paddingHorizontal: 20,
    },
    headerImage: {
        width: "25%",
        paddingVertical: 5,
        paddingHorizontal: 20
    },
    bold: { fontWeight: 700 },
    mt1: { marginTop: 10 },
    mb1: { marginBottom: 5 },
    mt2: { marginTop: 20, float: "right" },
    mt3: { marginTop: 30 },
    textCenter: { textAlign: "center" },
    row: {
        display: "flex",
        flexDirection: "rows"
    },
    table_half_column: {
        width: "51%"
    },
    table_column_grow: {
        flexGrow: 1
    },
    col_sr: { width: "5%", padding: 5 },
    col_auto: { width: "auto", padding: 5 },


    table: {
        width: '100%',
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
    },
    header: {
        borderTop: 'none',
    },
    row1: {
        width: '5%',
    },
    row2: {
        width: '26%',
    },
    row3: {
        width: '10%',
    },
    row4: {
        width: '15%',

    },
    row5: {
        width: '17%',

    },
    row6: {
        width: '27%',
    },
    table_third_column: {
        width: "33.33%"
    },
    textUnderline: {
        textDecoration: "underline"
    },
    border: {
        width: '100%',
        borderTop: 1
    },
    _row: {
        display: "flex",
        flexDirection: "row"
    },
    stamp_row: {
        justifyContent: "flex-end",
        marginVertical: 10
    },
    stamp_container: {
        width: 180,
        display: "flex",
        alignItems: "center"
    },
    stamp: {
        height: 104,
        width: 109
    },
});


const RiyatiSchedulePDF = ({ policy }) => {

    const date = moment(policy.startDate, "x").format("DD/MM/YYYY");
    const policy_no = _.get(policy, "_id", '');
    const name = _.get(policy, "personalInformation.fullName", '');
    const fromDate = moment(policy.startDate, "x").format("DD/MM/YYYY");
    const toDate = moment(policy.startDate, "x").add(policy.riyati_duration_days - 1, "d").format("DD/MM/YYYY");
    const dob = _.get(policy, "personalInformation.fullName", false) ? moment(policy.personalInformation.dob, "DD-MM-YYYY").format("DD/MM/YYYY") : "";
    const passport_no = _.get(policy, "personalInformation.passport", false);
    const base_premium = utils.getFormattedAmount(_.get(policy, "amount.base_price", 0));
    const policy_fee = utils.getFormattedAmount(_.get(policy, "amount.policy_fee", 0));
    const gov_fee = utils.getFormattedAmount(_.get(policy, "amount.government_fee", 0));
    const emergency_fee = utils.getFormattedAmount(_.get(policy, "amount.emergency_fund_fee", 0));
    const vat = utils.getFormattedAmount(_.get(policy, "amount.vat", 0));
    const total_price = utils.getFormattedAmount(_.get(policy, "amount.total", 0));

    const family_members = _.get(policy, "family_members", []);

    return <Document creator="Dhofar" producer="Dhofar" title="RIYATI INSURANCE POLICY SCHEDULE">
        <Page style={styles.page}>
            <Image source={Header} style={styles.headerImage} />
            <View style={styles.container}>
                <View style={[styles.row]}>
                    <View style={[styles.table_half_column]}>
                        <Text style={[styles.bold]}>DATE</Text>
                        <Text style={[styles.bold]}>RIYATI SCHEME (KHAREEF SALALAH MEDICAL SCHEME)</Text>

                    </View>
                    <View style={[styles.table_half_column]}>
                        <Text>&nbsp; &nbsp; &nbsp; {date}</Text>
                        <Text>&nbsp; &nbsp; &nbsp; POLICY NO: {policy_no}</Text>
                    </View>
                </View>

                <View style={[styles.row, styles.mt1]}>
                    <View style={[styles.table_third_column]}>
                        <Text style={[styles.bold]}>NAME</Text>
                        <Text style={[styles.bold]}>POLICY PERIOD</Text>
                    </View>
                    <View style={styles.table_column_grow}>
                        <Text>: {name}</Text>
                        <Text>: FROM: {fromDate} &nbsp; &nbsp; TO: {toDate}</Text>
                    </View>

                </View>
                <Text style={[styles.textCenter, styles.mt1, styles.bold, styles.textUnderline]}> RIYATI SCHEME (KHAREEF SALALAH MEDICAL SCHEME) </Text>


                {
                    (Array.isArray(family_members) && family_members.length >= 1) ?
                        <View style={styles.mt2}>
                            <Text style={[styles.mt1, styles.bold]}>FAMILY MEMBER DETAILS</Text>
                            <View style={styles.table}>
                                <View style={[styles.row, styles.bold, styles.header]}>
                                    <Text style={styles.row1}>Sr.</Text>
                                    <Text style={styles.row2}>Name</Text>
                                    <Text style={styles.row3}>Age</Text>
                                    <Text style={styles.row4}>Relation</Text>
                                    <Text style={styles.row5}>Date of Birth</Text>
                                    <Text style={styles.row6}>Passport No</Text>
                                </View>
                                {family_members.map((row, i) => (
                                    <View key={i} style={styles.row} wrap={false}>
                                        <Text style={styles.row1}>{i + 1}</Text>
                                        <Text style={styles.row2}>{row.name}</Text>
                                        <Text style={styles.row3}>{row.age}</Text>
                                        <Text style={styles.row4}>{row.relation}</Text>
                                        <Text style={styles.row5}>{row.dob}</Text>
                                        <Text style={styles.row6}>{row.passport_no}</Text>
                                    </View>
                                ))}
                            </View>

                        </View> : <View style={[styles.row, styles.mt1]}>
                            <View style={[styles.table_third_column]}>
                                <Text style={[styles.bold]}>DATE OF BIRTH</Text>
                                <Text style={[styles.bold]}>PASSPORT NUMBER</Text>
                            </View>
                            <View style={[styles.table_third_column]}>
                                <Text>: {dob}</Text>
                                <Text>: {passport_no}</Text>
                            </View>

                        </View>
                }

                <Text style={[styles.mt1, styles.bold]}> INSURANCE BENEFITS</Text>
                <View style={[styles.row, styles.mt1]}>
                    <View style={[styles.table_third_column]}>
                        <Text style={[styles.bold]}>NETWORK</Text>
                        <Text style={[styles.bold]}>GEOGRAPHY SCOPE</Text>
                        <Text style={[styles.bold]}>SUM ASSURED</Text>

                    </View>
                    <View style={[styles.table_half_column]}>
                        <Text>: VPS GROUP (BURJEEL & LIFELINE HOSPITALS)</Text>
                        <Text>: OMAN ONLY</Text>
                        <Text>: OMR 2,000</Text>
                    </View>

                </View>
                <Text style={[styles.mt1, styles.bold]}>
                    HOSPITAL ACCOMMODATION & RELATED SERVICES AT NETWORK COVERED WITHOUT CO. PAY
                </Text>

                <View style={[styles.row, styles.mt1]}>
                    <View style={[styles.table_third_column]}>
                        <Text style={[styles.bold]}>PREMIUM</Text>
                        <Text style={[styles.bold]}>POLICY FEE</Text>
                        <Text style={[styles.bold]}>GOVT FEE</Text>
                        <Text style={[styles.bold]}>EMERGENCY FUND FEE</Text>
                        <Text style={[styles.bold]}>VAT 5%</Text>
                    </View>
                    <View style={[styles.table_third_column]}>
                        <Text>: {base_premium}</Text>
                        <Text>: {policy_fee}</Text>
                        <Text>: {gov_fee}</Text>
                        <Text>: {emergency_fee}</Text>
                        <Text>: {vat}</Text>
                    </View>
                    <View style={[styles.table_third_column]}>
                        <Text>OMR</Text>
                        <Text>OMR</Text>
                        <Text>OMR</Text>
                        <Text>OMR</Text>
                        <Text>OMR</Text>
                    </View>
                </View>

                <View style={styles.border}>
                    <View style={[styles.row]}>
                        <Text style={[styles.table_third_column, styles.bold]}>TOTAL PREMIUM</Text>
                        <Text style={styles.table_third_column}>: {total_price}</Text>
                        <Text style={styles.table_third_column}>OMR</Text>
                    </View>
                </View>

                <View style={[styles.row]}>
                    <View style={[styles.table_half_column, styles.mt3]}>
                        <Text>Dhofar toll free number : 80012340</Text>
                        <Text>{`
                    For coordination in Lifeline Hospital, Salalah
                    Contact number :
                    Shabeer - +968 9207 1021
                    Ranjith- +968 9128 1031
                    `}</Text>
                    </View>

                    <View style={[styles._row, styles.stamp_row, styles.table_half_column, styles.mt3]}>
                        <View style={[styles.stamp_container]}>
                            <Text> Signature of the Issuing Authority </Text>
                            <Image source={Stamp} style={styles.stamp} />
                            <Text> Stamp / Seal </Text>
                        </View>
                    </View>
                </View>
            </View>


        </Page>
    </Document>
}

export default RiyatiSchedulePDF;