import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from "@material-ui/core";
import _ from "lodash";
import PropTypes from "prop-types";

class DeleteConfirmDialog extends React.Component {
  componentWillReceiveProps(props) {
    this.onClose = props.onClose;
    let title = _.get(props, "title", "Are you sure ?");
    let content = _.get(props, "content", false);
    let processing = _.get(props, "processing", false);
    this.setState({ open: props.open, title, content , processing });
  }

  state = {
    open: false,
    title: "",
    content: "",
  };

  handleClose = (save = false) => {
    if(!this.state.processing){
      this.onClose(save);
    }
  };

  render() {
    return (
      <Dialog
        open={this.state.open}
        onClose={this.handleClose}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle>{this.state.title}</DialogTitle>
        {this.state.content && (
          <DialogContent>
            <DialogContentText>{this.state.content}</DialogContentText>
          </DialogContent>
        )}
        <DialogActions>
          <Button size="small" autoFocus disabled={this.state.processing} onClick={this.handleClose} variant="contained">
            No
          </Button>
          <Button size="small"
            onClick={this.handleClose.bind(this, true)}
            disabled={this.state.processing}
            variant="contained"
            color="primary"
          >
            { this.state.processing ? "Processing..." : "Yes" }
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

DeleteConfirmDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  title: PropTypes.string,
  content: PropTypes.string,
  processing: PropTypes.bool,
};

export default DeleteConfirmDialog;
