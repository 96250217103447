import { Button, Grid, Paper, TextField, Typography } from "@material-ui/core";
import React from "react";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import Navigation from "../common/Navigation";
import Snackbar from "../common/Snackbar";
import ChannelService from "../../services/channel";


class CreateChannel extends React.Component {

  state = {
    name: "",
    isLoading: false,
    showSnackbar: false,
    snackBarMessage: "",
    messageType: "",
    isEditing : false,
    channel_id : ''
    
  }

  async componentDidMount() {
    const id = this.props.match.params.id
    if(id){
      this.getChannel(id);
      this.setState({ isEditing : true, channel_id : id })
    }
  }

  getChannel = (id) => {
    ChannelService.getChannel(id).then((res) => {

      const channel = res.data.data;
      const {
        name 
      } = channel;

      this.setState({
        name
      })
    })
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  }

  
  saveChannel = async () => {
    
    var data = {
      name: this.state.name
    }
    this.setState({ isLoading: true });
    if(this.state.isEditing){
      ChannelService.updateChannel(this.state.channel_id, data).then((channelEditRes) => {
        if(channelEditRes.data.status === true)
        {
          this.setState({
            showSnackbar: true,
            snackBarMessage: "Channel edited successfully.",
            messageType: "success",
            isLoading : false
          });
        }
        else
        {
          this.setState({
            showSnackbar: true,
            snackBarMessage: channelEditRes.data.message,
            messageType: "danger",
            isLoading : false
          });
        }
      })
    }
    else{
      
      
      try {
          let response = await ChannelService.channelAdd(data);
          if(response.data.status === true)
            {
              this.setState({
                name: "",
                isLoading: false,
                showSnackbar: true,
                snackBarMessage: "Channel added successfully,",
                messageType: "success"
            })
          }
          else{
            this.setState({
              isLoading: false,
              showSnackbar: true,
              snackBarMessage: response.data.message,
              messageType: "danger"
          })
          }
      } catch (error) { }
    }
    
  }
  

  handleCloseSnackbar = () => {
    this.setState({ showSnackbar: false })
  }


  isValid = () => {
    if(this.state.name === "" ){
      return false
    }
    return true;
  }
  

  render() {
    return (
      <div className="d-flex">
        <Navigation />

        <Snackbar
          showSnackbar={this.state.showSnackbar}
          messageType={this.state.messageType}
          snackBarMessage={this.state.snackBarMessage}
          onCloseSnackbar={this.handleCloseSnackbar}
        />

        <div className="container-fluid">
          <Paper className="p-2 sq-shadow mb-3 d-flex">
            <Typography><Link to={`${process.env.PUBLIC_URL}/`}>Home</Link></Typography>
            <Typography className="mr-2 ml-2">/</Typography>
            <Typography><Link to={`${process.env.PUBLIC_URL}/affiliator`}>Affiliators</Link></Typography>
            <Typography className="mr-2 ml-2">/</Typography>
            <Typography><Link to={`${process.env.PUBLIC_URL}/affiliator/channel`}>Channels</Link></Typography>
            <Typography className="mr-2 ml-2">/</Typography>
            <Typography> 
            {this.state.isEditing ? "Edit Channel" : "Add New Channel"} 
            </Typography>
          </Paper>

          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={3}>
              <Paper className="sq-shadow p-3">
                <TextField
                  label="Channel Name"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  name="name"
                  onChange={this.handleChange}
                  value={this.state.name}
                />
              </Paper>
            </Grid>
            <Grid item xs = {12}>
              <Paper className="sq-shadow p-2 d-flex justify-content-end">
                <Link to = "/affiliator/channel" className = "d-inline-block mr-3">
                  <Button variant="outlined" color="primary">Cancel</Button>
                </Link>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={
                    !this.isValid() || this.state.isLoading
                  }
                  onClick={this.saveChannel}
                >
                  {this.state.isLoading ? "Saving . . ." : "Save"}
                </Button>
              </Paper>
            </Grid>

          </Grid>
        </div>

      </div>
    )
  }
}

export default withRouter(CreateChannel);