import { Button, Grid, MenuItem, Paper, Table, TableBody, TableCell, TableHead, TablePagination, TableRow, TextField, Typography } from "@material-ui/core";
import React from "react";
import { withRouter } from "react-router";
import AffiliateService from "../../services/affiliates";

const months = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"]
const INSURANCE_TYPES = {
    health: "Health",
    life: "Life",
    travel: "Travel",
    home: "Home"
}

class ViewTarget extends React.Component {

    state = {
        affiliate: "",
        targets: [],
        tableData: [],
        isLoading: false,
        page: 0,
        rowsPerPage: 10,
    }

    componentDidMount() {
        this.getAffiliates();
    }

    handleChangeAffiliate = (e) => {
        const affiliate = e.target.value;
        this.setState({ affiliate });
    }

    handleChangePage = (e, page) => {
        this.setState({ page }, this.loadTableData);
    }

    handleChangeRowsPerPage = (e) => {
        this.setState({ rowsPerPage: e.target.value }, this.loadTableData);
    }

    getAffiliates = async () => {
        const res = await AffiliateService.getAllAffiliates();
        this.setState({ affiliates: res.data });
    }

    handleSearch = async () => {
        this.setState({ isLoading: true });
        const status = this.state.status === "All" ? undefined : this.state.status;
        try {
            const data = await AffiliateService.getTargets(this.state.affiliate);
            if (data.status === 200) {
                let targets = data.data.data;
                this.setState({ isLoading: false, showTable: true, targets: targets, totalRecords: targets.length }, this.loadTableData)
                return;
            }
        } catch (error) {

        }
        this.setState({ isLoading: false, showTable: false, targets: [] })
    }

    loadTableData = () => {
        let targets = [];
        let start = this.state.page * this.state.rowsPerPage;
        let end = (this.state.page + 1) * this.state.rowsPerPage;
        for (let index = start; index <= end; index++) {
            if (this.state.targets[index]) targets.push({ ...this.state.targets[index] })
        }
        this.setState({ tableData: targets });
    }

    getFormattedDate = (date) => {
        const newDate = new Date(date);
        const day = newDate.getDate();
        const month = months[newDate.getMonth()];
        const year = newDate.getFullYear();
        return `${day < 10 ? "0" + day : day}-${month}-${year}`;
    }

    getFormattedAmount = (amount) => {
        amount = parseFloat(amount).toFixed(3);
        amount = amount === "NaN" ? "0.000" : amount
        amount = amount.split(".");
        amount[0] = parseInt(amount[0]).toLocaleString()
        return amount.join(".");
    }

    render() {
        return (
            <React.Fragment>
                <Grid container spacing={2} className="mt-2">
                    <Grid item xs={3} >
                        <TextField
                            label="Affiliate"
                            variant="outlined"
                            margin="normal"
                            select
                            fullWidth
                            size="small"
                            className="mt-1"
                            name="affiliate"
                            onChange={this.handleChangeAffiliate}
                            value={this.state.affiliate}
                        >
                            <MenuItem value="" disabled>Select Affiliate</MenuItem>
                            {
                                Array.isArray(this.state.affiliates) && this.state.affiliates.map((affiliate) => {
                                    if (affiliate.affiliateId !== this.state.affiliate_id) {
                                        return (
                                            <MenuItem key={affiliate.affiliateId} value={affiliate.affiliateId}>{affiliate.name}</MenuItem>
                                        )
                                    }
                                })
                            }
                        </TextField>
                    </Grid>
                    <Grid item xs={3} >
                        <Button className="mt-1" variant="contained" color="primary"
                            disabled={this.state.isLoading || this.state.affiliate === ""} onClick={this.handleSearch}>{this.state.isLoading ? "Searching . . ." : "Search"}</Button>
                    </Grid>
                </Grid>

                {
                    (this.state.showTable && this.state.targets.length > 0) &&
                    <Grid container spacing={2} className="d-flex">
                        <Grid item xs={12}>
                            <Paper className="sq-shadow overflow-auto">
                                <div className="dash-table-container">
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell> Start Date </TableCell>
                                                <TableCell> End Date </TableCell>
                                                <TableCell> Insurance Type </TableCell>
                                                <TableCell className="text-right"> Target </TableCell>
                                                <TableCell className="text-right"> Target Archived </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {
                                                this.state.tableData.map((data, ind) => {
                                                    return (
                                                        <TableRow key={ind}>
                                                            <TableCell>{this.getFormattedDate(data.start_date)}</TableCell>
                                                            <TableCell> {this.getFormattedDate(data.end_date)} </TableCell>
                                                            <TableCell> {INSURANCE_TYPES[data.insurance_type]} </TableCell>
                                                            <TableCell className="text-right"> {this.getFormattedAmount(data.target)} </TableCell>
                                                            <TableCell className="text-right"> {this.getFormattedAmount(data.targetArchived)} </TableCell>
                                                        </TableRow>
                                                    )
                                                })
                                            }
                                            <TableRow>
                                                <TablePagination
                                                    rowsPerPage={this.state.rowsPerPage}
                                                    rowsPerPageOptions={[10, 25, 50]}
                                                    page={this.state.page}
                                                    count={this.state.totalRecords}
                                                    colSpan={0}
                                                    onChangePage={this.handleChangePage}
                                                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                                />
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </div>
                            </Paper>
                        </Grid>
                    </Grid>
                }
                {
                    (this.state.showTable && this.state.targets.length === 0) &&
                    <Paper className="q-shadow mt-3 pt-5 pb-5 d-flex align-items-center justify-content-center">
                        <Typography variant="h5"> No Data Found </Typography>
                    </Paper>
                }
            </React.Fragment>
        )
    }
}

export default withRouter(ViewTarget);