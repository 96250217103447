import { Button, Grid, Paper, List, ListItem, TextField, Typography } from "@material-ui/core";
import React from "react";
import PlanService from "../../../services/plans";
import _ from "lodash";
import Snackbar from "../../common/Snackbar";
import RiyatiFamily from "./RiyatiFamily"

class HomePlan extends React.Component {

    state = {
        personal_accident_premium: 0,
        prices: [{ days: 0, premium: 0 }],
        old_prices: "",
        loading: false,
        showSnackbar: false,
        snackBarMessage: "",
        messageType: "",
        process: false
        
    }

    handleCloseSnackbar = () => {
        this.setState({ showSnackbar: false })
    }

    componentDidMount() {
        this.loadDetails()
    }

    loadDetails = async () => {
        let riyati_plan_price = await PlanService.getPlanPrices("riyati");
        let prices = _.get(riyati_plan_price, "data.data.riyati_individual_plan.prices", []);
        let old_prices = JSON.stringify(_.get(riyati_plan_price, "data.data.riyati_individual_plan.prices", []))
        this.setState({ prices, old_prices })
    }

    handleChange = (e, index) => {
        let prices = [...this.state.prices];
        prices[index][e.target.name] = e.target.value;
        this.setState({ prices, process:false })
    }
    saveRiyatiPlan = async () => {
        this.setState({ loading: true })
        let status = false;
        try {
            let response = await PlanService.updatePlanPremium("riyati", "Individual", { prices: this.state.prices })
            if (response.data.status) {
                status = true
            }

        } catch (error) { }
        this.loadDetails();
        this.setState({
            loading: false,
            showSnackbar: true,
            process:true,
            snackBarMessage: status ? "Riyati Individual Plan premium updated." : "Something went wrong.",
            messageType: status ? "success" : "danger"
        });
    }
    isValid = () => {
        if (this.state.prices.length) {
            let premium = this.state.prices.filter(price => {
                return (price.premium === "" || price.premium < 0)
            })
            return premium.length ? false : true;
        } else {
            return false;
        }
    }

    isChanged = () => {
        try {
            return this.state.old_prices !== JSON.stringify(this.state.prices);
        } catch (error) {}
        return true
    }
    render() {
        return (
            <React.Fragment>
                <Snackbar
                    showSnackbar={this.state.showSnackbar}
                    messageType={this.state.messageType}
                    snackBarMessage={this.state.snackBarMessage}
                    onCloseSnackbar={this.handleCloseSnackbar}
                />
                <Grid item xs={12} md={4} sm={6}>
                    <Paper className="sq-shadow p-2">
                        <Typography><strong>Individual Plan</strong></Typography>
                        <br />
                        <List>
                            {
                                Array.isArray(this.state.prices) && this.state.prices.map((price, ind) => {
                                    return (<ListItem key={ind}>
                                        <TextField
                                            type="number"
                                            fullWidth
                                            value={price.premium}
                                            label={`${price.days} Days Premium`} variant="outlined"
                                            name="premium"
                                            onChange={e => { this.handleChange(e, ind) }}
                                        />
                                    </ListItem>)
                                })
                            }
                        </List>
                        <br />
                        <Button
                            className="ml-3"
                            onClick={this.saveRiyatiPlan}
                            disabled={!this.isValid() || this.state.loading || !this.isChanged()|| this.state.process}
                            variant="contained" color="primary">
                            {this.state.loading ? "Processing..." : "Save"}
                        </Button>

                    </Paper>
                </Grid>
                <Grid item xs={12} md={4} sm={6}>
                    <RiyatiFamily />
                </Grid>

            </React.Fragment>
        )
    }
}
export default HomePlan;