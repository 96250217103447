import { Button, Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel, Grid, MenuItem, Paper, TextField, Typography } from "@material-ui/core";
import React from "react";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import Navigation from "../common/Navigation";
import Snackbar from "../common/Snackbar";
import ChannelService from "../../services/channel";
import DiscountService from "../../services/discount";
import AuthService from "../../services/auth";
import _ from "lodash";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import moment from "moment";

const CUSTOMER_TYPE = {
    CUSTOMER : "Customer",
    STAFF : "Staff"
}

class DiscountCreate extends React.Component {

    products = {
        "life-personal_accident": { name: "Life Personal Accident" },
        "life-term_plan": { name: "Life Term Plan" },
        "health-individual_medical": { name: "Health Individual Medical" },
        "health-affordable_health_plan": { name: "Health Affordable Health Plan" },
        "health-unified_domestic_worker": { name: "Health Unified Domestic Worker" },
        "health-unified_health_insurance_plan": { name: "Health Unified Health Insurance Plan" },
        "travel-standard_travel": { name: "Travel Standard Travel" },
        "travel-lite_travel": { name: "Travel Lite Travel" },
        "travel-senior_travel": { name: "Travel Senior Travel" },
        "travel-covid_shield_travel": { name: "Travel Covid Shield Travel" },
        "travel-family_travel": { name: "Travel Family Travel" },
        "home": { name: "Home" },
    }

    state = {
        isLoading: false,
        showSnackbar: false,
        snackBarMessage: "",
        messageType: "",
        isEditing: false,
        discount_id: '',
        code: "",
        details: "",
        type: "",
        validity: "",
        discount: "",
        discount_staff: "",
        min_amount: "",
        min_amount_staff: "",
        max_discount: "",
        max_discount_staff: "",
        status: "",
        products: "",
        errors: {}
    }

    async componentDidMount() {
        AuthService.sendNotification("Something went wrong.", "danger");
        const id = this.props.match.params.id
        if (id) {
            this.getDiscount(id);
            this.setState({ isEditing: true, discount_id: id })
        }
    }

    getDiscount = (id) => {
        DiscountService.getDiscount(id).then((res) => {
        
            const discountres = res.data.data;
            let {
                details,
                code,
                type,
                validity,
                discount,
                discount_staff,
                max_discount,
                max_discount_staff,
                min_amount,
                min_amount_staff,
                status,
                products
            } = discountres;

            validity = validity && moment(validity, "DD-MM-YYYY").isValid() ? moment(validity, "DD-MM-YYYY").format("MM-DD-YYYY") : null;

            this.setState({
                details,
                code,
                type,
                validity,
                discount : discount || "",
                discount_staff : discount_staff || "",
                max_discount : max_discount || "",
                max_discount_staff : max_discount_staff || "",
                min_amount : min_amount || "",
                min_amount_staff : min_amount_staff || "",
                status,
                products :Array.isArray(products) ? products : []
            })
        })
    }

    handleChange = (e) => {
        let errors = { ...this.state.errors };
        delete errors[e.target.name];
        this.setState({ [e.target.name]: e.target.value, errors });
    }


    saveDiscount = async () => {

        var data = {
            code: this.state.code,
            details: this.state.details,
            type: this.state.type,
            validity: this.state.validity ? moment(this.state.validity).format("DD-MM-YYYY") : "",
            discount: this.state.discount,
            discount_staff: this.state.discount_staff,
            min_amount: this.state.min_amount,
            min_amount_staff: this.state.min_amount_staff,
            max_discount: this.state.max_discount,
            max_discount_staff: this.state.max_discount_staff,
            status: this.state.status,
            products: this.state.products,
        }

        this.setState({ isLoading: true, errors: {} });
        if (this.state.isEditing) {
            DiscountService.updateDiscount(this.state.discount_id, data).then((discountEditRes) => {
                if(discountEditRes.data.status == true)
                {
                    this.setState({
                        showSnackbar: true,
                        snackBarMessage: "Discount edited successfully.",
                        messageType: "success",
                        isLoading: false
                    });
                }
                else{
                    let errors = _.get(discountEditRes, "data.errors", {});
                    for (const key in errors) {
                        if (_.get(errors[key], "message", false) && _.get(errors[key], "message", false).length) {
                            errors[key] = _.get(errors[key], "message", false);
                        } else {
                            delete errors[key];
                        }
                    }
                    this.setState({ errors, isLoading: false })
                }
            })
        }
        else {
            try {
                let response = await DiscountService.discountAdd(data)
                let status = _.get(response, "data.status", false);
                if (status) {
                    this.setState({
                        showSnackbar: true,
                        snackBarMessage: "Discount added successfully",
                        messageType: "success",
                        isLoading: false
                    });
                    this.clearData();

                } else {
                    let errors = _.get(response, "data.errors", {});
                    for (const key in errors) {
                        if (_.get(errors[key], "message", false) && _.get(errors[key], "message", false).length) {
                            errors[key] = _.get(errors[key], "message", false);
                        } else {
                            delete errors[key];
                        }
                    }
                    this.setState({ errors, isLoading: false })
                }
            } catch (error) {
                AuthService.sendNotification("Something went wrong.", "danger");
            }
        }

    }

    clearData = () => {
        this.setState({
            code: "",
            details: "",
            type: "",
            validity: "",
            discount: "",
            min_amount: "",
            max_discount: "",
            status: "",
            products: "",
            errors: {},
        })
    }


    handleCloseSnackbar = () => {
        this.setState({ showSnackbar: false })
    }


    isValid = () => {
        if (this.state.name === "") {
            return false
        }
        return true;
    }

    handleValidityDate = (date) => {
        const validity = new Date(date.setHours(0, 0, 0, 0)).getTime();
        this.setState({ validity });
    }

    handleChangeProducts = (name) => {
        let value = Array.isArray(this.state.products) ? this.state.products : [];
        let valueIndex = value.findIndex(e => e === name);
        if (valueIndex === -1) {
            value.push(name)
        } else {
            value.splice(valueIndex, 1)
        }
        this.setState({ products: value });
    }

    getTextInput = (name, label) => {
        return <TextField
            label={label}
            variant="outlined"
            margin="normal"
            fullWidth
            name={name}
            helperText={this.state.errors[name] ? this.state.errors[name] : ""}
            error={this.state.errors[name] ? true : false}
            onChange={this.handleChange}
            value={this.state[name]}
        />
    }

    getSelectInput = (name, label, options) => {
        return <TextField
            label={label}
            variant="outlined"
            fullWidth
            select
            name={name}
            onChange={this.handleChange}
            value={this.state[name]}
            className="mt-2"
            helperText={this.state.errors[name] ? this.state.errors[name] : ""}
            error={this.state.errors[name] ? true : false}
        >
            {
                Array.isArray(options)
                && options.length
                && options.map((option, ind) => {
                    return <MenuItem key={ind} value={option.key}>{option.value}</MenuItem>;
                })
            }
        </TextField>;
    }

    render() {
        return (
            <div className="d-flex">
                <Navigation />

                <Snackbar
                    showSnackbar={this.state.showSnackbar}
                    messageType={this.state.messageType}
                    snackBarMessage={this.state.snackBarMessage}
                    onCloseSnackbar={this.handleCloseSnackbar}
                />

                <div className="container-fluid">
                    <Paper className="p-2 sq-shadow mb-3 d-flex">
                        <Typography><Link to={`${process.env.PUBLIC_URL}/`}>Home</Link></Typography>
                        <Typography className="mr-2 ml-2">/</Typography>
                        <Typography><Link to={`${process.env.PUBLIC_URL}/discounts`}>Discounts</Link></Typography>
                        <Typography className="mr-2 ml-2">/</Typography>
                        <Typography>
                            {this.state.isEditing ? "Edit Discounts" : "Add New Discounts"}
                        </Typography>
                    </Paper>

                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <Paper className="sq-shadow p-3">
                                {this.getTextInput("details", "Details")}
                                {this.getTextInput("code", "Code")}

                                {
                                    this.getSelectInput(
                                        "type",
                                        "Discount Type",
                                        [
                                            { key: "flat", "value": "Flat" },
                                            { key: "percentage", "value": "Percentage" }
                                        ])
                                }
                                
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    {/* {this.state.validity} */}
                                    <DatePicker
                                        label="Valid Till"
                                        inputVariant="outlined"
                                        animateYearScrolling
                                        name="validity"
                                        clearable
                                        fullWidth
                                        margin="normal"
                                        format="dd-MM-yyyy"
                                        value={this.state.validity ? this.state.validity : null}
                                        onChange={this.handleValidityDate}
                                        helperText={this.state.errors.validity ? this.state.errors.validity : ""}
                                        error={this.state.errors.validity ? true : false}
                                    />
                                </MuiPickersUtilsProvider>

                                <Grid container>
                                    <Grid item xs = {12}>
                                        <Typography className="mt-2 px-3 py-2 bg-dark rounded text-white"> Discount </Typography>
                                    </Grid>
                                    <Grid item xs={12} md={6} className="pr-0 pr-md-1">
                                        {this.getTextInput("discount", CUSTOMER_TYPE.CUSTOMER)}
                                    </Grid>
                                    <Grid item xs={12} md={6} className="pl-0 pl-md-1">
                                        {this.getTextInput("discount_staff", CUSTOMER_TYPE.STAFF)}
                                    </Grid>
                                </Grid>

                                <Grid container>
                                    <Grid item xs = {12}>
                                        <Typography className="mt-2 px-3 py-2 bg-dark rounded text-white"> Max Discount Amount </Typography>
                                    </Grid>
                                    <Grid item xs={12} md={6} className="pr-0 pr-md-1">
                                        {this.getTextInput("max_discount", CUSTOMER_TYPE.CUSTOMER)}
                                    </Grid>
                                    <Grid item xs={12} md={6} className="pl-0 pl-md-1">
                                        {this.getTextInput("max_discount_staff", CUSTOMER_TYPE.STAFF)}
                                    </Grid>
                                </Grid>

                                <Grid container>
                                    <Grid item xs = {12}>
                                        <Typography className="mt-2 px-3 py-2 bg-dark rounded text-white"> Min Amount </Typography>
                                    </Grid>
                                    <Grid item xs={12} md={6} className="pr-0 pr-md-1">
                                        {this.getTextInput("min_amount", CUSTOMER_TYPE.CUSTOMER)}
                                    </Grid>
                                    <Grid item xs={12} md={6} className="pl-0 pl-md-1">
                                        {this.getTextInput("min_amount_staff", CUSTOMER_TYPE.STAFF)}
                                    </Grid>
                                </Grid>

                                {
                                    this.getSelectInput(
                                        "status",
                                        "Status",
                                        [
                                            { key: "1", value: "Active" },
                                            { key: "0", value: "Deactive" }
                                        ])
                                }

                            </Paper>
                        </Grid>
                        <Grid item xs={6}>
                            <Paper className="sq-shadow p-3">
                                <FormControl
                                    component="fieldset"
                                    sx={{ m: 3 }}
                                    variant="standard"
                                >
                                    <FormLabel component="legend">Products</FormLabel>
                                    <FormGroup>
                                        {Object.keys(this.products).map((product, ind) => {
                                            return <FormControlLabel
                                                key={ind}
                                                control={
                                                    <Checkbox
                                                        checked={Array.isArray(this.state.products) && this.state.products.includes(product)}
                                                        onChange={e => { this.handleChangeProducts(product) }}
                                                        name={product} />
                                                }
                                                label={this.products[product].name}
                                            />
                                        })}
                                    </FormGroup>
                                </FormControl>
                            </Paper>
                        </Grid>
                        <Grid item xs={12}>
                            <Paper className="sq-shadow p-2 d-flex justify-content-end">
                                <Link to={`${process.env.PUBLIC_URL}/discounts`} className="d-inline-block mr-3">
                                    <Button variant="outlined" color="primary">Cancel</Button>
                                </Link>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={this.saveDiscount}
                                >
                                    {this.state.isLoading ? "Saving . . ." : "Save"}
                                </Button>
                            </Paper>
                        </Grid>

                    </Grid>
                </div>

            </div>
        )
    }
}

export default withRouter(DiscountCreate);