import React from "react";
import {Card, TextField, Grid, Button, InputAdornment, IconButton, FormHelperText, Typography} from "@material-ui/core";
import {withRouter, Link} from "react-router-dom";
import AuthService from "../../services/auth";
import { Visibility , VisibilityOff, Email } from "@material-ui/icons";

class Login extends React.Component{

  state = {
    email : "",
    password : "",
    showMailError : false,
    showPasswordError : false,
    isLoading : false,
    showPassword : false,
    showError : false
  }

  handleSubmit = async (e) => {
    e.preventDefault();
    this.setState({ isLoading : true });
    try{
      let data = await AuthService.login(this.state.email, this.state.password);
      if(data)
        this.props.history.push("/");
    }
    catch(e){
      this.setState({ isLoading : false, showError : true });
    }
  }

  isValidMail = () => {
    const value = this.state.email;
    const atpos = value.indexOf("@");
    const dotpos = value.lastIndexOf(".");
    if(atpos < 1 || dotpos < atpos + 2 || dotpos + 2 >= value.length || value === "")
      return false;
    else
      return true;
  }
  
  onBlurMail = () => {
    this.setState({showMailError : !this.isValidMail() });
  }

  onBlurPassword = () => {
    this.setState({
      showPasswordError : this.state.password.length < 6 ? true : false
    });
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name] : e.target.value,
      showMailError : false,
      showPasswordError : false,
      showError : false
    })
  }

  async componentDidMount(){
    try{
      const isLoggedIn = await AuthService.isLoggedIn();
      if(isLoggedIn)
        this.props.history.push("/");
    }
    catch(e){}
  }

  handleClickShowPassword = () => {
    this.setState({ showPassword : !this.state.showPassword });
  }

  render(){
    return(
      <Grid container className="d-flex align-items-center justify-content-center h-100">
        <Grid item md={3} sm={6} xs={11}>
          <form onSubmit={this.handleSubmit}>
            <Card className="p-4">
              <div>
                <TextField
                  variant = "outlined"
                  margin = "normal"
                  label = "Email"
                  fullWidth
                  name = "email"
                  onChange = {this.handleChange}
                  onBlur = {this.onBlurMail}
                  error = {this.state.showMailError}
                  helperText = {this.state.showMailError ? "Please enter valid email" : ""}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton edge="end" tabIndex="-1">
                          <Email />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
              <div>
                <TextField
                  variant = "outlined"
                  margin = "normal"
                  label = "Password"
                  type = {this.state.showPassword ? "text" : "password"}
                  fullWidth
                  name = "password"
                  onChange = {this.handleChange}
                  onBlur = {this.onBlurPassword}
                  error = {this.state.showPasswordError}
                  helperText = {this.state.showPasswordError ? "Password must be atleast 6 characters" : ""}

                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          edge="end"
                          onClick={this.handleClickShowPassword}
                          tabIndex = "-1"
                        >
                          {this.state.showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
              <div className="text-right">
                <Link to="/forgot-password">
                  <Typography component="span" variant="body2"> Forgot Password </Typography>
                </Link>
              </div>
              <Button
                variant = "contained"
                color="primary"
                fullWidth
                type="submit"
                size="large"
                className="submit-button mt-3"
                disabled = {!this.isValidMail() || this.state.password.length < 6 || this.state.isLoading}
              >
                Login
              </Button>
              {
                this.state.showError &&
                <FormHelperText error>Invalid username or password</FormHelperText>
              }
            </Card>
          </form>
        </Grid>
      </Grid>
    )
  }
}

export default withRouter(Login);