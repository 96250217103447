import { Grid, Paper, Tab, Tabs } from "@material-ui/core";
import React from "react";
import TravelPricePlan from "./Travel/TravelPricePlan";

class ModifyPremiumsTravel extends React.Component {

    travel_plans = [
        { plan_type: "Standard" },
        { plan_type: "Lite" },
        { plan_type: "Senior" },
        { plan_type: "Covid Shield" },
        { plan_type: "Family" }
    ]

    state = {
        plan_type: 0
    }

    componentDidMount() {
        let plan_type = "Standard";
        this.loadDetails(plan_type)
    }

    loadDetails(plan_type) {
        plan_type = this.travel_plans.findIndex(plan => plan.plan_type == plan_type);
        this.setState({ plan_type })
    }

    changePlanType = (e, value) => {
        this.setState({ plan_type: value })
    }

    render() {
        return (
            <React.Fragment>
                <Grid item xs={12}>
                    <Paper className="sq-shadow mb-4">
                        <Tabs variant="fullWidth" value={this.state.plan_type} onChange={this.changePlanType}>
                            {
                                this.travel_plans.map((plan, ind) => (<Tab label={plan.plan_type} key={ind} />))
                            }
                        </Tabs>
                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    {this.state.plan_type === 0 && <TravelPricePlan plan_type="Standard" />}
                    {this.state.plan_type === 1 && <TravelPricePlan plan_type="Lite" />}
                    {this.state.plan_type === 2 && <TravelPricePlan plan_type="Senior" />}
                    {this.state.plan_type === 3 && <TravelPricePlan plan_type="Covid Shield" />}
                    {this.state.plan_type === 4 && <TravelPricePlan plan_type="Family" />}
                </Grid>
            </React.Fragment>
        )
    }
}
export default ModifyPremiumsTravel;