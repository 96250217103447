import React from "react";
import Navigation from "../common/Navigation";
import { Paper, Tabs, Tab, Typography } from "@material-ui/core";
import AllTable from "./AllTable";
import { Link, withRouter } from "react-router-dom";

class ReferPolicies extends React.Component {

    state = {
        tabIndex: 0
    }

    handleChange = (e, value) => {
        this.setState({ tabIndex: value })
    }

    componentDidMount() {

    }
    render() {
        return (
            <div className="d-flex">
                <Navigation />
                <div className="container-fluid">
                    <Paper className="p-2 sq-shadow mb-3 d-flex">
                        <Typography><Link to={`/home`}>Home</Link></Typography>
                        <Typography className="mr-2 ml-2">/</Typography>
                        <Typography>Policies</Typography>
                    </Paper>
                    {this.state.tabIndex === 0 && <AllTable />}
                </div>
            </div>
        )
    }
}
export default withRouter(ReferPolicies);