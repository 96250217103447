import React from "react";
import DhofarLogo from "../../../static/images/dhofar-logo.jpg";
import MedNet from "../../../static/images/mednet.jpg";
import moment from "moment";

const IndividualCard = ({ data }) => {

  const startDate = data.startDate;
  const network = data.network;

  const getStartDate = () => {
    return moment(startDate).format("DD/MM/YYYY")
  }
  
  const getEndDate = () => {
    let numYears = 1;
    return moment(startDate).subtract(1, "day").add(numYears, "year").format("DD/MM/YYYY");
  }
  
  const getYob = () => {
    const dob = data.personalInformation.dob
    let date = moment(dob, 'DD-MM-YYYY');
    if(!date.isValid()){
      date = moment(dob, 'DD-MMM-YYYY');
    }
  
    return date.get("year");
  }

  const getNetworkText = () => {
    let text = "";
    if(network === "Silver"){
      text = "Premium"
    }
    else if(network === "Gold"){
      text = "(30% Copay @ MPH on all services)"
    }
  
    return text;
  }

  return(
    <div className="vc-container position-relative sq-shadow">
      <div className="d-flex align-items-center justify-content-between">
        <img src={DhofarLogo} alt="Dhofar" className="vc-company-logo" />
        <img src={MedNet} alt="MedNet" className="vc-company-logo" />
      </div>
      <div className="d-flex align-items-center mt-1 vc-detail-container">
        <span className="vc-title vc-description">Card No:</span>
        <span className="vc-description"> &nbsp; {data && data.policy_num} </span>
      </div>
      <div className="d-flex align-items-center vc-detail-container">
        <span className="vc-title vc-description">Validity:</span>
        <span className="vc-description"> &nbsp; {getStartDate()}</span>
        <span className="vc-description">&nbsp; To &nbsp; {getEndDate()} </span>
      </div>
      <div className="d-flex align-items-center vc-detail-container">
        <span className="vc-title vc-description">Civil No:</span>
        <span className="vc-description"> &nbsp; {data && data.civil_id} </span>
      </div>
      <div className="w-100 virtual-card-border"></div>
      <div className="d-flex align-items-center vc-detail-container">
        <span className="vc-title vc-description">Name: </span>
        <span className="vc-description"> &nbsp; {data && data.personalInformation.fullName} </span>
      </div>
      <div className="d-flex align-items-center vc-detail-container">
        <span className="vc-title vc-description">Holder: </span>
        <span className="vc-description"> &nbsp; {data && data.personalInformation.fullName} </span>
      </div>
      <div className="d-flex align-items-center vc-detail-container">
        <span className="vc-title vc-description">Policy Type: </span>
        <span className="vc-description vc-bottom-descreption"> &nbsp; Individual Policy </span>
        <span className="vc-description ml-4"> Y.O.B. &nbsp;</span><span className="vc-description"> {getYob()} </span>
      </div>
      <div className="w-100 virtual-card-border"></div>
      <div className="d-flex align-items-center vc-detail-container">
        <span className="vc-title vc-description">Network: </span>
        <span className="vc-description"> &nbsp; {network} </span>
        <span className="vc-description"> &nbsp; {getNetworkText()} </span>
      </div>
      <div className="d-flex align-items-center vc-detail-container vc-description">
        <span>Ded: </span> &nbsp; <span > {data && data.op} RO,</span>
        &nbsp;&nbsp;
        <span>Co-pay&nbsp;: </span> &nbsp; <span > {data && data.op} RO on Lab & Rad, </span>
        &nbsp;&nbsp;
        <span>PH: </span> &nbsp; <span > {data && data.co_pay === "Nil" ? "0%" : data.co_pay} </span>
      </div>
      {
        (data && data.plan === "Gold") &&
        <div className="d-flex align-items-center vc-description vc-detail-container">
          <span className="">Dental & Optical: </span> &nbsp;<span > Y-20%, ALT : Y-10% </span>
        </div>
      }
      <div className="d-flex align-items-center vc-description vc-detail-container">
        Vitamins and Minerals Covered
      </div>
      <div className="w-100 virtual-card-border"></div>
      <div className="vc-bottom-descreption vc-detail-container"> Pre-existing and Chronic Condition listed in MEMBER BENEFIT DOCUMENT (available online) are not covered</div>
      <div className="vc-bottom-descreption vc-detail-container"> Network Access in OMAN Only </div>
      <div className="vc-toll-free-number">MedNet Call Centre 24/7: 8008 5005</div>
    </div>
  )
}

export default IndividualCard;