import axios from "axios";
import config from "./config";
const baseUrl = config.baseUrl;

const plans = {};

/**
 * 
 * @param {"life"|"health"|"travel"|"home"} insurance_type 
 * @returns 
 */
plans.getPlanPrices = async (insurance_type) => {
    const url = `${baseUrl}/plans/${insurance_type}`;
    const res = await axios({ url });
    return res;
}

/**
 * 
 * @param {"life"|"health"|"travel"|"home"} insurance_type 
 * @param {"Personal Accident"|"Term Plan"} plan_type 
 * @param {*} data 
 */
plans.updatePlanPremium = async (insurance_type, plan_type, data) => {
    const url = `${baseUrl}/plans/${insurance_type}`;
    const dataObj = {
        ...data,
        plan_type : plan_type
    }
    const res = await axios({
        url,
        method: "POST",
        data: dataObj
    });
    return res;
}

export default plans;