import React from "react";
import Navigation from "../common/Navigation";
import Snackbar from "../common/Snackbar";
import PolicyService from "../../services/policy";
import { Paper, Typography, Dialog, DialogTitle, DialogActions, DialogContent, Button, List, ListItem, TextField } from "@material-ui/core";
import { Link, withRouter } from "react-router-dom";
import AffiliateService from "../../services/affiliates";
import "./TransferPolicy.css";

class TransferPolicy extends React.Component{

  state = {
    data : "",
    affiliate_id : "",
    affiliate : undefined,
    selectedAffiliateId : "",
    search : "",
    affiliates : [],
    showAffiliateDialog : false,
    affiliateObj : {},
    isSaving : false,
    showSnackbar : false,
    messageType : "",
    snackBarMessage : ""
  }

  componentDidMount(){
    const {id} = this.props.match.params;
    this.setState({ id });
    this.getPolicy();
    this.getAffiliates();
  }

  getPolicy = async () => {
    const {id} = this.props.match.params;
    const data = await PolicyService.getPolicyById(id);
    const policy = data.data;
    const affiliate_id = policy.affiliate_id;
    this.setState({ affiliate_id, selectedAffiliateId : affiliate_id, affiliate : policy.affiliate_name });
  }

  getAffiliates = async () => {
    const search = this.state.search.trim() === "" ? undefined : this.state.search.trim();
    const data = await AffiliateService.getAffiliates(0, 1000, search);
    this.setState({ affiliates : data.data.data });
  }

  closeAffiliateDialog = () => {
    this.setState({ showAffiliateDialog : false, affiliateObj : {}, search : "" }, this.getAffiliates);
  }

  selectAffiliate = (affiliate) => {
    this.setState({ affiliateObj : affiliate, selectedAffiliateId : affiliate.affiliateId });
  }

  handleSearch = (e) => {
    this.setState({
      search : e.target.value
    },this.getAffiliates);
  }

  handleSave = () => {
    this.setState({ isSaving : true });
    const affiliate = this.state.affiliateObj;
    const data = {
      affiliate_email : affiliate.email,
      affiliate_id : affiliate.affiliateId,
      affiliate_name : affiliate.name,
      affiliate_parent_id : affiliate.parent_id,
      affiliate_parents : affiliate.parents
    }
    PolicyService.update(data, this.state.id).then((res) => {
      this.setState({ isSaving : false, showSnackbar : true, snackBarMessage : "Policy transferred successfully", messageType : "success" });
      this.closeAffiliateDialog();
      this.getPolicy();
    }).catch((e) => {
      this.setState({ isSaving : false, showSnackbar : true, snackBarMessage : "Policy not transferred", messageType : "danger" });
    })
  }

  handleCloseSnackbar = () => {
    this.setState({ showSnackbar : false });
  }

  render(){
    return(
      <div className="d-flex">
        <Navigation />
        <Snackbar
          showSnackbar = { this.state.showSnackbar }
          messageType = {this.state.messageType}
          snackBarMessage = {this.state.snackBarMessage}
          onCloseSnackbar = {this.handleCloseSnackbar}
        />
        <div className = "container-fluid">
          <Paper className="p-2 sq-shadow mb-3 d-flex">
            <Typography><Link to={`/home`}>Home</Link></Typography>
            <Typography className="mr-2 ml-2">/</Typography>
            <Typography><Link to={`/policies`}>Policies</Link></Typography>
            <Typography className="mr-2 ml-2">/</Typography>
            <Typography>Transfer Policy</Typography>
          </Paper>

          <div className = "row">
            <div className = "col-md-4 col-12">
              <Paper className="p-2 sq-shadow">
                <Typography variant = "h6" className = "border-bottom pb-1 mb-2">Current Affiliate</Typography>
                <Typography>{this.state.affiliate ? this.state.affiliate : "None"}</Typography>
                <div className = "d-flex justify-content-end">
                  <Button variant = "contained" color = "primary" onClick = {() => this.setState({ showAffiliateDialog : true })}> Change </Button>
                </div>
              </Paper>
            </div>
          </div>
        </div>

        <Dialog
          open = {this.state.showAffiliateDialog}
          fullWidth
          maxWidth = "xs"
        >
          <DialogTitle> Choose Affiliates </DialogTitle>
          <DialogContent>
            <TextField
              variant = "outlined"
              fullWidth
              label = "Search"
              placeholder = "Search by name or email"
              onChange = {this.handleSearch}
              value = {this.state.search}
            />
            <List>
              {
                this.state.affiliates.map((affiliate) => 
                  <ListItem
                    className = "affiliate-list-item"
                    button
                    selected = {this.state.selectedAffiliateId === affiliate.affiliateId}
                    key = {affiliate._id}
                    onClick = {() => this.selectAffiliate(affiliate)}
                  >
                    {affiliate.name}
                  </ListItem>
                )
              }
            </List>
          </DialogContent>

          <DialogActions>
            <Button
              variant = "outlined"
              color = "primary"
              onClick = {this.closeAffiliateDialog}
            >
              Cancel
            </Button>
            <Button
              variant = "contained"
              color = "primary"
              disabled = {this.state.affiliate_id === this.state.selectedAffiliateId || this.state.isSaving}
              onClick = {this.handleSave}
            >
              {this.state.isSaving ? "Saving . . ." : "Save"}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}

export default withRouter(TransferPolicy);