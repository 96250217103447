import axios from "axios";
let payments = {};

payments.refundPayment = async (url, secret_key, id) => {
  const paymentUrl = `${url}/payments/${id}/refunds`;
  const res = await axios({
    url : paymentUrl,
    method : "POST",
    headers : {
      "Authorization" : secret_key,
      "Content-Type" : "application/json"
    }
  });
  return res;
}

export default payments;