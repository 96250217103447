import React from "react";
import { Snackbar, SnackbarContent } from "@material-ui/core";

export default (props) => (
  <Snackbar
    open = {props.showSnackbar}
    anchorOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    autoHideDuration={5000}
    disableWindowBlurListener
    onClose={props.onCloseSnackbar}
    className = "snackbar"
  >
    <SnackbarContent className={`bg-${props.messageType}`} message={props.snackBarMessage} />
  </Snackbar>
)