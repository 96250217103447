import React from "react";
import Navigation from "../common/Navigation";
import { Grid, Button, Table, TableHead, TableBody, TableRow, TableCell, Paper, Typography, TablePagination, TextField, MenuItem } from "@material-ui/core";
import Eye from "mdi-react/EyeIcon";
import { Link, withRouter } from "react-router-dom";
import { saveAs } from "file-saver";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import TravelService from "../../services/travel-insurance";
import moment from "moment";
import utils from "../../services/utils";
import AffiliateSelect from "../common/AffiliateSelect";
import _ from "lodash";
import helpers from "../../helpers";
import { REPORT_STATUS_OPTIONS, REPORT_SOB_OPTIONS } from "../../constants";
import BranchList from "../../services/branches.json";
import RegionList from "../../services/region.json";

class InboundTravelReport extends React.Component {
  state = {
    startDate: null,
    endDate: null,
    showTable: false,
    data: [],
    page: 0,
    rowsPerPage: 10,
    totalRecords: 0,
    headings: [],
    center: "",
    isLoading: false,
    isDownloading: false,
    status: "All",
    sob : "All",
    region : "All",
    branch : "All"
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    });
  }

  componentDidMount() {
    const center = this.props.match.params.center;

    let date = new Date().setHours(23, 59, 59, 999);
    let endDate = new Date(date).getTime();
    this.setState({
      startDate: new Date(new Date().setHours(0, 0, 0, 0)).getTime(),
      endDate,
      center
    });
  }

  handleChangePage = (e, page) => {
    this.setState({ page }, this.handleSearch);
  }

  handleChangeRowsPerPage = (e) => {
    this.setState({ rowsPerPage: e.target.value }, this.handleSearch);
  }

  formatPolicies = (policies) => {
    return Array.isArray(policies) && policies.length ?
      policies.map((policy, ind) => {
        if (policy.travellers_type === "individual") {
          policy.travellers = [{
            name: _.get(policy, "personalInformation.fullName", ""),
            relation: "Self",
            passport_no: _.get(policy, "personalInformation.passport", "")
          }];
        }
        return policy
      }) : [];
  }

  handleSearch = async () => {
    this.setState({ isLoading: true });
    const status = this.state.status === "All" ? undefined : this.state.status;
    const sob = this.state.sob === "All" ? undefined : this.state.sob;
    const region = this.state.region === "All" ? undefined : this.state.region;
    const branch = this.state.branch === "All" ? undefined : this.state.branch;

    const data = await TravelService.getPolicies({
      startDate : this.state.startDate,
      endDate : this.state.endDate,
      status,
      page : this.state.page,
      limit : this.state.rowsPerPage,
      travel_type : "inbound",
      sob : sob,
      region : region,
      branch : branch
    });
    
    let _data = data.data.data

    if (data.status === 200) {
      this.setState({ showTable: true, data: this.formatPolicies(_data), totalRecords: data.data.count })
    }


    let headings = [
      "Sr No",
      "Policy Number",
      "ISA Policy Number",
      "From Countries",
      "Travel Start Date",
      "Travel End Date",
      "Travel Coverage Type",
      "Product Type",
      "Travellers",
      "Premium",
      "Policy Holder Name",
      "Email ID",
      "Contact No",
      "Net Premium",
      "Discount Code",
      "Discount",
      "Policy Fee",
      "Emergency Fund Fee",
      "Goverment Fee",
      "Gross Premium",
      "VAT",
      "Loading",
      "Plan Name",
      "Payment Status",
      "Policy Status"
    ]
    if (["Active"].includes(this.state.status)) {
      headings.push("Payment Mode", "Payment Reference Number");
    }
    headings.push("Company", "Partner Branch Name", "Channel Name", "Sub Channel Name", "Employee ID", "Employee Name", "Employee EmailID", "Employee Designation", "Location/Branch-Id", "Location/Branch-Name", "Zone/Region", "Reporting Manager", "Reporting Mgr email id", "Employee Contact No.", "Comment", "View");
    this.setState({ isLoading: false, headings });
  }

  getFormattedDate = (date, responseFormat = "DD-MMM-YYYY") => {
    try {
      let newDate = moment(date, "DD-MM-YYYY").format(responseFormat);
      return (newDate === "Invalid date") ? "-" : newDate;
    } catch (error) {
      return "-";
    }
  }

  getFormattedDateTime = (date, checkFormat = true, responseFormat = 'MMM DD, YYYY LTS') => {
    try {
      let newDate;
      if (checkFormat) {
        newDate = moment(date, "DD-MM-YYYY").format(responseFormat)
      } else {
        newDate = moment(date).format(responseFormat)
      }
      return (newDate === "Invalid date") ? "-" : newDate;
    } catch (error) {
      return "-";
    }
  }

  downloadData = async () => {
    this.setState({ isDownloading: true });
    const status = this.state.status === "All" ? undefined : this.state.status;
    const sob = this.state.sob === "All" ? undefined : this.state.sob;
    const region = this.state.region === "All" ? undefined : this.state.region;
    const branch = this.state.branch === "All" ? undefined : this.state.branch;

    const res = await TravelService.getPolicies({
      startDate : this.state.startDate,
      endDate : this.state.endDate,
      status,
      travel_type : "inbound",
      sob : sob,
      region : region,
      branch : branch,
    });
    const data = this.formatPolicies(res.data.data);
    let csvArr = [];
    let newHeadings = [...this.state.headings];
    newHeadings.splice(-1, 1);
    csvArr.push(newHeadings.join(","));
    data.forEach((policy, ind) => {
      const base_price = policy.amount ? helpers.getBasePrice(policy.amount) : 0;
      const discount_code = policy.amount ? helpers.getDiscountCode(policy.amount) : 0;
      const discount = policy.amount ? helpers.getDiscount(policy.amount) : "-";
      const policy_fee = policy.amount ? policy.amount.policy_fee : 0;
      const emergency_fund_fee = policy.amount ? policy.amount.emergency_fund_fee : 0;
      const government_fee = policy.amount ? policy.amount.government_fee : 0;
      const amount = policy.amount ? policy.amount.total : 0;
      const paymentStatus = policy.payments && policy.payments.isPaid ? "Paid" : "Unpaid";
      const bmiValue = _.get(policy.amount, "bmi_value", 0)
      const vatValue = _.get(policy.amount, "vat", 0)
      const coverageType = policy.inbound_coverage_name.replace(/[,&]/g, " ")
      let travellers = "";

      if (Array.isArray(policy.travellers)) {
        policy.travellers.forEach((traveller) => {
          travellers += `First Name : ${traveller.first_name},Last Name : ${traveller.last_name},Gender : ${traveller.inbound_gender_detail},Marital Status : ${traveller.inbound_marital_status_detail},Relation : ${traveller.inbound_relation_detail},Mobile No : ${traveller.mobile_no},Passport No : ${traveller.passport_no}`
        })
      }

      const isaPolicyNumber = policy.inbound_issue_policy_response ? policy.inbound_issue_policy_response.response_data.PolicyNumber : "-"

      let arr = [
        ind + 1,
        policy._id,
        isaPolicyNumber,
        policy.inbound_country_name,
        moment(policy.inbound_travel_start_date).format("DD-MM-YYYY"),
        moment(policy.inbound_travel_end_date).format("DD-MM-YYYY"),
        // policy.inbound_coverage_name,
        coverageType,
        policy.inbound_product_type_name,
        `"${travellers}"`,
        `"${utils.getFormattedAmount(policy.amount ? policy.amount.total : "0")}"`,
        policy.personalInformation.fullName,
        policy.personalInformation.email,
        policy.personalInformation.mobileNumber,
        `"${utils.getFormattedAmount(base_price)}"`,
        discount_code,
        `"${utils.getFormattedAmount(discount)}"`,
        `"${utils.getFormattedAmount(policy_fee)}"`,
        `"${utils.getFormattedAmount(emergency_fund_fee)}"`,
        `"${utils.getFormattedAmount(government_fee)}"`,
        `"${utils.getFormattedAmount(amount)}"`,
        `"${utils.getFormattedAmount(vatValue)}"`,
        `"${utils.getFormattedAmount(bmiValue)}"`,
        (policy.plan).replace("_", " "),
        paymentStatus,
        policy.status
      ];

      if (["Active"].includes(this.state.status)) {
        const paymentMode = policy.payments.mode === "Link" ? "Card" : policy.payments.mode;
        arr.push(
          paymentMode,
          policy.payments.mode !== "Cash" ? policy.payments.payment_id : "-"
        )
      }
      arr.push(policy.company_affiliate_name ? policy.company_affiliate_name : "-")
      arr.push(policy.affiliate_partner_name ? policy.affiliate_partner_name : "-")
      arr.push(policy.channel_name ? policy.channel_name : "-")
      arr.push(policy.sub_channel_name ? policy.sub_channel_name : "-")
      arr.push(policy.affiliate_employee_id ? policy.affiliate_employee_id : "-")
      arr.push(policy.affiliate_name ? policy.affiliate_name : "AXSS")
      arr.push(policy.affiliate_email ? policy.affiliate_email : "-")
      arr.push(policy.affiliate_employee_designation ? policy.affiliate_employee_designation : "-")
      arr.push(policy.branch_id ? policy.branch_id : "-")
      arr.push(policy.branch_name ? policy.branch_name : "-")
      arr.push(policy.region_name ? policy.region_name : "-")
      arr.push(policy.branch_manager_name ? policy.branch_manager_name : "-")
      arr.push(policy.branch_manager_email ? policy.branch_manager_email : "-")
      arr.push(policy.affiliate_phone ? policy.affiliate_phone : "-")
      arr.push(policy.comment ? `"${policy.comment}"` : "-")
      csvArr.push(arr.join(","));
    });

    const csvFile = new Blob([csvArr.join("\n")], { type: "text/csv" });
    saveAs(csvFile, "inbound-travel-insurance.csv");
    this.setState({ isDownloading: false, showProcessPopup: false });
  }

  downloadRiReportData = async () => {
    this.setState({ isDownloadingRI: true });

    const riHeadings = [
      "Policy Approval Date",
      "Policy Cancellation Date",
      "ISA Policy Number",
      "AXSS Insurance Policy Number",
      "Type",
      "Plan",
      "Product",
      "Name",
      "Age",
      "Destination",
      "Inception",
      "Expiry",
      "No. of Days",
      "Total Premium (USD)",
      "Total Basic Premium (OMR)",
      "RI Net Premium (OMR) 50%",
      "AXSS Loading (OMR) 50%",
      "Fees & Charges (OMR)",
      "VAT (OMR)",
      "Total Gross Premium (OMR)"
    ]
    const status = this.state.status === "All" ? undefined : this.state.status;
    const res = await TravelService.getPolicies({
      startDate : this.state.startDate,
      endDate : this.state.endDate,
      status,
      travel_type : "inbound"
    });
    let data = res.data.data

    let csvArr = [];

    csvArr.push(riHeadings.join(","));
    data.forEach((policy) => {
      const base_price = policy.amount ? parseFloat(Number(helpers.getBasePrice(policy.amount)).toFixed(3)) : 0;
      const government_fee = policy.amount ? policy.amount.government_fee : 0;
      const vat = policy.amount ? policy.amount.vat : 0;
      
      const total_amount = base_price + government_fee + vat;

      const total_amount_in_usd = Number(total_amount / 0.386).toFixed(3);

      const ri_premium = Number(base_price / 2).toFixed(3)
      
      const isaPolicyNumber = policy.inbound_issue_policy_response ? policy.inbound_issue_policy_response.response_data.PolicyNumber : "-"
      
      const traveller = policy.travellers[0];
      const traveller_name = `${traveller.first_name} ${traveller.last_name}`;

      const travel_start_date = policy.inbound_travel_start_date;
      const travel_end_date = policy.inbound_travel_end_date;

      
      const travel_days = policy.inbound_generate_quotation_request.CumulativeDays

      let arr = [
        moment(policy.startDate).format("DD-MM-YYYY"),
        "",
        isaPolicyNumber,
        policy._id,
        "Policy",
        "Oman Inbound Product",
        policy.inbound_coverage_name,
        traveller_name,
        traveller.age,
        "Oman",
        moment(travel_start_date).format("DD-MM-YYYY"),
        moment(travel_end_date).format("DD-MM-YYYY"),
        travel_days,
        total_amount_in_usd,
        Number(base_price).toFixed(3),
        Number(ri_premium).toFixed(3),
        Number(ri_premium).toFixed(3),
        Number(government_fee).toFixed(3),
        Number(vat).toFixed(3),
        Number(total_amount).toFixed(3)
      ];

      
      csvArr.push(arr.join(","));
    });

    const csvFile = new Blob([csvArr.join("\n")], { type: "text/csv" });
    saveAs(csvFile, "RI-inbound-travel.csv");
    this.setState({ isDownloadingRI: false, showProcessPopup: false });
  }

  handleStartDateChange = (date) => {
    const startDate = new Date(date.setHours(0, 0, 0, 0)).getTime();
    this.setState({ startDate });
  }

  handleEndDateChange = (date) => {
    const endDate = new Date(date.setHours(23, 59, 59, 999)).getTime();
    this.setState({ endDate });
  }

  render() {
    return (
      <div className="d-flex">
        <Navigation />
        <div className="container-fluid">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Paper className="p-2 sq-shadow d-flex">
                <Typography><Link to={`/home`}>Home</Link></Typography>
                <Typography className="mr-2 ml-2">/</Typography>
                <Typography>Inbound Travel Report</Typography>
              </Paper>
            </Grid>

            <Grid item md={3} xs={12}>
              <AffiliateSelect onChange={() => { }} />
            </Grid>
            <Grid item md={3} xs={12}>
              <TextField
                variant="outlined"
                select
                label="Source of Business"
                fullWidth
                name="sob"
                onChange={this.handleChange}
                value={this.state.sob}
                SelectProps={{
                  MenuProps: {
                    anchorOrigin: {
                      vertical: "top",
                      horizontal: "left"
                    },
                    getContentAnchorEl: null
                  },
                }}
              >
                {
                  Object.values(REPORT_SOB_OPTIONS).map((option, index) => <MenuItem key={index} value={option}>{option}</MenuItem>)
                }
              </TextField>
            </Grid>
            <Grid item md={3} xs={12}>
              <TextField
                variant="outlined"
                select
                label="Region"
                fullWidth
                name="region"
                onChange={this.handleChange}
                value={this.state.region}
                SelectProps={{
                  MenuProps: {
                    anchorOrigin: {
                      vertical: "top",
                      horizontal: "left"
                    },
                    getContentAnchorEl: null
                  },
                }}
              >
                <MenuItem value="All">All</MenuItem>
                {
                  RegionList.map((region) => 
                    <MenuItem key={region.id} value={region.id}>{region.name}</MenuItem>
                  )
                }
              </TextField>
            </Grid>
            <Grid item md={3} xs={12}>
              <TextField
                variant="outlined"
                select
                label="Branch"
                fullWidth
                name="branch"
                onChange={this.handleChange}
                value={this.state.branch}
                SelectProps={{
                  MenuProps: {
                    anchorOrigin: {
                      vertical: "top",
                      horizontal: "left"
                    },
                    getContentAnchorEl: null
                  },
                }}
              >
                <MenuItem value="All">All</MenuItem>
                {
                  BranchList.map((branch) => 
                    <MenuItem key={branch.id} value={branch.id}>{branch.name}</MenuItem>
                  )
                }
              </TextField>
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={3}>
              <TextField
                variant="outlined"
                select
                label="Status"
                fullWidth
                name="status"
                onChange={this.handleChange}
                value={this.state.status}
                SelectProps={{
                  MenuProps: {
                    anchorOrigin: {
                      vertical: "top",
                      horizontal: "left"
                    },
                    getContentAnchorEl: null
                  },
                }}
              >
                {
                  Object.values(REPORT_STATUS_OPTIONS).map((option, index) => <MenuItem key={index} value={option}>{option}</MenuItem>)
                }
              </TextField>
            </Grid>

            <Grid item xs={3}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                  label="Start Date"
                  inputVariant="outlined"
                  animateYearScrolling
                  name="startDate"
                  clearable
                  fullWidth
                  value={this.state.startDate}
                  onChange={this.handleStartDateChange}
                  format="dd-MM-yyyy"
                />
              </MuiPickersUtilsProvider>
            </Grid>

            <Grid item xs={3}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                  label="End Date"
                  inputVariant="outlined"
                  animateYearScrolling
                  name="endDate"
                  clearable
                  fullWidth
                  value={this.state.endDate}
                  onChange={this.handleEndDateChange}
                  format="dd-MM-yyyy"
                />
              </MuiPickersUtilsProvider>
            </Grid>

            <Grid item xs={3} className="d-flex align-items-center">
              <Button variant="contained" color="primary" disabled={this.state.isLoading} onClick={this.handleSearch}>{this.state.isLoading ? "Searching . . ." : "Search"}</Button>
            </Grid>
          </Grid>

          {
            (this.state.showTable && this.state.data.length > 0) &&
            <Grid container spacing={2} className="d-flex">
              <Grid item xs={12}>
                <Paper className="sq-shadow overflow-auto">
                  <div className="dash-table-container">
                    <Table>
                      <TableHead>
                        <TableRow>
                          {
                            this.state.headings.map((heading, ind) =>
                              <TableCell key={ind}>{heading}</TableCell>
                            )
                          }
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {
                          this.state.data.map((data, ind) => {
                            return (
                              <TableRow key={ind}>
                                <TableCell>{ind + 1}</TableCell>
                                <TableCell>{data._id}</TableCell>
                                <TableCell> {data.inbound_issue_policy_response ? data.inbound_issue_policy_response.response_data.PolicyNumber : "-"} </TableCell>
                                <TableCell className="white-space-pre">{data.inbound_country_name}</TableCell>
                                <TableCell className="white-space-pre"> {moment(data.inbound_travel_start_date).format("DD-MM-YYYY")} </TableCell>
                                <TableCell className="white-space-pre"> {moment(data.inbound_travel_end_date).format("DD-MM-YYYY")} </TableCell>
                                <TableCell> {data.inbound_coverage_name} </TableCell>
                                <TableCell className="text-capitalize"> {data.inbound_product_type_name} </TableCell>
                                <TableCell>
                                  {
                                    Array.isArray(data.travellers) && data.travellers.map((traveller, bind) =>
                                      <div key={bind} className={`${bind === data.travellers.length - 1 ? "mb-0" : "mb-2"}`}>
                                        <div className="white-space-pre" > Traveler No : {bind + 1} </div>
                                        <div className="white-space-pre" > First Name : {traveller.first_name} </div>
                                        <div className="white-space-pre" > Last Name : {traveller.last_name} </div>
                                        <div className="white-space-pre" > Gender : {traveller.inbound_gender_detail} </div>
                                        <div className="white-space-pre" > Marital Status : {traveller.inbound_marital_status_detail} </div>
                                        <div className="white-space-pre" > Relation : {traveller.inbound_relation_detail} </div>
                                        <div className="white-space-pre" > Mobile No : {traveller.mobile_no} </div>
                                        <div className="white-space-pre" > Passport No : {traveller.passport_no} </div>
                                      </div>
                                    )
                                  }
                                </TableCell>
                                <TableCell> {data.amount && utils.getFormattedAmount(data.amount.total)} OMR</TableCell>
                                <TableCell> {data.personalInformation.fullName} </TableCell>
                                <TableCell>{data.personalInformation.email}</TableCell>
                                <TableCell>{data.personalInformation.mobileNumber}</TableCell>
                                <TableCell>{data.amount ? utils.getFormattedAmount(helpers.getBasePrice(data.amount)) : 0} OMR</TableCell>
                                <TableCell>{data.amount ? helpers.getDiscountCode(data.amount) : "-"}</TableCell>
                                <TableCell>{data.amount ? utils.getFormattedAmount(helpers.getDiscount(data.amount)) : 0} OMR</TableCell>
                                <TableCell>{data.amount ? utils.getFormattedAmount(data.amount.policy_fee) : 0} OMR</TableCell>
                                <TableCell>{data.amount ? utils.getFormattedAmount(data.amount.emergency_fund_fee) : 0} OMR</TableCell>
                                <TableCell>{data.amount ? utils.getFormattedAmount(data.amount.government_fee) : 0} OMR</TableCell>
                                <TableCell>{data.amount ? utils.getFormattedAmount(data.amount.total) : 0} OMR</TableCell>
                                <TableCell>{data.amount ? utils.getFormattedAmount(data.amount.vat) : 0} OMR</TableCell>
                                <TableCell>{data.amount ? utils.getFormattedAmount(data.amount.bmi_value) : 0} OMR</TableCell>
                                <TableCell> {(data.plan).replace("_", " ")} </TableCell>
                                <TableCell> {data.payments && data.payments.isPaid ? "Paid" : "Unpaid"} </TableCell>
                                <TableCell> {data.status} </TableCell>
                                {
                                  ["Active"].includes(this.state.status) &&
                                  <>
                                    <TableCell>
                                      {
                                        data.payments.mode === "Link" ? "Card" : data.payments.mode
                                      }
                                    </TableCell>
                                    <TableCell>
                                      {
                                        data.payments.mode !== "Cash" ? data.payments.payment_id : "-"
                                      }
                                    </TableCell>
                                  </>
                                }
                                <TableCell>{data.company_affiliate_name ? data.company_affiliate_name : "-"}</TableCell>
                                <TableCell>{data.affiliate_partner_name ? data.affiliate_partner_name : "-"}</TableCell>
                                <TableCell>{data.channel_name ? data.channel_name : "-"}</TableCell>
                                <TableCell>{data.sub_channel_name ? data.sub_channel_name : "-"}</TableCell>
                                <TableCell>{data.affiliate_employee_id ? data.affiliate_employee_id : "-"}</TableCell>
                                <TableCell>{data.affiliate_name ? data.affiliate_name : "AXSS"}</TableCell>
                                <TableCell>{data.affiliate_email ? data.affiliate_email : "-"}</TableCell>
                                <TableCell>{data.affiliate_employee_designation ? data.affiliate_employee_designation : "-"}</TableCell>
                                <TableCell>{data.branch_id ? data.branch_id : "-"}</TableCell>
                                <TableCell>{data.branch_name ? data.branch_name : "-"}</TableCell>
                                <TableCell>{data.region_name ? data.region_name : "-"}</TableCell>
                                <TableCell>{data.branch_manager_name ? data.branch_manager_name : "-"}</TableCell>
                                <TableCell>{data.branch_manager_email ? data.branch_manager_email : "-"}</TableCell>
                                <TableCell>{data.affiliate_phone ? data.affiliate_phone : "-"}</TableCell>
                                <TableCell>{data.comment ? data.comment : "-"}</TableCell>

                                <TableCell>
                                  <Link to={`/policies/${data._id}`} className="table-eye-link">
                                    <Eye />
                                  </Link>
                                </TableCell>
                              </TableRow>
                            )
                          })
                        }
                        <TableRow>
                          <TablePagination
                            rowsPerPage={this.state.rowsPerPage}
                            rowsPerPageOptions={[10, 25, 50]}
                            page={this.state.page}
                            count={this.state.totalRecords}
                            colSpan={0}
                            onChangePage={this.handleChangePage}
                            onChangeRowsPerPage={this.handleChangeRowsPerPage}
                          />
                        </TableRow>
                      </TableBody>
                    </Table>
                  </div>
                </Paper>
              </Grid>

              <Grid item xs={12}>
                <Button className="mr-1" onClick={this.downloadData} variant="contained" color="primary" disabled={this.state.isDownloading}>{this.state.isDownloading ? "Downloading . . ." : "Download csv"}</Button>
                <Button className="mr-1" onClick={this.downloadRiReportData} variant="contained" color="primary" disabled={this.state.isDownloadingRI}>{this.state.isDownloadingRI ? "Downloading . . ." : "Download RI Report"}</Button>
              </Grid>

            </Grid>
          }
          {
            (this.state.showTable && this.state.data.length === 0) &&
            <Paper className="q-shadow mt-3 pt-5 pb-5 d-flex align-items-center justify-content-center">
              <Typography variant="h5"> No Data Found </Typography>
            </Paper>
          }
        </div>
      </div >
    )
  }
}
export default withRouter(InboundTravelReport);