exports.pricesList = [
    { "from": 0, "to": 1, "price": { "male": 0, "female": 0, "female_married": 0 } },
    { "from": 2, "to": 5, "price": { "male": 0, "female": 0, "female_married": 0 } },
    { "from": 6, "to": 15, "price": { "male": 0, "female": 0, "female_married": 0 } },
    { "from": 16, "to": 20, "price": { "male": 0, "female": 0, "female_married": 0 } },
    { "from": 21, "to": 25, "price": { "male": 0, "female": 0, "female_married": 0 } },
    { "from": 26, "to": 30, "price": { "male": 0, "female": 0, "female_married": 0 } },
    { "from": 31, "to": 35, "price": { "male": 0, "female": 0, "female_married": 0 } },
    { "from": 36, "to": 40, "price": { "male": 0, "female": 0, "female_married": 0 } },
    { "from": 41, "to": 45, "price": { "male": 0, "female": 0, "female_married": 0 } },
    { "from": 46, "to": 50, "price": { "male": 0, "female": 0, "female_married": 0 } },
    { "from": 51, "to": 55, "price": { "male": 0, "female": 0, "female_married": 0 } },
    { "from": 56, "to": 60, "price": { "male": 0, "female": 0, "female_married": 0 } },
    { "from": 61, "to": 65, "price": { "male": 0, "female": 0, "female_married": 0 } },
    { "from": 66, "to": 70, "price": { "male": 0, "female": 0, "female_married": 0 } },
    { "from": 71, "to": 75, "price": { "male": 0, "female": 0, "female_married": 0 } },
    { "from": 76, "to": 99, "price": { "male": 0, "female": 0, "female_married": 0 } }
]