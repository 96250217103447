import React from "react";
import { Button, Checkbox, Grid, IconButton, InputAdornment, List, ListItem, ListItemIcon, ListItemText, Paper, TextField, Typography } from "@material-ui/core";
import { Link, withRouter } from "react-router-dom";
import Navigation from "../common/Navigation";
import Snackbar from "../common/Snackbar";
import SuperadminService from "../../services/superadmins";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { PERMISSIONS } from "../../constants";
import AuthService from "../../services/auth";

class AccessForm extends React.Component{

  state = {
    is_edit : false,
    name : "",
    email : "",
    password : "",
    permissions : [],
    email : "",
    password : ""
  }

  handleCloseSnackbar = () => {
    this.setState({ showSnackbar : false })
  }

  componentDidMount(){
    const id = this.props.match.params.id;
    if(id){
      this.setState({ is_edit : true, id })
      SuperadminService.getUser(id).then((res) => {
        const data = res.data.data;

        this.setState({
          name : data.name,
          permissions : data.permissions
        })
      })
    }
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  }

  handleClickShowPassword = () => {
    this.setState({ showPassword: !this.state.showPassword });
  }

  isValid = () => {
    if(
      this.state.name === "" ||
      (
        !this.state.is_edit &&
        (
          this.state.email === "" ||
          this.state.password === "" ||
          this.state.password.length < 6
        )
      ) ||
      this.state.permissions.length === 0
    ){
      return false;
    }

    return true;
  }

  handleSave = async () => {
    this.setState({ isSaving : true });

    const dataObj = {
      name : this.state.name,
      permissions : this.state.permissions
    }
    if(this.state.is_edit){
      SuperadminService.updateUser(this.state.id, dataObj).then((res) => {
        this.setState({
          showSnackbar: true,
          snackBarMessage: "Changes Saved Successfully",
          messageType: "success",
          isSaving: false
        });
      })
    }else {
      try {
        const registerData = await AuthService.register(this.state.email, this.state.password);
        const uid = registerData.user.uid;
        dataObj.uid = uid;
        dataObj.email = this.state.email;
        dataObj.is_active = true;

        const data = await SuperadminService.add(dataObj);

        if (data.data.inserted) {
          AuthService.logout2();
          this.setState({
            showSnackbar: true,
            snackBarMessage: "Affiliator Added",
            messageType: "success",
            email : "",
            name : "",
            password : "",
            permissions : [],
            isSaving : false
          });
        }
      } catch (e) {
        this.setState({ isSaving: false });
        if (e.code === "auth/email-already-in-use") {
          this.setState({
            showSnackbar: true,
            snackBarMessage: "Email already in use",
            messageType: "danger"
          })
        }
      }
    }
  }

  handleChangePermission = (permission) => {
    let available_permissions = [...this.state.permissions];

    const ind = available_permissions.indexOf(permission);
    if (ind < 0) {
      available_permissions.push(permission);
    } else {
      available_permissions.splice(ind, 1);
    }
    this.setState({ permissions : available_permissions })
  }

  render(){
    return(
      <div className="d-flex">
        <Snackbar
          showSnackbar = { this.state.showSnackbar }
          messageType = {this.state.messageType}
          snackBarMessage = {this.state.snackBarMessage}
          onCloseSnackbar = {this.handleCloseSnackbar}
        />
        <Navigation />

        <div className="container-fluid">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Paper className="p-2 sq-shadow d-flex">
                <Typography><Link to={`/home`}>Home</Link></Typography>
                <Typography className="mr-2 ml-2">/</Typography>
                <Typography><Link to={`/manage-access`}>Manage Access</Link></Typography>
                <Typography className="mr-2 ml-2">/</Typography>
                {
                  this.state.is_edit ?
                  <>
                    <Typography>Edit</Typography>
                    <Typography className="mr-2 ml-2">/</Typography>
                    <Typography> {this.state.id} </Typography>
                  </> :
                  <>
                    <Typography>Create</Typography>
                  </>
                }
              </Paper>
            </Grid>

            <Grid item xs = {4}>
              <Paper className="sq-shadow p-3">
                <TextField
                  label="Name"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  name="name"
                  onChange={this.handleChange}
                  value={this.state.name}
                />

                {
                  !this.state.is_edit &&
                  <>
                    <TextField
                      label="Email"
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      name="email"
                      onChange={this.handleChange}
                      value={this.state.email}
                    />

                    <TextField
                      label="Password"
                      type={this.state.showPassword ? "text" : "password"}
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      name="password"
                      onChange={this.handleChange}
                      value={this.state.password}
                      onBlur={this.onBlurPassword}
                      error={this.state.showPasswordError}
                      helperText={this.state.showPasswordError ? "Please enter atleast 6 digit password" : ""}

                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              edge="end"
                              onClick={this.handleClickShowPassword}
                              tabIndex="-1"
                            >
                              {this.state.showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}

                    />
                  </>
                }

              </Paper>
            </Grid>

            <Grid item xs = {8}>
              <Paper className="sq-shadow p-3">
                <Typography variant="h6" className="mb-3">Permissions</Typography>

                <Grid container spacing={2}>
                  {
                    Object.keys(PERMISSIONS).map((permission) =>
                      <Grid item xs = {6} key={permission}> 
                        <ListItem
                          className="border rounded"
                          button
                          onClick={() => this.handleChangePermission(permission)}
                          dense
                        >
                          <ListItemIcon>
                            <Checkbox
                              checked={this.state.permissions.includes(permission)}
                            />
                          </ListItemIcon>
                          <ListItemText primary={permission.split("_").join(" ")} />
                        </ListItem>
                      </Grid>
                    )
                  }
                  
                </Grid>
              </Paper>
            </Grid>

            <Grid item xs = {12}>
              <Paper className="sq-shadow d-flex justify-content-end p-2">
                <Button
                  variant="contained"
                  color="primary"
                  disabled = {
                    !this.isValid() ||
                    this.state.isSaving
                  }
                  onClick={this.handleSave}
                >
                  {this.state.isSaving ? "Saving . . ." : "Save"}
                </Button>
              </Paper>
            </Grid>
          </Grid>
        </div>
      </div>
    )
  }
}

export default withRouter(AccessForm);