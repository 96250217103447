import axios from "axios";
import config from "./config";
import utils from "./utils";

const { baseUrl } = config;
let home = {}
home.getHomeData = async () => {
  const centerId = sessionStorage.getItem("center_id");
  const channelId = sessionStorage.getItem("channel_id");
  let options = {};
  if (centerId) options['center'] = centerId
  if (channelId) options['channel_id'] = channelId
  const url = utils.addQueryParams(`${baseUrl}/home-data`, options);
  const res = await axios.get(url);
  return res;
}

export default home;