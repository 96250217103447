import React from 'react';
import { Document, Page, StyleSheet, View, Text, Image } from "@react-pdf/renderer";
import DhofarLogo from "../../static/images/dhofar-full-logo.png";
import utils from '../../services/utils';

const styles = StyleSheet.create({
  page : {
    fontSize : 11,
    paddingHorizontal : 50,
    paddingVertical : 50,
    fontFamily : "Helvetica",
    color : "black"
  },
  outerContainer : {
    border : "1pt solid #ccc",
    borderRadius : "5pt"
  },
  imageContainer : {
    paddingVertical : 15,
    display : "flex",
    alignItems : "center"
  },
  dhofarLogo : {
    height : 50
  },
  borderTop : {
    borderTop : "1pt solid #ccc"
  },
  borderBottom : {
    borderBottom : "1pt solid #ccc"
  },
  receiptTitle : {
    fontSize : 14,
    fontFamily : "Helvetica-Bold",
    textAlign : "center",
    paddingVertical : 8
  },
  policyNumber : {
    fontFamily : "Helvetica-Bold",
  },
  policyholder : {
    padding : 10
  },
  row : {
    display : "flex",
    flexDirection : "row",
    justifyContent : "space-between",
    alignItems : "center",
    paddingVertical : 4,
    paddingHorizontal : 10
  },
  padding8 : {
    paddingVertical : 8
  },
  amount : {
    fontFamily : "Helvetica-Bold",
  },
  mt8 : {
    marginTop : 8,
  }

})

const PaymentReceipt = ({ policy, payment_mode, amount, full_name, member_id, has_li_addon }) => {
  return(
    <Document creator = "AXSS" producer = "AXSS" title = "Payment Receipt">
      <Page style = { styles.page }>
        <View style = {styles.outerContainer}>
          <View style = {styles.imageContainer}>
            <Image src = {DhofarLogo} style = {styles.dhofarLogo} />
          </View>

          <View style = {[styles.receiptTitle, styles.borderTop, styles.borderBottom]}>
            <Text>
              Payment Receipt
            </Text>
          </View>

          <View style = { styles.descreptionContainer }>
            <View style = {styles.policyholder}>
              <Text>Payment for policy of {full_name} / Payment towards Member Id <Text style = {styles.policyNumber}>{member_id}</Text> </Text>
            </View>

            <View style = {[ styles.row, styles.borderTop, styles.borderBottom ]}>
              <Text>Payment Mode</Text>
              <Text style = { styles.amount }>{payment_mode}</Text>
            </View>

            {
              has_li_addon &&
              <View style = {[ styles.row, styles.borderBottom ]}>
                <Text>Medical</Text>
              </View>
            }

            <View style = {[ styles.row, styles.mt8 ]}>
              <Text>Basic Premuim</Text>
              <Text style = { styles.amount }>{Number(amount.base_price).toFixed(3)} OMR</Text>
            </View>
            <View style = { styles.row }>
              <Text>Policy Fee</Text>
              <Text style = { styles.amount }>{Number(amount.policy_fee).toFixed(3)} OMR</Text>
            </View>
            {
              amount.death_cover &&
              <View style = { styles.row }>
                <Text>Death Cover</Text>
                <Text style = { styles.amount }>{Number(amount.death_cover).toFixed(3)} OMR</Text>
              </View>
            }
            <View style = { styles.row }>
              <Text>Government Fee</Text>
              <Text style = { styles.amount }>{Number(amount.government_fee).toFixed(3)} OMR</Text>
            </View>
            <View style = { styles.row }>
              <Text>Emergency Fund Fee</Text>
              <Text style = { styles.amount }>{Number(amount.emergency_fund_fee).toFixed(3)} OMR</Text>
            </View>
            {
              amount.vat !== undefined &&
              <View style = { styles.row }>
                <Text>VAT</Text>
                <Text style = { styles.amount }>Exempted</Text>
              </View>
            }
            {
              amount.bmi_value > 0 &&
              <View style = { styles.row }>
                <Text>Loading Fee</Text>
                <Text style = { styles.amount }>{Number(amount.bmi_value).toFixed(3)} OMR</Text>
              </View>
            }

            {
              has_li_addon &&
              <View style = {[ styles.row, styles.borderTop, styles.borderBottom, styles.mt8 ]}>
                <Text>Life</Text>
              </View>
            }

            {
              has_li_addon &&
              <>
              <View style = {[ styles.row, styles.mt8 ]}>
                <Text>Basic Premuim</Text>
                <Text style = { styles.amount }>{Number(amount.life_base_amount).toFixed(3)} OMR</Text>
              </View>
              <View style = { styles.row }>
                <Text>Policy Fee</Text>
                <Text style = { styles.amount }>{Number(amount.life_policy_fee).toFixed(3)} OMR</Text>
              </View>
              <View style = { styles.row }>
                <Text>Government Fee</Text>
                <Text style = { styles.amount }>{Number(amount.life_government_fee).toFixed(3)} OMR</Text>
              </View>
              <View style = { styles.row }>
                <Text>Emergency Fund Fee</Text>
                <Text style = { styles.amount }>{Number(amount.life_emergency_fund_fee).toFixed(3)} OMR</Text>
              </View>
              </>
            }

            <View style = {[ styles.row, styles.padding8, styles.borderTop, styles.mt8 ]}>
              <Text>Round Off</Text>
              <Text style = { styles.amount }>{Number(utils.getRoundOffValue(policy)).toFixed(3)} OMR</Text>
            </View>
            <View style = {[ styles.row, styles.padding8, styles.borderTop, styles.mt8 ]}>
              <Text>Total</Text>
              <Text style = { styles.amount }>{Number(amount.total).toFixed(2)} OMR</Text>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  )
}
export default PaymentReceipt;