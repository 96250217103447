import { MenuItem, TextField } from "@material-ui/core";
import React from "react";
import AffiliateService from "../../../services/affiliates";
import PropTypes from "prop-types";
import _ from "lodash";
import { withRouter } from "react-router";
import Select from 'react-select';

class ChannelSelect extends React.Component {

    state = {
        channel: "",
        channels: [],
        size: "medium",
        setSelectedValue: "all"
    }

    async componentDidMount() {
        let channels = await this.getChannels();
        this.setState({ channels });
        let channel = sessionStorage.getItem("channel_id") ? sessionStorage.getItem("channel_id") : "all";
        this.setState({ setSelectedValue: channel });
    }

    componentWillReceiveProps(props) {

        let channel = sessionStorage.getItem("channel_id") ? sessionStorage.getItem("channel_id") : "all";
        if (props.fresh === true) {
            channel = "all";
        }

        let size = props.size ? props.size : "medium";

        this.setState({ channel, size });
        this.onChange = props.onChange;
    }

    getChannels = async () => {
        let channels = [];
        try {
            channels = await AffiliateService.getListAllChannels();
        } catch (error) { }
        // console.log(channels);
        return channels;
    }

    handleChange = (e) => {
        if ("all" == e.value) {
            sessionStorage.removeItem("channel_id")
        } else {
            sessionStorage.setItem("channel_id", e.value)
        }
        this.onChange(e.value);
        this.setState({
            [e.name]: e.value,
            setSelectedValue: e.value

        });
    }

    render() {

        const options = [];
        options.push({ value: 'all', label: 'All' });
        Array.isArray(this.state.channels) && this.state.channels.map((channel) => {
            options.push({ value: `${channel._id}`, label: `${channel.name}` })
        })


        return (
            <React.Fragment>
                <label className="text-secondary"><small>Select Channel</small></label>
                <Select
                    className="basic-single"
                    classNamePrefix="select"
                    name="channel"
                    value={options.find(obj => obj.value === this.state.setSelectedValue)}
                    options={options}
                    onChange={this.handleChange}
                />
            </React.Fragment>
        )
    }
}

ChannelSelect.propTypes = {
    fresh: PropTypes.bool,
    onChange: PropTypes.func,
    size: PropTypes.oneOf(["small"])
}

export default withRouter(ChannelSelect);