import React from 'react';
import { Document, Page, StyleSheet, View, Text, Image, Font } from "@react-pdf/renderer";
import DhofarLogo from "../../static/images/term-plan-header.png";
import DICStamp from "../../static/images/term-plan-signature.png"
import Arabic from "../../static/fonts/Arabic/A_Nefel_Adeti.ttf";
import AxssLogo from "../../static/images/axss-logo.png";

Font.register({ family : "Arabic", src : Arabic });

const styles = StyleSheet.create({
  page : {
    fontSize : 10,
    fontFamily : "Arabic"
  },
  container : {
    paddingHorizontal : 90,
    paddingVertical : 60
  },
  planTitle : {
    fontSize : 20,
    textAlign : "center",
    textDecoration : "underline",
    marginTop : 50
  },
  row : {
    display : "flex",
    flexDirection : "row"
  },
  planSubtitle : {
    fontSize : 16,
    marginTop : "30pt",
  } ,
  planDetailContainer : {
    border : 1,
    marginTop : "25pt"
  },
  column1 : {
    width : "50%",
    borderRightWidth : 1,
    paddingVertical : "2px",
    paddingLeft : "3px",
  },
  column2  : {
    paddingLeft : "4px",
    paddingVertical : "3px",
    fontSize : 9,
  },
  borderTop : {
    borderTop : 1,
  },
  expirayDate : {
    marginRight : "10px"
  },
  bold : {
    fontWeight : "extrabold"
  },
  dicStampContainer : {
    width : "100%",
    justifyContent : "flex-end",
    marginTop : "10px",
  },
  signatureContainer : {
    justifyContent : "flex-end",
    marginTop : "40pt"
  },
  signBottomContainer : {
    marginTop : "10px" 
  },
  signTitle : {
    display : "flex",
    flexDirection : "row",
    justifyContent : "center" 
  },
  topLogo : {
    textAlign : "center"
  },
  signature : {
    maxHeight : "140pt",
    maxWidth : "200pt",
    textAlign : "right"
  }
})




const LifeTermPlan = ({ beneficiaries, data, startDate, endDate, dob }) => {
  return(
    <Document creator = "Sehteq" producer = "Sehteq" title = "Life term plan">
      <Page style={styles.page}>
        <View style = {styles.container}>
          <View style={{ display : "flex", alignItems : "center" }}>
            <Image source = {AxssLogo} style = {styles.headerImage} />
          </View>
          <View style = {styles.planTitle}>
            <Text >
              INDIVIDUAL TERM PLAN
            </Text>
            <Text style = {styles.planSubtitle}>
              POLICY SCHEDULE
            </Text>
          </View>
          <View style = {styles.planDetailContainer}>
            <View style= {styles.row}>
              <View style = {styles.column1}>
                <Text>Policy Number</Text>
              </View>
              <View style = {[styles.column2, styles.bold]}>
                <Text>{ data.policy_num }</Text>
              </View>
            </View>
            <View style= {[styles.row, styles.borderTop]}>
              <View style = {styles.column1}>
                <Text>Name of Insured</Text>
              </View>
              <View style = {[styles.column2, styles.bold]}>
                <Text>{ data.personalInformation.fullName}</Text>
              </View>
            </View>
            <View style= {[styles.row, styles.borderTop]}>
              <View style = {styles.column1}>
                <Text>Civil ID/Passport</Text>
              </View>
              <View style = {[styles.column2, styles.bold]}>
                <Text>{ data.civil_id }</Text>
              </View>
            </View>
            <View style= {[styles.row, styles.borderTop]}>
              <View style = {styles.column1}>
                <Text>Nationality</Text>
              </View>
              <View style = {[styles.column2, styles.bold]}>
                <Text>{ data.nationality}</Text>
              </View>
            </View>
            <View style= {[styles.row, styles.borderTop]}>
              <View style = {styles.column1}>
                <Text>Date of Birth</Text>
              </View>
              <View style = {[styles.column2, styles.bold]}>
                <Text>{ dob }</Text>
              </View>
            </View>
            <View style= {[styles.row, styles.borderTop]}>
              <View style = {styles.column1}>
                <Text>Age</Text>
              </View>
              <View style = {[styles.column2, styles.bold]}>
                <Text>{ data.age }</Text>
              </View>
            </View>
            <View style= {[styles.row, styles.borderTop]}>
              <View style = {styles.column1}>
                <Text>Occupation</Text>
              </View>
              <View style = {[styles.column2, styles.bold]}>
                <Text>{ data.personalInformation.occupation }</Text>
              </View>
            </View>
            <View style= {[styles.row, styles.borderTop]}>
              <View style = {styles.column1}>
                <Text>Policy Term</Text>
              </View>
              <View style = {[styles.column2, styles.bold]}>
                <Text>365 Days</Text>
              </View>
            </View>
            <View style= {[styles.row, styles.borderTop]}>
              <View style = {styles.column1}>
                <Text>Sum Insured (RO)</Text>
              </View>
              <View style = {[styles.column2, styles.bold]}>
                <Text>{ data.sum_assured }</Text>
              </View>
            </View>
            {
              beneficiaries.map((beneficiary, ind) => 
                <React.Fragment key = {ind}>
                  <View style= {[styles.row, styles.borderTop]}>
                    <View style = {styles.column1}>
                      <Text>Name of Beneficiary {beneficiaries.length > 1 ? `(${ind + 1})` : ""}</Text>
                    </View>
                    <View style = {[styles.column2, styles.bold]}>
                      <Text> {beneficiary.name} </Text>
                    </View>
                  </View>
                  <View style= {[styles.row, styles.borderTop]}>
                    <View style = {styles.column1}>
                      <Text>Relationship {beneficiaries.length > 1 ? `(${ind + 1})` : ""}</Text>
                    </View>
                    <View style = {[styles.column2, styles.bold]}>
                      <Text> {beneficiary.relation} </Text>
                    </View>
                  </View>
                  {
                    beneficiaries.length > 1 &&
                    <View style= {[styles.row, styles.borderTop]}>
                      <View style = {styles.column1}>
                        <Text>Benefit Percentage {beneficiaries.length > 1 ? `(${ind + 1})` : ""}</Text>
                      </View>
                      <View style = {[styles.column2, styles.bold]}>
                        <Text> {beneficiary.benefit_percentage} </Text>
                      </View>
                    </View>
                  }
                </React.Fragment>
              )
            }
            <View style= {[styles.row, styles.borderTop]}>
              <View style = {styles.column1}>
                <Text>Period Of Insurance</Text>
              </View>
              <View style = {styles.column2}>
                <View style = {styles.row}>
                  <View style = {[styles.row, styles.expirayDate]}>
                    <Text>From : </Text>
                    <Text style = {styles.bold}>{startDate}</Text>
                  </View>
                  <View style = {styles.row}>
                    <Text>To : </Text>
                    <Text style = {styles.bold}>{endDate}</Text>
                  </View>
                </View>
              </View>
            </View>
            <View style= {[styles.row, styles.borderTop]}>
              <View style = {styles.column1}>
                <Text>Annual Premium</Text>
              </View>
              <View style = {[styles.column2, styles.bold]}>
                <Text>
                  { data.amount.life_total ? data.amount.life_total : data.amount.total  } OMR</Text>
              </View>
            </View>
            <View style= {[styles.row, styles.borderTop]}>
              <View style = {styles.column1}>
                <Text>Date of Issue</Text>
              </View>
              <View style = {[styles.column2, styles.bold]}>
                <Text>{startDate}</Text>
              </View>
            </View>
          </View>
          <View style = {[styles.signatureContainer, styles.row]}>
            <View>
              <View style = {styles.signTitle}>
                <Text>Signed on behalf of</Text>
              </View>
              <Text style = {styles.signBottomContainer}>Insurance Company.</Text>
            </View>
          </View>
          <View style = { [styles.dicStampContainer, styles.row ] }>
            <Image source = { DICStamp } style = {styles.signature} />
          </View>
        </View>
      </Page>
    </Document>
  )
}
export default LifeTermPlan;