import React from "react";
import { Table, TableHead, TableBody, TableRow, TableCell, TablePagination, TextField, Paper, InputAdornment } from "@material-ui/core";
import {Link, withRouter} from "react-router-dom";
import Eye from "mdi-react/EyeIcon";
import { Search } from "@material-ui/icons";
import PolicyService from "../../services/policy";
import Snackbar from "../common/Snackbar";
import AffiliateSelect from "../common/AffiliateSelect";

class AllTable extends React.Component{
  state = {
    data : [],
    page : 0,
    rowsPerPage : 10,
    count : 0,
    searchValue : "",
    center : "",
    status : undefined,
    showDeleteDialog : false,
    selectedId : "",
    showSnackbar : false
  }

  getData = async (page, rowsPerPage) => {
    const value = this.state.searchValue === "" ? undefined : this.state.searchValue;
    const data = await PolicyService.getAllUnpaid(page, rowsPerPage, value);
    this.setState({
      count : data.data.count,
      data : data.data.data
    })
  }
  handleChangePage = (e, page) => {
    this.setState({ page });
    this.getData(page, this.state.rowsPerPage);
  }
  handleChangeRowsPerPage = (e) => {
    this.setState({ rowsPerPage : e.target.value });
    this.getData(this.state.page, e.target.value);
  }
  componentDidMount(){
    const center = this.props.match.params.center;
    this.setState({ center });
    this.getData(this.state.page, this.state.rowsPerPage);
  }

  handleSearchChange =  (e) => {
    this.setState({
      searchValue : e.target.value
    },() => {
      this.setState({ page : 0 });
      this.getData(0, this.state.rowsPerPage)
    })
  }

  handleClose = () => {
    this.setState({
      showDeleteDialog : false,
      selectedId : ""
    })
  }

  handleDelete = async () => {
    const data = await PolicyService.deletePolicy(this.state.selectedId);
    if(data.data.deleted){
      this.setState({
        showSnackbar : true,
        messageType : "success",
        snackBarMessage : "Policy deleted successfully"
      })
      this.handleClose();
      this.getData(this.state.page, this.state.rowsPerPage);
    }
  }

  handleCloseSnackbar = () => {
    this.setState({ showSnackbar : false })
  }

  getFormattedDate = (startDate) => {
    let newDate = new Date(startDate);
    const date = newDate.getDate();
    const month = newDate.getMonth() + 1;
    const year = newDate.getFullYear();
    return `${date > 9 ? date : "0" + date}/${month > 9 ? month : "0" + month}/${year}`;
  }

  handleAffiliateChange = () => {
    this.getData(this.state.page, this.state.rowsPerPage);
  }

  render(){
    return(
      <div>
        <Snackbar
          showSnackbar = { this.state.showSnackbar }
          messageType = {this.state.messageType}
          snackBarMessage = {this.state.snackBarMessage}
          onCloseSnackbar = {this.handleCloseSnackbar}
        />
        <div className="row justify-content-between">
          <div className="col-md-5 col-12">
            <AffiliateSelect fresh={false} onChange={this.handleAffiliateChange} />
          </div>

          <div className="col-md-5 col-12">
            <TextField
            variant = "outlined"
            label = "Search"
            className = "mt-3 mb-3"
            fullWidth
            placeholder = "Search by quote no / policy num / payment id / name / email"
            onChange = {this.handleSearchChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Search />
                </InputAdornment>
              ),
            }}
            />
          </div>
        </div>
        <Paper className="sq-shadow policies-table-container mb-3">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  Quote No.
                </TableCell>
                {/* <TableCell>
                  Card Number
                </TableCell> */}
                <TableCell>
                  Start Date
                </TableCell>
                <TableCell>
                  Plan Name
                </TableCell>
                <TableCell>
                  Name
                </TableCell>
                <TableCell>
                  Status
                </TableCell>
                <TableCell align="right">
                  Amount
                </TableCell>
                <TableCell align="right">
                  View
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                this.state.data.map((data) => 
                  <TableRow key={data.id}>
                    <TableCell>
                      {data.id}
                    </TableCell>
                    {/* <TableCell>
                      {data.policy_num}
                    </TableCell> */}
                    <TableCell>
                      {this.getFormattedDate(data.startDate)}
                    </TableCell>
                    <TableCell>
                      {data.policy_type !== "undefined" ? data.policy_type.replace("_", " ") : "Domestic Worker"}
                    </TableCell>
                    <TableCell>
                      {data.name}
                    </TableCell>
                    <TableCell>
                      {data.status}
                    </TableCell>
                    <TableCell align="right">
                      {data.amount} OMR
                    </TableCell>
                    <TableCell align="right">
                      <Link to={`/policies/${data.id}`} className="table-eye-link">
                        <Eye />
                      </Link>
                    </TableCell>
                  </TableRow>
                )
              }
              <TableRow>
                <TablePagination
                  count = {this.state.count}
                  page = {this.state.page}
                  rowsPerPage = {this.state.rowsPerPage}
                  onChangePage = {this.handleChangePage}
                  onChangeRowsPerPage = {this.handleChangeRowsPerPage}
                  rowsPerPageOptions = {[10,25,50]}
                />
              </TableRow>
            </TableBody>
          </Table>
        </Paper>
      </div>
    )
  }
}

export default withRouter(AllTable);