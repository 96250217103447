import React from "react";
import Navigation from "../common/Navigation";
import { Grid, Button, Table, TableHead, TableBody, TableRow, TableCell, Paper, Typography, TablePagination, TextField, MenuItem } from "@material-ui/core";
import Eye from "mdi-react/EyeIcon";
import { Link, withRouter } from "react-router-dom";
import { saveAs } from "file-saver";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import TravelService from "../../services/travel-insurance";
import moment from "moment";
import utils from "../../services/utils";
import AffiliateSelect from "../common/AffiliateSelect";
import _ from "lodash";
import helpers from "../../helpers";
import { REPORT_STATUS_OPTIONS, REPORT_SOB_OPTIONS } from "../../constants";
import RICodes from "../../services/travel-ri-codes.json"
import BranchList from "../../services/branches.json";
import RegionList from "../../services/region.json";

class OutboundTravelReport extends React.Component {
  state = {
    startDate: null,
    endDate: null,
    showTable: false,
    data: [],
    page: 0,
    rowsPerPage: 10,
    totalRecords: 0,
    headings: [],
    center: "",
    isLoading: false,
    isDownloading: false,
    status: "All",
    sob : "All",
    region : "All",
    branch : "All"
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    });
  }

  componentDidMount() {
    const center = this.props.match.params.center;

    let date = new Date().setHours(23, 59, 59, 999);
    let endDate = new Date(date).getTime();
    this.setState({
      startDate: new Date(new Date().setHours(0, 0, 0, 0)).getTime(),
      endDate,
      center
    });
  }

  handleChangePage = (e, page) => {
    this.setState({ page }, this.handleSearch);
  }

  handleChangeRowsPerPage = (e) => {
    this.setState({ rowsPerPage: e.target.value }, this.handleSearch);
  }

  formatPolicies = (policies) => {
    return Array.isArray(policies) && policies.length ?
      policies.map((policy, ind) => {
        if (policy.travellers_type === "individual") {
          policy.travellers = [{
            name: _.get(policy, "personalInformation.fullName", ""),
            relation: "Self",
            passport_no: _.get(policy, "personalInformation.passport", "")
          }];
        }
        return policy
      }) : [];
  }

  handleSearch = async () => {
    this.setState({ isLoading: true });
    const status = this.state.status === "All" ? undefined : this.state.status;
    const sob = this.state.sob === "All" ? undefined : this.state.sob;
    const region = this.state.region === "All" ? undefined : this.state.region;
    const branch = this.state.branch === "All" ? undefined : this.state.branch;

    const data = await TravelService.getPolicies({
      startDate : this.state.startDate,
      endDate : this.state.endDate,
      status,
      page : this.state.page,
      limit : this.state.rowsPerPage,
      travel_type : 'outbound',
      sob : sob,
      region : region,
      branch : branch
    });
    let _data = data.data.data

    if (data.status === 200) {
      this.setState({ showTable: true, data: this.formatPolicies(_data), totalRecords: data.data.count })
    }


    let headings = [
      "Sr No",
      "Policy Number",
      "Policy Start Date",
      "Travel Start Date",
      "Travel End Date",
      "Travel Duration Days",
      "Travel Coverage Type",
      "Traveller type",
      "Travellers",
      "Premium",
      "Policy Holder Name",
      "Email ID",
      "Contact No",
      "Net Premium",
      "Discount Code",
      "Discount",
      "Policy Fee",
      "Emergency Fund Fee",
      "Goverment Fee",
      "Gross Premium",
      "VAT",
      "Loading",
      "Plan Name",
      "Payment Status",
      "Policy Status",
      "Net Rate USD",
      "Net Rate OMR",
      "Loading Rate USD",
      "Loading Rate OMR",
      "Gross Rate USD",
      "Gross Rate OMR",
    ]
    if (["Active"].includes(this.state.status)) {
      headings.push("Payment Mode", "Payment Reference Number");
    }
    headings.push("Company", "Partner Branch Name", "Channel Name", "Sub Channel Name", "Employee ID", "Employee Name", "Employee EmailID", "Employee Designation", "Location/Branch-Id", "Location/Branch-Name", "Zone/Region", "Reporting Manager", "Reporting Mgr email id", "Employee Contact No.", "Comment", "View");
    this.setState({ isLoading: false, headings });
  }

  getFormattedDate = (date, responseFormat = "DD-MMM-YYYY") => {
    try {
      let newDate = moment(date, "DD-MM-YYYY").format(responseFormat);
      return (newDate === "Invalid date") ? "-" : newDate;
    } catch (error) {
      return "-";
    }
  }

  getFormattedDateTime = (date, checkFormat = true, responseFormat = 'MMM DD, YYYY LTS') => {
    try {
      let newDate;
      if (checkFormat) {
        newDate = moment(date, "DD-MM-YYYY").format(responseFormat)
      } else {
        newDate = moment(date).format(responseFormat)
      }
      return (newDate === "Invalid date") ? "-" : newDate;
    } catch (error) {
      console.log(error);
      return "-";
    }
  }

  downloadData = async () => {
    this.setState({ isDownloading: true });
    const status = this.state.status === "All" ? undefined : this.state.status;
    const sob = this.state.sob === "All" ? undefined : this.state.sob;
    const region = this.state.region === "All" ? undefined : this.state.region;
    const branch = this.state.branch === "All" ? undefined : this.state.branch;

    const res = await TravelService.getPolicies({
      startDate : this.state.startDate,
      endDate : this.state.endDate,
      status,
      travel_type : 'outbound',
      sob : sob,
      region : region,
      branch : branch
    });
    const data = this.formatPolicies(res.data.data);
    let csvArr = [];
    let newHeadings = [...this.state.headings];
    newHeadings.splice(-1, 1);
    csvArr.push(newHeadings.join(","));
    data.forEach((policy, ind) => {
      const base_price = policy.amount ? helpers.getBasePrice(policy.amount) : 0;
      const discount_code = policy.amount ? helpers.getDiscountCode(policy.amount) : 0;
      const discount = policy.amount ? helpers.getDiscount(policy.amount) : "-";
      const policy_fee = policy.amount ? policy.amount.policy_fee : 0;
      const emergency_fund_fee = policy.amount ? policy.amount.emergency_fund_fee : 0;
      const government_fee = policy.amount ? policy.amount.government_fee : 0;
      const amount = policy.amount ? policy.amount.total : 0;
      const paymentStatus = policy.payments && policy.payments.isPaid ? "Paid" : "Unpaid";
      const bmiValue = _.get(policy.amount, "bmi_value", 0)
      const vatValue = _.get(policy.amount, "vat", 0)

      let travellers = "";

      if (Array.isArray(policy.travellers)) {
        policy.travellers.forEach((traveller) => {
          travellers += `Name : ${traveller.name ? traveller.name : `${traveller.first_name} ${traveller.last_name}`},\nRelation : ${traveller.relation || traveller.inbound_relation_detail},\nPassport No : ${traveller.passport_no}\n`
        })
      }
      let arr = [
        ind + 1,
        policy._id,
        this.getFormattedDateTime(policy.startDate, false, "DD-MMM-YYYY"),
        policy.travel_start_date ? this.getFormattedDate(policy.travel_start_date) : this.getFormattedDateTime(policy.outbound_travel_start_date, false, "DD-MM-YYYY"),
        policy.travel_end_date ? this.getFormattedDate(policy.travel_end_date) : this.getFormattedDateTime(policy.outbound_travel_end_date, false, "DD-MM-YYYY"),
        policy.travel_duration_days || policy.outbound_generate_quotation_request?.CumulativeDays,
        `"${policy.travel_coverage_type || policy.outbound_coverage_name}"`,
        policy.travellers_type,
        `"${travellers}"`,
        `"${utils.getFormattedAmount(policy.amount ? policy.amount.total : "0")}"`,
        policy.personalInformation.fullName,
        policy.personalInformation.email,
        policy.personalInformation.mobileNumber,
        `"${utils.getFormattedAmount(base_price)}"`,
        discount_code,
        `"${utils.getFormattedAmount(discount)}"`,
        `"${utils.getFormattedAmount(policy_fee)}"`,
        `"${utils.getFormattedAmount(emergency_fund_fee)}"`,
        `"${utils.getFormattedAmount(government_fee)}"`,
        `"${utils.getFormattedAmount(amount)}"`,
        `"${utils.getFormattedAmount(vatValue)}"`,
        `"${utils.getFormattedAmount(bmiValue)}"`,
        policy.outbound_product_name || policy.plan,
        paymentStatus,
        policy.status,
        policy.outbound_coverage_id && policy.amount.travel_amounts.net_premium ? policy.amount.travel_amounts.net_premium : "-",
        policy.outbound_coverage_id && policy.amount.travel_amounts.net_premium ? helpers.convertUSDToOMR(policy.amount.travel_amounts.net_premium) : "-",
        policy.outbound_coverage_id && policy.amount.travel_amounts.loading ? policy.amount.travel_amounts.loading : "-",
        policy.outbound_coverage_id && policy.amount.travel_amounts.loading ? helpers.convertUSDToOMR(policy.amount.travel_amounts.loading) : "-",
        policy.outbound_coverage_id && policy.amount.travel_amounts.gross_premium ? Number(policy.amount.travel_amounts.gross_premium).toFixed(3) : "-",
        policy.outbound_coverage_id && policy.amount.travel_amounts.gross_premium ? helpers.convertUSDToOMR(policy.amount.travel_amounts.gross_premium) : "-"
      ];

      if (["Active"].includes(this.state.status)) {
        const paymentMode = policy.payments.mode === "Link" ? "Card" : policy.payments.mode;
        arr.push(
          paymentMode,
          policy.payments.mode !== "Cash" ? policy.payments.payment_id : "-"
        )
      }
      arr.push(policy.company_affiliate_name ? policy.company_affiliate_name : "-")
      arr.push(policy.affiliate_partner_name ? policy.affiliate_partner_name : "-")
      arr.push(policy.channel_name ? policy.channel_name : "-")
      arr.push(policy.sub_channel_name ? policy.sub_channel_name : "-")
      arr.push(policy.affiliate_employee_id ? policy.affiliate_employee_id : "-")
      arr.push(policy.affiliate_name ? policy.affiliate_name : "AXSS")
      arr.push(policy.affiliate_email ? policy.affiliate_email : "-")
      arr.push(policy.affiliate_employee_designation ? policy.affiliate_employee_designation : "-")
      arr.push(policy.branch_id ? policy.branch_id : "-")
      arr.push(policy.branch_name ? policy.branch_name : "-")
      arr.push(policy.region_name ? policy.region_name : "-")
      arr.push(policy.branch_manager_name ? policy.branch_manager_name : "-")
      arr.push(policy.branch_manager_email ? policy.branch_manager_email : "-")
      arr.push(policy.affiliate_phone ? policy.affiliate_phone : "-")
      arr.push(policy.comment ? `"${policy.comment}"` : "-")
      csvArr.push(arr.join(","));
    });

    const csvFile = new Blob([csvArr.join("\n")], { type: "text/csv" });
    saveAs(csvFile, "outbound-travel-insurance.csv");
    this.setState({ isDownloading: false, showProcessPopup: false });
  }

  downloadRiReportData = async () => {
    this.setState({ isDownloadingRi: true });
    const status = this.state.status === "All" ? undefined : this.state.status;
    const res = await TravelService.getPolicies({
      startDate : this.state.startDate,
      endDate : this.state.endDate,
      status,
      travel_type : 'outbound',
    });
    let _data = res.data.data
    const data = this.formatPolicies(_data);
    let csvArr = [
      ["From Date :", moment(this.state.startDate).format('DD/MM/YYYY')].join(","),
      ["To Date :", moment(this.state.endDate).format('DD/MM/YYYY')].join(",")
    ];
    let newHeadings = [
      "PRODUCT",
      "POLICY_BRANCH",
      "POLICY_NUMER",
      "ENDORSEMENT_ID",
      "ENDORSEMENT_NO",
      "Coverage From date",
      "Coverage To date",
      "POLICY_CUSTOMER_ID",
      "POL_INSURED_NAME",
      "TRAVELLER_NAME",
      "POL_SOB_CODE_NAME",
      "ADD_RESS",
      "MOBILE NUMBER",
      "CIVIL_ID",
      "PASSPORT NO",
      "FROM CITY",
      "TO CITY",
      "NO.OF.DAYS",
      "Date Of Birth",
      "AGE",
      "TRAVEL PLAN",
      "GEO_ARE",
      "RI Sum Insured",
      "SI RETN",
      "GROSS PREMIUM",
      "CHARGES",
      "RI REF CODE",
      "RI PREM OMR",
      "SPL RETN PREM",
      "RETN PREM",
      "RI PREM USD",
      "SPL RETN PREM USD",
      "Policy Approved Date",
      "Policy Approved User"
    ];
    csvArr.push(newHeadings.join(","));
    data.forEach((policy, ind) => {
      const product = _.get(policy, "travellers_type", "") == "family" ? "Travel Family" : "Travel Individual";

      let travellersNames = "";
      if (Array.isArray(policy.travellers)) {
        policy.travellers.forEach((traveller) => {
          travellersNames += `${traveller.name},`
        })
      }

      let address = _.get(policy, "personalInformation.resident_location", "-") + ", " + _.get(policy, "personalInformation.nationality", "-")
      let travel_countries = _.get(policy, "travel_countries", []);
      let from_city = _.get(travel_countries, "0", "-");
      let to_city = Array.isArray(travel_countries) && travel_countries.length ? travel_countries.splice(1) : [];
      to_city = to_city.join(", ");
      let age = "-";
      if (_.get(policy, "personalInformation.dob", false)) {
        age = moment().diff(moment(_.get(policy, "personalInformation.dob", false), "DD-MM-YYYY"), 'years')
        age = age >= 0 ? age : "-";
      }

      let approveDate = _.get(policy, "payments.payment_done_at", false) ?
        this.getFormattedDateTime(policy.payments.payment_done_at, false) :
        (_.get(policy, "active_at", false) ? this.getFormattedDateTime(policy.active_at, false) : "-");

      let arr = [
        product,
        policy.branch_name ? policy.branch_name : "-",
        policy._id,
        0,
        "Null",
        this.getFormattedDateTime(policy.travel_start_date),
        this.getFormattedDateTime(policy.travel_end_date),
        "-",
        policy.personalInformation.fullName,
        travellersNames,
        policy.affiliate_name ? policy.affiliate_name : "Null",
        address,
        _.get(policy, "personalInformation.mobileNumber", "-"),
        _.get(policy, "civil_id", "-"),
        _.get(policy, "personalInformation.passport", "-"),
        from_city,
        to_city,
        _.get(policy, "amount.travel_amounts.travel_duration", "-"),
        this.getFormattedDateTime(_.get(policy, "personalInformation.dob", "")),
        age,
        _.get(policy, "plan", "-") + " Travel",
        _.get(policy, "travel_coverage_type", "-"),
        utils.getFormattedAmount(_.get(policy, "amount.travel_amounts.ri_sum_assured", 0)),
        utils.getFormattedAmount(_.get(policy, "amount.travel_amounts.si_retn", 0)),
        utils.getFormattedAmount(_.get(policy, "amount.travel_amounts.gross_premium", 0)),
        utils.getFormattedAmount(_.get(policy, "amount.travel_amounts.charges", 0)),
        _.get(policy, "amount.travel_amounts.ri_code", "-"),
        utils.getFormattedAmount(_.get(policy, "amount.travel_amounts.ri_premium_omr", 0)),
        utils.getFormattedAmount(_.get(policy, "amount.travel_amounts.si_retn_premium_omr", 0)),
        utils.getFormattedAmount(_.get(policy, "amount.travel_amounts.additional_premium", 0)),
        utils.getFormattedAmount(_.get(policy, "amount.travel_amounts.ri_premium_usd", 0)),
        utils.getFormattedAmount(_.get(policy, "amount.travel_amounts.si_retn_premium_usd", 0)),
        approveDate,
        "-"
      ];
      csvArr.push(arr.map(value => `"${value}"`).join(","));
    });

    const csvFile = new Blob([csvArr.join("\n")], { type: "text/csv" });
    saveAs(csvFile, "ri-report-outbound-travel-insurance.csv");
    this.setState({ isDownloadingRi: false, showProcessPopup: false });
  }

  downloadBordereauxReportData = async () => {
    this.setState({ isDownloadingBorderaux: true });
    const status = this.state.status === "All" ? undefined : this.state.status;
    const res = await TravelService.getPolicies({
      startDate : this.state.startDate,
      endDate : this.state.endDate,
      status,
      travel_type : 'outbound',
    });

    let _data = res.data.data
    const data = this.formatPolicies(_data);
    let csvArr = [
      ["From Date :", moment(this.state.startDate).format('DD/MM/YYYY')].join(","),
      ["To Date :", moment(this.state.endDate).format('DD/MM/YYYY')].join(",")
    ];
    let newHeadings = [
      "POLICY ISSUED DATE",
      "POLICY NUMBER",
      "ENDORSEMENT NO",
      "COVERAGE FROM DATE",
      "COVERAGE TO DATE",
      "INSURED NAME",
      "TRAVELLER NAME",
      "MOBILE NUMBER",
      "PASSPORT",
      "NO OF DAYS",
      "Date Of Birth",
      "AGE",
      "GEO AREA",
      "SI RI",
      "SI RETN",
      "RI REF CODE",
      "RI PREM USD",
      "SPL RETN PREM USD",
      "MAPFRE RI CODE"
    ];
    csvArr.push(newHeadings.join(","));

    let dataArr = [];

    data.forEach((policy, ind) => {

      let travellersNames = "";
      if (Array.isArray(policy.travellers)) {
        policy.travellers.forEach((traveller) => {
          travellersNames += `${traveller.name},`
        })
      }

      let age = "-";
      if (_.get(policy, "personalInformation.dob", false)) {
        age = moment().diff(moment(_.get(policy, "personalInformation.dob", false), "DD-MM-YYYY"), 'years')
        age = age >= 0 ? age : "-";
      }

      const ri_code = _.get(policy, "amount.travel_amounts.ri_code", "-");
      
      const ri_code_obj = RICodes.find((ri_code_obj) => ri_code_obj["DIC-RI-CODE"] === parseInt(ri_code));
      const mapfre_ri_code = ri_code_obj ? ri_code_obj["MAPFRE-RI-CODE"] : "-"
      
      let arr = [
        this.getFormattedDateTime(policy.startDate, false),
        policy._id,
        "--",
        this.getFormattedDateTime(policy.travel_start_date),
        this.getFormattedDateTime(policy.travel_end_date),
        policy.personalInformation.fullName,
        travellersNames,
        _.get(policy, "personalInformation.mobileNumber", "-"),
        _.get(policy, "personalInformation.passport", "-"),
        _.get(policy, "travel_duration_days", "-"),
        this.getFormattedDate(_.get(policy, "personalInformation.dob", ""), "DD/MM/YYYY"),
        age,
        _.get(policy, "travel_coverage_type", "-"),
        utils.getFormattedAmount(_.get(policy, "amount.travel_amounts.ri_sum_assured", 0)),
        utils.getFormattedAmount(_.get(policy, "amount.travel_amounts.si_retn", 0)),
        ri_code,
        utils.getFormattedAmount(_.get(policy, "amount.travel_amounts.ri_premium_usd", 0)),
        utils.getFormattedAmount(_.get(policy, "amount.travel_amounts.si_retn_premium_usd", 0)),
        mapfre_ri_code
      ];
      dataArr.push(arr);
    });

    try{
      const beyontec_res = await TravelService.getPoliciesBeyontec(this.state.startDate, this.state.endDate);
      const beyontec_data = beyontec_res.data.data;
      beyontec_data.forEach((policy, ind) => {
        let arr = [
          this.getFormattedDateTime(policy["POLICY ISSUED DATE"], false),
          policy["POLICY NUMBER"],
          policy["ENDORSEMENT NO"],
          this.getFormattedDateTime(policy["COVERAGE FROM DATE"]),
          this.getFormattedDateTime(policy["COVERAGE TO DATE"]),
          policy["INSURED NAME"],
          policy["TRAVELLER NAME"],
          policy["MOBILE_NO"],
          policy["PASSPORT"],
          policy["NO_OF_DAYS"],
          this.getFormattedDate(policy["DOB"], false, "DD-MM-YYYY"),
          policy["AGE"],
          policy["GEO_ARE"],
          utils.getFormattedAmount(policy["SI_RI"]),
          utils.getFormattedAmount(policy["SI_RETN"]),
          policy["RI_REF_CODE"],
          utils.getFormattedAmount(Math.abs(policy["RI_PREM_USD"])),
          utils.getFormattedAmount(Math.abs(policy["SPL_RETN_PREM_USD"])),
          policy["MAPFRE_RI_CODE"]
        ];
        dataArr.push(arr);
      });
    }catch(e){
    }finally{
      const sortedData = dataArr.sort((a, b) => {
        const date_a = moment(a[0], 'MMM DD, YYYY LTS');
        const date_b = moment(b[0], 'MMM DD, YYYY LTS');
        if(date_a.isBefore(date_b)){
          return -1;
        }
      })

      sortedData.forEach((arr) => {
        csvArr.push(arr.map(value => `"${value}"`).join(","));
      })
      const csvFile = new Blob([csvArr.join("\n")], { type: "text/csv" });
      saveAs(csvFile, "bordereaux-report-outbound-travel-insurance.csv");
      this.setState({ isDownloadingBorderaux: false, showProcessPopup: false });
    }

  }

  handleStartDateChange = (date) => {
    const startDate = new Date(date.setHours(0, 0, 0, 0)).getTime();
    this.setState({ startDate });
  }

  handleEndDateChange = (date) => {
    const endDate = new Date(date.setHours(23, 59, 59, 999)).getTime();
    this.setState({ endDate });
  }

  render() {
    return (
      <div className="d-flex">
        <Navigation />
        <div className="container-fluid">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Paper className="p-2 sq-shadow d-flex">
                <Typography><Link to={`/home`}>Home</Link></Typography>
                <Typography className="mr-2 ml-2">/</Typography>
                <Typography>Outbound Travel Report</Typography>
              </Paper>
            </Grid>

            <Grid item md={3} xs={12}>
              <AffiliateSelect onChange={() => { }} />
            </Grid>
            <Grid item md={3} xs={12}>
              <TextField
                variant="outlined"
                select
                label="Source of Business"
                fullWidth
                name="sob"
                onChange={this.handleChange}
                value={this.state.sob}
                SelectProps={{
                  MenuProps: {
                    anchorOrigin: {
                      vertical: "top",
                      horizontal: "left"
                    },
                    getContentAnchorEl: null
                  },
                }}
              >
                {
                  Object.values(REPORT_SOB_OPTIONS).map((option, index) => <MenuItem key={index} value={option}>{option}</MenuItem>)
                }
              </TextField>
            </Grid>
            <Grid item md={3} xs={12}>
              <TextField
                variant="outlined"
                select
                label="Region"
                fullWidth
                name="region"
                onChange={this.handleChange}
                value={this.state.region}
                SelectProps={{
                  MenuProps: {
                    anchorOrigin: {
                      vertical: "top",
                      horizontal: "left"
                    },
                    getContentAnchorEl: null
                  },
                }}
              >
                <MenuItem value="All">All</MenuItem>
                {
                  RegionList.map((region) => 
                    <MenuItem key={region.id} value={region.id}>{region.name}</MenuItem>
                  )
                }
              </TextField>
            </Grid>
            <Grid item md={3} xs={12}>
              <TextField
                variant="outlined"
                select
                label="Branch"
                fullWidth
                name="branch"
                onChange={this.handleChange}
                value={this.state.branch}
                SelectProps={{
                  MenuProps: {
                    anchorOrigin: {
                      vertical: "top",
                      horizontal: "left"
                    },
                    getContentAnchorEl: null
                  },
                }}
              >
                <MenuItem value="All">All</MenuItem>
                {
                  BranchList.map((branch) => 
                    <MenuItem key={branch.id} value={branch.id}>{branch.name}</MenuItem>
                  )
                }
              </TextField>
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={3}>
              <TextField
                variant="outlined"
                select
                label="Status"
                fullWidth
                name="status"
                onChange={this.handleChange}
                value={this.state.status}
                SelectProps={{
                  MenuProps: {
                    anchorOrigin: {
                      vertical: "top",
                      horizontal: "left"
                    },
                    getContentAnchorEl: null
                  },
                }}
              >
                {
                  Object.values(REPORT_STATUS_OPTIONS).map((option, index) => <MenuItem key={index} value={option}>{option}</MenuItem>)
                }
              </TextField>
            </Grid>

            <Grid item xs={3}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                  label="Start Date"
                  inputVariant="outlined"
                  animateYearScrolling
                  name="startDate"
                  clearable
                  fullWidth
                  value={this.state.startDate}
                  onChange={this.handleStartDateChange}
                  format="dd-MM-yyyy"
                />
              </MuiPickersUtilsProvider>
            </Grid>

            <Grid item xs={3}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                  label="End Date"
                  inputVariant="outlined"
                  animateYearScrolling
                  name="endDate"
                  clearable
                  fullWidth
                  value={this.state.endDate}
                  onChange={this.handleEndDateChange}
                  format="dd-MM-yyyy"
                />
              </MuiPickersUtilsProvider>
            </Grid>

            <Grid item xs={3} className="d-flex align-items-center">
              <Button variant="contained" color="primary" disabled={this.state.isLoading} onClick={this.handleSearch}>{this.state.isLoading ? "Searching . . ." : "Search"}</Button>
            </Grid>
          </Grid>

          {
            (this.state.showTable && this.state.data.length > 0) &&
            <Grid container spacing={2} className="d-flex">
              <Grid item xs={12}>
                <Paper className="sq-shadow overflow-auto">
                  <div className="dash-table-container">
                    <Table>
                      <TableHead>
                        <TableRow>
                          {
                            this.state.headings.map((heading, ind) =>
                              <TableCell key={ind}>{heading}</TableCell>
                            )
                          }
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {
                          this.state.data.map((data, ind) => {
                            return (
                              <TableRow key={ind}>
                                <TableCell>{ind + 1}</TableCell>
                                <TableCell>{data._id}</TableCell>
                                <TableCell className="white-space-pre"> {this.getFormattedDateTime(data.startDate, false, "DD-MMM-YYYY")} </TableCell>
                                <TableCell className="white-space-pre"> {data.travel_start_date ? this.getFormattedDate(data.travel_start_date) : this.getFormattedDateTime(data.outbound_travel_start_date, false, "DD-MM-YYYY")} </TableCell>
                                <TableCell className="white-space-pre"> {data.travel_end_date ? this.getFormattedDate(data.travel_end_date) : this.getFormattedDateTime(data.outbound_travel_end_date, false, "DD-MM-YYYY")} </TableCell>
                                <TableCell> {data.travel_duration_days || data.outbound_generate_quotation_request?.CumulativeDays} </TableCell>
                                <TableCell> {data.travel_coverage_type || data.outbound_coverage_name} </TableCell>
                                <TableCell className="text-capitalize"> {data.travellers_type} </TableCell>
                                <TableCell>
                                  {
                                    Array.isArray(data.travellers) && data.travellers.map((traveller, bind) =>
                                      <div key={bind} className={`${bind === data.travellers.length - 1 ? "mb-0" : "mb-2"}`}>
                                        <div className="white-space-pre" > Traveller No : {bind + 1} </div>
                                        <div className="white-space-pre" > Name : {traveller.name ? traveller.name : `${traveller.first_name} ${traveller.last_name}`} </div>
                                        <div className="white-space-pre" > Passport No : {traveller.passport_no} </div>
                                        <div className="white-space-pre" > Relation : {traveller.relation || traveller.inbound_relation_detail} </div>
                                      </div>
                                    )
                                  }
                                </TableCell>
                                <TableCell> {data.amount && utils.getFormattedAmount(data.amount.total)} OMR</TableCell>
                                <TableCell> {data.personalInformation.fullName} </TableCell>
                                <TableCell>{data.personalInformation.email}</TableCell>
                                <TableCell>{data.personalInformation.mobileNumber}</TableCell>
                                <TableCell>{data.amount ? utils.getFormattedAmount(helpers.getBasePrice(data.amount)) : 0} OMR</TableCell>
                                <TableCell>{data.amount ? helpers.getDiscountCode(data.amount) : "-"}</TableCell>
                                <TableCell>{data.amount ? utils.getFormattedAmount(helpers.getDiscount(data.amount)) : 0} OMR</TableCell>
                                <TableCell>{data.amount ? utils.getFormattedAmount(data.amount.policy_fee) : 0} OMR</TableCell>
                                <TableCell>{data.amount ? utils.getFormattedAmount(data.amount.emergency_fund_fee) : 0} OMR</TableCell>
                                <TableCell>{data.amount ? utils.getFormattedAmount(data.amount.government_fee) : 0} OMR</TableCell>
                                <TableCell>{data.amount ? utils.getFormattedAmount(data.amount.total) : 0} OMR</TableCell>
                                <TableCell>{data.amount ? utils.getFormattedAmount(data.amount.vat) : 0} OMR</TableCell>
                                <TableCell>{data.amount ? utils.getFormattedAmount(data.amount.bmi_value) : 0} OMR</TableCell>
                                <TableCell> {data.outbound_product_name || data.plan} </TableCell>
                                <TableCell> {data.payments && data.payments.isPaid ? "Paid" : "Unpaid"} </TableCell>
                                <TableCell> {data.status} </TableCell>
                                <TableCell> {data.outbound_coverage_id && data.amount.travel_amounts.net_premium ? data.amount.travel_amounts.net_premium : "-"} </TableCell>
                                <TableCell> {data.outbound_coverage_id && data.amount.travel_amounts.net_premium ? helpers.convertUSDToOMR(data.amount.travel_amounts.net_premium) : "-"} </TableCell>
                                <TableCell> {data.outbound_coverage_id && data.amount.travel_amounts.loading ? data.amount.travel_amounts.loading : "-"} </TableCell>
                                <TableCell> {data.outbound_coverage_id && data.amount.travel_amounts.loading ? helpers.convertUSDToOMR(data.amount.travel_amounts.loading) : "-"} </TableCell>
                                <TableCell> {data.outbound_coverage_id && data.amount.travel_amounts.gross_premium ? Number(data.amount.travel_amounts.gross_premium).toFixed(3) : "-"} </TableCell>
                                <TableCell> {data.outbound_coverage_id && data.amount.travel_amounts.gross_premium ? helpers.convertUSDToOMR(data.amount.travel_amounts.gross_premium) : "-"} </TableCell>
                                {
                                  ["Active"].includes(this.state.status) &&
                                  <>
                                    <TableCell>
                                      {
                                        data.payments.mode === "Link" ? "Card" : data.payments.mode
                                      }
                                    </TableCell>
                                    <TableCell>
                                      {
                                        data.payments.mode !== "Cash" ? data.payments.payment_id : "-"
                                      }
                                    </TableCell>
                                  </>
                                }
                                <TableCell>{data.company_affiliate_name ? data.company_affiliate_name : "-"}</TableCell>
                                <TableCell>{data.affiliate_partner_name ? data.affiliate_partner_name : "-"}</TableCell>
                                <TableCell>{data.channel_name ? data.channel_name : "-"}</TableCell>
                                <TableCell>{data.sub_channel_name ? data.sub_channel_name : "-"}</TableCell>
                                <TableCell>{data.affiliate_employee_id ? data.affiliate_employee_id : "-"}</TableCell>
                                <TableCell>{data.affiliate_name ? data.affiliate_name : "AXSS"}</TableCell>
                                <TableCell>{data.affiliate_email ? data.affiliate_email : "-"}</TableCell>
                                <TableCell>{data.affiliate_employee_designation ? data.affiliate_employee_designation : "-"}</TableCell>
                                <TableCell>{data.branch_id ? data.branch_id : "-"}</TableCell>
                                <TableCell>{data.branch_name ? data.branch_name : "-"}</TableCell>
                                <TableCell>{data.region_name ? data.region_name : "-"}</TableCell>
                                <TableCell>{data.branch_manager_name ? data.branch_manager_name : "-"}</TableCell>
                                <TableCell>{data.branch_manager_email ? data.branch_manager_email : "-"}</TableCell>
                                <TableCell>{data.affiliate_phone ? data.affiliate_phone : "-"}</TableCell>
                                <TableCell>{data.comment ? data.comment : "-"}</TableCell>

                                <TableCell>
                                  <Link to={`/policies/${data._id}`} className="table-eye-link">
                                    <Eye />
                                  </Link>
                                </TableCell>
                              </TableRow>
                            )
                          })
                        }
                        <TableRow>
                          <TablePagination
                            rowsPerPage={this.state.rowsPerPage}
                            rowsPerPageOptions={[10, 25, 50]}
                            page={this.state.page}
                            count={this.state.totalRecords}
                            colSpan={0}
                            onChangePage={this.handleChangePage}
                            onChangeRowsPerPage={this.handleChangeRowsPerPage}
                          />
                        </TableRow>
                      </TableBody>
                    </Table>
                  </div>
                </Paper>
              </Grid>

              <Grid item xs={12}>
                <Button className="mr-1" onClick={this.downloadData} variant="contained" color="primary" disabled={this.state.isDownloading}>{this.state.isDownloading ? "Downloading . . ." : "Download csv"}</Button>
                {/* <Button className="mr-1" onClick={this.downloadRiReportData} variant="contained" color="primary" disabled={this.state.isDownloadingRi}>{this.state.isDownloadingRi ? "Downloading . . ." : "Download RI Report"}</Button>
                <Button className="mr-1" onClick={this.downloadBordereauxReportData} variant="contained" color="primary" disabled={this.state.isDownloadingBorderaux}>{this.state.isDownloadingBorderaux ? "Downloading . . ." : "Download Bordereaux Report"}</Button> */}
              </Grid>

            </Grid>
          }
          {
            (this.state.showTable && this.state.data.length === 0) &&
            <Paper className="q-shadow mt-3 pt-5 pb-5 d-flex align-items-center justify-content-center">
              <Typography variant="h5"> No Data Found </Typography>
            </Paper>
          }
        </div>
      </div >
    )
  }
}
export default withRouter(OutboundTravelReport);