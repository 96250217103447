import * as firebase from "firebase/app";
import "firebase/auth";
import "firebase/firebase-firestore";
import config from "./config";
import axios from "axios";
import { BehaviorSubject } from "rxjs";
import SuperadminService from "./superadmins";

// Live Config

// var firebaseConfig = {
//   apiKey: "AIzaSyBwutDh2ZU-EeK6J1k3Ds_bdJq9kR5uy1k",
//   authDomain: "dhofar-ed9ec.firebaseapp.com",
//   databaseURL: "https://dhofar-ed9ec.firebaseio.com",
//   projectId: "dhofar-ed9ec",
//   storageBucket: "dhofar-ed9ec.appspot.com",
//   messagingSenderId: "187505030183",
//   appId: "1:187505030183:web:f8d70a86e5ff6182c8ca02",
//   measurementId: "G-5VJBFBHY5J"
// };

// Dev Config

var firebaseConfig = {
  apiKey: "AIzaSyB9NfJIRZiPHlJwrC20yO-T3mUF2IBMSN8",
  authDomain: "dhofar-dev.firebaseapp.com",
  projectId: "dhofar-dev",
  storageBucket: "dhofar-dev.appspot.com",
  messagingSenderId: "468650796291",
  appId: "1:468650796291:web:ca8254f7401e57ea7eb536",
  measurementId: "G-D4ZE20JRPN"
};

const app = firebase.initializeApp(firebaseConfig);
const app2 = firebase.initializeApp(firebaseConfig, "app2");
const db = firebase.firestore(app);
let auth = {};

auth.notification$ = new BehaviorSubject({
  showSnackbar: false, 
  snackBarMessage: "", 
  messageType: ""
});

auth.sendNotification = (message, messageType) => {
  auth.notification$.next({
    showSnackbar: true, 
    snackBarMessage: message, 
    messageType: messageType
  })
}

auth.login = async (email, password) => {
  await firebase.auth().signInWithEmailAndPassword(email, password);
  const firebaseAuth = firebase.auth();
  return new Promise((resolve, reject) => {
    firebaseAuth.onAuthStateChanged((user) => {
      if(user){
        const loginUrl = `${config.baseUrl}/login`;
        axios({
          url : loginUrl,
          method : "POST",
          data : {
            role : "super-admin",
            uid : user.uid
          }
        }).then((loginRes) => {
          const loginData = loginRes.data;
          const token = loginData.token;
          localStorage.setItem("token", token);
          SuperadminService.getUser(user.uid, token).then((userRes) => {
            const userData = userRes.data.data;
            if(userData.is_active){
              localStorage.setItem("permissions", JSON.stringify(userData.permissions));
              window.dispatchEvent(new Event("storage"));
              resolve(true);
            }else{
              reject(false);
              firebaseAuth.signOut();
            }
          })
        }).catch((e) => {
          reject(false);
          firebaseAuth.signOut();
        })
      }
      else{
        reject(false);
      }
    });
  });
}

auth.logout = () => {
  sessionStorage.clear();
  localStorage.clear();
  return new Promise((resolve) => {
    firebase.auth().signOut().then(() => {
      resolve(true);
    });
  })
}

auth.isLoggedIn = () => {
  const firebaseAuth = firebase.auth();
  return new Promise((resolve, reject) => {
    if(localStorage.getItem("token")){
      firebaseAuth.onAuthStateChanged((user) => {
        if(user){
          SuperadminService.getUser(user.uid).then((userRes) => {
            const userData = userRes.data.data;
            if(userData.is_active){
              localStorage.setItem("permissions", JSON.stringify(userData.permissions));
              window.dispatchEvent(new Event("storage"));
              resolve(true);
            }else{
              reject(false);
              firebaseAuth.signOut();
            }
          })
        }
        else{
          reject(false);
        }
      });
    }
    else{
      reject(false);
      firebaseAuth.signOut();
    }
  });
}

auth.updatePassword = async (oldPassword , newPassword) => {
  const firebaseAuth = firebase.auth();

  return new Promise((resolve, reject) => {
    const credential = firebase.auth.EmailAuthProvider.credential(
      firebaseAuth.currentUser.email,
      oldPassword
    );
    firebaseAuth.currentUser.reauthenticateWithCredential(credential).then(() => {
      firebaseAuth.currentUser.updatePassword(newPassword).then(() => {
        resolve({ passwordUpdated : true });
      }).catch((e) => {
        reject(e);
      })
    }).catch((e) => {
      reject(e);
    })
  })
}

auth.getEmail = async () => {
  const firebaseAuth = firebase.auth();
  return new Promise((resolve) => {
    firebaseAuth.onAuthStateChanged((user) => {
      if(user){
        resolve(user.email);
      }
    })
  })
}

auth.updateEmail = async (email, password) => {
  const firebaseAuth = firebase.auth();

  return new Promise((resolve, reject) => {
    const credential = firebase.auth.EmailAuthProvider.credential(
      firebaseAuth.currentUser.email,
      password
    );
    firebaseAuth.currentUser.reauthenticateWithCredential(credential).then(() => {
      firebaseAuth.currentUser.updateEmail(email).then(() => {
        const uid = firebaseAuth.currentUser.uid;
        db.collection("users").doc(uid).update({ email : email }).then(() => resolve({ emailUpdated : true }))
      }).catch((e) => {
        reject(e);
      })
    }).catch((e) => {
      reject(e);
    })
  });
}

auth.sendResetMail = async (mail) => {
  const firebaseAuth = firebase.auth();
  return new Promise((resolve, reject) => {
    firebaseAuth.sendPasswordResetEmail(mail).then(() => {
      resolve({ mailSent : true });
    }).catch((e) => {
      reject(e);
    })
  });
}

auth.register = async (email, password) => {
  const res = await firebase.auth(app2).createUserWithEmailAndPassword(email, password);
  return res;
}

auth.logout2 = () => {
  return new Promise((resolve) => {
    firebase.auth(app2).signOut().then(() => {
      resolve(true);
    });
  })
}

export default auth;