import { Grid, Paper, Tab, Tabs, Typography } from "@material-ui/core";
import React from "react";
import { Link } from "react-router-dom";
import Navigation from "../common/Navigation";
import ModifyPremiumsHealth from "./ModifyPremiumsHealth";
import ModifyPremiumsHome from "./ModifyPremiumsHome";
import ModifyPremiumsLife from "./ModifyPremiumsLife";
import ModifyPremiumsTravel from "./ModifyPremiumsTravel";
import ModifyPremiumRiyati from "./ModifyPremiumRiyati";

class ModifyPremiums extends React.Component {

    insurance_types = [
        { insurance_type: "Life", component: ModifyPremiumsLife },
        { insurance_type: "Health", component: ModifyPremiumsHealth },
        { insurance_type: "Travel", component: ModifyPremiumsTravel },
        { insurance_type: "Home", component: ModifyPremiumsHome },
        {insurance_type: "Riyati", component: ModifyPremiumRiyati}
    ]

    state = {
        insurance_type: 0,
        plan_type: ""
    }

    constructor(props){
        super(props);
        let insurance_type = props.match.params.insurance_type ? props.match.params.insurance_type : "Life";
        insurance_type = this.insurance_types.findIndex(insurance => insurance.insurance_type == insurance_type);
        this.state.insurance_type = insurance_type
    }

    componentWillReceiveProps(props) {
        let insurance_type = props.match.params.insurance_type ? props.match.params.insurance_type : "Life";
        this.loadDetails(insurance_type)
    }

    loadDetails(insurance_type) {
        insurance_type = this.insurance_types.findIndex(insurance => insurance.insurance_type == insurance_type);
        this.setState({ insurance_type })
    }

    changeInsuranceType = (e, value) => {
        this.props.history.push(`/modify-premiums/${this.insurance_types[value].insurance_type}`);
    }

    render() {
        return (
            <div className="d-flex">
                <Navigation />
                <div className="container-fluid">
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Paper className="p-2 sq-shadow d-flex">
                                <Typography><Link to="/">Home</Link></Typography>
                                <Typography className="mr-2 ml-2">/</Typography>
                                <Typography>Modify Premiums</Typography>
                            </Paper>
                        </Grid>
                        <Grid item xs={12}>
                            <Paper className="sq-shadow">
                                <Tabs variant="fullWidth" value={this.state.insurance_type} onChange={this.changeInsuranceType}>
                                    {
                                        this.insurance_types.map((insurance, ind) => (<Tab label={insurance.insurance_type} key={ind} />))
                                    }
                                </Tabs>
                            </Paper>
                        </Grid>
                        
                        {
                            this.insurance_types.map((insurance, ind) => {
                                if (ind === this.state.insurance_type) {
                                    return (<insurance.component key={ind} />)
                                } else {
                                    return "";
                                }
                            })
                        }

                    </Grid>
                </div>
            </div>
        )
    }
}
export default ModifyPremiums;