import { Button, Grid, Paper, TextField, Typography } from "@material-ui/core";
import React from "react";
import PlanService from "./../../../services/plans";
import _ from "lodash";
import Snackbar from "../../common/Snackbar";

class HomePlan extends React.Component {

    state = {
        
        section_1_premium_rate:0,
        section_1_premium_minimum:0,
        section_2_premium_rate:0,
        section_2_premium_minimum:0,
        section_3_premium_rate:0,
        section_3_premium_minimum:0,
        section_4_premium:0,
        section_5_premium_rate:0,
        section_5_premium_minimum:0,
        section_6_premium_personal:0,
        section_6_premium_owner:0,
        section_6_premium_tenant:0,
        section_7_premium:0,
        section_8_premium:0,

        isValidPersonalInfo: {
            section_1_premium_rate: undefined,
            section_1_premium_minimum: undefined,
            section_2_premium_rate: undefined,
            section_2_premium_minimum: undefined,
            section_3_premium_rate: undefined,
            section_3_premium_minimum: undefined,
            section_4_premium: undefined,
            section_5_premium_rate: undefined,
            section_5_premium_minimum: undefined,
            section_6_premium_personal: undefined,
            section_6_premium_owner: undefined,
            section_6_premium_tenant: undefined,
            section_7_premium: undefined,
            section_8_premium: undefined,
            
        },
        loading: false,
        showSnackbar: false,
        snackBarMessage: "",
        messageType: "",
    }

    handleCloseSnackbar = () => {
        this.setState({ showSnackbar: false })
    }

    componentDidMount() {
        this.loadDetails()
    }

    onBlur = (name, value , e) => {
        const re = /^[0-9\b]+$/;
        let isValidPersonalInfo = { ...this.state.isValidPersonalInfo };
        if (value === "") {
            isValidPersonalInfo[name] = false;
        }
        else if(value === '' || re.test(value)) {
            this.setState({value: value});
            isValidPersonalInfo[name] = false;
        }
        
        else if(name.includes("rate")){
            if(value > 100 || value < 0 )
            {
                this.setState({value: value});
                isValidPersonalInfo[name] = false;
            }
        }
        else {
            isValidPersonalInfo[name] = true;
        }
        this.setState({ isValidPersonalInfo })
    }

    async loadDetails() {
        let home_plan_price = await PlanService.getPlanPrices("home");
        let section_1_premium_rate = _.get(home_plan_price, "data.data.home_plan.section_1_premium_rate", 0);
        let section_1_premium_minimum = _.get(home_plan_price, "data.data.home_plan.section_1_premium_minimum", 0);
        let section_2_premium_rate = _.get(home_plan_price, "data.data.home_plan.section_2_premium_rate", 0);
        let section_2_premium_minimum = _.get(home_plan_price, "data.data.home_plan.section_2_premium_minimum", 0);
        let section_3_premium_rate = _.get(home_plan_price, "data.data.home_plan.section_3_premium_rate", 0);
        let section_3_premium_minimum = _.get(home_plan_price, "data.data.home_plan.section_3_premium_minimum", 0);
        let section_4_premium = _.get(home_plan_price, "data.data.home_plan.section_4_premium", 0);
        let section_5_premium_rate = _.get(home_plan_price, "data.data.home_plan.section_5_premium_rate", 0);
        let section_5_premium_minimum = _.get(home_plan_price, "data.data.home_plan.section_5_premium_minimum", 0);

        let section_6_premium_personal = _.get(home_plan_price, "data.data.home_plan.section_6_premium_personal", 0);
        let section_6_premium_owner = _.get(home_plan_price, "data.data.home_plan.section_6_premium_owner", 0);
        let section_6_premium_tenant = _.get(home_plan_price, "data.data.home_plan.section_6_premium_tenant", 0);
        
        let section_7_premium = _.get(home_plan_price, "data.data.home_plan.section_7_premium", 0);
        let section_8_premium = _.get(home_plan_price, "data.data.home_plan.section_8_premium", 0);


        this.onBlur("section_1_premium_rate", section_1_premium_rate);
        this.onBlur("section_1_premium_minimum", section_1_premium_minimum);
        this.onBlur("section_2_premium_minimum", section_2_premium_minimum);
        this.onBlur("section_2_premium_rate", section_2_premium_rate);
        this.onBlur("section_3_premium_minimum", section_3_premium_minimum);
        this.onBlur("section_3_premium_rate", section_3_premium_rate);
        this.onBlur("section_4_premium", section_4_premium);
        this.onBlur("section_5_premium_minimum", section_5_premium_minimum);
        this.onBlur("section_5_premium_rate", section_5_premium_rate);
        this.onBlur("section_6_premium_personal", section_6_premium_personal);
        this.onBlur("section_6_premium_owner", section_6_premium_owner);
        this.onBlur("section_6_premium_tenant", section_6_premium_tenant);
        this.onBlur("section_7_premium", section_7_premium);
        this.onBlur("section_8_premium", section_8_premium);

        this.setState({ section_1_premium_rate,section_1_premium_minimum,section_2_premium_minimum,section_2_premium_rate,section_3_premium_minimum,section_3_premium_rate,section_4_premium,section_5_premium_minimum,section_5_premium_rate,section_6_premium_personal,section_6_premium_owner,section_6_premium_tenant,section_7_premium,section_8_premium });

    }

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    saveHomePlan = async () => {
        this.setState({ loading: true })
        let status = false;
        try {
            let response = await PlanService.updatePlanPremium("home", "Home Plan", { section_1_premium_rate:this.state.section_1_premium_rate,
                section_1_premium_minimum:this.state.section_1_premium_minimum,
                section_2_premium_rate:this.state.section_2_premium_rate,
                section_2_premium_minimum:this.state.section_2_premium_minimum,
                section_3_premium_rate:this.state.section_3_premium_rate,
                section_3_premium_minimum:this.state.section_3_premium_minimum,
                section_4_premium:this.state.section_4_premium,
                section_5_premium_rate:this.state.section_5_premium_rate,
                section_5_premium_minimum:this.state.section_5_premium_minimum,
                section_6_premium_personal:this.state.section_6_premium_personal,
                section_6_premium_owner:this.state.section_6_premium_owner,
                section_6_premium_tenant:this.state.section_6_premium_tenant,
                section_7_premium:this.state.section_7_premium,
                section_8_premium:this.state.section_8_premium })
            if (response.data.status) {
                status = true
            }

        } catch (error) { }

        this.setState({
            loading: false,
            showSnackbar: true,
            snackBarMessage: status ? "Home Plan premium updated." : "Something went wrong.",
            messageType: status ? "success" : "danger"
        });

    }

    render() {
        return (
            <React.Fragment>
                <Snackbar
                    showSnackbar={this.state.showSnackbar}
                    messageType={this.state.messageType}
                    snackBarMessage={this.state.snackBarMessage}
                    onCloseSnackbar={this.handleCloseSnackbar}
                />
                <Grid item xs={12} md={4} sm={6}>
                    <Paper className="sq-shadow p-2">
                        <Typography><strong>Home Plan</strong></Typography>
                        <br />
                        
                        
                        <TextField
                            type="number"
                            fullWidth
                            value={this.state.section_1_premium_rate}
                            label="Section 1 Premium Rate (%)" variant="outlined"
                            onBlur={e => { this.onBlur("section_1_premium_rate", e.target.value) }}
                            name="section_1_premium_rate"
                            onChange={this.handleChange}
                        />
                        <br />
                        <br />
                        <TextField
                            type="number"
                            fullWidth
                            value={this.state.section_1_premium_minimum}
                            label="Section 1 Minimum Premium" variant="outlined"
                            onBlur={e => { this.onBlur("section_1_premium_minimum", e.target.value) }}
                            name="section_1_premium_minimum"
                            onChange={this.handleChange}
                        />
                        <br />
                        <br />
                        <TextField
                            type="number"
                            fullWidth
                            value={this.state.section_2_premium_rate}
                            label="Section 2 Premium Rate (%)" variant="outlined"
                            onBlur={e => { this.onBlur("section_2_premium_rate", e.target.value) }}
                            name="section_2_premium_rate"
                            onChange={this.handleChange}
                        />
                        <br />
                        <br />
                        <TextField
                            type="number"
                            fullWidth
                            value={this.state.section_2_premium_minimum}
                            label="Section 2 Minimum Premium" variant="outlined"
                            onBlur={e => { this.onBlur("section_2_premium_minimum", e.target.value) }}
                            name="section_2_premium_minimum"
                            onChange={this.handleChange}
                        />
                        <br />
                        <br />
                        <TextField
                            type="number"
                            fullWidth
                            value={this.state.section_3_premium_rate}
                            label="Section 3 Premium Rate (%)" variant="outlined"
                            onBlur={e => { this.onBlur("section_3_premium_rate", e.target.value) }}
                            name="section_3_premium_rate"
                            onChange={this.handleChange}
                        />
                        <br />
                        <br />
                        <TextField
                            type="number"
                            fullWidth
                            value={this.state.section_3_premium_minimum}
                            label="Section 3 Minimum Premium" variant="outlined"
                            onBlur={e => { this.onBlur("section_3_premium_minimum", e.target.value) }}
                            name="section_3_premium_minimum"
                            onChange={this.handleChange}
                        />
                        <br />
                        <br />
                        <TextField
                            type="number"
                            fullWidth
                            value={this.state.section_4_premium}
                            label="Section 4 Premium" variant="outlined"
                            onBlur={e => { this.onBlur("section_4_premium", e.target.value) }}
                            name="section_4_premium"
                            onChange={this.handleChange}
                        />
                        <br />
                        <br />
                        <TextField
                            type="number"
                            fullWidth
                            value={this.state.section_5_premium_rate}
                            label="Section 5 Premium Rate (%)" variant="outlined"
                            onBlur={e => { this.onBlur("section_5_premium_rate", e.target.value) }}
                            name="section_5_premium_rate"
                            onChange={this.handleChange}
                        />
                        <br />
                        <br />
                        <TextField
                            type="number"
                            fullWidth
                            value={this.state.section_5_premium_minimum}
                            label="Section 5 Minimum Premium" variant="outlined"
                            onBlur={e => { this.onBlur("section_5_premium_minimum", e.target.value) }}
                            name="section_5_premium_minimum"
                            onChange={this.handleChange}
                        />
                        <br />
                        <br />
                        <TextField
                            type="number"
                            fullWidth
                            value={this.state.section_6_premium_personal}
                            label="Section 6 Personal Liability " variant="outlined"
                            onBlur={e => { this.onBlur("section_6_premium_personal", e.target.value) }}
                            name="section_6_premium_personal"
                            onChange={this.handleChange}
                        />
                        <br />
                        <br />
                        <TextField
                            type="number"
                            fullWidth
                            value={this.state.section_6_premium_owner}
                            label="Section 6 Premium Liability as Owner" variant="outlined"
                            onBlur={e => { this.onBlur("section_6_premium_owner", e.target.value) }}
                            name="section_6_premium_owner"
                            onChange={this.handleChange}
                        />
                        <br />
                        <br />
                        <TextField
                            type="number"
                            fullWidth
                            value={this.state.section_6_premium_tenant}
                            label="Section 6 Premium Liability as Tenant" variant="outlined"
                            onBlur={e => { this.onBlur("section_6_premium_tenant", e.target.value) }}
                            name="section_6_premium_tenant"
                            onChange={this.handleChange}
                        />
                        <br />
                        <br />
                        <TextField
                            type="number"
                            fullWidth
                            value={this.state.section_7_premium}
                            label="Section 7 Premium" variant="outlined"
                            onBlur={e => { this.onBlur("section_7_premium", e.target.value) }}
                            name="section_7_premium"
                            onChange={this.handleChange}
                        />
                        <br />
                        <br />
                        <TextField
                            type="number"
                            fullWidth
                            value={this.state.section_8_premium}
                            label="Section 8 Premium" variant="outlined"
                            onBlur={e => { this.onBlur("section_8_premium", e.target.value) }}
                            name="section_8_premium"
                            onChange={this.handleChange}
                        />
                        <br />
                        <br />
                        
                        <Button onClick={this.saveHomePlan} disabled={!this.state.isValidPersonalInfo || this.state.loading} variant="contained" color="primary">
                            {this.state.loading ? "Processing..." : "Save"}
                        </Button>
                    </Paper>
                </Grid>
            </React.Fragment>
        )
    }
}
export default HomePlan;