import React from "react";
import Navigation from "../common/Navigation";
import {Paper, Tabs, Tab, Typography} from "@material-ui/core";
import {Link, withRouter} from "react-router-dom";
import SetTarget from "./SetTarget";
import ViewTarget from "./ViewTarget";

class Targets extends React.Component{

  state = {
    tabIndex : 0
  }

  handleChange = (e, value) => {
    this.setState({ tabIndex : value })
  }

  componentDidMount(){
    
  }
  render(){
    return(
      <div className="d-flex">
        <Navigation />
        <div className="container-fluid">
          <Paper className="p-2 sq-shadow mb-3 d-flex">
            <Typography><Link to="/">Home</Link></Typography>
            <Typography className="mr-2 ml-2">/</Typography>
            <Typography><Link to={`${process.env.PUBLIC_URL}/affiliator`}>Affiliators</Link></Typography>
            <Typography className="mr-2 ml-2">/</Typography>
            <Typography>Targets</Typography>
          </Paper>
          <Paper className="sq-shadow">
            <Tabs
              value={this.state.tabIndex}
              indicatorColor = "primary"
              onChange = {this.handleChange}
              variant = "fullWidth"
            >
              <Tab label="View Target" />
              <Tab label="Set Target" /> 
            </Tabs>
          </Paper>
          {this.state.tabIndex === 0 && <ViewTarget />}
          {this.state.tabIndex === 1 && <SetTarget />}
        </div>
      </div>
    )
  }
}
export default withRouter(Targets);