import axios from "axios";
import config from "./config";

let travel = {};

travel.getPolicies = async ({
    startDate,
    endDate,
    status,
    page,
    limit,
    travel_type,
    sob,
    region,
    branch
}) => {
    const centerId = sessionStorage.getItem("center_id");
    const url = `${config.baseUrl}/travel-policies`;
    let params = {
        startDate : startDate,
        endDate : endDate
    }
    if(status){
        params.status = status;
    }
    if(centerId){
        params.center = centerId;
    }
    if(page !== undefined){
        params.page = page;
        params.limit = limit;
    }
    if(sob){
        params.sob = sob;
    }
    if(region){
        params.region = region;
    }
    if(branch){
        params.branch = branch;
    }
    if(travel_type){
        params.travel_type = travel_type;
    }
    const res = await axios({
        url,
        params
    });
    return res;
};

travel.getPoliciesBeyontec = async (startDate, endDate) => {
    const url = `${config.baseUrl}/travel-policies-beyontec?startDate=${startDate}&endDate=${endDate}`;
    const res = await axios({ url });
    return res;
};

export default travel;