import React from "react";
import Navigation from "../common/Navigation";
import { Grid, Typography, Paper, Button } from "@material-ui/core";
import "./style.css";
import HomeService from "../../services/home";
import _ from "lodash";
import { withRouter } from "react-router";
import AffiliateSelect from "../common/AffiliateSelect";
import ChannelSelect from "../common/ChannelSelect";
import { PDFDownloadLink } from '@react-pdf/renderer';
import CountReport from "./CountReport";
import AffiliateService from "../../services/affiliates";



class Home extends React.Component {

  state = {
    data: {},
    health: {},
    life: {},
    travel: {},
    home: {},
    selectedAffiliter: '',
    selectedChannel: '',
    processing: true
  }

  getData = async () => {
    this.setState({processing : true, selectedAffiliter: "", selectedChannel: "" })
    const res = await HomeService.getHomeData();
    const data = res.data;

    const { health, life, travel, home } = data;
    this.setState({
      data,
      health,
      life,
      travel,
      home
    });

    let center = sessionStorage.getItem("center_id") ? sessionStorage.getItem("center_id") : "all";
    let affiliates = await AffiliateService.getListAllAffiliates();
    let findname = _.find(affiliates, { 'affiliateId': center });
    if (findname) {
      this.setState({
        selectedAffiliter: findname.name
      });
    }

    const channelId = sessionStorage.getItem("channel_id") ? sessionStorage.getItem("channel_id") : "all";
    let channels = await AffiliateService.getListAllChannels();
    let findChannelName = _.find(channels, { '_id': channelId });
    if (findChannelName) {
      this.setState({
        selectedChannel: findChannelName.name
      });
    }

    this.setState({ processing: false });
  }

  componentDidMount() {
    if (this.props.history.location.pathname == "/") {
      this.props.history.push('/home');
    }
    this.getData();
  }

  render() {
    return (
      <div className="d-flex">
        <Navigation />
        <div className="container-fluid">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Paper className="p-2 sq-shadow">
                <Typography>Home</Typography>
              </Paper>
            </Grid>
            <Grid item md={3} xs={12}>
              <AffiliateSelect onChange={this.getData} />
            </Grid>
            <Grid item md={3} xs={12}>
              <ChannelSelect onChange={this.getData} />
            </Grid>
            <Grid item md={3} xs={12}>
              {/* <PDFViewer
                children={
                  <CountReport 
                  data = {this.state.data}
                  selectedAffiliter = {this.state.selectedAffiliter}
                  selectedChannel = {this.state.selectedChannel}
                  />}
                className="fixed-top h-100 w-100"
              /> */}
              {
                this.state.processing === false ?
                <PDFDownloadLink
                  document={
                    <CountReport
                      data={this.state.data}
                      selectedAffiliter={this.state.selectedAffiliter}
                      selectedChannel={this.state.selectedChannel}
                    />
                  }
                  fileName="count-report"
                >
                  
                  {({ loading }) =>
                    
                    <Button variant="contained" color="primary" disabled={loading || this.state.processing} className="my-2 ml-2 mt-4">
                      
                      {this.state.processing ? "Processing..." : "Download"}
                    </Button>
                  }
                </PDFDownloadLink> : 
                <Button variant="contained" color="primary" disabled={true} className="my-2 ml-2">Processing...</Button>
              }
            </Grid>
          </Grid>

          <Grid container spacing={2} className="mt-2">
            {
              ["health", "life", "travel", "home"].map((insurance_type) =>
                <Grid item md={3} xs={12} key={insurance_type}>
                  <Paper className="sq-shadow p-2">
                    <Typography className="text-capitalize">
                      {insurance_type}
                    </Typography>
                  </Paper>

                  <Paper className="home-count-container sq-shadow p-0 bg-info text-white mt-3">

                    <div className="d-flex align-items-center justify-content-between p-2">
                      <Typography variant="subtitle1">
                        Pending
                      </Typography>
                    </div>

                    <div className="d-flex align-items-center justify-content-between border-top border-bottom py-1 px-2">
                      <Typography variant="subtitle1">
                        Paid
                      </Typography>
                      <Typography className="text-right">
                        {_.get(this.state[insurance_type], "paid_pending", 0)}
                      </Typography>
                    </div>

                    <div className="d-flex align-items-center justify-content-between py-1 px-2">
                      <Typography variant="subtitle1">
                        Unpaid
                      </Typography>
                      <Typography className="text-right">
                        {_.get(this.state[insurance_type], "unpaid_pending", 0)}
                      </Typography>
                    </div>

                    <div className="d-flex align-items-center justify-content-between border-top py-1 px-2">
                      <Typography variant="subtitle1">
                        Total
                      </Typography>
                      <Typography className="text-right">
                        {_.get(this.state[insurance_type], "pending", 0)}
                      </Typography>
                    </div>

                  </Paper>

                  <Paper className="home-count-container sq-shadow p-2 bg-success d-flex justify-content-between text-white mt-3 align-items-center">
                    <Typography variant="subtitle1">
                      Active
                    </Typography>
                    <Typography className="text-right">
                      {_.get(this.state[insurance_type], "active", 0)}
                    </Typography>
                  </Paper>

                  <Paper className="home-count-container sq-shadow p-2 bg-warning d-flex justify-content-between text-white mt-3 align-items-center">
                    <Typography variant="subtitle1">
                      Suspended
                    </Typography>
                    <Typography className="text-right">
                      {_.get(this.state[insurance_type], "suspend", 0)}
                    </Typography>
                  </Paper>

                  <Paper className="home-count-container sq-shadow p-2 bg-danger d-flex justify-content-between text-white mt-3 align-items-center">
                    <Typography variant="subtitle1">
                      Rejected
                    </Typography>
                    <Typography className="text-right">
                      {_.get(this.state[insurance_type], "rejected", 0)}
                    </Typography>
                  </Paper>

                  <Paper className="home-count-container sq-shadow p-2 bg-dark d-flex justify-content-between text-white mt-3 align-items-center">
                    <Typography variant="subtitle1">
                      Total
                    </Typography>
                    <Typography className="text-right">
                      {_.get(this.state[insurance_type], "total", 0)}
                    </Typography>
                  </Paper>

                </Grid>
              )
            }

          </Grid>
        </div>
      </div>
    )
  }
}
export default withRouter(Home);