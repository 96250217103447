import axios from "axios";
import config from "./config";

let home = {};

home.getPolicies = async ({
    startDate,
    endDate,
    status,
    page,
    limit,
    sob,
    region,
    branch
}) => {
    const centerId = sessionStorage.getItem("center_id");
    const url = `${config.baseUrl}/home-policies`;
    let params = {
        startDate : startDate,
        endDate : endDate
    }
    if(status){
        params.status = status;
    }
    if(centerId){
        params.center = centerId;
    }
    if(page !== undefined){
        params.page = page;
        params.limit = limit;
    }
    if(sob){
        params.sob = sob;
    }
    if(region){
        params.region = region;
    }
    if(branch){
        params.branch = branch;
    }
    const res = await axios({
        url,
        params
    });
    return res;
};

export default home;