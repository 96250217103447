import { Grid, Paper, Tab, Tabs } from "@material-ui/core";
import React from "react";
import IndividualMedical from "./Health/IndividualMedical";
import AffordableHealthPlan from "./Health/AffordableHealthPlan";
import UnifiedDomesticWorker from "./Health/UnifiedDomesticWorker";


class ModifyPremiumsHealth extends React.Component {

    life_plans = [
        { plan_type: "Individual Medical" },
        { plan_type: "Affordable Health Plan" },
        { plan_type: "Unified Domestic Worker" }
    ]

    state = {
        plan_type: 0
    }

    constructor(props){
        super(props);
        let plan_type = "Individual Medical";
        plan_type = this.life_plans.findIndex(plan => plan.plan_type === plan_type);
        this.state.plan_type = plan_type;
    }

    changePlanType = (e, value) => {
        this.setState({ plan_type: value })
    }

    render() {
        return (
            <>
                <Grid item xs={12}>
                    <Paper className="sq-shadow">
                        <Tabs value={this.state.plan_type} onChange={this.changePlanType}>
                            {
                                this.life_plans.map((plan, ind) => (<Tab label={plan.plan_type} key={ind} />))
                            }
                        </Tabs>
                    </Paper>
                </Grid>
                {this.state.plan_type === 0 && <IndividualMedical />}
                {this.state.plan_type === 1 && <AffordableHealthPlan />}
                {this.state.plan_type === 2 && <UnifiedDomesticWorker />}
            </>
        )
    }
}
export default ModifyPremiumsHealth;