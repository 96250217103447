import React from "react";
import Navigation from "../common/Navigation";
import { Link, withRouter } from "react-router-dom";
import { Paper, Typography, Grid} from "@material-ui/core";
import AffiliateService from "../../services/affiliates";
import "./Affiliate.css";
import $ from "jquery";

class ShowAffiliates extends React.Component{

  state = {
    center : "",
    affiliateId : "",
    openLevel : -1,
    rootAffiliate : {},
    affiliatesObj : {},
    affiliates : []
  }

  componentDidMount(){
    const {center, id} = this.props.match.params;
    this.setState({ center, affiliateId : id });
    this.getChildAffiliates(id);
  }
  
  getChildAffiliates = async (uid) => {
    const rootAffiliateData = await AffiliateService.getAffiliate(uid);
    const rootAffiliate = rootAffiliateData.data.data;
    const data = await AffiliateService.getChildAffiliates(uid);
    const childAffiliates = data.data.data;
    let affiliates = [rootAffiliate, ...childAffiliates];
    this.setState({ rootAffiliate });
    this.getAffiliatesArray(affiliates)
  }

  getAffiliatesArray = (affiliates) => {
    let arr = [];

    const affiliatesTree = this.buildHierarchy(affiliates);

    const getObject = (array) => {
      array.forEach((affiliate) => {
        arr.push(affiliate.value);
        if(affiliate.subchildren){
          getObject(affiliate.subchildren);
        }
      })
    }

    getObject(affiliatesTree);

    this.setState({ affiliates : arr });
  }

  getLevel = (parents) => {
    const length = parents.length;
    const parentIndex = parents.indexOf(this.state.rootAffiliate.affiliateId);
    const level = length - parentIndex;
    return level;
  }

  buildHierarchy = (array) => {
    let roots = [], subchildren = {};

    // find the top level nodes and hash the subchildren based on parent
    for (let i = 0; i < array.length; i++) { 
      let item = array[i],
        p = item.parent_id,
      target = i === 0 ? roots : (subchildren[p] || (subchildren[p] = []));
      target.push({ value: item });
    }

    // function to recursively build the tree
    let findsubchildren = (parent) => {
        if (subchildren[parent.value.affiliateId]) {
            parent.subchildren = subchildren[parent.value.affiliateId];
            for (let i = 0; i < parent.subchildren.length; i++) {
              findsubchildren(parent.subchildren[i]);
            }
        }
    };
    // enumerate through to handle the case where there are multiple roots
    for (let i = 0; i < roots.length; i++) {
      findsubchildren(roots[i]);
    }

    return roots;
  }

  getVerticalLine = (parentId, childId) => {
    $(document).ready(() => {
      const parentIdStr = `#${parentId}`;
      const childIdStr = `#${childId}`;
      const parentTop = $(parentIdStr).offset().top + 40;
      const childTop = $(childIdStr).offset().top + 20;
      const height = childTop - parentTop;
      const childLineId = `${childIdStr}-line`;
      $(childLineId).css({
        height : height - 1,
        top : `-${height - 20}px`
      });
    })
  }

  render(){
    return(
      <div className="d-flex">
        <Navigation />
        <div className="container-fluid">
          <Grid container spacing = {2}>
            <Grid item xs={12}>
              <Paper className="p-2 sq-shadow d-flex">
                <Typography><Link to="/">Home</Link></Typography>
                <Typography className="mr-2 ml-2">/</Typography>
                <Typography><Link to={`${process.env.PUBLIC_URL}/affiliator`}>Affiliators</Link></Typography>
                <Typography className="mr-2 ml-2">/</Typography>
                <Typography> {this.state.affiliateId} </Typography>
              </Paper>
            </Grid>

            <Grid item xs={12}>
              <div id={this.state.rootAffiliate.affiliateId}>
                <Typography className="affiliate-container sq-shadow d-inline-flex align-items-center">
                  {this.state.rootAffiliate.name}
                </Typography>
              </div>

              {
                this.state.affiliates.map((child, ind) =>
                  ind > 0 &&
                  <div
                    key={child._id}
                    className = "position-relative"
                    style={{ marginLeft : `${this.getLevel(child.parents) * 20}px` }}
                    id = {child.affiliateId}
                  >
                    {this.getVerticalLine(child.parent_id, child.affiliateId)}
                    <div
                      className="position-absolute tree-vertical-line"
                      id = {`${child.affiliateId}-line`}
                    ></div>
                    
                    <Typography className="affiliate-container affiliate-container-child position-relative sq-shadow d-inline-flex align-items-center">
                      {child.name}
                    </Typography>

                  </div>
                )
              }
            </Grid>

          </Grid>
        </div>
      </div>
    )
  }
}

export default withRouter(ShowAffiliates);