import axios from "axios";
import config from "./config";

const {baseUrl} = config;
let upload = {}
upload.uploadImage = async (id, data) => {
  const url = `${baseUrl}/upload/${id}`;
  const res = await axios({
    url,
    method : "PUT",
    data
  });

  return res;
}

upload.deleteDocument = async (id, data) => {
  const url = `${baseUrl}/delete-document/${id}`;
  const res = await axios({
    url,
    method : "PUT",
    data
  });

  return res;
}

upload.uploadFile = async (data) => {
  const url = `${baseUrl}/upload-file`;
  const res = await axios({
    url,
    method : "POST",
    data
  });
  return res;
}

export default upload;