import React from "react";
import DhofarLogo from "../../../static/images/dhofar-logo.jpg";
import moment from "moment";
import NextCare from "../../../static/images/nextcare-logo.png";

const DomesticCard = ({ data }) => {

  const startDate = data.startDate;

  const getDob = () => {
    const dob = data.personalInformation.dob;

    let date = moment(dob, 'DD-MM-YYYY');
    if(!date.isValid()){
      date = moment(dob, 'DD-MMM-YYYY');
    }

    return date.format("DD-MM-YYYY");
  }

  const getStartDate = () => {
    return moment(startDate).format("DD/MM/YYYY")
  }
  
  const getEndDate = () => {
    let numYears = 1;

    if(data.cover_type === "Unified Domestic Worker"){
      numYears = 2
    }
    return moment(startDate).subtract(1, "day").add(numYears, "year").format("DD/MM/YYYY");
  }
  
  return(
    <div className="vc-container domestic-vc-container position-relative sq-shadow">
      <div className="d-flex align-items-center justify-content-between domestic-vc-header">
        <img src={DhofarLogo} alt="Dhofar" className="domestic-dhofar-logo" />
        <div className="ml-2">
          <div className="d-flex justify-content-end text-right w-100">
            <img src={NextCare} alt="Next-care" className="nextcare-logo text-right" />
          </div>
          <div className="vc-description mr-4 mt-1">
            {
              data.cover_type === "Unified Domestic Worker" ?
                <>
                  <div>UNIFIED MEDICAL INSURANCE </div>
                  <div>FOR HOUSEHOLD DOMESTIC WORKERS</div>
                </>
                :
                <div className="mr-md-5"> AFFORDABLE HEALTH COVER  </div>
            }
          </div>
        </div>
      </div>
      <div className="d-flex align-items-center vcd-detail-container mt-1 ">
        <span className="vcd-title ">Name: </span> &nbsp; <span className="vc-description"> {data && data.personalInformation.fullName} </span>
      </div>
      <div className="d-flex align-items-center vcd-detail-container">
        <div className="col-8 p-0">
          <div className="d-flex align-items-center ">
            <span className="vcd-title">Card No: </span> &nbsp; <span className="vc-description"> {data && data.policy_num} </span>
          </div>
        </div>
        <div className="col-4 p-0">
          <div className="d-flex align-items-center ">
            <span className="vcd-title">Id No: </span> &nbsp; <span className="vc-description"> {data && data.civil_id} </span>
          </div>
        </div>
      </div>
      <div className="d-flex align-items-center vcd-detail-container">
        <div className="col-8 p-0">
          <div className="d-flex align-items-center ">
            <span className="vcd-title">Network: </span> &nbsp; <span className="vc-description"> RN2 (Access in Oman) </span>
          </div>
        </div>
        <div className="col-4 p-0">
          <div className="d-flex align-items-center ">
            <span className="vcd-title">D.O.B: </span> &nbsp; <span className="vc-description"> {getDob()} </span>
          </div>
        </div>
      </div>
      <div className="d-flex align-items-center vcd-detail-container">
        <div className="col-4 p-0">
          <div className="d-flex align-items-center">
            <span className="vcd-title">Ded: </span> &nbsp; <span className="vc-description"> Nil </span>
          </div>
          <div className="d-flex align-items-center">
            <span className="vcd-title">Co.Ins: </span> &nbsp; <span className="vc-description"> Nil </span>
          </div>
          <div className="d-flex align-items-center">
            <span className="vcd-title">Cons: </span> &nbsp; <span className="vc-description"> Yes </span>
          </div>
        </div>
        <div className="col-4 p-0">
          <div className="d-flex align-items-center">
            <span className="vcd-title">Optical: </span> &nbsp; <span className="vc-description"> No </span>
          </div>
          <div className="d-flex align-items-center">
            <span className="vcd-title">Dental: </span> &nbsp; <span className="vc-description"> No </span>
          </div>
          <div className="d-flex align-items-center">
            <span className="vcd-title">Metarnity: </span> &nbsp; <span className="vc-description"> No </span>
          </div>
        </div>
        <div className="col-4 p-0">
          <div className="d-flex align-items-center">
            <span className="vcd-title">Class: </span> &nbsp; <span className="vc-description"> Semi-Private </span>
          </div>
          <div className="d-flex align-items-center">
            <span className="vcd-title">Effective:</span> &nbsp; <span className="vc-description">{getStartDate()}</span>
          </div>
          <div className="d-flex align-items-center">
            <span className="vcd-title">Expiry:</span> &nbsp; <span className="vc-description">{getEndDate()}</span>
          </div>
        </div>
      </div>
      <div className="vc-bottom-descreption vcd-detail-container"> Pre approval required for all Out-Patient services <br /> Out-Patient Pre-Existing & Chronic Conditions:Not Covered <br /> Please collect resident card copy & submit along with claim </div>
      <div className="vcd-bottom-text vcd-detail-container">This card is for personal use and must be presented at every doctor's visit</div>
      <div className="vcd-title text-center mt-1 w-100 vcd-detail-container">Nextcare Hotline Number: 80077757</div>
    </div>
  )
}

export default DomesticCard;