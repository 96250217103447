import React from "react";
import Navigation from "../common/Navigation";
import { Grid, Button, Table, TableHead, TableBody, TableRow, TableCell, Paper, Typography, TablePagination, TextField, MenuItem } from "@material-ui/core";
import Eye from "mdi-react/EyeIcon";
import { Link, withRouter } from "react-router-dom";
import { saveAs } from "file-saver";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import RiyatiService from "../../services/riyati-insurance";
import moment from "moment";
import utils from "../../services/utils";
import AffiliateSelect from "../common/AffiliateSelect";
import ChannelSelect from "../common/ChannelSelect";
import SubChannelSelect from "../common/SubChannelSelect";
import _ from "lodash";
import helpers from "../../helpers";
import { REPORT_STATUS_OPTIONS } from "../../constants";

class RiyatiReport extends React.Component {
  state = {
    startDate: null,
    endDate: null,
    showTable: false,
    data: [],
    page: 0,
    rowsPerPage: 10,
    totalRecords: 0,
    headings: [],
    center: "",
    isLoading: false,
    isDownloading: false,
    status: "All"
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    });
  }

  componentDidMount() {
    const center = this.props.match.params.center;

    let date = new Date().setHours(23, 59, 59, 999);
    let endDate = new Date(date).getTime();
    this.setState({
      startDate: new Date(new Date().setHours(0, 0, 0, 0)).getTime(),
      endDate,
      center
    });
  }

  handleChangePage = (e, page) => {
    this.setState({ page }, this.handleSearch);
  }

  handleChangeRowsPerPage = (e) => {
    this.setState({ rowsPerPage: e.target.value }, this.handleSearch);
  }

  formatPolicies = (policies) => {
    return Array.isArray(policies) && policies.length ?
      policies.map((policy, ind) => {
        if (policy.plan === "individual") {
          policy.family_members = [{
            name: _.get(policy, "personalInformation.fullName", ""),
            relation: "Self",
            passport_no: _.get(policy, "personalInformation.passport", "")
          }];
        }
        return policy
      }) : [];
  }

  handleSearch = async () => {
    this.setState({ isLoading: true });
    const status = this.state.status === "All" ? undefined : this.state.status;
    const data = await RiyatiService.getPolicies(this.state.startDate, this.state.endDate, status, this.state.page, this.state.rowsPerPage);

    if (data.status === 200) {
      this.setState({ showTable: true, data: this.formatPolicies(data.data.data), totalRecords: data.data.count })
    }


    let headings = [
      "Sr No",
      "Policy Number",
      "Civil ID",
      // "Travel Countries",
      "Riyati Start Date",
      "Riyati End Date",
      "Riyati Duration Days",
      // "Travel Coverage Type",
      "Riyati type",
      "Members",
      "Premium",
      "Policy Holder Name",
      "Email ID",
      "Contact No",
      "Net Premium",
      "Discount Code",
      "Discount",
      "Policy Fee",
      "Emergency Fund Fee",
      "Goverment Fee",
      "Gross Premium",
      "VAT",
      "Loading",
      "Plan Name",
      "Payment Status",
      "Policy Status"
    ]
    if (["Active"].includes(this.state.status)) {
      headings.push("Payment Mode", "Payment Reference Number");
    }
    headings.push("Company", "Partner Branch Name", "Channel Name", "Sub Channel Name", "Employee ID", "Employee Name", "Employee EmailID", "Employee Designation", "Location/Branch-Id", "Location/Branch-Name", "Zone/Region", "Reporting Manager", "Reporting Mgr email id", "Employee Contact No.", "Comment", "View");
    this.setState({ isLoading: false, headings });
  }

  // getFormattedDate = (date, dateFormat = "DD-MM-YYYY", responseFormat = "DD-MMM-YYYY") => {
  //   try {
  //     let newDate = moment(date, dateFormat).format(responseFormat);
  //     return (newDate === "Invalid date") ? "-" : newDate;
  //   } catch (error) {
  //     return "-";
  //   }
  // }

  getFormattedDateTime = (date, checkFormat = true, responseFormat = 'MMM DD, YYYY LTS') => {
    try {
      let newDate;
      if (checkFormat) {
        newDate = moment(date, "DD-MM-YYYY").format(responseFormat)
      } else {
        newDate = moment(date).format(responseFormat)
      }
      return (newDate === "Invalid date") ? "-" : newDate;
    } catch (error) {
      return "-";
    }
  }

  downloadData = async () => {
    this.setState({ isDownloading: true });
    const status = this.state.status === "All" ? undefined : this.state.status;
    const res = await RiyatiService.getPolicies(this.state.startDate, this.state.endDate, status);
    const data = this.formatPolicies(res.data.data);
    let csvArr = [];
    let newHeadings = [...this.state.headings];
    newHeadings.splice(-1, 1);
    csvArr.push(newHeadings.join(","));
    data.forEach((policy, ind) => {
      const base_price = policy.amount ? helpers.getBasePrice(policy.amount) : 0;
      const discount_code = policy.amount ? helpers.getDiscountCode(policy.amount) : 0;
      const discount = policy.amount ? helpers.getDiscount(policy.amount) : "-";
      const policy_fee = policy.amount ? policy.amount.policy_fee : 0;
      const emergency_fund_fee = policy.amount ? policy.amount.emergency_fund_fee : 0;
      const government_fee = policy.amount ? policy.amount.government_fee : 0;
      const amount = policy.amount ? policy.amount.total : 0;
      const paymentStatus = policy.payments && policy.payments.isPaid ? "Paid" : "Unpaid";
      const bmiValue = _.get(policy.amount, "bmi_value", 0)
      const vatValue = _.get(policy.amount, "vat", 0)

      let members = "";

      if (Array.isArray(policy.family_members)) {
        policy.family_members.forEach((member) => {
          members += `Name : ${member.name},Relation : ${member.relation},Passport No : ${member.passport_no}`
        })
      }

      let arr = [
        ind + 1,
        policy._id,
        policy.civil_id,
        // `"${Array.isArray(policy.travel_countries) ? policy.travel_countries.join(", ") : ''}"`,
        // this.getFormattedDate(policy.startDate, 'x'),
        moment(policy.startDate, "x").format("DD/MM/YYYY"),
        moment(policy.startDate, "x").add(policy.riyati_duration_days - 1, "d").format("DD/MM/YYYY"),
        policy.riyati_duration_days,
        // policy.travel_coverage_type,
        policy.plan,
        `"${members}"`,
        `"${utils.getFormattedAmount(policy.amount ? policy.amount.total : "0")} OMR"`,
        policy.personalInformation.fullName,
        policy.personalInformation.email,
        policy.personalInformation.mobileNumber,
        `"${utils.getFormattedAmount(base_price)} OMR"`,
        discount_code,
        `"${utils.getFormattedAmount(discount)} OMR"`,
        `"${utils.getFormattedAmount(policy_fee)} OMR"`,
        `"${utils.getFormattedAmount(emergency_fund_fee)} OMR"`,
        `"${utils.getFormattedAmount(government_fee)} OMR"`,
        `"${utils.getFormattedAmount(amount)} OMR"`,
        `"${utils.getFormattedAmount(vatValue)} OMR"`,
        `"${utils.getFormattedAmount(bmiValue)} OMR"`,
        policy.plan,
        paymentStatus,
        policy.status
      ];

      if (["Active"].includes(this.state.status)) {
        const paymentMode = policy.payments.mode === "Link" ? "Card" : policy.payments.mode;
        arr.push(
          paymentMode,
          policy.payments.mode !== "Cash" ? policy.payments.payment_id : "-"
        )
      }
      arr.push(policy.company_affiliate_name ? policy.company_affiliate_name : "-")
      arr.push(policy.affiliate_partner_name ? policy.affiliate_partner_name : "-")
      arr.push(policy.channel_name ? policy.channel_name : "-")
      arr.push(policy.sub_channel_name ? policy.sub_channel_name : "-")
      arr.push(policy.affiliate_employee_id ? policy.affiliate_employee_id : "-")
      arr.push(policy.affiliate_name ? policy.affiliate_name : "Dhofar")
      arr.push(policy.affiliate_email ? policy.affiliate_email : "-")
      arr.push(policy.affiliate_employee_designation ? policy.affiliate_employee_designation : "-")
      arr.push(policy.branch_id ? policy.branch_id : "-")
      arr.push(policy.branch_name ? policy.branch_name : "-")
      arr.push(policy.region_name ? policy.region_name : "-")
      arr.push(policy.branch_manager_name ? policy.branch_manager_name : "-")
      arr.push(policy.branch_manager_email ? policy.branch_manager_email : "-")
      arr.push(policy.affiliate_phone ? policy.affiliate_phone : "-")
      arr.push(policy.comment ? policy.comment : "-")
      csvArr.push(arr.join(","));
    });

    const csvFile = new Blob([csvArr.join("\n")], { type: "text/csv" });
    saveAs(csvFile, "riyati-insurance.csv");
    this.setState({ isDownloading: false, showProcessPopup: false });
  }

  handleStartDateChange = (date) => {
    const startDate = new Date(date.setHours(0, 0, 0, 0)).getTime();
    this.setState({ startDate });
  }

  handleEndDateChange = (date) => {
    const endDate = new Date(date.setHours(23, 59, 59, 999)).getTime();
    this.setState({ endDate });
  }

  render() {
    return (
      <div className="d-flex">
        <Navigation />
        <div className="container-fluid">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Paper className="p-2 sq-shadow d-flex">
                <Typography><Link to={`/home`}>Home</Link></Typography>
                <Typography className="mr-2 ml-2">/</Typography>
                <Typography>Riyati Report</Typography>
              </Paper>
            </Grid>

            <Grid item md={3} xs={12}>
              <AffiliateSelect onChange={() => { }} />
            </Grid>
            <Grid item md={3} xs={12}>
              <ChannelSelect onChange={() => { }} />
            </Grid>
            <Grid item md={3} xs={12}>
              <SubChannelSelect onChange={() => { }} />
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={3}>
              <TextField
                variant="outlined"
                select
                label="Status"
                fullWidth
                name="status"
                onChange={this.handleChange}
                value={this.state.status}
                SelectProps={{
                  MenuProps: {
                    anchorOrigin: {
                      vertical: "top",
                      horizontal: "left"
                    },
                    getContentAnchorEl: null
                  },
                }}
              >
                {
                  Object.values(REPORT_STATUS_OPTIONS).map((option, index) => <MenuItem key={index} value={option}>{option}</MenuItem>)
                }
              </TextField>
            </Grid>

            <Grid item xs={3}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                  label="Start Date"
                  inputVariant="outlined"
                  animateYearScrolling
                  name="startDate"
                  clearable
                  fullWidth
                  value={this.state.startDate}
                  onChange={this.handleStartDateChange}
                />
              </MuiPickersUtilsProvider>
            </Grid>

            <Grid item xs={3}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                  label="End Date"
                  inputVariant="outlined"
                  animateYearScrolling
                  name="endDate"
                  clearable
                  fullWidth
                  value={this.state.endDate}
                  onChange={this.handleEndDateChange}
                />
              </MuiPickersUtilsProvider>
            </Grid>

            <Grid item xs={3} className="d-flex align-items-center">
              <Button variant="contained" color="primary" disabled={this.state.isLoading} onClick={this.handleSearch}>{this.state.isLoading ? "Searching . . ." : "Search"}</Button>
            </Grid>
          </Grid>

          {
            (this.state.showTable && this.state.data.length > 0) &&
            <Grid container spacing={2} className="d-flex">
              <Grid item xs={12}>
                <Paper className="sq-shadow overflow-auto">
                  <div className="dash-table-container">
                    <Table>
                      <TableHead>
                        <TableRow>
                          {
                            this.state.headings.map((heading, ind) =>
                              <TableCell key={ind}>{heading}</TableCell>
                            )
                          }
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {
                          this.state.data.map((data, ind) => {
                            return (
                              <TableRow key={ind}>
                                <TableCell>{ind + 1}</TableCell>
                                <TableCell>{data._id}</TableCell>
                                <TableCell> {data.civil_id} </TableCell>
                                {/* <TableCell>
                                  {Array.isArray(data.travel_countries) ? data.travel_countries.map(country => (<span className="white-space-pre">{country}<br /></span>)) : "-"}
                                </TableCell> */}

                                <TableCell className="white-space-pre"> {moment(data.startDate, "x").format("DD/MM/YYYY")} </TableCell>
                                <TableCell className="white-space-pre"> {moment(data.startDate, "x").add(data.riyati_duration_days - 1, "d").format("DD/MM/YYYY")} </TableCell>

                                {/* <TableCell className="white-space-pre"> {this.getFormattedDate(data.startDate, 'x')} </TableCell> */}
                                {/* <TableCell className="white-space-pre"> {this.getFormattedDate(data.endDate, 'x')} </TableCell> */}
                                <TableCell> {data.riyati_duration_days} </TableCell>
                                {/* <TableCell> {data.travel_coverage_type} </TableCell> */}
                                <TableCell className="text-capitalize"> {data.plan} </TableCell>
                                <TableCell>
                                  {
                                    Array.isArray(data.family_members) && data.family_members.map((member, bind) =>
                                      <div key={bind} className={`${bind === data.family_members.length - 1 ? "mb-0" : "mb-2"}`}>
                                        <div className="white-space-pre" > Member No : {bind + 1} </div>
                                        <div className="white-space-pre" > Name : {member.name} </div>
                                        <div className="white-space-pre" > Passport No : {member.passport_no} </div>
                                        <div className="white-space-pre" > Relation : {member.relation} </div>
                                      </div>
                                    )
                                  }
                                </TableCell>
                                <TableCell> {data.amount && utils.getFormattedAmount(data.amount.total)} OMR</TableCell>
                                <TableCell> {data.personalInformation.fullName} </TableCell>
                                <TableCell>{data.personalInformation.email}</TableCell>
                                <TableCell>{data.personalInformation.mobileNumber}</TableCell>
                                <TableCell>{data.amount ? utils.getFormattedAmount(helpers.getBasePrice(data.amount)) : 0} OMR</TableCell>
                                <TableCell>{data.amount ? helpers.getDiscountCode(data.amount) : "-"}</TableCell>
                                <TableCell>{data.amount ? utils.getFormattedAmount(helpers.getDiscount(data.amount)) : 0} OMR</TableCell>
                                <TableCell>{data.amount ? utils.getFormattedAmount(data.amount.policy_fee) : 0} OMR</TableCell>
                                <TableCell>{data.amount ? utils.getFormattedAmount(data.amount.emergency_fund_fee) : 0} OMR</TableCell>
                                <TableCell>{data.amount ? utils.getFormattedAmount(data.amount.government_fee) : 0} OMR</TableCell>
                                <TableCell>{data.amount ? utils.getFormattedAmount(data.amount.total) : 0} OMR</TableCell>
                                <TableCell>{data.amount ? utils.getFormattedAmount(data.amount.vat) : 0} OMR</TableCell>
                                <TableCell>{data.amount ? utils.getFormattedAmount(data.amount.bmi_value) : 0} OMR</TableCell>
                                <TableCell> {data.plan} </TableCell>
                                <TableCell> {data.payments && data.payments.isPaid ? "Paid" : "Unpaid"} </TableCell>
                                <TableCell> {data.status} </TableCell>
                                {
                                  ["Active"].includes(this.state.status) &&
                                  <>
                                    <TableCell>
                                      {
                                        data.payments.mode === "Link" ? "Card" : data.payments.mode
                                      }
                                    </TableCell>
                                    <TableCell>
                                      {
                                        data.payments.mode !== "Cash" ? data.payments.payment_id : "-"
                                      }
                                    </TableCell>
                                  </>
                                }
                                <TableCell>{data.company_affiliate_name ? data.company_affiliate_name : "-"}</TableCell>
                                <TableCell>{data.affiliate_partner_name ? data.affiliate_partner_name : "-"}</TableCell>
                                <TableCell>{data.channel_name ? data.channel_name : "-"}</TableCell>
                                <TableCell>{data.sub_channel_name ? data.sub_channel_name : "-"}</TableCell>
                                <TableCell>{data.affiliate_employee_id ? data.affiliate_employee_id : "-"}</TableCell>
                                <TableCell>{data.affiliate_name ? data.affiliate_name : "Dhofar"}</TableCell>
                                <TableCell>{data.affiliate_email ? data.affiliate_email : "-"}</TableCell>
                                <TableCell>{data.affiliate_employee_designation ? data.affiliate_employee_designation : "-"}</TableCell>
                                <TableCell>{data.branch_id ? data.branch_id : "-"}</TableCell>
                                <TableCell>{data.branch_name ? data.branch_name : "-"}</TableCell>
                                <TableCell>{data.region_name ? data.region_name : "-"}</TableCell>
                                <TableCell>{data.branch_manager_name ? data.branch_manager_name : "-"}</TableCell>
                                <TableCell>{data.branch_manager_email ? data.branch_manager_email : "-"}</TableCell>
                                <TableCell>{data.affiliate_phone ? data.affiliate_phone : "-"}</TableCell>
                                <TableCell>{data.comment ? data.comment : "-"}</TableCell>

                                <TableCell>
                                  <Link to={`/policies/${data._id}`} className="table-eye-link">
                                    <Eye />
                                  </Link>
                                </TableCell>
                              </TableRow>
                            )
                          })
                        }
                        <TableRow>
                          <TablePagination
                            rowsPerPage={this.state.rowsPerPage}
                            rowsPerPageOptions={[10, 25, 50]}
                            page={this.state.page}
                            count={this.state.totalRecords}
                            colSpan={0}
                            onChangePage={this.handleChangePage}
                            onChangeRowsPerPage={this.handleChangeRowsPerPage}
                          />
                        </TableRow>
                      </TableBody>
                    </Table>
                  </div>
                </Paper>
              </Grid>

              <Grid item xs={12}>
                <Button className="mr-1" onClick={this.downloadData} variant="contained" color="primary" disabled={this.state.isDownloading}>{this.state.isDownloading ? "Downloading . . ." : "Download csv"}</Button>
                {/* <Button className="mr-1" onClick={this.downloadRiReportData} variant="contained" color="primary" disabled={this.state.isDownloading}>{this.state.isDownloading ? "Downloading . . ." : "Download RI Report"}</Button> */}
                {/* <Button className="mr-1" onClick={this.downloadBordereauxReportData} variant="contained" color="primary" disabled={this.state.isDownloading}>{this.state.isDownloading ? "Downloading . . ." : "Download Bordereaux Report"}</Button> */}
              </Grid>

            </Grid>
          }
          {
            (this.state.showTable && this.state.data.length === 0) &&
            <Paper className="q-shadow mt-3 pt-5 pb-5 d-flex align-items-center justify-content-center">
              <Typography variant="h5"> No Data Found </Typography>
            </Paper>
          }
        </div>
      </div >
    )
  }
}
export default withRouter(RiyatiReport);