import React from "react";
import Navigation from "../common/Navigation";
import {Grid, Tabs, Tab, Paper, Typography, Button, Dialog, DialogContent, DialogTitle, TextField, DialogActions} from "@material-ui/core";
import "./style.css";
import PieChart from "react-minimal-pie-chart";
import PolicyService from "../../services/policy";
import {Link, withRouter} from "react-router-dom";
import AuthService from "../../services/auth";
import CreditLimit from "../../services/credit-limit";

function getRandomColor(){
  var letters = '0123456789ABCDEF';
  var color = '#';
  for (var i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}

class Analysis extends React.Component{
  state = {
    paymentIndex : 0,
    data : [],
    paymentTypeData : [],
    agentsData : [],
    paymentTypeIndex : 0,
    agentsIndex : 0,
    center : "",
    creditLimit : "",
    availableLimit : "",
    showCreditLimitDialog : false,
    creditLimitValue : "",
    availableLimitValue : "",
    showUpdateLimitDialog : false,
    isCenterSelected : false,
    childrenIndex : 0,
    childrenData : [],
    brokerIndex : 0,
    brokerData : []
  }

  handlePaymentIndexChange = (e, ind) => {
    if(this.state.paymentIndex !== ind){
      this.setState({ paymentIndex : ind }, this.getData);
    }
  }

  getData = async () => {
    let startDate = undefined;
    let endDate = undefined;
    const date = new Date();
    const paymentIndex = this.state.paymentIndex;
    if(paymentIndex === 0){
      startDate = new Date(date.setHours(0,0,0,0));
      endDate = new Date(date.setHours(23,59,59,999));
    }
    if(paymentIndex === 1){
      startDate = new Date();
      startDate.setDate(date.getDate() - 7);
      startDate.setHours(0,0,0,0);
      endDate = new Date(date.setHours(23,59,59,999));
    }
    if(paymentIndex === 2){
      startDate = new Date();
      startDate.setDate(date.getDate() - 30);
      startDate.setHours(0,0,0,0);
      endDate = new Date(date.setHours(23,59,59,999));
    }

    const startDateIso = startDate ? startDate.getTime() : startDate;
    const endDateIso = endDate ? endDate.getTime() : endDate;

    const data = await PolicyService.getAnalysisData(startDateIso, endDateIso);
    let dataArr = [];
    data.data.forEach((dataObj) => {
      let obj = {};

      obj.title = dataObj.type;
      obj.value = dataObj.count;
      obj.color = getRandomColor();

      dataArr.push(obj);
    });
    this.setState({ data : dataArr });
  }

  getPaymentTypeData = async () => {
    let startDate = undefined;
    let endDate = undefined;
    const date = new Date();
    const paymentIndex = this.state.paymentTypeIndex;
    if(paymentIndex === 0){
      startDate = new Date(date.setHours(0,0,0,0));
      endDate = new Date(date.setHours(23,59,59,999));
    }
    if(paymentIndex === 1){
      startDate = new Date();
      startDate.setDate(date.getDate() - 7);
      startDate.setHours(0,0,0,0);
      endDate = new Date(date.setHours(23,59,59,999));
    }
    if(paymentIndex === 2){
      startDate = new Date();
      startDate.setDate(date.getDate() - 30);
      startDate.setHours(0,0,0,0);
      endDate = new Date(date.setHours(23,59,59,999));
    }

    const startDateIso = startDate ? startDate.getTime() : startDate;
    const endDateIso = endDate ? endDate.getTime() : endDate;

    const data = await PolicyService.getPaymentAnalysisData(startDateIso, endDateIso);
    let paymentTypeData = [];
    data.data.forEach((paymentObj) => {
      let obj = {};

      obj.title = paymentObj.type;
      obj.value = paymentObj.count;
      obj.color = getRandomColor();

      paymentTypeData.push(obj);
    })
    this.setState({ paymentTypeData });
  }

  handlePaymentTypeIndexChange = (e, ind) => {
    if(this.state.paymentTypeIndex !== ind){
      this.setState({ paymentTypeIndex : ind }, this.getPaymentTypeData);
    }
  }

  handleAgentsIndexChange = (e, ind) => {
    if(this.state.agentsIndex !== ind){
      this.setState({ agentsIndex : ind }, this.getAgentsData);
    }
  }

  handleChildrenIndexChange = (e, ind) => {
    if(this.state.childrenIndex !== ind){
      this.setState({ childrenIndex : ind }, this.getChildrenData);
    }
  }

  handleBrokerIndexChange = (e, ind) => {
    if(this.state.brokerIndex !== ind){
      this.setState({ brokerIndex : ind }, this.getBrokersData);
    }
  }

  componentDidMount(){
    this.getData();
    const center = this.props.match.params.center;
    const isCenterSelected = Boolean(sessionStorage.getItem("center_id"));
    if(isCenterSelected){
      this.getCreditLimit();
    }
    this.getChildrenData();
    this.setState({ center, isCenterSelected });
  }

  getChildrenData = async () => {
    let startDate = undefined;
    let endDate = undefined;
    const date = new Date();
    const childrenIndex = this.state.childrenIndex;
    if(childrenIndex === 0){
      startDate = new Date(date.setHours(0,0,0,0));
      endDate = new Date(date.setHours(23,59,59,999));
    }
    if(childrenIndex === 1){
      startDate = new Date();
      startDate.setDate(date.getDate() - 7);
      startDate = new Date(date.setHours(0,0,0,0));
    }
    if(childrenIndex === 2){
      startDate = new Date();
      startDate.setDate(date.getDate() - 30);
            endDate = new Date(date.setHours(23,59,59,999));
    }

    const startDateIso = startDate ? startDate.getTime() : startDate;
    const endDateIso = endDate ? endDate.getTime() : endDate;
    const data = await PolicyService.getChildrenAnalysis(startDateIso, endDateIso);
    let dataArr = [];
    data.data.forEach((dataObj) => {
      let obj = {};

      obj.title = dataObj.name;
      obj.value = dataObj.count;
      obj.color = getRandomColor();

      dataArr.push(obj);
    });

    this.setState({ childrenData : dataArr });
  }

  getCreditLimit = async () => {
    const res = await CreditLimit.getCreditLimit();
    const data = res.data;
    if(Boolean(data)){
      this.setState({
        creditLimit : data.total_limit,
        availableLimit : data.available_limit,
        availableLimitValue : data.available_limit
      });
    }
  }

  handleCloseDialog = () => {
    this.setState({
      showCreditLimitDialog : false,
      showUpdateLimitDialog : false
    })
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name] : e.target.value
    })
  }

  handleCreditLimitSave = async () => {
    let obj = {
      total_limit : parseFloat(Number(this.state.creditLimitValue).toFixed(2))
    }
    
    if(this.state.availableLimit === ""){
      obj.available_limit = parseFloat(Number(this.state.creditLimitValue).toFixed(2));
    }

    const res = await CreditLimit.updateLimit(obj);
    if(res.status === 200){
      this.handleCloseDialog();
      this.getCreditLimit();
    }
  }

  handleAvalilableLimitSave = async () => {
    let obj = {
      available_limit : parseFloat(Number(this.state.availableLimitValue).toFixed(2))
    }
    const res = await CreditLimit.updateLimit(obj);
    if(res.status === 200){
      this.handleCloseDialog();
      this.getCreditLimit();
    }
  }

  render(){
    return(
      <div className="d-flex">
        <Navigation />
        <div className="container-fluid">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Paper className="p-2 sq-shadow d-flex">
                <Typography> <Link to={`/home`}>Home</Link></Typography>
                <Typography className="mr-2 ml-2">/</Typography>
                <Typography>Analysis</Typography>
              </Paper>
            </Grid>
            
            <Grid item xs={6}>
              <Paper className="sq-shadow mb-3 p-3 text-center">
                <Typography variant="h4"> Accounts </Typography>
              </Paper>

              <Paper className="sq-shadow mb-4">
                <Tabs variant="fullWidth" value={this.state.paymentTypeIndex} onChange = {this.handlePaymentTypeIndexChange}>
                  <Tab label = "Daily" />
                  <Tab label = "Weekly" />
                  <Tab label = "Monthly" />
                  <Tab label = "Total" />
                </Tabs>
              </Paper>

              <div className="col-12">
                <div className="row">
                  <div className="col offset-1">
                    <PieChart
                      data={this.state.paymentTypeData}
                      label = {({ data , dataIndex }) => 
                        data[dataIndex].value
                      }
                      className="pie-chart"
                      labelStyle={{
                        fontSize: '5px',
                        fill: '#fff'
                      }}
                      animate
                    />
                  </div>
                  <div className="col-4 d-flex align-items-center">
                    <div>
                      {
                        this.state.paymentTypeData.map((d, ind) =>
                          <div key={ind} className="mt-2 mb-2 d-flex align-items-center text-capitalize"> <span style={{ backgroundColor : d.color, height : "15px", width : "15px" }} className="d-inline-block mr-2"></span> {d.title} &nbsp; - &nbsp; {d.value}</div>
                        )
                      }
                    </div>
                  </div>
                </div>
              </div>

              {
                this.state.isCenterSelected &&
                <div className="col-12">
                  <div className="row mt-4">
                    <div className={`col-6`}>
                      <div className="p-2 home-card sq-shadow text-center">
                        <Typography variant="subtitle1"> Credit Limit </Typography>
                        <Typography variant="subtitle2"> {this.state.creditLimit === "" ? "Not set yet" : `${Number(this.state.creditLimit).toFixed(2)} OMR`} </Typography>
                        <Button variant="contained" color="primary" size="small" className="mt-2" onClick={() => this.setState({ showCreditLimitDialog : true })}>
                          {this.state.creditLimit === "" ? "Set" : "Update"} Limit
                        </Button>

                        <Dialog
                          open={this.state.showCreditLimitDialog}
                          maxWidth = "xs"
                          fullWidth
                          onClose= {this.handleCloseDialog}
                        >
                          <DialogTitle>
                            {this.state.creditLimit === "" ? "Set" : "Update"} Credit Limit
                          </DialogTitle>
                          <DialogContent>
                            <TextField
                              variant="outlined"
                              fullWidth
                              label = "Credit Limit"
                              name = "creditLimitValue"
                              onChange = {this.handleChange}
                              value = {this.state.creditLimitValue}
                            />
                          </DialogContent>
                          <DialogActions>
                            <Button variant="outlined" color="primary" onClick={this.handleCloseDialog}>Cancel</Button>
                            <Button variant="contained" color="primary" onClick={this.handleCreditLimitSave} disabled={this.state.creditLimitValue === ""}>Save</Button>
                          </DialogActions>
                        </Dialog>

                      </div>
                    </div>
                    <div className={`col-6`}>
                      <div className="p-2 home-card sq-shadow text-center">
                        <Typography variant="subtitle1"> Remaining Usability </Typography>
                        <Typography variant="subtitle2"> {this.state.availableLimit === "" ? "Not set yet" : `${Number(this.state.availableLimit).toFixed(2)} OMR`} </Typography>
                        <Button variant="contained" color="primary" size="small" className="mt-2" onClick={() => this.setState({ showUpdateLimitDialog : true })}>
                          Update Usability
                        </Button>

                        <Dialog
                          open={this.state.showUpdateLimitDialog}
                          maxWidth = "xs"
                          fullWidth
                          onClose= {this.handleCloseDialog}
                        >
                          <DialogTitle>
                            Update Credit Limit
                          </DialogTitle>
                          <DialogContent>
                            <TextField
                              variant="outlined"
                              fullWidth
                              label = "Credit Limit"
                              name = "availableLimitValue"
                              onChange = {this.handleChange}
                              error = {parseFloat(this.state.creditLimit) < parseFloat(this.state.availableLimitValue)}
                              helperText = {parseFloat(this.state.creditLimit) < parseFloat(this.state.availableLimitValue) ? "Cannot be more than credit limit" : ""}
                              value = {this.state.availableLimitValue}
                            />
                          </DialogContent>
                          <DialogActions>
                            <Button variant="outlined" color="primary" onClick={this.handleCloseDialog}>Cancel</Button>
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={this.handleAvalilableLimitSave}
                              disabled = {
                                parseFloat(this.state.creditLimit) < parseFloat(this.state.availableLimitValue) ||
                                this.state.availableLimitValue === ""
                              }
                            >
                              Save
                            </Button>
                          </DialogActions>
                        </Dialog>
                      </div>
                    </div>
                  </div>

                </div>
              }
            </Grid>
            <Grid item xs={6}>

              <div>
                <Paper className="sq-shadow mb-3 p-3 text-center">
                  <Typography variant="h4"> Transaction History </Typography>
                </Paper>
                <Paper className="sq-shadow mb-4">
                  <Tabs variant="fullWidth" value={this.state.paymentIndex} onChange = {this.handlePaymentIndexChange}>
                    <Tab label = "Daily" />
                    <Tab label = "Weekly" />
                    <Tab label = "Monthly" />
                    <Tab label = "Total" />
                  </Tabs>
                </Paper>
                <div className="col-12">
                  <div className="row">
                    <div className="col offset-1">
                      <PieChart
                        data={this.state.data}
                        label = {({ data , dataIndex }) => 
                          data[dataIndex].value
                        }
                        className="pie-chart"
                        labelStyle={{
                          fontSize: '5px',
                          fill: '#fff'
                        }}
                        animate
                      />
                    </div>
                    <div className="col-4 d-flex align-items-center">
                      <div>
                        {
                          this.state.data.map((d, ind) =>
                            <div key={ind} className="mt-2 mb-2 d-flex align-items-center text-capitalize"> <span style={{ backgroundColor : d.color, height : "15px", width : "15px" }} className="d-inline-block mr-2"></span> {d.title} &nbsp; - &nbsp; {d.value}</div>
                          )
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {
                this.state.isCenterSelected &&
                <div>
                  <Paper className="sq-shadow mt-4 mb-3 p-3 text-center">
                    <Typography variant="h4"> Children History </Typography>
                  </Paper>
                  <Paper className="sq-shadow mb-4">
                    <Tabs variant="fullWidth" value={this.state.childrenIndex} onChange = {this.handleChildrenIndexChange}>
                      <Tab label = "Daily" />
                      <Tab label = "Weekly" />
                      <Tab label = "Monthly" />
                      <Tab label = "Total" />
                    </Tabs>
                  </Paper>
                  <div className="col-12">
                    <div className="row">
                      <div className="col offset-1">
                        <PieChart
                          data={this.state.childrenData}
                          label = {({ data , dataIndex }) => 
                            data[dataIndex].value
                          }
                          className="pie-chart"
                          labelStyle={{
                            fontSize: '5px',
                            fill: '#fff'
                          }}
                          animate
                        />
                      </div>
                      <div className="col-4 d-flex align-items-center">
                        <div>
                          {
                            this.state.childrenData.map((d, ind) =>
                              <div key={ind} className="mt-2 mb-2 d-flex align-items-center text-capitalize"> <span style={{ backgroundColor : d.color, height : "15px", width : "15px" }} className="d-inline-block mr-2"></span> {d.title} &nbsp; - &nbsp; {d.value}</div>
                            )
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              }

            </Grid>
          </Grid>
        </div>
      </div>
    )
  }
}
export default withRouter(Analysis);