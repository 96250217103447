import React from "react";
import { Document, Page, StyleSheet, View, Text, Font, Image } from "@react-pdf/renderer";
import Header from "../../static/images/pa-schedule-header.jpg";
import Footer from "../../static/images/pa-schedule-footer.jpg";
import Stamp from "../../static/images/pa-schedule-signature.jpg";
import moment from "moment";
import helpers from "../../helpers"
import AffiliateService from "../../services/affiliates";

const styles = StyleSheet.create({
  page : {
    fontSize : 11,
    paddingHorizontal : 30
  },
  headerImage: {
    width: "100%",
    marginVertical: 5
  },
  container : {
    border : "1pt solid black",
    marginTop : 10
  },
  title : {
    borderBottom : "1pt solid black",
    fontWeight : 900,
    padding : 5,
    fontSize : 12
  },
  text_center : {
    textAlign : "center"
  },
  row : {
    display : "flex",
    flexDirection : "row"
  },
  topDetailsRow : {
    paddingVertical : 10,
    paddingHorizontal : 5,
    lineHeight : 1.4
  },
  italicDescription : {
    paddingHorizontal : 5,
    paddingBottom : 12,
    marginTop : 30,
    fontStyle : "italic",
    borderBottom : "1pt solid black"
  },
  detail_column_1 : {
    flexGrow : 1
  },
  detail_column_2 : {
    width : "170pt",
    borderLeft : "1pt solid black",
    textAlign : "right"
  },
  text_right : {
    textAlign : "right"
  },
  total_row : {
    borderTop : "1pt solid black",
    borderBottom : "1pt solid black"
  },
  amount_in_words : {
    borderBottom : "1pt solid black",
    padding : 5
  },
  stamp_row : {
    justifyContent : "space-between",
    alignItems : "flex-end",
    padding : 5
  },
  stamp_container :{
    display : "flex",
    flexDirection : "row",
    justifyContent : "flex-end",
    marginVertical : 15
  },
  stamp: {
    height: 100,
    width: 100
  },
  footer: {
    width: "100%",
    marginTop : 250
  },
});


const CommissionCreditNote = ({
  policy
}) => {

  const policy_number = policy._id;
  const credit_note_date = moment(policy.startDate).format("DD/MM/YYYY");
  const plan_name = policy.plan;
  const insurance_type = policy.insurance_type
  const commission_percentage = policy.commission;

  const amount = policy.amount;
  const base_price = amount.base_price;
  const bmi_value = amount.bmi_value;
  const pa_death_cover = amount.pa_death_cover || 0;
  const life_addon_total = insurance_type === "health" && amount.life_total ? amount.life_total : 0;

  const commission_premium = base_price + bmi_value + pa_death_cover + life_addon_total;
  const commission = Number((commission_premium * commission_percentage) / 100).toFixed(2) + "0";

  const commission_amount_arr = commission.split(".");
  const beforeDot = helpers.getNumberInWords(commission_amount_arr[0]);
  
  let commission_in_words = `${beforeDot}Only`;
  
  if(commission_amount_arr[1]){
    const afterDot = helpers.getNumberInWords(commission_amount_arr[1]);
    commission_in_words = `${beforeDot}and Baisa ${afterDot}Only`;
  }

  const getEndDate = () => {
    let numYears = 1;
    if (["Unified Domestic Worker", "Personal Accident"].includes(policy.cover_type)) {
      numYears = 2
    }

    return moment(policy.startDate).subtract(1, "day").add(numYears, "year").format("DD/MM/YYYY");
  }

  const affiliate_details = policy.affiliate_details;
  
  let account_code = affiliate_details.accountCode

  if(affiliate_details.affiliateSubType === "Company" && affiliate_details.accountCode){
    account_code = affiliate_details.accountCode
  }
  else{
    AffiliateService.getAffiliate(policy.company_affiliate_id).then((res) => {
      const affiliate_data = res.data.data;
      account_code = affiliate_data.accountCode
    })
  }

  return(
    <Document creator = "AXSS" producer = "AXSS" title = "Commission Credit Note">
      <Page style = { styles.page }>
        <Image source={Header} style={styles.headerImage} fixed />
        
        <View style={ styles.container }>
          <View style={[styles.title, styles.text_center]}>
            <Text> Credit Note </Text>
          </View>

          <View style={[styles.topDetailsRow, styles.row]}>
            
            <View style={{ width : "50%" }}>
              <Text> M/S. {affiliate_details.name} </Text>
              <Text> P.O.Box : </Text>
              <Text> P C: MUSCAT, </Text>
              <Text> MUSCAT,</Text>
              <Text> MUSCAT </Text>
            </View>

            <View style={{ width : "50%" }}>
              <View style={styles.row}>
                <View style={{ width : "50%" }}>
                  <Text> Credit Note No. </Text>
                </View>
                <View style={{ width : "50%" }}>
                  <Text> :  {policy_number} </Text>
                </View>
              </View>

              <View style={styles.row}>
                <View style={{ width : "50%" }}>
                  <Text> Date </Text>
                </View>
                <View style={{ width : "50%" }}>
                  <Text> :  {credit_note_date} </Text>
                </View>
              </View>

              <View style={styles.row}>
                <View style={{ width : "50%" }}>
                  <Text> Department </Text>
                </View>
                <View style={{ width : "50%" }}>
                  <Text> :  Retail Underwriting </Text>
                </View>
              </View>

              <View style={styles.row}>
                <View style={{ width : "50%" }}>
                  <Text> Account no. </Text>
                </View>
                <View style={{ width : "50%" }}>
                  <Text> :  {account_code} </Text>
                </View>
              </View>

              <View style={styles.row}>
                <View style={{ width : "50%" }}>
                  <Text> Product name </Text>
                </View>
                <View style={{ width : "50%" }}>
                  <Text> :  {plan_name} </Text>
                </View>
              </View>

            </View>
            
          </View>

          <View style={styles.italicDescription}>
            <Text>
              Please note that your account has been Credited as follows: 
            </Text>
          </View>

          <View style={styles.row}>
            <View style={styles.detail_column_1}>
              <View style={styles.title}>
                <Text>
                  DESCRIPTION
                </Text>
              </View>

              <View style={{ padding : 5 }}>
                <Text>
                  Being Commission for Policy No: {policy_number}
                </Text>

                <Text style={{ marginTop : 10 }}>
                  Total Commission excluding VAT
                </Text>
                <Text>
                  VAT (@5%)
                </Text>
                <Text>
                  Insurance Period: {moment(policy.startDate).format("DD/MM/YYYY HH:MM")}:00 to {getEndDate()} 23:59:59
                </Text>
              </View>
            </View>
            <View style={[styles.detail_column_2, styles.text_right]}>
              <View style={styles.title}>
                <Text>
                  AMOUNT IN OMR
                </Text>
              </View>
              <View style={{ padding : 5 }}>
                <Text>
                  {commission}
                </Text>

                <Text style={{ marginTop : 10 }}>
                  {commission}
                </Text>
              </View>
            </View>
          </View>

          <View style={[styles.total_row, styles.row]}>
            <View style={[styles.detail_column_1, { padding : 5 }]}>
              <Text>
                Total Commission including VAT
              </Text>
            </View>
            <View style={[styles.detail_column_2, { padding : 5 }]}>
              <Text>
                {commission}
              </Text>
            </View>
          </View>

          <View style={styles.amount_in_words}>
            <Text>
              <Text>
                Amount in words :
              </Text>
              <Text>
                &nbsp; RO {commission_in_words}
              </Text>
            </Text>
          </View>

          <View style={[styles.stamp_row, styles.row]}>
            <View>

              <View style={styles.row}>
                <View style={[styles.row, { width : 100, justifyContent : "space-between" }]}>
                  <Text> Prepared By </Text>
                  <Text> : </Text>
                </View>
                <Text style={{ marginLeft : 5 }}> MIS User </Text>
              </View>

              <View style={[styles.row, { marginTop : 8 }]}>
                <View style={[styles.row, { width : 100, justifyContent : "space-between" }]}>
                  <Text> Approved By </Text>
                  <Text> : </Text>
                </View>
                <Text style={{ marginLeft : 5 }}> MIS User </Text>
              </View>

              <View style={[styles.row, { marginTop : 60 }]}>
                <View style={[styles.row, { width : 100, justifyContent : "space-between" }]}>
                  <Text> Issue Date </Text>
                  <Text> : </Text>
                </View>
                <Text style={{ marginLeft : 5 }}> {credit_note_date} </Text>
              </View>

            </View>
            <View style={{ flexGrow : 1 }}>

              <View style={[ styles.row, { justifyContent : "flex-end" }]}>
                <Text>
                  For Insurance Company S.A.O.G
                </Text>
              </View>

              <View style={styles.stamp_container}>
                <Image source={Stamp} style={styles.stamp} />
              </View>

              <View style={[ styles.row, { justifyContent : "flex-end" }]}>
                <Text>
                  Authorized Signatory
                </Text>
              </View>

            </View>
          </View>
        </View>

        <View fixed>
          <Image source={Footer} style={styles.footer} />
        </View>
      </Page>
    </Document>
  )
}

export default CommissionCreditNote;