import React from "react"
import Navigation from "../common/Navigation";
import { Button, Grid, InputAdornment, Paper, Switch, Table, TableBody, TableCell, TableHead, TablePagination, TableRow, TextField, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";
import SuperadminService from "../../services/superadmins";
import { Edit, Search } from "@material-ui/icons";
import Snackbar from "../common/Snackbar";

class ManageAccess extends React.Component{

  state = {
    data : [],
    page : 0,
    rowsPerPage : 10,
    count : 0,
    searchText : ""
  }

  componentDidMount(){
    this.getUsers();
  }

  getUsers = async () => {
    const page = this.state.page;
    const limit = this.state.rowsPerPage;
    const search = this.state.searchText === "" ? undefined : this.state.searchText;

    SuperadminService.getUsers(page, limit, search).then((res) => {
      console.log(res.data);
      const resData = res.data;
      this.setState({
        data : resData.data,
        count : resData.count
      })
    })
  }

  handleChangePage = (e, page) => {
    this.setState({ page }, this.getAffiliates);
  }

  handleChangeRowsPerPage = (e) => {
    this.setState({ rowsPerPage : e.target.value }, this.getAffiliates);
  }

  handleActiveChange = (checked, id) => {
    const obj = { is_active : checked };
    SuperadminService.updateUser(id, obj).then((res) => {
      if(res.data.updated){
        this.getUsers();
        this.setState({
          showSnackbar : true,
          snackBarMessage : "Status Changed Successfully",
          messageType : "success"
        })
      }
    })
  }

  handleCloseSnackbar = () => {
    this.setState({ showSnackbar : false });
  }

  handleSearchChange = (e) => {
    this.setState({
      searchText : e.target.value,
      page : 0
    }, this.getUsers)
  }

  render(){
    return(
      <div className="d-flex">
        <Snackbar
          showSnackbar = { this.state.showSnackbar }
          messageType = {this.state.messageType}
          snackBarMessage = {this.state.snackBarMessage}
          onCloseSnackbar = {this.handleCloseSnackbar}
        />
        <Navigation />
        <div className="container-fluid">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Paper className="p-2 sq-shadow d-flex">
                <Typography><Link to={`/home`}>Home</Link></Typography>
                <Typography className="mr-2 ml-2">/</Typography>
                <Typography>Manage Access</Typography>
              </Paper>
            </Grid>

            <Grid item xs={12}>
              <div className="d-flex justify-content-between align-items-center">
                <Link to = "/manage-access/create" className = "d-block">
                  <Button variant="contained" color="primary"> Create New </Button>
                </Link>

                <TextField
                  variant = "outlined"
                  label = "Search"
                  placeholder = "Search by name or email"
                  onChange = {this.handleSearchChange}
                  value = {this.state.searchText}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Search />
                      </InputAdornment>
                    ),
                  }}
                  style={{
                    width : "400px"
                  }}
                />
              </div>
            </Grid>

            <Grid item xs={12}>
              <Paper className="sq-shadow policies-table-container">
                <Table>

                  <TableHead>
                    <TableRow>
                      <TableCell> Name </TableCell>
                      <TableCell> Email </TableCell>
                      <TableCell> Is Active </TableCell>
                      <TableCell> Edit </TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {
                      this.state.data.map((user) => 
                      <TableRow key={user._id}>
                        <TableCell> {user.name} </TableCell>
                        <TableCell> {user.email} </TableCell>
                        <TableCell>
                          <Switch
                            checked = {user.is_active}
                            onChange = {(e) => this.handleActiveChange(e.target.checked, user.uid)}
                          />
                        </TableCell>
                        <TableCell>
                          <Link to={`/manage-access/edit/${user.uid}`} className="table-eye-link">
                            <Edit />
                          </Link>
                        </TableCell>
                      </TableRow>
                      )
                    }
                    <TableRow>
                      <TablePagination
                        count = {this.state.count}
                        page = {this.state.page}
                        rowsPerPage = {this.state.rowsPerPage}
                        onChangePage = {this.handleChangePage}
                        onChangeRowsPerPage = {this.handleChangeRowsPerPage}
                        rowsPerPageOptions = {[ 10,25,50 ]}
                      />
                    </TableRow>
                  </TableBody>
                </Table>
              </Paper>
            </Grid>
          </Grid>
        </div>
      </div>
    )
  }
}

export default ManageAccess;