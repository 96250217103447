import { Grid, Paper, Tab, Tabs } from "@material-ui/core";
import React from "react";
import RiyatiPlan from "./Riyati/RiyatiIndividual";

class ModifyPremiumsHome extends React.Component {

    life_plans = [
        { plan_type: "Riyati Plan" }
    ]

    state = {
        plan_type: 0
    }

    componentDidMount() {
        let plan_type = "Riyati Plan";
        this.loadDetails(plan_type)
    }

    loadDetails(plan_type) {
        plan_type = this.life_plans.findIndex(plan => plan.plan_type === plan_type);
        this.setState({ plan_type })
    }

    changePlanType = (e, value) => {
        this.setState({ plan_type: value })
    }

    render() {
        return (
            <>
                <Grid item xs={12}>
                    <Paper className="sq-shadow">
                        <Tabs variant="fullWidth" value={this.state.plan_type} onChange={this.changePlanType}>
                            {
                                this.life_plans.map((plan, ind) => (<Tab label={plan.plan_type} key={ind} />))
                            }
                        </Tabs>
                    </Paper>
                </Grid>
                {this.state.plan_type === 0 && <RiyatiPlan />}
            </>
        )
    }
}
export default ModifyPremiumsHome;