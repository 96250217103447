import { Button, Grid, Paper, TextField, Typography, FormGroup, FormControlLabel, Checkbox, TableContainer, Table, TableCell, TableRow, TableBody, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@material-ui/core";
import React from "react";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import Navigation from "../common/Navigation";
import Snackbar from "../common/Snackbar";
import ChannelService from "../../services/channel";
import CreatableSelect from "react-select/creatable"
import AffiliateService from "../../services/affiliates";
import AffiliateAppService from "../../services/affiliateApp"
import _ from "lodash"

class AffiliateAppShow extends React.Component {

    state = {
        isLoading: false,
        showSnackbar: false,
        snackBarMessage: "",
        messageType: "",
        isEditing: false,
        channel_id: '',
        selectedAffiliate: null,
        app: {},
        dialogOpen: false,
        showSecret: false,
        appSecret: null
    }

    async componentDidMount() {
        this.affiliateApp()
    }

    handleClickOpen = () => {
        this.setState({ dialogOpen: true })
    };

    handleClose = () => {
        this.setState({ dialogOpen: false })
    };
    affiliateApp = async () => {
        this.setState({ isLoading: true });
        try {
            let response = await AffiliateAppService.showAllAffiliateApp(this.props.match.params.affiliate_id, this.props.match.params.app_id);
            if (response.data && response.data.success) {
                this.setState({ isLoading: false, app: response.data.data })
            }
            else {
                this.setState({
                    isLoading: false,
                    showSnackbar: true,
                    snackBarMessage: "Something went wrong.",
                    messageType: "danger"
                })
            }
        } catch (error) {
            this.setState({
                isLoading: false,
                showSnackbar: true,
                snackBarMessage: "Something went wrong.",
                messageType: "danger"
            })
        }
    }

    changeAffiliateAppSecret = async () => {
        this.setState({ isLoading: true });
        try {
            let response = await AffiliateAppService.changeAppSecret(this.props.match.params.affiliate_id, this.props.match.params.app_id);
            if (response.data && response.data.success) {
                this.setState({
                    isLoading: false,
                    showSnackbar: true,
                    snackBarMessage: response.data.message,
                    messageType: "success"
                })
                this.getAppSecret();
                this.setState({ dialogOpen: false })
            }
            else {
                this.setState({
                    isLoading: false,
                    showSnackbar: true,
                    snackBarMessage: "Something went wrong.",
                    messageType: "danger"
                })
            }
        } catch (error) {
            this.setState({
                isLoading: false,
                showSnackbar: true,
                snackBarMessage: "Something went wrong.",
                messageType: "danger"
            })
        }
    }

    getAppSecret = async () => {
        this.setState({ isLoading: true });
        try {
            let response = await AffiliateAppService.getAffiliateAppSecret(this.props.match.params.affiliate_id, this.props.match.params.app_id);
            if (response.data && response.data.success) {
                this.setState({ isLoading: false, showSecret: true, appSecret: response.data.data.app_secret })
            }
            else {
                this.setState({
                    isLoading: false,
                    showSnackbar: true,
                    snackBarMessage: "Something went wrong.",
                    messageType: "danger"
                })
            }
        } catch (error) {
            this.setState({
                isLoading: false,
                showSnackbar: true,
                snackBarMessage: "Something went wrong.",
                messageType: "danger"
            })
        }
    }

    render() {
        return (
            <React.Fragment>
                <div className="d-flex">
                    <Navigation />

                    <Snackbar
                        showSnackbar={this.state.showSnackbar}
                        messageType={this.state.messageType}
                        snackBarMessage={this.state.snackBarMessage}
                        onCloseSnackbar={this.handleCloseSnackbar}
                    />

                    <div className="container-fluid">
                        <Paper className="p-2 sq-shadow mb-3 d-flex">
                            <Typography><Link to={`${process.env.PUBLIC_URL}/`}>Home</Link></Typography>
                            <Typography className="mr-2 ml-2">/</Typography>
                            <Typography><Link to={`${process.env.PUBLIC_URL}/affiliator`}>Affiliators</Link></Typography>
                            <Typography className="mr-2 ml-2">/</Typography>
                            <Typography><Link to={`${process.env.PUBLIC_URL}/affiliator/dhofar-api-kit`}>Dhofar Api Kit</Link></Typography>
                            <Typography className="mr-2 ml-2">/</Typography>
                            <Typography>{this.state.app.affiliateApp?.app_name}</Typography>
                        </Paper>

                        <Grid container spacing={6}>

                            <Grid item xs={9}>
                                <Paper className="p-3">
                                    <TableContainer>
                                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell style={{ fontWeight: 600 }}>Affiliate Name</TableCell>
                                                    <TableCell>: {this.state.app.affiliate_name}</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell style={{ fontWeight: 600 }}>App Name</TableCell>
                                                    <TableCell>: {this.state.app.affiliateApp?.app_name}</TableCell>
                                                </TableRow>

                                                <TableRow>
                                                    <TableCell style={{ fontWeight: 600 }}>App Id</TableCell>
                                                    <TableCell>: {this.state.app.affiliateApp?.app_id}</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell style={{ fontWeight: 600 }}>App Secret</TableCell>
                                                    <TableCell>
                                                        <React.Fragment>
                                                            {
                                                                !this.state.showSecret ?
                                                                    <>
                                                                        <div className="d-flex">
                                                                            <Typography style={{ filter: "blur(4px)" }} className="mt-2">k7V3!onL6XaP$RE_Q6BCK?QlyX$5vXwG7$lgS1?IAJm3c8Sl_puq#Da5MNjF</Typography>
                                                                            <Button variant="contained" color="primary" className="ml-2"
                                                                                onClick={this.getAppSecret}
                                                                                disabled={this.state.isLoading ? true : false}
                                                                            >Show App Secret</Button>
                                                                        </div>
                                                                    </>
                                                                    :
                                                                    <>
                                                                        <div className="d-flex">
                                                                            <Typography className="mt-2">: {this.state.appSecret}</Typography>
                                                                            <Button variant="contained" color="primary" className="ml-2"
                                                                                onClick={() => this.setState({ showSecret: false })}
                                                                                disabled={this.state.isLoading ? true : false}
                                                                            >Hide App Secret</Button>
                                                                        </div>
                                                                    </>


                                                            }
                                                        </React.Fragment>

                                                    </TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Paper>
                            </Grid>
                            <Grid item xs={3} className="change-secret-btn">
                                <Button variant="contained" color="primary"
                                    onClick={this.handleClickOpen}
                                >Change App Secret</Button>
                            </Grid>
                            <Grid item xs={12}>
                                <Paper className="sq-shadow p-2 d-flex justify-content-end">

                                    <Link to="/affiliator/dhofar-api-kit" className="d-inline-block mr-3">
                                        <Button variant="outlined" color="primary">Back</Button>
                                    </Link>
                                </Paper>
                            </Grid>

                        </Grid>
                    </div>

                </div>
                <div>
                    <Dialog
                        open={this.state.dialogOpen}
                        onClose={this.handleClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        {/* <DialogTitle id="alert-dialog-title">
                            {"Use Google's location service?"}
                        </DialogTitle> */}
                        <DialogContent>
                            <Typography> Are you sure you want to change Affiliate App secret?</Typography>
                        </DialogContent>
                        <DialogActions>
                            <Button variant="outlined" color="primary" onClick={this.handleClose}>Cancel</Button>
                            <Button variant="contained" color="primary"
                                onClick={this.changeAffiliateAppSecret}
                                disabled={this.state.isLoading ? true : false}>
                                Change
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div>
            </React.Fragment>
        )

    }

}

export default withRouter(AffiliateAppShow);