import React from 'react';
import { Document, Page, StyleSheet, View, Text, Image, Font } from "@react-pdf/renderer";
import DhofarLogo from "../../static/images/dhofar-full-logo.png";
import helpers from "../../helpers";
import Arabic from "../../static/fonts/Arabic/A_Nefel_Adeti.ttf";
import { PLAN_TYPES } from '../../constants';
import utils from '../../services/utils';

Font.register({ family : "Arabic", src : Arabic });

const styles = StyleSheet.create({
  page : {
    fontSize : 11,
    paddingHorizontal : 50,
    paddingVertical : 50,
    fontFamily : "Arabic",
    color : "black"
  },
  outerContainer : {
    border : "1pt solid #ccc",
    borderRadius : "5pt"
  },
  imageContainer : {
    paddingVertical : 15,
    display : "flex",
    alignItems : "center"
  },
  dhofarLogo : {
    height : 50
  },
  borderTop : {
    borderTop : "1pt solid #ccc"
  },
  borderBottom : {
    borderBottom : "1pt solid #ccc"
  },
  receiptTitle : {
    fontSize : 14,
    fontWeight : "extrabold",
    textAlign : "center",
    paddingVertical : 8
  },
  policyNumber : {
    fontWeight : "extrabold",
  },
  row : {
    display : "flex",
    flexDirection : "row",
    justifyContent : "space-between",
    alignItems : "center",
    paddingVertical : 4,
    paddingHorizontal : 10
  },
  top_detail_container : {
    alignItems : "flex-start"
  },
  padding8 : {
    paddingVertical : 8
  },
  amount : {
    fontWeight : "extrabold"
  },
  mt8 : {
    marginTop : 8,
  },
  w50 : {
    width : "50%"
  },
  w40 : {
    width : "40%"
  },
  w10 : {
    width : "10%"
  },
  top_detail_row : {
    paddingHorizontal : 0
  }
})

const getFormattedDate = (dateMS) => {
  const dateObj = new Date(dateMS);
  const date = dateObj.getDate();
  const month = dateObj.getMonth() + 1;
  const year = dateObj.getFullYear();

  return `${date < 10 ? "0" + date : date}/${month < 10 ? "0" + month : month}/${year}`;
}

const getAmountInWords = (amount) => {
  const amountArr = amount.toString().split(".");
  const beforeDot = helpers.getNumberInWords(amountArr[0]);
  
  let totalInWords = `${beforeDot}Only`;
  
  if(parseInt(amountArr[1])){
    const afterDot = helpers.getNumberInWords(amountArr[1]);
    totalInWords = `${beforeDot}and Baisa ${afterDot}Only`;
  }

  return totalInWords;
}

const VatDebitNote = ({ policy, payment_mode, amount, full_name, member_id, has_li_addon, li_addon_type, start_date, nationality, vat_in, resident_location }) => {
  return(
    <Document creator = "Dhofar" producer = "Dhofar" title = "Payment Receipt">
      <Page style = { styles.page }>
        <View style = {styles.outerContainer}>
          <View style = {styles.imageContainer}>
            <Image src = {DhofarLogo} style = {styles.dhofarLogo} />
          </View>

          <View style = {[styles.receiptTitle, styles.borderTop, styles.borderBottom]}>
            <Text>
              Tax Invoice
            </Text>
          </View>

          <View style = { styles.descreptionContainer }>
            <View style = {[styles.row, styles.top_detail_container]}>
              <View style = {styles.w50}>
                
                {/* Supplier Name */}
                <View style = {[styles.row, styles.top_detail_row]}>
                  <View>
                    <Text style = {styles.policyNumber}>Supplier Name</Text>
                    <Text>DHOFAR INSURANCE COMPANY SAOG</Text>
                  </View>
                </View>
                <View style = {[styles.row, styles.top_detail_row]}>
                  <View>
                    <Text style = {styles.policyNumber}>Supplier Address</Text>
                    <Text>1/ 2, 3716,</Text>
                    <Text>P.O Box : 1002, Postal Code 112,</Text>
                    <Text>Mutrah, Muscat,</Text>
                    <Text>Muttrah,</Text>
                    <Text>Sultanate of Oman</Text>
                  </View>
                </View>

                <View style = {[styles.row, styles.top_detail_row, styles.policyNumber]}>
                  <Text>Supplier VATIN - OM1100005435</Text>
                </View>

                {/* Customer Details */}
                <View style = {[styles.row, styles.top_detail_row]}>
                  <View>
                    <Text style = {styles.policyNumber}>Customer Name</Text>
                    <Text>{full_name}</Text>
                  </View>
                </View>
                <View style = {[styles.row, styles.top_detail_row]}>
                  <View>
                    <Text style = {styles.policyNumber}>Customer Address</Text>
                    <Text>{resident_location},</Text>
                    <Text>{nationality}</Text>
                  </View>
                </View>

                <View style = {[styles.row, styles.top_detail_row, styles.policyNumber]}>
                  <Text> Customer VATIN : {vat_in || "N/A"}</Text>
                </View>

              </View>

              {/* Invoice Number */}
              <View style = {styles.w50}>
                <View style = {[styles.row, styles.top_detail_row]}>
                  <View style = {styles.w40}>
                    <Text>
                      Invoice No
                    </Text>
                  </View>
                  <View style = {styles.w10}>
                    <Text>
                      :
                    </Text>
                  </View>
                  <View style = {styles.w50}>
                    <Text>
                      <Text style = {styles.policyNumber}>{member_id}</Text>
                    </Text>
                  </View>
                </View>

                {/* Invoice Date */}
                <View style = {[styles.row, styles.top_detail_row]}>
                  <View style = {styles.w40}>
                    <Text>
                      Invoice Date
                    </Text>
                  </View>
                  <View style = {styles.w10}>
                    <Text>
                      :
                    </Text>
                  </View>
                  <View style = {styles.w50}>
                    <Text>
                      <Text style = {styles.policyNumber}>{getFormattedDate(start_date)}</Text>
                    </Text>
                  </View>
                </View>

                {/* Bill Date */}
                <View style = {[styles.row, styles.top_detail_row]}>
                  <View style = {styles.w40}>
                    <Text>
                      Date of Supply
                    </Text>
                  </View>
                  <View style = {styles.w10}>
                    <Text>
                      :
                    </Text>
                  </View>
                  <View style = {styles.w50}>
                    <Text>
                      <Text style = {styles.policyNumber}>{getFormattedDate(start_date)}</Text>
                    </Text>
                  </View>
                </View>

                {/* Due Date */}
                <View style = {[styles.row, styles.top_detail_row]}>
                  <View style = {styles.w40}>
                    <Text>
                      Due Date
                    </Text>
                  </View>
                  <View style = {styles.w10}>
                    <Text>
                      :
                    </Text>
                  </View>
                  <View style = {styles.w50}>
                    <Text>
                      <Text style = {styles.policyNumber}>{getFormattedDate(start_date)}</Text>
                    </Text>
                  </View>
                </View>

              </View>
            </View>

            <View style = {[ styles.row, styles.borderTop, styles.borderBottom ]}>
              <Text>Payment Mode</Text>
              <Text style = { styles.amount }>{payment_mode}</Text>
            </View>

            {
              has_li_addon &&
              <View style = {[ styles.row, styles.borderBottom ]}>
                <Text>Medical</Text>
              </View>
            }

            <View style = {[ styles.row, styles.mt8 ]}>
              <Text>Basic Premuim</Text>
              <Text style = { styles.amount }>{Number(amount.base_price).toFixed(3)} OMR</Text>
            </View>
            <View style = { styles.row }>
              <Text>Policy Fee</Text>
              <Text style = { styles.amount }>{Number(amount.policy_fee).toFixed(3)} OMR</Text>
            </View>
            <View style = { styles.row }>
              <Text>Government Fee</Text>
              <Text style = { styles.amount }>{Number(amount.government_fee).toFixed(3)} OMR</Text>
            </View>
            <View style = { styles.row }>
              <Text>Emergency Fund Fee</Text>
              <Text style = { styles.amount }>{Number(amount.emergency_fund_fee).toFixed(3)} OMR</Text>
            </View>
            {
              amount.bmi_value > 0 &&
              <View style = { styles.row }>
                <Text>Loading Fee</Text>
                <Text style = { styles.amount }>{Number(amount.bmi_value).toFixed(3)} OMR</Text>
              </View>
            }

            {
              has_li_addon &&
              <View style = {[ styles.row, styles.borderTop, styles.borderBottom, styles.mt8 ]}>
                <Text>Life</Text>
              </View>
            }

            {
              has_li_addon &&
              <>
              <View style = {[ styles.row, styles.mt8 ]}>
                <Text>Basic Premuim</Text>
                <Text style = { styles.amount }>{Number(amount.life_base_amount).toFixed(3)} OMR</Text>
              </View>
              <View style = { styles.row }>
                <Text>Policy Fee</Text>
                <Text style = { styles.amount }>{Number(amount.life_policy_fee).toFixed(3)} OMR</Text>
              </View>
              <View style = { styles.row }>
                <Text>Government Fee</Text>
                <Text style = { styles.amount }>{Number(amount.life_government_fee).toFixed(3)} OMR</Text>
              </View>
              <View style = { styles.row }>
                <Text>Emergency Fund Fee</Text>
                <Text style = { styles.amount }>{Number(amount.life_emergency_fund_fee).toFixed(3)} OMR</Text>
              </View>
              {
                (li_addon_type === PLAN_TYPES.personal_accident) && 
                <View style = { styles.row }>
                  <Text>Death Cover</Text>
                  <Text style = { styles.amount }>{Number(amount.pa_death_cover).toFixed(3)} OMR</Text>
                </View>
              }
              </>
            }

            <View style = {[ styles.row, styles.borderTop, styles.borderBottom, styles.mt8 ]}>
              <Text>Taxable Value</Text>
              <Text style = { styles.amount }>{Number((policy.insurance_type === "riyati" ? amount.base_price + amount.policy_fee + amount.government_fee + amount.emergency_fund_fee : amount.total - amount.vat)).toFixed(3)} OMR</Text>
            </View>

            <View style = { styles.row }>
              <Text>Applicable VAT (5%)</Text>
              <Text style = { styles.amount }>{Number(amount.vat).toFixed(3)}</Text>
            </View>

            <View style = {[ styles.row, styles.padding8, styles.borderTop, styles.borderBottom ]}>
              <Text>Round Off</Text>
              <Text style = { styles.amount }>{Number(utils.getRoundOffValue(policy)).toFixed(3)} OMR</Text>
            </View>

            <View style = {[ styles.row, styles.padding8, styles.borderTop, styles.borderBottom ]}>
              <Text>Total Consideration Including VAT</Text>
              <Text style = { styles.amount }>{Number(amount.total).toFixed(2)} OMR</Text>
            </View>
            
            <View style = {[ styles.padding8 ]}>
              <View style = {[ styles.row ]}>
                <Text>Total Consideration Including VAT (in words)</Text>
              </View>
              <View style = {[ styles.row ]}>
                <Text style = { styles.amount }>OMR {getAmountInWords(amount.total)}</Text>
              </View>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  )
}
export default VatDebitNote;