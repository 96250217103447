import Axios from "axios";
import config from "./config";

let riyati = {};

riyati.getPolicies = async (startDate, endDate, status, page, limit) => {
    const centerId = sessionStorage.getItem("center_id");
    const channel_id = sessionStorage.getItem("channel_id");
    const sub_channel_id = sessionStorage.getItem("sub_channel_id");
    const url = `${config.baseUrl}/riyati-policies?startDate=${startDate}&endDate=${endDate}${status ? `&status=${status}` : ""}${centerId ? `&center=${centerId}` : ""}${page !== undefined ? `&page=${page}` : ""}${limit ? `&limit=${limit}` : ""}${channel_id ? `&channel_id=${channel_id}` : ""}${sub_channel_id ? `&sub_channel_id=${sub_channel_id}` : ""}`;
    const res = await Axios({ url });
    return res;
};

export default riyati;