import React from "react";
import Navigation from "../common/Navigation";
import { Grid, TextField, MenuItem, Button, Table, TableHead, TableBody, TableRow, TableCell, Paper, Typography, TablePagination } from "@material-ui/core";
import "./style.css";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import PolicyService from "../../services/policy";
import Eye from "mdi-react/EyeIcon";
import { Link, withRouter } from "react-router-dom";
import { saveAs } from "file-saver";
import AffiliateService from "../../services/affiliates";
import AffiliateSelect from "../common/AffiliateSelect";
import _ from "lodash";
import helpers from "../../helpers";
import { REPORT_STATUS_OPTIONS, REPORT_SOB_OPTIONS } from "../../constants";
import moment from "moment";
import BranchList from "../../services/branches.json";
import RegionList from "../../services/region.json";

const months = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"]

class IndividualMedicalReport extends React.Component {
  state = {
    application: "self",
    startDate: null,
    endDate: null,
    showTable: false,
    data: [],
    page: 0,
    rowsPerPage: 10,
    totalRecords: 0,
    headings: [],
    isLoading: false,
    isDownloading: false,
    status: "All",
    loadedPercentage: 0,
    sob : "All",
    region : "All",
    branch : "All"
  }
  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    });
  }

  handleStartDateChange = (date) => {
    const startDate = new Date(date.setHours(0, 0, 0, 0)).getTime();
    this.setState({ startDate });
  }

  handleEndDateChange = (date) => {
    const endDate = new Date(date.setHours(23, 59, 59, 999)).getTime();
    this.setState({ endDate });
  }

  componentDidMount() {
    let date = new Date().setHours(23, 59, 59, 999);
    let endDate = new Date(date).getTime();
    this.setState({
      startDate: new Date(new Date().setHours(0, 0, 0, 0)).getTime(),
      endDate
    });

  }

  handleChangePage = (e, page) => {
    this.setState({ page }, this.handleSearch);
  }

  handleChangeRowsPerPage = (e) => {
    this.setState({ rowsPerPage: e.target.value }, this.handleSearch);
  }

  handleSearch = async () => {
    this.setState({ isLoading: true });
    const status = this.state.status === "All" ? undefined : this.state.status;
    const sob = this.state.sob === "All" ? undefined : this.state.sob;
    const region = this.state.region === "All" ? undefined : this.state.region;
    const branch = this.state.branch === "All" ? undefined : this.state.branch;

    const data = await PolicyService.getHealthReport({
      startDate : this.state.startDate,
      endDate : this.state.endDate,
      type : "self",
      status,
      page : this.state.page,
      limit : this.state.rowsPerPage,
      sob : sob,
      region : region,
      branch : branch
    });

    if (data.status === 200) {
      this.setState({ showTable: true, data: data.data.data, totalRecords: data.data.count })
    }

    let headings = [
      "Sr No",
      "Policy Number",
      "Card Number",
      "Civil ID",
      "Name Of policy holder",
      "DOB",
      "Gender",
      "Occupation",
      "Occupation Code",
      "Passport / Civil ID Expiry date",
      "Marital Status",
      "Nationality",
      "Policy Start Date",
      "Policy End Date",
      "Email ID",
      "Contact No",
      "Application Type",
      "Net Premium",
      "Discount Code",
      "Discount",
      "Policy Fee",
      "Emergency Fund Fee",
      "Goverment Fee",
      "Gross Premium",
      "VAT",
      "Loading",
      "Plan Name",
      "Network",
      "Annual Limit",
      "Op Deductible",
      "PM Co Pay",
      "Payment Status",
      "Policy Status"
    ];
    if (["Active"].includes(this.state.status)) {
      headings.push("Payment Mode", "Payment Reference Number");
    }
    headings.push( "Company", "Partner Branch Name", "Channel Name", "Sub Channel Name", "Employee ID", "Employee Name", "Employee EmailID", "Employee Designation", "Location/Branch-Id", "Location/Branch-Name" ,"Zone/Region" ,"Reporting Manager" ,"Reporting Mgr email id" ,"Employee Contact No.", "comment" , "View");
    this.setState({ isLoading: false, headings });
  }

  getFormattedDate = (date) => {
    return moment(date).format("DD-MM-YYYY");
  }

  getExpiryDate = (startDate, cover_type) => {
    const numYears = cover_type === "Unified Domestic Worker" ? 2 : 1;
    return moment(startDate).subtract(1, "day").add(numYears, "year").format("DD-MM-YYYY");
  }

  downloadData = async () => {
    this.setState({ isDownloading: true, showProcessPopup: true });
    
    const status = this.state.status === "All" ? undefined : this.state.status;
    const sob = this.state.sob === "All" ? undefined : this.state.sob;
    const region = this.state.region === "All" ? undefined : this.state.region;
    const branch = this.state.branch === "All" ? undefined : this.state.branch;

    const res = await PolicyService.getHealthReport({
      startDate : this.state.startDate,
      endDate : this.state.endDate,
      type : "self",
      status,
      sob : sob,
      region : region,
      branch : branch
    });
    const data = res.data.data;
    let csvArr = [];
    let newHeadings = [...this.state.headings];
    newHeadings.splice(-1, 1);
    csvArr.push(newHeadings.join(","));
    data.forEach((policy, ind) => {
      const maritalStatus = policy.personalInformation.isMarried === "yes" ? "Married" : "Single";
      const startDate = this.getFormattedDate(policy.startDate);
      const endDate = this.getExpiryDate(policy.startDate, policy.cover_type);
      const base_price = policy.amount ? helpers.getBasePrice(policy.amount) : 0;
      const discount_code = policy.amount ? helpers.getDiscountCode(policy.amount) : 0;
      const discount = policy.amount ? helpers.getDiscount(policy.amount) : "-";
      const policy_fee = policy.amount ? policy.amount.policy_fee : 0;
      const emergency_fund_fee = policy.amount ? policy.amount.emergency_fund_fee : 0;
      const government_fee = policy.amount ? policy.amount.government_fee : 0;
      const amount = policy.amount ? policy.amount.total : 0;
      const paymentStatus = policy.payments && policy.payments.isPaid ? "Paid" : "Unpaid";
      const bmiValue = _.get(policy.amount, "bmi_value", 0)
      const vatValue = _.get(policy.amount, "vat", 0)

      let arr = [
        ind + 1,
        policy._id,
        policy.policy_num,
        policy.civil_id,
        policy.personalInformation.fullName,
        policy.personalInformation.dob,
        policy.personalInformation.gender,
        `"${policy.personalInformation.occupation}"` || "-",
        policy.personalInformation.occupation_code || "-",
        policy.personalInformation.id_expiry_date || "-",
        maritalStatus,
        policy.nationality,
        startDate,
        endDate,
        policy.personalInformation.email,
        policy.personalInformation.mobileNumber,
        policy.applicationType,
        base_price,
        discount_code,
        discount,
        policy_fee,
        emergency_fund_fee,
        government_fee,
        amount,
        vatValue,
        bmiValue,
        policy.plan,
        policy.network === "Silver" ? "Silver Premium" : policy.network,
        `"${policy.annual_limit}" OMR`,
        `${policy.op} OMR`,
        policy.co_pay,
        paymentStatus,
        policy.status
      ];
      if (["Active"].includes(this.state.status)) {
        const paymentMode = policy.payments.mode === "Link" ? "Card" : policy.payments.mode;
        arr.push(
          paymentMode,
          policy.payments.mode !== "Cash" ? policy.payments.payment_id : "-"
        )
      }
      arr.push(policy.company_affiliate_name ? policy.company_affiliate_name : "-")
      arr.push(policy.affiliate_partner_name ? policy.affiliate_partner_name : "-")
      arr.push(policy.channel_name ? policy.channel_name : "-")
      arr.push(policy.sub_channel_name ? policy.sub_channel_name : "-")
      arr.push(policy.affiliate_employee_id ? policy.affiliate_employee_id : "-")
      arr.push(policy.affiliate_name ? policy.affiliate_name : "AXSS")
      arr.push(policy.affiliate_email ? policy.affiliate_email : "-")
      arr.push(policy.affiliate_employee_designation ? policy.affiliate_employee_designation : "-")
      arr.push(policy.branch_id ? policy.branch_id : "-")
      arr.push(policy.branch_name ? policy.branch_name : "-")
      arr.push(policy.region_name ? policy.region_name : "-")
      arr.push(policy.branch_manager_name ? policy.branch_manager_name : "-")
      arr.push(policy.branch_manager_email ? policy.branch_manager_email : "-")
      arr.push(policy.affiliate_phone ? policy.affiliate_phone : "-")
      arr.push(policy.comment ? `"${policy.comment}"` : "-")
      csvArr.push(arr.join(","));
    });

    const csvFile = new Blob([csvArr.join("\n")], { type: "text/csv" });
    saveAs(csvFile, "medical-dash.csv");
    this.setState({ isDownloading: false, showProcessPopup: false });
  }

  downloadLegacyData = async () => {
    this.setState({ isDownloadingLegacy: true });
    const type = this.state.application === "All" ? undefined : this.state.application;
    const status = this.state.status === "All" ? undefined : this.state.status;
    const res = await PolicyService.getAllDashPolicies(this.state.startDate, this.state.endDate, type, status);
    const data = res.data.data;
    const fields = [
      "INTREGNO",
      "REGCODE",
      "COMPCODE",
      "BRANCHCODE",
      "REGDATE",
      "DEPTNO",
      "CLASSCODE",
      "SUBCLASS",
      "SPONSORCODE",
      "POLYTYPECODE",
      "SPONSORNAME",
      "SPONSORADDRESS",
      "SPONSORADDRESS1",
      "SPONSORMOBILE",
      "SPONSORTELNO",
      "SPONSORREMARKS",
      "POLICYHOLDERNAME",
      "SEX",
      "ADDRESS",
      "ADDRESS1",
      "TELNO",
      "MOBILENO",
      "CUSTTYPE",
      "AGE",
      "OCCUPATIONCODE",
      "NATIONALITYCODE",
      "PASSPORTNO",
      "CIVILNO",
      "PROFESSIONCODE",
      "DESTINATION",
      "DOB",
      "ASSURERAGE",
      "STREET",
      "BLDNO",
      "FLATNO",
      "BLOCKNO",
      "WAYNO",
      "OFFICEADDRESS",
      "HOLDERADDRESS",
      "HOLDERREMARKS",
      "LEGALERNAME",
      "LEGALERCIVILNO",
      "LEGALERNATIONALITY",
      "LEGALERPROFESSION",
      "LEGALERADDRESS",
      "LEGALERADDRESS1",
      "LEGALERREMARKS",
      "POLYPERIODCODE",
      "POLYPERIOD",
      "POLYFROM",
      "POLYTO",
      "POLYPERIODREMARKS",
      "INSUREDAMOUNT",
      "COVERAGECODE",
      "PREMIUMAMOUNT",
      "POLICYFEES",
      "GOVTFEES",
      "EMERGENCYFEES",
      "FUNDFEES",
      "DEATHCOVERAMOUNT",
      "TOTALAMOUNT",
      "POLYCOVERAGEREMARKS",
      "REMARKS",
      "INTPLYSTATUS",
      "FLXFILEDNO",
      "FLXFIELDNO1",
      "FLXFIELDSTR",
      "FLXFIELDSTR1",
      "INSERTDATE",
      "INSERTUSER",
      "UPDATEDATE",
      "UPDATEUSER",
      "INTPOLICYSEQNO",
      "POLICYNO",
      "SCHEMENO",
      "AXSSBRANCHNO",
      "INTPRODUCERNO",
      "COMMISONPERCENTAGE",
      "INTDAY",
      "INTMONTH",
      "INTYEARID",
      "INTHOURS",
      "INTMINS"
    ];

    let csvArr = [];
    csvArr.push(fields.join(","));
    for (let policy of data) {
      const { affiliate_parents, affiliate_id, insurance_type, cover_type, applicationType, personalInformation, amount } = policy;
      let branch = "";
      if (affiliate_id) {
        const affiliates = [
          ...affiliate_parents,
          affiliate_id
        ]
        for (let affiliateId of affiliates) {
          const affiliateData = await AffiliateService.getAffiliate(affiliateId);
          const affiliate = affiliateData.data.data;
          const affiliateName = affiliate.name.trim();
          const affiliateInd = affiliates.indexOf(affiliateId);
          branch += `${affiliateInd === 0 ? "" : "/"}${affiliateName}`;
        }
      }
      else {
        branch = "AXSS";
      }

      const commissionPercentage = affiliate_id ? parseInt(policy.commission) : 0;
      let commissionAmount = "0.000";
      if (isNaN(commissionPercentage) && commissionPercentage > 0 && policy.payments && policy.payments.isPaid) {
        commissionAmount = Number(Math.round((policy.amount.total * commissionPercentage) / 100)).toFixed(3)
      }
      const startDate = this.getFormattedDate(policy.startDate);
      const endDate = this.getExpiryDate(policy.startDate, cover_type)
      const arr = [
        policy._id,
        "",
        "AXSS",
        branch,
        startDate,
        insurance_type === "life" ? "06" : "07",
        insurance_type === "life" ? "600" : "700",
        cover_type === "Individual Medical" ? "705" : "",
        "",
        "",
        applicationType === "domestic" ? personalInformation.sponsorName : "",
        "",
        "",
        "",
        "",
        "",
        "",
        personalInformation.gender,
        "",
        "",
        "",
        "",
        "individual",
        policy.age,
        "",
        policy.nationalityCode,
        "",
        policy.civil_id,
        "",
        "",
        personalInformation.dob,
        policy.age,
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        cover_type === "Unified Domestic Worker" ? "2 Years" : "1 Year",
        startDate,
        endDate,
        "",
        "",
        "",
        amount ? amount.base_price : 0,
        amount ? amount.policy_fee : 0,
        amount ? amount.government_fee : 0,
        amount ? amount.emergency_fund_fee : 0,
        0,
        0,
        amount ? amount.total : 0,
        "",
        "",
        "",
        "",
        commissionAmount,
        "",
        "",
        startDate,
        personalInformation.fullName,
        "",
        "",
        "",
        "",
        "",
        "59 - Sehteq",
        branch,
        commissionPercentage,
        "",
        "",
        "",
        "",
        ""
      ];
      csvArr.push(arr.join(","));
    }
    const csvFile = new Blob([csvArr.join("\n")], { type: "text/csv" });
    saveAs(csvFile, "legacy-format-dash.csv");
    this.setState({ isDownloadingLegacy: false });
  }

  render() {
    return (
      <div className="d-flex">
        <Navigation />
        <div className="container-fluid">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Paper className="p-2 sq-shadow d-flex">
                <Typography><Link to={`/home`}>Home</Link></Typography>
                <Typography className="mr-2 ml-2">/</Typography>
                <Typography>Individual Medical Report</Typography>
              </Paper>
            </Grid>

            <Grid item md={3} xs={12}>
              <AffiliateSelect onChange={() => { }} />
            </Grid>
            <Grid item md={3} xs={12}>
              <TextField
                variant="outlined"
                select
                label="Source of Business"
                fullWidth
                name="sob"
                onChange={this.handleChange}
                value={this.state.sob}
                SelectProps={{
                  MenuProps: {
                    anchorOrigin: {
                      vertical: "top",
                      horizontal: "left"
                    },
                    getContentAnchorEl: null
                  },
                }}
              >
                {
                  Object.values(REPORT_SOB_OPTIONS).map((option, index) => <MenuItem key={index} value={option}>{option}</MenuItem>)
                }
              </TextField>
            </Grid>
            <Grid item md={3} xs={12}>
              <TextField
                variant="outlined"
                select
                label="Region"
                fullWidth
                name="region"
                onChange={this.handleChange}
                value={this.state.region}
                SelectProps={{
                  MenuProps: {
                    anchorOrigin: {
                      vertical: "top",
                      horizontal: "left"
                    },
                    getContentAnchorEl: null
                  },
                }}
              >
                <MenuItem value="All">All</MenuItem>
                {
                  RegionList.map((region) => 
                    <MenuItem key={region.id} value={region.id}>{region.name}</MenuItem>
                  )
                }
              </TextField>
            </Grid>
            <Grid item md={3} xs={12}>
              <TextField
                variant="outlined"
                select
                label="Branch"
                fullWidth
                name="branch"
                onChange={this.handleChange}
                value={this.state.branch}
                SelectProps={{
                  MenuProps: {
                    anchorOrigin: {
                      vertical: "top",
                      horizontal: "left"
                    },
                    getContentAnchorEl: null
                  },
                }}
              >
                <MenuItem value="All">All</MenuItem>
                {
                  BranchList.map((branch) => 
                    <MenuItem key={branch.id} value={branch.id}>{branch.name}</MenuItem>
                  )
                }
              </TextField>
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={3}>
              <TextField
                variant="outlined"
                select
                label="Status"
                fullWidth
                name="status"
                onChange={this.handleChange}
                value={this.state.status}
                SelectProps={{
                  MenuProps: {
                    anchorOrigin: {
                      vertical: "top",
                      horizontal: "left"
                    },
                    getContentAnchorEl: null
                  },
                }}
              >
                {
                  Object.values(REPORT_STATUS_OPTIONS).map((option, index) => <MenuItem key={index} value={option}>{option}</MenuItem>)
                }

              </TextField>
            </Grid>

            <Grid item xs={3}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                  label="Start Date"
                  inputVariant="outlined"
                  animateYearScrolling
                  name="startDate"
                  clearable
                  fullWidth
                  value={this.state.startDate}
                  onChange={this.handleStartDateChange}
                  format="dd-MM-yyyy"
                />
              </MuiPickersUtilsProvider>
            </Grid>

            <Grid item xs={3}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                  label="End Date"
                  inputVariant="outlined"
                  animateYearScrolling
                  name="endDate"
                  clearable
                  fullWidth
                  value={this.state.endDate}
                  onChange={this.handleEndDateChange}
                  format="dd-MM-yyyy"
                />
              </MuiPickersUtilsProvider>
            </Grid>

            <Grid item xs={3} className="d-flex align-items-center">
              <Button variant="contained" color="primary" disabled={this.state.isLoading} onClick={this.handleSearch}>{this.state.isLoading ? "Searching . . ." : "Search"}</Button>
            </Grid>
          </Grid>

          {
            (this.state.showTable && this.state.data.length > 0) &&
            <Grid container spacing={2} className="d-flex">
              <Grid item xs={12}>
                <Paper className="sq-shadow overflow-auto">
                  <div className="dash-table-container">
                    <Table>
                      <TableHead>
                        <TableRow>
                          {
                            this.state.headings.map((heading, ind) =>
                              <TableCell key={ind}>{heading}</TableCell>
                            )
                          }
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {
                          this.state.data.map((data, ind) => {
                            return (
                              <TableRow key={ind}>
                                <TableCell>{ind + 1}</TableCell>
                                <TableCell>{data._id}</TableCell>
                                <TableCell>{data.policy_num}</TableCell>
                                <TableCell>{data.civil_id}</TableCell>
                                <TableCell>{data.personalInformation.fullName}</TableCell>
                                <TableCell>{data.personalInformation.dob}</TableCell>
                                <TableCell className="text-capitalize"> {data.personalInformation.gender} </TableCell>
                                <TableCell>{data.personalInformation.occupation || "-"}</TableCell>
                                <TableCell>{data.personalInformation.occupation_code || "-"}</TableCell>
                                <TableCell>{data.personalInformation.id_expiry_date || "-"}</TableCell>
                                <TableCell>{(data.personalInformation.isMarried === "yes" ? "Married" : "Single")}</TableCell>
                                <TableCell>{data.nationality}</TableCell>
                                <TableCell padding="checkbox">{this.getFormattedDate(data.startDate)}</TableCell>
                                <TableCell padding="checkbox">{this.getExpiryDate(data.startDate, data.cover_type)}</TableCell>
                                <TableCell>{data.personalInformation.email}</TableCell>
                                <TableCell>{data.personalInformation.mobileNumber}</TableCell>
                                <TableCell className="text-capitalize"> {data.applicationType} </TableCell>
                                <TableCell>{data.amount ? helpers.getBasePrice(data.amount) : 0} OMR</TableCell>
                                <TableCell>{data.amount ? helpers.getDiscountCode(data.amount) : "-"}</TableCell>
                                <TableCell>{data.amount ? helpers.getDiscount(data.amount) : 0} OMR</TableCell>
                                <TableCell>{data.amount ? data.amount.policy_fee : 0} OMR</TableCell>
                                <TableCell>{data.amount ? data.amount.emergency_fund_fee : 0} OMR</TableCell>
                                <TableCell>{data.amount ? data.amount.government_fee : 0} OMR</TableCell>
                                <TableCell>{data.amount ? data.amount.total : 0} OMR</TableCell>
                                <TableCell>{data.amount ? data.amount.vat : 0} OMR</TableCell>
                                <TableCell>{data.amount ? data.amount.bmi_value : 0} OMR</TableCell>
                                <TableCell> {data.plan} </TableCell>
                                <TableCell> {data.network === "Silver" ? "Silver Premium" : data.network} </TableCell>
                                <TableCell> {data.annual_limit} OMR </TableCell>
                                <TableCell> {data.op} OMR </TableCell>
                                <TableCell> {data.co_pay} </TableCell>
                                <TableCell> {data.payments && data.payments.isPaid ? "Paid" : "Unpaid"} </TableCell>
                                <TableCell> {data.status} </TableCell>
                                {
                                  ["Active"].includes(this.state.status) &&
                                  <>
                                    <TableCell>
                                      {
                                        data.payments.mode === "Link" ? "Card" : data.payments.mode
                                      }
                                    </TableCell>
                                    <TableCell>
                                      {
                                        data.payments.mode !== "Cash" ? data.payments.payment_id : "-"
                                      }
                                    </TableCell>
                                  </>
                                }
                                <TableCell>{data.company_affiliate_name ? data.company_affiliate_name : "-"}</TableCell>
                                <TableCell>{data.affiliate_partner_name ? data.affiliate_partner_name : "-"}</TableCell>
                                <TableCell>{data.channel_name ? data.channel_name : "-"}</TableCell>
                                <TableCell>{data.sub_channel_name ? data.sub_channel_name : "-"}</TableCell>
                                <TableCell>{data.affiliate_employee_id ? data.affiliate_employee_id : "-"}</TableCell>
                                <TableCell>{data.affiliate_name ? data.affiliate_name : "AXSS"}</TableCell>
                                <TableCell>{data.affiliate_email ? data.affiliate_email : "-"}</TableCell>
                                <TableCell>{data.affiliate_employee_designation ? data.affiliate_employee_designation : "-"}</TableCell>
                                <TableCell>{data.branch_id ? data.branch_id : "-"}</TableCell>
                                <TableCell>{data.branch_name ? data.branch_name : "-"}</TableCell>
                                <TableCell>{data.region_name ? data.region_name : "-"}</TableCell>
                                <TableCell>{data.branch_manager_name ? data.branch_manager_name : "-"}</TableCell>
                                <TableCell>{data.branch_manager_email ? data.branch_manager_email : "-"}</TableCell>
                                <TableCell>{data.affiliate_phone ? data.affiliate_phone : "-"}</TableCell>
                                <TableCell>{data.comment ? data.comment : "-"}</TableCell>
                                <TableCell>
                                  <Link to={`/policies/${data._id}`} className="table-eye-link">
                                    <Eye />
                                  </Link>
                                </TableCell>
                              </TableRow>
                            )
                          })
                        }
                        <TableRow>
                          <TablePagination
                            rowsPerPage={this.state.rowsPerPage}
                            rowsPerPageOptions={[10, 25, 50]}
                            page={this.state.page}
                            count={this.state.totalRecords}
                            colSpan={0}
                            onChangePage={this.handleChangePage}
                            onChangeRowsPerPage={this.handleChangeRowsPerPage}
                          />
                        </TableRow>
                      </TableBody>
                    </Table>
                  </div>
                </Paper>
              </Grid>

              <Grid item xs={12}>
                <Button onClick={this.downloadData} variant="contained" color="primary" disabled={this.state.isDownloading}>{this.state.isDownloading ? "Downloading . . ." : "Download csv"}</Button>
                <Button onClick={this.downloadLegacyData} variant="contained" color="primary" className="ml-3" disabled={this.state.isDownloadingLegacy}> {this.state.isDownloadingLegacy ? "Downloading . . ." : "Download csv (in Legacy system format)"} </Button>
              </Grid>

            </Grid>
          }
          {
            (this.state.showTable && this.state.data.length === 0) &&
            <Paper className="q-shadow mt-3 pt-5 pb-5 d-flex align-items-center justify-content-center">
              <Typography variant="h5"> No Data Found </Typography>
            </Paper>
          }
        </div>
      </div>
    )
  }
}
export default withRouter(IndividualMedicalReport);