import React from 'react';
import { Document, Page, StyleSheet, View, Text, Image, Font } from "@react-pdf/renderer";
import Arabic from "../../static/fonts/Arabic/A_Nefel_Adeti.ttf";
import Stamp from "../../static/images/pa-schedule-signature.jpg";
import AxssLogo from "../../static/images/axss-logo.png";

Font.register({ family : "Arabic", src : Arabic });

const styles = StyleSheet.create({
  page : {
    fontSize : 10,
    fontFamily : "Arabic"
  },
  container : {
    paddingHorizontal : 20,
    paddingVertical : 20
  },
  headerImage : {
    height : 40,
    width : "auto"
  },
  border_right : {
    borderRight : "1pt solid black"
  },
  border_left : {
    borderLeft : "1pt solid black"
  },
  border_top : {
    borderTop : "1pt solid black"
  },
  border_bottom : {
    borderBottom : "1pt solid black"
  },
  borderAll : {
    border : "1pt solid black"
  },
  center : {
    textAlign : "center"
  },
  topTitle : {
    marginTop : 10
  },
  row : {
    display : "flex",
    flexDirection : "row"
  },
  top_column_1 : {
    width : "22%",
    paddingLeft : 5
  },
  top_column_2 : {
    justifyContent : "center",
    alignItems : "center",
    width : "3%"
  },
  top_column_3 : {
    width : "75%",
    paddingLeft : 5
  },
  toMarLeft : {
    paddingLeft : 25
  },
  cover_column_1 : {
    width : "8%"
  },
  cover_column_2 : {
    width : "50%",
    paddingHorizontal : 5
  },
  cover_column_3 : {
    width : "42%",
    paddingHorizontal : 5
  },
  text_right : {
    textAlign : "right"
  },
  pd_column_1 : {
    width : "30%",
    paddingLeft : 5
  },
  pd_column_2 : {
    paddingHorizontal : 5
  },
  capitalize : {
    textTransform : "capitalize"
  },
  padding_none : {
    paddingHorizontal : 0
  },
  beneficiary_column_1 : {
    width : "8%"
  },
  beneficiary_column_2 : {
    width : "62%",
    paddingHorizontal : 5
  },
  beneficiary_column_3 : {
    width : "20%"
  },
  beneficiary_column_4 : {
    width : "10%"
  },
  policy_subject : {
    paddingHorizontal : 5
  },
  stamp_row : {
    justifyContent : "flex-end",
    marginVertical : 10
  },
  stamp_container : {
    width : 180,
    display : "flex",
    alignItems : "center"
  },
  stamp : {
    height : 124,
    width : 129
  },
  special_clause : {
    fontSize : 9,
    paddingLeft : 5
  },
  special_clause_note : {
    fontSize : 8,
    paddingHorizontal : 5
  },
  footer : {
    width : "100%",
    marginTop : 20
  }
});

const LifePA = ({ beneficiaries, data, startDate, endDate, dob }) => {

  const totalBeneficiaries = beneficiaries.length;

  const {policy_num, personalInformation, nationality} = data;

  return(
    <Document creator = "AXSS" producer = "AXSS" title = "Personal Accident">
      <Page style={styles.page}>
        <View style = {styles.container}>
          <View style={{ display : "flex", alignItems : "center" }}>
            <Image source = {AxssLogo} style = {styles.headerImage} />
          </View>
          <View style = {[styles.borderAll, styles.topTitle, styles.center]}>
            <Text> Personal Accident Policy Schedule </Text>
          </View>

          <View style = {[styles.row, styles.border_bottom, styles.border_left, styles.border_right]}>
            <View style = {[styles.top_column_1]}>
              <Text> Policy No </Text>
            </View>
            <View style = {[styles.row, styles.top_column_2, styles.border_left, styles.border_right]}>
              <Text>:</Text>
            </View>
            <View style = {[styles.top_column_3]}>
              <Text> { policy_num } </Text>
            </View>
          </View>

          <View style = {[styles.row, styles.border_bottom, styles.border_left, styles.border_right]}>
            <View style = {[styles.top_column_1]}>
              <Text> Insured Name </Text>
            </View>
            <View style = {[styles.row, styles.top_column_2, styles.border_left, styles.border_right]}>
              <Text>:</Text>
            </View>
            <View style = {[styles.top_column_3]}>
              <Text> {personalInformation.fullName} </Text>
            </View>
          </View>

          <View style = {[styles.row, styles.border_bottom, styles.border_left, styles.border_right]}>
            <View style = {[styles.top_column_1]}>
              <Text> Nationality </Text>
            </View>
            <View style = {[styles.row, styles.top_column_2, styles.border_left, styles.border_right]}>
              <Text>:</Text>
            </View>
            <View style = {[styles.top_column_3]}>
              <Text> {nationality} </Text>
            </View>
          </View>

          <View style = {[styles.row, styles.border_left, styles.border_right]}>
            <View style = {[styles.top_column_1]}>
              <Text> Period of Insurance </Text>
            </View>
            <View style = {[styles.row, styles.top_column_2, styles.border_left, styles.border_right]}>
              <Text>:</Text>
            </View>
            <View style = {[styles.top_column_3, styles.row]}>
              <View>
                <Text> From &nbsp;:&nbsp; { startDate } </Text>
              </View>
              <View style = { styles.toMarLeft }>
                <Text> To &nbsp;:&nbsp; { endDate } </Text>
              </View>
            </View>
          </View>

          <View style = {[styles.borderAll, styles.center]}>
            <Text> Cover Description </Text>
          </View>

          <View style = {[styles.row, styles.border_bottom, styles.border_left, styles.border_right]}>
            <View style = {[styles.cover_column_1, styles.center]}>
              <Text> SR. No </Text>
            </View>
            <View style = {[styles.row, styles.cover_column_2, styles.border_left, styles.border_right]}>
              <Text> Benefits </Text>
            </View>
            <View style = {[styles.cover_column_3]}>
              <Text> Sum Insured Maximum Limit (RO) </Text>
            </View>
          </View>

          <View style = {[styles.row, styles.border_bottom, styles.border_left, styles.border_right]}>
            <View style = {[styles.cover_column_1, styles.center]}>
              <Text> 1 </Text>
            </View>
            <View style = {[styles.row, styles.cover_column_2, styles.border_left, styles.border_right]}>
              <Text> Death due to an accident </Text>
            </View>
            <View style = {[styles.cover_column_3, styles.text_right]}>
              <Text> 3,000.000 </Text>
            </View>
          </View>
          
          <View style = {[styles.row, styles.border_bottom, styles.border_left, styles.border_right]}>
            <View style = {[styles.cover_column_1, styles.center]}>
              <Text> 2 </Text>
            </View>
            <View style = {[styles.row, styles.cover_column_2, styles.border_left, styles.border_right]}>
              <Text> Permanent Total Disablement due to an accident </Text>
            </View>
            <View style = {[styles.cover_column_3, styles.text_right]}>
              <Text> 3,000.000 </Text>
            </View>
          </View>

          <View style = {[styles.row, styles.border_bottom, styles.border_left, styles.border_right]}>
            <View style = {[styles.cover_column_1, styles.center]}>
              <Text> 3 </Text>
            </View>
            <View style = {[styles.row, styles.cover_column_2, styles.border_left, styles.border_right]}>
              <View>
                <Text> Permanent Partial Disablement due to an accident </Text>
                <Text> (% of Sum Assured as certified by Govt Medical Board) </Text>
              </View>
            </View>
            <View style = {[styles.cover_column_3, styles.text_right]}>
              <Text> 3,000.000 </Text>
            </View>
          </View>

          <View style = {[styles.row, styles.border_bottom, styles.border_left, styles.border_right]}>
            <View style = {[styles.cover_column_1, styles.center]}>
              <Text> 4 </Text>
            </View>
            <View style = {[styles.row, styles.cover_column_2, styles.border_left, styles.border_right]}>
              <Text> Death Due to any reason (Other than Accident) </Text>
            </View>
            <View style = {[styles.cover_column_3, styles.text_right]}>
              <Text> 1,000.000 </Text>
            </View>
          </View>

          <View style = {[styles.row, styles.border_bottom, styles.border_left, styles.border_right]}>
            <View style = {[styles.cover_column_1, styles.center]}>
              <Text> 5 </Text>
            </View>
            <View style = {[styles.row, styles.cover_column_2, styles.border_left, styles.border_right]}>
              <Text> Medical Expenses due to an accident (Up to) </Text>
            </View>
            <View style = {[styles.cover_column_3, styles.text_right]}>
              <Text> 500.000 </Text>
            </View>
          </View>

          <View style = {[styles.row, styles.border_bottom, styles.border_left, styles.border_right]}>
            <View style = {[styles.cover_column_1, styles.center]}>
              <Text> 6 </Text>
            </View>
            <View style = {[styles.row, styles.cover_column_2, styles.border_left, styles.border_right]}>
              <Text> Repatriation of Mortal Remains to home country (Up to) </Text>
            </View>
            <View style = {[styles.cover_column_3, styles.text_right]}>
              <Text> 400.000 </Text>
            </View>
          </View>

          <View style = {[styles.row, styles.border_bottom, styles.border_left, styles.border_right]}>
            <View style = {[styles.cover_column_1, styles.center]}>
              <Text> 7 </Text>
            </View>
            <View style = {[styles.row, styles.cover_column_2, styles.border_left, styles.border_right]}>
              <Text> Air ticket expenses for medically unfit to work (Up to) </Text>
            </View>
            <View style = {[styles.cover_column_3, styles.text_right]}>
              <Text> 100.000 </Text>
            </View>
          </View>

          <View style = {[styles.row, styles.border_left, styles.border_right]}>
            <View style = {[styles.top_column_1]}>
              <Text> Premium (RO) </Text>
            </View>
            <View style = {[styles.row, styles.top_column_2, styles.border_left, styles.border_right]}>
              <Text>:</Text>
            </View>
            <View style = {[styles.top_column_3]}>
              <Text> 15.000 </Text>
            </View>
          </View>

          <View style = {[styles.borderAll, styles.center]}>
            <Text> Insured Person Details </Text>
          </View>

          <View style = {[styles.row, styles.border_bottom, styles.border_left, styles.border_right]}>
            <View style = {[styles.pd_column_1, styles.border_right]}>
              <Text> Insured Name </Text>
            </View>
            <View style = {[styles.pd_column_2]}>
              <Text> {personalInformation.fullName} </Text>
            </View>
          </View>

          <View style = {[styles.row, styles.border_bottom, styles.border_left, styles.border_right]}>
            
            <View style = {[styles.row, styles.pd_column_1, styles.border_right]}>
              <View style = {[styles.pd_column_1, styles.border_right]}>
                <Text> DOB </Text>
              </View>
              <View style = {[styles.pd_column_2]}>
                <Text> {dob} </Text>
              </View>
            </View>

            <View style = {[styles.row, styles.pd_column_2]}>
              <View style = {[styles.pd_column_1, styles.padding_none, styles.border_right]}>
                <Text> Gender </Text>
              </View>
              <View style = {[styles.pd_column_2, styles.capitalize]}>
                <Text> {personalInformation.gender} </Text>
              </View>
            </View>

          </View>

          <View style = {[styles.row, styles.border_left, styles.border_right]}>
            <View style = {[styles.pd_column_1, styles.border_right]}>
              <Text> Passport Number </Text>
            </View>
            <View style = {[styles.pd_column_2]}>
              <Text> {personalInformation.passport} </Text>
            </View>
          </View>

          <View style = {[styles.borderAll, styles.center]}>
            <Text> Beneficiary Details </Text>
          </View>

          <View style = {[styles.row, styles.border_bottom, styles.border_left, styles.border_right]}>
            <View style = {[styles.center, styles.beneficiary_column_1, styles.border_right]}>
              <Text> SR. No </Text>
            </View>
            <View style = {[styles.beneficiary_column_2, styles.border_right]}>
              <Text> Name </Text>
            </View>
            <View style = {[styles.center, styles.beneficiary_column_3, styles.border_right]}>
              <Text> Relation </Text>
            </View>
            <View style = {[styles.center, styles.beneficiary_column_4]}>
              <Text> % </Text>
            </View>
          </View>

          {
            beneficiaries.map((beneficiary, ind) => 
              <View style = {[styles.row, styles.border_bottom, styles.border_left, styles.border_right]} key = {ind}>
                <View style = {[styles.center, styles.beneficiary_column_1, styles.border_right]}>
                  <Text> { ind + 1 } </Text>
                </View>
                <View style = {[styles.beneficiary_column_2, styles.border_right]}>
                  <Text> {beneficiary.name} </Text>
                </View>
                <View style = {[styles.center, styles.beneficiary_column_3, styles.border_right]}>
                  <Text> {beneficiary.relation} </Text>
                </View>
                <View style = {[styles.center, styles.beneficiary_column_4]}>
                  <Text> { totalBeneficiaries === 1 ? 100 : beneficiary.benefit_percentage} </Text>
                </View>
              </View>
            )
          }

          <View style = {[styles.row, styles.border_bottom, styles.border_left, styles.border_right]}>
            <View style = {[styles.top_column_1]}>
              <Text> Policy Wording </Text>
            </View>
            <View style = {[styles.row, styles.top_column_2, styles.border_left, styles.border_right]}>
              <Text>:</Text>
            </View>
            <View style = {[styles.top_column_3]}>
              <Text> As per Attached Policy Wordings </Text>
            </View>
          </View>

          <View style = {[styles.row, styles.border_left, styles.border_right, styles.policy_subject]}>
            <Text> THIS POLICY IS SUBJECT TO THE ATTACHED POLICY WORDING, ITS GENERAL AND SPECIAL CONDITIONS, CLAUSES, ENDORSEMENT, EXCLUSION etc. WHICH FOM PART OF THIS POLICY SCHEDULE </Text>
          </View>

          <View style = {[styles.borderAll, styles.center]}>
            <Text> SPECIAL CLAUSES </Text>
          </View>

          <View style = {[styles.border_bottom, styles.border_left, styles.border_right]}>
            
            <Text style = { styles.special_clause }> Law & Jurisdiction: Sultanate Of Oman </Text>
            <Text style = { styles.special_clause }> In witness whereof this policy is signed for and on behalf of the Insurance Company</Text>
            <Text style = { styles.special_clause }> In Sultanate Of Oman on {startDate} </Text>

            <View style = {[styles.row, styles.stamp_row]}>
              <View style = { [styles.stamp_container] }>
                <Text> Signature of the Issuing Authority </Text>
                <Image source = {Stamp} style = {styles.stamp} />
                <Text> Stamp / Seal </Text>
              </View>
            </View>

            <Text style = { styles.special_clause_note }> N.B. Please examine this document carefully and advise us immediately if any of the terms & conditions does not accurately meet your requirements or is incorrect </Text>

          </View>
        </View>
      </Page>
    </Document>
  )
}

export default LifePA;