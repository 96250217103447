import React from "react";
import { Table, TableHead, TableBody, TableRow, TableCell, Checkbox, Button, TextField, Paper, InputAdornment } from "@material-ui/core";
import {Link, withRouter} from "react-router-dom";
import Eye from "mdi-react/EyeIcon";
import Search from "@material-ui/icons/Search";
import PolicyService from "../../services/policy";
import JSZip from "jszip";
import DownloadService from "../../services/download";
import { saveAs } from "file-saver";
import Snackbar from "../common/Snackbar";
import AffiliateSelect from "../common/AffiliateSelect";

class SubmittedTable extends React.Component{

  state = {
    selectedData : [],
    data : [],
    idArr : [],
    searchValue : "",
    showSnackbar : false,
    snackBarMessage : "",
    messageType : "success",
    center : ""
  }

  handleSelectAllClick = (e) => {
    this.setState({ selectedData : e.target.checked ? this.state.idArr : [] });
  }

  handleCheckClick = (checked, id) => {
    let arr = [...this.state.selectedData];
    const index = arr.indexOf(id);
    if(index === -1)
      arr.push(id);
    else
      arr.splice(index, 1);

    this.setState({ selectedData : arr });
  }

  getData = async () => {
    const value = this.state.searchValue === "" ? undefined : this.state.searchValue;
    const data = await PolicyService.getAllPolicies(value);
    const policies = data.data.data;
    const arr = [];
    const idArr = [];
    policies.forEach((policy) => {
      const totalDocuments = policy.totalDocuments;
      const submittedDocuments = policy.submittedDocuments;

      if(submittedDocuments.length === totalDocuments){
        arr.push(policy)
        idArr.push(policy.id);
      }
    });

    this.setState({ data : arr, idArr });
  }

  handleSearchChange = (e) => {
    this.setState({ searchValue : e.target.value }, () => {
      this.getData();
    })
  }

  componentDidMount(){
    const center = this.props.match.params.center;
    this.setState({ center })
    this.getData();
  }

  downloadFiles = async () => {
    this.setState({
      showSnackbar : true,
      snackBarMessage : "Downloading . . .",
      messageType : "primary"
    })
    const ids = this.state.selectedData;
    let policies = [];
    for(let policy_id of ids){
      const res = await PolicyService.getPolicyById(policy_id);
      policies.push({
        images : res.data.images,
        policy_id : res.data.policy_id
      });
    }

    var zip = new JSZip();
    
    for(let policy of policies){
      const {policy_id, images} = policy;
      for(let docObj of images){
        const title = docObj.title.split(" ").join("-");
        const res = await DownloadService.getFile(docObj.url); 
        if(res.status === 200){
          const path = `${policy_id}/${title}.pdf`;
          zip.file(path, res.data);
        }
      }
    }

    const date = new Date();
    const year = date.getFullYear();
    const month = date.getMonth() < 9 ? "0" + date.getMonth() + 1 : date.getMonth() + 1;
    const day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
    const fileStr = `Documents_${year}-${month}-${day}_${date.getHours()}-${date.getMinutes()}-${date.getSeconds()}.zip`;

    zip.generateAsync({ type : "blob" }).then((blob) => {
      this.setState({ showSnackbar : false });
      saveAs(blob, fileStr);
    });
  }

  handleCloseSnackbar = () => {
    this.setState({ showSnackbar : false });
  }

  updateStatus = async (status, status2) => {
    
    const ids = this.state.selectedData;
    this.setState({
      showSnackbar : true,
      snackBarMessage : "Updating . . .",
      messageType : "primary"
    });
    for(let policy_id of ids){
      const data = [...this.state.data];
      const obj = data.find((policy) => {
        return policy_id === policy.id;
      });
      await PolicyService.updateStatus(obj.member_id, obj.policy_id, status, status2, policy_id);
    }
    
    this.setState({
      showSnackbar : false
    }, () => {
      this.setState({
        showSnackbar : true,
        snackBarMessage : "Status updated successfully",
        messageType : "success"
      });
      this.getData();
    });

  }

  getFormattedDate = (startDate) => {
    let newDate = new Date(startDate);
    const date = newDate.getDate();
    const month = newDate.getMonth() + 1;
    const year = newDate.getFullYear();
    return `${date > 9 ? date : "0" + date}/${month > 9 ? month : "0" + month}/${year}`;
  }

  handleAffiliateChange = () => {
    this.getData();
  }

  render(){
    return(
      <div>
        <Snackbar
          showSnackbar = { this.state.showSnackbar }
          messageType = {this.state.messageType}
          snackBarMessage = {this.state.snackBarMessage}
          onCloseSnackbar = {this.handleCloseSnackbar}
        />
        <div className="row justify-content-between">
          <div className="col-md-5 col-12">
            <AffiliateSelect fresh={false} onChange={this.handleAffiliateChange} />
          </div>

          <div className="col-md-5 col-12">
            <TextField
              variant = "outlined"
              label = "Search"
              className = "mt-3 mb-3"
              fullWidth
              placeholder = "Search by quote no / policy num / payment id / name / email"
              onChange = {this.handleSearchChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Search />
                  </InputAdornment>
                ),
              }}
            />
          </div>
        </div>
        <Paper className="sq-shadow policies-table-container">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox indeterminate = {this.state.selectedData.length > 0 && this.state.selectedData.length < this.state.idArr.length} checked={this.state.idArr.length > 0 && this.state.selectedData.length === this.state.idArr.length} onClick={this.handleSelectAllClick} />
                </TableCell>
                <TableCell>
                  Quote No.
                </TableCell>
                <TableCell>
                  Card Number
                </TableCell>
                <TableCell>
                  Start Date
                </TableCell>
                <TableCell>
                  Policy Type
                </TableCell>
                <TableCell>
                  Name
                </TableCell>
                <TableCell>
                  Status
                </TableCell>
                <TableCell align="right">
                  Amount
                </TableCell>
                <TableCell>
                  View
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                this.state.data.map((data) => 
                  <TableRow key={data.id}>
                    <TableCell padding="checkbox">
                      <Checkbox checked={this.state.selectedData.includes(data.id)} onClick={(e) => this.handleCheckClick(e.target.checked, data.id)} />
                    </TableCell>
                    <TableCell>
                      {data.id}
                    </TableCell>
                    <TableCell>
                      {data.policy_num}
                    </TableCell>
                    <TableCell>
                      {this.getFormattedDate(data.startDate)}
                    </TableCell>
                    <TableCell>
                      {data.policy_type !== "undefined" ? data.policy_type.replace("_", " ") : "Domestic Worker"}
                    </TableCell>
                    <TableCell>
                      {data.name}
                    </TableCell>
                    <TableCell>
                      {data.status}
                    </TableCell>
                    <TableCell align="right">
                      {data.amount} OMR
                    </TableCell>
                    <TableCell>
                      <Link to={`/policies/${data.id}`} className="table-eye-link">
                        <Eye />
                      </Link>
                    </TableCell>
                  </TableRow>
                )
              }
            </TableBody>
          </Table>
          {
            this.state.selectedData.length > 0 &&
            <div className="p-3 text-right">
              <Button variant="contained" className="bg-success text-white mr-3" onClick={() => this.updateStatus("1", "Active")}> Activate </Button>
              <Button variant="contained" className="bg-warning text-white mr-3" onClick={() => this.updateStatus("2", "Suspended")}> Suspend </Button>
              <Button variant="contained" className="bg-danger text-white mr-3" onClick={() => this.updateStatus("3", "Rejected")}> Reject </Button>
              <Button variant="contained" className="bg-info text-white" onClick={this.downloadFiles}> Download Attachments </Button>
            </div>
          }
        </Paper>
      </div>
    )
  }
}

export default withRouter(SubmittedTable);