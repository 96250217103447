import axios from "axios";
import config from "./config";

let motorMis = {};

motorMis.getPolicies = async ({
  start_date,
  end_date,
  company_code,
  employee_id,
  send_data
}) => {
  const url = `${config.baseUrl}/get-motor-report`;
  let params = {
    send_data : send_data ? 1 : 0
  }

  if(start_date){
    params.start_date = start_date;
  }
  if(end_date){
    params.end_date = end_date;
  }
  if(company_code){
    params.company_code = company_code;
  }
  if(employee_id){
    params.employee_id = employee_id;
  }
  
  const res = await axios({
    url,
    params
  });
  return res;
};

export default motorMis;