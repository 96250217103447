import { MenuItem, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Paper, TextField, Typography, IconButton } from "@material-ui/core";
import React from "react";
import PlanService from "./../../../services/plans";
import _ from "lodash";
import Snackbar from "../../common/Snackbar";
import PropTypes from "prop-types";
import validator from "validator";
import { Close, Delete, Edit } from "@material-ui/icons";
import CountryList from "./country-data.json";
import DeleteConfirmDialog from "../../common/DeleteConfirmDialog";

class UnifiedDomesticWorker extends React.Component {

    state = {
        premium: 0,
        isValidPersonalInfo: {
            premium: undefined
        },
        loading: false,
        delete_id: "",
        dialogActive: false,
        deleteTrue: false,
        showSnackbar: false,
        snackBarMessage: "",
        messageType: "",
        premium_country: [],
        open: false,
        index: false
    }

    

    handleCloseSnackbar = () => {
        this.setState({ showSnackbar: false })
    }

    componentDidMount() {
        this.loadDetails()
    }

    onBlur = (name, value) => {
        let isValidPersonalInfo = { ...this.state.isValidPersonalInfo };
        if (value === "") {
            isValidPersonalInfo[name] = false;
        }
        else {
            isValidPersonalInfo[name] = true;
        }
        this.setState({ isValidPersonalInfo })
    }

    loadDetails = async () => {
        this.setState({ loading: true })
        let unified_domestic_worker_price = await PlanService.getPlanPrices("health");
        let premium = _.get(unified_domestic_worker_price, "data.data.health_unified_domestic_worker.default_premium", 0)
        let premium_country = _.get(unified_domestic_worker_price, "data.data.health_unified_domestic_worker.premium_country", 0)

        this.onBlur("premium", premium);
        this.setState({ premium, premium_country, loading: false })
    }

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    saveUnifiedDomesticWorker = async () => {
        this.setState({ loading: true })
        let status = false;

        let premium_country = [];
        if (Array.isArray(this.state.premium_country) && this.state.premium_country.length) {
            premium_country = [...this.state.premium_country]
        }

        try {
            let response = await PlanService.updatePlanPremium("health", "Unified Domestic Worker", { premium: this.state.premium, premium_country })
            if (response.data.status) {
                status = true
            }

        } catch (error) { }

        this.setState({
            loading: false,
            showSnackbar: true,
            snackBarMessage: status ? "Unified Domestic Worker premium updated." : "Something went wrong.",
            messageType: status ? "success" : "danger"
        });
    }

    onClosePremiumCountry = (isSave, premium_country) => {
        if (isSave) {
            this.setState({ open: false, premium_country })
        } else {
            this.setState({ open: false })
        }
    }

    openPremiumCountry = (index = false) => {
        this.setState({ open: true, index })
    }

    confirmDelete = async (id) => {
        this.setState({ dialogActive: true, delete_id: id });
    };

    onCloseDialog = async (confirm) => {
        if (confirm === true) {
            this.removeCountry(this.state.delete_id);
        }
        else {
            this.setState({ dialogActive: false, delete_id: undefined });
        }
    };


    removeCountry = async (index) => {
        this.setState({ deleteTrue: true });
        let premium_country = [...this.state.premium_country];
        
        premium_country.splice(index, 1)

        this.setState({ dialogActive: false, delete_id: undefined, premium_country });
    }



    render() {
        return (
            <React.Fragment>
                <Grid item xs={12} md={4} sm={6}>
                    <Snackbar
                        showSnackbar={this.state.showSnackbar}
                        messageType={this.state.messageType}
                        snackBarMessage={this.state.snackBarMessage}
                        onCloseSnackbar={this.handleCloseSnackbar}
                    />
                    <Paper className="sq-shadow p-2">
                        <Typography><strong>Unified Domestic Worker</strong></Typography>
                        <br />
                        <TextField
                            type="number"
                            fullWidth
                            value={this.state.premium}
                            label="Premium" variant="outlined"
                            onBlur={e => { this.onBlur("premium", e.target.value) }}
                            name="premium"
                            onChange={this.handleChange}
                        />
                    </Paper>
                </Grid>
                <Grid item xs={12} md={8} sm={12}>
                    <Paper className="sq-shadow p-2">
                        <CountryPremiumDialog
                            open={this.state.open}
                            onClose={this.onClosePremiumCountry}
                            index={this.state.index}
                            value={this.state.premium_country}
                        />
                        <DeleteConfirmDialog
                            onClose={this.onCloseDialog}
                            open={this.state.dialogActive}
                            processing={false}
                        />
                        <Typography className="mb-2">
                            <strong>Premium Countries</strong>
                            <Button onClick={this.openPremiumCountry.bind(this, false)} className="float-right" variant="contained" size="small" color="primary">Add</Button>
                        </Typography>
                        <table className="table table-sm">
                            <thead>
                                <tr>
                                    <th>Countries</th>
                                    <th>Premium</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    (Array.isArray(this.state.premium_country) && this.state.premium_country.length) ?
                                        this.state.premium_country.map((country, ind) => {
                                            return <tr key={ind}>
                                                <td>{_.get(country, "countries", []).join(", ")}</td>
                                                <td>{_.get(country, "premium", "")}</td>
                                                <td>
                                                    <IconButton onClick={e => { this.openPremiumCountry(ind) }}> <Edit /> </IconButton>
                                                    <IconButton onClick={() => this.confirmDelete(ind)}> <Delete /> </IconButton>
                                                </td>
                                            </tr>
                                        }) :
                                        <tr>
                                            <td className="text-sm-secondery">No data found.</td>
                                        </tr>
                                }
                            </tbody>
                        </table>

                    </Paper>
                </Grid>

                <Grid item xs={12}>
                    <Paper className="sq-shadow p-2 text-right">
                        <Button onClick={this.loadDetails} variant="outlined" className="mr-2">
                            {this.state.loading ? "Processing..." : "Reset"}
                        </Button>
                        <Button onClick={this.saveUnifiedDomesticWorker} disabled={!this.state.isValidPersonalInfo.premium || this.state.loading} variant="contained" color="primary">
                            {this.state.loading ? "Processing..." : "Save"}
                        </Button>
                    </Paper>
                </Grid>

            </React.Fragment>
        )
    }
}

class CountryPremiumDialog extends React.Component {

    state = {
        index: false,
        countries: [],
        premium: undefined,
        isValid: {
            premium: undefined,
        },
        open: false,
        allCountryList: [],
        disabledCountries: [],
        country: ""
    }

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    constructor(props) {
        super(props);
        this.state.CountryList = [...CountryList];
    }

    componentWillReceiveProps(props) {
        let index = props.index;
        this.onClose = props.onClose;
        let allCountryList = [...props.value];
        let open = props.open;
        let countries = [];
        let premium = "";
        if (allCountryList[index]) {
            let value = { ...allCountryList[index] }
            countries = value.countries;
            premium = value.premium
        }
        this.setState({ index, allCountryList, open, countries, premium, country: "" }, this.loadDisabledCountryList)
    }

    loadDisabledCountryList = () => {
        let selectedCountries = [...this.state.allCountryList];
        if (selectedCountries[this.state.index]) {
            selectedCountries.splice(this.state.index, 1);
        }
        let countries = [...this.state.countries];
        for (const key in selectedCountries) {
            countries = [...countries, ...selectedCountries[key].countries];
        }
        this.setState({ disabledCountries: countries })
    }

    isValidPremium = () => {
        return (!this.state.premium || !validator.isNumeric(this.state.premium.toString()) || this.state.premium <= 0) ? false : true
    }

    handleClose = () => {
        this.onClose(false)
    }

    saveDetails = () => {
        if (this.isValidPremium() && this.state.countries.length) {
            let value = [...this.state.allCountryList];

            if (value[this.state.index]) {
                value[this.state.index] = {
                    premium: this.state.premium,
                    countries: this.state.countries
                }
            } else {
                value.push({
                    premium: this.state.premium,
                    countries: this.state.countries
                })
            }

            this.onClose(true, value)
        }
    }

    removeCountry = (index) => {
        let countries = [...this.state.countries];
        countries.splice(index, 1);
        this.setState({ countries }, this.loadDisabledCountryList)
    }

    handleSelectCountry = (e) => {
        let countries = [...this.state.countries];
        if (e.target.value) {
            countries.push(e.target.value)
        }
        this.setState({ countries, country: e.target.value }, this.loadDisabledCountryList)
    }

    render() {
        return (<Dialog fullWidth={true} onClose={this.handleClose} aria-labelledby="simple-dialog-title" open={this.state.open}>
            <DialogTitle id="simple-dialog-title">
                {this.state.index === false ? "Add Country Premium" : "Update Country Premium"}
            </DialogTitle>
            <DialogContent>

                <TextField
                    type="number"
                    fullWidth
                    label="Premium"
                    value={this.state.premium}
                    variant="outlined"
                    name="premium"
                    onChange={this.handleChange}
                    error={this.state.isValid.premium === false}
                    helperText={this.state.isValid.premium === false ? "Enter valid premium." : ""}
                />

                <TextField
                    label="Choose Country"
                    variant="outlined"
                    margin="normal"
                    select
                    fullWidth
                    className="mt-3"
                    name="country"
                    onChange={this.handleSelectCountry}
                    value={this.state.country}
                >
                    <MenuItem value="">Select Country</MenuItem>
                    {
                        Array.isArray(this.state.CountryList) &&
                        this.state.CountryList.map((value, ind) => (
                            <MenuItem disabled={this.state.disabledCountries.map(v => (v.toLowerCase())).includes(value.toLowerCase())} key={ind} value={value}>{value}</MenuItem>
                        ))
                    }
                </TextField>

                <Paper className="sq-shadow p-0 mt-3 mb-3">

                    <div className="d-flex align-items-center justify-content-between p-2">
                        <Typography variant="subtitle1">
                            Countries
                        </Typography>
                    </div>

                    {(Array.isArray(this.state.countries) && this.state.countries.length)
                        ? this.state.countries.map((data, index) => {
                            return (
                                <div key={index} className="d-flex align-items-center justify-content-between border-top border-bottom py-1 px-2">
                                    <Typography variant="subtitle1">
                                        {data}
                                    </Typography>
                                    <Typography className="text-right">
                                        <IconButton onClick={this.removeCountry.bind(this, index)} size="small" >
                                            <Close />
                                        </IconButton>
                                    </Typography>
                                </div>
                            )
                        }) : <div className="d-flex align-items-center justify-content-between border-top border-bottom py-1 px-2">
                            <Typography className="text-secondary" variant="subtitle1">
                                No countries selected.
                            </Typography>
                        </div>
                    }

                </Paper>

            </DialogContent>
            <DialogActions>
                <Button variant="contained" size="small" onClick={this.handleClose}>
                    Cancel
                </Button>
                <Button variant="contained" disabled={!(this.isValidPremium() && this.state.countries.length)} color="primary" size="small" onClick={this.saveDetails.bind(this, true)}>
                    Save
                </Button>
            </DialogActions>
        </Dialog>)
    }
}

CountryPremiumDialog.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    index: PropTypes.any,
    value: PropTypes.array
}

export default UnifiedDomesticWorker;