import { Grid, Paper, Tab, Tabs } from "@material-ui/core";
import React from "react";
import PersonalAccident from "./Life/PersonalAccident";
import TermPlan from "./Life/TermPlan";

class ModifyPremiumsLife extends React.Component {

    life_plans = [
        { plan_type: "Personal Accident" },
        { plan_type: "Term Plan" }
    ]

    state = {
        plan_type: 0,
    }

    constructor(props){
        super(props);
        let plan_type = "Personal Accident";
        plan_type = this.life_plans.findIndex(plan => plan.plan_type === plan_type);
        this.state.plan_type = plan_type;
    }

    changePlanType = (e, value) => {
        this.setState({ plan_type: value })
    }

    render() {
        return (
            <React.Fragment>
                <Grid item xs={12}>
                    <Paper className="sq-shadow">
                        <Tabs value={this.state.plan_type} onChange={this.changePlanType}>
                            {
                                this.life_plans.map((plan, ind) => (<Tab label={plan.plan_type} key={ind} />))
                            }
                        </Tabs>
                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    {this.state.plan_type === 0 && <PersonalAccident />}
                    {this.state.plan_type === 1 && <TermPlan />}
                </Grid>
            </React.Fragment>
        )
    }
}
export default ModifyPremiumsLife;