import React from "react";
import Navigation from "../common/Navigation";
import { Grid, Button, Table, TableHead, TableBody, TableRow, TableCell, Paper, Typography, TablePagination } from "@material-ui/core";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { Link, withRouter } from "react-router-dom";
import { saveAs } from "file-saver";
import _ from "lodash";
import motorMis from "../../services/motor-mis";
import moment from "moment";
import AffiliateSelect from "../common/AffiliateSelect";
import AffiliateService from "../../services/affiliates";

const headings = [
  "SR No.",
  "Quote Number",
  "Policy Number",
  "Previous Policy Number",
  "Approval Date",
  "Coverage Start Date",
  "Coverage End Date",
  "Endorsement ID",
  "Endorsement Number",
  "Insured Name",
  "Insured DOB",
  "Mobile Number",
  "Email",
  "Gender",
  "Civil ID",
  "Customer ID",
  "Product",
  "Net Premium",
  "Charges",
  "Gross Premium",
  "Plate Character",
  "Plate No",
  "Serial Number",
  "Vehicle Value"
]

class MotorReport extends React.Component {
  state = {
    application: "self",
    startDate: null,
    endDate: null,
    showTable: false,
    data: [],
    page: 0,
    rowsPerPage: 10,
    totalRecords: 0,
    headings: headings,
    isLoading: false,
    isDownloading: false,
    status: "All",
    loadedPercentage: 0
  }
  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    });
  }

  handleStartDateChange = (date) => {
    const startDate = new Date(date.setHours(0, 0, 0, 0)).getTime();
    this.setState({ startDate });
  }

  handleEndDateChange = (date) => {
    const endDate = new Date(date.setHours(23, 59, 59, 999)).getTime();
    this.setState({ endDate });
  }

  componentDidMount() {
    let date = new Date().setHours(23, 59, 59, 999);
    let endDate = new Date(date).getTime();
    this.setState({
      startDate: new Date(new Date().setHours(0, 0, 0, 0)).getTime(),
      endDate
    });
    this.getAffiliates()
  }

  getAffiliates = async () => {
    const affiliates = await AffiliateService.getAllAffiliates();
    this.setState({ affiliates : affiliates.data }, () => {
      const center_id = sessionStorage.getItem("center_id");

      if(center_id){
        const selected_affiliate = this.state.affiliates.find((affiliate) => affiliate.affiliateId === center_id);
        this.setState({ selected_affiliate })
      }
    })
  }

  handleChangePage = (e, page) => {
    this.setState({ page }, this.handleSearch);
  }

  handleChangeRowsPerPage = (e) => {
    this.setState({ rowsPerPage: e.target.value }, this.handleSearch);
  }

  getCompanyAffiliate = (affiliate) => {
    let parent_affiliates = _.get(affiliate, "parents", []) ? affiliate.parents : [];
    let company_affiliate;
    if (parent_affiliates && Array.isArray(parent_affiliates)) {
      parent_affiliates.reverse();
      for (const parent_affiliate_id of parent_affiliates) {
        company_affiliate = this.state.affiliates.find((user) => user.affiliateId === parent_affiliate_id);
        if (_.get(company_affiliate, "affiliateSubType", false) == "Company") {
          return company_affiliate;
        }
      }
    }
    return;
  }

  handleSearch = async () => {
    this.setState({ isLoading: true });
    const selected_affiliate = this.state.selected_affiliate;
    let company_id, employee_id, employee_ids = [];

    if(selected_affiliate){
      let company_affiliate;

      const child_affiliates_res = await AffiliateService.getChildAffiliates(selected_affiliate.affiliateId);
      const child_affiliates = child_affiliates_res.data.data;

      if(selected_affiliate.affiliateSubType === "Company"){
        company_affiliate = selected_affiliate;
      }else{
        company_affiliate = this.getCompanyAffiliate(selected_affiliate);
      }

      if(company_affiliate){
        company_id = company_affiliate.accountCode;
        
        if(selected_affiliate.affiliateSubType !== "Company"){
          employee_ids.push(selected_affiliate.employeeID)
          
          if(child_affiliates.length){
            child_affiliates.forEach((affiliate) => {
              employee_ids.push(affiliate.employeeID);
            })
          }
        }
        employee_id = employee_ids.length ? JSON.stringify(employee_ids) : null
      }
      
    }

    const motorRes = await motorMis.getPolicies({
      start_date : moment(this.state.startDate).format("DD-MMMM-YYYY"),
      end_date : moment(this.state.endDate).format("DD-MMMM-YYYY"),
      company_code : company_id,
      employee_id : employee_id,
      send_data : true
    });
    
    this.setState({
      isLoading : false,
      data : motorRes.data.data,
      step : "downloading",
      totalRecords : motorRes.data.totalRecords
    }, this.downloadData)
  }

  // getFormattedDate = (date) => {
  //   const newDate = new Date(date);
  //   const day = newDate.getDate();
  //   const month = months[newDate.getMonth()];
  //   const year = newDate.getFullYear();
  //   return `${day < 10 ? "0" + day : day} - ${month} - ${year}`;
  // }

  downloadData = async () => {
    const data = this.state.data;
    let csvArr = [];
    csvArr.push([...this.state.headings].join(","));

    data.forEach((policy, ind) => {
      let arr = [
        ind + 1,
        policy.QUOTE_NUMER || "-",
        policy.POLICY_NUMER || "-",
        policy.PREVIOUS_POL_NO || "-",
        policy.APPRV_DATE ? moment(policy.APPRV_DATE).format("DD-MM-YYYY") : "-",
        policy.COVERAGE_FROM_DATE ? moment(policy.COVERAGE_FROM_DATE).format("DD-MM-YYYY") : "-",
        policy.COVERAGE_TO_DATE ? moment(policy.COVERAGE_TO_DATE).format("DD-MM-YYYY") : "-",
        policy.ENDORSEMENT_ID || "-",
        policy.ENDORSEMENT_NUMBER || "-",
        policy.INSURED_NAME || "-",
        policy.ULMI_DOB ? moment(policy.ULMI_DOB).format("DD-MM-YYYY") : "-",
        policy.ULMI_MOBILE_NO || "-",
        policy.ULMI_EMAIL_ID || "-",
        policy.ULMI_GENDER || "-",
        policy.CIVIL_ID || "-",
        policy.CUSTOMER_ID || "-",
        policy.PRODUCT || "-",
        policy.NET_PREMIUM ? Number(policy.NET_PREMIUM).toFixed(3) : "-",
        policy.CHARGES ? Number(policy.CHARGES).toFixed(3) : "-",
        policy.GROSS_PREMIUM ? Number(policy.GROSS_PREMIUM).toFixed(3) : "-",
        policy.PLATE_CHAR || "-",
        policy.PLATE_NO || "-",
        policy.SERIAL_NO || "-",
        policy.VEH_VALUE ? Number(policy.VEH_VALUE).toFixed(2) : "-"
      ];

      csvArr.push(arr.join(","));
    })
    const csvFile = new Blob([csvArr.join("\n")], { type: "text/csv" });
    saveAs(csvFile, "motor-dash.csv");
    this.setState({ isLoading : false, step : "" });
  }

  getButtonLabel = () => {
    let label = "Search"
    if(this.state.isLoading){
      label = this.state.step === "downloading" ? "Downloading . . ." : "Fetching . . ."
    }
    return label
  }

  handleAffiliateSelectChange = (value) => {
    const affiliates = [...this.state.affiliates];
    const affiliate = affiliates.find((affiliate) => affiliate.affiliateId === value);

    this.setState({ selected_affiliate : affiliate })
  }

  render() {
    return (
      <div className="d-flex">
        <Navigation />
        <div className="container-fluid">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Paper className="p-2 sq-shadow d-flex">
                <Typography><Link to={`/home`}>Home</Link></Typography>
                <Typography className="mr-2 ml-2">/</Typography>
                <Typography>Motor Report</Typography>
              </Paper>
            </Grid>
          </Grid>

          <Grid container spacing={2} className="my-3">

            <Grid item md={3} xs={12}>
              <AffiliateSelect onChange={this.handleAffiliateSelectChange} />
            </Grid>

            <Grid item xs={3}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                  label="Start Date"
                  inputVariant="outlined"
                  animateYearScrolling
                  name="startDate"
                  clearable
                  fullWidth
                  value={this.state.startDate}
                  onChange={this.handleStartDateChange}
                  format="dd-MM-yyyy"
                />
              </MuiPickersUtilsProvider>
            </Grid>

            <Grid item xs={3}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                  label="End Date"
                  inputVariant="outlined"
                  animateYearScrolling
                  name="endDate"
                  clearable
                  fullWidth
                  value={this.state.endDate}
                  onChange={this.handleEndDateChange}
                  format="dd-MM-yyyy"
                />
              </MuiPickersUtilsProvider>
            </Grid>

            <Grid item xs={3} className="d-flex align-items-center">
              <Button
                variant="contained"
                color="primary"
                disabled={this.state.isLoading}
                onClick={this.handleSearch}
              >
                {this.getButtonLabel()}
              </Button>
            </Grid>
          </Grid>

          {
            (this.state.showTable && this.state.data.length > 0) &&
            <Grid container spacing={2} className="d-flex">
              <Grid item xs={12}>
                <Paper className="sq-shadow overflow-auto">
                  <div className="dash-table-container">
                    <Table>
                      <TableHead>
                        <TableRow>
                          {
                            this.state.headings.map((heading, ind) =>
                              <TableCell key={ind}>{heading}</TableCell>
                            )
                          }
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {
                          this.state.data.map((data, ind) => {
                            return (
                              <TableRow key={ind}>
                                <TableCell>{ind + 1}</TableCell>
                                <TableCell>{data.QUOTE_NUMER || "-"}</TableCell>
                                <TableCell>{data.POLICY_NUMER || "-"}</TableCell>
                                <TableCell>{data.PREVIOUS_POL_NO || "-"}</TableCell>
                                <TableCell>{data.APPRV_DATE ? moment(data.APPRV_DATE).format("DD-MM-YYYY") : "-"}</TableCell>
                                <TableCell>{data.COVERAGE_FROM_DATE ? moment(data.COVERAGE_FROM_DATE).format("DD-MM-YYYY") : "-"}</TableCell>
                                <TableCell>{data.COVERAGE_TO_DATE ? moment(data.COVERAGE_TO_DATE).format("DD-MM-YYYY") : "-"}</TableCell>
                                <TableCell>{data.ENDORSEMENT_ID || "-"}</TableCell>
                                <TableCell>{data.ENDORSEMENT_NUMBER || "-"}</TableCell>
                                <TableCell>{data.INSURED_NAME || "-"}</TableCell>
                                <TableCell>{data.ULMI_DOB ? moment(data.ULMI_DOB).format("DD-MM-YYYY") : "-"}</TableCell>
                                <TableCell>{data.ULMI_MOBILE_NO || "-"}</TableCell>
                                <TableCell>{data.ULMI_EMAIL_ID || "-"}</TableCell>
                                <TableCell>{data.ULMI_GENDER || "-"}</TableCell>
                                <TableCell>{data.CIVIL_ID || "-"}</TableCell>
                                <TableCell>{data.CUSTOMER_ID || "-"}</TableCell>
                                <TableCell>{data.PRODUCT || "-"}</TableCell>
                                <TableCell>{data.NET_PREMIUM ? Number(data.NET_PREMIUM).toFixed(3) : "-"}</TableCell>
                                <TableCell>{data.CHARGES ? Number(data.CHARGES).toFixed(3) : "-"}</TableCell>
                                <TableCell>{data.GROSS_PREMIUM ? Number(data.GROSS_PREMIUM).toFixed(3) : "-"}</TableCell>
                                <TableCell>{data.PLATE_CHAR || "-"}</TableCell>
                                <TableCell>{data.PLATE_NO || "-"}</TableCell>
                                <TableCell>{data.SERIAL_NO || "-"}</TableCell>
                                <TableCell>{data.VEH_VALUE ? Number(data.VEH_VALUE).toFixed(2) : "-"}</TableCell>
                              </TableRow>
                            )
                          })
                        }
                        <TableRow>
                          <TablePagination
                            rowsPerPage={this.state.rowsPerPage}
                            rowsPerPageOptions={[10, 25, 50]}
                            page={this.state.page}
                            count={this.state.totalRecords}
                            colSpan={0}
                            onChangePage={this.handleChangePage}
                            onChangeRowsPerPage={this.handleChangeRowsPerPage}
                          />
                        </TableRow>
                      </TableBody>
                    </Table>
                  </div>
                </Paper>
              </Grid>

              <Grid item xs={12}>
                <Button onClick={this.downloadData} variant="contained" color="primary" disabled={this.state.isDownloading}>{this.state.isDownloading ? "Downloading . . ." : "Download csv"}</Button>
              </Grid>

            </Grid>
          }
          {
            (this.state.showTable && this.state.data.length === 0) &&
            <Paper className="q-shadow mt-3 pt-5 pb-5 d-flex align-items-center justify-content-center">
              <Typography variant="h5"> No Data Found </Typography>
            </Paper>
          }
        </div>
      </div>
    )
  }
}
export default withRouter(MotorReport);