import { Button, Grid, Paper, TextField, Typography, FormGroup, FormControlLabel, Checkbox } from "@material-ui/core";
import React from "react";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import Navigation from "../common/Navigation";
import Snackbar from "../common/Snackbar";
import ChannelService from "../../services/channel";
import CreatableSelect from "react-select/creatable"
import AffiliateService from "../../services/affiliates";
import AffiliateAppService from "../../services/affiliateApp"
import _ from "lodash"

class CreateAffiliateApp extends React.Component {

  state = {
    app_name: "",
    status: true,
    isLoading: false,
    showSnackbar: false,
    snackBarMessage: "",
    messageType: "",
    isEditing: false,
    channel_id: '',
    selectedAffiliate: null,
    affiliateList: []

  }

  async componentDidMount() {
    this.affiliateList();
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  }


  affiliateApp = async () => {

    let data = {
      app_name: this.state.app_name,
      status: this.state.status
    }
    this.setState({ isLoading: true });
    try {
      let response = await AffiliateAppService.addAffiliateApp(this.state.selectedAffiliate.value, data);
      if (response.data && response.data.success) {
        this.setState({
          name: "",
          isLoading: false,
          showSnackbar: true,
          snackBarMessage: response.data.message,
          messageType: "success"
        })
        this.props.history.push('/affiliator/dhofar-api-kit');
      }
      else {
        this.setState({
          isLoading: false,
          showSnackbar: true,
          snackBarMessage: "Something went wrong.",
          messageType: "danger"
        })
      }
    } catch (error) {
      this.setState({
        isLoading: false,
        showSnackbar: true,
        snackBarMessage: "Something went wrong.",
        messageType: "danger"
      })
    }


  }
  handleCloseSnackbar = () => {
    this.setState({ showSnackbar: false })
  }

  affiliateList = async () => {
    try {
      let _affiliate = []
      const response = await AffiliateService.listAllAffiliates();
      console.log("response", response)
      if (response.data && response.status == 200) {
        _.sortBy(response.data, "name").map((affiliate) => {
          _affiliate.push({ value: affiliate._id, label: affiliate.name })
        })
        this.setState({ affiliateList: _affiliate })
      } else {
        this.setState({
          showSnackbar: true,
          messageType: "danger",
          snackBarMessage: "Something went wrong."
        })
      }
    } catch (error) {
      console.log(error);
      this.setState({
        showSnackbar: true,
        messageType: "danger",
        snackBarMessage: "Something went wrong."
      })
    }
  }

  isValid = () => {
    if (this.state.app_name === "") {
      return false
    }
    return true;
  }


  render() {
    return (
      <div className="d-flex">
        <Navigation />

        <Snackbar
          showSnackbar={this.state.showSnackbar}
          messageType={this.state.messageType}
          snackBarMessage={this.state.snackBarMessage}
          onCloseSnackbar={this.handleCloseSnackbar}
        />

        <div className="container-fluid">
          <Paper className="p-2 sq-shadow mb-3 d-flex">
            <Typography><Link to={`${process.env.PUBLIC_URL}/`}>Home</Link></Typography>
            <Typography className="mr-2 ml-2">/</Typography>
            <Typography><Link to={`${process.env.PUBLIC_URL}/affiliator`}>Affiliators</Link></Typography>
            <Typography className="mr-2 ml-2">/</Typography>
            <Typography><Link to={`${process.env.PUBLIC_URL}/affiliator/dhofar-api-kit`}>Api Kit</Link></Typography>
            <Typography className="mr-2 ml-2">/</Typography>
            <Typography>Add New Api Kit</Typography>
          </Paper>

          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={3}>
              <Paper className="sq-shadow p-3">
                <CreatableSelect
                  className="mt-3 margin-bottum-affiliate-list"
                  placeholder="Affiliate"
                  isClearable
                  defaultValue={this.state.selectedAffiliate}
                  onChange={(e) => this.setState({ selectedAffiliate: e })}
                  options={this.state.affiliateList}
                />
              </Paper>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Paper className="sq-shadow p-3">
                <TextField
                  label="App Name"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  name="app_name"
                  onChange={this.handleChange}
                  value={this.state.app_name}
                />
              </Paper>
            </Grid>
            <Grid item xs={12}>

              <FormGroup>
                <Typography>Status</Typography>
                <FormControlLabel control={<Checkbox color="primary" checked={this.state.status} onChange={(e) => this.setState({ status: e.target.checked })} name="status" />} label={this.state.status ? "Active" : "Deactive"} />
              </FormGroup>
            </Grid>
            <Grid item xs={12}>
              <Paper className="sq-shadow p-2 d-flex justify-content-end">

                <Link to="/affiliator/dhofar-api-kit" className="d-inline-block mr-3">
                  <Button variant="outlined" color="primary">Cancel</Button>
                </Link>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={
                    !this.isValid() || this.state.isLoading || Boolean(!this.state.selectedAffiliate)
                  }
                  onClick={this.affiliateApp}
                >
                  {this.state.isLoading ? "Saving . . ." : "Save"}
                </Button>
              </Paper>
            </Grid>

          </Grid>
        </div>

      </div>
    )
  }
}

export default withRouter(CreateAffiliateApp);