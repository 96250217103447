import config from "./config";
import axios from "axios";

let superadmin = {};
const baseUrl = config.baseUrl;

superadmin.add = async (data) => {
  const url = `${baseUrl}/add-superadmin`;

  const res = await axios({
    url,
    method: "POST",
    data
  });
  
  return res;
}

superadmin.getUsers = async (page, limit, search) => {
  const url = `${baseUrl}/get-superadmins`;
	const params = {}

	if(page !== undefined){
		params.page = page;
	}
	if(limit !== undefined){
		params.limit = limit;
	}
	if(search !== undefined){
		params.search = search;
	}
  const res = await axios({
		url,
		params
	});
  return res;
}

superadmin.updateUser = async (id, data) => {
  const url = `${baseUrl}/superadmin/${id}`;
  const res = axios({
    url,
    method: "PUT",
    data
  });

  return res;
}

superadmin.getUser = async (id, token) => {
  const url = `${baseUrl}/superadmin/${id}`;

  let headers = {};

  if(token){
    headers["auth-token"] = token
  }
  const res = await axios({
    url,
    headers : headers
  });
  return res;
}

export default superadmin;