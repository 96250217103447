import React, { Component } from 'react'
import Navigation from "../common/Navigation";
import Snackbar from "../common/Snackbar";
import {Grid, Paper, Typography, Divider, Button } from "@material-ui/core";
import {Link, withRouter} from "react-router-dom";
import DiscountService from "../../services/discount";
import utils from "../../services/utils";
import _ from "lodash";

export default class DiscountView extends Component {
    state = {
        showSnackbar : false,
        discount:[]
    }

    humanize(str) {
        var i, frags = str.split('_');
        
        for (i=0; i<frags.length; i++) {
          frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
        }
        
        return frags.join(' ');
      }
    
    componentDidMount(){
        const center = this.props.match.params.id;
        this.setState({ center });
        this.getData();
    }

    getData = async () => {
        const id = this.props.match.params.id;
        const data = await DiscountService.getDiscount(id);
        const discount = data.data.data;
        
        this.setState({
            data:discount
        })
    }

    render() {
        return (
            
            <div className="d-flex">
                <Navigation />
                <Snackbar
                showSnackbar = { this.state.showSnackbar }
                messageType = {this.state.messageType}
                snackBarMessage = {this.state.snackBarMessage}
                onCloseSnackbar = {this.handleCloseSnackbar}
                />
                <div className="container-fluid">
                    <Paper className="p-2 sq-shadow mb-3 d-flex">
                        <Typography><Link to={`${process.env.PUBLIC_URL}/`}>Home</Link></Typography>
                        <Typography className="mr-2 ml-2">/</Typography>
                        <Typography><Link to={`${process.env.PUBLIC_URL}/discounts`}>Discounts</Link></Typography>
                        <Typography className="mr-2 ml-2">/</Typography>
                        <Typography>
                            {this.props.match.params.id}
                        </Typography>
                    </Paper>
                    <Grid container spacing={2}>
                        <Grid item xs={6} md={4}>
                            <Paper className="pt-3 pb-3 pr-2 pl-2 sq-shadow">
                                <Typography variant="h5" className="pb-2">
                                Discount Details
                                </Typography>
                                <Divider />
                                <table className="mt-4 mb-3">
                                <tbody>
                                    <tr>
                                    <td>
                                        <Typography variant="body1"> Code </Typography>
                                    </td>
                                    <td> &nbsp; - &nbsp; </td>
                                    <td>
                                        <Typography variant="body1"> {this.state.data && this.state.data.code} </Typography>
                                    </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <Typography variant="body1"> Type </Typography>
                                        </td>
                                        <td> &nbsp; - &nbsp; </td>
                                        <td>
                                            <Typography variant="body1"> { this.state.data && _.capitalize(this.state.data.type) } </Typography>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <Typography variant="body1"> Validity </Typography>
                                        </td>
                                        <td> &nbsp; - &nbsp; </td>
                                        <td>
                                            <Typography variant="body1"> {this.state.data && this.state.data.validity} </Typography>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <Typography variant="body1"> Discount </Typography>
                                        </td>
                                        <td> &nbsp; - &nbsp; </td>
                                        <td>
                                            <Typography variant="body1"> {this.state.data && this.state.data.discount} </Typography>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <Typography variant="body1"> Status </Typography>
                                        </td>
                                        <td> &nbsp; - &nbsp; </td>
                                        <td>
                                            <Typography variant="body1"> {this.state.data && (this.state.data.status == 1)? "Active":"Deactive"} </Typography>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <Typography variant="body1"> Min Discount Amount </Typography>
                                        </td>
                                        <td> &nbsp; - &nbsp; </td>
                                        <td>
                                            <Typography variant="body1"> {this.state.data && this.state.data.min_amount} </Typography>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <Typography variant="body1"> Max Discount Amount </Typography>
                                        </td>
                                        <td> &nbsp; - &nbsp; </td>
                                        <td>
                                            <Typography variant="body1"> {this.state.data && this.state.data.max_discount} </Typography>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <Typography variant="body1"> Created At </Typography>
                                        </td>
                                        <td> &nbsp; - &nbsp; </td>
                                        <td>
                                            <Typography variant="body1"> {this.state.data && utils.getFormatedDate(this.state.data.created_at)} </Typography>
                                        </td>
                                    </tr>
                                </tbody>
                                </table>
                                
                            </Paper>
                            </Grid>
                            <Grid item xs={6} md={4}>
                            <Paper className=" pt-3 pb-3 pr-2 pl-2 sq-shadow">
                                <Typography variant="h5" className="pb-2">
                                Products
                                </Typography>
                                <Divider />
                                <div class="pt-3">
                                {
                                    
                                    this.state.data && this.state.data.products.map((product, ind) => 
                                        
                                    <Typography class="pt-1" variant="body1" key = {ind}>
                                       {/* { _.startCase(product.replace(/-/g, ':').replace(/_/g, ' '))} */}
                                        {ind +1} - {_.startCase((this.humanize(product)).replace(/-/g, ' : '))}
                                    </Typography>
                                )}
                                </div>
                            </Paper>    
                        </Grid>
                    </Grid>
                </div>    
            </div>
            
        )
    }
}
