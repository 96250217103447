import React from "react";
import Navigation from "../common/Navigation";
import { Grid, Typography, Paper } from "@material-ui/core";
import Affiliates from "../Affiliates";
import { Link } from "react-router-dom";

class HomeSa extends React.Component {
  state = {
    centerIndex: "",
    showDummyTab: true,
    saFor: ""
  }

  handleChangeCenterIndex = (ind) => {
    this.setState({ centerIndex: ind })
  }

  componentDidMount() {
    sessionStorage.removeItem("center_id");
  }
  render() {
    return (
      <div className="d-flex">
        <Navigation />
        <div className="container-fluid">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Paper className="p-2 sq-shadow d-flex">
                <Typography>
                  <Link to={`${process.env.PUBLIC_URL}/`}>Home</Link>
                </Typography>
                <Typography className="mr-2 ml-2">/</Typography>
                <Typography>Affiliators</Typography>
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Affiliates />
            </Grid>
          </Grid>
        </div>
      </div>
    )
  }
}
export default HomeSa;