import config from "./config";
import axios from "axios";
let discount = {};
const baseUrl = config.baseUrl;

discount.discountAdd = async(data) => {
  
  const url = `${baseUrl}/discounts`;
  const res = await axios({
    url,
    method: "POST",
    data
  });
  return res;
}

discount.getDiscountList = async () => {
  const url = `${baseUrl}/discounts`;
  const res = await axios(url);
  return res;
}

discount.deleteDiscount = async (id) => {
  const url = `${baseUrl}/discounts/${id}`;
  const res = await axios({
    url,
    method: "DELETE",
    id
  });
  return res;
}

discount.getDiscount = async (id) => {
  const url = `${baseUrl}/discounts/${id}`;
  const res = await axios(url);
  return res;
} 


discount.updateDiscount = async (id , data) => {
  const url = `${baseUrl}/discounts/${id}`;
  const res = await axios({
    url,
    method: "PUT",
    data
  });
  return res;
}




export default discount;