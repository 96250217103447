import { Button, Checkbox, Grid, IconButton, InputAdornment, List, ListItem, ListItemIcon, ListItemText, MenuItem, Paper, TextField, Typography } from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import React from "react";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import Navigation from "../common/Navigation";
import Auth from "../../services/auth";
import Snackbar from "../common/Snackbar";
import AffiliateService from "../../services/affiliates";
import _ from "lodash";
import Select from "react-select";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import moment from "moment";
import { INSURANCE_TYPES, PLAN_TYPES, PAYMENT_TYPES } from "../../constants";
import branchList from "../../services/branches.json";
import regionList from "../../services/region.json";

const CUSTOMER_TYPE = {
  CUSTOMER : "Customer",
  STAFF : "Staff"
}

class CreateAffiliator extends React.Component {

  state = {
    fname: "",
    email: "",
    password: "",
    mobileNumber: "",
    showPassword: false,
    isLoading: false,
    affiliateType: "",
    affiliateSubType: "",
    employeeID: "",
    employeeName: "",
    branchID: "",
    designation: "",
    under: "none",
    accountCode: "",
    availablePlans: [],
    IMCommision: "",
    IMCommision_staff : "",
    AHPCommision: "",
    AHPCommision_staff: "",
    UDWCommision: "",
    UDWCommision_staff: "",
    UHIPCommision: "",
    UHIPCommision_staff: "",
    term_plan_commission: "",
    term_plan_commission_staff: "",
    pa_commission: "",
    pa_commission_staff: "",
    standard_travel_commission: "",
    standard_travel_commission_staff: "",
    lite_travel_commission: "",
    lite_travel_commission_staff: "",
    inbound_travel_commission: "",
    inbound_travel_commission_staff: "",
    senior_travel_commission: "",
    senior_travel_commission_staff: "",
    covid_shield_travel_commission: "",
    covid_shield_travel_commission_staff: "",
    family_travel_commission: "",
    family_travel_commission_staff: "",
    home_commission: "",
    home_commission_staff: "",
    riyati_commission: "",
    riyati_commission_staff: "",
    channel_id: "none",
    sub_channel_id: "none",
    showSnackbar: false,
    snackBarMessage: "",
    messageType: "",
    availableInsurance: [],
    affiliates: [],
    isEditing: false,
    under_changed: false,
    selectedParentAffiliate: {},
    parents: [],
    channels: [],
    partnerBranch: "",
    employeeBranchID: "",
    employeeRegionID: "",
    branchList: [],
    regionList: [],
    affiliateOptions: [],
    home_insurance_discount_start_date : null,
    home_insurance_discount_end_date : null,
    available_payment_methods : []
  }

  async componentDidMount() {
    this.getAffiliates();
    await this.getChannels();
    const id = this.props.match.params.id
    if (id) {
      this.getAffiliate(id);
      this.setState({ isEditing: true, affiliate_id: id })
    }
    
    regionList.sort((a, b) => a.id - b.id);
    branchList.sort((a, b) => a.id - b.id);
    this.setState({ branchList, regionList })
  }

  handleInsuranceTypeChange = (type, healthInsuranceType) => {

    let availableTypes = [...this.state[type]];

    const ind = availableTypes.indexOf(healthInsuranceType);
    if (ind < 0) {
      availableTypes.push(healthInsuranceType);
    } else {
      availableTypes.splice(ind, 1);
      if (type == "availableInsurance") { this.removePlans(healthInsuranceType); }
    }
    this.setState({ [type]: availableTypes })
  }

  removePlans = (insuranceType) => {
    let availablePlans = [...this.state.availablePlans];

    if (insuranceType === INSURANCE_TYPES.health) {
      availablePlans = availablePlans.filter(
        value => (![PLAN_TYPES.individual_medical,
        PLAN_TYPES.affordable_health_plan,
        PLAN_TYPES.unified_domestic_worker,
        PLAN_TYPES.unified_health_insurance_plan].includes(value))
      )
    }
    if (insuranceType === INSURANCE_TYPES.life) {
      availablePlans = availablePlans.filter(
        value => (![
          PLAN_TYPES.term_plan,
          PLAN_TYPES.personal_accident
        ].includes(value))
      )
    }
    if (insuranceType === INSURANCE_TYPES.travel) {
      availablePlans = availablePlans.filter(
        value => (![
          PLAN_TYPES.standard_travel,
          PLAN_TYPES.lite_travel,
          PLAN_TYPES.senior_travel,
          PLAN_TYPES.covid_shield_travel,
          PLAN_TYPES.family_travel
        ].includes(value))
      )
    }

    this.setState({ availablePlans })
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  }

  handleChangeAffiliateType = (e) => {
    if (e.target.value == "Bank") {
      this.setState({ [e.target.name]: e.target.value, under: "none" });
    } else {
      this.setState({ [e.target.name]: e.target.value });
    }
  }

  handleChangeUnder = (value) => {
    const under = value;
    const affiliates = [...this.state.affiliates];
    let selectedParentAffiliate = affiliates.find((affiliate) => affiliate.affiliateId === under);

    // let affiliateSubType = this.state.affiliateSubType;
    // if(value === "none"){
    //   affiliateSubType = "Company";
    // }else if(selectedParentAffiliate && _.get(selectedParentAffiliate, "affiliateSubType", false) === "Company"){
    //   affiliateSubType = "Region";
    // }else if(selectedParentAffiliate && _.get(selectedParentAffiliate, "affiliateSubType", false) === "Region"){
    //   affiliateSubType = "Branch";
    // }else if(selectedParentAffiliate && _.get(selectedParentAffiliate, "affiliateSubType", false) === "Branch"){
    //   affiliateSubType = "Branch Manager";
    // }else if(selectedParentAffiliate && _.get(selectedParentAffiliate, "affiliateSubType", false) === "Branch Manager"){
    //   affiliateSubType = "Staff";
    // }

    this.setState({ under_changed: true, under, selectedParentAffiliate });
  }

  onBlurEmail = () => {
    this.setState({ showEmailError: !this.isValidMail() })
  }

  onBlurPassword = () => {
    this.setState({ showPasswordError: this.state.password.length < 6 });
  }

  handleClickShowPassword = () => {
    this.setState({ showPassword: !this.state.showPassword });
  }

  handleCloseSnackbar = () => {
    this.setState({ showSnackbar: false })
  }

  isValidMail = () => {
    const value = this.state.email;
    const atpos = value.indexOf("@");
    const dotpos = value.lastIndexOf(".");
    if (atpos < 1 || dotpos < atpos + 2 || dotpos + 2 >= value.length || value === "")
      return false;
    else
      return true;
  }

  saveAffiliate = async () => {
    this.setState({ isLoading: true });

    const parent_id = this.state.under;
    const isRoot = parent_id === "none";
    const parents = isRoot ? [] : [...this.state.selectedParentAffiliate.parents, parent_id];

    const dataObj = {
      name: this.state.fname,
      email: this.state.email,
      phone: this.state.mobileNumber,
      availableInsurance: this.state.availableInsurance,
      availablePlans: this.state.availablePlans,
      affiliateType: this.state.affiliateType,
      IMCommision: this.state.IMCommision,
      IMCommision_staff: this.state.IMCommision_staff,
      AHPCommision: this.state.AHPCommision,
      AHPCommision_staff: this.state.AHPCommision_staff,
      UDWCommision: this.state.UDWCommision,
      UDWCommision_staff: this.state.UDWCommision_staff,
      UHIPCommision: this.state.UHIPCommision,
      UHIPCommision_staff: this.state.UHIPCommision_staff,
      term_plan_commission: this.state.term_plan_commission,
      term_plan_commission_staff: this.state.term_plan_commission_staff,
      pa_commission: this.state.pa_commission,
      pa_commission_staff: this.state.pa_commission_staff,
      standard_travel_commission: this.state.standard_travel_commission,
      standard_travel_commission_staff: this.state.standard_travel_commission_staff,
      inbound_travel_commission: this.state.inbound_travel_commission,
      inbound_travel_commission_staff: this.state.inbound_travel_commission_staff,
      lite_travel_commission: this.state.lite_travel_commission,
      lite_travel_commission_staff: this.state.lite_travel_commission_staff,
      senior_travel_commission: this.state.senior_travel_commission,
      senior_travel_commission_staff: this.state.senior_travel_commission_staff,
      covid_shield_travel_commission: this.state.covid_shield_travel_commission,
      covid_shield_travel_commission_staff: this.state.covid_shield_travel_commission_staff,
      family_travel_commission: this.state.family_travel_commission,
      family_travel_commission_staff: this.state.family_travel_commission_staff,
      home_commission: this.state.home_commission,
      home_commission_staff: this.state.home_commission_staff,
      riyati_commission: this.state.riyati_commission,
      riyati_commission_staff: this.state.riyati_commission_staff,
      channel_id: this.state.channel_id,
      sub_channel_id: this.state.sub_channel_id,
      affiliateSubType: this.state.affiliateSubType,
      employeeID: this.state.employeeID,
      employeeName: this.state.employeeName,
      branchID: this.state.branchID,
      employeeBranchID: this.state.employeeBranchID,
      employeeRegionID: this.state.employeeRegionID,
      designation: this.state.designation,
      parent_id: isRoot ? null : parent_id,
      accountCode: this.state.accountCode,
      partnerBranch: this.state.partnerBranch,
      isRoot,
      parents,
      home_insurance_discount_start_date : this.state.home_insurance_discount_start_date ? moment(this.state.home_insurance_discount_start_date).format("DD-MM-YYYY") : "",
      home_insurance_discount_end_date : this.state.home_insurance_discount_end_date ? moment(this.state.home_insurance_discount_end_date).format("DD-MM-YYYY") : "",
      available_payment_methods : this.state.available_payment_methods
    }

    if (this.state.isEditing) {
      AffiliateService.updateAffiliate(this.state.affiliate_id, dataObj).then((affiliateEditRes) => {
        this.setState({
          showSnackbar: true,
          snackBarMessage: "Changes Saved Successfully",
          messageType: "success",
          isLoading: false
        });
      })
    } else {
      try {
        const registerData = await Auth.register(this.state.email, this.state.password);
        const uid = registerData.user.uid;
        dataObj.affiliateId = uid;

        const data = await AffiliateService.addAffiliate(dataObj);
        if (data.data.inserted) {
          Auth.logout2();
          this.setState({
            showSnackbar: true,
            snackBarMessage: "Affiliator Added",
            messageType: "success"
          });
          this.clearState();
        }
      } catch (e) {
        this.setState({ isLoading: false });
        if (e.code === "auth/email-already-in-use") {
          this.setState({
            showSnackbar: true,
            snackBarMessage: "Email already in use",
            messageType: "danger"
          })
        }
      }
    }
  }

  clearState = () => {
    this.setState({
      fname: "",
      email: "",
      password: "",
      mobileNumber: "",
      accountCode: "",
      partnerBranch: "",
      showPassword: false,
      isLoading: false,
      affiliateType: "",
      under: "none",
      availablePlans: [],
      availableInsurance: [],
      IMCommision: "",
      IMCommision_staff: "",
      AHPCommision: "",
      AHPCommision_staff: "",
      UDWCommision: "",
      UDWCommision_staff: "",
      UHIPCommision: "",
      UHIPCommision_staff: "",
      term_plan_commission: "",
      term_plan_commission_staff: "",
      pa_commission: "",
      pa_commission_staff: "",
      standard_travel_commission: "",
      standard_travel_commission_staff: "",
      inbound_travel_commission: "",
      inbound_travel_commission_staff: "",
      lite_travel_commission: "",
      lite_travel_commission_staff: "",
      senior_travel_commission: "",
      senior_travel_commission_staff: "",
      covid_shield_travel_commission: "",
      covid_shield_travel_commission_staff: "",
      family_travel_commission: "",
      family_travel_commission_staff: "",
      home_commission: "",
      home_commission_staff: "",
      riyati_commission: "",
      riyati_commission_staff: "",
      channel_id: "none",
      sub_channel_id: "none",
      selectedParentAffiliate: {},
      affiliateSubType: "",
      employeeID: "",
      employeeName: "",
      branchID: "",
      designation: "",
      home_insurance_discount_start_date : null,
      home_insurance_discount_end_date : null
    }, this.getAffiliates)
  }

  isValid = () => {
    if (
      this.state.fname === "" ||
      !this.isValidMail() ||
      (
        !this.state.isEditing &&
        (
          this.state.password === "" ||
          this.state.password.length < 6
        )
      ) ||
      this.state.mobileNumber === "" ||
      this.state.affiliateType === "" ||
      // this.state.availableInsurance.length === 0 ||
      (
        (
          this.state.availableInsurance.includes(INSURANCE_TYPES.health) ||
          this.state.availableInsurance.includes(INSURANCE_TYPES.life) ||
          this.state.availableInsurance.includes(INSURANCE_TYPES.travel)
        ) && this.state.availablePlans.length === 0
      ) ||
      // (this.state.availablePlans.includes(PLAN_TYPES.individual_medical) && !this.state.IMCommision >= 0) ||
      // (this.state.availablePlans.includes(PLAN_TYPES.affordable_health_plan) && !this.state.AHPCommision >= 0) ||
      // (this.state.availablePlans.includes(PLAN_TYPES.unified_domestic_worker) && !this.state.UDWCommision >= 0) ||
      // (this.state.availablePlans.includes(PLAN_TYPES.unified_health_insurance_plan) && !this.state.UHIPCommision >= 0) ||
      // (this.state.availablePlans.includes(PLAN_TYPES.term_plan) && !this.state.term_plan_commission >= 0) ||
      // (this.state.availablePlans.includes(PLAN_TYPES.personal_accident) && !this.state.pa_commission >= 0) ||
      // (this.state.availablePlans.includes(PLAN_TYPES.standard_travel) && !this.state.standard_travel_commission >= 0) ||
      // (this.state.availablePlans.includes(PLAN_TYPES.inbound) && !this.state.inbound_travel_commission >= 0) ||
      // (this.state.availablePlans.includes(PLAN_TYPES.lite_travel) && !this.state.lite_travel_commission >= 0) ||
      // (this.state.availablePlans.includes(PLAN_TYPES.covid_shield_travel) && !this.state.covid_shield_travel_commission >= 0) ||
      // (this.state.availablePlans.includes(PLAN_TYPES.senior_travel) && !this.state.senior_travel_commission >= 0) ||
      // (this.state.availablePlans.includes(PLAN_TYPES.family_travel) && !this.state.family_travel_commission >= 0) ||
      // (this.state.availableInsurance.includes(INSURANCE_TYPES.home) && !this.state.home_commission >= 0) ||
      (this.state.availableInsurance.includes(INSURANCE_TYPES.riyati) && parseFloat(this.state.riyati_commission) === NaN) ||
      // (this.state.availableInsurance.includes(INSURANCE_TYPES.riyati)) ||
      (
        this.state.affiliateSubType === "Staff" && (
          this.state.employeeID === "" ||
          this.state.employeeName === "" ||
          this.state.designation === ""
        )
      )
    ) {
      return false
    }
    return true;
  }

  getAffiliates = async () => {
    const res = await AffiliateService.getAllAffiliates();
    let affiliateOptions = [];

    if (res.data && Array.isArray(res.data)) {
      affiliateOptions = res.data.map((affiliate) => {
        if (affiliate.affiliateId !== this.state.affiliate_id) {
          return { value: affiliate.affiliateId, label: affiliate.name };
        }
      })
      affiliateOptions = [{ value: "none", label: "None" }, ...affiliateOptions]
    }

    affiliateOptions = _.without(affiliateOptions, undefined);

    this.setState({ affiliates: res.data, affiliateOptions });
  }

  getAffiliate = (id) => {
    AffiliateService.getAffiliate(id).then((res) => {
      const affiliate = res.data.data;
      const {
        name: fname,
        email,
        phone: mobileNumber,
        accountCode,
        partnerBranch,
        availableInsurance,
        availablePlans,
        affiliateType,
        IMCommision,
        IMCommision_staff,
        AHPCommision,
        AHPCommision_staff,
        UDWCommision,
        UDWCommision_staff,
        UHIPCommision,
        UHIPCommision_staff,
        term_plan_commission,
        term_plan_commission_staff,
        pa_commission,
        pa_commission_staff,
        standard_travel_commission,
        standard_travel_commission_staff,
        inbound_travel_commission,
        inbound_travel_commission_staff,
        lite_travel_commission,
        lite_travel_commission_staff,
        senior_travel_commission,
        senior_travel_commission_staff,
        covid_shield_travel_commission,
        covid_shield_travel_commission_staff,
        family_travel_commission,
        family_travel_commission_staff,
        home_commission,
        home_commission_staff,
        riyati_commission,
        riyati_commission_staff,
        channel_id,
        sub_channel_id,
        affiliateSubType,
        employeeID,
        employeeName,
        branchID,
        employeeBranchID,
        employeeRegionID,
        designation,
        parent_id,
        home_insurance_discount_start_date,
        home_insurance_discount_end_date,
        available_payment_methods
      } = affiliate;
      
      this.setState({
        fname,
        email,
        mobileNumber,
        accountCode,
        partnerBranch,
        availableInsurance: availableInsurance || [],
        availablePlans: Array.isArray(availablePlans) ? availablePlans : [],
        affiliateType,
        IMCommision: IMCommision || "",
        IMCommision_staff: IMCommision_staff || "",
        AHPCommision: AHPCommision || "",
        AHPCommision_staff: AHPCommision_staff || "",
        UDWCommision: UDWCommision || "",
        UDWCommision_staff: UDWCommision_staff || "",
        UHIPCommision: UHIPCommision || "",
        UHIPCommision_staff: UHIPCommision_staff || "",
        term_plan_commission: term_plan_commission || "",
        term_plan_commission_staff: term_plan_commission_staff || "",
        pa_commission: pa_commission || "",
        pa_commission_staff: pa_commission_staff || "",
        standard_travel_commission: standard_travel_commission || "",
        standard_travel_commission_staff: standard_travel_commission_staff || "",
        inbound_travel_commission: inbound_travel_commission || "",
        inbound_travel_commission_staff: inbound_travel_commission_staff || "",
        lite_travel_commission: lite_travel_commission || "",
        lite_travel_commission_staff: lite_travel_commission_staff || "",
        senior_travel_commission: senior_travel_commission || "",
        senior_travel_commission_staff: senior_travel_commission_staff || "",
        covid_shield_travel_commission: covid_shield_travel_commission || "",
        covid_shield_travel_commission_staff: covid_shield_travel_commission_staff || "",
        family_travel_commission: family_travel_commission || "",
        family_travel_commission_staff: family_travel_commission_staff || "",
        home_commission: home_commission || "",
        home_commission_staff: home_commission_staff || "",
        riyati_commission: riyati_commission || "",
        riyati_commission_staff: riyati_commission_staff || "",
        channel_id: channel_id === null ? 'none' : channel_id,
        sub_channel_id: sub_channel_id === null ? 'none' : sub_channel_id,
        affiliateSubType,
        employeeID,
        employeeName,
        branchID,
        employeeBranchID,
        employeeRegionID,
        designation,
        under: parent_id || "none",
        home_insurance_discount_start_date : home_insurance_discount_start_date ? moment(home_insurance_discount_start_date, "DD-MM-YYYY").format() : null,
        home_insurance_discount_end_date : home_insurance_discount_end_date ? moment(home_insurance_discount_end_date, "DD-MM-YYYY").format() : null,
        available_payment_methods : available_payment_methods || []
      }, () => {
        this.handleChangeUnder(parent_id || "none")
      })
    })
  }

  getChannels = async () => {
    let response = [];
    try {
      response = await AffiliateService.getChannels()
      response = _.get(response, "data", []);
    } catch (error) { }
    this.setState({ channels: response })
    return;
  }

  handleDateChange = (date, field) => {
    this.setState({
      [field] : date
    })
  }

  render() {
    return (
      <div className="d-flex">
        <Navigation />

        <Snackbar
          showSnackbar={this.state.showSnackbar}
          messageType={this.state.messageType}
          snackBarMessage={this.state.snackBarMessage}
          onCloseSnackbar={this.handleCloseSnackbar}
        />

        <div className="container-fluid">
          <Paper className="p-2 sq-shadow mb-3 d-flex">
            <Typography><Link to={`${process.env.PUBLIC_URL}/`}>Home</Link></Typography>
            <Typography className="mr-2 ml-2">/</Typography>
            <Typography><Link to={`${process.env.PUBLIC_URL}/affiliator`}>Affiliators</Link></Typography>
            <Typography className="mr-2 ml-2">/</Typography>
            <Typography> Add New Affiliator </Typography>
          </Paper>

          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={3}>
              <Paper className="sq-shadow p-3">

                <TextField
                  label="Affiliate Type"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  select
                  name="affiliateType"
                  onChange={this.handleChange}
                  value={this.state.affiliateType}
                  className="mb-3"
                >
                  <MenuItem value="" disabled>Select Affiliate type</MenuItem>
                  <MenuItem value="Broker">Broker</MenuItem>
                  <MenuItem value="Agent">Agent</MenuItem>
                  <MenuItem value="Bank">Bank</MenuItem>
                  <MenuItem value="Employee">Employee</MenuItem>
                  <MenuItem value="Mobile App">Mobile App</MenuItem>
                  <MenuItem value="Website">Website</MenuItem>
                </TextField>

                <div className="mb-3">
                  <label className="text-secondary"><small>Under</small></label>
                  <Select
                    className="select-with-filter"
                    classNamePrefix="select"
                    name="center"
                    menuPortalTarget={document.body}
                    menuPosition={'fixed'}
                    styles={{
                      menuPortal: provided => ({ ...provided, zIndex: 9999 }),
                      menu: provided => ({ ...provided, zIndex: 9999 })
                    }}
                    value={this.state.affiliateOptions.find(obj => obj.value === this.state.under)}
                    options={this.state.affiliateOptions}
                    onChange={e => { this.handleChangeUnder(e.value) }}
                  />
                </div>

                {/* <TextField
                  label="Under"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  select
                  name="under"
                  onChange={e => { this.handleChangeUnder(e.target.value) }}
                  value={this.state.under}
                  className="mb-3"
                  // disabled={this.state.affiliateType == "Bank"}
                >
                  <MenuItem value="none">None</MenuItem>
                  {
                    this.state.affiliates.map((affiliate) => {
                    // this.state.affiliates.filter(affiliate => (!["Staff", "Manager"].includes(affiliate.affiliateSubType) && affiliate.affiliateType !== "Employee")).map((affiliate) => {
                      if(affiliate.affiliateId !== this.state.affiliate_id){
                        return (
                          <MenuItem key={affiliate.affiliateId} value={affiliate.affiliateId}>{affiliate.name}</MenuItem>
                        )
                      }
                    })
                  }
                </TextField> */}

                <TextField
                  label="Affiliate Sub Type"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  select
                  name="affiliateSubType"
                  onChange={this.handleChange}
                  value={this.state.affiliateSubType}
                  className="mb-3"
                >
                  <MenuItem value="" disabled>Select Affiliate Sub type</MenuItem>
                  {/* <MenuItem value="Company" disabled={this.state.under !== "none" || ["Company"].includes(_.get(this.state.selectedParentAffiliate, "affiliateSubType", "")) }>Company</MenuItem> */}
                  {/* <MenuItem value="Region" disabled={ this.state.under === "none" || ["Region", "Branch", "Branch Manager", "Staff"].includes(_.get(this.state.selectedParentAffiliate, "affiliateSubType", "")) }>Region</MenuItem> */}
                  {/* <MenuItem value="Branch" disabled={ this.state.under === "none" || ["Company", "Branch", "Branch Manager", "Staff"].includes(_.get(this.state.selectedParentAffiliate, "affiliateSubType", "")) } >Branch</MenuItem> */}
                  {/* <MenuItem value="Branch Manager" disabled={ this.state.under === "none" || ["Company", "Region", "Branch Manager", "Staff"].includes(_.get(this.state.selectedParentAffiliate, "affiliateSubType", ""))} >Branch Manager</MenuItem> */}
                  {/* <MenuItem value="Manager" disabled={ this.state.under === "none" || !["Company", "Manager", "Staff"].includes(_.get(this.state.selectedParentAffiliate, "affiliateSubType", "")) } >Manager</MenuItem> */}
                  {/* <MenuItem value="Staff" disabled={ this.state.under === "none" || !["Company", "Manager", "Staff"].includes(_.get(this.state.selectedParentAffiliate, "affiliateSubType", "")) } >Staff</MenuItem> */}

                  <MenuItem value="Company">Company</MenuItem>
                  <MenuItem value="Manager">Manager</MenuItem>
                  <MenuItem value="Staff">Staff</MenuItem>
                </TextField>

                <TextField
                  label="Full Name"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  name="fname"
                  onChange={this.handleChange}
                  value={this.state.fname}
                />
                <TextField
                  label="Email"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  name="email"
                  onChange={this.handleChange}
                  value={this.state.email}
                  onBlur={this.onBlurEmail}
                  error={this.state.showEmailError}
                  helperText={this.state.showEmailError ? "Please enter valid email" : ""}
                />
                {
                  !this.state.isEditing &&
                  <TextField
                    label="Password"
                    type={this.state.showPassword ? "text" : "password"}
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    name="password"
                    onChange={this.handleChange}
                    value={this.state.password}
                    onBlur={this.onBlurPassword}
                    error={this.state.showPasswordError}
                    helperText={this.state.showPasswordError ? "Please enter atleast 6 digit password" : ""}

                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            edge="end"
                            onClick={this.handleClickShowPassword}
                            tabIndex="-1"
                          >
                            {this.state.showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}

                  />
                }
                <TextField
                  label="Mobile Number"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  name="mobileNumber"
                  onChange={this.handleChange}
                  value={this.state.mobileNumber}
                />

                {
                  this.state.affiliateSubType === "Company" &&
                  <TextField
                    label="Account Code"
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    name="accountCode"
                    onChange={this.handleChange}
                    value={this.state.accountCode}
                  />
                }

                {
                  this.state.affiliateSubType !== "Company" &&
                  <TextField
                    label="Partner Branch"
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    name="partnerBranch"
                    onChange={this.handleChange}
                    value={this.state.partnerBranch}
                  />
                }

                {
                  ["Manager", "Staff"].includes(this.state.affiliateSubType) &&
                  <React.Fragment>
                    <TextField
                      label="Branch"
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      select
                      name="employeeBranchID"
                      onChange={this.handleChange}
                      value={this.state.employeeBranchID}
                      className="mb-3"
                    >
                      {
                        this.state.branchList.map((branch, ind) => {
                          return <MenuItem key={ind} value={branch.id}>{`${branch.id} - ${branch.name}`}</MenuItem>
                        })
                      }
                    </TextField>

                    <TextField
                      label="Region"
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      select
                      name="employeeRegionID"
                      onChange={this.handleChange}
                      value={this.state.employeeRegionID}
                      className="mb-3"
                    >
                      {
                        this.state.regionList.map((region, ind) => {
                          return <MenuItem key={ind} value={region.id}>{`${region.id} - ${region.name}`}</MenuItem>
                        })
                      }
                    </TextField>

                    <TextField
                      label="Employee ID"
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      name="employeeID"
                      onChange={this.handleChange}
                      value={this.state.employeeID}
                    />
                    <TextField
                      label="Employee Name"
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      name="employeeName"
                      onChange={this.handleChange}
                      value={this.state.employeeName}
                    />
                    <TextField
                      label="Designation"
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      name="designation"
                      onChange={this.handleChange}
                      value={this.state.designation}
                    />
                  </React.Fragment>
                }
                {/* {
                  this.state.affiliateSubType === "Branch" &&
                    <TextField
                      label="Branch ID"
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      name="branchID"
                      onChange={this.handleChange}
                      value={this.state.branchID}
                    />
                } */}

                <TextField
                  label="Channel"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  select
                  name="channel_id"
                  onChange={this.handleChange}
                  value={this.state.channel_id}
                  className="mb-3"
                >
                  <MenuItem value="none">None</MenuItem>
                  {
                    this.state.channels.map((channel) => {
                      return (
                        <MenuItem key={channel._id} value={channel._id}>{channel.name}</MenuItem>
                      )
                    })
                  }
                </TextField>

                <TextField
                  label="Sub Channel"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  select
                  name="sub_channel_id"
                  onChange={this.handleChange}
                  value={this.state.sub_channel_id}
                  className="mb-3"
                  disabled={this.state.channel_id === "none"}
                >
                  <MenuItem value="none">None</MenuItem>
                  {
                    this.state.channels.map((channel) => {
                      return (
                        <MenuItem disabled={this.state.channel_id === channel._id} key={channel._id} value={channel._id}>{channel.name}</MenuItem>
                      )
                    })
                  }
                </TextField>
              </Paper>
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <Paper className="sq-shadow p-3">
                <Typography variant="h6">Available Insurance</Typography>

                <List className="mt-2">
                  <ListItem
                    className="border rounded mb-3"
                    button
                    onClick={() => this.handleInsuranceTypeChange("availableInsurance", INSURANCE_TYPES.health)}
                    dense
                  >
                    <ListItemIcon>
                      <Checkbox
                        checked={this.state.availableInsurance.includes(INSURANCE_TYPES.health)}
                      />
                    </ListItemIcon>
                    <ListItemText primary={INSURANCE_TYPES.health} />
                  </ListItem>

                  <ListItem
                    className="border rounded mb-3"
                    button
                    dense
                    onClick={() => this.handleInsuranceTypeChange("availableInsurance", INSURANCE_TYPES.life)}
                  >
                    <ListItemIcon>
                      <Checkbox
                        checked={this.state.availableInsurance.includes(INSURANCE_TYPES.life)}
                      />
                    </ListItemIcon>
                    <ListItemText primary={INSURANCE_TYPES.life} />
                  </ListItem>

                  <ListItem
                    className="border rounded mb-3"
                    button
                    onClick={() => this.handleInsuranceTypeChange("availableInsurance", INSURANCE_TYPES.travel)}
                    dense
                  >
                    <ListItemIcon>
                      <Checkbox
                        checked={this.state.availableInsurance.includes(INSURANCE_TYPES.travel)}
                      />
                    </ListItemIcon>
                    <ListItemText primary={INSURANCE_TYPES.travel} />
                  </ListItem>

                  <ListItem
                    className="border rounded mb-3"
                    button
                    onClick={() => this.handleInsuranceTypeChange("availableInsurance", INSURANCE_TYPES.home)}
                    dense
                  >
                    <ListItemIcon>
                      <Checkbox
                        checked={this.state.availableInsurance.includes(INSURANCE_TYPES.home)}
                      />
                    </ListItemIcon>
                    <ListItemText primary={INSURANCE_TYPES.home} />
                  </ListItem>

                  <ListItem
                    className="border rounded mb-3"
                    button
                    onClick={() => this.handleInsuranceTypeChange("availableInsurance", INSURANCE_TYPES.riyati)}
                    dense
                  >
                    <ListItemIcon>
                      <Checkbox
                        checked={this.state.availableInsurance.includes(INSURANCE_TYPES.riyati)}
                      />
                    </ListItemIcon>
                    <ListItemText primary={INSURANCE_TYPES.riyati} />
                  </ListItem>

                  <ListItem
                    className="border rounded mb-3"
                    button
                    onClick={() => this.handleInsuranceTypeChange("availableInsurance", INSURANCE_TYPES.motor)}
                    dense
                  >
                    <ListItemIcon>
                      <Checkbox
                        checked={this.state.availableInsurance.includes(INSURANCE_TYPES.motor)}
                      />
                    </ListItemIcon>
                    <ListItemText primary={INSURANCE_TYPES.motor} />
                  </ListItem>

                  <ListItem
                    className="border rounded"
                    button
                    onClick={() => this.handleInsuranceTypeChange("availableInsurance", INSURANCE_TYPES.group_credit_life)}
                    dense
                  >
                    <ListItemIcon>
                      <Checkbox
                        checked={this.state.availableInsurance.includes(INSURANCE_TYPES.group_credit_life)}
                      />
                    </ListItemIcon>
                    <ListItemText primary={INSURANCE_TYPES.group_credit_life} />
                  </ListItem>
                </List>
              </Paper>

              {/* Payment Types */}
              <Paper className="sq-shadow p-3 mt-3">
                <Typography variant="h6">Available Payment Types</Typography>

                <List className="mt-2">

                  {
                    Object.keys(PAYMENT_TYPES).map((payment_type) => 
                      <ListItem
                        key={payment_type}
                        className="border rounded mb-3"
                        button
                        onClick={() => this.handleInsuranceTypeChange("available_payment_methods", PAYMENT_TYPES[payment_type])}
                        dense
                      >
                        <ListItemIcon>
                          <Checkbox
                            checked={this.state.available_payment_methods.includes(PAYMENT_TYPES[payment_type])}
                          />
                        </ListItemIcon>
                        <ListItemText primary={PAYMENT_TYPES[payment_type]} />
                      </ListItem>
                    )
                  }

                </List>
              </Paper>

              {
                this.state.availableInsurance.includes(INSURANCE_TYPES.home) &&
                <Paper className="sq-shadow p-3 mt-3">
                  <Typography variant="h6">Home Insurance Discount</Typography>

                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DatePicker
                      label="Start Date"
                      inputVariant="outlined"
                      animateYearScrolling
                      name="home_insurance_discount_start_date"
                      className="mt-3"
                      format="dd-MM-yyyy"
                      clearable
                      fullWidth
                      value={this.state.home_insurance_discount_start_date}
                      onChange={(date) => this.handleDateChange(date, "home_insurance_discount_start_date")}
                    />
                  </MuiPickersUtilsProvider>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DatePicker
                      label="End Date"
                      inputVariant="outlined"
                      animateYearScrolling
                      name="home_insurance_discount_end_date"
                      className="mt-3"
                      format="dd-MM-yyyy"
                      clearable
                      fullWidth
                      minDate={this.state.home_insurance_discount_start_date}
                      value={this.state.home_insurance_discount_end_date}
                      onChange={(date) => this.handleDateChange(date, "home_insurance_discount_end_date")}
                    />
                  </MuiPickersUtilsProvider>
                </Paper>
              }
              
            </Grid>

            {
              (
                this.state.availableInsurance.includes(INSURANCE_TYPES.health) ||
                this.state.availableInsurance.includes(INSURANCE_TYPES.life) ||
                this.state.availableInsurance.includes(INSURANCE_TYPES.travel)
              ) &&
              <Grid item xs={12} sm={6} md={3}>
                <Paper className="sq-shadow p-3">
                  <Typography variant="h6">Choose Plans</Typography>

                  {
                    this.state.availableInsurance.includes(INSURANCE_TYPES.health) &&
                    <>
                      <Typography className="mt-3 mb-2 px-3 py-2 bg-dark text-white rounded">
                        {INSURANCE_TYPES.health}
                      </Typography>
                      <List>
                        <ListItem
                          className="border rounded mb-3"
                          button
                          onClick={() => this.handleInsuranceTypeChange("availablePlans", PLAN_TYPES.individual_medical)}
                          dense
                        >
                          <ListItemIcon>
                            <Checkbox
                              checked={this.state.availablePlans.includes(PLAN_TYPES.individual_medical)}
                            />
                          </ListItemIcon>
                          <ListItemText primary={PLAN_TYPES.individual_medical} />
                        </ListItem>

                        <ListItem
                          className="border rounded mb-3"
                          button
                          dense
                          onClick={() => this.handleInsuranceTypeChange("availablePlans", PLAN_TYPES.affordable_health_plan)}
                        >
                          <ListItemIcon>
                            <Checkbox
                              checked={this.state.availablePlans.includes(PLAN_TYPES.affordable_health_plan)}
                            />
                          </ListItemIcon>
                          <ListItemText primary={PLAN_TYPES.affordable_health_plan} />
                        </ListItem>

                        <ListItem
                          className="border rounded mb-3"
                          button
                          onClick={() => this.handleInsuranceTypeChange("availablePlans", PLAN_TYPES.unified_domestic_worker)}
                          dense
                        >
                          <ListItemIcon>
                            <Checkbox
                              checked={this.state.availablePlans.includes(PLAN_TYPES.unified_domestic_worker)}
                            />
                          </ListItemIcon>
                          <ListItemText primary={PLAN_TYPES.unified_domestic_worker} />
                        </ListItem>

                        <ListItem
                          className="border rounded"
                          button
                          onClick={() => this.handleInsuranceTypeChange("availablePlans", PLAN_TYPES.unified_health_insurance_plan)}
                          dense
                        >
                          <ListItemIcon>
                            <Checkbox
                              checked={this.state.availablePlans.includes(PLAN_TYPES.unified_health_insurance_plan)}
                            />
                          </ListItemIcon>
                          <ListItemText primary={PLAN_TYPES.unified_health_insurance_plan} />
                        </ListItem>
                      </List>
                    </>
                  }
                  {
                    this.state.availableInsurance.includes(INSURANCE_TYPES.life) &&
                    <>
                      <Typography className="mt-3 mb-2 px-3 py-2 bg-dark text-white rounded">
                        {INSURANCE_TYPES.life}
                      </Typography>
                      <List>
                        <ListItem
                          className="border rounded mb-3"
                          button
                          onClick={() => this.handleInsuranceTypeChange("availablePlans", PLAN_TYPES.term_plan)}
                          dense
                        >
                          <ListItemIcon>
                            <Checkbox
                              checked={this.state.availablePlans.includes(PLAN_TYPES.term_plan)}
                            />
                          </ListItemIcon>
                          <ListItemText primary={PLAN_TYPES.term_plan} />
                        </ListItem>

                        <ListItem
                          className="border rounded mb-3"
                          button
                          dense
                          onClick={() => this.handleInsuranceTypeChange("availablePlans", PLAN_TYPES.personal_accident)}
                        >
                          <ListItemIcon>
                            <Checkbox
                              checked={this.state.availablePlans.includes(PLAN_TYPES.personal_accident)}
                            />
                          </ListItemIcon>
                          <ListItemText primary={PLAN_TYPES.personal_accident} />
                        </ListItem>
                      </List>
                    </>
                  }
                  {
                    this.state.availableInsurance.includes(INSURANCE_TYPES.travel) &&
                    <>
                      <Typography className="mt-3 mb-2 px-3 py-2 bg-dark text-white rounded">
                        {INSURANCE_TYPES.travel}
                      </Typography>
                      <List>
                      <ListItem
                          className="border rounded mb-3"
                          button
                          onClick={() => this.handleInsuranceTypeChange("availablePlans", PLAN_TYPES.inbound)}
                          dense
                        >
                          <ListItemIcon>
                            <Checkbox
                              checked={this.state.availablePlans.includes(PLAN_TYPES.inbound)}
                            />
                          </ListItemIcon>
                          <ListItemText primary={PLAN_TYPES.inbound} />
                        </ListItem>

                        <ListItem
                          className="border rounded mb-3"
                          button
                          onClick={() => this.handleInsuranceTypeChange("availablePlans", PLAN_TYPES.standard_travel)}
                          dense
                        >
                          <ListItemIcon>
                            <Checkbox
                              checked={this.state.availablePlans.includes(PLAN_TYPES.standard_travel)}
                            />
                          </ListItemIcon>
                          <ListItemText primary={PLAN_TYPES.standard_travel} />
                        </ListItem>

                        <ListItem
                          className="border rounded mb-3"
                          button
                          dense
                          onClick={() => this.handleInsuranceTypeChange("availablePlans", PLAN_TYPES.lite_travel)}
                        >
                          <ListItemIcon>
                            <Checkbox
                              checked={this.state.availablePlans.includes(PLAN_TYPES.lite_travel)}
                            />
                          </ListItemIcon>
                          <ListItemText primary={PLAN_TYPES.lite_travel} />
                        </ListItem>

                        <ListItem
                          className="border rounded mb-3"
                          button
                          dense
                          onClick={() => this.handleInsuranceTypeChange("availablePlans", PLAN_TYPES.covid_shield_travel)}
                        >
                          <ListItemIcon>
                            <Checkbox
                              checked={this.state.availablePlans.includes(PLAN_TYPES.covid_shield_travel)}
                            />
                          </ListItemIcon>
                          <ListItemText primary={PLAN_TYPES.covid_shield_travel} />
                        </ListItem>

                        <ListItem
                          className="border rounded mb-3"
                          button
                          dense
                          onClick={() => this.handleInsuranceTypeChange("availablePlans", PLAN_TYPES.senior_travel)}
                        >
                          <ListItemIcon>
                            <Checkbox
                              checked={this.state.availablePlans.includes(PLAN_TYPES.senior_travel)}
                            />
                          </ListItemIcon>
                          <ListItemText primary={PLAN_TYPES.senior_travel} />
                        </ListItem>

                        <ListItem
                          className="border rounded mb-3"
                          button
                          dense
                          onClick={() => this.handleInsuranceTypeChange("availablePlans", PLAN_TYPES.family_travel)}
                        >
                          <ListItemIcon>
                            <Checkbox
                              checked={this.state.availablePlans.includes(PLAN_TYPES.family_travel)}
                            />
                          </ListItemIcon>
                          <ListItemText primary={PLAN_TYPES.family_travel} />
                        </ListItem>
                      </List>
                    </>
                  }
                </Paper>
              </Grid>
            }

            {
              (
                this.state.availablePlans.length > 0 ||
                this.state.availableInsurance.includes(INSURANCE_TYPES.home) ||
                this.state.availableInsurance.includes(INSURANCE_TYPES.riyati)
              ) &&
              <Grid item xs={12} sm={6} md={3}>
                <Paper className="sq-shadow p-3">
                  <Typography variant="h6" >Commision (In %)</Typography>

                  {
                    (
                      this.state.availableInsurance.includes(INSURANCE_TYPES.health) &&
                      (
                        this.state.availablePlans.includes(PLAN_TYPES.individual_medical) ||
                        this.state.availablePlans.includes(PLAN_TYPES.affordable_health_plan) ||
                        this.state.availablePlans.includes(PLAN_TYPES.unified_domestic_worker) ||
                        this.state.availablePlans.includes(PLAN_TYPES.unified_health_insurance_plan)
                      )
                    ) &&
                    <>
                      <Typography className="mt-3 px-3 py-2 bg-dark text-white rounded">
                        {INSURANCE_TYPES.health}
                      </Typography>

                      <Grid container>
                        {
                          this.state.availablePlans.includes(PLAN_TYPES.individual_medical) &&
                          <>
                            <Grid item xs = {12}>
                              <Typography className="mt-3 px-3 py-2 border border-dark rounded"> {PLAN_TYPES.individual_medical} </Typography>
                            </Grid>
                            <Grid item xs = {12} md={6} className="pr-0 pr-md-1">
                              <TextField
                                type="number"
                                label={CUSTOMER_TYPE.CUSTOMER}
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                name="IMCommision"
                                onChange={this.handleChange}
                                value={this.state.IMCommision}
                              />
                            </Grid>
                            <Grid item xs = {12} md={6} className="pl-0 pl-md-1">
                              <TextField
                                type="number"
                                label={CUSTOMER_TYPE.STAFF}
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                name="IMCommision_staff"
                                onChange={this.handleChange}
                                value={this.state.IMCommision_staff}
                              />
                            </Grid>
                          </>
                        }
                        {
                          this.state.availablePlans.includes(PLAN_TYPES.affordable_health_plan) &&
                          <>
                            <Grid item xs = {12}>
                              <Typography className="mt-2 px-3 py-2 border border-dark rounded"> {PLAN_TYPES.affordable_health_plan} </Typography>
                            </Grid>
                            <Grid item xs = {12} md={6} className="pr-0 pr-md-1">
                              <TextField
                                type="number"
                                label={CUSTOMER_TYPE.CUSTOMER}
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                name="AHPCommision"
                                onChange={this.handleChange}
                                value={this.state.AHPCommision}
                              />
                            </Grid>
                            <Grid item xs = {12} md={6} className="pl-0 pl-md-1">
                              <TextField
                                type="number"
                                label={CUSTOMER_TYPE.STAFF}
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                name="AHPCommision_staff"
                                onChange={this.handleChange}
                                value={this.state.AHPCommision_staff}
                              />
                            </Grid>
                          </>
                        }
                        {
                          this.state.availablePlans.includes(PLAN_TYPES.unified_domestic_worker) &&
                          <>
                            <Grid item xs = {12}>
                              <Typography className="mt-2 px-3 py-2 border border-dark rounded"> {PLAN_TYPES.unified_domestic_worker} </Typography>
                            </Grid>
                            <Grid item xs = {12} md={6} className="pr-0 pr-md-1">
                              <TextField
                                type="number"
                                label={CUSTOMER_TYPE.CUSTOMER}
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                name="UDWCommision"
                                onChange={this.handleChange}
                                value={this.state.UDWCommision}
                              />
                            </Grid>
                            <Grid item xs = {12} md={6} className="pl-0 pl-md-1">
                              <TextField
                                type="number"
                                label={CUSTOMER_TYPE.STAFF}
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                name="UDWCommision_staff"
                                onChange={this.handleChange}
                                value={this.state.UDWCommision_staff}
                              />
                            </Grid>
                          </>
                        }

                        {
                          this.state.availablePlans.includes(PLAN_TYPES.unified_health_insurance_plan) &&
                          <>
                            <Grid item xs = {12}>
                              <Typography className="mt-2 px-3 py-2 border border-dark rounded"> {PLAN_TYPES.unified_health_insurance_plan} </Typography>
                            </Grid>
                            <Grid item xs = {12} md={6} className="pr-0 pr-md-1">
                              <TextField
                                type="number"
                                label={CUSTOMER_TYPE.CUSTOMER}
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                name="UHIPCommision"
                                onChange={this.handleChange}
                                value={this.state.UHIPCommision}
                              />
                            </Grid>
                            <Grid item xs = {12} md={6} className="pl-0 pl-md-1">
                              <TextField
                                type="number"
                                label={CUSTOMER_TYPE.STAFF}
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                name="UHIPCommision_staff"
                                onChange={this.handleChange}
                                value={this.state.UHIPCommision_staff}
                              />
                            </Grid>
                          </>
                        }
                      </Grid>
                    </>
                  }

                  {
                    (
                      this.state.availableInsurance.includes(INSURANCE_TYPES.life) &&
                      (
                        this.state.availablePlans.includes(PLAN_TYPES.term_plan) ||
                        this.state.availablePlans.includes(PLAN_TYPES.personal_accident)
                      )
                    ) &&
                    <>
                      <Typography className="mt-3 px-3 py-2 bg-dark text-white rounded">
                        {INSURANCE_TYPES.life}
                      </Typography>
                      <Grid container>
                        {
                          this.state.availablePlans.includes(PLAN_TYPES.term_plan) &&
                          <>
                            <Grid item xs = {12}>
                              <Typography className="mt-2 px-3 py-2 border border-dark rounded"> {PLAN_TYPES.term_plan} </Typography>
                            </Grid>
                            <Grid item xs = {12} md={6} className="pr-0 pr-md-1">
                              <TextField
                                type="number"
                                label={CUSTOMER_TYPE.CUSTOMER}
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                name="term_plan_commission"
                                onChange={this.handleChange}
                                value={this.state.term_plan_commission}
                              />
                            </Grid>
                            <Grid item xs = {12} md={6} className="pl-0 pl-md-1">
                              <TextField
                                type="number"
                                label={CUSTOMER_TYPE.STAFF}
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                name="term_plan_commission_staff"
                                onChange={this.handleChange}
                                value={this.state.term_plan_commission_staff}
                              />
                            </Grid>
                          </>
                        }
                        {
                          this.state.availablePlans.includes(PLAN_TYPES.personal_accident) &&
                          <>
                            <Grid item xs = {12}>
                              <Typography className="mt-2 px-3 py-2 border border-dark rounded"> {PLAN_TYPES.personal_accident} </Typography>
                            </Grid>
                            <Grid item xs = {12} md={6} className="pr-0 pr-md-1">
                              <TextField
                                type="number"
                                label={CUSTOMER_TYPE.CUSTOMER}
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                name="pa_commission"
                                onChange={this.handleChange}
                                value={this.state.pa_commission}
                              />
                            </Grid>
                            <Grid item xs = {12} md={6} className="pl-0 pl-md-1">
                              <TextField
                                type="number"
                                label={CUSTOMER_TYPE.STAFF}
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                name="pa_commission_staff"
                                onChange={this.handleChange}
                                value={this.state.pa_commission_staff}
                              />
                            </Grid>
                          </>
                        }
                      </Grid>
                    </>
                  }

                  {
                    (
                      this.state.availableInsurance.includes(INSURANCE_TYPES.travel) &&
                      (
                        this.state.availablePlans.includes(PLAN_TYPES.standard_travel) ||
                        this.state.availablePlans.includes(PLAN_TYPES.lite_travel) ||
                        this.state.availablePlans.includes(PLAN_TYPES.covid_shield_travel) ||
                        this.state.availablePlans.includes(PLAN_TYPES.senior_travel) ||
                        this.state.availablePlans.includes(PLAN_TYPES.family_travel) ||
                        this.state.availablePlans.includes(PLAN_TYPES.inbound)
                      )
                    ) &&
                    <>
                      <Typography className="mt-3 px-3 py-2 bg-dark text-white rounded">
                        {INSURANCE_TYPES.travel}
                      </Typography>
                      <Grid container>
                        {
                          this.state.availablePlans.includes(PLAN_TYPES.inbound) &&
                          <>
                            <Grid item xs = {12}>
                              <Typography className="mt-2 px-3 py-2 border border-dark rounded"> {PLAN_TYPES.inbound} </Typography>
                            </Grid>
                            <Grid item xs = {12} md={6} className="pr-0 pr-md-1">
                              <TextField
                                type="number"
                                label={CUSTOMER_TYPE.CUSTOMER}
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                name="inbound_travel_commission"
                                onChange={this.handleChange}
                                value={this.state.inbound_travel_commission}
                              />
                            </Grid>
                            <Grid item xs = {12} md={6} className="pl-0 pl-md-1">
                              <TextField
                                type="number"
                                label={CUSTOMER_TYPE.STAFF}
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                name="inbound_travel_commission_staff"
                                onChange={this.handleChange}
                                value={this.state.inbound_travel_commission_staff}
                              />
                            </Grid>
                          </>
                        }
                        {
                          this.state.availablePlans.includes(PLAN_TYPES.standard_travel) &&
                          <>
                            <Grid item xs = {12}>
                              <Typography className="mt-2 px-3 py-2 border border-dark rounded"> {PLAN_TYPES.standard_travel} </Typography>
                            </Grid>
                            <Grid item xs = {12} md={6} className="pr-0 pr-md-1">
                              <TextField
                                type="number"
                                label={CUSTOMER_TYPE.CUSTOMER}
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                name="standard_travel_commission"
                                onChange={this.handleChange}
                                value={this.state.standard_travel_commission}
                              />
                            </Grid>
                            <Grid item xs = {12} md={6} className="pl-0 pl-md-1">
                              <TextField
                                type="number"
                                label={CUSTOMER_TYPE.STAFF}
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                name="standard_travel_commission_staff"
                                onChange={this.handleChange}
                                value={this.state.standard_travel_commission_staff}
                              />
                            </Grid>
                          </>
                        }
                        {
                          this.state.availablePlans.includes(PLAN_TYPES.lite_travel) &&
                          <>
                            <Grid item xs = {12}>
                              <Typography className="mt-2 px-3 py-2 border border-dark rounded"> {PLAN_TYPES.lite_travel} </Typography>
                            </Grid>
                            <Grid item xs = {12} md={6} className="pr-0 pr-md-1">
                              <TextField
                                type="number"
                                label={CUSTOMER_TYPE.CUSTOMER}
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                name="lite_travel_commission"
                                onChange={this.handleChange}
                                value={this.state.lite_travel_commission}
                              />
                            </Grid>
                            <Grid item xs = {12} md={6} className="pl-0 pl-md-1">
                              <TextField
                                type="number"
                                label={CUSTOMER_TYPE.STAFF}
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                name="lite_travel_commission_staff"
                                onChange={this.handleChange}
                                value={this.state.lite_travel_commission_staff}
                              />
                            </Grid>
                          </>
                        }
                        {
                          this.state.availablePlans.includes(PLAN_TYPES.covid_shield_travel) &&
                          <>
                            <Grid item xs = {12}>
                              <Typography className="mt-2 px-3 py-2 border border-dark rounded"> {PLAN_TYPES.covid_shield_travel} </Typography>
                            </Grid>
                            <Grid item xs = {12} md={6} className="pr-0 pr-md-1">
                              <TextField
                                type="number"
                                label={CUSTOMER_TYPE.CUSTOMER}
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                name="covid_shield_travel_commission"
                                onChange={this.handleChange}
                                value={this.state.covid_shield_travel_commission}
                              />
                            </Grid>
                            <Grid item xs = {12} md={6} className="pl-0 pl-md-1">
                              <TextField
                                type="number"
                                label={CUSTOMER_TYPE.STAFF}
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                name="covid_shield_travel_commission_staff"
                                onChange={this.handleChange}
                                value={this.state.covid_shield_travel_commission_staff}
                              />
                            </Grid>
                          </>
                        }
                        {
                          this.state.availablePlans.includes(PLAN_TYPES.senior_travel) &&
                          <>
                            <Grid item xs = {12}>
                              <Typography className="mt-2 px-3 py-2 border border-dark rounded"> {PLAN_TYPES.senior_travel} </Typography>
                            </Grid>
                            <Grid item xs = {12} md={6} className="pr-0 pr-md-1">
                              <TextField
                                type="number"
                                label={CUSTOMER_TYPE.CUSTOMER}
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                name="senior_travel_commission"
                                onChange={this.handleChange}
                                value={this.state.senior_travel_commission}
                              />
                            </Grid>
                            <Grid item xs = {12} md={6} className="pl-0 pl-md-1">
                              <TextField
                                type="number"
                                label={CUSTOMER_TYPE.STAFF}
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                name="senior_travel_commission_staff"
                                onChange={this.handleChange}
                                value={this.state.senior_travel_commission_staff}
                              />
                            </Grid>
                          </>
                        }

                        {
                          this.state.availablePlans.includes(PLAN_TYPES.family_travel) &&
                          <>
                            <Grid item xs = {12}>
                              <Typography className="mt-2 px-3 py-2 border border-dark rounded"> {PLAN_TYPES.family_travel} </Typography>
                            </Grid>
                            <Grid item xs = {12} md={6} className="pr-0 pr-md-1">
                              <TextField
                                type="number"
                                label={CUSTOMER_TYPE.CUSTOMER}
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                name="family_travel_commission"
                                onChange={this.handleChange}
                                value={this.state.family_travel_commission}
                              />
                            </Grid>
                            <Grid item xs = {12} md={6} className="pl-0 pl-md-1">
                              <TextField
                                type="number"
                                label={CUSTOMER_TYPE.STAFF}
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                name="family_travel_commission_staff"
                                onChange={this.handleChange}
                                value={this.state.family_travel_commission_staff}
                              />
                            </Grid>
                          </>
                        }
                      </Grid>
                    </>
                  }

                  {
                    this.state.availableInsurance.includes(INSURANCE_TYPES.home) &&
                    <>
                      <Typography className="mt-3 px-3 py-2 bg-dark text-white rounded">
                        {INSURANCE_TYPES.home}
                      </Typography>
                      <Grid container>
                        <Grid item xs = {12} md={6} className="pr-0 pr-md-1">
                          <TextField
                            type="number"
                            label={CUSTOMER_TYPE.CUSTOMER}
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            name="home_commission"
                            onChange={this.handleChange}
                            value={this.state.home_commission}
                          />
                        </Grid>
                        <Grid item xs = {12} md={6} className="pl-0 pl-md-1">
                          <TextField
                            type="number"
                            label={CUSTOMER_TYPE.STAFF}
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            name="home_commission_staff"
                            onChange={this.handleChange}
                            value={this.state.home_commission_staff}
                          />
                        </Grid>
                      </Grid>
                    </>
                  }

                  {
                    this.state.availableInsurance.includes(INSURANCE_TYPES.riyati) &&
                    <>
                      <Typography className="mt-3 px-3 py-2 bg-dark text-white rounded">
                        {INSURANCE_TYPES.riyati}
                      </Typography>

                      <Grid container>
                        <Grid item xs = {12} md={6} className="pr-0 pr-md-1">
                          <TextField
                            type="number"
                            label={CUSTOMER_TYPE.CUSTOMER}
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            name="riyati_commission"
                            onChange={this.handleChange}
                            value={this.state.riyati_commission}
                          />
                        </Grid>
                        <Grid item xs = {12} md={6} className="pl-0 pl-md-1">
                          <TextField
                            type="number"
                            label={CUSTOMER_TYPE.STAFF}
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            name="riyati_commission_staff"
                            onChange={this.handleChange}
                            value={this.state.riyati_commission_staff}
                          />
                        </Grid>
                      </Grid>
                    </>
                  }
                </Paper>
              </Grid>
            }


            <Grid item xs={12}>
              <Paper className="sq-shadow p-2 d-flex justify-content-end">
                <Link to="/affiliator" className="d-inline-block mr-3">
                  <Button variant="outlined" color="primary">Cancel</Button>
                </Link>

                <Button
                  variant="contained"
                  color="primary"
                  disabled={
                    !this.isValid() || this.state.isLoading
                  }
                  onClick={this.saveAffiliate}
                >
                  {this.state.isLoading ? "Saving . . ." : "Save"}
                </Button>
              </Paper>
            </Grid>

          </Grid>
        </div>

      </div>
    )
  }
}

export default withRouter(CreateAffiliator);