import React from "react";
import Navigation from "../common/Navigation";
import Snackbar from "../common/Snackbar";
import PolicyService from "../../services/policy";
import { Cancel } from "@material-ui/icons";
import { Paper, Typography, Button, TextField, Grid, MenuItem, FormControlLabel, Checkbox } from "@material-ui/core";
import { Link, withRouter } from "react-router-dom";
import Countries from "../../services/country-data.json";
import OccupationList from "../../services/occupation-list.json"
import Select from "react-select";

class EditPolicy extends React.Component {

  state = {
    showSnackbar: false,
    snackBarMessage: "",
    messageType: "",
    beneficiaries: [],
    civil_id: "",
    personalInformation: {
      dob: "",
      email: "",
      fullName: "",
      gender: "",
      height: "",
      isMarried: "",
      mobileNumber: "",
      occupation: "",
      resident_location: "",
      vat_in: "",
      weight: "",
      occupation_code : "",
      sponsorName : "",
      sponsor_id : "",
      passport : ""
    },
    isSavingPersonalInfo: false,
    isSavingBuildingInfo: false,
    isSavingBeneficiaries: false,
    payments: {
      isPaid: false,
      mode: ""
    },
    homeOwnerType: "",
    buildingInformation: {
      address: "",
      age_of_building: "",
      business_of_insured: "",
      floor_no: "",
      have_any_special_conditions_imposed: "",
      have_burglar_alarm_system: "",
      have_fire_alarm_system: "",
      have_grilled_doors: "",
      have_hydrant_system: "",
      have_insurance_cancelled_by_other_company: "",
      have_insurance_declined_before: "",
      have_photographs_of_property_attached: "",
      have_portable_extinguishers: "",
      have_security_checkpoint: "",
      have_surveillance_camera: "",
      have_watchman_service: "",
      is_property_in_basement: "",
      is_whole_building_residential: "",
      not_residential_reason: "",
      other_fire_protection: "",
      other_security_system: "",
      previous_insurance_details: "",
      type_of_building: ""
    },
    nationality: "",
    nationalityCode: ""
  }

  componentDidMount() {
    this.getPolicy();
  }

  getPolicy = async () => {
    const { id } = this.props.match.params;
    const data = await PolicyService.getPolicyById(id);
    const policy = data.data;
    const {
      personalInformation,
      homeOwnerType,
      buildingInformation,
      civil_id,
      beneficiaries,
      has_li_addon,
      insurance_type,
      payments,
      nationality,
      nationalityCode,
      applicationType
    } = policy;

    this.setState({
      personalInformation,
      homeOwnerType,
      buildingInformation,
      civil_id,
      beneficiaries,
      has_li_addon,
      insurance_type,
      id,
      payments: payments || {},
      nationality, 
      nationalityCode,
      applicationType
    });
  }

  handleCloseSnackbar = () => {
    this.setState({ showSnackbar: false })
  }

  handlePersonalInfoChange = (e) => {
    const personalInformation = { ...this.state.personalInformation }
    personalInformation[e.target.name] = e.target.value;
    this.setState({ personalInformation });
  }

  handleOccupationChange = (occupation) => {
    const personalInformation = { ...this.state.personalInformation }
    personalInformation.occupation = occupation.ENGLISH;
    personalInformation.occupation_code = occupation.CODE;
    this.setState({ personalInformation });
  }

  handleChangeCivilId = (e) => {
    this.setState({ civil_id: e.target.value })
  }

  isValidMail = (value) => {
    const atpos = value.indexOf("@");
    const dotpos = value.lastIndexOf(".");
    if (atpos < 1 || dotpos < atpos + 2 || dotpos + 2 >= value.length || value === "") {
      return false;
    }
    else {
      return true;
    }
  }

  isValidPersonalInfo = () => {
    const personalInformation = this.state.personalInformation;
    const civil_id = this.state.civil_id;
    const applicationType = this.state.applicationType;
    const {
      fullName,
      email,
      mobileNumber,
      height,
      weight,
      dob,
      gender,
      isMarried,
      resident_location,
      occupation_code
    } = personalInformation;
    if (
      fullName === "" ||
      !this.isValidMail(email) ||
      mobileNumber === "" ||
      mobileNumber.length !== 8 ||
      (
        !["home", "travel"].includes(this.state.insurance_type) &&
        (height === "" ||
          weight === "" ||
          dob === "" ||
          gender === "")
      ) ||

      (
        !(["home", "travel"].includes(this.state.insurance_type) || applicationType === "domestic") &&
        (
          isMarried === ""
        )
      ) ||
      civil_id === "" ||
      resident_location === "" ||
      !Boolean(occupation_code)
    ) {
      return false;
    }
    else {
      return true;
    }
  }

  isValidBuildingInfo = () => {
    const buildingInformation = this.state.buildingInformation;

    if (this.state.homeOwnerType === "owner") {
      return !(
        buildingInformation.business_of_insured === ""
        || buildingInformation.age_of_building === ""
        || buildingInformation.type_of_building === ""
        || buildingInformation.floor_no === ""
        || buildingInformation.is_property_in_basement === ""
        || buildingInformation.is_whole_building_residential === ""
        || (buildingInformation.is_whole_building_residential === "No" && buildingInformation.not_residential_reason === "")
        || buildingInformation.address === ""
        || buildingInformation.have_fire_alarm_system === ""
        || buildingInformation.have_portable_extinguishers === ""
        || buildingInformation.have_hydrant_system === ""
        || buildingInformation.have_surveillance_camera === ""
        || buildingInformation.have_burglar_alarm_system === ""
        || buildingInformation.have_grilled_doors === ""
        || buildingInformation.have_watchman_service === ""
        || buildingInformation.have_security_checkpoint === ""
        || buildingInformation.previous_insurance_details === ""
      )
    } else {
      return !(
        buildingInformation.business_of_insured === ""
        || buildingInformation.type_of_building === ""
        || buildingInformation.floor_no === ""
      )
    }
  }

  handleSavePersonalInfo = () => {
    this.setState({ isSavingPersonalInfo: true });
    const data = {
      civil_id: this.state.civil_id,
      personalInformation: this.state.personalInformation
    };
    const id = this.state.id;
    PolicyService.update(data, id).then((res) => {
      this.setState({ showSnackbar: true, snackBarMessage: "Personal information updated successfully", messageType: "success", isSavingPersonalInfo: false });
      this.getPolicy();
    }).catch((e) => {
      this.setState({ showSnackbar: true, snackBarMessage: "Personal information not updated", messageType: "danger" })
    })
  }

  handleSaveBuildingInfo = () => { 
    this.setState({ isSavingBuildingInfo: true });
    const data = {
      buildingInformation: this.state.buildingInformation
    };
    const id = this.state.id;
    PolicyService.update(data, id).then((res) => {
      this.setState({ showSnackbar: true, snackBarMessage: "Building information updated successfully", messageType: "success", isSavingBuildingInfo: false });
      this.getPolicy();
    }).catch((e) => {
      this.setState({ showSnackbar: true, snackBarMessage: "Building information not updated", messageType: "danger" })
    })
  }

  handleSaveBeneficiaries = () => {
    this.setState({ isSavingBeneficiaries: true });
    const data = {
      beneficiaries: this.state.beneficiaries
    };
    const id = this.state.id;
    PolicyService.update(data, id).then((res) => {
      this.setState({ showSnackbar: true, snackBarMessage: "Beneficiaries updated successfully", messageType: "success", isSavingBeneficiaries: false });
      this.getPolicy();
    }).catch((e) => {
      this.setState({ showSnackbar: true, snackBarMessage: "Beneficiaries not updated", messageType: "danger", isSavingBeneficiaries: false })
    })
  }

  addNewBeneficiary = () => {
    const beneficiaries = [...this.state.beneficiaries];
    beneficiaries.push({ name: "", relation: "", benefit_percentage: "" });
    this.setState({ beneficiaries });
  }

  removeBeneficiary = (ind) => {
    const beneficiaries = [...this.state.beneficiaries];
    beneficiaries.splice(ind, 1);
    this.setState({ beneficiaries });
  }

  handleBeneficiaryChange = (ind, title, value) => {
    const beneficiaries = [...this.state.beneficiaries];
    const selectedBeneficiary = { ...beneficiaries[ind] };
    selectedBeneficiary[title] = value;
    beneficiaries[ind] = selectedBeneficiary;
    this.setState({ beneficiaries });
  }

  isValidBeneficiary = () => {
    let blankObj = 0;
    const beneficiaries = [...this.state.beneficiaries];
    let totalPercentage = 0;
    beneficiaries.forEach((beneficiary) => {
      if (beneficiaries.length > 1) {
        const benefitPercentage = parseFloat(beneficiary.benefit_percentage);
        totalPercentage += benefitPercentage;
      }
      if (beneficiary.name === "" || beneficiary.relation === "" || (beneficiaries.length > 1 && beneficiaries.benefit_percentage === "")) {
        blankObj++;
      }
    });
    return blankObj === 0 && (totalPercentage === 0 || totalPercentage === 100);
  }

  handleSavePaymentDetails = () => {
    this.setState({ isSavingPaymentDetails: true });
    const data = {
      email : this.state.personalInformation.email,
      data : {
        payments: this.state.payments
      }
    };
    const id = this.state.id;
    PolicyService.updatePaymentDetails(id, data).then((res) => {
      this.setState({ showSnackbar: true, snackBarMessage: "Payment details updated successfully", messageType: "success", isSavingPaymentDetails: false });
      this.getPolicy();
    }).catch((e) => {
      this.setState({ showSnackbar: true, snackBarMessage: "Payment details not updated", messageType: "danger", isSavingPaymentDetails: false })
    })
  }

  handleIsPaidChange = (e) => {
    const payments = { ...this.state.payments }
    payments.isPaid = e.target.checked;
    this.setState({ payments });
  }

  handlePaymentDetailChange = (e) => {
    const payments = { ...this.state.payments }
    payments[e.target.name] = e.target.value;
    this.setState({ payments });
  }

  handleBuildingDetailChange = (e) => {
    const buildingInformation = { ...this.state.buildingInformation }
    buildingInformation[e.target.name] = e.target.value;
    this.setState({ buildingInformation });
  }

  handleNationalityChange = (e) => {
    const nationality = e.target.value;
    this.setState({ nationality });
  }

  handleSaveNationality = () => {
    this.setState({ isSavingNationality: true });
    const data = {
      nationality: this.state.nationality,
      nationalityCode: this.state.nationalityCode
    };
    const id = this.state.id;
    PolicyService.update(data, id).then((res) => {
      this.setState({ showSnackbar: true, snackBarMessage: "Nationality updated successfully", messageType: "success", isSavingNationality: false });
      this.getPolicy();
    }).catch((e) => {
      this.setState({ showSnackbar: true, snackBarMessage: "Nationality not updated", messageType: "danger", isSavingNationality: false })
    })
  }

  getDecisionContent = (field, label = "") => {
    return (<TextField
      variant="outlined"
      select
      fullWidth
      className="mt-3"
      label={label}
      value={this.state.buildingInformation[field]}
      name={field}
      onChange={this.handleBuildingDetailChange}
    >
      <MenuItem value=""> Select {label} </MenuItem>
      <MenuItem value="Yes"> Yes </MenuItem>
      <MenuItem value="No"> No </MenuItem>
    </TextField>)
  }

  render() {
    return (
      <div className="d-flex">
        <Navigation />

        <Snackbar
          showSnackbar={this.state.showSnackbar}
          messageType={this.state.messageType}
          snackBarMessage={this.state.snackBarMessage}
          onCloseSnackbar={this.handleCloseSnackbar}
        />
        <div className="container-fluid">
          <Paper className="p-2 sq-shadow mb-3 d-flex">
            <Typography><Link to={`/home`}>Home</Link></Typography>
            <Typography className="mr-2 ml-2">/</Typography>
            <Typography><Link to={`/policies`}>Policies</Link></Typography>
            <Typography className="mr-2 ml-2">/</Typography>
            <Typography>Edit Policy</Typography>
          </Paper>

          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4}>
              <Paper className="sq-shadow px-2 py-3">
                <Typography variant="h5" className="pb-2 border-bottom"> Personal Information </Typography>

                {
                  this.state.applicationType === "domestic" &&
                  <>
                    <TextField
                      variant="outlined"
                      fullWidth
                      className="mt-3"
                      label="Sponsor Name"
                      value={this.state.personalInformation.sponsorName}
                      name="sponsorName"
                      onChange={this.handlePersonalInfoChange}
                    />

                    <TextField
                      variant="outlined"
                      fullWidth
                      className="mt-3"
                      label="Sponsor ID"
                      value={this.state.personalInformation.sponsor_id}
                      name="sponsor_id"
                      onChange={this.handlePersonalInfoChange}
                    />
                  </>
                }

                <TextField
                  variant="outlined"
                  fullWidth
                  className="mt-3"
                  label="Full Name"
                  value={this.state.personalInformation.fullName}
                  name="fullName"
                  onChange={this.handlePersonalInfoChange}
                />

                <TextField
                  variant="outlined"
                  fullWidth
                  className="mt-3"
                  label="Email"
                  value={this.state.personalInformation.email}
                  name="email"
                  onChange={this.handlePersonalInfoChange}
                />

                <TextField
                  variant="outlined"
                  fullWidth
                  className="mt-3"
                  label="Mobile Number"
                  value={this.state.personalInformation.mobileNumber}
                  name="mobileNumber"
                  onChange={this.handlePersonalInfoChange}
                />

                <TextField
                  variant="outlined"
                  fullWidth
                  className="mt-3"
                  label="Civil Id"
                  value={this.state.civil_id}
                  name="civil_id"
                  onChange={this.handleChangeCivilId}
                />

                <TextField
                  variant="outlined"
                  fullWidth
                  className="mt-3"
                  label="Passport Number"
                  value={this.state.personalInformation.passport}
                  name="passport"
                  onChange={this.handlePersonalInfoChange}
                />

                <div className="mt-2">
                  <label className="text-secondary pl-2"><small>Occupation</small></label>
                  <Select
                    className="select-with-filter"
                    classNamePrefix="select"
                    name="center"
                    menuPortalTarget={document.body}
                    menuPosition={'fixed'}
                    styles={{
                      menuPortal: provided => ({ ...provided, zIndex: 9999 }),
                      menu: provided => ({ ...provided, zIndex: 9999 })
                    }}
                    value={OccupationList.find(occupation => occupation.CODE === this.state.personalInformation.occupation_code)}
                    options={OccupationList}
                    onChange={this.handleOccupationChange}
                    getOptionLabel = {(occupation) => occupation.ENGLISH}
                    getOptionValue = {(occupation) => occupation.CODE}
                  />
                </div>

                {
                  !["home", "travel"].includes(this.state.insurance_type) &&
                  <React.Fragment>
                    <TextField
                      variant="outlined"
                      fullWidth
                      className="mt-3"
                      label="Height"
                      value={this.state.personalInformation.height}
                      name="height"
                      onChange={this.handlePersonalInfoChange}
                    />

                    <TextField
                      variant="outlined"
                      fullWidth
                      className="mt-3"
                      label="Weight"
                      value={this.state.personalInformation.weight}
                      name="weight"
                      onChange={this.handlePersonalInfoChange}
                    />
                    <TextField
                      variant="outlined"
                      fullWidth
                      className="mt-3"
                      label="Date Of Birth"
                      value={this.state.personalInformation.dob}
                      name="dob"
                      onChange={this.handlePersonalInfoChange}
                    />

                    <TextField
                      variant="outlined"
                      select
                      fullWidth
                      className="mt-3"
                      label="Gender"
                      value={this.state.personalInformation.gender}
                      name="gender"
                      onChange={this.handlePersonalInfoChange}
                    >
                      <MenuItem value=""> Select Gender </MenuItem>
                      <MenuItem value="male"> Male </MenuItem>
                      <MenuItem value="female"> Female </MenuItem>
                    </TextField>

                    <TextField
                      variant="outlined"
                      select
                      fullWidth
                      className="mt-3"
                      label="Is Married"
                      value={this.state.personalInformation.isMarried}
                      name="isMarried"
                      onChange={this.handlePersonalInfoChange}
                    >
                      <MenuItem value=""> Select Marital Status </MenuItem>
                      <MenuItem value="yes"> Yes </MenuItem>
                      <MenuItem value="no"> No </MenuItem>
                    </TextField>
                  </React.Fragment>
                }

                <TextField
                  variant="outlined"
                  fullWidth
                  className="mt-3"
                  label="Residential Location"
                  value={this.state.personalInformation.resident_location}
                  name="resident_location"
                  onChange={this.handlePersonalInfoChange}
                />

                <TextField
                  variant="outlined"
                  fullWidth
                  className="mt-3"
                  label="VATIN (optional)"
                  value={this.state.personalInformation.vat_in}
                  name="vat_in"
                  onChange={this.handlePersonalInfoChange}
                />



                <div className="mt-3 d-flex justify-content-end">
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={this.state.isSavingPersonalInfo || !this.isValidPersonalInfo()}
                    onClick={this.handleSavePersonalInfo}
                  >
                    {this.state.isSavingPersonalInfo ? "Saving . . ." : "Save"}
                  </Button>
                </div>
              </Paper>
            </Grid>

            {
              (this.state.beneficiaries && this.state.beneficiaries.length > 0) &&
              <Grid item xs={12} sm={6} md={4}>
                <Paper className="sq-shadow px-2 py-3">
                  <Typography variant="h5" className="pb-2 border-bottom"> Beneficiaries </Typography>

                  {
                    this.state.beneficiaries.map((beneficiary, ind) =>
                      <div className="border rounded p-2 mt-3" key={ind}>
                        <Typography variant="body1" className="pb-2 border-bottom d-flex justify-content-between">
                          Beneficiary {ind + 1}

                          {
                            this.state.beneficiaries.length > 1 &&
                            <Cancel className="beneficiary-close-icon" onClick={() => this.removeBeneficiary(ind)} />
                          }
                        </Typography>

                        <TextField
                          variant="outlined"
                          fullWidth
                          label="Name"
                          className="mt-2"
                          value={beneficiary.name}
                          onChange={(e) => this.handleBeneficiaryChange(ind, "name", e.target.value)}
                        />

                        {
                          this.state.beneficiaries.length > 1 &&
                          <TextField
                            variant="outlined"
                            fullWidth
                            label="Benefit Percentage"
                            className="mt-2"
                            value={beneficiary.benefit_percentage}
                            onChange={(e) => this.handleBeneficiaryChange(ind, "benefit_percentage", e.target.value)}
                          />
                        }

                        <TextField
                          select
                          fullWidth
                          label="Relation"
                          variant="outlined"
                          className="mt-2"
                          value={beneficiary.relation}
                          onChange={(e) => this.handleBeneficiaryChange(ind, "relation", e.target.value)}
                        >
                          <MenuItem value="">Select Relation</MenuItem>
                          <MenuItem value="Spouse">Spouse</MenuItem>
                          <MenuItem value="Grand Parent">Grand Parent</MenuItem>
                          <MenuItem value="Son">Son</MenuItem>
                          <MenuItem value="Daughter">Daughter</MenuItem>
                          <MenuItem value="Father">Father</MenuItem>
                          <MenuItem value="Mother">Mother</MenuItem>
                          <MenuItem value="Brother">Brother</MenuItem>
                          <MenuItem value="Sister">Sister</MenuItem>
                          <MenuItem value="Embassy">Embassy</MenuItem>
                          <MenuItem value="As Per Sharia">As Per Sharia</MenuItem>
                        </TextField>
                      </div>
                    )
                  }

                  <div className="d-flex justify-content-between mt-3">
                    <Button variant="contained" className="bg-info text-white" onClick={this.addNewBeneficiary}>
                      Add Beneficiary
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={this.handleSaveBeneficiaries}
                      disabled={this.state.isSavingBeneficiaries || !this.isValidBeneficiary()}
                    >
                      {this.state.isSavingBeneficiaries ? "Saving . . ." : "Save"}
                    </Button>
                  </div>
                </Paper>
              </Grid>
            }

            <Grid item xs={12} sm={6} md={4}>
              <Paper className="sq-shadow px-2 py-3">
                <Typography variant="h5" className="pb-2 border-bottom"> Payment Details </Typography>

                <FormControlLabel
                  className="mb-0 mx-0 mt-2 d-flex align-items-center border"
                  control={
                    <Checkbox
                      checked={this.state.payments.isPaid}
                      onChange={this.handleIsPaidChange}
                    />
                  }
                  label="Is Paid"
                />

                <TextField
                  variant="outlined"
                  select
                  fullWidth
                  className="mt-3"
                  label="Payment Mode"
                  value={this.state.payments.mode}
                  name="mode"
                  onChange={this.handlePaymentDetailChange}
                >
                  <MenuItem value=""> Select Payment Mode </MenuItem>
                  <MenuItem value="Cash"> Cash </MenuItem>
                  <MenuItem value="Card"> Card </MenuItem>
                </TextField>

                {
                  this.state.payments.mode === "Card" &&
                  <TextField
                    variant="outlined"
                    fullWidth
                    className="mt-3"
                    label="Payment Id"
                    value={this.state.payments.payment_id}
                    name="payment_id"
                    onChange={this.handlePaymentDetailChange}
                  />
                }

                <div className="mt-3 d-flex justify-content-end">
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={this.state.isSavingPaymentDetails}
                    onClick={this.handleSavePaymentDetails}
                  >
                    {this.state.isSavingPaymentDetails ? "Saving . . ." : "Save"}
                  </Button>
                </div>
              </Paper>

              <Paper className="sq-shadow px-2 py-3 mt-3">
                <Typography variant="h5" className="pb-2 border-bottom"> Nationality </Typography>

                <TextField
                  variant="outlined"
                  select
                  fullWidth
                  className="mt-3"
                  value={this.state.nationality}
                  name="mode"
                  onChange={this.handleNationalityChange}
                >
                  {
                    Countries.map((country, ind) =>
                      <MenuItem key={ind} value={country}> {country} </MenuItem>
                    )
                  }
                </TextField>

                <div className="mt-3 d-flex justify-content-end">
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={this.state.isSavingNationality}
                    onClick={this.handleSaveNationality}
                  >
                    {this.state.isSavingNationality ? "Saving . . ." : "Save"}
                  </Button>
                </div>
              </Paper>
            </Grid>




            {
              this.state.insurance_type == "home" &&
              <Grid item xs={12} sm={12} md={12}>

                <Paper className="sq-shadow px-2 py-3">
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={4}>
                      <Typography variant="h5" className="pb-2 border-bottom"> Building Details </Typography>

                      <TextField
                        variant="outlined"
                        fullWidth
                        className="mt-3"
                        label="Business or Occupation of the Insured"
                        value={this.state.buildingInformation.business_of_insured}
                        name="business_of_insured"
                        onChange={this.handleBuildingDetailChange}
                      />

                      {
                        this.state.homeOwnerType === "owner" &&
                        <TextField
                          variant="outlined"
                          fullWidth
                          className="mt-3"
                          label="Age of the Building"
                          value={this.state.buildingInformation.age_of_building}
                          name="age_of_building"
                          onChange={this.handleBuildingDetailChange}
                        />
                      }

                      <TextField
                        variant="outlined"
                        select
                        fullWidth
                        className="mt-3"
                        label="Type of Building"
                        value={this.state.buildingInformation.type_of_building}
                        name="type_of_building"
                        onChange={this.handleBuildingDetailChange}
                      >
                        <MenuItem value=""> Select Type of Building </MenuItem>
                        <MenuItem value="House"> House </MenuItem>
                        <MenuItem value="Villa"> Villa </MenuItem>
                        <MenuItem value="Apartment"> Apartment </MenuItem>
                        <MenuItem value="Flat"> Flat </MenuItem>
                        <MenuItem value="Others"> Others </MenuItem>
                      </TextField>

                      <TextField
                        variant="outlined"
                        fullWidth
                        className="mt-3"
                        label="On which floor/ story of the building is the premises located"
                        value={this.state.buildingInformation.floor_no}
                        name="floor_no"
                        onChange={this.handleBuildingDetailChange}
                      />

                      {
                        this.state.homeOwnerType === "owner" &&
                        <React.Fragment>
                          {this.getDecisionContent("is_property_in_basement", "Is your property in the basement")}
                          {this.getDecisionContent("is_whole_building_residential", "Is the whole building used for residential purposes only?")}

                          {this.state.buildingInformation.is_whole_building_residential === "No" &&
                            <TextField
                              variant="outlined"
                              fullWidth
                              className="mt-3"
                              label="Please give details"
                              value={this.state.buildingInformation.not_residential_reason}
                              name="not_residential_reason"
                              onChange={this.handleBuildingDetailChange}
                            />
                          }

                          <TextField
                            variant="outlined"
                            fullWidth multiline rows={2}
                            className="mt-3"
                            label="Address of Property to be Insured"
                            value={this.state.buildingInformation.address}
                            name="address"
                            onChange={this.handleBuildingDetailChange}
                          />
                        </React.Fragment>
                      }
                    </Grid>
                    {
                      this.state.homeOwnerType === "owner" &&
                      <React.Fragment>
                        <Grid item xs={12} sm={6} md={4}>
                          <Typography variant="h5" className="pb-2 border-bottom">
                            Type of Fire protection devices installed in premises
                          </Typography>
                          {this.getDecisionContent("have_fire_alarm_system", "Fire Alarm Systems")}
                          {this.getDecisionContent("have_portable_extinguishers", "Portable Extinguishers")}
                          {this.getDecisionContent("have_hydrant_system", "Hydrant System")}
                          <TextField
                            variant="outlined"
                            fullWidth
                            className="mt-3"
                            label="Others (Please specify if any)"
                            value={this.state.buildingInformation.other_fire_protection}
                            name="other_fire_protection"
                            onChange={this.handleBuildingDetailChange}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                          <Typography variant="h5" className="pb-2 border-bottom">
                            Security systems provided in the premises
                          </Typography>
                          {this.getDecisionContent("have_surveillance_camera", "Surveillance Camera")}
                          {this.getDecisionContent("have_burglar_alarm_system", "Burglar Alarm System")}
                          {this.getDecisionContent("have_grilled_doors", "Grilled Doors")}
                          {this.getDecisionContent("have_watchman_service", "24 Hours watchman services")}
                          {this.getDecisionContent("have_security_checkpoint", "Security Check point")}

                          <TextField
                            variant="outlined"
                            fullWidth
                            className="mt-3"
                            label="Other, please specify"
                            value={this.state.buildingInformation.other_security_system}
                            name="other_security_system"
                            onChange={this.handleBuildingDetailChange}
                          />

                          <TextField
                            variant="outlined"
                            fullWidth
                            className="mt-3"
                            label="Previous Insurance Details"
                            value={this.state.buildingInformation.previous_insurance_details}
                            name="previous_insurance_details"
                            onChange={this.handleBuildingDetailChange}
                          />
                        </Grid>
                      </React.Fragment>
                    }

                    <Grid item xs={12} sm={12} md={12}>
                      <div className="d-flex justify-content-start">
                        <Button
                          variant="contained"
                          color="primary"
                          disabled={this.state.isSavingBuildingInfo || !this.isValidBuildingInfo()}
                          onClick={this.handleSaveBuildingInfo}
                        >
                          {this.state.isSavingBuildingInfo ? "Saving . . ." : "Save"}
                        </Button>
                      </div>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            }

          </Grid>
        </div>

      </div >
    )
  }
}

export default withRouter(EditPolicy);