import React from "react";
import Navigation from "../common/Navigation";
import { Grid, Paper, Typography, Divider, List, ListItem, ListItemIcon, ListItemSecondaryAction, Checkbox, Button, Dialog, DialogTitle, DialogActions, DialogContent, TextField, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core";
import "./style.css";
import { Link, withRouter } from "react-router-dom";
import { GetApp, Delete } from "@material-ui/icons";
import PolicyService from "../../services/policy";
import { saveAs } from "file-saver";
import UploadService from "../../services/upload";
import DownloadService from "../../services/download";
import JSZip from "jszip";
import Snackbar from "../common/Snackbar";
import $ from "jquery";
import HtmlToCanvas from "html2canvas";
import { PDFDownloadLink } from '@react-pdf/renderer';
import LifePDF from "./LifeTermPlan";
import PaymentReceipt from "./PaymentReceipt";
import config from "../../services/config";
import VatDebitNote from "./VatDebitNote";
import PASchedule from "./LifePA";
import TravelSchedule from "./TravelSchedule";
import HomeSchedulePDF from "./HomeSchedule";
import RiyatiSchedulePDF from "./RiyatiSchedule";
import PremiumService from "../../services/premium";
import PaymentService from "../../services/payments";
import FormattedFunctionMessage from "./../../Language/FormattedFunctionMessage";
import utils from "../../services/utils";
import _ from "lodash";
import moment from "moment";
import DownloadFile from "js-file-download";
import CommissionCreditNote from "./CommissionCreditNote";
import IndividualVirtualCardPDF from "./VirtualCard/IndividualPDF";
import IndividualVirtualCard from "./VirtualCard/IndividualCard";
import DomesticVirtualCard from "./VirtualCard/DomesticCard";
import DomesticVirtualCardPDF from "./VirtualCard/DomesticPDF";
import { PERMISSIONS } from "../../constants";

class Policy extends React.Component {

  state = {
    policy: {},
    items: [],
    showSnackbar: false,
    beneficiaries: [],
    showDomPdf: false,
    showDeleteDocumentDialog: false,
    selectedDocument: "",
    isDeletingDocument: false,
    showPaymentReceipt: false,
    payment_key_data: {},
    processing: false,
    comment: "",
    planType: "",
    downloading: false,
    documentNotFound: false,
    permissions : localStorage.getItem("permissions") ? JSON.parse(localStorage.getItem("permissions")) : []
  }

  getData = async () => {
    const id = this.props.match.params.id;
    this.setState({ processing: true });
    const data = await PolicyService.getPolicyById(id);
    this.setState({ processing: false });

    const policy = data.data;
    const comment = _.get(policy, "comment", "")
    const beneficiaries = policy.beneficiaries || [];

    if (policy.bank_deposit && Array.isArray(policy.bank_deposit.supporting_documents)) {
      policy.bank_deposit.supporting_documents = policy.bank_deposit.supporting_documents.map((document) => {
        let name = document;
        try {
          name = name.split("-");
          name.splice(0, 1);
          name = name.join("-");
        } catch (e) { }
        return {
          name: name,
          file: document
        }
      })
    }

    if (Array.isArray(policy.photographsOfPropertyFiles)) {
      policy.photographsOfPropertyFiles = policy.photographsOfPropertyFiles.map(photo => {
        let name = photo;
        try {
          name = name.split("-");
          name.splice(0, 1);
          name = name.join("-");
        } catch (e) { }
        return {
          name: name,
          file: photo
        }
      })
    }
    let basicPremium = utils.getFormattedAmount(policy.amount.base_price)

    this.setState({
      policy_id: id,
      data: policy,
      beneficiaries,
      insurance_type: policy.insurance_type,
      comment,
      basicPremium: basicPremium,
      planType: policy.plan
    }, () => {
      this.setState({
        showPdf: this.state.beneficiaries.length > 0,
        showDomPdf: this.state.data && (this.state.data.applicationType === "domestic"),
        showPaymentReceipt : true,
        showTravelPdf: true,
        showHomePdf: true,
        showRiyatiPdf: true,
        showCommissionCreditNotePdf : true,
        showVirtualCard : true
      });
    });

  }

  comparePolicyComment = () => {
    let comment = (this.state.data && this.state.data.comment) ? this.state.data.comment : "";
    return this.state.comment == comment;
  }

  saveComment = async () => {
    this.setState({ commentProcessing: true });
    try {
      await PolicyService.updateComment(this.state.comment, this.state.data._id);
      this.setState({ snackBarMessage: "Comment saved Successfully", showSnackbar: true, messageType: "success" })
      this.setState({ commentProcessing: false }, () => {
        this.getData();
      });
    } catch (error) {
      this.setState({ snackBarMessage: "Something went wrong", showSnackbar: true, messageType: "fail" })
      this.setState({ commentProcessing: false });
    }
  }

  getPaymentKeys = async () => {
    PremiumService.getPremium("payment_keys").then((paymentRes) => {
      this.setState({ payment_key_data: paymentRes.data.data });
    })
  }

  componentDidMount() {
    this.getPaymentKeys();
    const center = this.props.match.params.center;
    this.setState({ center })
    this.getData();
    window.addEventListener("storage", () => {
      const permissions = localStorage.getItem("permissions") ? JSON.parse(localStorage.getItem("permissions")) : []
      this.setState({ permissions })
    })
  }

  handleItemClick = (value) => {
    let arr = [...this.state.items]
    let itemIndex = arr.indexOf(value);
    if (itemIndex === -1)
      arr.push(value);
    else
      arr.splice(itemIndex, 1)

    this.setState({ items: arr });
  }

  addAllItems = () => {
    const all_documents = this.state.data.submittedDocuments;
    this.setState({
      items: all_documents
    })
  }

  downloadFile = (image) => {
    const title = image.title.split(" ").join("-");
    const url = image.url;
    const fileExt = url.substr(url.lastIndexOf("."));
    const _id = this.state.data._id;
    const fileName = `${(_id === "" || _id === undefined) ? "" : _id + "-"}${title}${fileExt}`;

    saveAs(url, fileName);
  }

  downloadAuthFile = (image) => {
    if (image.filename) {
      const title = image.title.split(" ").join("-");
      const url = `${config.baseUrl}/download-document/${image.filename}`;
      const fileExt = url.substr(url.lastIndexOf("."));
      const _id = this.state.data._id;
      const fileName = `${(_id === "" || _id === undefined) ? "" : _id + "-"}${title}${fileExt}`;

      fetch(url, { headers: { "auth-token": localStorage.getItem("token") } })
        .then(res => {
          if (res.status === 200) { return res.blob() }
        })
        .then(blob => {
          if (blob) saveAs(blob, fileName)
        })

    }
  }

  downloadHomePropertyPhoto = (photo) => {
    if (photo.file && photo.name) {
      const url = `${config.baseUrl}/download-home-document/${photo.file}`;

      fetch(url, { headers: { "auth-token": localStorage.getItem("token") } })
        .then(res => {
          if (res.status === 200) { return res.blob() }
        })
        .then(blob => {
          if (blob) saveAs(blob, photo.name)
        })

    }
  }

  downloadHomePropertyDocument = (photo) => {
    if (photo.file && photo.name) {
      const url = `${config.baseUrl}/download-bank-deposit-document/${photo.file}`;

      fetch(url, { headers: { "auth-token": localStorage.getItem("token") } })
        .then(res => {
          if (res.status === 200) { return res.blob() }
        })
        .then(blob => {
          if (blob) saveAs(blob, photo.name)
        })

    }
  }


  handleStatusChange = async (status) => {
    this.setState({ processing: true });
    const data = await PolicyService.updateStatus(status, this.state.data._id);
    this.setState({ processing: false });
    if (data.status === 200) {
      this.getData();
      this.setState({ snackBarMessage: "Status Changed Successfully", showSnackbar: true, messageType: "success" });
    }
  }

  handleCloseSnackbar = () => {
    this.setState({ showSnackbar: false, snackBarDuration: 5000 });
  }

  uploadFile = async (doc, file) => {
    let formData = new FormData();
    formData.append("title", doc);
    formData.append("policy_id", this.state.data._id);
    formData.append("file", file);
    const res = await UploadService.uploadImage(this.state.data.documents_id, formData);
    const data = res.data;
    if (data.uploaded) {
      this.setState({
        showSnackbar: true,
        snackBarMessage: "Uploaded successfully",
        messageType: "success"
      });
      this.getData();
    }
  }

  handleFileChange = (doc, e) => {
    if (e.target.files.length) {
      this.uploadFile(doc, e.target.files[0]);
      this.setState({
        showSnackbar: true,
        snackBarMessage: "Uploading . . .",
        messageType: "info"
      })
    } else {
      this.setState({
        showSnackbar: true,
        snackBarMessage: "No files selected",
        messageType: "danger"
      })
    }
  }

  downloadFiles = async () => {
    this.setState({
      showSnackbar: true,
      snackBarMessage: "Downloading . . .",
      messageType: "primary"
    })
    const images = [...this.state.data.images];
    const items = this.state.items;
    let arr = [];
    items.forEach((item) => {
      const selectedItem = images.find((image) => { return image.title === item });
      arr.push(selectedItem);
    })
    const policyId = this.state.data.policy_id;
    var zip = new JSZip();

    for (let docObj of arr) {
      const title = docObj.title.split(" ").join("-");
      const res = await DownloadService.getFile(docObj.url);
      if (res.status === 200) {
        const path = `${title}.pdf`;
        zip.file(path, res.data);
      }
    }
    zip.generateAsync({ type: "blob" }).then((blob) => {
      this.setState({ showSnackbar: false });
      saveAs(blob, `${policyId}.zip`);
    });
  }

  downloadContactDetails = () => {
    const personalInformation = this.state.data.personalInformation;
    const str = `
      Name : ${personalInformation.fullName}
      Email : ${personalInformation.email}
      Mobile No : ${personalInformation.mobileNumber}
    `

    var blob = new Blob([str], { type: "text/plain;charset=utf-8" });

    const fileName = (this.state.data.policy_id === undefined || this.state.data.policy_id === "") ? "contact-details.txt" : `${this.state.data.policy_id}-contact-details.txt`;
    saveAs(blob, fileName);
  }

  downloadAffordableHealthCover = async () => {
    this.setState({ downloading: true, documentNotFound: false })
    try {
      let response = await PolicyService.getUHIPDocument(this.state.data._id);
      if (response.data && response.data.type != 'application/json') {
        DownloadFile(response.data, `${this.state.data._id}-UHIP.pdf`);
        this.setState({ downloading: false })
      } else {
        this.setState({ documentNotFound: true, downloading: false })
      }
    } catch (error) {
      console.error(error);
    }
  }

  handleDialogClose = () => {
    this.setState({
      showMemberDialog: false,
      showPolicyDialog: false,
      policyId: "",
      memberId: ""
    })
  }

  handleIdChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  }

  handleUpdateId = async (name, fullName, field) => {
    const obj = { [field]: this.state[name] }
    const data = await PolicyService.update(obj, this.state.data._id);
    if (data.status === 200) {
      this.setState({ snackBarMessage: `${fullName} updated successfully`, showSnackbar: true, messageType: "success" });
      this.getData();
      this.handleDialogClose();
    }
  }

  saveImage = () => {
    const top = $("html").scrollTop();
    if (top !== 0) {
      $("html").animate({
        scrollTop: 0
      }, 500, "linear", this.downloadImage);
    } else {
      this.downloadImage();
    }
  }

  downloadImage = () => {
    const el = $(".vc-container");
    HtmlToCanvas(el[0], { useCORS: true }).then((canvas) => {
      let image = canvas.toDataURL("image/jpeg", 1.0);
      let link = document.createElement("a");
      link.download = "AXSS-virtual-card.jpeg";
      link.href = image;
      link.click();
    });
  }

  getDob = () => {
    const dob = this.state.data.personalInformation.dob;
    const splitDob = dob.split("-");
    const month = splitDob[1];
    const months = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];
    let monthInd = months.indexOf(month);
    const dobMonth = monthInd + 1;
    return `${splitDob[0]}-${dobMonth < 10 ? "0" + dobMonth : dobMonth}-${splitDob[2]}`;
  }

  getStartDate = (startDate) => {
    return moment(startDate).format("DD-MM-YYYY");
  }

  getEndDate = (startDate) => {
    let numYears = 1;
    if (["Unified Domestic Worker", "Personal Accident"].includes(this.state.data.cover_type)) {
      numYears = 2
    }

    return moment(startDate).subtract(1, "day").add(numYears, "year").format("DD/MM/YYYY");
  }

  getFormattedUploadDate = (dateMS) => {
    return moment(dateMS).format("DD-MM-YYYY HH:mm:ss");
  }

  deleteFile = (image) => {
    this.setState({ showDeleteDocumentDialog: true, selectedDocument: image.title })
  }

  handleCloseDeleteDialog = () => {
    this.setState({ showDeleteDocumentDialog: false, selectedDocument: "", isDeletingDocument: false });
  }

  handleDeleteDocument = () => {
    this.setState({ isDeletingDocument: true });
    const data = {
      title: this.state.selectedDocument,
      policy_id: this.state.data._id
    }
    UploadService.deleteDocument(this.state.data.documents_id, data).then((res) => {
      if (res.data.deleted) {
        this.handleCloseDeleteDialog();
        this.setState({
          showSnackbar: true,
          snackBarMessage: "Document Deleted successfully",
          messageType: "success"
        });
        this.getData();
      }
    }).catch((e) => {
      this.handleCloseDeleteDialog();
      this.setState({
        showSnackbar: true,
        snackBarMessage: "Document not deleted",
        messageType: "danger"
      });
    })
  }

  handleRefund = () => {
    this.setState({ isRefunding: true });

    const paymentId = this.state.data.payments.payment_id;
    const paymentIdArr = paymentId.split("_");
    const id = paymentIdArr[paymentIdArr.length - 1];
    const { url, secret_key } = this.state.payment_key_data;
    PaymentService.refundPayment(url, secret_key, paymentId).then((refundRes) => {
      const updateObj = {
        ...this.state.data.payments,
        isRefunded: true,
        payment_refunded_at: Date.now()
      }
      PolicyService.update(updateObj, this.state.data._id).then((updateRes) => {
        this.setState({
          isRefunding: false,
          showSnackbar: true,
          snackBarMessage: "Payment refunded successfully",
          messageType: "success"
        });
      });

      this.getData();
    }).catch((refundErr) => {
      this.setState({
        isRefunding: false,
        showSnackbar: true,
        snackBarMessage: "Payment not refunded",
        messageType: "danger"
      });
    })
  }

  handleAddToISA = () => {
    PolicyService.updateToISA(this.props.match.params.id).then((res) => {
      this.setState({
        showSnackbar: true,
        snackBarMessage: "Updated successfully",
        messageType: "success"
      });
      this.getData();
    })
  }

  downloadSignedQuestionnaire = () => {
    const policy_id = this.state.policy_id;
    const fileUrl = `${config.baseUrl}/uploads/signed-maf/${policy_id}.pdf`;
    this.setState({ downloadingQuestionnaire : true });
    DownloadService.getFile(fileUrl).then((downloadRes) => {
      saveAs(downloadRes.data, `${policy_id}-Signed-Questionnaire.pdf`)
      this.setState({ downloadingQuestionnaire : false });
    })
  }

  downloadPaymentReceipt = () => {
    const policy_id = this.state.policy_id;
    const fileUrl = `${config.baseUrl}/upload-payment-receipt/${policy_id}.pdf`;
    this.setState({ downloadingPaymentReceipt : true });
    DownloadService.getFile(fileUrl).then((downloadRes) => {
      saveAs(downloadRes.data, `${policy_id}-Payment-Receipt.pdf`)
      this.setState({ downloadingPaymentReceipt : false });
    })
  }

  downloadPolicySchedule = (title) => {
    const policy_id = this.state.policy_id;
    this.setState({ is_downloading_policy_schedule : true });
    DownloadService.getPolicySchedule(policy_id).then((downloadRes) => {
      saveAs(downloadRes.data, `${policy_id}-${title}.pdf`)
      this.setState({ is_downloading_policy_schedule : false });
    })
  }

  render() {
    return (
      <div className="d-flex">
        <Navigation />
        <Snackbar
          showSnackbar={this.state.showSnackbar}
          messageType={this.state.messageType}
          snackBarMessage={this.state.snackBarMessage}
          onCloseSnackbar={this.handleCloseSnackbar}
        />
        <div className="container-fluid">
          <Paper className="p-2 sq-shadow mb-3 d-flex">
            <Typography><Link to={`/home`}>Home</Link></Typography>
            <Typography className="mr-2 ml-2">/</Typography>
            <Typography> <Link to={`/policies`}>Policies</Link> </Typography>
            <Typography className="mr-2 ml-2">/</Typography>
            <Typography>{this.props.match.params.id}</Typography>
          </Paper>

          {
            this.state.data &&
            <Grid container spacing={2}>

              <Grid item xs={12}>
                <Grid container spacing={2}>
                  {
                    (this.state.data && this.state.showVirtualCard && this.state.data.insurance_type === "health") &&
                    <Grid item xs={12} md={4}>
                      {
                        this.state.data.cover_type === "Individual Medical" ?
                        <IndividualVirtualCard
                          data={this.state.data}
                        /> :
                        <DomesticVirtualCard
                          data={this.state.data}
                        />
                      }
                    </Grid>
                  }

                  <Grid item xs={12} md={4}>
                    <Paper className="pb-3 pr-3 pl-3 sq-shadow">

                      {
                        (this.state.data && this.state.data.insurance_type === "health" && this.state.showVirtualCard) &&
                        <div className="pt-3">
                          <PDFDownloadLink
                            document={
                              this.state.data.cover_type === "Individual Medical" ?
                              <IndividualVirtualCardPDF
                                data = {this.state.data}
                              /> :
                              <DomesticVirtualCardPDF
                                data = {this.state.data}
                              />
                            }
                            fileName = {`${this.state.data._id}-virtual-card.pdf`}
                          >
                            {({ loading }) =>
                              <Button
                                variant="contained"
                                color="primary"
                                className="w-100"
                                disabled={loading}
                              >
                                Download Virtual Card
                              </Button>
                            }
                          </PDFDownloadLink>
                        </div>
                      }
                      {
                        (this.state.data && this.state.data.payments && this.state.data.payments.isPaid && this.state.showDomPdf) &&
                        <div className="pt-3">
                          <Button
                            variant="contained"
                            color="primary"
                            className="w-100"
                            disabled={this.state.downloading}
                            onClick={this.downloadAffordableHealthCover}
                          >
                            Download Policy Schedule
                          </Button>
                          {
                            this.state.documentNotFound &&
                            <p className="affordable-health-cover-not-found">Document not found.</p>
                          }
                        </div>
                      }

                      {
                        (
                          this.state.data &&
                          this.state.data.payments &&
                          this.state.data.payments.isPaid &&
                          this.state.showCommissionCreditNotePdf &&
                          this.state.data.affiliate_id &&
                          this.state.data.affiliate_type === "Broker"
                        ) &&

                        <div className="pt-3">
                          <PDFDownloadLink
                            document={
                              <CommissionCreditNote
                                policy = {this.state.data}
                              />
                            }
                            fileName = {`${this.state.data._id}-Commission-credit-note.pdf`}
                          >
                            {({ loading }) =>
                              <Button
                                variant="contained"
                                color="primary"
                                className="w-100"
                                disabled={loading}
                              >
                                Download Commission Credit Note
                              </Button>
                            }
                          </PDFDownloadLink>
                        </div>
                      }

                      {this.state.data &&
                        <div className="pt-3">
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={this.downloadSignedQuestionnaire}
                            className="w-100"
                            disabled={this.state.downloadingQuestionnaire}
                          >
                            Download Signed Questionnaire
                          </Button>
                        </div>
                      }

                      {
                        (this.state.data && this.state.beneficiaries.length > 0 && this.state.showPdf) &&
                        <div className="pt-3">
                          <PDFDownloadLink
                            document={
                              [this.state.data.li_addon_type, this.state.data.cover_type].includes("Personal Accident") ?
                                <PASchedule
                                  beneficiaries={this.state.beneficiaries}
                                  data={this.state.data}
                                  startDate={this.getStartDate(this.state.data && this.state.data.startDate)}
                                  endDate={this.getEndDate(this.state.data && this.state.data.startDate)}
                                  dob={this.getDob()}
                                /> :
                                <LifePDF
                                  beneficiaries={this.state.beneficiaries}
                                  data={this.state.data}
                                  startDate={this.getStartDate(this.state.data && this.state.data.startDate)}
                                  endDate={this.getEndDate(this.state.data && this.state.data.startDate)}
                                  dob={this.getDob()}
                                />
                            }
                            fileName={[this.state.data.li_addon_type, this.state.data.cover_type].includes("Personal Accident") ? "Personal-Accident-Schedule.pdf" : "Term-plan-certificate.pdf"}
                          >
                            {({ loading }) =>
                              <Button
                                variant="contained"
                                color="primary"
                                className="w-100"
                                disabled={loading}
                              >
                                Download Policy Schedule
                              </Button>
                            }
                          </PDFDownloadLink>
                        </div>
                      }

                      {/* {
                        ((this.state.insurance_type === "travel" && this.state.data.plan != "inbound_travel") && this.state.showTravelPdf) &&
                        <div className="pt-3">
                          <Button
                            variant="contained"
                            color="primary"
                            className="w-100"
                            onClick={() => this.downloadPolicySchedule("Travel-Schedule")}
                            disabled={this.state.is_downloading_policy_schedule}
                          >
                            {
                              this.state.is_downloading_policy_schedule ? "Downloading . . ." : "Download Policy Schedule"
                            }
                          </Button>
                        </div>
                      } */}

                      {
                        (this.state.insurance_type === "home" && this.state.showHomePdf) &&
                        <div className="pt-3">
                          <Button
                            variant="contained"
                            color="primary"
                            className="w-100"
                            onClick={() => this.downloadPolicySchedule("Home-Schedule")}
                            disabled={this.state.is_downloading_policy_schedule}
                          >
                            {
                              this.state.is_downloading_policy_schedule ? "Downloading . . ." : "Download Policy Schedule"
                            }
                          </Button>
                        </div>
                      }
                      {
                        (this.state.insurance_type === "cyber") &&
                        <div className="pt-3">
                          <Button
                            variant="contained"
                            color="primary"
                            className="w-100"
                            onClick={() => this.downloadPolicySchedule("Cyber-Schedule")}
                            disabled={this.state.is_downloading_policy_schedule}
                          >
                            {
                              this.state.is_downloading_policy_schedule ? "Downloading . . ." : "Download Policy Schedule"
                            }
                          </Button>
                        </div>
                      }
                      {
                        (this.state.insurance_type === "riyati" && this.state.showRiyatiPdf) &&
                        <div className="pt-3">

                          <PDFDownloadLink
                            document={
                              <RiyatiSchedulePDF
                                policy={this.state.data}
                              />
                            }
                            fileName="Riyati-Schedule.pdf"
                          >
                            {({ loading }) =>
                              <Button
                                variant="contained"
                                color="primary"
                                className="w-100"
                                disabled={loading}
                              >
                                Download Policy Schedule
                              </Button>
                            }
                          </PDFDownloadLink>
                        </div>
                      }

                      {
                        (
                          this.state.data &&
                          this.state.data.payments &&
                          this.state.data.payments.isPaid &&
                          this.state.showPaymentReceipt
                        ) &&

                        <div className="pt-3">
                          <Button
                            variant="contained"
                            color="primary"
                            className="w-100"
                            onClick={this.downloadPaymentReceipt}
                            disabled = {this.state.downloadingPaymentReceipt}
                          >
                            Download Payment Receipt
                          </Button>
                        </div>
                      }

                      {
                        (
                          !(this.state.data.insurance_type === "life" || this.state.data.amount.vat === undefined) &&
                          (
                            this.state.data &&
                            this.state.data.payments &&
                            this.state.data.payments.isPaid &&
                            this.state.showPaymentReceipt
                          )
                        ) &&
                        <div className="pt-3">
                          <PDFDownloadLink
                            document={
                              <VatDebitNote
                                policy={this.state.data}
                                payment_mode={this.state.data.payments.mode}
                                amount={this.state.data.amount}
                                full_name={this.state.data.personalInformation.fullName}
                                member_id={this.state.data._id}
                                has_li_addon={this.state.data.has_li_addon}
                                li_addon_type={this.state.data.li_addon_type}
                                start_date={this.state.data.startDate}
                                nationality={this.state.data.nationality}
                                vat_in={this.state.data.personalInformation.vat_in}
                                resident_location={this.state.data.personalInformation.resident_location}
                              />
                            }
                          >
                            {({ loading }) =>
                              <Button
                                variant="contained"
                                color="primary"
                                className="w-100"
                                disabled={loading}
                              >
                                Download VAT Debit Note
                              </Button>
                            }
                          </PDFDownloadLink>
                        </div>
                      }

                      {
                        this.state.insurance_type == "travel" &&
                        <div className="pt-3">
                          <a
                            href={
                              this.state.data && this.state.data.inbound_issue_policy_response &&
                              this.state.data.inbound_issue_policy_response.response_data &&
                              this.state.data.inbound_issue_policy_response.response_data.CertificateUrl
                            }
                            target="_blank"
                          >
                              <Button
                                variant="contained"
                                color="primary"
                                className="w-100"
                              >
                                Travel Certificate
                              </Button>
                            </a>

                            {
                              (this.state.data && !this.state.data.inbound_issue_policy_response) &&
                              <Button
                                variant="contained"
                                color="primary"
                                onClick={this.handleAddToISA}
                                className="w-100 mt-3"
                              >
                                Add to ISA
                              </Button>
                            }
                        </div>
                      }

                    </Paper>
                  </Grid>
                </Grid>
              </Grid>
              
              <Grid item xs={12} md={4}>
                <Paper className="pt-3 pb-3 pr-2 pl-2 sq-shadow">
                  <Typography variant="h5" className="pb-2">
                    Policy Details
                  </Typography>
                  <Divider />
                  <table className="mt-4 mb-3">
                    <tbody>
                      <tr>
                        <td>
                          <Typography variant="body1"> Member ID </Typography>
                        </td>
                        <td> &nbsp; - &nbsp; </td>
                        <td>
                          <Typography variant="body1" className="text-capitalize"> {this.state.data && this.state.data._id} </Typography>
                        </td>
                      </tr>
                      {
                        ["health", "life"].includes(this.state.insurance_type) &&
                        <tr>
                          <td>
                            <Typography variant="body1"> Relation </Typography>
                          </td>
                          <td> &nbsp; - &nbsp; </td>
                          <td>
                            <Typography variant="body1" className="text-capitalize"> {this.state.data && this.state.data.applicationType} </Typography>
                          </td>
                        </tr>
                      }
                      {
                        ["riyati"].includes(this.state.insurance_type) &&
                        <tr>
                          <td>
                            <Typography variant="body1"> Plan Name </Typography>
                          </td>
                          <td> &nbsp; - &nbsp; </td>
                          <td>
                            <Typography variant="body1" className="text-capitalize"> {this.state.data && this.state.data.riyati_plan_type} </Typography>
                          </td>
                        </tr>
                      }
                      {
                        ["riyati"].includes(this.state.insurance_type) &&
                        <tr>
                          <td>
                            <Typography variant="body1"> Duration Days</Typography>
                          </td>
                          <td> &nbsp; - &nbsp; </td>
                          <td>
                            <Typography variant="body1" className="text-capitalize"> {this.state.data && this.state.data.riyati_duration_days} </Typography>
                          </td>
                        </tr>
                      }
                      {
                        [
                          { field: "Status", value: (this.state.data && this.state.data.status) },
                          { field: "Nationality", value: (this.state.data && this.state.data.nationality) },
                          { field: "Amount", value: (this.state.data && (this.state.data.amount ? Number(this.state.data.amount.total).toFixed(2) : 0)) },
                          { field: "Payment Mode ", value: (this.state.data && this.state.data.payments && (this.state.data.payments.mode === "Link" ? "Card (By Link)" : this.state.data.payments.mode)) },
                        ].map((details, ind) => (
                          <tr key={ind}>
                            <td> <Typography variant="body1"> {details.field} </Typography> </td>
                            <td> &nbsp; - &nbsp; </td>
                            <td> <Typography variant="body1" className="text-capitalize"> {details.value} </Typography> </td>
                          </tr>
                        ))
                      }

                      {
                        this.state.data && this.state.data.payments && this.state.data.payments.mode === "Point of Sale" &&
                        <React.Fragment>
                          {
                            [
                              { field: "Payment references Number", value: (this.state.data.payments.payment_ref_number) },
                            ].map((details, ind) => (
                              <tr key={ind}>
                                <td> <Typography variant="body1"> {details.field} </Typography> </td>
                                <td> &nbsp; - &nbsp; </td>
                                <td> <Typography variant="body1" className="text-capitalize"> {details.value} </Typography> </td>
                              </tr>
                            ))
                          }
                        </React.Fragment>
                      }
                      {
                        this.state.data && this.state.data.payments && this.state.data.payments.mode === "Bank Deposit" &&
                        <React.Fragment>
                          {
                            [
                              { field: "Bank", value: (this.state.data.bank_deposit && this.state.data.bank_deposit.bank) },
                              { field: "Bank Account Code", value: (this.state.data.bank_deposit && this.state.data.bank_deposit.bank_account_code) },
                              { field: "Payment references Number", value: (this.state.data.bank_deposit && this.state.data.bank_deposit.payment_ref_number) },
                            ].map((details, ind) => (
                              <tr key={ind}>
                                <td> <Typography variant="body1"> {details.field} </Typography> </td>
                                <td> &nbsp; - &nbsp; </td>
                                <td> <Typography variant="body1" className="text-capitalize"> {details.value} </Typography> </td>
                              </tr>
                            ))
                          }
                          {
                            this.state.data.bank_deposit && this.state.data.bank_deposit.supporting_documents &&
                            Array.isArray(this.state.data.bank_deposit.supporting_documents) &&
                            this.state.data.bank_deposit.supporting_documents.map((document, ind) => {
                              return <tr key={ind}>
                                <th className="d-flex justify-content-between">
                                  <Typography variant="body1"> {document.name}</Typography>
                                </th>
                                <td> &nbsp; - &nbsp; </td>
                                <th>
                                  <Button size="small" variant="contained" color="primary" className="ml-3" onClick={e => { this.downloadHomePropertyDocument(document) }}>
                                    <GetApp />
                                  </Button>
                                </th>
                              </tr>
                            })
                          }
                        </React.Fragment>
                      }

                    </tbody>
                  </table>
                </Paper>

                <Paper className="pt-3 pb-3 pr-2 pl-2 mt-3 sq-shadow">
                  <Typography variant="h5" className="pb-2">
                    Personal Information
                  </Typography>
                  <Divider />
                  <table className="mt-4">
                    <tbody>
                      <tr>
                        <td>
                          <Typography variant="body1"> Name </Typography>
                        </td>
                        <td> &nbsp; - &nbsp; </td>
                        <td>
                          <Typography variant="body1" className="text-capitalize"> {this.state.data && this.state.data.personalInformation.fullName} </Typography>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <Typography variant="body1"> Email </Typography>
                        </td>
                        <td> &nbsp; - &nbsp; </td>
                        <td>
                          <Typography variant="body1"> {this.state.data && this.state.data.personalInformation.email} </Typography>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <Typography variant="body1"> Phone </Typography>
                        </td>
                        <td> &nbsp; - &nbsp; </td>
                        <td>
                          <Typography variant="body1"> {this.state.data && this.state.data.personalInformation.mobileNumber} </Typography>
                        </td>
                      </tr>
                      {
                        (this.state.insurance_type !== "home" && this.state.planType !== "family" && this.state.planType !== "travel" && this.state.planType !== "inbound_travel") &&
                        <tr>
                          <td>
                            <Typography variant="body1"> DOB </Typography>
                          </td>
                          <td> &nbsp; - &nbsp; </td>
                          <td>
                            <Typography variant="body1"> {this.state.data && this.state.data.personalInformation.dob} </Typography>
                          </td>
                        </tr>
                      }
                      {
                        ["health", "life"].includes(this.state.insurance_type) &&
                        <tr>
                          <td>
                            <Typography variant="body1"> Married </Typography>
                          </td>
                          <td> &nbsp; - &nbsp; </td>
                          <td>
                            <Typography variant="body1" className="text-capitalize"> {this.state.data && this.state.data.personalInformation.isMarried} </Typography>
                          </td>
                        </tr>
                      }
                      <tr>
                        <td>
                          <Typography variant="body1"> Civil Id </Typography>
                        </td>
                        <td> &nbsp; - &nbsp; </td>
                        <td>
                          <Typography variant="body1"> {this.state.data && this.state.data.civil_id} </Typography>
                        </td>
                      </tr>

                      {
                        (["health", "life"].includes(this.state.insurance_type) && this.state.data && this.state.data.personalInformation.height) &&
                        <tr>
                          <td>
                            <Typography variant="body1"> Height </Typography>
                          </td>
                          <td> &nbsp; - &nbsp; </td>
                          <td>
                            <Typography variant="body1" className="text-capitalize"> {this.state.data.personalInformation.height} </Typography>
                          </td>
                        </tr>
                      }
                      {
                        (["health", "life"].includes(this.state.insurance_type) && this.state.data && this.state.data.personalInformation.weight) &&
                        <tr>
                          <td>
                            <Typography variant="body1"> Weight </Typography>
                          </td>
                          <td> &nbsp; - &nbsp; </td>
                          <td>
                            <Typography variant="body1" className="text-capitalize"> {this.state.data.personalInformation.weight} </Typography>
                          </td>
                        </tr>
                      }
                    </tbody>
                  </table>
                </Paper>
                {
                  this.state.data && this.state.data.insurance_type == "travel" &&
                  <Paper className="pt-3 pb-3 pr-2 pl-2 mt-3 sq-shadow">
                    <Typography variant="h5" className="pb-2">
                      {this.state.data.plan == "inbound_travel" ? "Inbound" : "Outbound"} Details
                    </Typography>
                    <Divider />

                    <Typography variant="body1" className="mt-3"> Coverage Name </Typography>
                    <Typography variant="body1" className="text-capitalize"> <b> { this.state.data.plan == "inbound_travel" ? this.state.data.inbound_coverage_name : this.state.data.outbound_coverage_name } </b> </Typography>

                    <Typography variant="body1" className="mt-3"> {this.state.data.plan == "inbound_travel" ? "From" : "To"} Country </Typography>
                    <Typography variant="body1" className="text-capitalize"> <b> { this.state.data.plan == "inbound_travel" ? this.state.data.inbound_country_name : this.state.data.outbound_country_name } </b> </Typography>

                    <Typography variant="body1" className="mt-3"> Product Name </Typography>
                    <Typography variant="body1" className="text-capitalize"> <b> { this.state.data.plan == "inbound_travel" ? this.state.data.inbound_product_name : this.state.data.outbound_product_name } </b> </Typography>

                    <Typography variant="body1" className="mt-3"> Product Type </Typography>
                    <Typography variant="body1" className="text-capitalize"> <b> { this.state.data.plan == "inbound_travel" ? this.state.data.inbound_product_type_name : this.state.data.outbound_product_type_name } </b> </Typography>

                    <Typography variant="body1" className="mt-3"> Start Date </Typography>
                    <Typography variant="body1" className="text-capitalize"> <b> { moment(this.state.data.plan == "inbound_travel" ? this.state.data.inbound_travel_start_date : this.state.data.outbound_travel_start_date).format("DD-MM-YYYY")} </b> </Typography>

                    <Typography variant="body1" className="mt-3"> End Date </Typography>
                    <Typography variant="body1" className="text-capitalize"> <b> { moment(this.state.data.plan == "inbound_travel" ? this.state.data.inbound_travel_end_date : this.state.data.outbound_travel_end_date).format("DD-MM-YYYY")} </b> </Typography>

                    <Typography variant="body1" className="mt-3"> Push to ISA Travel </Typography>
                    <Typography variant="body1" className="text-capitalize"> <b> { this.state.data.inbound_issue_policy_status ? "Yes" : "no"} </b> </Typography>

                    <Typography variant="body1" className="mt-3"> ISA Policy ID / Number </Typography>
                    <Typography variant="body1" className="text-capitalize"> <b> { this.state.data.inbound_issue_policy_response && this.state.data.inbound_issue_policy_response.response_data && this.state.data.inbound_issue_policy_response.response_data.PolicyNumber} </b> </Typography>

                    <Typography variant="body1" className="mt-3"> Additional Cover </Typography>
                    <Typography variant="body1" className="text-capitalize"> <b> { this.state.data.inbound_generate_quotation_response && this.state.data.inbound_generate_quotation_response.CoversPremium.map((value) => (value.Description + ", "))} </b> </Typography>

                  </Paper>

                }

                {
                  (this.state.data && this.state.data.cover_type === "Individual Medical") &&
                  <Paper className="pt-3 pb-3 pr-2 pl-2 mt-3 sq-shadow">
                    <Typography variant="h5" className="pb-2">
                      Plan Details
                    </Typography>
                    <Divider />
                    <table className="mt-4 mb-3">
                      <tbody>
                        <tr>
                          <td>
                            <Typography variant="body1"> Plan Name </Typography>
                          </td>
                          <td> &nbsp; - &nbsp; </td>
                          <td>
                            <Typography variant="body1" className="text-capitalize"> {this.state.data && this.state.data.plan} </Typography>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <Typography variant="body1"> Network </Typography>
                          </td>
                          <td> &nbsp; - &nbsp; </td>
                          <td>
                            <Typography variant="body1" className="text-capitalize"> {this.state.data && (this.state.data.network === "Silver" ? "Silver Premium" : this.state.data.network)} </Typography>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <Typography variant="body1"> Annual Limit </Typography>
                          </td>
                          <td> &nbsp; - &nbsp; </td>
                          <td>
                            <Typography variant="body1" className="text-capitalize"> {this.state.data && this.state.data.annual_limit} OMR </Typography>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <Typography variant="body1"> OP Deductible </Typography>
                          </td>
                          <td> &nbsp; - &nbsp; </td>
                          <td>
                            <Typography variant="body1" className="text-capitalize"> {this.state.data && this.state.data.op} OMR </Typography>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <Typography variant="body1"> Pharmacy Co Pay </Typography>
                          </td>
                          <td> &nbsp; - &nbsp; </td>
                          <td>
                            <Typography variant="body1"> {this.state.data && this.state.data.co_pay} </Typography>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </Paper>
                }

                {
                  this.state.data && this.state.data.plan != "inbound_travel" &&
                  <Paper className="pt-3 pb-3 pr-2 pl-2 mt-3 sq-shadow">
                    <Typography variant="h5" className="pb-2">
                      Question Information
                    </Typography>
                    <Divider />
                    {
                      (this.state.data && this.state.data.has_li_addon) &&
                      <Typography variant="h6" className="mt-4 border-top border-bottom">Health</Typography>
                    }
                    {
                      (this.state.data && this.state.data.insurance_type === "health") &&
                      <div className="mt-4">
                        <Typography variant="body1"> Are you under any medical observation/undergoing any medical/ surgical/ treatment or have been advised for the same? </Typography>
                        <Typography variant="body1" className="text-capitalize"> <b>{this.state.data && this.state.data.underlyingMedicalObservation}</b> </Typography>

                        <Typography variant="body1" className="mt-3"> Do you have any chronic illness? </Typography>
                        <Typography variant="body1" className="text-capitalize"> <b>{this.state.data && this.state.data.haveChronicIllness}</b> </Typography>

                        <Typography variant="body1" className="mt-3"> Are you taking any medication (pharmaceutical/alternative medicine) or have been advised? </Typography>
                        <Typography variant="body1" className="text-capitalize"> <b>{this.state.data && this.state.data.isTakingMedication}</b> </Typography>

                        <Typography variant="body1" className="mt-3"> Do you have any physical problems/ disability for which you are undergoing physiotherapy or have been advised for? </Typography>
                        <Typography variant="body1" className="text-capitalize"> <b>{this.state.data && this.state.data.havePhysicalProblem}</b> </Typography>

                        <Typography variant="body1" className="mt-3"> Have you been admitted in the hospital in the last 10 years? </Typography>
                        <Typography variant="body1" className="text-capitalize"> <b>{this.state.data && this.state.data.haveAdmitted}</b> </Typography>

                        <Typography variant="body1" className="mt-3"> Do you have any previous surgical history or are you advised to undergo any kind of surgeries in the near future? </Typography>
                        <Typography variant="body1" className="text-capitalize"> <b>{this.state.data && this.state.data.havePreviousSurgicalHistory}</b> </Typography>

                        <Typography variant="body1" className="mt-3"> Have you been ever diagnosed/treated and cured or undergoing treatments for cancer? </Typography>
                        <Typography variant="body1" className="text-capitalize"> <b>{this.state.data && this.state.data.haveCuredCancer}</b> </Typography>

                        <Typography variant="body1" className="mt-3"> Is there any other medical condition or disorder or any symptoms that you should be declared, and you are unable to relate to the above-mentioned Questions? </Typography>
                        <Typography variant="body1" className="text-capitalize"> <b>{this.state.data && this.state.data.haveOtherConditions}</b> </Typography>
                        {
                          (this.state.data && this.state.data.personalInformation.gender === "female" && this.state.data.personalInformation.isMarried === "yes") &&
                          <>
                            <Typography variant="body1" className="mt-3"> Are you currently pregnant or show signs and symptoms of pregnancy ?  </Typography>
                            <Typography variant="body1" className="text-capitalize"> <b>{this.state.data && this.state.data.isPregnant}</b> </Typography>
                          </>
                        }
                      </div>
                    }
                    {
                      (this.state.data && this.state.data.has_li_addon) &&
                      <Typography variant="h6" className="mt-4 border-top border-bottom">Life</Typography>
                    }
                    {
                      (
                        this.state.data &&
                        (this.state.data.insurance_type === "life" || this.state.data.has_li_addon) &&
                        (this.state.data.li_addon_type === "Term Plan" || this.state.data.plan === "Term Plan")
                      ) &&
                      <div className="mt-4">
                        <Typography variant="body1"> I have not been on any medication/ suffered from any ailment/disease/deformity which required continuous follow up treatment /medication beyond 15 days/advised for any surgery/hospitalization in the past 5 years and none of my previous applications for insurance has ever been declined/ deferred/ accepted with extra premium for any reasons. </Typography>
                        <Typography variant="body1" className="text-capitalize"> <b>{this.state.data && this.state.data.on_medication}</b> </Typography>

                        <Typography variant="body1" className="mt-3"> I have not travelled abroad since 01/01/2020, do not intend to travel abroad within the next 3 months, am not currently not receiving/ have not received any medical care/ referred to a hospital/clinic for pneumonia, flu like symptoms, fever, sore throat, runny nose, cough, shortness of breath and breathing difficulties in the past 30 days. I have not undergone any surgical procedure during my travel abroad or in my country of residence in the past 30 days. I have not been issued any notice or directive to self-quarantine or stay home (excluding as part of government mandate or altered employment arrangement), nor have I within the past 15 days come in contact with someone confirmed as infected with the COVID- 19 virus </Typography>
                        <Typography variant="body1" className="text-capitalize"> <b>{this.state.data && this.state.data.have_travelled}</b> </Typography>

                        <Typography variant="body1" className="mt-3"> I am currently in good health, free from any defect, disease, disability or medication </Typography>
                        <Typography variant="body1" className="text-capitalize"> <b>{this.state.data && this.state.data.in_good_health}</b> </Typography>

                        <Typography variant="body1" className="mt-3"> Are you a member of any armed forces ? </Typography>
                        <Typography variant="body1" className="text-capitalize"> <b>{this.state.data && this.state.data.is_armed_member}</b> </Typography>
                      </div>
                    }
                    {
                      (
                        this.state.data &&
                        (this.state.data.insurance_type === "life" || this.state.data.has_li_addon) &&
                        (this.state.data.li_addon_type === "Personal Accident" || this.state.data.plan === "Personal Accident")
                      ) &&
                      <div className="mt-4">
                        {
                          Array.isArray(PolicyService.personal_accident_questions.questions) &&
                          PolicyService.personal_accident_questions.questions.map((question, ind) => (<div key={ind}>
                            <Typography variant="body1">
                              {
                                Array.isArray(question.question) && question.question.map((content, ind) => (
                                  <React.Fragment key={ind}>
                                    {FormattedFunctionMessage(content)}
                                    <br />
                                  </React.Fragment>
                                ))
                              }
                            </Typography>
                            <Typography variant="body1" className="text-capitalize"> <b>{this.state.data && this.state.data[question.session_storage_key]}</b> </Typography>
                          </div>))
                        }

                      </div>
                    }
                    {
                      (this.state.insurance_type === "travel" && this.state.data.plan !== "inbound_travel") &&
                      <div className="mt-4">
                        <Typography variant="body1"> There are no actual circumstances with the travel that render it abnormal </Typography>
                        <Typography variant="body1" className="text-capitalize"> <b>{this.state.data && this.state.data.no_abnormal_travel_circumstances}</b> </Typography>

                        <Typography variant="body1" className="mt-3"> All persons to be insured are in good health and free from any physical defect at the time of effecting this insurance </Typography>
                        <Typography variant="body1" className="text-capitalize"> <b>{this.state.data && this.state.data.in_good_health}</b> </Typography>
                      </div>
                    }

                    {
                      this.state.insurance_type === "home" &&
                      <div className="mt-4">
                        <Typography variant="body1"> Has this insurance been declined before ? </Typography>
                        <Typography variant="body1" className="text-capitalize"> <b> No </b> </Typography>

                        <Typography variant="body1" className="mt-3"> Has this insurance been cancelled by other insurance company ? </Typography>
                        <Typography variant="body1" className="text-capitalize"> <b> No </b> </Typography>

                        <Typography variant="body1" className="mt-3"> Does this insurance have any special Conditions imposed ? </Typography>
                        <Typography variant="body1" className="text-capitalize"> <b> No </b> </Typography>
                      </div>
                    }

                    {
                      this.state.insurance_type === "riyati" &&
                      <div className="mt-4">
                        <Typography variant="body1"> Are you under any medical observation/undergoing any medical/ surgical/ treatment or have been advised for the same? </Typography>
                        <Typography variant="body1" className="text-capitalize"> <b> {this.state.data && this.state.data.underMedicalObservation} </b> </Typography>

                        <Typography variant="body1" className="mt-3"> Do you have any chronic illness? </Typography>
                        <Typography variant="body1" className="text-capitalize"> <b> {this.state.data && this.state.data.anyChronicIllness} </b> </Typography>

                        <Typography variant="body1" className="mt-3"> Are you taking any medication (pharmaceutical/alternative medicine) or have been advised? </Typography>
                        <Typography variant="body1" className="text-capitalize"> <b> {this.state.data && this.state.data.takingAnyMedication} </b> </Typography>

                        <Typography variant="body1" className="mt-3"> Do you have any physical problems/ disability for which you are undergoing physiotherapy or have been advised for? </Typography>
                        <Typography variant="body1" className="text-capitalize"> <b> {this.state.data && this.state.data.anyPhysicalProblems} </b> </Typography>

                        <Typography variant="body1" className="mt-3"> Is there any other medical condition or disorder or any symptoms that you should be declared, and you are unable to relate to the above-mentioned Questions? </Typography>
                        <Typography variant="body1" className="text-capitalize"> <b> {this.state.data && this.state.data.anyOtherMedicalConditionOrDisorder} </b> </Typography>
                      </div>
                    }
                  </Paper>
                }

                {
                  (this.state.data && (this.state.data.insurance_type === "life" || this.state.data.has_li_addon)) &&
                  <Paper className="pt-3 pb-3 pr-2 pl-2 mt-3 sq-shadow">
                    <Typography variant="h5" className="pb-2">
                      Life Insurance Information
                    </Typography>
                    <Divider />
                    <div className="mt-4">
                      <Typography variant="body1"> Sum Assured </Typography>
                      <Typography variant="body1" className="text-capitalize"> <b>{this.state.data && this.state.data.sum_assured} OMR</b> </Typography>
                      <Typography variant="body1" className="mt-3"> Beneficiaries </Typography>
                      <table className="table table-striped table-hover">
                        <thead>
                          <tr>
                            <th>
                              <Typography variant="body1"> # </Typography>
                            </th>
                            <th>
                              <Typography variant="body1"> Name </Typography>
                            </th>
                            <th>
                              <Typography variant="body1"> Relation </Typography>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            (this.state.data.beneficiaries && Array.isArray(this.state.data.beneficiaries)) ? this.state.data.beneficiaries.map((beneficiary, ind) =>
                              <tr key={ind}>
                                <td>
                                  <Typography variant="body1"> {ind + 1} </Typography>
                                </td>
                                <td>
                                  <Typography variant="body1"> {beneficiary.name} </Typography>
                                </td>
                                <td>
                                  <Typography variant="body1"> {beneficiary.relation} </Typography>
                                </td>
                              </tr>
                            ) : <React.Fragment></React.Fragment>
                          }
                        </tbody>
                      </table>
                    </div>
                  </Paper>
                }

                {/* {
                  (this.state.insurance_type === "travel" && this.state.data && this.state.data.travel_countries && Array.isArray(this.state.data.travel_countries) && this.state.data.travel_countries.length > 1) &&
                  <Paper className="px-2 py-3 mt-3 sq-shadow">
                    <Typography variant="h5" className="pb-2">
                      Travel Countries
                    </Typography>
                    <Divider />
                    <div className="pt-3">
                      {
                        this.state.data.travel_countries.map((country, ind) =>
                          <Typography variant="body1" className="travel-country d-flex align-items-center" key={ind}>
                            {country}
                          </Typography>
                        )
                      }
                    </div>
                  </Paper>
                } */}

                {
                  this.state.insurance_type === "home" &&
                  <React.Fragment>
                    <Paper className="px-2 py-3 mt-3 sq-shadow">
                      <Typography variant="h5" className="pb-2">
                        Cover Details
                      </Typography>
                      <Divider />

                      <Typography variant="body1" className="mt-3"> Ownership Type </Typography>
                      <Typography variant="body1" className="text-capitalize"> <b> {this.state.data.homeOwnerType} </b> </Typography>

                      <table className="table table-bordered mt-3">
                        <thead>
                          <tr>
                            <th> <Typography variant="body1"> Section Name </Typography> </th>
                            <th> <Typography variant="body1"> Required </Typography> </th>
                            <th> <Typography variant="body1"> Sum Assured </Typography> </th>
                          </tr>
                        </thead>

                        <tbody>
                          <tr>
                            <td> <Typography variant="body1"> Section 1 </Typography> </td>
                            <td> <Typography variant="body1"> {this.state.data.homeCoverageInformation.section_1.selected ? "Yes" : "No"} </Typography> </td>
                            <td> <Typography variant="body1"> {this.state.data.homeCoverageInformation.section_1.sum_assured || "- -"} </Typography> </td>
                          </tr>
                          <tr>
                            <td> <Typography variant="body1"> Section 2 </Typography> </td>
                            <td> <Typography variant="body1"> {this.state.data.homeCoverageInformation.section_2.selected ? "Yes" : "No"} </Typography> </td>
                            <td> <Typography variant="body1"> {this.state.data.homeCoverageInformation.section_2.sum_assured || "- -"} </Typography> </td>
                          </tr>
                          <tr>
                            <td> <Typography variant="body1"> Section 3 </Typography> </td>
                            <td> <Typography variant="body1"> {this.state.data.homeCoverageInformation.section_3.selected ? "Yes" : "No"} </Typography> </td>
                            <td> <Typography variant="body1"> {this.state.data.homeCoverageInformation.section_3.sum_assured} </Typography> </td>
                          </tr>
                          <tr>
                            <td> <Typography variant="body1"> Section 4 </Typography> </td>
                            <td> <Typography variant="body1"> {this.state.data.homeCoverageInformation.section_4.selected ? "Yes" : "No"} </Typography> </td>
                            <td> <Typography variant="body1"> {this.state.data.homeCoverageInformation.section_4.sum_assured || "- -"} </Typography> </td>
                          </tr>
                          <tr>
                            <td> <Typography variant="body1"> Section 5 </Typography> </td>
                            <td> <Typography variant="body1"> {this.state.data.homeCoverageInformation.section_5.selected ? "Yes" : "No"} </Typography> </td>
                            <td> <Typography variant="body1"> {this.state.data.homeCoverageInformation.section_5.sum_assured || "- -"} </Typography> </td>
                          </tr>
                          <tr>
                            <td> <Typography variant="body1"> Section 6 </Typography> </td>
                            <td> <Typography variant="body1"> {this.state.data.homeCoverageInformation.section_6.selected ? "Yes" : "No"} </Typography> </td>
                            <td> <Typography variant="body1"> {this.state.data.homeCoverageInformation.section_6.sum_assured || "- -"} </Typography> </td>
                          </tr>
                          <tr>
                            <td> <Typography variant="body1"> Section 7 </Typography> </td>
                            <td> <Typography variant="body1"> {this.state.data.homeCoverageInformation.section_7.selected ? "Yes" : "No"} </Typography> </td>
                            <td> <Typography variant="body1"> {this.state.data.homeCoverageInformation.section_7.sum_assured || "- -"} </Typography> </td>
                          </tr>
                          <tr>
                            <td> <Typography variant="body1"> Section 8 </Typography> </td>
                            <td> <Typography variant="body1"> {this.state.data.homeCoverageInformation.section_8.selected ? "Yes" : "No"} </Typography> </td>
                            <td> <Typography variant="body1"> {this.state.data.homeCoverageInformation.section_8.sum_assured || "- -"} </Typography> </td>
                          </tr>
                        </tbody>
                      </table>

                    </Paper>
                    <Paper className="px-2 py-3 mt-3 sq-shadow">
                      <Typography variant="h5" className="pb-2">
                        Photographs of the property
                      </Typography>
                      <Divider />

                      <table className="table table-bordered mt-3">
                        <thead>
                          {
                            Array.isArray(this.state.data.photographsOfPropertyFiles) ?
                              this.state.data.photographsOfPropertyFiles.map((photo, ind) => {
                                return <tr key={ind}>
                                  <th className="d-flex justify-content-between">
                                    <Typography variant="body1"> {photo.name}</Typography>
                                    <Button variant="contained" color="primary" className="ml-3" onClick={e => { this.downloadHomePropertyPhoto(photo) }}>
                                      <GetApp />
                                    </Button>
                                  </th>
                                </tr>
                              }) : <tr><th>Not found.</th></tr>
                          }

                        </thead>
                      </table>

                    </Paper>
                  </React.Fragment>
                }

                {
                  (this.state.data && this.state.data.affiliate_name) &&
                  <Paper className="pt-3 pb-3 pr-2 pl-2 mt-3 sq-shadow">
                    <Typography variant="h5" className="pb-2">
                      Center Details
                    </Typography>
                    <Divider />
                    <table className="mt-4">
                      <tbody>
                        <tr>
                          <td>
                            <Typography variant="body1"> Center Name </Typography>
                          </td>
                          <td> &nbsp; - &nbsp; </td>
                          <td>
                            <Typography variant="body1" className="text-capitalize"> {this.state.data.affiliate_name} </Typography>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </Paper>
                }

              </Grid>
              <Grid item xs={12} md={4}>
                <Paper className="pt-3 pb-3 pr-2 pl-2 sq-shadow">
                  <Typography variant="h5" className="pb-2">
                    Documents Submitted
                  </Typography>
                  <Divider />
                  <List>
                    {
                      this.state.data && this.state.data.submittedDocuments.map((document, ind) =>
                        <ListItem button dense onClick={() => this.handleItemClick(document)} key={ind}>
                          <ListItemIcon className="documents-list-icon">
                            <Checkbox
                              edge="start"
                              disableRipple
                              checked={this.state.items.includes(document)}
                            />
                          </ListItemIcon>
                          <Typography variant="body1"> {document} </Typography>
                        </ListItem>
                      )
                    }
                    <ListItem className="mt-3">
                      <Button variant="contained" color="primary" onClick={this.addAllItems}>
                        Select All
                      </Button>
                      <Button variant="contained" color="primary" className="ml-3" disabled={this.state.items.length === 0} onClick={this.downloadFiles}>
                        <GetApp /> Download
                      </Button>
                    </ListItem>
                  </List>
                </Paper>

                {
                  (this.state.data && this.state.data.payments && this.state.data.payments.isPaid) &&
                  <Paper className="pt-3 pb-3 pr-2 pl-2 mt-3 sq-shadow">
                    <Typography variant="h5" className="pb-2">
                      Premium Details
                    </Typography>
                    <Divider />
                    {
                      this.state.data.has_li_addon &&
                      <Typography variant="body1" className="py-1 border-bottom">
                        Medical
                      </Typography>
                    }
                    <table className="w-100">
                      <tbody>
                        {
                          this.state.data.amount.discount_amount > 0 &&
                          <React.Fragment>
                            <tr colSpan="2">
                              <td>
                                <Typography variant="body1"> <strong>Applied Discount</strong> </Typography>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <Typography variant="body1"> Basic Premium </Typography>
                              </td>
                              <td> &nbsp; - &nbsp; </td>
                              <td>
                                <Typography variant="body1" className="text-capitalize"> {Number(this.state.data.amount.before_discount_base_price).toFixed(3)} OMR </Typography>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <Typography variant="body1"> Discount  ({this.state.data.amount?.discount_details?.code})</Typography>
                              </td>
                              <td> &nbsp; - &nbsp; </td>
                              <td>
                                <Typography variant="body1" className="text-capitalize"> -{this.state.data.amount.discount_amount} OMR </Typography>
                              </td>
                            </tr>
                          </React.Fragment>
                        }

                        <tr className="border-top">
                          <td>
                            <Typography variant="body1"> Basic Premium </Typography>
                          </td>
                          <td> &nbsp; - &nbsp; </td>
                          <td>
                            <Typography variant="body1" className="text-capitalize"> {Number(this.state.data.amount.base_price).toFixed(3)} OMR </Typography>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <Typography variant="body1"> Policy Fee </Typography>
                          </td>
                          <td> &nbsp; - &nbsp; </td>
                          <td>
                            <Typography variant="body1" className="text-capitalize"> {this.state.data.amount.policy_fee} OMR </Typography>
                          </td>
                        </tr>
                        {
                          this.state.data.amount.death_cover &&
                          <tr>
                            <td>
                              <Typography variant="body1"> Death Cover </Typography>
                            </td>
                            <td> &nbsp; - &nbsp; </td>
                            <td>
                              <Typography variant="body1" className="text-capitalize"> {this.state.data.amount.death_cover} OMR </Typography>
                            </td>
                          </tr>
                        }
                        <tr>
                          <td>
                            <Typography variant="body1"> Government Fee </Typography>
                          </td>
                          <td> &nbsp; - &nbsp; </td>
                          <td>
                            <Typography variant="body1" className="text-capitalize"> {this.state.data.amount.government_fee} OMR </Typography>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <Typography variant="body1"> Emergency Fund Fee </Typography>
                          </td>
                          <td> &nbsp; - &nbsp; </td>
                          <td>
                            <Typography variant="body1" className="text-capitalize"> {this.state.data.amount.emergency_fund_fee} OMR </Typography>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <Typography variant="body1"> BMI Loading </Typography>
                          </td>
                          <td> &nbsp; - &nbsp; </td>
                          <td>
                            <Typography variant="body1" className="text-capitalize"> {this.state.data.amount.bmi_value} OMR </Typography>
                          </td>
                        </tr>
                        {
                          this.state.data.has_li_addon &&
                          <>
                            <tr>
                              <td colSpan="3" className="border-top border-bottom">
                                <Typography variant="body1"> Life </Typography>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <Typography variant="body1"> Basic Premuim </Typography>
                              </td>
                              <td> &nbsp; - &nbsp; </td>
                              <td>
                                <Typography variant="body1" className="text-capitalize"> {this.state.data.amount.life_base_amount} OMR </Typography>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <Typography variant="body1"> Policy Fee </Typography>
                              </td>
                              <td> &nbsp; - &nbsp; </td>
                              <td>
                                <Typography variant="body1" className="text-capitalize"> {this.state.data.amount.life_policy_fee} OMR </Typography>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <Typography variant="body1"> Government Fee </Typography>
                              </td>
                              <td> &nbsp; - &nbsp; </td>
                              <td>
                                <Typography variant="body1" className="text-capitalize"> {this.state.data.amount.life_government_fee} OMR </Typography>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <Typography variant="body1"> Emergency Fund Fee </Typography>
                              </td>
                              <td> &nbsp; - &nbsp; </td>
                              <td>
                                <Typography variant="body1" className="text-capitalize"> {this.state.data.amount.life_emergency_fund_fee} OMR </Typography>
                              </td>
                            </tr>
                          </>
                        }
                        <tr className="border-top">
                          <td>
                            <Typography variant="body1"> VAT </Typography>
                          </td>
                          <td> &nbsp; - &nbsp; </td>
                          <td>
                            <Typography variant="body1"> {this.state.data.amount.vat || 0} OMR </Typography>
                          </td>
                        </tr>
                        {
                          this.state.data.amount &&
                          <tr className="border-top">
                            <td>
                              <Typography variant="body1"> Round Off </Typography>
                            </td>
                            <td> &nbsp; - &nbsp; </td>
                            <td>
                              <Typography variant="body1"> {utils.getRoundOffValue(this.state.data) || 0} OMR </Typography>
                            </td>
                          </tr>
                        }
                        <tr className="border-top">
                          <td>
                            <Typography variant="body1"> Total </Typography>
                          </td>
                          <td> &nbsp; - &nbsp; </td>
                          <td>
                            <Typography variant="body1"> {this.state.data.amount.total} OMR </Typography>
                          </td>
                        </tr>
                      </tbody>
                    </table>

                    {
                      (this.state.data && this.state.data.payments.isPaid && !this.state.data.payments.isRefunded && this.state.data.payments.mode !== "Cash") &&
                      <Button variant="contained" className="mt-3 bg-primary text-white" onClick={this.handleRefund} disabled={this.state.isRefunding}>
                        {this.state.isRefunding ? "Refunding . . ." : "Refund"}
                      </Button>
                    }
                  </Paper>
                }


                {
                  this.state.insurance_type === "home" &&
                  <Paper className="py-3 px-2 mt-3 sq-shadow">
                    <Typography variant="h5" className="pb-2">
                      Building Details
                    </Typography>
                    <Divider />

                    <Typography variant="body1" className="mt-3"> Business or Occupation of the Insured </Typography>
                    <Typography variant="body1" className="text-capitalize"> <b> {this.state.data.buildingInformation.business_of_insured} </b> </Typography>

                    <Typography variant="body1" className="mt-3"> Age of the Building </Typography>
                    <Typography variant="body1" className="text-capitalize"> <b> {this.state.data.buildingInformation.age_of_building} </b> </Typography>

                    <Typography variant="body1" className="mt-3"> Type of Building </Typography>
                    <Typography variant="body1" className="text-capitalize"> <b> {this.state.data.buildingInformation.type_of_building} </b> </Typography>

                    <Typography variant="body1" className="mt-3"> On which floor/ story of the building is the premises located </Typography>
                    <Typography variant="body1" className="text-capitalize"> <b> {this.state.data.buildingInformation.floor_no} </b> </Typography>

                    <Typography variant="body1" className="mt-3"> Is your property in the basement </Typography>
                    <Typography variant="body1" className="text-capitalize"> <b> {this.state.data.buildingInformation.is_property_in_basement} </b> </Typography>

                    <Typography variant="body1" className="mt-3"> Is the whole building used for residential purposes only? </Typography>
                    <Typography variant="body1" className="text-capitalize"> <b> {this.state.data.buildingInformation.is_whole_building_residential} </b> </Typography>
                    {
                      this.state.data.buildingInformation.is_whole_building_residential === "No" &&
                      <Typography variant="body1" className="text-capitalize"> <b> {this.state.data.buildingInformation.not_residential_reason} </b> </Typography>
                    }

                    <Typography variant="body1" className="mt-3"> Address of Property to be Insured </Typography>
                    <Typography variant="body1" className="text-capitalize"> <b> {this.state.data.buildingInformation.address} </b> </Typography>

                    <Typography variant="body1" className="mt-3"> Type of Fire protection devices installed in premises </Typography>

                    <Typography variant="body1" className="mt-3 border-top border-bottom"> Fire Alarm Systems </Typography>
                    <Typography variant="body1" className="text-capitalize"> <b> {this.state.data.buildingInformation.have_fire_alarm_system} </b> </Typography>

                    <Typography variant="body1" className="mt-3"> Portable Extinguishers </Typography>
                    <Typography variant="body1" className="text-capitalize"> <b> {this.state.data.buildingInformation.have_portable_extinguishers} </b> </Typography>

                    <Typography variant="body1" className="mt-3"> Hydrant System </Typography>
                    <Typography variant="body1" className="text-capitalize"> <b> {this.state.data.buildingInformation.have_hydrant_system} </b> </Typography>

                    <Typography variant="body1" className="mt-3"> Other </Typography>
                    <Typography variant="body1" className="text-capitalize"> <b> {this.state.data.buildingInformation.other_fire_protection} </b> </Typography>

                    <Typography variant="body1" className="mt-3 border-top border-bottom"> Security systems provided in the premises </Typography>

                    <Typography variant="body1" className="mt-3"> Surveillance Camera </Typography>
                    <Typography variant="body1" className="text-capitalize"> <b> {this.state.data.buildingInformation.have_surveillance_camera} </b> </Typography>

                    <Typography variant="body1" className="mt-3"> Burglar Alarm System </Typography>
                    <Typography variant="body1" className="text-capitalize"> <b> {this.state.data.buildingInformation.have_burglar_alarm_system} </b> </Typography>

                    <Typography variant="body1" className="mt-3"> Grilled Doors </Typography>
                    <Typography variant="body1" className="text-capitalize"> <b> {this.state.data.buildingInformation.have_grilled_doors} </b> </Typography>

                    <Typography variant="body1" className="mt-3"> 24 Hours watchman services </Typography>
                    <Typography variant="body1" className="text-capitalize"> <b> {this.state.data.buildingInformation.have_watchman_service} </b> </Typography>

                    <Typography variant="body1" className="mt-3"> Security Check point </Typography>
                    <Typography variant="body1" className="text-capitalize"> <b> {this.state.data.buildingInformation.have_security_checkpoint} </b> </Typography>

                    <Typography variant="body1" className="mt-3 border-bottom"> Other </Typography>
                    <Typography variant="body1" className="text-capitalize"> <b> {this.state.data.buildingInformation.other_security_system} </b> </Typography>

                    <Typography variant="body1" className="mt-3"> Previous Insurance Details </Typography>
                    <Typography variant="body1" className="text-capitalize"> <b> {this.state.data.buildingInformation.previous_insurance_details} </b> </Typography>

                    <Typography variant="body1" className="mt-3"> Photographs of the property attached </Typography>
                    <Typography variant="body1" className="text-capitalize"> <b> {this.state.data.buildingInformation.have_photographs_of_property_attached} </b> </Typography>

                  </Paper>
                }
              </Grid>
              <Grid item xs={12} md={4}>
                {
                  this.state.data && this.state.data.pendingDocuments?.length > 0 &&
                  <Paper className="pt-3 pb-3 pr-2 pl-2 sq-shadow mb-3">
                    <Typography variant="h5" className="pb-2">
                      Documents Pending
                    </Typography>
                    <Divider />
                    <List>
                      {
                        this.state.data.pendingDocuments.map((doc, ind) =>
                          <ListItem key={ind} className="mb-2 border">
                            <Typography variant="body1"> {doc} </Typography>
                            <ListItemSecondaryAction>
                              <label className="mb-0">
                                <input type="file" className="d-none" accept="application/pdf" onChange={(e) => this.handleFileChange(doc, e)} />
                                <Button component="span" variant="contained" size="small" color="primary">Upload</Button>
                              </label>
                            </ListItemSecondaryAction>
                          </ListItem>
                        )
                      }

                      <ListItem className="mt-3">
                        <Button variant="contained" color="primary" onClick={this.downloadContactDetails}>
                          <GetApp /> Download Contact Details
                        </Button>
                      </ListItem>

                    </List>
                  </Paper>
                }
                <Paper className="pt-3 pb-3 pr-2 pl-2 sq-shadow mb-3">
                  <Typography variant="h5" className="pb-2">
                    Comment Box
                  </Typography>

                  <TextField
                    variant="outlined"
                    fullWidth
                    multiline
                    rows="10"
                    value={this.state.comment}
                    onChange={(e) => this.setState({ comment: e.target.value })}
                  />

                  <Button onClick={this.saveComment} variant="contained" className="mt-3" color="primary" disabled={this.comparePolicyComment() || this.state.commentProcessing}>
                    {this.state.commentProcessing ? "Processing..." : "Save"}
                  </Button>
                </Paper>
                {/* {
                  (
                    this.state.data &&
                    (
                      !this.state.data.payments ||
                      ( this.state.data.payments && !this.state.data.payments.isPaid )
                    )
                  ) &&
                  <Paper className="pt-3 pb-3 pr-2 pl-2 sq-shadow">
                    <Typography variant="h5" className="pb-2">
                      Payment Link
                    </Typography>
                    <Divider />
                    <Typography className="mt-3">
                      <a href={`${config.lp_link}/payment/${this.state.policy_id}`} target="_blank" rel="noopener noreferrer">
                        {`${config.lp_link}/payment/${this.state.policy_id}`}
                      </a>
                    </Typography>
                  </Paper>
                } */}
              </Grid>
              {
                ((this.state.data && this.state.insurance_type === "riyati" && this.state.data.riyati_plan_type === "family") ||
                  (this.state.data && this.state.insurance_type === "travel" && this.state.planType == "Family")) &&
                <Grid xs={12} md={12} className="mt-2 sq-shadow">

                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell colSpan={7}><Typography variant="h5">{this.state.data && this.state.insurance_type === "travel" && this.state.planType == "Family" ? "Travelers Details" : "Family Details"}</Typography></TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Name</TableCell>
                          <TableCell>Age</TableCell>
                          <TableCell>Relation</TableCell>
                          <TableCell align="right">Date of Birth</TableCell>
                          <TableCell align="right">Passport Number</TableCell>
                        </TableRow>
                      </TableHead>
                      {this.state.data && this.state.insurance_type === "riyati" && this.state.data.riyati_plan_type === "family" &&
                        <TableBody>
                          {
                            this.state.data.family_members.map((doc, ind) => (
                              <TableRow key={ind}>
                                <TableCell>{doc.name}</TableCell>
                                <TableCell>{doc.age}</TableCell>
                                <TableCell>{doc.relation}</TableCell>
                                <TableCell align="right">{doc.dob}</TableCell>
                                <TableCell align="right">{doc.passport_no}</TableCell>

                              </TableRow>
                            ))
                          }

                        </TableBody>
                      }
                      {this.state.data && this.state.insurance_type === "travel" && this.state.planType == "Family" &&
                        <TableBody>
                          {
                            this.state.data.travellers.map((doc, ind) => (
                              <TableRow key={ind}>
                                <TableCell>{doc.name}</TableCell>
                                <TableCell>{doc.age}</TableCell>
                                <TableCell>{doc.relation}</TableCell>
                                <TableCell align="right">{doc.dob}</TableCell>
                                <TableCell align="right">{doc.passport_no}</TableCell>

                              </TableRow>
                            ))
                          }

                        </TableBody>
                      }

                    </Table>
                  </TableContainer>
                </Grid>
              }
              {
                this.state.data && this.state.planType == "inbound_travel" &&
                <Grid xs={12} md={12} className="mt-2 sq-shadow">

                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell colSpan={8}><Typography variant="h5">Travelers Details</Typography></TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>First Name</TableCell>
                          <TableCell>Last Name</TableCell>
                          <TableCell>Gender</TableCell>
                          <TableCell>Relation</TableCell>
                          <TableCell align="right">Date of Birth</TableCell>
                          <TableCell align="right">Mobile Number</TableCell>
                          <TableCell align="right">Passport Number</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {
                          this.state.data.travellers && Array.isArray(this.state.data.travellers) && this.state.data.travellers.map((doc, ind) => (
                            <TableRow key={ind}>
                              <TableCell>{doc.first_name}</TableCell>
                              <TableCell>{doc.last_name}</TableCell>
                              <TableCell>{doc.inbound_gender_detail}</TableCell>
                              <TableCell>{doc.inbound_relation_detail}</TableCell>
                              <TableCell align="right">{doc.dob}</TableCell>
                              <TableCell align="right">{doc.mobile_no}</TableCell>
                              <TableCell align="right">{doc.passport_no}</TableCell>

                            </TableRow>
                          ))
                        }
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              }

              <div className="w-100"></div>
              {
                this.state.data && this.state.data.images.map((image, ind) =>
                  <Grid item xs={12} md={4} key={ind}>
                    <Paper className="p-2 sq-shadow">
                      <Typography variant="body2" className="pb-2 border-bottom d-flex align-items-center justify-content-between">
                        <span>{image.title}</span>
                        {
                          image.uploaded_at &&
                          <span>{this.getFormattedUploadDate(image.uploaded_at)}</span>
                        }
                      </Typography>
                      <div className="d-flex justify-content-between mt-2">
                        <Button variant="contained" color="primary" className="text-white" onClick={() => this.downloadAuthFile(image)}>
                          <GetApp />
                        </Button>
                        <Button variant="contained" className="text-white bg-danger" onClick={() => this.deleteFile(image)}>
                          <Delete />
                        </Button>
                      </div>
                    </Paper>
                  </Grid>
                )
              }
              {
                this.state.permissions.includes(PERMISSIONS.CHANGE_POLICY_STATUS) &&
                <Grid item xs={12}>
                  {
                    this.state.data && this.state.data.status !== "Active" &&
                    <Button
                      variant="contained"
                      className={`mr-3 text-white bg-success`}
                      onClick={() => this.handleStatusChange("Active")}
                    >
                      Activate
                    </Button>
                  }
                  {
                    this.state.data && this.state.data.status !== "Suspended" &&
                    <Button variant="contained" className="mr-3 bg-warning text-white" onClick={() => this.handleStatusChange("Suspended")}>
                      Suspend
                    </Button>
                  }
                  {
                    this.state.data && this.state.data.status !== "Rejected" &&
                    <Button variant="contained" className="mr-3 bg-danger text-white" onClick={() => this.handleStatusChange("Rejected")}>
                      Reject
                    </Button>
                  }
                  {
                    this.state.data && this.state.data.status !== "Cancelled" &&
                    <Button variant="contained" className="mr-3 bg-danger text-white" disabled={this.state.processing} onClick={() => this.handleStatusChange("Cancelled")}>
                      Cancel
                    </Button>
                  }
                </Grid>
              }
            </Grid>
          }
        </div>

        <Dialog
          open={this.state.showDeleteDocumentDialog}
        >
          <DialogTitle> Are you sure you want to delete this document ? </DialogTitle>
          <DialogContent>
            {this.state.selectedDocument}
          </DialogContent>
          <DialogActions>
            <Button variant="outlined" color="primary" onClick={this.handleCloseDeleteDialog} disabled={this.state.isDeletingDocument}>
              Cancel
            </Button>
            <Button variant="contained" color="primary" onClick={this.handleDeleteDocument} disabled={this.state.isDeletingDocument}>
              {this.state.isDeletingDocument ? "Deleting . . ." : "Yes, Delete"}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}
export default withRouter(Policy);