import React from "react";
import { AppBar, Toolbar, IconButton, Typography, Drawer, Divider, List, ListItem, ListItemText, ListItemIcon, Tooltip } from "@material-ui/core";
import { Menu, ChevronLeft, LocalHospital, DescriptionOutlined, ExitToApp, Settings, HomeOutlined, Security, DirectionsWalk, HomeWork, CropRounded, ReplayOutlined, Group, Assessment, CreditCard, MoneyOffRounded,FlightLand, FlightTakeoff, DirectionsCar, PeopleOutline } from "@material-ui/icons";
import "./style.css";
import { NavLink, withRouter } from "react-router-dom";
import AuthService from "../../../services/auth";
import AxiosInterceptor from "../../../services/interceptor";
import Snackbar from "../Snackbar";
import RiyatiIcon from "../../../static/images/riyati.svg";
import _ from "lodash";
import { PERMISSIONS } from "../../../constants";

class Navigation extends React.Component {
  state = {
    navbarOpen: false,
    showCenterLinks: true,
    center: "",
    isCenterSelected: false,
    showSnackbar: false,
    messageType: "",
    snackBarMessage: "",
    permissions : localStorage.getItem("permissions") ? JSON.parse(localStorage.getItem("permissions")) : []
  }

  componentDidMount() {

    AxiosInterceptor(this.props.history, this.showAuthSnackbar);

    this.checkLogin();
    const center = sessionStorage.getItem("center_selected");
    const isCenterSelected = Boolean(sessionStorage.getItem("center_id"));

    this.setState({
      showCenterLinks: true,
      isCenterSelected
    });
    
    window.addEventListener("storage", () => {
      const permissions = localStorage.getItem("permissions") ? JSON.parse(localStorage.getItem("permissions")) : []
      this.setState({ permissions })
    })
  }
  
  handleLogout = () => {
    AuthService.logout();
    const {host, protocol} = window.location;
    const path = `${protocol}//${host}/login`;
    window.location.href = path;
      // this.props.history.push("/login");
  }

  checkLogin = async () => {
    try {
      await AuthService.isLoggedIn();
    }
    catch (e) {
      this.handleLogout();
    }
  }

  removeCenterDetails = () => {
    // sessionStorage.removeItem("center_selected");
    // sessionStorage.removeItem("center_id");
  }

  handleCloseSnackbar = () => {
    this.setState({ showSnackbar: false, snackBarMessage: "", messageType: "" });
  }

  showAuthSnackbar = () => {
    this.setState({ showSnackbar: true, snackBarMessage: "Session Expired ! Please login again", messageType: "danger" });
  }

  render() {
    const pathname = this.props.history.location.pathname;
    const permissions = this.state.permissions;

    return (
      <>
        <AppBar
          position="fixed"
          className={`top-bar ${this.state.navbarOpen ? "top-bar-open" : ""}`}
        >
          <Toolbar>
            {
              !this.state.navbarOpen &&
              <IconButton
                color="inherit"
                edge="start"
                onClick={() => this.setState({ navbarOpen: true })}
              >
                <Menu />
              </IconButton>
            }
            <Typography variant="h6" noWrap>
              Super Admin
            </Typography>
          </Toolbar>
        </AppBar>
        <Drawer
          variant="permanent"
          className={`drawer ${this.state.navbarOpen ? "drawer-open" : ""}`}
          open={true}
          classes={{
            paper: `drawer ${this.state.navbarOpen ? "drawer-open" : ""}`,
          }}
        >
          <div className="nav-panel-top d-flex align-items-center justify-content-end">
            <IconButton onClick={() => this.setState({ navbarOpen: false })}>
              <ChevronLeft />
            </IconButton>
          </div>
          <Divider />
          <List>
            <NavLink to={`/home`} className="navpanel-link">
              <Tooltip title="Home" placement="right">
                <ListItem selected={pathname === "/home"} button >
                  <ListItemIcon className="nav-icon"><HomeOutlined color={pathname === "/home" ? "primary" : "action"} /></ListItemIcon>
                  <ListItemText primary={"Home"} />
                </ListItem>
              </Tooltip>
            </NavLink>

            {
              permissions.includes(PERMISSIONS.VIEW_AFFILIATES) &&
              <NavLink to="/affiliator" className="navpanel-link" onClick={this.removeCenterDetails}>
                <Tooltip title="Affiliates" placement="right">
                  <ListItem selected={pathname.includes("/affiliator") || pathname.includes("/affiliates")} button >
                    <ListItemIcon className="nav-icon"><Group color={(pathname.includes("/affiliator") || pathname.includes("/affiliates")) ? "primary" : "action"} /></ListItemIcon>
                    <ListItemText primary={"Affiliates"} />
                  </ListItem>
                </Tooltip>
              </NavLink>
            }
            {
              permissions.includes(PERMISSIONS.MODIFY_PREMIUMS) &&
              <NavLink to="/modify-premiums" className="navpanel-link" onClick={this.removeCenterDetails}>
                <Tooltip title="Modify Premium" placement="right">
                  <ListItem selected={pathname === "/modify-premiums"} button >
                    <ListItemIcon className="nav-icon"><CropRounded color={pathname === "/modify-premiums" ? "primary" : "action"} /></ListItemIcon>
                    <ListItemText primary={"Modify Premium"} />
                  </ListItem>
                </Tooltip>
              </NavLink>
            }
            {
              permissions.includes(PERMISSIONS.VIEW_DISCOUNT_CODES) &&
              <NavLink to="/discounts" className="navpanel-link" onClick={this.removeCenterDetails}>
                <Tooltip title="Discounts" placement="right">
                  <ListItem selected={pathname.includes("/discounts")} button >
                    <ListItemIcon className="nav-icon"><MoneyOffRounded color={pathname.includes("/discounts") ? "primary" : "action"} /></ListItemIcon>
                    <ListItemText primary={"Discounts"} />
                  </ListItem>
                </Tooltip>
              </NavLink>
            }
          </List>
          <Divider />
          <List>
            {
              permissions.includes(PERMISSIONS.VIEW_POLICIES) &&
              <NavLink to={`/policies`} className="navpanel-link">
                <Tooltip title="Policies" placement="right">
                  <ListItem selected={pathname.includes("/policies")} button >
                    <ListItemIcon className="nav-icon"><DescriptionOutlined color={pathname.includes("/policies") ? "primary" : "action"} /></ListItemIcon>
                    <ListItemText primary={"Policies"} />
                  </ListItem>
                </Tooltip>
              </NavLink>
            }
            {
              permissions.includes(PERMISSIONS.VIEW_REFERAL_POLICIES) &&
              <NavLink to={`/refer-policies`} className="navpanel-link">
                <Tooltip title="Refer Policies" placement="right">
                  <ListItem selected={pathname.includes("/refer-policies")} button >
                    <ListItemIcon className="nav-icon"><ReplayOutlined color={pathname.includes("/refer-policies") ? "primary" : "action"} /></ListItemIcon>
                    <ListItemText primary={"Refer Policies"} />
                  </ListItem>
                </Tooltip>
              </NavLink>
            }
            {
              permissions.includes(PERMISSIONS.VIEW_CONSOLIDATED_REPORT) &&
              <NavLink to={`/consolidate-report`} className="navpanel-link">
                <Tooltip title="Consolidate Report" placement="right">
                  <ListItem selected={pathname.includes("/consolidate-report")} button >
                    <ListItemIcon className="nav-icon"><Assessment color={pathname.includes("/consolidate-report") ? "primary" : "action"} /></ListItemIcon>
                    <ListItemText primary={"Consolidate Report"} />
                  </ListItem>
                </Tooltip>
              </NavLink>
            }
            {
              permissions.includes(PERMISSIONS.VIEW_INDIVIDUAL_MEDICAL_REPORT) &&
              <NavLink to={`/individual-medical-report`} className="navpanel-link">
                <Tooltip title="Individual Medical Report" placement="right">
                  <ListItem selected={pathname.includes("/individual-medical-report")} button >
                    <ListItemIcon className="nav-icon"><LocalHospital color={pathname.includes("/individual-medical-report") ? "primary" : "action"} /></ListItemIcon>
                    <ListItemText primary={"Individual Medical Report"} />
                  </ListItem>
                </Tooltip>
              </NavLink>
            }
            {
              permissions.includes(PERMISSIONS.VIEW_DOMESTIC_WORKER_REPORT) &&
              <NavLink to={`/domestic-workers-report`} className="navpanel-link">
                <Tooltip title="Domestic Workers Report" placement="right">
                  <ListItem selected={pathname.includes("/domestic-workers-report")} button >
                    <ListItemIcon className="nav-icon"><DirectionsWalk color={pathname.includes("/domestic-workers-report") ? "primary" : "action"} /></ListItemIcon>
                    <ListItemText primary={"Domestic Workers Report"} />
                  </ListItem>
                </Tooltip>
              </NavLink>
            }
            {
              permissions.includes(PERMISSIONS.VIEW_LIFE_INSURANCE_REPORT) &&
              <NavLink to={`/life-insurance-report`} className="navpanel-link">
                <Tooltip title="Life Insurance Report" placement="right">
                  <ListItem selected={pathname.includes("/life-insurance-report")} button >
                    <ListItemIcon className="nav-icon"><Security color={pathname.includes("/life-insurance-report") ? "primary" : "action"} /></ListItemIcon>
                    <ListItemText primary={"Life Insurance Report"} />
                  </ListItem>
                </Tooltip>
              </NavLink>
            }
            {
              permissions.includes(PERMISSIONS.VIEW_HOME_INSURANCE_REPORT) &&
              <NavLink to={`/home-insurance-report`} className="navpanel-link">
                <Tooltip title="Home Insurance Report" placement="right">
                  <ListItem selected={pathname.includes("/home-insurance-report")} button >
                    <ListItemIcon className="nav-icon"><HomeWork color={pathname.includes("/home-insurance-report") ? "primary" : "action"} /></ListItemIcon>
                    <ListItemText primary={"Home Insurance Report"} />
                  </ListItem>
                </Tooltip>
              </NavLink>
            }
            {
              permissions.includes(PERMISSIONS.VIEW_OUTBOUND_TRAVEL_REPORT) &&
              <NavLink to={`/outbound-travel-report`} className="navpanel-link">
                <Tooltip title="Outbound Travel Report" placement="right">
                  <ListItem selected={pathname.includes("/outbound-travel-report")} button >
                    <ListItemIcon className="nav-icon">
                      <FlightTakeoff color={pathname.includes("/outbound-travel-report") ? "primary" : "action"} />
                    </ListItemIcon>
                    <ListItemText primary={"Outbound Travel Report"} />
                  </ListItem>
                </Tooltip>
              </NavLink>
            }
            {
              permissions.includes(PERMISSIONS.VIEW_INBOUND_TRAVEL_REPORT) &&
              <NavLink to={`/inbound-travel-report`} className="navpanel-link">
                <Tooltip title="Inbound Travel Report" placement="right">
                  <ListItem selected={pathname.includes("/inbound-travel-report")} button >
                    <ListItemIcon className="nav-icon">
                      <FlightLand color={pathname.includes("/inbound-travel-report") ? "primary" : "action"} />
                    </ListItemIcon>
                    <ListItemText primary={"Inbound Travel Report"} />
                  </ListItem>
                </Tooltip>
              </NavLink>
            }

            {/* <NavLink to={`/riyati-report`} className="navpanel-link">
              <Tooltip title="Riyati Report" placement="right">
                <ListItem selected={pathname.includes("/riyati-report")} button >
                  <ListItemIcon className="nav-icon">
                  <img src={RiyatiIcon} alt="Riyati" style = {{ maxHeight : "20px" }}/>
                  </ListItemIcon>
                  <ListItemText primary={"Riyati Report"} />
                </ListItem>
              </Tooltip>
            </NavLink> */}

            {
              permissions.includes(PERMISSIONS.VIEW_MOTOR_REPORT) &&
              <NavLink to={`/motor-report`} className="navpanel-link">
                <Tooltip title="Motor Insurance Report" placement="right">
                  <ListItem selected={pathname.includes("/motor-report")} button >
                    <ListItemIcon className="nav-icon">
                      <DirectionsCar color={pathname.includes("/motor-report") ? "primary" : "action"} />
                    </ListItemIcon>
                    <ListItemText primary={"Motor Insurance Report"} />
                  </ListItem>
                </Tooltip>
              </NavLink>
            }
            {
              permissions.includes(PERMISSIONS.MANAGE_CREDIT_LIMIT) &&
              <NavLink to="/credit-limits" className="navpanel-link" onClick={this.removeCenterDetails}>
                <Tooltip title="Credit Limit" placement="right">
                  <ListItem selected={pathname === "/credit-limits"} button >
                    <ListItemIcon className="nav-icon"><CreditCard color={pathname === "/credit-limits" ? "primary" : "action"} /></ListItemIcon>
                    <ListItemText primary={"Credit Limit"} />
                  </ListItem>
                </Tooltip>
              </NavLink>
            }

            {/* <NavLink to={`/analysis`} className="navpanel-link">
              <Tooltip title="Analysis" placement="right">
                <ListItem selected={pathname.includes("/analysis")} button >
                  <ListItemIcon className="nav-icon"><ShowChartOutlined color={pathname.includes("/analysis") ? "primary" : "action"} /></ListItemIcon>
                  <ListItemText primary={"Analysis"} />
                </ListItem>
              </Tooltip>
            </NavLink> */}
          </List>

          <Divider />
          <List>
            {
              permissions.includes(PERMISSIONS.MANAGE_ACCESS) &&
              <NavLink to={`/manage-access`} className="navpanel-link">
                <Tooltip title="Manage Access" placement="right">
                  <ListItem selected={pathname.includes("/manage-access")} button >
                    <ListItemIcon className="nav-icon">
                      <PeopleOutline color={pathname.includes("/manage-access") ? "primary" : "action"} />
                    </ListItemIcon>
                    <ListItemText primary={"Manage Access"} />
                  </ListItem>
                </Tooltip>
              </NavLink>
            }
            <NavLink to="/settings" className="navpanel-link" onClick={this.removeCenterDetails}>
              <Tooltip title="Settings" placement="right">
                <ListItem selected={pathname === "/settings"} button >
                  <ListItemIcon className="nav-icon"><Settings color={pathname === "/settings" ? "primary" : "action"} /></ListItemIcon>
                  <ListItemText primary={"Settings"} />
                </ListItem>
              </Tooltip>
            </NavLink>
            <Tooltip title="Logout" placement="right">
              <ListItem button className="navpanel-link" onClick={this.handleLogout}>
                <ListItemIcon className="nav-icon"><ExitToApp /></ListItemIcon>
                <ListItemText primary={"Logout"} />
              </ListItem>
            </Tooltip>
          </List>
        </Drawer>

        <Snackbar
          showSnackbar={this.state.showSnackbar}
          messageType={this.state.messageType}
          snackBarMessage={this.state.snackBarMessage}
          onCloseSnackbar={this.handleCloseSnackbar}
        />

      </>
    )
  }
}

export default withRouter(Navigation);