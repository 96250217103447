import React from 'react';
import { Document, Page, StyleSheet, View, Text } from "@react-pdf/renderer";
import _ from "lodash";

const styles = StyleSheet.create({
  page: {
    fontSize: 10,
    fontFamily: "Arabic"
  },
  titleFont : {
    fontSize: 20
  },
  subTitleFont : {
    fontSize: 15
  },
  container: {
    paddingHorizontal: 20,
    paddingVertical: 20
  },
  headerImage: {
    width: "100%"
  },
  border_right: {
    borderRight: "1pt solid black"
  },
  border_left: {
    borderLeft: "1pt solid black"
  },
  border_top: {
    borderTop: "1pt solid black"
  },
  border_bottom: {
    borderBottom: "1pt solid black"
  },
  borderAll: {
    border: "1pt solid black"
  },
  center: {
    textAlign: "center"
  },
  topTitle: {
    marginTop: 10
  },
  row: {
    display: "flex",
    flexDirection: "row"
  },
  top_column_1: {
    width: "16%",
    paddingLeft: 5
  },

  first_column_1: {
    width: "25%",
    paddingLeft: 5
  },

  top_column_2: {
    justifyContent: "center",
    alignItems: "center",
    width: "3%"
  },
  top_column_3: {
    width: "75%",
    paddingLeft: 5
  },
  toMarLeft: {
    paddingLeft: 25
  },
  cover_column_1: {
    width: "8%"
  },
  cover_column_2: {
    width: "50%",
    paddingHorizontal: 5
  },
  cover_column_3: {
    width: "42%",
    paddingHorizontal: 5
  },
  text_right: {
    textAlign: "right"
  },
  pd_column_1: {
    width: "30%",
    paddingLeft: 5
  },
  pd_column_2: {
    paddingHorizontal: 5
  },
  capitalize: {
    textTransform: "capitalize"
  },
  padding_none: {
    paddingHorizontal: 0
  },
  padding_col_large: {
    paddingLeft: 8
  }

});

const CountReport = ({ data, selectedAffiliter, selectedChannel }) => {

  const { health, life, travel, home } = data;
  var today = new Date(),
    date = today.getDate() + '-' + (today.getMonth() + 1) + '-' + today.getFullYear();

  return (

    <Document creator="Dhofar" producer="Dhofar" >
      <Page style={styles.page}>
        <View style={styles.container}>

          <View style={[styles.borderAll, styles.topTitle, styles.center]}>
            <Text style={[styles.capitalize, styles. titleFont]}> Dashboard Count </Text>
          </View>

          <View style={[styles.padding_col_large, styles.topTitle]}>
            <Text>Date : {date} </Text>
          </View>

          { 
            Boolean(selectedAffiliter) && 
            <View style={[styles.padding_col_large]}>
              <Text>Affilate : {selectedAffiliter}</Text>
            </View>
          }

          {
            Boolean(selectedChannel) &&
            <View style={[styles.padding_col_large]}>
              <Text>Channel : {selectedChannel}</Text>
            </View>
          }


          <View style={[styles.padding_col_large, styles.topTitle]}>
            <Text style={[styles.subTitleFont]}>Pending Counts</Text>
          </View>
          <View style={[styles.row, styles.borderAll]}>
            <View style={[styles.first_column_1]}>
              <Text></Text>
            </View>
            <View style={[styles.row, styles.top_column_1, styles.border_left]}>
              <Text>Health</Text>
            </View>
            <View style={[styles.row, styles.top_column_1, styles.border_left, styles.border_right]}>
              <Text>Life</Text>
            </View>
            <View style={[styles.row, styles.top_column_1, styles.border_right]}>
              <Text>Travel</Text>
            </View>
            <View style={[styles.top_column_1]}>
              <Text>Home</Text>
            </View>
          </View>

          <View style={[styles.row, styles.border_bottom, styles.border_left, styles.border_right]}>
            <View style={[styles.first_column_1]}>
              <Text>Paid</Text>
            </View>
            <View style={[styles.row, styles.top_column_1, styles.border_left]}>
              <Text>{_.get(health, 'paid_pending', 0)}</Text>
            </View>
            <View style={[styles.row, styles.top_column_1, styles.border_left, styles.border_right]}>
              <Text>{_.get(life, 'paid_pending', 0)}</Text>
            </View>
            <View style={[styles.row, styles.top_column_1, styles.border_right]}>
              <Text>{_.get(travel, 'paid_pending', 0)}</Text>
            </View>
            <View style={[styles.top_column_1]}>
              <Text>{_.get(home, 'paid_pending', 0)}</Text>
            </View>
          </View>

          <View style={[styles.row, styles.border_bottom, styles.border_left, styles.border_right]}>
            <View style={[styles.first_column_1]}>
              <Text>Unpaid</Text>
            </View>
            <View style={[styles.row, styles.top_column_1, styles.border_left]}>
              <Text>{_.get(health, 'unpaid_pending', 0)}</Text>
            </View>
            <View style={[styles.row, styles.top_column_1, styles.border_left, styles.border_right]}>
              <Text>{_.get(life, 'unpaid_pending', 0)}</Text>
            </View>
            <View style={[styles.row, styles.top_column_1, styles.border_right]}>
              <Text>{_.get(travel, 'unpaid_pending', 0)}</Text>
            </View>
            <View style={[styles.top_column_1]}>
              <Text>{_.get(home, 'unpaid_pending', 0)}</Text>
            </View>
          </View>

          <View style={[styles.row, styles.borderAll]}>
            <View style={[styles.first_column_1]}>
              <Text>Total</Text>
            </View>
            <View style={[styles.row, styles.top_column_1, styles.border_left]}>
              <Text>{_.get(health, 'pending', 0)}</Text>
            </View>
            <View style={[styles.row, styles.top_column_1, styles.border_left, styles.border_right]}>
              <Text>{_.get(life, 'pending', 0)}</Text>
            </View>
            <View style={[styles.row, styles.top_column_1, styles.border_right]}>
              <Text>{_.get(travel, 'pending', 0)}</Text>
            </View>
            <View style={[styles.top_column_1]}>
              <Text>{_.get(home, 'pending', 0)}</Text>
            </View>
          </View>

          <View style={[styles.padding_col_large, styles.topTitle]}>
            <Text style={[styles.subTitleFont]}>Total Counts</Text>
          </View>
          <View style={[styles.row, styles.borderAll]}>
            <View style={[styles.first_column_1]}>
              <Text></Text>
            </View>
            <View style={[styles.row, styles.top_column_1, styles.border_left]}>
              <Text>Health</Text>
            </View>
            <View style={[styles.row, styles.top_column_1, styles.border_left, styles.border_right]}>
              <Text>Life</Text>
            </View>
            <View style={[styles.row, styles.top_column_1, styles.border_right]}>
              <Text>Travel</Text>
            </View>
            <View style={[styles.top_column_1]}>
              <Text>Home</Text>
            </View>
          </View>

          <View style={[styles.row, styles.border_bottom, styles.border_left, styles.border_right]}>
            <View style={[styles.first_column_1]}>
              <Text>Pending</Text>
            </View>
            <View style={[styles.row, styles.top_column_1, styles.border_left]}>
              <Text>{_.get(health, 'pending', 0)}</Text>
            </View>
            <View style={[styles.row, styles.top_column_1, styles.border_left, styles.border_right]}>
              <Text>{_.get(life, 'pending', 0)}</Text>
            </View>
            <View style={[styles.row, styles.top_column_1, styles.border_right]}>
              <Text>{_.get(travel, 'pending', 0)}</Text>
            </View>
            <View style={[styles.top_column_1]}>
              <Text>{_.get(home, 'pending', 0)}</Text>
            </View>
          </View>

          <View style={[styles.row, styles.border_bottom, styles.border_left, styles.border_right]}>
            <View style={[styles.first_column_1]}>
              <Text>Active</Text>
            </View>
            <View style={[styles.row, styles.top_column_1, styles.border_left]}>
              <Text>{_.get(health, 'active', 0)}</Text>
            </View>
            <View style={[styles.row, styles.top_column_1, styles.border_left, styles.border_right]}>
              <Text>{_.get(life, 'active', 0)}</Text>
            </View>
            <View style={[styles.row, styles.top_column_1, styles.border_right]}>
              <Text>{_.get(travel, 'active', 0)}</Text>
            </View>
            <View style={[styles.top_column_1]}>
              <Text>{_.get(home, 'active', 0)}</Text>
            </View>
          </View>

          <View style={[styles.row, styles.border_bottom, styles.border_left, styles.border_right]}>
            <View style={[styles.first_column_1]}>
              <Text>Suspended</Text>
            </View>
            <View style={[styles.row, styles.top_column_1, styles.border_left]}>
              <Text>{_.get(health, 'suspend', 0)}</Text>
            </View>
            <View style={[styles.row, styles.top_column_1, styles.border_left, styles.border_right]}>
              <Text>{_.get(life, 'suspend', 0)}</Text>
            </View>
            <View style={[styles.row, styles.top_column_1, styles.border_right]}>
              <Text>{_.get(travel, 'suspend', 0)}</Text>
            </View>
            <View style={[styles.top_column_1]}>
              <Text>{_.get(home, 'suspend', 0)}</Text>
            </View>
          </View>

          <View style={[styles.row, styles.border_bottom, styles.border_left, styles.border_right]}>
            <View style={[styles.first_column_1]}>
              <Text>Rejected</Text>
            </View>
            <View style={[styles.row, styles.top_column_1, styles.border_left]}>
              <Text>{_.get(health, 'rejected', 0)}</Text>
            </View>
            <View style={[styles.row, styles.top_column_1, styles.border_left, styles.border_right]}>
              <Text>{_.get(life, 'rejected', 0)}</Text>
            </View>
            <View style={[styles.row, styles.top_column_1, styles.border_right]}>
              <Text>{_.get(travel, 'rejected', 0)}</Text>
            </View>
            <View style={[styles.top_column_1]}>
              <Text>{_.get(home, 'rejected', 0)}</Text>
            </View>
          </View>

          <View style={[styles.row, styles.borderAll]}>
            <View style={[styles.first_column_1]}>
              <Text>Total</Text>
            </View>
            <View style={[styles.row, styles.top_column_1, styles.border_left]}>
              <Text>{_.get(health, 'total', 0)}</Text>
            </View>
            <View style={[styles.row, styles.top_column_1, styles.border_left, styles.border_right]}>
              <Text>{_.get(life, 'total', 0)}</Text>
            </View>
            <View style={[styles.row, styles.top_column_1, styles.border_right]}>
              <Text>{_.get(travel, 'total', 0)}</Text>
            </View>
            <View style={[styles.top_column_1]}>
              <Text>{_.get(home, 'total', 0)}</Text>
            </View>
          </View>



          <View style={[styles.top_column_1]}>

          </View>
          <View style={[styles.top_column_1]}>

          </View>


        </View>
      </Page>
    </Document>
  )
}

export default CountReport;