import { Accordion, AccordionDetails, AccordionSummary, Button, Dialog, DialogTitle, DialogActions, DialogContent, Grid, IconButton, MenuItem, Paper, TextField, Typography } from "@material-ui/core";
import React from "react";
import PlanService from "./../../../services/plans";
import _ from "lodash";
import Snackbar from "../../common/Snackbar";
import { Delete, Edit, ExpandMore } from "@material-ui/icons";
import PropTypes from "prop-types";
import validator from "validator";
import { pricesList } from "./pricesList";
import DeleteConfirmDialog from "../../common/DeleteConfirmDialog";

class IndividualMedical extends React.Component {

    state = {
        selectedPlan: "green",
        annualLimitDialog: {
            selectedPlan: "",
            open: false,
            index: false,
            value: ""
        },
        pmDialog: {
            selectedPlan: "",
            open: false,
            index: false,
            value: ""
        },
        opDialog: {
            selectedPlan: "",
            open: false,
            index: undefined,
            value: ""
        },
        selected: {
            plan: "",
            annual_limit: "",
            op: "",
            pm: ""
        },
        saved: {
            green: {},
            silver: {},
            gold: {}
        },
        green: {
            annual_limit: [],
            op: [],
            pm: [],
            prices: {}
        },
        silver: {
            annual_limit: [],
            op: [],
            pm: [],
            prices: {}
        },
        gold: {
            annual_limit: [],
            op: [],
            pm: [],
            prices: {}
        },
        plans: ["green", "silver", "gold"],
        loading: false,
        showSnackbar: false,
        snackBarMessage: "",
        messageType: "",
        delete_index: undefined,
        dialogActive: false,
        deleteItem: undefined,
    }

    handleCloseSnackbar = () => {
        this.setState({ showSnackbar: false })
    }

    componentDidMount() {
        this.loadDetails()
    }

    loadDetails = async () => {
        this.setState({ loading: true })
        let health_plan_price = await PlanService.getPlanPrices("health");
        let plan = this.state.selectedPlan;
        let planDetails = _.get(health_plan_price, `data.data.health_individual_medical.sub_type.${plan}`, this.state[plan])
        let saved = {
            [plan]: JSON.stringify(_.get(health_plan_price, `data.data.health_individual_medical.sub_type.${plan}`, this.state[plan]))
        }
        this.setState({ [plan]: planDetails, saved, loading: false }, () => {
            this.createPricingObj(plan)
        })
    }

    createPricingObj = (plan_name) => {
        let plan = { ...this.state[plan_name] };

        // Remove annual Limit
        for (const key in plan.prices) {
            if (!this.state[plan_name].annual_limit.limits.includes(key)) {
                delete plan.prices[key];
            }
        }

        // Add New Annual Limit
        this.state[plan_name].annual_limit.limits.forEach(limit => {
            if (!plan.prices[limit]) plan.prices[limit] = [];
        })

        // Update Pricing Collection 
        for (const key in plan.prices) {

            this.state[plan_name].op.deductible.forEach(op => {

                this.state[plan_name].pm.co_pays.forEach(pm => {

                    let find = plan.prices[key].findIndex((price, index) => {
                        return price.op === op && price.pm === pm.english
                    })

                    if (find === -1) {
                        plan.prices[key].push({
                            op, pm: pm.english, prices: pricesList
                        })
                    }

                })

            })

        }

        this.setState({ [plan_name]: plan })
    }


    handleChangeSelected = (e) => {
        let selected = { ...this.state.selected };
        selected[e.target.name] = e.target.value;
        this.setState({ selected })
    }

    handleChangeOp = (plan_name, e, index) => {
        let plan = { ...this.state[plan_name] }
        plan.op.deductible[index] = e.target.value
        this.setState({ [plan_name]: plan })
    }

    addNewOp = (plan_name) => {
        let plan = { ...this.state[plan_name] }
        plan.op.deductible.push("")
        this.setState({ [plan_name]: plan })
    }

    removeNewOp = (plan_name, index) => {
        let plan = { ...this.state[plan_name] }
        plan.op.deductible.splice(index, 1);
        this.setState({ [plan_name]: plan }, () => {
            this.clearDeleteDialog();
            this.createPricingObj(plan_name)
        })
    }

    confirmDelete = async (item, index) => {
        this.setState({ dialogActive: true, delete_index: index, deleteItem: item });
    };

    onCloseDialog = async (confirm) => {
        if (confirm === true) {
            if (this.state.deleteItem === "op") {
                this.removeNewOp(this.state.selectedPlan, this.state.delete_index)
            }else if(this.state.deleteItem === "pm"){
                this.removeNewPm(this.state.selectedPlan, this.state.delete_index)
            }else if(this.state.deleteItem === "annual_limit"){
                this.removeNewAnnualLimit(this.state.selectedPlan, this.state.delete_index)
            }
        } else {
            this.clearDeleteDialog();
        }
    };

    clearDeleteDialog = () => {
        this.setState({ dialogActive: false, delete_index: undefined, deleteItem: undefined });
    }

    removeNewPm = (plan_name, index) => {
        let plan = { ...this.state[plan_name] }
        plan.pm.co_pays.splice(index, 1);
        this.setState({ [plan_name]: plan }, () => {
            this.clearDeleteDialog();
            this.createPricingObj(plan_name)
        })
    }

    removeNewAnnualLimit = (plan_name, index) => {
        let plan = { ...this.state[plan_name] }
        plan.annual_limit.limits.splice(index, 1);
        this.setState({ [plan_name]: plan }, () => {
            this.clearDeleteDialog();
            this.createPricingObj(plan_name)
        })
    }

    openDialogFormOp = (plan_name, index) => {
        let plan = { ...this.state[plan_name] }

        let opDialog = {
            selectedPlan: plan_name,
            open: true,
            index: index,
            value: plan.op.deductible[index]
        }
        this.setState({ opDialog })
    }

    onCloseDialogOp = (isSave, value) => {

        let opDialog = {
            selectedPlan: "",
            open: false,
            index: false,
            value: ""
        }

        if (isSave === true) {
            let selectedPlan = this.state.opDialog.selectedPlan;
            let plan = { ...this.state[this.state.opDialog.selectedPlan] }
            if (!plan.op.deductible[this.state.opDialog.index]) {
                plan.op.deductible.push(value)
            } else {
                let oldOp = plan.op.deductible[this.state.opDialog.index];
                plan.op.deductible[this.state.opDialog.index] = value

                for (const key in plan.prices) {

                    this.state[selectedPlan].op.deductible.forEach(op => {

                        this.state[selectedPlan].pm.co_pays.forEach(pm => {

                            plan.prices[key] = plan.prices[key].map(price => {
                                if (price.op === oldOp) {
                                    price.op = value;
                                }
                                return price;
                            })

                        })

                    })

                }

            }
            this.setState({ [this.state.opDialog.selectedPlan]: plan, opDialog }, () => {
                this.createPricingObj(selectedPlan)
            })
        } else {
            this.setState({ opDialog })
        }

    }

    openDialogFormAnnualLimit = (plan_name, index) => {
        let plan = { ...this.state[plan_name] }

        let annualLimitDialog = {
            selectedPlan: plan_name,
            open: true,
            index: index,
            value: plan.annual_limit.limits[index]
        }
        this.setState({ annualLimitDialog })
    }

    onCloseDialogAnnualLimit = (isSave, value) => {

        let annualLimitDialog = {
            selectedPlan: "",
            open: false,
            index: false,
            value: ""
        }

        if (isSave === true) {
            let selectedPlan = this.state.annualLimitDialog.selectedPlan;
            let plan = { ...this.state[this.state.annualLimitDialog.selectedPlan] }
            if (!plan.annual_limit.limits[this.state.annualLimitDialog.index]) {
                plan.annual_limit.limits.push(value)
            } else {
                let oldAnnualLimit = plan.annual_limit.limits[this.state.annualLimitDialog.index];
                plan.annual_limit.limits[this.state.annualLimitDialog.index] = value;
                plan.prices[value] = [...plan.prices[oldAnnualLimit]];
                delete plan.prices[oldAnnualLimit];
            }
            this.setState({ [this.state.annualLimitDialog.selectedPlan]: plan, annualLimitDialog }, () => {
                this.createPricingObj(selectedPlan)
            })
        } else {
            this.setState({ annualLimitDialog })
        }

    }

    handleChangePrice = (name, value, index) => {
        let plan = { ...this.state[this.state.selectedPlan] };
        plan.prices[this.state.selected.annual_limit] = plan.prices[this.state.selected.annual_limit].map(list => {

            if (list.op === this.state.selected.op && list.pm === this.state.selected.pm && list.prices[index]) {
                list.prices[index].price[name] = value
            }

            return list;
        })
        this.setState({ [this.state.selectedPlan]: plan })
    }

    openDialogFormPm = (plan_name, index) => {
        let plan = { ...this.state[plan_name] }

        let pmDialog = {
            selectedPlan: plan_name,
            open: true,
            index: index,
            value: plan.pm.co_pays[index]
        }
        this.setState({ pmDialog })
    }

    onCloseDialogPm = (isSave, value) => {

        let pmDialog = {
            selectedPlan: "",
            open: false,
            index: false,
            value: ""
        }

        if (isSave === true) {
            let selectedPlan = this.state.pmDialog.selectedPlan;
            let plan = { ...this.state[this.state.pmDialog.selectedPlan] }
            value = value === "0" ? "Nil" : value + "%";
            if (!plan.pm.co_pays[this.state.pmDialog.index]) {
                plan.pm.co_pays.push({ english: value, arabic: value })
            } else {
                let oldPm = plan.pm.co_pays[this.state.pmDialog.index].english;
                plan.pm.co_pays[this.state.pmDialog.index].english = value
                plan.pm.co_pays[this.state.pmDialog.index].arabic = value

                for (const key in plan.prices) {

                    this.state[selectedPlan].op.deductible.forEach(op => {

                        this.state[selectedPlan].pm.co_pays.forEach(pm => {

                            plan.prices[key] = plan.prices[key].map(price => {
                                if (price.pm === oldPm) {
                                    price.pm = value;
                                }
                                return price;
                            })

                        })

                    })

                }

            }
            this.setState({ [this.state.pmDialog.selectedPlan]: plan, pmDialog }, () => {
                this.createPricingObj(selectedPlan)
            })
        } else {
            this.setState({ pmDialog })
        }

    }

    validateSaveDetails = () => {

    }

    savePlanDetails = async () => {

        this.setState({ loading: true })
        let messageType = false;
        let message = false;
        let selected = { ...this.state.selected }

        let annual_limit = { ...this.state[this.state.selectedPlan].annual_limit };

        if (!(annual_limit.limits && annual_limit.limits.length)) {
            message = "Annual limit is not valid."
        } else {
            let limits = annual_limit.limits.filter(value => Boolean(value));
            limits = _.uniq(limits);
            if (limits.length != annual_limit.limits.length) {
                message = "Annual limit is not valid."
            }
        }

        let op = { ...this.state[this.state.selectedPlan].op };

        if (!(op.deductible && op.deductible.length)) {
            message = "Out Patient is not valid."
        } else {
            let deductible = op.deductible.filter(value => Boolean(value));
            deductible = _.uniq(deductible);
            if (deductible.length != op.deductible.length) {
                message = "Out Patient is not valid."
            }
        }

        let pm = { ...this.state[this.state.selectedPlan].pm };

        if (!(pm.co_pays && pm.co_pays.length)) {
            message = "Co Pays is not valid."
        } else {
            let co_pays = pm.co_pays.filter(value => { return Boolean(value.english) && Boolean(value.arabic) });
            co_pays = co_pays.map(value => JSON.stringify(value));
            co_pays = _.uniq(co_pays);
            if (co_pays.length != pm.co_pays.length) {
                message = "Co Pays is not valid."
            }
        }

        let prices = { ...this.state[this.state.selectedPlan].prices };


        // Check annual Limit in price
        for (const key in prices) {
            if (!this.state[this.state.selectedPlan].annual_limit.limits.includes(key)) {
                message = "Invalid Plan Price.";
                break;
            }
        }

        // Check All Price must exist
        let find;
        let agePrice;
        for (const key in prices) {
            if (message) break;

            for (const opKey in op.deductible) {
                if (message) break;

                for (const pmKey in pm.co_pays) {
                    if (message) break;

                    find = prices[key].findIndex((price, index) => {
                        return price.op === op.deductible[opKey] && price.pm === pm.co_pays[pmKey].english
                    })

                    if (find === -1) {
                        message = "Price is missing."
                        // console.log("Annual Limit", key);
                        // console.log("Out Patient", op.deductible[opKey]);
                        // console.log("Co Pays", pm.co_pays[pmKey].english);
                        break;
                    } else {

                        for (const ageKey in pricesList) {
                            agePrice = prices[key][find].prices.findIndex((price) => {
                                return price.from === pricesList[ageKey].from && price.to === pricesList[ageKey].to;
                            })
                            if (
                                agePrice === -1 ||
                                prices[key][find].prices[agePrice].price.female <= 0 ||
                                prices[key][find].prices[agePrice].price.female_married <= 0 ||
                                prices[key][find].prices[agePrice].price.male <= 0
                            ) {
                                message = `Price is missing. Annual Limit : ${key}, Out Patient : ${op.deductible[opKey]}, Co Pays : ${pm.co_pays[pmKey].english}.`
                                // console.log("Annual Limit", key);
                                // console.log("Out Patient", op.deductible[opKey]);
                                // console.log("Co Pays", pm.co_pays[pmKey].english);
                                // console.log("Age From:", pricesList[ageKey].from, "| To :", pricesList[ageKey].to)

                                selected.annual_limit = key;
                                selected.op = op.deductible[opKey];
                                selected.pm = pm.co_pays[pmKey].english;
                                break;
                            }
                        }

                    }

                }

            }

        }

        if (message === false) {
            try {
                let data = {
                    plan_name: this.state.selectedPlan,
                    annual_limit: this.state[this.state.selectedPlan].annual_limit,
                    op: this.state[this.state.selectedPlan].op,
                    pm: this.state[this.state.selectedPlan].pm,
                    prices: this.state[this.state.selectedPlan].prices
                }
                let response = await PlanService.updatePlanPremium("health", "Individual Medical", data)
                if (response.data.status) {
                    messageType = true;
                    message = "Individual Medical premium updated.";
                    this.loadDetails()
                }

            } catch (error) { }
        }

        this.setState({
            selected,
            loading: false,
            showSnackbar: true,
            // snackBarMessage: status ? "Individual Medical premium updated." : "Something went wrong.",
            snackBarMessage: message ? message : "Something went wrong.",
            messageType: messageType ? "success" : "danger"
        });

    }

    handleChangeSelectedPlan = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        }, this.loadDetails)
    }

    isChanged = () => {
        try {
            return JSON.stringify(this.state[this.state.selectedPlan]) !== this.state.saved[this.state.selectedPlan]
        } catch (error) { }
        return true;
    }

    render() {
        return (
            <React.Fragment>
                <Grid item xs={12} className="plan-price-form">
                    <Snackbar
                        showSnackbar={this.state.showSnackbar}
                        messageType={this.state.messageType}
                        snackBarMessage={this.state.snackBarMessage}
                        onCloseSnackbar={this.handleCloseSnackbar}
                    />

                    <DeleteConfirmDialog
                        onClose={this.onCloseDialog}
                        open={this.state.dialogActive}
                        processing={false}
                    />


                    <TextField
                        label="Plan"
                        variant="outlined"
                        margin="normal"
                        select
                        fullWidth
                        className="mt-3"
                        name="selectedPlan"
                        onChange={this.handleChangeSelectedPlan}
                        value={this.state.selectedPlan}
                    >
                        <MenuItem value="">Select Country</MenuItem>
                        <MenuItem value="green">Green</MenuItem>
                        <MenuItem value="silver">Silver</MenuItem>
                        <MenuItem value="gold">Gold</MenuItem>
                    </TextField>

                    <Grid container spacing={2}>
                        <Grid item xs={12} md={4} sm={6}>
                            <Accordion expanded={true}>
                                <AccordionSummary expandIcon={<ExpandMore />} >
                                    <Typography>Out Patient</Typography>
                                </AccordionSummary>
                                <AccordionDetails className="p-0">
                                    <OutPatientDialog
                                        onClose={this.onCloseDialogOp}
                                        open={this.state.opDialog.open}
                                        value={Array.isArray(this.state[this.state.selectedPlan].op.deductible) ? this.state[this.state.selectedPlan].op.deductible : []}
                                        index={this.state.opDialog.index}
                                    />
                                    <ul style={{ listStyleType: "none" }} className="w-100">
                                        {
                                            Array.isArray(this.state[this.state.selectedPlan].op.deductible) &&
                                            this.state[this.state.selectedPlan].op.deductible.map((value, ind) => {
                                                return <li key={ind}>
                                                    <div className="row">
                                                        <div className="col align-self-center">
                                                            {value}
                                                        </div>
                                                        <div className="col-auto align-self-center">
                                                            <IconButton onClick={e => { this.openDialogFormOp(this.state.selectedPlan, ind) }}> <Edit /> </IconButton>
                                                            <IconButton onClick={e => { this.confirmDelete("op", ind) }}> <Delete /> </IconButton>
                                                        </div>
                                                    </div>
                                                </li>
                                            })
                                        }
                                        <li className="float-right">
                                            <Button onClick={e => { this.openDialogFormOp(this.state.selectedPlan, false) }} size="small" color="primary">Add</Button>
                                        </li>
                                    </ul>
                                </AccordionDetails>
                            </Accordion>

                        </Grid>
                        <Grid item xs={12} md={4} sm={6}>
                            <Accordion expanded={true}>
                                <AccordionSummary expandIcon={<ExpandMore />} >
                                    <Typography>Co Pays</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <CoPayDialog
                                        onClose={this.onCloseDialogPm}
                                        open={this.state.pmDialog.open}
                                        value={Array.isArray(this.state[this.state.selectedPlan].pm.co_pays) ? this.state[this.state.selectedPlan].pm.co_pays : []}
                                        index={this.state.pmDialog.index}
                                    />
                                    <ul style={{ listStyleType: "none" }} className="w-100">
                                        {
                                            Array.isArray(this.state[this.state.selectedPlan].pm.co_pays) &&
                                            this.state[this.state.selectedPlan].pm.co_pays.map((value, ind) => (<li key={ind}>
                                                <div className="row">
                                                    <div className="col align-self-center">
                                                        {value.english}
                                                    </div>
                                                    <div className="col-auto align-self-center">
                                                        <IconButton onClick={e => { this.openDialogFormPm(this.state.selectedPlan, ind) }}> <Edit /> </IconButton>
                                                        <IconButton onClick={e => { this.confirmDelete("pm", ind) }}> <Delete /> </IconButton>
                                                    </div>
                                                </div>
                                            </li>))
                                        }
                                        <li className="float-right">
                                            <Button onClick={e => { this.openDialogFormPm(this.state.selectedPlan, false) }} size="small" color="primary">Add</Button>
                                        </li>
                                    </ul>
                                </AccordionDetails>
                            </Accordion>
                        </Grid>
                        <Grid item xs={12} md={4} sm={6}>
                            <Accordion expanded={true}>
                                <AccordionSummary expandIcon={<ExpandMore />} >
                                    <Typography>Annual Limit</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <AnnualLimitDialog
                                        onClose={this.onCloseDialogAnnualLimit}
                                        open={this.state.annualLimitDialog.open}
                                        value={Array.isArray(this.state[this.state.selectedPlan].annual_limit.limits) ? this.state[this.state.selectedPlan].annual_limit.limits : []}
                                        index={this.state.annualLimitDialog.index}
                                    />
                                    <ul style={{ listStyleType: "none" }} className="w-100">
                                        {
                                            Array.isArray(this.state[this.state.selectedPlan].annual_limit.limits) &&
                                            this.state[this.state.selectedPlan].annual_limit.limits.map((value, ind) => (
                                                <li key={ind}>
                                                    <div className="row">
                                                        <div className="col align-self-center">
                                                            {value}
                                                        </div>
                                                        <div className="col-auto align-self-center">
                                                            <IconButton onClick={e => { this.openDialogFormAnnualLimit(this.state.selectedPlan, ind) }}> <Edit /> </IconButton>
                                                            <IconButton onClick={e => { this.confirmDelete("annual_limit", ind) }}> <Delete /> </IconButton>
                                                        </div>
                                                    </div>
                                                </li>
                                            ))
                                        }
                                        <li className="float-right">
                                            <Button onClick={e => { this.openDialogFormAnnualLimit(this.state.selectedPlan, false) }} size="small" color="primary">Add</Button>
                                        </li>
                                    </ul>

                                </AccordionDetails>
                            </Accordion>
                        </Grid>
                        <Grid item xs={12}>
                            <Accordion expanded={true}>
                                <AccordionSummary expandIcon={<ExpandMore />} >
                                    <Typography>Prices</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid container spacing={2}>

                                        <Grid item xs={12}>
                                            <div className="row">
                                                <div className="col-4">
                                                    <TextField
                                                        label="Annual Limit"
                                                        variant="outlined"
                                                        margin="normal"
                                                        select
                                                        fullWidth
                                                        size="small"
                                                        className="mt-1"
                                                        name="annual_limit"
                                                        onChange={this.handleChangeSelected}
                                                        value={this.state.selected.annual_limit}
                                                    >
                                                        <MenuItem value="">Select Annual Limit</MenuItem>
                                                        {
                                                            Array.isArray(this.state[this.state.selectedPlan].annual_limit.limits) &&
                                                            this.state[this.state.selectedPlan].annual_limit.limits.map((value, ind) => (
                                                                <MenuItem key={ind} value={value}>{value}</MenuItem>
                                                            ))
                                                        }
                                                    </TextField>
                                                </div>
                                                <div className="col-4">
                                                    <TextField
                                                        label="Out Patient"
                                                        variant="outlined"
                                                        margin="normal"
                                                        select
                                                        fullWidth
                                                        size="small"
                                                        className="mt-1"
                                                        name="op"
                                                        onChange={this.handleChangeSelected}
                                                        value={this.state.selected.op}
                                                    >
                                                        <MenuItem value="">Select Out Patient</MenuItem>
                                                        {
                                                            Array.isArray(this.state[this.state.selectedPlan].op.deductible) &&
                                                            this.state[this.state.selectedPlan].op.deductible.map((value, ind) => (
                                                                <MenuItem key={ind} value={value}>{value}</MenuItem>
                                                            ))
                                                        }
                                                    </TextField>
                                                </div>
                                                <div className="col-4">
                                                    <TextField
                                                        label="Co Pays"
                                                        variant="outlined"
                                                        margin="normal"
                                                        select
                                                        fullWidth
                                                        size="small"
                                                        className="mt-1"
                                                        name="pm"
                                                        onChange={this.handleChangeSelected}
                                                        value={this.state.selected.pm}
                                                    >
                                                        <MenuItem value="">Select Co Pays</MenuItem>
                                                        {
                                                            Array.isArray(this.state[this.state.selectedPlan].pm.co_pays) &&
                                                            this.state[this.state.selectedPlan].pm.co_pays.map((value, ind) => (
                                                                <MenuItem key={ind} value={value.english}>{value.english}</MenuItem>
                                                            ))
                                                        }
                                                    </TextField>
                                                </div>
                                            </div>
                                        </Grid>

                                        {
                                            (
                                                this.state.selected.annual_limit !== "" &&
                                                this.state.selected.co !== "" &&
                                                this.state.selected.pm !== "" &&
                                                Array.isArray(this.state[this.state.selectedPlan].prices[this.state.selected.annual_limit]) &&
                                                this.state[this.state.selectedPlan].prices[this.state.selected.annual_limit].length
                                            )
                                                ?
                                                Array.isArray(this.state[this.state.selectedPlan].prices[this.state.selected.annual_limit]) &&
                                                this.state[this.state.selectedPlan].prices[this.state.selected.annual_limit].map((price, ind) => {

                                                    return (price.op === this.state.selected.op && price.pm === this.state.selected.pm) ? (<Grid item xs={12} key={`${price.op}-${price.pm}`}>
                                                        <Typography>Out Patient : {price.op}, Co Pay : {price.pm}</Typography>
                                                        <div className="row">
                                                            {
                                                                Array.isArray(price.prices) && price.prices.map((premium, premiumIndex) => {
                                                                    return (<div className="col-3" key={premiumIndex}>
                                                                        <Paper className="p-2 mb-2">
                                                                            <div className="row">
                                                                                <div className="col-7">
                                                                                    <TextField
                                                                                        label="Male"
                                                                                        type="number"
                                                                                        variant="outlined"
                                                                                        margin="normal"
                                                                                        name="male"
                                                                                        size="small"
                                                                                        value={premium.price.male}
                                                                                        onChange={e => { this.handleChangePrice(e.target.name, e.target.value, premiumIndex) }}
                                                                                    />
                                                                                    <TextField
                                                                                        label="Female"
                                                                                        type="number"
                                                                                        variant="outlined"
                                                                                        margin="normal"
                                                                                        name="female"
                                                                                        size="small"
                                                                                        value={premium.price.female}
                                                                                        onChange={e => { this.handleChangePrice(e.target.name, e.target.value, premiumIndex) }}
                                                                                    />
                                                                                    <TextField
                                                                                        label="Female (Married)"
                                                                                        type="number"
                                                                                        variant="outlined"
                                                                                        margin="normal"
                                                                                        name="female_married"
                                                                                        size="small"
                                                                                        value={premium.price.female_married}
                                                                                        onChange={e => { this.handleChangePrice(e.target.name, e.target.value, premiumIndex) }}
                                                                                    />
                                                                                </div>
                                                                                <div className="col-5 align-self-center">
                                                                                    <TextField
                                                                                        label="From Age"
                                                                                        type="number"
                                                                                        variant="outlined"
                                                                                        margin="normal"
                                                                                        name="op"
                                                                                        size="small"
                                                                                        disabled
                                                                                        value={premium.from}
                                                                                    />
                                                                                    <TextField
                                                                                        label="To Age"
                                                                                        type="number"
                                                                                        variant="outlined"
                                                                                        margin="normal"
                                                                                        name="op"
                                                                                        size="small"
                                                                                        disabled
                                                                                        value={premium.to}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </Paper>
                                                                    </div>)
                                                                })
                                                            }
                                                        </div>
                                                    </Grid>) : (<div key={`${price.op}-${price.pm}`}></div>)
                                                })
                                                : <></>
                                        }
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>

                        </Grid>

                        <Grid item xs={12}>
                            <Button
                                className="mr-2"
                                onClick={this.savePlanDetails}
                                disabled={this.state.loading === true || !this.isChanged()}
                                variant="contained" size="small" color="primary">
                                {this.state.loading === true ? "Processing" : "Save"}
                            </Button>
                            <Button onClick={this.loadDetails}
                                disabled={this.state.loading || !this.isChanged()}
                                variant="contained" size="small" >
                                Reset
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </React.Fragment>
        )
    }
}

class OutPatientDialog extends React.Component {

    componentWillReceiveProps(props) {
        this.onClose = props.onClose
        let value = 0;
        let allOpList = Array.isArray(props.value) ? [...props.value] : [];
        if (props.value[props.index]) {
            value = props.value[props.index];
            allOpList.splice(props.index, 1);
        }
        this.setState({ open: props.open, index: props.index, allOpList, value })
    }

    state = {
        index: false,
        open: false,
        value: undefined,
        allOpList: [],
        isTouched: false
    }

    handleClose = (save = false) => {
        this.onClose(false);
    }

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    onBlur = () => {
        this.setState({ isTouched: true });
    }

    saveDetails = (save = false) => {
        this.onClose(save, this.state.value);
    }

    isInValid = () => {
        if (!this.state.value || !validator.isInt(this.state.value) || this.state.value <= 0) {
            return "Enter Valid Out Patient";;
        } else if (this.state.allOpList.includes(this.state.value)) {
            return "Out Patient is already exist";
        } else {
            return false;
        }
    }

    render() {
        return (<Dialog onClose={this.handleClose} aria-labelledby="simple-dialog-title" open={this.state.open}>
            <DialogTitle id="simple-dialog-title">
                {this.state.index === false ? "Add Out Patient" : "Update Out Patient"}
            </DialogTitle>
            <DialogContent>
                <TextField
                    type="number"
                    fullWidth
                    value={this.state.value}
                    variant="outlined"
                    name="value"
                    onChange={this.handleChange}
                    onBlur={this.onBlur}
                    error={this.state.isTouched === true && this.isInValid() !== false}
                    helperText={this.state.isTouched === true ? this.isInValid() : ""}
                />
            </DialogContent>
            <DialogActions>
                <Button variant="contained" size="small" onClick={this.saveDetails.bind(this, false)}>
                    Cancel
                </Button>
                <Button variant="contained" disabled={this.isInValid() !== false} color="primary" size="small" onClick={this.saveDetails.bind(this, true)}>
                    Save
                </Button>
            </DialogActions>
        </Dialog>)
    }
}

OutPatientDialog.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    index: PropTypes.any,
    value: PropTypes.array,
}

class AnnualLimitDialog extends React.Component {

    componentWillReceiveProps(props) {
        this.onClose = props.onClose
        let value = 0;
        let allAnnualLimitList = Array.isArray(props.value) ? [...props.value] : [];
        if (props.value[props.index]) {
            value = props.value[props.index];
            allAnnualLimitList.splice(props.index, 1);
        }
        this.setState({ open: props.open, index: props.index, allAnnualLimitList, value })
    }

    state = {
        index: false,
        open: false,
        value: undefined,
        allAnnualLimitList: [],
        isTouched: false
    }

    handleClose = (save = false) => {
        this.onClose(false);
    }

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    onBlur = () => {
        this.setState({ isTouched: true });
    }

    saveDetails = (save = false) => {
        this.onClose(save, this.state.value);
    }

    isInValid = () => {
        if (!this.state.value) {
            return "Enter Valid Annual Limit";;
        } else if (this.state.allAnnualLimitList.includes(this.state.value)) {
            return "Annual Limit is already exist";
        } else {
            return false;
        }
    }

    render() {
        return (<Dialog onClose={this.handleClose} aria-labelledby="simple-dialog-title" open={this.state.open}>
            <DialogTitle id="simple-dialog-title">
                {this.state.index === false ? "Add Out Patient" : "Update Out Patient"}
            </DialogTitle>
            <DialogContent>
                <TextField
                    type="text"
                    fullWidth
                    value={this.state.value}
                    variant="outlined"
                    name="value"
                    onChange={this.handleChange}
                    onBlur={this.onBlur}
                    error={this.state.isTouched === true && this.isInValid() !== false}
                    helperText={this.state.isTouched === true ? this.isInValid() : ""}
                />
            </DialogContent>
            <DialogActions>
                <Button variant="contained" size="small" onClick={this.saveDetails.bind(this, false)}>
                    Cancel
                </Button>
                <Button variant="contained" disabled={this.isInValid() !== false} color="primary" size="small" onClick={this.saveDetails.bind(this, true)}>
                    Save
                </Button>
            </DialogActions>
        </Dialog>)
    }
}

AnnualLimitDialog.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    index: PropTypes.any,
    value: PropTypes.array,
}

class CoPayDialog extends React.Component {

    componentWillReceiveProps(props) {
        this.onClose = props.onClose
        let value = 0;
        let allPmList = Array.isArray(props.value) ? [...props.value.map(e => this.convertToNumber(e.english))] : [];
        if (props.value[props.index]) {
            value = this.convertToNumber(props.value[props.index].english);
            allPmList.splice(props.index, 1);
        }
        this.setState({ open: props.open, index: props.index, allPmList, value })
    }

    convertToNumber = (value) => {
        return parseFloat(value) >= 0 ? parseFloat(value).toString() : "0";
    }

    state = {
        index: false,
        open: false,
        value: undefined,
        allPmList: [],
        isTouched: false
    }

    handleClose = (save = false) => {
        this.onClose(false);
    }

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    onBlur = () => {
        this.setState({ isTouched: true });
    }

    saveDetails = (save = false) => {
        this.onClose(save, this.state.value);
    }

    isInValid = () => {
        if (!this.state.value || !validator.isNumeric(this.state.value.toString()) || this.state.value < 0 || this.state.value > 100) {
            return "Enter Valid Co Pay";;
        } else if (this.state.allPmList.includes(this.state.value)) {
            return "Co Pay is already exist";
        } else {
            return false;
        }
    }

    render() {
        return (<Dialog onClose={this.handleClose} aria-labelledby="simple-dialog-title" open={this.state.open}>
            <DialogTitle id="simple-dialog-title">
                {this.state.index === false ? "Add Co Pay" : "Update Co Pay"}
            </DialogTitle>
            <DialogContent>
                <TextField
                    type="number"
                    fullWidth
                    value={this.state.value}
                    variant="outlined"
                    name="value"
                    onChange={this.handleChange}
                    onBlur={this.onBlur}
                    error={this.state.isTouched === true && this.isInValid() !== false}
                    helperText={this.state.isTouched === true ? this.isInValid() : ""}
                />
            </DialogContent>
            <DialogActions>
                <Button variant="contained" size="small" onClick={this.saveDetails.bind(this, false)}>
                    Cancel
                </Button>
                <Button variant="contained" disabled={this.isInValid() !== false} color="primary" size="small" onClick={this.saveDetails.bind(this, true)}>
                    Save
                </Button>
            </DialogActions>
        </Dialog>)
    }
}

CoPayDialog.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    index: PropTypes.any,
    value: PropTypes.array,
}

export default IndividualMedical;