import { MenuItem, TextField } from "@material-ui/core";
import React from "react";
import AffiliateService from "../../../services/affiliates";
import PropTypes from "prop-types";
import _ from "lodash";
import { withRouter } from "react-router";
import Select from 'react-select';

class AffiliateSelect extends React.Component {

    state = {
        center: "",
        onlyParents: false,
        affiliates: [],
        size: "medium",
        setSelectedValue: "all",
        options: []
    }

    async componentDidMount() {
        let affiliates = await this.listAllAffiliates();
        const options = [];
        options.push({ value: 'all', label: 'All' });
        Array.isArray(affiliates) && affiliates.map((affiliate) => {
            if(
                (
                    this.state.onlyParents === true &&
                    (!affiliate.has_any_parent || affiliate.has_any_parent == 0)
                ) || this.state.onlyParents !== true
            ){
                options.push({ value: `${affiliate.affiliateId}`, label: `${affiliate.name}` })
            }
        })
        this.setState({ affiliates, options });
        let center = sessionStorage.getItem("center_id") ? sessionStorage.getItem("center_id") : "all";
        this.setState({ setSelectedValue: center });
    }

    componentWillReceiveProps(props) {

        let center = sessionStorage.getItem("center_id") ? sessionStorage.getItem("center_id") : "all";
        if (props.fresh === true) {
            center = "all";
            sessionStorage.removeItem("center_id")
        }

        let size = props.size ? props.size : "medium";
        let onlyParents = props.onlyParents ? props.onlyParents : false;

        this.setState({ center, size, onlyParents });
        this.onChange = props.onChange;
    }

    listAllAffiliates = async () => {
        let affiliates = [];
        try {
            let response = await AffiliateService.getListAllAffiliates();
            affiliates = response;
        } catch (error) { }
        return affiliates;
    }

    handleChange = (e) => {
        if ("all" == e.value) {
            sessionStorage.removeItem("center_id")
        } else {
            sessionStorage.setItem("center_id", e.value)
        }
        this.onChange(e.value);
        this.setState({
            [e.name]: e.value,
            setSelectedValue: e.value
        });

    }

    render() {



        return (
            <React.Fragment>
                <label className="text-secondary"><small>Select Affiliate</small></label>
                <Select
                    className="basic-single"
                    classNamePrefix="select"
                    name="center"
                    value={this.state.options.find(obj => obj.value === this.state.setSelectedValue)}
                    options={this.state.options}
                    onChange={this.handleChange}
                />
            </React.Fragment>
        )
    }
}

AffiliateSelect.propTypes = {
    fresh: PropTypes.bool,
    onlyParents: PropTypes.bool,
    onChange: PropTypes.func,
    size: PropTypes.oneOf(["small"])
}

export default withRouter(AffiliateSelect);