export const INSURANCE_TYPES = {
    health: "Health",
    life: "Life",
    travel: "Travel",
    home: "Home",
    riyati: "Riyati",
    motor : "Motor",
    group_credit_life : "Group Credit Life"
}

export const PLAN_TYPES = {
    individual_medical: "Individual Medical",
    affordable_health_plan: "Affordable Health Plan",
    unified_domestic_worker: "Unified Domestic Worker",
    unified_health_insurance_plan: "Unified Health Insurance Plan",
    term_plan: "Term Plan",
    personal_accident: "Personal Accident",
    standard_travel: "Standard",
    lite_travel: "Lite",
    senior_travel: "Senior",
    covid_shield_travel: "Covid Shield",
    family_travel: "Family",
    inbound: "Inbound"
}

export const REPORT_STATUS_OPTIONS = {
    "All" : "All",
    "Active" : "Active",
    "Cancelled" : "Cancelled",
    "Cancelled-Paid" : "Cancelled-Paid",
    "Suspended" : "Suspended",
    "Suspended-Paid" : "Suspended-Paid",
    "Rejected" : "Rejected",
    "Rejected-Paid" : "Rejected-Paid",
    "Pending" : "Pending",
    "Pending-Paid" : "Pending-Paid",
}

export const PAYMENT_TYPES = {
    "PG" : "PG",
    "CASH" : "CASH",
    "POS" : "POS",
    "PAYMENT_LINK" : "PAYMENT LINK",
    "BANK_DEPOSIT" : "BANK DEPOSIT",
    "CREDIT" : "CREDIT"
}

export const REPORT_SOB_OPTIONS = {
    "All" : "All",
    "B2C" : "B2C",
    "Direct" : "Direct",
    "B2B" : "B2B",
    "Banca" : "Banca",
}

export const PERMISSIONS = {
    "VIEW_AFFILIATES" : "VIEW_AFFILIATES",
    "CREATE_AFFILIATES" : "CREATE_AFFILIATES",
    "EDIT_AFFILIATES" : "EDIT_AFFILIATES",
    "MODIFY_PREMIUMS" : "MODIFY_PREMIUMS",
    "VIEW_DISCOUNT_CODES" : "VIEW_DISCOUNT_CODES",
    "CREATE_DISCOUNT_CODES" : "CREATE_DISCOUNT_CODES",
    "EDIT_DISCOUNT_CODES" : "EDIT_DISCOUNT_CODES",
    "DELETE_DISCOUNT_CODES" : "DELETE_DISCOUNT_CODES",
    "VIEW_POLICIES" : "VIEW_POLICIES",
    "EDIT_POLICY" : "EDIT_POLICY",
    "DELETE_POLICY" : "DELETE_POLICY",
    "TRANSFER_POLICY" : "TRANSFER_POLICY",
    "VIEW_REFERAL_POLICIES" : "VIEW_REFERAL_POLICIES",
    "VIEW_CONSOLIDATED_REPORT" : "VIEW_CONSOLIDATED_REPORT",
    "VIEW_INDIVIDUAL_MEDICAL_REPORT" : "VIEW_INDIVIDUAL_MEDICAL_REPORT",
    "VIEW_DOMESTIC_WORKER_REPORT" : "VIEW_DOMESTIC_WORKER_REPORT",
    "VIEW_LIFE_INSURANCE_REPORT" : "VIEW_LIFE_INSURANCE_REPORT",
    "VIEW_HOME_INSURANCE_REPORT" : "VIEW_HOME_INSURANCE_REPORT",
    "VIEW_OUTBOUND_TRAVEL_REPORT" : "VIEW_OUTBOUND_TRAVEL_REPORT",
    "VIEW_INBOUND_TRAVEL_REPORT" : "VIEW_INBOUND_TRAVEL_REPORT",
    "VIEW_MOTOR_REPORT" : "VIEW_MOTOR_REPORT",
    "MANAGE_CREDIT_LIMIT" : "MANAGE_CREDIT_LIMIT",
    "MANAGE_ACCESS" : "MANAGE_ACCESS",
    "CHANGE_POLICY_STATUS" : "CHANGE_POLICY_STATUS"
  }