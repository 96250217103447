import React from "react";
import Navigation from "../common/Navigation";
import {Paper, Tabs, Tab, Typography} from "@material-ui/core";
import "./style.css";
import AllTable from "./AllTable";
import SubmittedTable from "./SubmittedTable";
import PendingTable from "./PendingTable";
import {Link, withRouter} from "react-router-dom";
import UnpaidTable from "./UnpaidTable";

class Policies extends React.Component{

  state = {
    tabIndex : 0
  }

  handleChange = (e, value) => {
    this.setState({ tabIndex : value })
  }

  componentDidMount(){
    
  }
  render(){
    return(
      <div className="d-flex">
        <Navigation />
        <div className="container-fluid">
          <Paper className="p-2 sq-shadow mb-3 d-flex">
            <Typography><Link to={`/home`}>Home</Link></Typography>
            <Typography className="mr-2 ml-2">/</Typography>
            <Typography>Policies</Typography>
          </Paper>
          <Paper className="sq-shadow">
            <Tabs
              value={this.state.tabIndex}
              indicatorColor = "primary"
              onChange = {this.handleChange}
              variant = "fullWidth"
            >
              <Tab label="All" />
              <Tab label="Documents Submitted" /> 
              <Tab label="Documents Pending" /> 
              <Tab label="Active" />
              <Tab label="Suspended" />
              <Tab label="Rejected" />
              <Tab label="Unpaid" />
            </Tabs>
          </Paper>
          {this.state.tabIndex === 0 && <AllTable />}
          {this.state.tabIndex === 1 && <SubmittedTable />}
          {this.state.tabIndex === 2 && <PendingTable />}
          {this.state.tabIndex === 3 && <AllTable status = "Active" />}
          {this.state.tabIndex === 4 && <AllTable status = "Suspended" />}
          {this.state.tabIndex === 5 && <AllTable status = "Rejected" />}
          {this.state.tabIndex === 6 && <UnpaidTable />}
        </div>
      </div>
    )
  }
}
export default withRouter(Policies);