import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  palette: {
    primary: {
      main : "#01c0c8",
      contrastText : "#fff"
    },
    secondary: {
      main : "#01c0c8",
      contrastText : "#fff"
    },
  },
  status: {
    danger: 'orange',
  },
});

export default theme;