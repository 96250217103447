import { Button, Grid, MenuItem, Paper, TextField } from "@material-ui/core";
import React from "react";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import Snackbar from "../common/Snackbar";
import AffiliateService from "../../services/affiliates";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

const INSURANCE_TYPES = {
  health: "Health",
  life: "Life",
  travel: "Travel",
  home: "Home"
}

class SetTarget extends React.Component {

  state = {
    affiliate: "",
    start_date: "",
    end_date: "",
    target: "",
    insurance_type: "",
    isLoading: false,
    showSnackbar: false,
    snackBarMessage: "",
    messageType: "",
  }

  componentDidMount() {
    this.getAffiliates();
    this.setState({
      start_date: new Date(new Date().setHours(0, 0, 0, 0)).getTime(),
      end_date: new Date(new Date().setHours(23, 59, 59, 999)).getTime()
    })
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  }

  handleChangeAffiliate = (e) => {
    const affiliate = e.target.value;
    this.setState({ affiliate });
  }

  handleCloseSnackbar = () => {
    this.setState({ showSnackbar: false })
  }

  saveTarget = async () => {
    this.setState({ isLoading: true });

    const dataObj = {
      start_date: this.state.start_date,
      end_date: this.state.end_date,
      target: this.state.target,
      insurance_type: this.state.insurance_type,
    }

    const data = await AffiliateService.addTarget(this.state.affiliate, dataObj);
    if (data.data.status) {
      this.setState({
        isLoading: false,
        showSnackbar: true,
        snackBarMessage: "Target Added",
        messageType: "success"
      });
      this.clearState();
    }

  }

  clearState = () => {
    this.setState({
      affiliate: "",
      target: "",
      insurance_type: "",
      start_date: new Date(new Date().setHours(0, 0, 0, 0)).getTime(),
      end_date: new Date(new Date().setHours(23, 59, 59, 999)).getTime()
    })
  }

  isValid = () => {
    if (
      this.state.start_date === "" ||
      this.state.end_date === "" ||
      this.state.start_date > this.state.end_date ||
      this.state.target <= 0 ||
      this.state.insurance_type === "" ||
      this.state.affiliate === ""
    ) {
      return false
    }
    return true;
  }

  getAffiliates = async () => {
    const res = await AffiliateService.getAllAffiliates();
    this.setState({ affiliates: res.data });
  }

  handleStartDateChange = (date) => {
    const start_date = new Date(date.setHours(0, 0, 0, 0)).getTime();
    this.setState({ start_date });
  }

  handleEndDateChange = (date) => {
    const end_date = new Date(date.setHours(23, 59, 59, 999)).getTime();
    this.setState({ end_date });
  }

  render() {
    return (
      <React.Fragment>
        <Snackbar
          showSnackbar={this.state.showSnackbar}
          messageType={this.state.messageType}
          snackBarMessage={this.state.snackBarMessage}
          onCloseSnackbar={this.handleCloseSnackbar}
        />
        <Grid container spacing={2} className="mt-2">
          <Grid item xs={12} sm={6} >
            <TextField
              label="Affiliate"
              variant="outlined"
              margin="normal"
              fullWidth
              select
              name="affiliate"
              onChange={this.handleChangeAffiliate}
              value={this.state.affiliate}
              className="mb-3"
            >
              <MenuItem value="" disabled>Select Affiliate</MenuItem>
              {
                Array.isArray(this.state.affiliates)
                && this.state.affiliates.filter((affiliate) => affiliate.affiliateId !== this.state.affiliate_id)
                  .map((affiliate) => {
                      return (
                        <MenuItem key={affiliate.affiliateId} value={affiliate.affiliateId}>{affiliate.name}</MenuItem>
                      )
                  })
              }
            </TextField>
            <TextField
              label="Insurance type"
              variant="outlined"
              margin="normal"
              fullWidth
              select
              name="insurance_type"
              onChange={this.handleChange}
              value={this.state.insurance_type}
              className="mb-3"
            >
              <MenuItem value="" disabled>Select Insurance type</MenuItem>
              <MenuItem value="health">{INSURANCE_TYPES.health}</MenuItem>
              <MenuItem value="life">{INSURANCE_TYPES.life}</MenuItem>
              <MenuItem value="travel">{INSURANCE_TYPES.travel}</MenuItem>
              <MenuItem value="home">{INSURANCE_TYPES.home}</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={12} sm={6} className="pt-4" >
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <DatePicker
                label="Start Date"
                inputVariant="outlined"
                animateYearScrolling
                name="start_date"
                clearable
                fullWidth
                value={this.state.start_date}
                onChange={this.handleStartDateChange}
                className="mb-4"
              />
            </MuiPickersUtilsProvider>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <DatePicker
                label="End Date"
                inputVariant="outlined"
                animateYearScrolling
                name="end_date"
                clearable
                fullWidth
                value={this.state.end_date}
                onChange={this.handleEndDateChange}
              />
            </MuiPickersUtilsProvider>
            <TextField
              type="number"
              label="Target"
              variant="outlined"
              margin="normal"
              fullWidth
              name="target"
              onChange={this.handleChange}
              value={this.state.target}
            />
          </Grid>

          <Grid item xs={12}>
            <Paper className="sq-shadow p-2 d-flex justify-content-end">
              <Link to="/" className="d-inline-block mr-3">
                <Button variant="outlined" color="primary">Cancel</Button>
              </Link>
              <Button
                variant="contained"
                color="primary"
                disabled={
                  !this.isValid() || this.state.isLoading
                }
                onClick={this.saveTarget}
              >
                {this.state.isLoading ? "Saving . . ." : "Save"}
              </Button>
            </Paper>
          </Grid>

        </Grid>
      </React.Fragment>
    )
  }
}

export default withRouter(SetTarget);