import axios from "axios";
import config from "./config";

const baseUrl = config.baseUrl;

let download = {}
download.getFile = async (fileUrl) => {
  const res = await axios({
    url : fileUrl,
    responseType : "blob"
  });
  return res;
}

download.getPolicySchedule = async(id) => {
  const url = `${baseUrl}/download-policy-schedule/${id}`
  const res = axios({url, responseType: "blob"});
  return res;
}

export default download;