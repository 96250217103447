import moment from "moment";
import _ from "lodash";

const utils = {};

utils.getFormatedDate = (timestamp, checkFormat) => {
    try {
        let time;
        if(checkFormat){
            time = moment(timestamp, checkFormat).format('LL')
        }else{
            time = moment(timestamp).format('LL')
        }
        return time === "Invalid Date" ? "-" : time;
    } catch (error) {
        return "-";
    }
}


utils.getFormattedAmount = (amount) => {
    amount = parseFloat(amount).toFixed(3);
    amount = amount === "NaN" ? "0.000" : amount
    amount = amount.split(".");
    amount[0] = parseInt(amount[0]).toLocaleString()
    return amount.join(".");
}

/**
 * 
 * @param {string} url 
 * @param {Object} queryParams 
 * @returns 
 */
utils.addQueryParams = (url, queryParams) => {
    queryParams = _.map(queryParams, (value, key) => {
        return `${key}=${value}`;
    })
    queryParams = Object.values(queryParams).join("&")

    return `${url}?${queryParams}`;
}

utils.getRoundOffValue = (policy) => {
    let total_amount = 0;
    total_amount += _.get(policy, "amount.base_price", 0);
    total_amount += _.get(policy, "amount.policy_fee", 0);
    total_amount += _.get(policy, "amount.government_fee", 0);
    total_amount += _.get(policy, "amount.emergency_fund_fee", 0);
    total_amount += _.get(policy, "amount.bmi_value", 0);
    total_amount += _.get(policy, "amount.vat", 0);

    if(policy.insurance_type == "health"){
        total_amount += _.get(policy, "amount.life_total", 0);
    }

    if(policy.insurance_type == "life" ) {
        total_amount += _.get(policy, "amount.pa_death_cover", 0);
    }

    let round_off = _.get(policy, "amount.total", 0) - total_amount;
    return parseFloat(round_off).toFixed(2);
}

utils.getStatusText = (status) => {
    return status ? "Active" : "Deactive"
}
 


export default utils;