import React from "react";
import { Document, Page, View, Text, Image, StyleSheet, Font } from "@react-pdf/renderer"
import DhofarLogo from "../../../static/images/dhofar-logo.jpg";
import NextCare from "../../../static/images/nextcare-logo.png";
import LatoBold from "../../../static/fonts/Lato/Lato-Black.ttf";
import LatoRegular from "../../../static/fonts/Lato/Lato-Regular.ttf";
import moment from "moment";

Font.register({
  family: 'Lato',
  fonts: [
    { src: LatoRegular },
    { src: LatoBold, fontWeight: "black" }
  ]
});

const styles = StyleSheet.create({
  page : {
    paddingTop : 30,
    fontSize : 9,
    fontFamily : "Lato",
    fontWeight : "black"
  },
  card_container : {
    width : 360,
    marginLeft : "auto",
    marginRight : "auto",
    paddingVertical : 10,
    paddingHorizontal : 15,
    border : "1pt solid #ccc",
    borderRadius : 4,
    backgroundColor : "#fff",
    lineHeight : 1.4,
    position : "relative"
  },
  row : {
    display : "flex",
    flexDirection : "row"
  },
  company_logo : {
    height : 30
  },
  top_image_row : {
    flexGrow : 1,
    paddingLeft : 30
  },
  label : {
    width : 45,
    fontWeight : "normal"
  },
  col_4 : {
    width : "33.33%"
  },
  col_8 : {
    width : "66.67%"
  }
});

const DomesticPDF = ({ data }) => {

  const startDate = data.startDate;

  const getDob = () => {
    const dob = data.personalInformation.dob;

    let date = moment(dob, 'DD-MM-YYYY');
    if(!date.isValid()){
      date = moment(dob, 'DD-MMM-YYYY');
    }

    return date.format("DD-MM-YYYY");
  }

  const getStartDate = () => {
    return moment(startDate).format("DD/MM/YYYY")
  }
  
  const getEndDate = () => {
    let numYears = 1;

    if(data.cover_type === "Unified Domestic Worker"){
      numYears = 2
    }
    return moment(startDate).subtract(1, "day").add(numYears, "year").format("DD/MM/YYYY");
  }

  return(
    <Document>
      <Page style={styles.page}>
        <View style={styles.card_container}>
          <View style={[styles.row, { justifyContent : "space-between", alignItems : "center", height : 65, marginBottom : 8 }]}>
            <Image source={DhofarLogo} style={{ height : 65 }} />

            <View style={styles.top_image_row}>
              <View style={[styles.row, { justifyContent : "flex-end", marginBottom : 4 }]}>
                <Image source={NextCare} style={{ height : 35 }} />
              </View>
              {
                data.cover_type === "Unified Domestic Worker" ?
                  <>
                    <Text>
                      UNIFIED MEDICAL INSURANCE
                    </Text>
                    <Text>
                      FOR HOUSEHOLD DOMESTIC WORKERS
                    </Text>
                  </>
                  :
                  <Text>
                    AFFORDABLE HEALTH COVER
                  </Text>
              }
            </View>
          </View>

          <View style={styles.row}>
            <View style={styles.label}>
              <Text>
                Name :
              </Text>
            </View>
            <Text>
              {data.personalInformation.fullName}
            </Text>
          </View>

          <View style={styles.row}>
            <View style={[styles.row, styles.col_8]}>
              <View style={styles.label}>
                <Text>
                  Card No :
                </Text>
              </View>
              <Text>
                {data.policy_num}
              </Text>
            </View>
            <View style={[styles.row, styles.col_4]}>
              <View style={styles.label}>
                <Text>
                  Id No :
                </Text>
              </View>
              <Text>
                {data.civil_id}
              </Text>
            </View>
          </View>

          <View style={styles.row}>
            <View style={[styles.row, styles.col_8]}>
              <View style={styles.label}>
                <Text>
                  Network :
                </Text>
              </View>
              <Text>
                RN2 (Access in Oman)
              </Text>
            </View>
            <View style={[styles.row, styles.col_4]}>
              <View style={styles.label}>
                <Text>
                  D.O.B :
                </Text>
              </View>
              <Text>
                {getDob()}
              </Text>
            </View>
          </View>

          <View style={styles.row}>
            <View style={styles.col_4}>

              <View style={styles.row}>
                <View style={styles.label}>
                  <Text>
                    Ded :
                  </Text>
                </View>
                <Text>
                  Nil
                </Text>
              </View>

              <View style={styles.row}>
                <View style={styles.label}>
                  <Text>
                    Co.Ins :
                  </Text>
                </View>
                <Text>
                  Nil
                </Text>
              </View>

              <View style={styles.row}>
                <View style={styles.label}>
                  <Text>
                    Cons :
                  </Text>
                </View>
                <Text>
                  Yes
                </Text>
              </View>
            </View>

            <View style={styles.col_4}>

              <View style={styles.row}>
                <View style={styles.label}>
                  <Text>
                    Optical :
                  </Text>
                </View>
                <Text>
                  No
                </Text>
              </View>

              <View style={styles.row}>
                <View style={styles.label}>
                  <Text>
                    Dental :
                  </Text>
                </View>
                <Text>
                  No
                </Text>
              </View>

              <View style={styles.row}>
                <View style={styles.label}>
                  <Text>
                    Metarnity :
                  </Text>
                </View>
                <Text>
                  No
                </Text>
              </View>
            </View>

            <View style={styles.col_4}>

              <View style={styles.row}>
                <View style={styles.label}>
                  <Text>
                    Class :
                  </Text>
                </View>
                <Text>
                  Semi-Private
                </Text>
              </View>

              <View style={styles.row}>
                <View style={styles.label}>
                  <Text>
                    Effective :
                  </Text>
                </View>
                <Text>
                  {getStartDate()}
                </Text>
              </View>

              <View style={styles.row}>
                <View style={styles.label}>
                  <Text>
                    Expiry :
                  </Text>
                </View>
                <Text>
                  {getEndDate()}
                </Text>
              </View>
            </View>

          </View>

          <View style={{ color : "red", lineHeight : 1.2 }}>
            <Text>
              Pre approval required for all Out-Patient services
            </Text>
            <Text>
              Out-Patient Pre-Existing & Chronic Conditions:Not Covered
            </Text>
            <Text>
              Please collect resident card copy & submit along with claim
            </Text>
          </View>

          <View style={{ fontWeight : "normal", lineHeight : 1.2 }}>
            <Text>
              This card is for personal use and must be presented at every doctor's visit
            </Text>
          </View>

          <View style={{ fontWeight : "normal", lineHeight : 1.2, textAlign : "center", marginTop : 4 }}>
            <Text>
              Nextcare Hotline Number: 8007775
            </Text>
          </View>

        </View>
      </Page>
    </Document>
  )
}

export default DomesticPDF;