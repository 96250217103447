import React from "react";
import Navigation from "../common/Navigation";
import {Grid, Typography, Paper, TextField, Button, InputAdornment, IconButton} from "@material-ui/core";
import {Link} from "react-router-dom";
import Auth from "../../services/auth";
import Snackbar from "../common/Snackbar";
import { Visibility , VisibilityOff } from "@material-ui/icons";
class Settings extends React.Component{
  
  state = {
    oldPassword : "",
    newPassword : "",
    confirmPassword : "",
    showError : false,
    showOldPasswordError : false,
    showPasswordLengthError : false,
    isUpdatingPassword : false,
    showSnackbar : false,
    messageType : "",
    snackBarMessage : "",
    showOldPassword : false,
    showNewPassword : false,
    showConfirmPassword : false,
    email : "",
    isLoadingEmail : false,
    password : ""
  }

  handleChange = (e) => {
    this.setState({ [e.target.name] : e.target.value, showOldPasswordError : false })
  }

  handleCloseSnackbar = () => {
    this.setState({ showSnackbar : false });
  }

  updatePassword = () => {
    this.setState({ isUpdatingPassword : true });
    Auth.updatePassword(this.state.oldPassword, this.state.newPassword).then((res) => {
      if(res.passwordUpdated){
        this.setState({
          showSnackbar : true,
          snackBarMessage : "Password updated successfully",
          messageType : "success",
          isUpdatingPassword : false,
          oldPassword : "",
          newPassword : "",
          confirmPassword : "",
          showOldPassword : false,
          showNewPassword : false,
          showConfirmPassword : false
        });
      }
    }).catch((e) => {
      if(e.code === "auth/wrong-password"){
        this.setState({ showOldPasswordError : true })
      }
      else{
        this.setState({
          showSnackbar : true,
          snackBarMessage : e.message,
          messageType : "danger"
        })
      }
      this.setState({ isUpdatingPassword : false });
    })
  }

  updateEmail = async () => {
    this.setState({ isLoadingEmail : true });
    Auth.updateEmail(this.state.email, this.state.password).then((res) => {
      if(res.emailUpdated){
        this.setState({
          showSnackbar : true,
          snackBarMessage : "Email updated successfully",
          messageType : "success",
          isLoadingEmail : false,
          password : "",
          showPassword : false,
        });
      }
    }).catch((e) => {
      if(e.code === "auth/wrong-password"){
        this.setState({ showPasswordError : true })
      }
      else{
        this.setState({
          showSnackbar : true,
          snackBarMessage : e.message,
          messageType : "danger"
        })
      }
      this.setState({ isLoadingEmail : false });
    })
  }

  handleClickShowPassword = (name) => {
    this.setState({ [name] : !this.state[name] });
  }

  getEmail = () => {
    Auth.getEmail().then((email) => {
      this.setState({ email })
    })
  }

  componentDidMount(){
    this.getEmail();
    sessionStorage.clear();
  }

  isValidMail = () => {
    const value = this.state.email;
    const atpos = value.indexOf("@");
    const dotpos = value.lastIndexOf(".");
    if(atpos < 1 || dotpos < atpos + 2 || dotpos + 2 >= value.length || value === "")
      return false;
    else
      return true;
  }

  render(){
    return(
      <div className="d-flex">
        <Navigation />
        <Snackbar
          showSnackbar = { this.state.showSnackbar }
          messageType = {this.state.messageType}
          snackBarMessage = {this.state.snackBarMessage}
          onCloseSnackbar = {this.handleCloseSnackbar}
        />
        <div className="container-fluid">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Paper className="p-2 sq-shadow d-flex">
                <Typography><Link to="/">Home</Link></Typography>
                <Typography className="mr-2 ml-2">/</Typography>
                <Typography>Settings</Typography>
              </Paper>
            </Grid>

            <Grid item xs={12} md={4} sm={6}>
              <Paper className="p-2 sq-shadow">
                <Typography variant="h6" className="pb-2 mb-2 border-bottom"> Update Password </Typography>
                <TextField
                  label = "Old Password"
                  variant = "outlined"
                  fullWidth
                  className="mb-2"
                  name = "oldPassword"
                  type = {this.state.showOldPassword ? "text" : "password"}
                  onChange = {this.handleChange}
                  value = {this.state.oldPassword}
                  error = {this.state.showOldPasswordError}
                  helperText = {this.state.showOldPasswordError ? "Please enter correct password" : ""}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          edge="end"
                          onClick={() => this.handleClickShowPassword("showOldPassword")}
                          tabIndex = "-1"
                        >
                          {this.state.showOldPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <TextField
                  label = "New Password"
                  variant = "outlined"
                  fullWidth
                  className="mb-2"
                  name = "newPassword"
                  type = {this.state.showNewPassword ? "text" : "password"}
                  onChange = {this.handleChange}
                  value = {this.state.newPassword}
                  error = {this.state.showPasswordLengthError && this.state.newPassword !== "" && this.state.newPassword.length < 6}
                  helperText = {this.state.showPasswordLengthError && this.state.newPassword !== "" && this.state.newPassword.length < 6 ? "Please enter atleast 6 digit password" : ""}
                  
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          edge="end"
                          onClick={() => this.handleClickShowPassword("showNewPassword")}
                          tabIndex = "-1"
                        >
                          {this.state.showNewPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <TextField
                  label = "Confirm New Password"
                  variant = "outlined"
                  fullWidth
                  className="mb-2"
                  name = "confirmPassword"
                  type = {this.state.showConfirmPassword ? "text" : "password"}
                  onChange = {this.handleChange}
                  value = {this.state.confirmPassword}
                  onBlur = {() => this.setState({ showError : true })}
                  error = {this.state.showError && this.state.newPassword !== this.state.confirmPassword}
                  helperText = {this.state.showError && this.state.newPassword !== this.state.confirmPassword ? "New password and confirm password must be same" : ""}
                  
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          edge="end"
                          onClick={() => this.handleClickShowPassword("showConfirmPassword")}
                          tabIndex = "-1"
                        >
                          {this.state.showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />

                <div className="text-right">
                  <Button
                    color="primary"
                    variant="contained"
                    disabled = {
                      this.state.oldPassword === "" ||
                      this.state.oldPassword.length < 6 ||
                      this.state.newPassword === "" ||
                      this.state.newPassword.length < 6 ||
                      this.state.confirmPassword === "" ||
                      this.state.newPassword !== this.state.confirmPassword ||
                      this.state.isUpdatingPassword
                    }

                    onClick = {this.updatePassword}
                  >
                    Update
                  </Button>
                </div>
              </Paper>
            </Grid>
            <Grid item xs={12} md={4} sm={6}>
              <Paper className="p-2 sq-shadow">
                <Typography variant="h6" className="pb-2 mb-2 border-bottom"> Update Email </Typography>
                
                <TextField
                  label = "Password"
                  variant = "outlined"
                  fullWidth
                  className="mb-2"
                  name = "password"
                  type = {this.state.showPassword ? "text" : "password"}
                  onChange = {this.handleChange}
                  value = {this.state.password}
                  error = {this.state.showPasswordError}
                  helperText = {this.state.showPasswordError ? "Please enter correct password" : ""}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          edge="end"
                          onClick={() => this.handleClickShowPassword("showPassword")}
                          tabIndex = "-1"
                        >
                          {this.state.showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />

                <TextField
                  type = "text"
                  label = "E - mail"
                  variant = "outlined"
                  fullWidth
                  value = {this.state.email}
                  name = "email"
                  onChange = {this.handleChange}
                />

                <div className="mt-2 text-right">
                  <Button
                    color = "primary"
                    variant = "contained"
                    value = {this.state.email}
                    disabled = {
                      !this.isValidMail() ||
                      this.state.isLoadingEmail ||
                      this.state.password === "" || 
                      this.state.password.length < 6
                    }
                    onClick = {this.updateEmail}
                  >
                    Update
                  </Button>
                </div>
              </Paper>
            </Grid>
          </Grid>
        </div>
      </div>
    )
  }
}
export default Settings;