import EN from "./en.json";
import AR from "./ar.json";

const FormattedFunctionMessage = (id) => {
    const isArabic = localStorage.getItem("language") === "arabic";
    const languageObj = isArabic ? AR : EN;

    return languageObj[id] || id
}

export default FormattedFunctionMessage;